import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { BDX_AGGREGATE_TYPE_CLAIM, BDX_TYPE_CLAIM, BDX_TYPE_PREMIUM } from 'consts';
import { enqueueNotification, hideModal } from 'stores';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES, BDX_FACILITY_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitAllAggregates = ({ facilityReference, yearMonthSubmission, type }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useSubmitAllAggregates',
    warning: 'notification.binderManagement.aggregate.fail',
  });

  const handleSuccess = ({ data }) => {
    queryClient.setQueryData([BDX_AGGREGATES, type, facilityReference, yearMonthSubmission], data);
    queryClient.invalidateQueries([
      BDX_FACILITY_AGGREGATES,
      facilityReference,
      yearMonthSubmission,
      type === BDX_AGGREGATE_TYPE_CLAIM ? BDX_TYPE_CLAIM : BDX_TYPE_PREMIUM,
    ]);
    dispatch(hideModal());
    dispatch(enqueueNotification(`notification.binderManagement.aggregate.${type}.success}`, 'success'));
  };

  return useMutation(
    async () => {
      if (!facilityReference || !yearMonthSubmission) throw new Error('Missing required params');

      const path = `aggregate/${type}/facility/${facilityReference}/${yearMonthSubmission}/submit`;

      const { data } = await binderManagementBDXAxios.post(path, {
        facilityReference,
        yearMonthSubmission,
      });

      return { data };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
