import { useQuery } from 'react-query';

// app
import { appAxios } from 'lib/app';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { SEARCH_CLIENT, SEARCH_OPTIONS } from 'lib/app/queryKeys';

export const useSearch = ({ searchStr, sourceSystemId, searchSource = SEARCH_CLIENT }) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'useSearch',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const { key, URL } = SEARCH_OPTIONS.find(({ key }) => key === searchSource);

  return useQuery(
    [key, searchStr, sourceSystemId],
    async () => {
      const endpointParams = {
        searchStr,
        sourceSystemId,
      };

      if (searchStr) {
        const { data } = await appAxios.get(URL, {
          params: endpointParams,
          timeout: 30000,
          enabled: false,
        });

        return data;
      }

      return [];
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
