import { useEffect, useMemo, useState } from 'react';

// MUI
import { Box, Grid } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Button } from 'components';
import { useUploadBDXCoverHolderModal } from 'forms/UploadBDX/BDXCoverHolder/useUploadBDXCoverHolderModal';
import { BDXTypesButtons, getTabFromBDXType } from 'modules/Binders/components';

// Edge
import { useGetBdxTypes } from 'lib/binderManagement';
import * as utils from 'utils';

import { UnSubmittedBdxFiles } from './components';

export const UnSubmittedBdx = ({ entity }) => {
  const { data: bdxTypes, isFetching: isLoadingBDXTypes } = useGetBdxTypes();
  const uploadBDXCoverHolderModal = useUploadBDXCoverHolderModal();

  // TODO: remove 'Sanction check only' from bdxTypes
  const validBdxTypes = bdxTypes?.filter((bdxType) => bdxType !== 'Sanction check only');

  const tabs = useMemo(() => validBdxTypes?.map(getTabFromBDXType), [validBdxTypes]);
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (bdxTypes?.length) {
      setActiveTab(getTabFromBDXType(bdxTypes[0]));
    }
  }, [bdxTypes]);

  const handleChangeTab = (_, newTabId) => {
    if (newTabId) {
      setActiveTab(tabs.find(({ id }) => newTabId === id));
    }
  };

  if (isLoadingBDXTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Box display="flex" flex={1} flexDirection="column" style={{ width: '100%' }}>
      <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item>
          <BDXTypesButtons active={activeTab?.id} tabs={tabs} handleChangeTab={handleChangeTab} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="medium"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              uploadBDXCoverHolderModal({ coverHolderName: entity.name, id: entity.id, defaultBdxType: activeTab.label });
            }}
            icon={CloudUploadIcon}
            iconPosition="right"
            text={utils.string.t('app.uploadBDX')}
          />
        </Grid>
      </Grid>
      <Box display="flex" flex="1" flexDirection="column">
        <UnSubmittedBdxFiles bdxType={activeTab?.id} coverHolderId={entity?.id} />
      </Box>
    </Box>
  );
};
