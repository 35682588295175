import { FC } from 'react';

import { Grid } from '@material-ui/core';

import { Button, FormSelect, FormText } from 'components';

// app
import * as utils from 'utils';

import { IAddBinderLimitViewProps } from './AddBinderLimit.types';
import { Conditions, SumColumns } from './components';

import { addBinderLimitStyles } from './AddBinderLimit.styles';

export const AddBinderLimitView: FC<IAddBinderLimitViewProps> = ({
  fields,
  methods,
  errors,
  limitColumns,
  limit,
  limitColumnsFiltered,
  onSelectBdxType,
  submit,
}) => {
  const classes = addBinderLimitStyles();

  return (
    <div className={classes.root} data-testid="AddLimitView">
      <Grid container spacing={2}>
        <Grid item className={classes.nameWrapper}>
          <FormText
            {...utils.form.getFieldProps(fields, 'name')}
            control={methods.control}
            error={errors.name}
            rules={{ required: 'Required' }}
          />
          <FormSelect
            {...utils.form.getFieldProps(fields, 'bdxType')}
            handleUpdate={onSelectBdxType}
            control={methods.control}
            error={errors.bdxType}
            rules={{ required: 'Required' }}
          />
        </Grid>
      </Grid>
      {limitColumns && <Conditions methods={methods} fields={fields} limit={limit} limitColumns={limitColumns} />}
      {limitColumns && (
        <SumColumns methods={methods} limit={limit} limitColumns={limitColumns} limitColumnsFiltered={limitColumnsFiltered} />
      )}
      <div className={classes.submit}>
        <Button
          text={utils.string.t('app.save')}
          onClick={methods.handleSubmit(submit)}
          color="primary"
          disabled={methods.formState.isSubmitting}
        />
      </div>
    </div>
  );
};
