import { useMemo, useState } from 'react';

import { Box, CircularProgress, Grid, IconButton, Popover, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button, FormAutocompleteMui, Translate } from 'components';

import { useGetBdximportMapping } from 'lib/binderManagement';
import * as utils from 'utils';

import { useImportMappingClasses } from './ImportMapping.style';

export const ImportMapping = ({ handleClose, inputEl, handleImport }) => {
  const [inputValue, setInputValue] = useState(null);
  const { data: mappings, isLoading } = useGetBdximportMapping();
  const classes = useImportMappingClasses();

  const mappingOptions = useMemo(
    () =>
      mappings?.map((mapping) => ({
        value: mapping,
        label: `${mapping.facility}-${mapping.bdxType}`,
      })) ?? [],
    [mappings]
  );

  const handleSubmit = () => {
    handleImport({
      facilityFrom: inputValue.facility,
      bdxTypeFrom: inputValue.bdxType,
    });
    handleClose();
  };

  return (
    <div>
      <Popover
        style={{
          padding: '1rem',
          paddingTop: 0,
          paddingBottom: 0,
        }}
        open
        anchorEl={inputEl.current}
        disableScrollLock
        disableRestoreFocus
        onClose={() => {
          handleClose();
        }}
      >
        <Box className={classes.commentsBox}>
          <Box className={classes.title}>
            <Typography>{utils.string.t('form.bdx.importMapping')}</Typography>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container direction="column">
            <Grid
              item
              style={{
                minWidth: 300,
                paddingBottom: '1rem',
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <FormAutocompleteMui
                  name="importMapping"
                  onSelect={({ value }) => setInputValue(value)}
                  label={utils.string.t('form.bdx.importMappingLabel')}
                  options={mappingOptions}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                type="submit"
                text={<Translate label="app.import" />}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                size="small"
                disabled={inputValue === null}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
};
