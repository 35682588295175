import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './Rule.styles';

export const RuleSkeleton = () => {
  const classes = makeStyles(styles, { name: 'Rule' })();

  return (
    <div className={classes.rule}>
      <div className={classes.ruleSkeleton}>
        <Skeleton width={300} height={30} />
      </div>
    </div>
  );
};
