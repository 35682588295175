import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { QB_CARRIERS } from 'lib/quoteBind/queryKeys';

export const usePostCarrier = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePostCarrier',
      message: 'There is an error when adding new carrier',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);

    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_CARRIERS);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.carrier.postSuccess', 'success'));
  };

  return useMutation(
    async (data) => {
      if (!data || isEmpty(data)) {
        throw new Error('Data missing .');
      }

      return await quoteBindAxios.post(`carriers`, data);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
