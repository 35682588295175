import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { BM_SEARCH } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetSearch = ({ searchStr }, options) => {
  const params = {
    searchStr,
  };
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetSearch',
    message: 'Error getting search results',
  });

  return useQuery<IGetSearchResponse, AxiosError, IGetSearchDTO>(
    [BM_SEARCH, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get('search', { params });
      return result?.data;
    },
    {
      onError: handleError,
      ...options,
    }
  );
};
