import React from 'react';

import { makeStyles } from '@material-ui/core';

import styles from './Reports.style';
import { PowerBIEmbed } from 'powerbi-client-react';

export const ReportsView = ({ config }) => {
  const classes = makeStyles(styles, { name: 'Reports' })();

  return (
    <div className={classes.root}>
      {config?.accessToken && config?.embedUrl ? <PowerBIEmbed embedConfig={config} cssClassName={classes.reports} /> : null}
    </div>
  );
};
