import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
// mui
import { Box, Grid } from '@material-ui/core';

// Edge
import { Empty, Pagination } from 'components';
import { Card } from 'modules/Admin/OperationalClients/Card';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { usePagination } from 'hooks';
import { useGetOperationalClients } from 'lib/admin';
import * as utils from 'utils';
import config from 'config';

export const Clients = ({ firstLetter }) => {
  const [params, setParams] = useState(() => ({
    firstLetterFilter: firstLetter,
  }));

  const { data: operationalClientsList, isLoading, isError } = useGetOperationalClients(params);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      firstLetterFilter: firstLetter,
    }));
  }, [firstLetter]);

  const handleChangePage = (newPage) => {
    setParams((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setParams((prevState) => ({
      ...prevState,
      page: 0,
      size: rowsPerPage,
    }));
  };

  const pagination = usePagination(
    operationalClientsList?.content,
    utils.generic.getPaginationObj(operationalClientsList),
    handleChangePage,
    handleChangeRowsPerPage
  );

  if (isLoading) return <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />;
  if (isError) return <Empty title={utils.string.t('app.error')} icon={<Loading />} width={300} />;

  if (operationalClientsList?.content?.length === 0) return <Empty title={utils.string.t('app.noResult')} icon={<Loading />} width={300} />;

  return (
    <Box mt={4} display="flex" flex="1" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
      <Grid container spacing={3} style={{ marginTop: 20 }} data-testid="operational-clients-list">
        {operationalClientsList?.content?.map((operationalClient) => {
          const slug = operationalClient?.name ? `${kebabCase(operationalClient.name)}` : '';

          return (
            <Grid key={operationalClient.id} item xs={12} sm={6} md={4} lg={3} data-testid={`operational-client-${operationalClient.id}`}>
              <Link
                to={{
                  pathname: `${config.routes.client.item}/${operationalClient.id}/${slug}`,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Card operational={operationalClient} />
              </Link>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Pagination
            page={pagination.obj.page}
            count={pagination.obj.rowsTotal}
            rowsPerPage={pagination.obj.rowsPerPage}
            rowsPerPageOptions={config.ui.gridPagination.options}
            onChangePage={pagination.handlers.handleChangePage}
            onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
            testid={'operational-clients'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
