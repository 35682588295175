import * as utils from 'utils';
import config from 'config';

export const getFacilityDetailsBreadcrumbs = ({ facility, binderType, binderId, history }) => {
  switch (binderType) {
    case 'market':
      const market = facility?.markets.find(({ operationalMarketId }) => operationalMarketId === parseInt(binderId));

      if (facility && !market) {
        history.replace(config.routes.binders.root);
        return;
      }

      return [
        {
          name: 'binder-management',
          label: utils.string.t('binders.markets.title'),
          link: config.routes.binders.markets,
        },
        {
          name: binderType,
          label: market?.operationalMarketName || utils.string.t('binders.markets.title'),
          link: `${config.routes.binders.market}/${binderId}`,
        },
        {
          name: 'facility',
          label: facility?.umr || utils.string.t('binders.facility'),
          link: `${config.routes.binders.market}/${binderId}/${config.routes.binders.facilityDetails}/${facility?.umr}`,
          active: true,
        },
      ];
    case 'coverholder':
      const isValidCoverholder = facility?.operationalClientId === parseInt(binderId);

      if (facility && !isValidCoverholder) {
        history.replace(config.routes.binders.root);
        return;
      }

      return [
        {
          name: 'binder-management',
          label: utils.string.t('binders.coverholders.title'),
          link: config.routes.binders.coverholders,
        },
        {
          name: binderType,
          label: facility?.operationalClientName || utils.string.t('binders.coverholders.title'),
          link: `${config.routes.binders.coverholder}/${binderId}`,
        },
        {
          name: 'facility',
          label: facility?.umr || utils.string.t('binders.facility'),
          link: `${config.routes.binders.coverholder}/${binderId}/${config.routes.binders.facilityDetails}/${facility?.umr}`,
          active: true,
        },
      ];
    default:
      return {
        root: config.routes.binders.root,
        binder: config.routes.binders.root,
        facility: config.routes.binders.root,
      };
  }
};
