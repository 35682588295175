import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification } from 'stores';
import { FACILITY_GROUPS } from 'lib/quoteBind/queryKeys';

export const useAddFacilityGroup = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.assignFacilityGroup',
      message: 'Data missing for assign to facility group.',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.quoteBind.admin.facilityGroupAssign.fail', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(FACILITY_GROUPS);

    dispatch(enqueueNotification('notification.quoteBind.admin.facilityGroupAssign.success', 'success'));
  };

  return useMutation(
    async (data) => {
      return await quoteBindAxios.post(`facilities/groups`, data);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
