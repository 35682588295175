import { useMutation } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { file } from 'utils';

export const useDownloadEndorsement = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useDownloadEndorsement',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = ({ data, fileName }) => {
    file.fileSaver(data, fileName);
  };

  return useMutation(
    async ({ id }) => {
      if (!id) {
        throw new Error('Invalid ID');
      }

      const { headers, data } = await quoteBindAxios.get(`endorsements/${id}/documents`, {
        responseType: 'blob',
        timeout: 30000,
      });

      const fileName = utils.file.getFilenameFromHeadersBlob(headers, data, '');

      return { data, fileName };
    },
    {
      onError: (err) => handleError(err),
      onSuccess: (res) => handleSuccess(res),
    }
  );
};
