import React from 'react';
import PropTypes from 'prop-types';

// app
import styles from './SaveBar.styles';

// mui
import { makeStyles, Slide } from '@material-ui/core';

SaveBar.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

SaveBar.defaultProps = {
  show: false,
};

export default function SaveBar({ show, children }) {
  const classes = makeStyles(styles, { name: 'SaveBar' })();
  return (
    <Slide direction="up" in={show} mountOnEnter unmountOnExit>
      <div className={classes.root}>{children}</div>
    </Slide>
  );
}
