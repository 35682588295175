import { makeStyles } from '@material-ui/core';

export const useFacilityDetailsStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      width: '100%',
      zIndex: 100,
      position: 'relative',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 100%',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${theme.palette.neutral.light}`,
      boxShadow: '2px 2px 4px #dadada',
    },
    content: {
      width: 'auto',
      margin: theme.spacing(2.5),
    },
    header: {
      borderBottom: `1px solid ${theme.palette.neutral.light}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      margin: 0,
      transition: theme.transitions.create('margin'),
      zIndex: 100,
      backgroundColor: 'white',
    },
    chipList: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
      },
    },
    pageContainer: {
      maxWidth: 1400,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      border: `1px solid ${theme.palette.neutral.light}`,
      borderTop: 0,
      '& .MuiGrid-spacing-xs-4': {
        width: '100%',
      },
    },
    avatar: {
      padding: '0.1rem',
      color: `${theme.palette.primary.main} !important`,
    },
    sectionHeader: {
      marginBottom: `${theme.spacing(0)}px!important`,
    },
    searchBox: {
      marginBottom: theme.spacing(2),
    },
    headerContent: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    button: {
      marginLeft: theme.spacing(1.5),
    },
    infoAvatar: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    facilityUsers: {
      cursor: 'pointer',
    },
    facilityUsersAvatar: {
      cursor: 'pointer!important',
    },
    detailsAvatar: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '1rem',
    },
    activeBtn: {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    facilityIcon: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    riskLink: {
      textDecoration: 'none',
    },
    info: {
      margin: theme.spacing(3),
      flex: '1 1 auto',
      display: 'flex',
      maxWidth: '100%',
      paddingBottom: '0.5rem',
    },
    facilityDetails: {
      display: 'inline-flex',
      justifyContent: 'center',
      padding: '0.5rem 0 0.5rem 0',
      flexWrap: 'nowrap',
      boxShadow: '0px 4px 4px  #dadada',
      border: '0.2px solid #dadada',
      borderRadius: '3px',
      width: '-webkit-fill-available',
      margin: '1rem 1.5rem 1rem 1.5rem',
    },
    divider: {
      margin: 0,
    },
    marketChip: {
      backgroundColor: '#334762',
      color: '#FFFFFF',
      padding: '0.25rem',
      fontSize: '0.7rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },

    facilityCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    facilityCardStatus: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1, 1, 0),
      border: 'none!important',
    },
    facilityBinders: {
      flex: 1,
      width: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    facilityBindersContainer: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
    },
    facilityBindersWrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      boxShadow: theme.shadows[2],
      borderRadius: 3,
      overflow: 'hidden',
      minHeight: '3.5rem',
      '& > *': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        '&:first-child': {
          borderLeft: 0,
        },
      },
    },
    facilityBindersInfoText: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minWidth: 90,
    },
    facilityBindersInfo: {
      textDecoration: 'none',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      minWidth: 90,
      '&::after': {
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: `${theme.palette.grey[100]}44`,
      },
      '&:hover::after': {
        content: '""',
      },
    },
    facilityBindersImage: {
      width: 'auto',
      maxWidth: '6rem',
      maxHeight: '3rem',
      padding: theme.spacing(0.25, 1),
    },
    facilityBindersName: {
      margin: 0,
      fontWeight: 600,
      minWidth: '6rem',
      padding: '0 0.5rem',
      height: '3.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    cardHeaderLogo: {
      width: 'auto',
      maxWidth: '8rem',
    },
    cardHeaderContent: {
      display: 'flex',
      margin: theme.spacing(0, 4),
    },
    cardHeaderTitleWrapper: {
      width: 150,
    },
    cardHeaderTitle: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    facilityCardContent: {
      padding: theme.spacing(1),
      width: 300,
    },
    facilityCardWrapper: {
      display: 'flex',
      alignSelf: 'center',
    },
    facilityDetailsWrapper: {
      width: 'auto',
      flex: 1,
    },
    facilityBindersUsers: {
      marginRight: theme.spacing(0.5),
    },
    facilityBindersOptions: {
      marginLeft: 'auto',
      width: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    facilityConfig: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '3.5rem',
      padding: theme.spacing(0, 1),
    },
    facilityConfigLabel: {
      borderLeft: 0,
      margin: 0,
      fontWeight: 600,
      marginLeft: theme.spacing(0.5),
    },
    facilityConfigLoading: {
      padding: 0,
      cursor: 'default',
    },
    iconLabelWrapper: {
      flexDirection: 'row-reverse',
    },
    icon: {
      color: 'mediumvioletred',
      fontSize: '1.1rem',
    },
    disabled: {
      opacity: 0.5,
      backgroundColor: `${theme.palette.grey[100]}!important`,
    },
    limitAlertIconWrapper: {
      display: 'flex',
    },
    limitAlertIcon: {
      position: 'relative',
      left: '0.3rem',
    },
  }),
  { name: 'FacilityDetails' }
);
