import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Grid, Zoom } from '@material-ui/core';

import { FormAutocompleteMui, Tooltip } from 'components';
import * as utils from 'utils';
import { ReactComponent as PrivacyTip } from 'assets/svg/privacy_tip.svg';

const MIN_LABEL_SIZE = 20;

export const FieldItem = ({ methods, classes, item, list, matchedFields }) => {
  const { control } = methods;
  const value = useWatch({ control, name: item.name });

  const getDefaultStyles = (field) => {
    if (methods.formState.errors[item.name]) {
      return {};
    }

    if (value && value.mapperField === field.defaultValue) {
      if (field.matchType === 'REAL') {
        return {
          InputProps: {
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          },
        };
      } else if (field.matchType === 'FUZZY') {
        return {
          InputProps: {
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.fuzzyNotchedOutline,
            },
          },
        };
      }
    }
    return {};
  };

  const options = useMemo(() => {
    return item.options.map((option) => {
      return {
        ...option,
        isMapped: matchedFields.includes(option.mapperField) ? 'Mapped' : 'Unmapped',
      };
    });
  }, [item.options, matchedFields]);

  const Label = () => {
    if (item.sanctionField) {
      return (
        <div className={`${classes.autoCompleteLabel} ${classes.sanctionsMandatoryField}`}>
          {item.label.length > MIN_LABEL_SIZE ? (
            <Tooltip title={item.label} placement="top-start" TransitionComponent={Zoom} block style={{ overflow: 'hidden' }}>
              <span>{item.label}</span>
            </Tooltip>
          ) : (
            <span className={classes.sanctionsTooltip}>{item.label}</span>
          )}

          <Tooltip title={utils.string.t('bdx.sanctionRequired')} placement="top-start" TransitionComponent={Zoom} block>
            <div className={classes.helpIcon}>
              <PrivacyTip />
            </div>
          </Tooltip>
        </div>
      );
    }

    if (item.label && item.label.length > MIN_LABEL_SIZE) {
      return (
        <Tooltip title={item.label} placement="top-start" TransitionComponent={Zoom} block>
          <div className={classes.autoCompleteLabel}>{item.label}</div>
        </Tooltip>
      );
    }

    return <div className={classes.autoCompleteLabel}>{item.label}</div>;
  };

  return (
    <Grid item xs={3}>
      <FormAutocompleteMui
        labelComp={<Label />}
        {...utils.form.getFieldProps(list, item.name)}
        getOptionDisabled={(option) => option.isMapped === 'Mapped'}
        options={options.sort(utils.sort.array('lexical', 'isMapped', 'desc'))}
        groupBy="isMapped"
        muiComponentProps={{
          ...getDefaultStyles(item),
        }}
        control={methods.control}
        rules={item?.required ? { required: 'Required' } : {}}
        error={methods.formState.errors[item.name]}
      />
    </Grid>
  );
};
