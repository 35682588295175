import { useState } from 'react';

import { Box, Button, ButtonBase, IconButton, makeStyles, Popper, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { DateRange, Translate } from 'components';

import config from 'config';
import * as utils from 'utils';

import { styles } from './FilterDateRange.styles';

export const FilterDateRange = ({ handleOnChange, selectionKey, title, selection }) => {
  const classes = makeStyles(styles, { name: 'FilterDateRange' })();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectionRange, setSelectionRange] = useState(selection);

  const handleDateRangeChange = ({ selection }) => {
    setSelectionRange(selection);
  };

  const handleClearDateRange = () => {
    setSelectionRange(null);
    handleOnChange(null, selectionKey);
  };

  const handleApply = () => {
    handleOnChange(selectionRange, selectionKey);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'calendar-popper' : undefined;

  return (
    <>
      <ButtonBase
        data-testid="button-filter-date-range"
        disableRipple
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Typography variant="body2">{title}</Typography>
        <DateRangeIcon />
      </ButtonBase>
      <Box className={classes.selectedValue}>
        {selectionRange?.startDate && selectionRange?.endDate ? (
          <>
            <Translate
              label="format.date"
              options={{ value: { date: selectionRange.startDate, format: config.ui.format.date.text, default: '-' } }}
            />
            {utils.date.formatDateWithParameter(selectionRange.endDate) !== utils.date.formatDateWithParameter(selectionRange.startDate) ? (
              <>
                {` - `}
                <Translate
                  label="format.date"
                  options={{ value: { date: selectionRange.endDate, format: config.ui.format.date.text, default: '-' } }}
                />
              </>
            ) : null}
            <Box display="flex" flex="1" justifyContent="flex-end">
              <span data-testid="filter-data-range-clear" onClick={handleClearDateRange} className={classes.clearButton}>
                {utils.string.t('products.filter.clear')}
              </span>
            </Box>
          </>
        ) : (
          <span>{utils.string.t('products.filter.selectDateRange')}</span>
        )}
      </Box>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" className={classes.popper}>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 2 }}>
            <Typography variant="subtitle1" classes={{ root: classes.calendarTitle }}>
              {title}
            </Typography>
            <IconButton data-testid="close-date-filter-range" onClick={handleClick} style={{ padding: 0 }}>
              <HighlightOffIcon width={20} height={20} style={{ color: 'white' }} />
            </IconButton>
          </Box>
          <Box className={classes.paper}>
            <DateRange selected={selection} handleOnChange={handleDateRangeChange} />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center" classes={{ root: classes.buttonBox }}>
            <Button onClick={handleClick} size="small" color="default" variant="outlined" style={{ marginRight: 10 }}>
              {utils.string.t('products.filter.close')}
            </Button>
            <Button onClick={handleApply} size="small" color="primary" variant="contained">
              {utils.string.t('products.filter.apply')}
            </Button>
          </Box>
        </Box>
      </Popper>
    </>
  );
};
