import { useState } from 'react';

import { UPLOAD_RULES_STEPS } from 'consts';

import { UploadRulesView } from './UploadRules.view';

export const UploadRules = ({ bdxType, facility, thirdPartyId }: IUploadRules) => {
  const [activeStep, setActiveStep] = useState(UPLOAD_RULES_STEPS.CONFIRM_UPLOAD);

  return (
    <UploadRulesView
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      bdxType={bdxType}
      facility={facility}
      thirdPartyId={thirdPartyId}
    />
  );
};
