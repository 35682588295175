import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import * as utils from 'utils';

const cleanConfigFeatures = (config) => {
  Object.keys(config.features || {}).forEach((key) => {
    if (config.features[key] === true || config.features[key] === 'true') {
      config.features[key] = true;
    } else {
      config.features[key] = false;
    }
  });
  return config;
};

export const ConfigContext =
  process.env.NODE_ENV === 'test' ? createContext(cleanConfigFeatures(require('../../public/config/config.json'))) : createContext({});

export const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  const loadConfig = useCallback(async () => {
    const config = await utils.app.getConfig();
    setConfig(cleanConfigFeatures(config));
  }, []);

  const setConfigValue = useCallback((key, value) => {
    setConfig((config) => {
      const result = { ...config };

      const path = key.split('.');

      const lastProp = path.pop();
      const obj = path.reduce((acc, val) => acc && acc[val], result);

      if (obj) {
        obj[lastProp] = value;
      }

      return cleanConfigFeatures(result);
    });
  }, []);

  const printConfig = useCallback(() => {
    console.log(config);
  }, [config]);

  useEffect(() => {
    loadConfig();

    window.setConfig = setConfigValue;
  }, [loadConfig, setConfigValue]);

  useEffect(() => {
    window.printConfig = printConfig;
  }, [printConfig]);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => useContext(ConfigContext);
