import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_LIMITSETS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

const getLimits = async (params) => {
  const result = await binderManagementDroolsAxios.get('/limits', {
    params,
  });

  return result?.data;
};

export const useGetLimitSets = (params = {}, options = {}) => {
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetLimitSets' });

  return useQuery([DROOLS_LIMITSETS, params], async () => getLimits(params), {
    onError: (err) => handleError(err),
    cacheTime: 60 * 60 * 1000,
    staleTime: 10 * 1000,
    ...options,
  });
};
