export const FACILITIES = 'FACILITIES' as const;
export const REPORTS = 'REPORTS' as const;
export const DOCUMENTS = 'DOCUMENTS' as const;
export const BORDEREAUX = 'BORDEREAUX' as const;
export const COVERHOLDERS = 'COVERHOLDERS' as const;
export const MARKETS = 'MARKETS' as const;
export const RULES = 'RULES' as const;
export const BINDERS = 'BINDERS' as const;
export const LINEAGE = 'LINEAGE' as const;
export const BINDER_LIMITS = 'BINDER LIMITS' as const;
export const FACILITY_USERS_UNDERWRITERS = 'FACILITY_USERS_UNDERWRITERS' as const;
export const FACILITY_USERS_PRODUCERS = 'FACILITY_USERS_PRODUCERS' as const;
export const OVERVIEW_REPORT = 'OVERVIEW_REPORT' as const;
export const BULK_BDX_UPLOADS = 'BULK_BDX_UPLOADS' as const;
export const DRL_FILE_EXTENSION = '.drl' as const;

// BDX UPLOAD STATUS
export const PUBLISHED = 'PUBLISHED' as const;
export const PROCESSING = 'PROCESSING' as const;
export const PUBLISHING = 'PUBLISHING' as const;
export const BLOCKED = 'BLOCKED' as const;
export const PROCESSED = 'PROCESSED' as const;
export const UPLOADED = 'UPLOADED' as const;
export const QUEUED = 'QUEUED' as const;
export const PENDING_SANCTIONS = 'PENDING_SANCTIONS' as const;
export const UNPUBLISHING = 'UNPUBLISHING' as const;

// Tabs
export const TAB = 'tab' as const;
export const TAB_REPORTS = 'reports' as const;
export const TAB_BDX = 'bdx' as const;
export const TAB_DOCUMENTS = 'documents' as const;
export const TAB_RULES = 'rules' as const;
export const TAB_FACILITIES = 'facilities' as const;
export const TAB_BINDER_LIMITS = 'binderLimits' as const;
export const TAB_BULK_UPLOADS = 'bulkUploads' as const;

// WEBSOCKET STATUS
export const INVACTIVE = 'INVACTIVE' as const;
export const CLOSED = 'CLOSED' as const;

export const BDX_TYPE_CLAIM = 'Claim' as const;
export const BDX_TYPE_PREMIUM = 'Premium' as const;
export const BDX_TYPE_RLD = 'RLD' as const;

export const BDX_AGGREGATE_TYPE_CLAIM = 'claims' as const;
export const BDX_AGGREGATE_TYPE_PREMIUM = 'premium' as const;

export const BINDER_SORT_BY_OPTIONS = {
  COVERHOLDERS: [
    { sortBy: 'coverHolderName', sortDirection: 'asc', label: 'Title A-Z' },
    { sortBy: 'coverHolderName', sortDirection: 'desc', label: 'Title Z-A' },
  ],
  MARKETS: [
    { sortBy: 'marketName', sortDirection: 'asc', label: 'Title A-Z' },
    { sortBy: 'marketName', sortDirection: 'desc', label: 'Title Z-A' },
  ],
} as const;

export const REPORT_SORT_BY_OPTIONS = [
  { sortBy: 'title', sortDirection: 'asc', label: 'Title' },
  { sortBy: 'createdDate', sortDirection: 'desc', label: 'Created date - Newest' },
  { sortBy: 'createdDate', sortDirection: 'asc', label: 'Created date - Oldest ' },
] as const;

export const FACILITY_SORT_BY_OPTIONS = [
  { sortBy: 'umr', sortDirection: 'desc', label: 'UMR' },
  { sortBy: 'product', sortDirection: 'asc', label: 'Product A-Z' },
  { sortBy: 'product', sortDirection: 'desc', label: 'Product Z-A' },
  { sortBy: 'inceptionDate', sortDirection: 'desc', label: 'Inception date - Newest' },
  { sortBy: 'inceptionDate', sortDirection: 'asc', label: 'Inception date - Oldest ' },
  { sortBy: 'expiryDate', sortDirection: 'desc', label: 'Expiry date - Newest' },
  { sortBy: 'expiryDate', sortDirection: 'asc', label: 'Expiry date - Oldest ' },
] as const;

export const BINDER_FILTER_OPTIONS = [
  {
    title: 'Name',
    key: 'names',
    type: 'autocomplete',
  },
  {
    title: 'Brand',
    key: 'brands',
    type: 'autocomplete',
  },
] as const;

export const FACILITY_FILTER_OPTIONS = [
  {
    title: 'UMR',
    key: 'umrs',
    type: 'autocomplete',
  },
  {
    title: 'Brand',
    key: 'brandIds',
    type: 'autocomplete',
  },
  {
    title: 'Status',
    key: 'statuses',
    type: 'autocomplete',
  },
  {
    title: 'GXB Instance',
    key: 'gxbInstanceIds',
    type: 'autocomplete',
  },
  {
    title: 'Team',
    key: 'teams',
    type: 'autocomplete',
  },
  {
    title: 'Products',
    key: 'products',
    type: 'autocomplete',
  },
  {
    title: 'Inception Date',
    key: 'inceptionDate',
    type: 'Date',
  },
] as const;

export const REPORT_FILTER_OPTIONS = [
  {
    title: 'Created Date',
    key: 'createdDate',
    type: 'Date',
  },
  {
    title: 'Created By',
    key: 'createdBy',
    type: 'autocomplete',
  },
] as const;

export const DOCUMENT_FILTER_OPTIONS = [
  {
    title: 'Created Date',
    key: 'createdDate',
    type: 'Date',
  },
  {
    title: 'Created By',
    key: 'createdBy',
    type: 'autocomplete',
    options: ['Mandy Moore', 'Matt Damon'],
  },
] as const;

export const BORDEREAUX_FILTER_OPTIONS = [
  {
    title: 'Created Date',
    key: 'createdDate',
    type: 'Date',
  },
  {
    title: 'Created By',
    key: 'createdBy',
    type: 'autocomplete',
  },
] as const;

export const UPLOAD_RULES = 'UPLOAD_RULES' as const;
export const UPLOAD_RULES_STEPS = {
  CONFIRM_UPLOAD: 0,
  UPLOAD_RULES: 1,
} as const;

export const COVERHOLDER_FILTER_OPTIONS = {
  FACILITIES: FACILITY_FILTER_OPTIONS,
  DOCUMENTS: DOCUMENT_FILTER_OPTIONS,
  REPORTS: REPORT_FILTER_OPTIONS,
  BULK_BDX_UPLOADS: [],
} as const;

export const FACILITY_DETAILS_FILTER_OPTIONS = {
  BORDEREAUX: BORDEREAUX_FILTER_OPTIONS,
  DOCUMENTS: DOCUMENT_FILTER_OPTIONS,
  REPORTS: REPORT_FILTER_OPTIONS,
} as const;

export const COVERHOLDER_SORT_OPTIONS = {
  FACILITIES: FACILITY_SORT_BY_OPTIONS,
  DOCUMENTS: REPORT_SORT_BY_OPTIONS,
  REPORTS: REPORT_SORT_BY_OPTIONS,
} as const;

export const UPLOAD_BDX = 'UPLOAD_BDX' as const;

export const UPLOAD_BDX_STEPS = {
  UPLOAD_FILE: 0,
  FIELD_MAPPING: 1,
  SUMMARY: 2,
} as const;

export const SUMMARY_ACCORDIONS = {
  EXCEPTIONS: 'EXCEPTIONS',
} as const;

export const URL_BDX = 'requestId' as const;

export const POWER_BI_MODEL = {
  BackgroundType: {
    Default: 0,
    Transparent: 1,
  },
  TokenType: {
    Add: 0,
    Embed: 1,
  },
  DisplayOption: {
    ActualSize: 2,
    FitToPage: 0,
    FitToWidth: 1,
  },
} as const;

// SUBMISSION STATUS
export const SUBMISSION_STATUS_NEW = 'NEW' as const;
export const SUBMISSION_STATUS_PROCESSING = 'PROCESSING' as const;
export const SUBMISSION_STATUS_PROCESSED = 'PROCESSED' as const;
export const SUBMISSION_STATUS_FAILED = 'FAILED' as const;
export const SUBMISSION_STATUS_PROCESSED_MANUALLY = 'PROCESSED_MANUALLY' as const;
export const SUBMISSION_STATUS_NO_CONTENT = 'No Content' as const;

export const SUBMISSION_STATUS = [
  {
    statusCode: SUBMISSION_STATUS_NEW,
    status: '',
    text: 'New',
  },
  {
    statusCode: SUBMISSION_STATUS_PROCESSING,
    status: 'info',
    text: 'Processing',
  },
  {
    statusCode: SUBMISSION_STATUS_PROCESSED,
    status: 'success',
    text: 'Processed',
  },

  {
    statusCode: SUBMISSION_STATUS_FAILED,
    status: 'error',
    text: 'Not-Processed',
  },
  {
    statusCode: SUBMISSION_STATUS_PROCESSED_MANUALLY,
    status: 'success',
    text: 'Processed Manually',
  },
] as const;

// BINDER LIMITS
export const ADD_LIMIT = 'ADD_LIMIT' as const;
export const EDIT_LIMIT = 'EDIT_LIMIT' as const;
export const DELETE_LIMIT = 'DELETE_LIMIT' as const;
export const FAVOURITE_LIMIT = 'FAVOURITE_LIMIT' as const;
export const UNFAVOURITE_LIMIT = 'UNFAVOURITE_LIMIT' as const;
export const IMPORT_LIMITS = 'IMPORT_LIMITS' as const;
export type TBinderLimitAction =
  | typeof ADD_LIMIT
  | typeof EDIT_LIMIT
  | typeof DELETE_LIMIT
  | typeof FAVOURITE_LIMIT
  | typeof UNFAVOURITE_LIMIT
  | typeof IMPORT_LIMITS;

// LINEAGE REPORT
export const UPLOAD_LINEAGE_REPORT = 'UPLOAD_LINEAGE_REPORT' as const;

export const LINEAGE_STATUS_BACKGROUND: Record<TLineageEntitiesByMonthStatus, string> = {
  PROCESSING: '#4fb2ce',
  PENDING: '#757575',
  QUEUED: '#757575',
  PROCESSED: '#58dcc4',
  FAILED: '#D11828',
  NEW: '#818181',
  NOTPROCESSED: '#e93d4c',
} as const;

export const LINEAGE_PROCESSING_BLACK_LIST = ['requestId', 'deductions', 'errorMessage', 'underwriterGroupId'];
