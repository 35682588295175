import { makeStyles } from '@material-ui/core';

export const useBDXProcessingStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    flex: 1,
    margin: theme.spacing(3),
  },
  header: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    color: `${theme.palette.neutral.darker}!important`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  title: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    lineHeight: '1.2em',
  },
  refreshButton: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
  },
}));
