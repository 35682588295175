import { useMutation } from 'react-query';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useUploadBDXDetails = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useUploadBDXDetails',
    warning: 'notification.bdx.uploadFail',
  });

  return useMutation(
    async ({
      isPreview,
      facility,
      requestId,
      sheets,
      yearMonthSubmission,
      password,
      bdxType,
      riskCodes,
      coverholder,
      facilityUMR,
      thirdPartyId,
      replacesRequest,
      cumulative,
    }) => {
      const form = new FormData();
      form.append('isPreview', isPreview);
      form.append('requestId', requestId);
      form.append('sheetNames', JSON.stringify(sheets));
      form.append('yearMonthSubmission', yearMonthSubmission);
      form.append('password', password);
      form.append('bdxType', bdxType);
      form.append('riskCodes', riskCodes);
      form.append('coverHolderId', facility ? facility.coverHolderId : coverholder);
      form.append('facility', facility ? facility.umr : facilityUMR);
      form.append('thirdPartyId', thirdPartyId);
      form.append('replacesRequest', replacesRequest);
      form.append('cumulative', cumulative);

      const data = await binderManagementFileInputAxios.post('details', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    },
    {
      onError: handleError,
    }
  );
};
