import { useState, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// MUI
import { Box, Tabs, Tab, makeStyles, useTheme, CircularProgress } from '@material-ui/core';
import { Error, Warning, FormatListBulleted, AssignmentTurnedIn } from '@material-ui/icons';

// app
import styles from './BDXExtraction.styles';
import { TabPanel } from 'components';
import { BDXOverview, BDXSummary, BDXExceptions, PremiumProcessing, ClaimProcessing } from './tabs';
import { useConfirmCancelModal } from 'components/Modal/useConfirmCancelModal';
import { useGetExceptionsCount, binderManagementFileInputAxios } from 'lib/binderManagement';
import { enqueueNotification, hideModal, showModal } from 'stores';
import { UPLOAD_BDX } from 'consts/binder-management';
import * as utils from 'utils';
import { BDXExtractionContext, BDXExtractionContextProvider } from './BDXExtraction.context';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { useLocationTabs, useSectionWebSocket } from 'hooks';
import { useConfigContext } from 'config/Config.context';

export const bdxDetails = 'bdxDetails';

const TabLabel = ({ label, warningIcon }) => (
  <Box display="flex" alignContent="center">
    <span>{label}</span>
    {warningIcon}
  </Box>
);

export default function BDXExtraction({ item, bdxInfo, fullScreen, facility, defaultIndex = 0, isLoading }) {
  const classes = makeStyles(styles, { name: 'bdxExtraction' })();
  const dispatch = useDispatch();
  const [isDownload, setDownload] = useState(false);
  useSectionWebSocket(facility, bdxDetails);

  const handleConfirm = () => {
    dispatch(hideModal('UPLOAD_BDX'));
  };

  const confirmCancelHandler = useConfirmCancelModal(handleConfirm);

  const uploadBDX = (yearMonthSubmission, bdxType, file, fileRequestId, callbackOnSuccess, confirmCancel = true) => {
    dispatch(
      showModal({
        type: UPLOAD_BDX,
        component: UPLOAD_BDX,
        props: {
          title: [utils.string.t('documents.upload.bdx.title'), facility.coverHolder, facility.umr].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          disableEscapeKey: true,
          componentProps: {
            facility,
            yearMonthSubmission,
            bdxType,
            file,
            fileRequestId,
            callbackOnSuccess,
            confirmCancel,
            confirmCancelHandler,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX')),
          },
        },
      })
    );
  };

  const handleDownload = async (requestId) => {
    setDownload(true);
    try {
      const { headers, data } = await binderManagementFileInputAxios.get(`submit/${requestId}`, { responseType: 'blob' });
      const fileName = utils.file.getFilenameFromHeadersBlob(headers, data);
      const defaultFileName = [facility.umr, item.bdxType, bdxInfo.yearMonthSubmission].filter(Boolean).join('-');

      utils.file.download(data, `${defaultFileName}-${fileName}`);
    } catch (e) {
      dispatch(enqueueNotification('notification.binderManagement.documentDownloadFail', 'error'));
    }
    setDownload(false);
  };

  const handleDownloadAll = async ({ bdxType, facilityReference, yearMonthSubmission }) => {
    setDownload(true);
    try {
      const url = `submit/${facilityReference}/${yearMonthSubmission}/${bdxType}`;
      const { headers, data } = await binderManagementFileInputAxios.get(url, { responseType: 'blob' });
      const defaultFileName = [facility.umr, item.bdxType, bdxInfo.yearMonthSubmission].filter(Boolean).join('-');
      const documentName = utils.file.getFilenameFromHeadersBlob(headers, data, defaultFileName);

      utils.file.download(data, documentName);
    } catch (e) {
      dispatch(enqueueNotification('notification.binderManagement.documentDownloadFail', 'error'));
    }
    setDownload(false);
  };

  const onTabClick = (_, newValue) => {};

  return (
    <BDXExtractionContextProvider isLoading={isLoading}>
      <Box className={classes.container}>
        <BDXOverview
          facility={facility}
          bdxItem={item}
          bdxDocumentsInfo={{
            bdxType: item.bdxType,
            yearMonthSubmission: bdxInfo.yearMonthSubmission,
            facilityReference: facility?.umr,
          }}
          fullScreen={fullScreen}
          uploadBDX={uploadBDX}
          goToTab={(index) => onTabClick(index)}
          isDownload={isDownload}
          handleDownload={handleDownload}
        />
      </Box>
      {!isLoading && (
        <BDXExtractionContent
          onTabClick={onTabClick}
          item={item}
          bdxInfo={bdxInfo}
          facility={facility}
          fullScreen={fullScreen}
          uploadBDX={uploadBDX}
          isDownload={isDownload}
          handleDownloadAll={handleDownloadAll}
          handleDownload={handleDownload}
          defaultIndex={defaultIndex}
        />
      )}
    </BDXExtractionContextProvider>
  );
}

const BDXExtractionContent = ({
  item,
  bdxInfo,
  facility,
  fullScreen,
  uploadBDX,
  isDownload,
  defaultIndex,
  handleDownload,
  handleDownloadAll,
}) => {
  const theme = useTheme();
  const { activeBDX } = useContext(BDXExtractionContext);
  const config = useConfigContext();

  const bdxDocumentsInfo = {
    facilityReference: facility.umr,
    yearMonthSubmission: bdxInfo.yearMonthSubmission,
    bdxType: item.bdxType,
  };

  const { data: exceptionsCount, isFetching } = useGetExceptionsCount(
    {
      bdxDocumentsInfo,
      requestId: activeBDX.requestId,
    },
    { enabled: !!facility.umr && !!bdxInfo.yearMonthSubmission && !!item.bdxType }
  );

  useEffect(() => {
    handleSelectTab(null, defaultIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBDX.requestId]);

  const TabIcon = ({ WarningIcon, ErrorIcon, value, isError, isWarning, size }) => {
    if (isFetching) {
      return <CircularProgress size={20} />;
    }
    if (isError) {
      return ErrorIcon;
    }

    if (isWarning) {
      return WarningIcon;
    }

    return <CheckCircle style={{ color: theme.palette.success.main, width: size, height: size }} />;
  };

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: 'summary',
        label: 'Summary',
        tabLabel: <TabLabel label="Summary" />,
        value: 'summary',
        tabIcon: <FormatListBulleted style={{ color: '#67bb67' }} />,
        color: '#67bb67',
        content: BDXSummary,
      },
      {
        id: 'validations',
        tabLabel: (
          <TabLabel
            label="Validation"
            warningIcon={
              <TabIcon
                size={20}
                WarningIcon={<Warning style={{ color: '#ff9800', width: 20, height: 20 }} />}
                isWarning={exceptionsCount?.validationWarnings !== '0'}
                isError={exceptionsCount?.validationErrors !== '0'}
                ErrorIcon={<Error style={{ color: '#ef5350', width: 20, height: 20 }} />}
              />
            }
          />
        ),
        label: 'Validation',
        value: 'validations',
        tabIcon: (
          <TabIcon
            WarningIcon={<Error style={{ color: '#ff9800' }} />}
            isWarning={exceptionsCount?.validationWarnings !== '0'}
            isError={exceptionsCount?.validationErrors !== '0'}
            ErrorIcon={<Error style={{ color: '#ef5350' }} />}
          />
        ),
        color: '#ff9800',
        content: BDXExceptions,
      },
      {
        id: 'sanctions',
        tabLabel: (
          <TabLabel
            label="Sanction"
            warning
            warningIcon={
              <TabIcon
                WarningIcon={<Warning style={{ color: '#ff9800', width: 20, height: 20 }} />}
                size={20}
                isWarning={exceptionsCount?.sanctionExceptions !== '0'}
              />
            }
          />
        ),
        label: 'Sanction',
        value: 'sanctions',
        tabIcon: <TabIcon WarningIcon={<Warning style={{ color: '#ff9800' }} />} isWarning={exceptionsCount?.sanctionExceptions !== '0'} />,
        color: '#ff9800',
        content: BDXExceptions,
      },
    ];

    if (config.features?.ENABLE_PREMIUM_CLAIM_SUBMISSION === true && item?.bdxType === 'Premium') {
      tabs.push({
        id: 'premium',
        label: 'Premium',
        tabLabel: <TabLabel label="Premium" />,
        value: 'premium',
        tabIcon: <AssignmentTurnedIn style={{ color: '#67bb67' }} />,
        color: '#67bb67',
        content: PremiumProcessing,
      });
    }

    if (config.features?.ENABLE_PREMIUM_CLAIM_SUBMISSION === true && item?.bdxType === 'Claim') {
      tabs.push({
        id: 'claim',
        label: 'Claim',
        tabLabel: <TabLabel label="Claim" />,
        value: 'claim',
        tabIcon: <AssignmentTurnedIn style={{ color: '#67bb67' }} />,
        color: '#67bb67',
        content: ClaimProcessing,
      });
    }

    return tabs;
  }, [exceptionsCount?.sanctionExceptions, exceptionsCount?.validationErrors, exceptionsCount?.validationWarnings, item?.bdxType, config]);

  const { selectedTab, handleSelectTab } = useLocationTabs(tabs);

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleSelectTab} aria-label="">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.tabLabel} disabled={tab?.disabled || !activeBDX} value={tab.value} />
        ))}
      </Tabs>

      {tabs.map((tab) => (
        <TabPanel key={tab.value} value={selectedTab} id={tab.id}>
          <tab.content
            bdxItem={item}
            bdxDocumentsInfo={{
              bdxType: item.bdxType,
              yearMonthSubmission: bdxInfo.yearMonthSubmission,
              facilityReference: facility.umr,
            }}
            fullScreen={fullScreen}
            uploadBDX={uploadBDX}
            goToTab={(index) => handleSelectTab(index)}
            isDownload={isDownload}
            handleDownload={handleDownload}
            handleDownloadAll={handleDownloadAll}
            tabId={`${tab.value}-content`}
            tab={tab}
            errors={exceptionsCount?.validationErrors !== '0'}
            warnings={exceptionsCount?.validationWarnings !== '0'}
            sanctions={exceptionsCount?.sanctionExceptions !== '0'}
          />
        </TabPanel>
      ))}
    </Box>
  );
};
