import { Grid, makeStyles, Typography } from '@material-ui/core';

import * as utils from 'utils';

import { RuleLine } from '../RuleLine';

import { parseDatetimeValue } from './RulePreview.utils';

import styles from './RulePreview.styles';

const CONDITION_TYPE_MAP = {
  ALL: 'all of',
  ANY: 'any of',
};

const isDuplicate = 'is duplicate';

const caseSensitivityList = [
  'equal to',
  'not equal to',
  'contains',
  'starts with',
  'ends in',
  'equal to column',
  'not equal to column',
  'does not contain',
];

export const ConditionsPreview = ({ conditionType, conditions }) => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();

  const rowIsDuplicate = conditions.some((c) => c.operation === isDuplicate);
  const conditionsFiltered = conditions.filter((condition) => condition.operation !== isDuplicate);

  const isDuplicateOnlyText = rowIsDuplicate && conditions.length === 1 && (
    <Typography variant="subtitle1" className={classes.conditionType}>
      {utils.string.t('rules.rowsAreDuplicated')}
    </Typography>
  );

  const isDuplicateMixedText = rowIsDuplicate && conditions.length > 1 && (
    <>
      <Typography variant="subtitle1" className={classes.conditionType}>
        {utils.string.t('rules.rowsAreDuplicated')}
      </Typography>
      <Typography variant="subtitle1">{utils.string.t('rules.and')}</Typography>
      <Typography variant="subtitle1" className={classes.conditionType}>
        {CONDITION_TYPE_MAP[conditionType]}
      </Typography>
      <Typography variant="subtitle1">{utils.string.t('rules.conditionStatement')}</Typography>
    </>
  );

  const isNotDuplicate = (
    <>
      <Typography variant="subtitle1" className={classes.conditionType}>
        {CONDITION_TYPE_MAP[conditionType]}
      </Typography>
      <Typography variant="subtitle1">{utils.string.t('rules.conditionStatement')}</Typography>
    </>
  );

  const rulesText = (() => {
    if (rowIsDuplicate && conditions.length === 1) {
      return isDuplicateOnlyText;
    }

    if (rowIsDuplicate && conditions.length > 1) {
      return isDuplicateMixedText;
    }

    return isNotDuplicate;
  })();

  return (
    <Grid container className={classes.previewContainer}>
      <Grid item className={classes.previewHeader}>
        <Typography variant="subtitle1">{utils.string.t('rules.if')}</Typography>
        {rulesText}
      </Grid>
      {conditionsFiltered?.map((condition, index) => (
        <ConditionPreview key={index} condition={condition} />
      ))}
    </Grid>
  );
};

const ConditionPreview = ({ condition }) => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();
  const caseSensitivity =
    condition.caseInsensitive === false ? utils.string.t('rules.caseIsSensitive') : utils.string.t('rules.caseisInsensitive');
  return (
    <Grid container className={classes.operation}>
      <Grid item className={classes.ruleLineContainer}>
        <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.operationDescription}>
          <span className={classes.operationText}>{condition.field}</span>
          <span className={classes.operationTextBold}>{condition.operation}</span>
          {condition.value1 && <span className={classes.operationText}>{parseDatetimeValue(condition.value1)}</span>}
          {condition.value2 && (
            <span className={classes.operationText}>{`${utils.string.t('rules.and')} ${parseDatetimeValue(
              condition.value2
            ).toLowerCase()}`}</span>
          )}
          {caseSensitivityList.includes(condition.operation) && <span className={classes.operationText}>{`(${caseSensitivity})`}</span>}

          {condition.column && <span className={classes.operationText}>{condition.column}</span>}
          {condition.values && <span className={classes.operationText}>{condition.values.join(', ')}</span>}
        </Typography>
      </Grid>
    </Grid>
  );
};
