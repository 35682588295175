import { FormText, TableCell } from 'components';
import * as Yup from 'yup';
import * as utils from 'utils';
import { binderLimitStyles } from './BinderLimit.styles';

const getField = (fieldName, value) => [
  {
    name: fieldName,
    type: typeof value,
    validation: Yup.string().required(utils.string.t('validation.required')),
  },
];

export const QuickEdit = ({ fieldName, value, methods }) => {
  const classes = binderLimitStyles();

  return (
    <TableCell data-testid="quick-edit-table-cell" className={classes.tableCellOverride}>
      <FormText
        {...utils.form.getFieldProps(getField(fieldName, value), fieldName)}
        control={methods.control}
        error={methods.formState.errors[fieldName]}
        defaultValue={value}
        value={value}
        rules={{ required: 'Required' }}
        data-testid="quick-edit-form-text"
      />
    </TableCell>
  );
};
