import React from 'react';

import { CircularProgress, Divider, IconButton, InputAdornment } from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import { Button, FormText } from 'components';
import * as utils from 'utils';
import { useValidateFilePasswordClasses } from './ValidateFilePassword.style';

export const ValidateFilePasswordView = ({ isLoading, fields, onSubmit, methods, showPassword, setShowPassword }) => {
  const classes = useValidateFilePasswordClasses();

  return isLoading ? (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className={classes.root}>
        <FormText
          {...utils.form.getFieldProps(fields, 'password')}
          type={showPassword ? 'text' : 'password'}
          control={methods.control}
          muiComponentProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((showPassword) => !showPassword)}
                    edge="end"
                    className={classes.iconButton}
                  >
                    {showPassword ? <VisibilityOff className={classes.icon} /> : <Visibility className={classes.icon} />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </div>
      <Divider />
      <div className={classes.footer}>
        <Button
          text={utils.string.t('app.submit')}
          type="submit"
          disabled={methods.formState.isSubmitting}
          color="primary"
          onClick={onSubmit}
        />
      </div>
    </>
  );
};
