import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification } from 'stores';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useProcessAggregateManually = ({ facilityReference, yearMonthSubmission, type }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useProcessAggregateManually',
    warning: 'notification.binderManagement.useProcessAggregateManually.error',
  });

  const handleSuccess = ({ data }) => {
    queryClient.setQueryData([BDX_AGGREGATES, type, facilityReference, yearMonthSubmission], (oldData) =>
      oldData?.map((item) => {
        if (item.requestId === data.requestId) {
          return data;
        }
        return item;
      })
    );

    dispatch(enqueueNotification('notification.binderManagement.useProcessAggregateManually.success', 'success'));
  };

  return useMutation(
    async ({ requestId }) => {
      if (!requestId) throw new Error('Missing required params');

      const { data } = await binderManagementBDXAxios.post(`aggregate/${type}/${requestId}/process`, {
        requestId,
      });

      return {
        data,
      };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
