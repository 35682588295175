const styles = (theme) => ({
  labelWithComponent: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  labelIndent: {
    paddingLeft: theme.spacing(2),
  },
  labelIndentExtra: {
    paddingLeft: theme.spacing(6),
  },
  subCell: {
    background: theme.palette.grey[50],
  },
  subCellHeader: {
    background: theme.palette.grey[100],
  },
});

export default styles;
