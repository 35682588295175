import { useApi } from 'hooks';
import { locationAxios } from 'lib/quoteBind/quoteBind.api';

async function getStates(states) {
  const response = states ? await locationAxios.post(`usStates`) : await locationAxios.get(`usStates`);

  return response.data?.data || [];
}

export function useGetStates(queryKey, params, options) {
  return useApi([queryKey], async (params) => await getStates(params), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
