import { UPLOAD_RULES_STEPS } from 'consts';

import { ConfirmRulesUpload } from './ConfirmRulesUpload';
import { RulesUploader } from './RulesUploader';

import { useUploadRulesStyles } from './UploadRules.styles';

export const UploadRulesView = ({ activeStep, setActiveStep, bdxType, facility, thirdPartyId }: IUploadRulesViewProps) => {
  const classes = useUploadRulesStyles();
  const getContent = () => {
    switch (activeStep) {
      case UPLOAD_RULES_STEPS.CONFIRM_UPLOAD:
        return <ConfirmRulesUpload setActiveStep={setActiveStep} classes={classes} />;

      case UPLOAD_RULES_STEPS.UPLOAD_RULES:
        return <RulesUploader bdxType={bdxType} facility={facility} thirdPartyId={thirdPartyId} classes={classes} />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>{getContent()}</div>
    </div>
  );
};
