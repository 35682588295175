import classnames from 'classnames';

// MUI
import { CircularProgress } from '@material-ui/core';

import { BDXDragDrop } from 'components';

import * as utils from 'utils';

import { UploadBDXActions, UploadBDXContent, UploadBDXError } from '../components';

import { getBdxCoverHolderFields, getBdxFields } from './UploadFile.utils';

// app
import { useFileUploadStyles } from './UploadFile.styles';

export function UploadFileView({
  file,
  setFile,
  methods,
  fullScreen,
  isUploadingBDXFile,
  dragDropRef,
  sanctionsCheckOnly,
  isFileReady,
  isTest,
  isCoverHolderUpload,
  fields,
  sheets,
  bdxTypesOptions,
  bdxTypeDefaultValue,
  setSelectedBDXType,
  handleNext,
  resetOnFileChange,
  handleUploadBDXFile,
  thirdParties,
  config,
  error,
}) {
  const classes = useFileUploadStyles();
  const bdxType = methods.watch('bdxType');

  return (
    <UploadBDXContent
      actions={
        <UploadBDXActions
          isBackDisabled
          isNextDisabled={!isFileReady || error}
          labelNext={utils.string.t('app.next')}
          handleNext={handleNext}
        />
      }
    >
      {error && (
        <div className={classes.root}>
          <UploadBDXError title={utils.string.t('notification.binderManagement.aggregate.submissionCheck.error')} text={error} />
        </div>
      )}
      {!error && (
        <div className={classes.root}>
          {isUploadingBDXFile ? (
            <div className={classes.bdxFileLoader}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classnames({ [classes.uploadFileFullScreen]: fullScreen })} data-testid="uploadfile-grid">
              <BDXDragDrop
                ref={dragDropRef}
                label="BDX File"
                control={methods.control}
                defaultFile={file}
                onChange={(_, file) => {
                  setFile(file);
                  if (file) {
                    handleUploadBDXFile(file);
                  } else {
                    resetOnFileChange();
                  }
                }}
                showUploadPreview
                width="30rem"
                height="15rem"
                name="file"
              />
            </div>
          )}
          {file &&
            isFileReady &&
            (isCoverHolderUpload
              ? getBdxCoverHolderFields(
                  classes,
                  sanctionsCheckOnly,
                  fields,
                  utils,
                  methods,
                  isTest,
                  bdxTypesOptions,
                  bdxTypeDefaultValue,
                  setSelectedBDXType,
                  sheets,
                  bdxType,
                  thirdParties,
                  config,
                  fullScreen
                )
              : getBdxFields(
                  classes,
                  sanctionsCheckOnly,
                  fields,
                  utils,
                  methods,
                  isTest,
                  bdxTypesOptions,
                  bdxTypeDefaultValue,
                  setSelectedBDXType,
                  sheets,
                  bdxType,
                  thirdParties,
                  config,
                  fullScreen
                ))}
        </div>
      )}
    </UploadBDXContent>
  );
}
