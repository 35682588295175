import * as Yup from 'yup';

// app
import { AddEditMarketsView } from './AddEditMarkets.view';
import * as utils from 'utils';
import { useCreateOperationalMarket, useEditOperationalMarket } from 'lib/admin';

export default function AddEditMarkets({ handleClose, market = {} }) {
  const { mutate: createOperationalMarket } = useCreateOperationalMarket();
  const { mutate: editOperationalMarket } = useEditOperationalMarket();

  const handleSubmit = (operationalMarket) => {
    if (market.id) {
      editOperationalMarket({ newMarket: operationalMarket, id: market.id });
    } else {
      createOperationalMarket(operationalMarket);
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const fields = [
    {
      name: 'name',
      label: utils.string.t('admin.form.market.label'),
      type: 'text',
      value: market?.name ? market.name : '',
      innerComponentProps: {
        maxMenuHeight: 120,
        allowEmpty: true,
        'data-testid': 'parent',
      },
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      type: 'file',
      name: 'logo',
      label: utils.string.t('app.file'),
      value: market?.logo ? market.logo : '',
      placeholder: 'Select File ...',
      validation: market.id ? Yup.mixed().nullable() : Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
    },
  ];

  const logo = utils.client.parent.getLogoFilePath(market);

  const actions = [
    {
      name: 'submit',
      label: market.id ? utils.string.t('app.save') : utils.string.t('app.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return <AddEditMarketsView actions={actions} fields={fields} logo={logo} />;
}
