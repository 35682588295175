import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { CircularProgress } from '@material-ui/core';

import { Button, FormSelect, FormText } from 'components';

import { useCreateClaimBlock, useGetFacility, useGetUnderwriterGroups } from 'lib/binderManagement';
import { hideModal } from 'stores';
import * as utils from 'utils';

import { IAddClaimBlockForm, IAddClaimBlockProps } from './AddClaimBlock.types';

import { useAddClaimBlockStyles } from './AddClaimBlock.style';

export const AddClaimBlock: FC<IAddClaimBlockProps> = ({ umr }) => {
  const classes = useAddClaimBlockStyles();
  const dispatch = useDispatch();

  const { data: facilityDetails, isLoading: isLoadingFacility } = useGetFacility({ umr });
  const { data: uwGroups, isLoading: isLoadingUWGroups } = useGetUnderwriterGroups({ facilityReference: umr });
  const { mutateAsync: createClaimBlock, isLoading: isSubmitting } = useCreateClaimBlock({ umr });

  const methods = useForm<IAddClaimBlockForm>({
    mode: 'onChange',
  });

  const fields: FormFields<IAddClaimBlockForm> = useMemo(
    () => [
      {
        id: 'name',
        name: 'name',
        type: 'text',
        label: utils.string.t('form.addClaimBlock.name'),
      },
      {
        id: 'ucr',
        name: 'ucr',
        type: 'text',
        label: utils.string.t('form.addClaimBlock.ucr'),
      },
      {
        id: 'interestType',
        name: 'interestType',
        type: 'select',
        label: utils.string.t('form.addClaimBlock.interestType'),
        options: facilityDetails?.riskCodes ?? [],
        optionKey: 'code',
        optionLabel: 'code',
      },
      {
        id: 'uwGroupId',
        name: 'uwGroupId',
        type: 'select',
        label: utils.string.t('form.addClaimBlock.uwGroup'),
        options:
          uwGroups?.map((group) => ({
            name: [group.groupRef, group.sectionName].filter(Boolean).join(' - '),
            id: group.sectionKey,
          })) ?? [],
        optionKey: 'id',
        optionLabel: 'name',
      },
    ],
    [facilityDetails, uwGroups]
  );

  const handleSubmit = async () => {
    const isFormValid = await methods.trigger();

    if (isFormValid) {
      const { interestType, ucr, uwGroupId, name } = methods.getValues();
      await createClaimBlock({
        id: {
          facilityReference: umr,
          ucr,
        },
        name,
        uwGroupId,
        interestType,
      });

      dispatch(hideModal('ADD_CLAIM_BLOCK'));
    }
  };

  const isLoading = isLoadingFacility || isLoadingUWGroups || isSubmitting;

  const { errors } = methods.formState;

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress data-testid="loader" />
        </div>
      ) : (
        <div className={classes.root}>
          <FormText
            control={methods.control}
            {...utils.form.getFieldProps(fields, 'name')}
            rules={{ required: 'Required' }}
            error={get(errors, `name`)}
          />
          <FormText
            control={methods.control}
            {...utils.form.getFieldProps(fields, 'ucr')}
            rules={{ required: 'Required' }}
            error={get(errors, `ucr`)}
          />
          <FormSelect
            control={methods.control}
            {...utils.form.getFieldProps(fields, 'interestType')}
            rules={{ required: 'Required' }}
            error={get(errors, `interestType`)}
          />
          <FormSelect
            control={methods.control}
            {...utils.form.getFieldProps(fields, 'uwGroupId')}
            rules={{ required: 'Required' }}
            error={get(errors, `uwGroupId`)}
          />

          <div className={classes.submit}>
            <Button text={utils.string.t('app.save')} color="primary" onClick={handleSubmit} />
          </div>
        </div>
      )}
    </FormProvider>
  );
};
