import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

// app
import styles from './ClientContent.styles';
import { SectionHeader } from 'components';
import { ClientCharts } from 'modules';
import { Clients, Markets } from 'modules/PortfolioAnalysis';
import { AlphabeticalFilter } from 'components/AlphabeticalFilter';
import * as utils from 'utils';
import { DEFAULT_CHARACTERS } from 'consts';

// mui
import { makeStyles, Collapse, Box } from '@material-ui/core';

export const ClientContent = (props) => {
  const classes = makeStyles(styles, { name: 'Clients' })();
  const { parent, urlParentId, showByMarket, showByYear, showOfficeSelection, type, pageIcon } = props;

  const pageTitlePlural = utils.string.t(`${type}.title`);
  const pageTitle = utils.string.t(`app.${type}`);

  const [firstLetter, setFirstLetter] = useState('');

  const characters = [...DEFAULT_CHARACTERS, { value: '', label: 'All' }];

  const handleFilterClick = (character) => {
    setFirstLetter(character);
  };

  const SelectionComponent = type === 'market' ? Markets : Clients;

  const isReady =
    parent &&
    get(parent, 'selected.id') &&
    get(parent, 'placementsFetched') &&
    !get(parent, 'loading.selected') &&
    !get(parent, 'loading.placements');

  return (
    <Fragment>
      <Collapse in={!Boolean(urlParentId)} timeout={'auto'}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SectionHeader
            title={pageTitlePlural}
            icon={pageIcon}
            nestedClasses={{ root: classes.header, content: classes.content }}
            testid={`${type}-analysis`}
          />

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <AlphabeticalFilter handleClick={handleFilterClick} selectedCharacter={firstLetter} characters={characters} />
          </Box>
        </Box>
        <SelectionComponent nestedClasses={{ form: classes.selection }} firstLetter={firstLetter} />
      </Collapse>

      {Boolean(urlParentId) && isReady && (
        <ClientCharts
          key={get(parent, 'selected.id')}
          type={type}
          parent={parent}
          pageTitle={pageTitle}
          pageIcon={pageIcon}
          showByMarket={showByMarket}
          showByYear={showByYear}
          showOfficeSelection={showOfficeSelection}
        />
      )}
    </Fragment>
  );
};

export default ClientContent;

ClientContent.propTypes = {
  urlParentId: PropTypes.number,
  type: PropTypes.oneOf(['client', 'market']).isRequired,
};
