import React from 'react';
import PropTypes from 'prop-types';

// app
import { LogoView } from './Logo.view';
import { ReactComponent as LogoEdge } from 'assets/svg/logo-edge-horizontal.svg';

Logo.propTypes = {
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: 20,
};

export default function Logo({ height, className, noLink = false }) {
  // abort

  return <LogoView component={LogoEdge} height={height} noLink={noLink} nestedClasses={className} />;
}
