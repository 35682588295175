import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as FlashWarningSVG } from 'assets/svg/flash-warning.svg';
import { Empty } from 'components';
import { useConfirmCancelModal } from 'components/Modal/useConfirmCancelModal';
import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';

import { UPLOAD_BDX } from 'consts/binder-management';
import { useGetRequiredBdx } from 'lib/binderManagement';
import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

import { BDXView } from './BDX.view';
import { BDXSkeleton } from './BDXSkeleton';

export const BDX = ({ binderType }) => {
  const { facility, isLoading: isLoadingFacility } = useContext(FacilityContext);
  const { data, isLoading: isLoadingBDX, fetchNextPage, isFetchingNextPage } = useGetRequiredBdx({ facilityReference: facility?.umr });
  const dispatch = useDispatch();

  const handleLoadMore = () => fetchNextPage();

  const handleConfirm = () => {
    dispatch(hideModal('UPLOAD_BDX'));
  };

  const confirmCancelHandler = useConfirmCancelModal(handleConfirm);

  const uploadBDX = (yearMonthSubmission, bdxType, file, fileRequestId, callbackOnSuccess, confirmCancel) => {
    dispatch(
      showModal({
        type: UPLOAD_BDX,
        component: UPLOAD_BDX,
        props: {
          title: [utils.string.t('documents.upload.bdx.title'), facility.coverHolder, facility.umr].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          disableEscapeKey: true,
          componentProps: {
            facility,
            yearMonthSubmission,
            bdxType,
            file,
            fileRequestId,
            callbackOnSuccess,
            confirmCancel,
            confirmCancelHandler,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX')),
          },
        },
      })
    );
  };

  const NoResult = () => <Empty width={400} title={utils.string.t('app.noBDX')} icon={<FlashWarningSVG />} padding />;

  const isLoading = isLoadingFacility || isLoadingBDX;

  if (isLoading) return <BDXSkeleton isFirst />;

  if (
    !facility ||
    !data?.pages ||
    data.pages
      .flatMap(({ data }) => data)
      .flatMap((year) => year.items)
      .flatMap((month) => month.items).length === 0
  ) {
    return <NoResult />;
  }

  return (
    <BDXView
      bordereaux={data?.pages.flatMap(({ data }) => data)}
      uploadBDX={uploadBDX}
      handleLoadMore={handleLoadMore}
      binderType={binderType}
      facility={facility}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
