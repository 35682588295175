import { get } from 'lodash';

export const selectSortOptions = (state) => get(state, 'sort') || null;
export const selectBinderSortOptions = (state) => get(state, 'sort.binders') || [];
export const selectBinderFacilitySortOptions = (state) => get(state, 'sort.binders.facilities.sortOptions') || {};
export const selectBinderCoverholderSortOptions = (state) => get(state, 'sort.binders.coverholders.sortOptions') || {};
export const selectBinderMarketSortOptions = (state) => get(state, 'sort.binders.markets.sortOptions') || {};

export const selectBinderFacilityDisplayStyle = (state) => get(state, 'sort.binders.facilities.displayStyle') || 'CARD';
export const selectBinderCoverholderDisplayStyle = (state) => get(state, 'sort.binders.coverholders.displayStyle') || 'CARD';
export const selectBinderMarketDisplayStyle = (state) => get(state, 'sort.binders.markets.displayStyle') || 'CARD';

// Admin
export const selectAdminUsersSortOptions = (state) => get(state, 'sort.admin.users.sortOptions') || {};
// QB
export const selectRiskListSortOptions = (state) => get(state, 'sort.quote-bind.risk-list.sortOptions') || {};
// Claims Tasks
export const selectClaimsTasksListSortOptions = (state) => get(state, 'sort.claims-tasks.claims-tasks-list.sortOptions') || {};
