import { useMutation } from 'react-query';
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { useAuth0 } from '@auth0/auth0-react';

async function getClient(id, token) {
  if (!id) return;

  const response = await api(token).get(`clients/${id}`);
  return response.data;
}

export function useGetClientDetail(queryKey, id, options) {
  return useApi([queryKey, id], async (id, token) => await getClient(id, token), {
    cacheTime: 1000 * 60 * 15,
    ...options,
  });
}

export function useGetClientDetailMutation() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async (id) => {
      const token = await getAccessTokenSilently();
      if (!id) return;
      const result = await getClient(id, token);

      return result;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );
}
