import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// app
import { ConfirmDeclineView } from './ConfirmDecline.view';
import * as utils from 'utils';

ConfirmDecline.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default function ConfirmDecline({ submitHandler, handleClose, isNtu = false }) {
  const regexDecline = isNtu ? new RegExp(`^${utils.string.t('risks.ntu')}$`) : new RegExp(`^${utils.string.t('risks.decline')}$`);

  const formConfirm = isNtu ? `form.ntuConfirm` : `form.declineConfirm`;

  const fields = [
    {
      name: 'declineConfirm',
      type: 'text',
      value: '',
      validation: Yup.string()
        .matches(regexDecline, utils.string.t(`${formConfirm}.required`))
        .required(utils.string.t(`${formConfirm}.required`)),
      label: utils.string.t(`${formConfirm}.label`),
      hint: isNtu ? '' : utils.string.t(`${formConfirm}.hint`),
      muiComponentProps: {
        autoComplete: 'off',
        autoFocus: true,
      },
    },
  ];

  const actions = [
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleClose,
    },
    {
      name: 'submit',
      label: isNtu ? utils.string.t('app.submit') : utils.string.t('risks.decline'),
      handler: () => {
        if (utils.generic.isFunction(submitHandler)) {
          return submitHandler();
        }
      },
    },
  ];

  return <ConfirmDeclineView fields={fields} actions={actions} />;
}
