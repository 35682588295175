import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

// mui
import { Fade, makeStyles } from '@material-ui/core';

import { Button, FormActions, FormContainer, FormFields, FormGrid, FormSelect, FormText, Loader } from 'components';

import * as utils from 'utils';

// app
import styles from './AddUser.styles';

export const AddUserView = ({ fields, actions, isCreatingUser, useEmailSearch }) => {
  const classes = makeStyles(styles, { name: 'AddEditUser' })();
  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, watch, reset, setError, clearErrors, handleSubmit, formState } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  const email = watch('emailId');
  const { refetch } = useEmailSearch(email);
  const cancel = actions && actions.find((action) => action.name === 'cancel');
  const submit = actions && actions.find((action) => action.name === 'submit');

  const watchEmailField = async () => {
    const { data: existingAccounts } = await refetch();
    if (email && existingAccounts?.data?.length !== 0) {
      setError('emailId', { message: utils.string.t('validation.emailAlreadyInUse') });
    } else {
      clearErrors('emailId');
    }
    return email && existingAccounts?.data?.length !== 0;
  };

  const onCancel = () => {
    if (cancel && utils.generic.isFunction(cancel.handler)) cancel.handler();
    reset();
  };

  const onSubmit = async (data) => {
    const emailError = await watchEmailField();
    return !emailError && submit && utils.generic.isFunction(submit.handler) && submit.handler(data);
  };

  return (
    <div className={classes.root}>
      {isCreatingUser && <Loader visible={isCreatingUser} absolute />}

      <Fade in>
        <FormContainer onSubmit={handleSubmit(onSubmit)} data-testid="form-addUser">
          <FormFields type="dialog">
            <FormGrid container>
              <FormGrid item xs={12} sm={6}>
                <FormText {...utils.form.getFieldProps(fields, 'firstName')} control={control} error={errors.firstName} />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <FormText {...utils.form.getFieldProps(fields, 'lastName')} control={control} error={errors.lastName} />
              </FormGrid>
            </FormGrid>
            <FormGrid container>
              <FormGrid item xs={12} sm={6}>
                <FormText {...utils.form.getFieldProps(fields, 'emailId')} control={control} error={errors.emailId} />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <FormText {...utils.form.getFieldProps(fields, 'contactPhone')} control={control} error={errors.contactPhone} />
              </FormGrid>
            </FormGrid>

            <FormGrid item xs={12}>
              <FormGrid container>
                <FormGrid item xs={6}>
                  <FormSelect {...utils.form.getFieldProps(fields, 'role')} error={errors.role} control={control} />
                </FormGrid>
              </FormGrid>
            </FormGrid>
          </FormFields>

          <FormActions type="dialog">
            {cancel && <Button text={cancel.label} variant="text" onClick={onCancel} disabled={formState.isSubmitting} />}
            {submit && <Button text={submit.label} type="submit" disabled={formState.isSubmitting || !formState.isDirty} color="primary" />}
          </FormActions>
        </FormContainer>
      </Fade>
    </div>
  );
};

AddUserView.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string.isRequired,
    })
  ),
  actions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, label: PropTypes.string, handler: PropTypes.func })),
  isCreatingUser: PropTypes.bool,
  useEmailSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  userId: PropTypes.number,
};
