const styles = (theme) => ({
  rootUpload: {
    display: 'flex',
  },
  root: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:first-child': {
      marginTop: '6px',
    },

    '&:last-child': {
      paddingBottom: '2rem',
    },
  },
  formLabel: {
    color: theme.palette.primary.main,
    marginLeft: '2px',
    lineHeight: 1,
    marginBottom: '6px',
    fontSize: theme.typography.pxToRem(15),
    display: 'flex',
  },
  dragArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderWidth: 1,
    borderRadius: 10,
    borderColor: theme.palette.neutral.light,
    borderStyle: 'dashed',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '30rem',
    width: '100%',
    height: '15rem',
    justifyContent: 'center',
    transition: theme.transitions.create(['border']),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  dragOver: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  uploadedFileList: {
    marginTop: theme.spacing(3),
    listStyle: 'none',
    // display: 'flex',
    padding: 0,
    fontSize: theme.typography.pxToRem(13),

    width: '100%',

    '& > li': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      backgroundColor: 'rgba(79, 178, 206, 0.13)',
      borderRadius: 12,
      padding: '0.25rem',
    },
  },
  browseFile: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.light,
    border: `1px solid rgba(79, 178, 206, 0.5)`,
    borderRadius: 10,
    backgroundColor: 'rgba(134, 228, 255, 0.08)',
    transition: theme.transitions.create(['color', 'border', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.neutral.lightest,
    },
  },
  dragFile: {
    margin: '8px 0 10px',
    padding: 0,
    textAlign: 'center',
    fontFamily: 'Open Sans',
  },
  uploadIcon: {
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.primary.main,
    opacity: 0.75,
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: theme.typography.pxToRem(20),
    marginRight: 5,
  },
  excelIcon: {
    marginRight: '0.25rem',
  },
  uploadedFileListLabel: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  or: {
    margin: 0,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.grey[400],
  },
  uploadedFileListItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadedFileListImage: {
    marginTop: 20,
  },
  filePreview: {
    width: '120px',
    height: 'auto',
    display: 'block',
  },
  editImage: {
    marginLeft: '30px',
  },
});

export default styles;
