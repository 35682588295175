import React, { useEffect, useRef } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export const getTabFromBDXType = (bdxType) => ({ id: bdxType, label: bdxType });

export const BDXTypesButtons = ({ active, tabs, handleChangeTab }) => {
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef?.current.childNodes.length > 0) {
      const buttons = [...tabsRef?.current.childNodes];

      const maxWidth = Math.max(...buttons.map(({ offsetWidth }) => offsetWidth));

      buttons.forEach((button) => (button.style.width = `${maxWidth}px`));
    }
  }, [tabs]);

  return (
    <ToggleButtonGroup ref={tabsRef} exclusive value={active} onChange={handleChangeTab}>
      {tabs.map((tab) => (
        <ToggleButton key={tab.id} value={tab.id}>
          {tab.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
