import { Box, Card, CardHeader, CardActions, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './RiskCard.styles';

export const RiskCardLoading = ({ isDraft }) => {
  const classes = makeStyles(styles, { name: 'RiskCard' })();

  const InfoLine = () => (
    <Box display="flex" sx={{ mb: 0.75 }}>
      <Typography classes={{ root: classes.infoLeft }}>
        <Skeleton />
      </Typography>
      <Typography classes={{ root: classes.infoRight }}>
        <Skeleton width={100} />
      </Typography>
    </Box>
  );

  return (
    <Card data-testid="risk-loading-card" classes={{ root: classes.card }}>
      <CardHeader
        title={
          <Typography variant="h4" classes={{ root: classes.cardTitle }}>
            <Skeleton width="80%" />
          </Typography>
        }
        subheader={
          <Typography variant="h5" classes={{ root: classes.subTitle }}>
            <Skeleton width="60%" />
          </Typography>
        }
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
          <Skeleton variant="circle" width={22} height={22} style={{ marginRight: 5 }} />
          <Typography classes={{ root: classes.productName }}>
            <Skeleton width={140} />
          </Typography>
        </Box>
        {isDraft ? <InfoLine /> : null}
        <InfoLine />
        <InfoLine />
      </CardContent>
      <CardActions classes={{ root: classes.cardActions }}>
        <Box display="flex" position="relative" flex="1" justifyContent="space-between" alignItems="center">
          <Typography classes={{ root: classes.createdBy }}>
            <Skeleton width={100} />
          </Typography>
          <Skeleton width={66} height={24} style={{ borderRadius: '10px' }} />
        </Box>
      </CardActions>
    </Card>
  );
};

export const RiskCardLoadingSkeleton = ({ numberOfCards = 12, isDraft = false }) => {
  return (
    <>
      {Array.from(Array(numberOfCards).keys()).map((i) => (
        <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
          <RiskCardLoading isDraft={isDraft} />
        </Grid>
      ))}
    </>
  );
};
