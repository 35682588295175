const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(0),
  },
  listItem: {
    padding: theme.spacing(0.25),
  },
  user: {
    padding: theme.spacing(0.25, 0.25, 0.25, 1),
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
  },
  custom: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(-0.3),
    border: `1px solid ${theme.palette.grey[300]}`,
    height: 24,
    borderRadius: 16,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: theme.shadows[1],
    },
  },
});

export default styles;
