import { useQuery } from 'react-query';

import { IUseGetLineageMappingResult } from 'forms/AddEditLineageMapping/AddEditLineageMapping.types';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { LINEAGE_MAPPING } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetLineageMapping = () => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineageMapping',
  });

  return useQuery<IUseGetLineageMappingResult[]>(
    [LINEAGE_MAPPING],
    async () => {
      const result = await binderManagementLineageAxios.get('/lineage/config');
      return result?.data;
    },
    {
      onError: handleError,
    }
  );
};
