import { makeStyles } from '@material-ui/core';

export const useUploadBDXStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      overflowX: 'hidden',
      flex: 1,
    },
    rootModal: {
      maxHeight: '75vh',
      height: '75vh',
      flex: 'auto',
    },
    iconContainer: {
      height: 24,
      fontSize: 24,
      fontWeight: 700,
    },
    linearProgress: {
      height: 2,
      padding: '1px',
    },
  }),
  { name: 'UploadBDX' }
);
