import { useMutation, useQueryClient } from 'react-query';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_DATA, BDX_EXCEPTIONS_COUNT } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useDismissBDXSanctionException = () => {
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.reporting.mutation.useDismissBDXSanctionException',
  });

  const handleSuccess = ({ requestId }) => {
    queryClient.invalidateQueries(BDX_DATA);
    queryClient.invalidateQueries([BDX_EXCEPTIONS_COUNT, requestId]);
  };

  return useMutation(
    async ({ requestId, rowId }) => {
      const data = await binderManagementBDXAxios.put('exceptions/sanction/dismiss', { requestId, rowId });
      return { requestId, data };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
