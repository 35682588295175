import React from 'react';
import { RuleSkeleton } from '../Rule';

export const RulesListSkeleton = () => (
  <div data-testid="RulesListSkeleton">
    <RuleSkeleton />
    <RuleSkeleton />
    <RuleSkeleton />
  </div>
);
