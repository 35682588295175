import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// app
import styles from './Menu.styles';
import { Restricted, Tooltip } from 'components';
import { NewIcon } from './NewIcon';
import * as utils from 'utils';

import {
  makeStyles,
  Collapse,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Typography,
} from '@material-ui/core';

MenuView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string,
      icon: PropTypes.object,
      link: PropTypes.string,
      include: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  isExpanded: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default function MenuView({ items, isExpanded, handleClick, toggleNav }) {
  const classes = makeStyles(styles, { name: 'Menu' })({ isExpanded });

  return (
    <>
      <List className={classes.list} data-testid="menu-list">
        {items.map((item) => {
          if (item.divider) {
            return <Divider className={classes.divider} key={item.name} data-testid="menu-list-divider" />;
          }

          if (item.title) {
            return (
              <Collapse in={isExpanded} timeout={'auto'} key={item.name}>
                <ListSubheader className={classes.subheader}>{item.text}</ListSubheader>
              </Collapse>
            );
          }

          const IconComponent = item.icon;

          const itemContent = (
            <>
              <ListItemIcon className={classnames(classes.icon, item.selected ? classes.iconActive : null)}>
                {item?.newIcon ? item.icon : <IconComponent />}
              </ListItemIcon>
              <ListItemText className={classes.text}>
                <Typography noWrap title={item.text} className={item.selected ? classes.listActive : null}>
                  {item.text}
                </Typography>
              </ListItemText>
            </>
          );

          const listLink = item?.externalLink ? (
            <>
              {item?.ppClaim ? <Divider className={classnames([classes.dividerCollapsed])} /> : null}
              <ListItem button data-testid={`menu-item-${item.name}`}>
                <a
                  href={item.link}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: 'inherit',
                  }}
                >
                  {itemContent}
                  <NewIcon isNew={item?.new} />
                </a>
              </ListItem>
              {item?.ppClaim ? <Divider className={classnames([classes.dividerCollapsed])} /> : null}
            </>
          ) : (
            <ListItem
              button
              component={Link}
              to={item.link}
              onClick={handleClick}
              data-testid={`menu-item-${item.name}`}
              className={item.selected ? classes.active : null}
            >
              {itemContent}
            </ListItem>
          );

          const listButton = (
            <ListItem button onClick={handleClick} data-testid={`menu-item-${item.name}`}>
              {itemContent}
            </ListItem>
          );

          const listItem = item.link ? listLink : listButton;

          const listItemContent = (
            <>
              {isExpanded && listItem}

              {!isExpanded && (
                <>
                  <Hidden xsDown>
                    <Tooltip title={utils.string.t(item.text)} placement="right" block>
                      {listItem}
                    </Tooltip>
                  </Hidden>
                  <Hidden smUp>{listItem}</Hidden>
                </>
              )}
            </>
          );

          if (item.include) {
            return (
              <Restricted include={item.include} key={item.name}>
                <li>{listItemContent}</li>
              </Restricted>
            );
          }

          return (
            <li key={item.name} data-testid={`menu-list${item.link ? '-link' : '-button'}`}>
              {listItemContent}
            </li>
          );
        })}
      </List>
    </>
  );
}
