// mui
import { Box, CircularProgress, IconButton, Typography, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

// app
import styles from './RiskIssues.styles';
import { useQuoteBindStyles } from 'modules/QuoteBind';
import { FormGrid } from 'components';
import IssueCard from './IssueCard';
import * as utils from 'utils';

export function RiskIssuesView({
  riskSanctionsIssues,
  insuredSanctionsCheckResult,
  reInsuredSanctionsCheckResult,
  riskIsLoading,
  handleRefresh,
  isQuoteLoading,
}) {
  const classes = makeStyles(styles, { name: 'RiskIssues' })();
  const classesQB = useQuoteBindStyles();

  return (
    <Box className={classesQB.root}>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" className={classesQB.header}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="h3" className={classesQB.title}>
            {utils.string.t('risks.riskIssues')}
          </Typography>
          {riskIsLoading || isQuoteLoading ? (
            <Box position="relative" className={classes.progressBox}>
              <CircularProgress size={24} classes={{ root: classes.circularProgress }} />
            </Box>
          ) : (
            <IconButton data-testid="refresh" aria-label="Refresh" size="small" onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box className={classesQB.content}>
        <FormGrid container spacing={2}>
          {riskSanctionsIssues?.map((issue) => {
            return (
              <FormGrid key={issue.id} item xs={12} sm={6} md={4} lg={3}>
                <IssueCard
                  key={issue.id}
                  issue={issue}
                  insuredSanctionsCheckResult={insuredSanctionsCheckResult}
                  reInsuredSanctionsCheckResult={reInsuredSanctionsCheckResult}
                />
              </FormGrid>
            );
          })}
        </FormGrid>
      </Box>
    </Box>
  );
}
