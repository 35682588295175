/* eslint-disable no-param-reassign */
import mapboxgl from 'mapbox-gl';

const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
});

const wildFireFields = [
  { title: 'Incident Name: ', path: 'IncidentName' },
  { title: 'Fire Discovery Date: ', path: 'FireDiscoveryDateTime' },
  { title: 'Fire Out Date: ', path: 'FireOutDateTime' },
  { title: 'Incident Type Category: ', path: 'IncidentTypeCategory' },
  { title: 'Incident Description: ', path: 'IncidentShortDescription' },
  { title: 'Fire Cause: ', path: 'FireCauseGeneral' },
];

const massShootings2022Fields = [
  { title: 'Incident ID: ', path: 'incidentID' },
  { title: 'Date: ', path: 'date' },
  {
    title: 'Address: ',
    path: 'address',
  },
  { title: 'Suspects Arrested: ', path: 'suspectsArrested' },
  { title: 'Suspects Injured: ', path: 'suspectsInjured' },
  { title: 'Suspects Killed: ', path: 'suspectsKilled' },
  { title: 'Victims Injured: ', path: 'victimsInjured' },
  { title: 'Victims Killed: ', path: 'victimsKilled' },
];

const shootingsFields = [
  { title: 'Year: ', path: 'Year' },
  { title: 'State: ', path: 'State' },
  { title: 'Killed: ', path: 'Killed' },
  { title: 'Injured: ', path: 'Injured' },
];

export const onWildFireHoverPopup = (map, e, layerData) => {
  // Change the cursor style as a UI indicator.
  map.getCanvas().style.cursor = 'pointer';
  // Copy coordinates array.
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];
    let popupData = layerData.id === 'liveWildFires' ? `<strong><u>${layerData.name} </u></strong></br>` : '';
    const details = wildFireFields.map((field) => {
      if (field.path === 'FireDiscoveryDateTime' || field.path === 'FireOutDateTime') {
        return properties[field.path] !== 'null'
          ? `<strong>${field.title}</strong>${new Date(properties[field.path]).toLocaleString()}</br>`
          : '';
      }

      return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
    });
    popupData += details?.length > 0 && details.join('');

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(lngLatLike).setHTML(popupData).addTo(map);
  }
};

export const onShootingsHoverPopup = (map, e, layerData) => {
  // Change the cursor style as a UI indicator.
  map.getCanvas().style.cursor = 'pointer';
  // Copy coordinates array.
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];

    let popupData = `<strong><u>${layerData.name} </u></strong></br>`;
    const dataFields = layerData.id === 'mass-shootings-2022' ? massShootings2022Fields : shootingsFields;

    const details = dataFields.map((field) => {
      if (field.path === 'date') {
        return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
      }

      return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
    });
    popupData += details?.length > 0 && details.join('');

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(lngLatLike).setHTML(popupData).addTo(map);
  }
};

export const onExit = (map) => {
  map.getCanvas().style.cursor = 'default';
  setTimeout(() => popup.remove(), 20000);
};
