export const resetRiskSelected = () => {
  return {
    type: 'RISK_SELECTED_RESET',
  };
};

export const selectRiskProduct = (type) => {
  return {
    type: 'RISK_PRODUCTS_SELECT',
    payload: type,
  };
};

export const setRiskDisplayStyle = (displayStyle) => {
  return {
    type: 'RISK_SET_DISPLAY_STILE',
    payload: displayStyle,
  };
};
