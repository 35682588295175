import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import styles from './Reports.style';

export const ReportsSkeleton = () => {
  const classes = makeStyles(styles, { name: 'Rules' })();
  return (
    <div className={classes.root} data-testid="ReportsSkeleton">
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    </div>
  );
};
