import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Box } from '@material-ui/core';

import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '5px',
    overflow: 'hidden',
  },
  cardTop: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    justifyContent: 'center',
    padding: '0px',
    minHeight: '60px',
  }),
  name: {
    color: theme.palette.primary.contrastText,
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    textAlign: 'center',
    margin: '0px',
  },
  avatarBox: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    height: '100px',
    width: '100%',
    '& > img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: '100%',
      maxHeight: '100px',
    },
  },
}));

export function Card({ operational }) {
  const classes = useStyles();
  const { name } = operational;
  const logo = utils.client.parent.getLogoFilePath(operational);

  return (
    <Paper elevation={2} className={classes.root} data-testid="operational-card">
      <Box className={classes.cardTop}>
        <Typography variant="h3" classes={{ h3: classes.name }}>
          {name}
        </Typography>
      </Box>
      <Box className={classes.avatarBox}>{logo ? <img src={logo} alt={name} /> : null}</Box>
    </Paper>
  );
}
