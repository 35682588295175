import * as React from 'react';

// mui
import { Box, Typography } from '@material-ui/core';

// app
import { Documents, Empty, FormGrid } from 'components';
import { RiskDocumentUpload } from './RiskDocumentUpload';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { useDeleteRiskDocument, useDownloadRiskDocument, useGetRiskDocuments } from 'lib/quoteBind';
import * as utils from 'utils';
import { useQuoteBindStyles } from 'modules/QuoteBind';

const documentsColumns = [
  { id: 'folder', label: utils.string.t('app.folder') },
  { id: 'filename', label: utils.string.t('app.filename') },
  { id: 'uploadedBy', label: utils.string.t('app.uploadedBy') },
  { id: 'uploadedDate', label: utils.string.t('app.uploadedDate') },
  { id: 'actions', empty: true },
];

export const RiskDocuments = ({ riskId }) => {
  const classes = useQuoteBindStyles();
  const { data, isLoading } = useGetRiskDocuments(riskId);
  const { mutate: deleteRiskDocument } = useDeleteRiskDocument();
  const { mutate: downloadRiskDocument } = useDownloadRiskDocument();

  const handleDeleteRiskDocument = (documentId) => {
    deleteRiskDocument({ riskId, documentId });
  };

  const handleDownloadRiskDocument = (data) => {
    const { id: documentId, fileName, contentType } = data;
    downloadRiskDocument({ riskId, documentId, fileName, contentType });
  };

  const handlers = {
    handleDelete: handleDeleteRiskDocument,
    handleDownload: handleDownloadRiskDocument,
  };

  let folders = [];

  const formattedDocuments =
    data?.map((doc) => {
      const folder = {
        id: doc?.metaData?.group || '',
        label: doc?.metaData?.group || '',
        value: doc?.metaData?.group || '',
      };

      if (!folders.find((item) => item.label === folder.label)) {
        folders.push(folder);
      }

      return {
        id: doc.fileId,
        fileName: doc.name,
        contentType: doc.contentType,
        uploaderFullName: doc?.metaData?.userName || '',
        createdAt: doc?.metaData?.createdAt,
        folder: doc?.metaData?.group || '',
      };
    }) || [];

  return (
    <Box className={classes.root}>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" className={classes.header}>
        <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600 }}>
          {utils.string.t('risks.documents')}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <FormGrid container spacing={5}>
          <FormGrid
            item
            xs={12}
            sm={6}
            md={8}
            data-testid="risk-documents-list"
            style={{
              borderRight: '1px solid #e0e0e0',
            }}
          >
            {isLoading ? (
              <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />
            ) : (
              <Documents
                documents={formattedDocuments}
                folders={folders}
                columns={documentsColumns}
                handlers={handlers}
                displayUserAvatar
              />
            )}
          </FormGrid>
          <FormGrid item xs={12} sm={6} md={4} data-testid="risk-documents-upload">
            <RiskDocumentUpload riskId={riskId} groups={folders.filter((folder) => folder.label)} />
          </FormGrid>
        </FormGrid>
      </Box>
    </Box>
  );
};
