import { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { motion } from 'framer-motion';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles, Box, Card, CardHeader, CardActions, CardContent, Chip, Typography } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { Button, EdgeImage, Tooltip, Translate } from 'components';
import { RiskCardLoading } from './RiskCardLoading';
import styles from './RiskCard.styles';
import * as utils from 'utils';
import config from 'config';

const cardMotion = {
  rest: {
    scale: 1,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    scale: 1.04,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const cardNameMotion = {
  rest: { x: -55, ease: 'easeIn', duration: 0.3, type: 'tween' },
  hover: {
    x: 10,
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const cardButtonMotion = {
  rest: { x: -75, ease: 'easeOut', duration: 0.4, type: 'tween' },
  hover: {
    x: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

export const RiskInformationLine = ({ label, value }) => {
  const classes = makeStyles(styles, { name: 'RiskCard' })();
  return (
    <Box display="flex" alignItems="center" sx={{ mb: 0.75 }}>
      <Typography classes={{ root: classes.infoLeft }}>{label}</Typography>
      <Typography classes={{ root: classes.infoRight }}>
        <Translate label="format.date" options={{ value: { date: value, format: config.ui.format.date.text, default: '-' } }} />
      </Typography>
    </Box>
  );
};

export const RiskCard = ({ risk, riskProducts, loading, handlers, isDraft = false, createdAt, createdBy }) => {
  const classes = makeStyles(styles, { name: 'RiskCard' })();
  const theme = useTheme();
  const [isTitleLong, setIsTitleLong] = useState(false);
  const [isSubTitleLong, setIsSubTitleLong] = useState(false);
  const [isProductNameLong, setIsProductNameLong] = useState(false);
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const productNameRef = useRef(null);

  const isEllipsisActive = (e) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (titleRef?.current) {
      setIsTitleLong(isEllipsisActive(titleRef.current));
    }
  }, [titleRef]);

  useEffect(() => {
    if (subTitleRef?.current) {
      setIsSubTitleLong(isEllipsisActive(subTitleRef.current));
    }
  }, [subTitleRef]);

  useEffect(() => {
    if (productNameRef?.current) {
      setIsProductNameLong(isEllipsisActive(productNameRef.current));
    }
  }, [productNameRef]);

  if (loading) {
    return <RiskCardLoading isDraft={isDraft} />;
  }

  const riskStatus = risk?.riskStatus;
  let riskStatusString = riskStatus ? utils.string.t(`QBstatus.${riskStatus?.toLowerCase()}`) : '-';

  const statusBackgroundColor = theme.palette.status[riskStatus?.toLowerCase()]
    ? theme.palette.status[riskStatus.toLowerCase()]
    : theme.palette.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);
  const productName = utils.risk.getRiskName(risk.riskType, riskProducts);

  const subheader = (
    <Box style={{ height: 26, overflow: 'hidden' }}>
      <Tooltip title={isSubTitleLong ? get(risk, 'client.name', '-') : ''} placement="top">
        <Typography ref={subTitleRef} variant="h5" classes={{ root: classes.subTitle }}>
          {get(risk, 'client.name', '-')}
        </Typography>
      </Tooltip>
    </Box>
  );

  return (
    <motion.div initial="rest" whileHover="hover" animate="rest" data-testid="risk-card">
      <Card component={motion.div} variants={cardMotion} classes={{ root: isDraft ? classes.cardDraft : classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader }}
          title={
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Tooltip title={isTitleLong ? get(risk, 'insured.name', '-') : ''} placement="top" rich>
                <Typography ref={titleRef} data-testid="insuredName" variant="h4" classes={{ root: classes.cardTitle }}>
                  {get(risk, 'insured.name', '-')}
                </Typography>
              </Tooltip>
            </Box>
          }
          subheader={subheader}
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" alignItems="center" sx={{ mb: 1 }} style={{ height: 26, overflow: 'hidden' }}>
              <EdgeImage container="quote-bind" imageName={`${risk.riskType}.svg`} width="22" height="22" style={{ marginRight: 5 }} />
              <Tooltip title={isProductNameLong ? productName : ''} placement="top">
                <Typography ref={productNameRef} classes={{ root: classes.productName }}>
                  {productName}
                </Typography>
              </Tooltip>
            </Box>
            {isDraft ? <RiskInformationLine label={utils.string.t('app.createdAt')} value={createdAt} /> : null}
            <RiskInformationLine label={utils.string.t('app.inception')} value={risk.inceptionDate} />
            <RiskInformationLine label={utils.string.t('app.expiry')} value={risk.expiryDate} />
          </Box>
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }} style={{ borderBottom: `4px  solid ${statusBackgroundColor}` }}>
          {isDraft ? (
            <Box display="flex" alignItems="center" flex="1" justifyContent="space-between">
              <Typography variant="h6" style={{ color: 'black', fontWeight: 400, fontSize: 13 }}>
                {createdBy}
              </Typography>
              <Box>
                <Button
                  data-testid="edit-draft-risk"
                  size="xsmall"
                  color="primary"
                  icon={EditIcon}
                  onClick={handlers.handleEditDraftRisk}
                />
                <Button
                  data-testid="delete-draft-risk"
                  style={{ marginLeft: 10 }}
                  size="xsmall"
                  color="secondary"
                  icon={DeleteForeverIcon}
                  onClick={handlers.handleDeleteDraftRisk}
                />
              </Box>
            </Box>
          ) : (
            <Box style={{ height: 24 }} display="flex" position="relative" flex="1" justifyContent="space-between" alignItems="start">
              <Box component={motion.div} display="flex" position="relative" flex="1" alignItems="center" justifyContent="start">
                <Box component={motion.div} variants={cardButtonMotion}>
                  <Button
                    to={`${config.routes.quoteBind.riskDetails}/${risk.id}`}
                    color="primary"
                    size="xsmall"
                    icon={ChevronRightIcon}
                    iconPosition="right"
                    text="View"
                  />
                </Box>
                <motion.div variants={cardNameMotion}>
                  <Typography classes={{ root: classes.createdBy }}>{risk.createdBy}</Typography>
                </motion.div>
              </Box>
              {riskStatus ? (
                <Chip
                  size="small"
                  label={<Translate label={`${riskStatusString}`} />}
                  status={riskStatus.toLowerCase()}
                  data-testid="quote-status"
                  style={{
                    textTransform: 'uppercase',
                    backgroundColor: statusBackgroundColor,
                    color: statusColor,
                    fontSize: '11px',
                    marginLeft: 10,
                    zIndex: 1,
                  }}
                />
              ) : null}
            </Box>
          )}
        </CardActions>
      </Card>
    </motion.div>
  );
};
