import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// app
import styles from './AddEditMarkets.styles';
import { Button, FormContainer, FormFields, FormActions, FormGrid, FormText, FormFileDrop } from 'components';
import { LogoInstructions } from '../AddEditParentClient/AddEditParentClient.view';
import * as utils from 'utils';

// mui
import { makeStyles } from '@material-ui/core';

AddEditMarketsView.propTypes = {
  fields: PropTypes.array,
  actions: PropTypes.array,
  logo: PropTypes.string || null,
};

export function AddEditMarketsView({ fields, actions, logo }) {
  const classes = makeStyles(styles, { name: 'AddEditParentClient' })();

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, reset, handleSubmit, formState, setValue } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  const cancel = actions && actions.find((action) => action.name === 'cancel');
  const submit = actions && actions.find((action) => action.name === 'submit');

  const onCancel = () => {
    cancel && utils.generic.isFunction(cancel.handler) && cancel.handler();
    reset();
  };

  const onSubmit = (data) => {
    return submit && utils.generic.isFunction(submit.handler) && submit.handler(data);
  };

  const handleUpdateFile = (file) => {
    setValue('logo.0', file);
  };

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(onSubmit)} data-testid="form-AddEditMarkets">
        <FormFields type="dialog">
          <FormGrid container>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'name')} control={control} error={errors.name} />
            </FormGrid>
            {logo ? (
              <FormGrid item xs={12}>
                <div className={classes.logo}>
                  <img src={logo} alt="logo" />
                </div>
              </FormGrid>
            ) : null}
            <FormGrid item xs={12}>
              <LogoInstructions />
              <FormFileDrop
                control={control}
                onChange={(file) => {
                  setValue('logo', file);
                }}
                {...utils.form.getFieldProps(fields, 'logo')}
                dragLabel={utils.string.t('admin.uploadLogoLabel')}
                showUploadPreview={false}
                showEditImage={true}
                handleUpdateFile={handleUpdateFile}
                fileNameLength={30}
                onlyOneFile
              />
            </FormGrid>
          </FormGrid>
        </FormFields>

        <FormActions type="dialog">
          {cancel && <Button text={cancel.label} variant="text" onClick={onCancel} disabled={formState.isSubmitting} />}
          {submit && <Button text={submit.label} type="submit" disabled={formState.isSubmitting} color="primary" />}
        </FormActions>
      </FormContainer>
    </div>
  );
}
