import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { v4 as uuidv4 } from 'uuid';

export default function useWebSockets() {
  const openSocket = (url) => {
    const sessionId = uuidv4();
    var socket = new SockJS(`${url}&sessionId=${sessionId}`);
    const stompClient = Stomp.over(socket);
    return { stompClient, sessionId };
  };

  const connectWebSocket = (url) => {
    const { stompClient, sessionId } = openSocket(url);
    return { stompClient, sessionId };
  };

  return {
    connectWebSocket,
  };
}
