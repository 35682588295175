import { RISK_DISPLAY_CARD } from 'consts';

const initialState = {
  displayStyle: RISK_DISPLAY_CARD,
  products: {
    selected: null,
  },
};

const riskReducers = (state = initialState, action) => {
  switch (action.type) {
    // risk

    case 'RISK_PRODUCTS_SELECT':
      return {
        ...state,
        products: {
          ...state.products,
          selected: action.payload,
        },
      };

    case 'RISK_PRODUCTS_RESET':
      return {
        ...state,
        products: {
          ...state.products,
          selected: initialState.products.selected,
        },
      };

    case 'RISK_SET_DISPLAY_STILE':
      return {
        ...state,
        displayStyle: action.payload,
      };

    default:
      return state;
  }
};

export default riskReducers;
