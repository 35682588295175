import * as utils from 'utils';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

const utilsTrip = {
  getDateFrom: (visits) => {
    if (!visits || !utils.generic.isValidArray(visits, true)) return 0;

    return visits.reduce((acc, visit) => {
      const date = utils.date.timestamp(visit.visitingDate);

      return Boolean(acc && date) ? Math.min(acc, date) : acc || date;
    }, 0);
  },

  getDateTo: (visits) => {
    if (!visits || !utils.generic.isValidArray(visits, true)) return 0;

    return visits.reduce((acc, visit) => {
      const date = utils.date.timestamp(visit.visitingDate);
      return Math.max(acc, date);
    }, 0);
  },

  getBrokers: (visits) => {
    if (!visits || !utils.generic.isValidArray(visits, true)) return [];

    const users = visits.reduce((acc, visit) => {
      return utils.generic.isValidArray(visit.users) ? [...acc, ...visit.users] : acc;
    }, []);

    return uniqBy(users, 'id');
  },

  getKcsTier: (lead) => {
    const clientId = get(lead, 'client.id') || null;

    if (!clientId) return null;

    const premiumSnapshot = get(lead, 'premiumSnapshots', []).filter((snapshot) => snapshot.clientId === clientId);

    const kcsTier = premiumSnapshot?.find((snapshot) => snapshot.kcsTier)?.kcsTier;

    return kcsTier || null;
  },
};

export default utilsTrip;
