import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFileInputLineageAxios } from '../../binderManagement.api';
import { LINEAGE_YEAR_MONTH, LINEAGE_YEAR_MONTH_DETAILS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useDeleteLineage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useDeleteLineage',
    warning: 'notification.lineage.submitFail',
  });

  const handleSuccess = ({ yearMonthSubmission }: IDeleteLineageDTO) => {
    const yearMonthStart = `${yearMonthSubmission.split('-')[0]}-01`;
    queryClient.invalidateQueries([LINEAGE_YEAR_MONTH, yearMonthStart]);
    queryClient.invalidateQueries([LINEAGE_YEAR_MONTH_DETAILS, yearMonthSubmission]);

    dispatch(enqueueNotification('notification.lineage.submitSuccess', 'success'));
  };

  return useMutation<IDeleteLineageDTO, AxiosError, IDeleteLineageDTO>(
    async (config) => {
      await binderManagementFileInputLineageAxios.delete(config.requestId);

      return config;
    },
    { onError: handleError, onSuccess: handleSuccess }
  );
};
