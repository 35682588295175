import { useMutation } from 'react-query';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useConfirmBDXRow = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useConfirmBDXRow',
    warning: 'notification.bdx.uploadFail',
  });

  return useMutation(
    async ({ requestId, rowNumber }) => {
      const data = await binderManagementFileInputAxios.post('confirm', { requestId, rowNumber });

      return data;
    },
    {
      onError: handleError,
    }
  );
};
