import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getFacilityLimitsDefinition(facilityId, productCode, token) {
  const path = `products/${productCode}?type=LIMIT_APPLICABLE&facilityId=${facilityId}`;

  const response = await api(token).get(path);

  return response.data;
}

export function useGetFacilityLimitsDefinition(queryKey, facilityId, productCode, options) {
  return useApi([queryKey, facilityId], async (facilityId, token) => await getFacilityLimitsDefinition(facilityId, productCode, token), {
    ...options,
    cacheTime: 1000 * 60 * 60 * 24,
  });
}
