import { makeStyles } from '@material-ui/core';

export const binderLimitPreviewStyles = makeStyles((theme) => ({
  limit: {
    width: '100%',
  },

  limitControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(1.5),
  },

  limitControl: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    '& svg': {
      transform: 'none !important',
    },
  },

  previewTableWrapper: {
    marginBottom: theme.spacing(4),
  },

  binderLimit: {
    width: '100%',
  },

  binderLimitControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(1.5),
  },

  binderLimitControl: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    '& svg': {
      transform: 'none !important',
    },
  },

  ruleLineContainer: {
    padding: theme.spacing(0, 0, 0, 7),
    '& svg': {
      transform: 'none !important',
    },
  },

  previewContainer: {
    width: '100%',
    padding: theme.spacing(2),
    flexDirection: 'column',
  },

  previewHeader: {
    display: 'flex',
    marginRight: theme.spacing(2),
    alignItems: 'center',
    flex: 1,
    fontSize: theme.typography.pxToRem(16),
  },

  conditionType: {
    margin: theme.spacing(0, 1),
    fontWeight: 600,
  },

  operation: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  operationDescription: {
    marginBottom: -10,
    marginLeft: theme.spacing(1),
  },

  operationText: {
    marginLeft: theme.spacing(0.5),
    '&:first-child': {
      marginLeft: 0,
    },
  },

  operationTextBold: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 600,
    '&:first-child': {
      marginLeft: 0,
    },
  },

  operationSkeleton: {
    flex: 1,

    '& *': {
      margin: theme.spacing(1, 0, 0, 1),
    },
  },
}));
