import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import * as utils from 'utils';
import { useValidatePassword } from 'lib/binderManagement';
import { hideModal } from 'stores';
import { ValidateFilePasswordView } from './ValidateFilePassword.view';

export default function ValidateFilePassword({ onSuccess, requestId }) {
  const [showPassword, setShowPassword] = useState(false);

  const fields = [
    {
      name: 'password',
      label: utils.string.t('app.password'),
      validation: Yup.string().required(utils.string.t('validation.required')),
      muiComponentProps: {
        autoComplete: 'off',
      },
    },
  ];
  const dispatch = useDispatch();
  const { mutateAsync: validatePassword } = useValidatePassword();
  const methods = useForm({
    mode: 'onChange',
  });
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    const isFormValid = await methods.trigger();
    if (isFormValid) {
      try {
        setLoading(true);
        const { password } = methods.getValues();
        const data = await validatePassword({ password, requestId });
        if (data) {
          onSuccess({ data, password });
          dispatch(hideModal('VALIDATE_FILE_PASSWORD'));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <ValidateFilePasswordView
        isLoading={isLoading}
        fields={fields}
        methods={methods}
        onSubmit={onSubmit}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
    </FormProvider>
  );
}
