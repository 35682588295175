import { FC } from 'react';

import { Table, TableBody, TableRow, useTheme, TableCell } from '@material-ui/core';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Empty, Overflow, TableHead } from 'components';

import * as utils from 'utils';

import { FacilityTableListElement } from './FacilityTableListElement';
import { FacilityTableListSkeleton } from './FacilityTableListSkeleton';

const NoResult = () => <Empty width={400} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />;

export const FacilityTableList: FC<IFacilityTableListProps> = ({ sort, isListLoading, facilities, type, entity }) => {
  const cols = [
    { id: 'umr', label: utils.string.t('app.umr') },
    { id: 'product', label: utils.string.t('app.product') },
    { id: 'gxbInstance', label: utils.string.t('app.gxb') },
    { id: 'team', label: utils.string.t('app.team') },
    { id: 'inceptionDate', label: utils.string.t('app.inceptionDate'), nowrap: true },
    { id: 'expiryDate', label: utils.string.t('app.expiryDate'), nowrap: true },
    { id: 'status', label: utils.string.t('app.status') },
  ];
  const theme = useTheme<Theme>();

  return (
    <Overflow>
      <Table data-testid="facility-list-table">
        <TableHead columns={cols} sorting={sort} />
        {isListLoading ? (
          <FacilityTableListSkeleton colSpan={cols.length} />
        ) : facilities?.content?.length === 0 ? (
          <TableBody data-testid="facility-list">
            <TableRow>
              <TableCell colSpan={cols.length}>
                <NoResult />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody data-testid="facility-list">
            {facilities?.content?.map((facility) => {
              const statusBackgroundColor = theme.palette.bm.status[facility.status?.toLowerCase()]
                ? theme.palette.bm.status[facility.status?.toLowerCase()]
                : theme.palette.bm.status.default;

              return (
                <FacilityTableListElement
                  key={facility.umr}
                  statusBackgroundColor={statusBackgroundColor}
                  facility={facility}
                  type={type}
                  entity={entity}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
    </Overflow>
  );
};
