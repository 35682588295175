import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showModal } from 'stores';
import { Button, EdgeImage, Translate, FormGrid, Tooltip } from 'components';
import styles from './AddQuoteBind.styles';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { Box, Typography } from '@material-ui/core';

import { PRODUCT_STATUS_ERROR, PRODUCT_STATUS_WARN, PRODUCT_STATUS_OK } from 'consts';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddQuoteBind = ({ products, cancelHandler }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState('');
  const cssClasses = makeStyles(styles, { name: 'AddQuoteBind' })();

  useEffect(() => {
    if (products?.length === 1) {
      setProduct(products?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products?.length]);

  const handleChange = (event) => {
    setProduct(event.target.value);
  };

  const productStatus = product?.status || PRODUCT_STATUS_OK;
  const isDisabled = product ? (productStatus === PRODUCT_STATUS_ERROR ? true : false) : true;
  const addButtonIcon = productStatus === PRODUCT_STATUS_OK ? AddIcon : productStatus === PRODUCT_STATUS_WARN ? WarningIcon : ErrorIcon;

  const ModalTitle = () => {
    return (
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <EdgeImage container="quote-bind" imageName={`${product.value}.svg`} width="24" height="24" style={{ marginRight: 10 }} />
        <Typography variant="h2" style={{ margin: 0 }}>
          {product.label}
        </Typography>
      </Box>
    );
  };

  const MenuItemChildren = ({ title, type }) => {
    return (
      <Box display="flex" alignItems="center" flex="1">
        <EdgeImage container="quote-bind" imageName={`${type}.svg`} width="18" height="18" style={{ marginRight: 5 }} />
        <span className={cssClasses.productName}>{title}</span>
      </Box>
    );
  };

  // ADD_EDIT_QUOTE_BIND
  const handleAddQuoteBind = () => {
    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          titleChildren: <ModalTitle />,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product,
            cancelHandler,
          },
        },
      })
    );
  };
  return (
    <FormGrid container spacing={2}>
      <FormGrid item xs={12}>
        <FormControl className={classes.formControl}>
          {products?.length === 1 ? (
            <Box className={cssClasses.defaultMenuItem}>
              <MenuItem>
                <MenuItemChildren title={products?.[0].label} type={products?.[0].value} />
              </MenuItem>
            </Box>
          ) : (
            <Select
              labelId="select-product"
              name="select-product"
              displayEmpty
              id="select-product"
              value={product}
              onChange={handleChange}
              label="Product"
              disabled={products?.length > 0 ? false : true}
              style={{
                width: 200,
              }}
            >
              <MenuItem value="" disabled>
                <em>Select Product</em>
              </MenuItem>
              {products.map((product) => (
                <MenuItem key={product.value} value={product}>
                  <MenuItemChildren title={product.label} type={product.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>

        <Tooltip title={product?.message} placement="top" rich>
          <Button
            style={{ marginTop: 8 }}
            icon={addButtonIcon}
            color="primary"
            size="small"
            text={<Translate label="risks.addRisk" />}
            data-testid="risk-add-button"
            onClick={() => handleAddQuoteBind()}
            disabled={isDisabled}
          />
        </Tooltip>
      </FormGrid>
    </FormGrid>
  );
};

AddQuoteBind.propTypes = {
  products: PropTypes.array.isRequired,
};

export default AddQuoteBind;
