import { useQuery } from 'react-query';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { BDX_IMPORT_MAPPING } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBdximportMapping = () => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxData' });

  return useQuery(
    [BDX_IMPORT_MAPPING],
    async () => {
      const result = await binderManagementFileInputAxios.get('mapping');
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
