import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import { Grid, makeStyles, Switch, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, FormAutocompleteMui, FormDate, FormMultiTextField, FormSelect, FormText } from 'components';
import { RuleLine } from 'modules/Rules';

import * as utils from 'utils';

import styles from './Actions.styles';

const getSelectOptions = (options) =>
  options?.map((option, index) => ({
    label: option,
    id: index + 1,
  }));

const getDefaultObject = (label, value, list) => {
  if (typeof value === 'object') {
    return list?.find((item) => item[label] === value[label]);
  }
  return list?.find((item) => item[label] === value);
};

export const Action = ({ fields, methods, action, ruleColumns, ruleRowActions, handleRemoveCondition, isLast, prefix }) => {
  const ROW_ACTIONS = useMemo(
    () => [
      {
        name: 'Current',
        actions: ruleRowActions,
      },
    ],
    [ruleRowActions]
  );

  const ROW_ACTION_NAMES = ROW_ACTIONS[0]?.actions?.map((action) => action.action);
  const initialRowAction = ROW_ACTION_NAMES?.includes(action?.operation) || false;

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
  } = methods;

  useEffect(() => {
    if (action.field) {
      setSelectedField(action.field);
      if (action.operation) {
        const operands = action.field?.actions?.find((a) => a.action === action.operation);
        setOperands(operands);
      }
    }
    if (initialRowAction) {
      if (action.field) {
        setSelectedField(action.field);
        setOperands(action);
      } else {
        const newAction = ruleRowActions?.find((actionItem) => actionItem.action === action.operation);
        setSelectedField(newAction.field);
        setOperands(newAction);
      }
    }
  }, [ROW_ACTIONS, action, initialRowAction, ruleRowActions]);

  const classes = makeStyles(styles, { name: 'Action' })();
  const [selectedField, setSelectedField] = useState(null);
  const [isRowAction, setRowAction] = useState(initialRowAction);
  const [operands, setOperands] = useState({});

  const onSelectColumnHeading = (selected) => {
    setSelectedField(selected);
    setValue(`${prefix}.operation`, null);
    setOperands({});
  };

  const onChangeActionType = (e) => {
    setRowAction(e.target.checked);
    setSelectedField(null);
    setValue(`${prefix}.operation`, null);
    setValue(`${prefix}.field`, null);
    setOperands({});
  };

  const onSelectOperation = (_, option) => {
    setOperands(selectedField.actions.find(({ action }) => action === option));
  };

  useEffect(() => {
    if (!operands?.value1) {
      resetField(`${prefix}.value1`);
    }
    if (!operands?.value2) {
      resetField(`${prefix}.value2`);
    }
    if (!operands?.column1) {
      resetField(`${prefix}.column1`);
    }
    if (!operands?.column2) {
      resetField(`${prefix}.column2`);
    }
    if (!operands?.values) {
      resetField(`${prefix}.values`);
    }
    if (!operands?.delimiter) {
      resetField(`${prefix}.delimiter`);
    }
  }, [operands, resetField, prefix]);

  useEffect(() => {
    if (initialRowAction) {
      setSelectedField(ROW_ACTIONS[0]);
    }
  }, [ROW_ACTIONS, initialRowAction]);

  const renderOperand = (field, name, required = true) => {
    if (field === 'DATE' || field.type === 'DATE') {
      return (
        <FormDate
          {...utils.form.getFieldProps(fields, 'dateValue')}
          name={`${prefix}.${name}`}
          defaultValue={action[name] || ''}
          rules={required ? { required: 'Required' } : {}}
          control={control}
          error={get(errors, `${prefix}.${name}`)}
        />
      );
    }
    if (field === 'DATE_TIME' || field.type === 'DATE_TIME') {
      return (
        <FormDate
          {...utils.form.getFieldProps(fields, 'dateValue')}
          isDatetimePicker
          name={`${prefix}.${name}`}
          defaultValue={action[name] || ''}
          rules={required ? { required: 'Required' } : {}}
          control={control}
          error={get(errors, `${prefix}.${name}`)}
        />
      );
    }
    if (field.type === 'LIST' || field.type === 'SELECT') {
      return (
        <FormSelect
          name={`${prefix}.${name}`}
          label={utils.string.t(`rules.${name}`)}
          defaultValue={action.value2 || ''}
          optionKey={field?.optionKey ? field.optionKey : 'label'}
          optionLabel={field?.optionLabel ? field.optionLabel : 'label'}
          rules={required ? { required: 'Required' } : {}}
          options={field?.options ? field?.options : getSelectOptions(field.list)}
          getOptionSelected={(option, value) => option.id === value.id}
          control={control}
          error={get(errors, `${prefix}.value2`)}
        />
      );
    }
    return (
      <FormText
        {...utils.form.getFieldProps(fields, name)}
        name={`${prefix}.${name}`}
        defaultValue={action[name] || ''}
        rules={required ? { required: 'Required' } : {}}
        control={control}
        error={get(errors, `${prefix}.${name}`)}
      />
    );
  };

  const label = (
    <div className={classes.actionLabel}>
      <Typography className={classNames(classes.actionLabelText, { [classes.actionLabelTextActive]: !isRowAction })}>
        {utils.string.t('rules.columnHeading')}
      </Typography>
      <Switch size="small" checked={isRowAction} onClick={onChangeActionType} color="default" data-testid="rowActionSwitch" />
      <Typography className={classNames(classes.actionLabelText, { [classes.actionLabelTextActive]: isRowAction })}>
        {utils.string.t('rules.rowHeading')}
      </Typography>
    </div>
  );

  const getDefaultValue = () => {
    if (action.field) {
      getDefaultObject('name', action.field, isRowAction ? ROW_ACTIONS : ruleColumns);
    }
    if (isRowAction) {
      return ROW_ACTIONS[0];
    }
    return null;
  };

  return (
    <Grid container spacing={1} className={classes.action}>
      <Grid item container spacing={1}>
        <Grid item className={classes.ruleLineContainer}>
          <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={55} />
        </Grid>
        <Grid item xs={2}>
          <FormAutocompleteMui
            name={`${prefix}.field`}
            optionKey="name"
            optionLabel="name"
            labelComp={label}
            defaultValue={isRowAction ? ROW_ACTIONS[0] : getDefaultValue()}
            options={(isRowAction ? ROW_ACTIONS : ruleColumns) || []}
            onSelect={onSelectColumnHeading}
            rules={operands?.isRowAction ? { required: false } : { required: 'Required' }}
            control={control}
            error={get(errors, `${prefix}.field`)}
          />
        </Grid>
        <Grid item xs={3} className={classes.operandLabelWrapper}>
          <FormSelect
            name={`${prefix}.operation`}
            control={control}
            label={utils.string.t('rules.action')}
            defaultValue={action.operation || ''}
            options={selectedField?.actions || []}
            handleUpdate={onSelectOperation}
            optionKey="action"
            optionLabel="action"
            rules={{ required: 'Required' }}
            disabled={!selectedField}
            getOptionSelected={(option, value) => option.id === value.id}
            error={get(errors, `${prefix}.operation`)}
          />
        </Grid>
        {operands?.column1 && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            <FormAutocompleteMui
              name={`${prefix}.column1`}
              label={`${utils.string.t('rules.columnHeading')} 1`}
              optionKey="name"
              optionLabel="name"
              rules={{ required: 'Required' }}
              options={ruleColumns || []}
              control={control}
              error={get(errors, `${prefix}.column1`)}
            />
          </Grid>
        )}
        {operands?.values && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            <FormMultiTextField
              {...utils.form.getFieldProps(fields, 'values')}
              name={`${prefix}.values`}
              defaultValue={action.values || []}
              control={control}
              rules={{ required: 'Required' }}
              error={get(errors, `${prefix}.values`)}
              isPasteFromExcel
            />
          </Grid>
        )}
        {operands?.value1 && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            {operands?.field
              ? renderOperand(
                  {
                    ...operands.field,
                  },
                  'value1'
                )
              : renderOperand(operands.value1, 'value1')}
          </Grid>
        )}
        {operands?.value2 && (
          <Grid item className={classes.operandLabelWrapper}>
            {renderOperand(operands.value2, 'value2', false)}
          </Grid>
        )}
        {operands?.column2 && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            <FormAutocompleteMui
              name={`${prefix}.column2`}
              label={`${utils.string.t('rules.columnHeading')} 2`}
              optionKey="name"
              optionLabel="name"
              rules={{ required: 'Required' }}
              options={ruleColumns || []}
              control={control}
              error={get(errors, `${prefix}.column2`)}
            />
          </Grid>
        )}
        {operands?.delimiter && (
          <Grid item xs={1} className={classes.operandLabelWrapper}>
            <FormText
              {...utils.form.getFieldProps(fields, 'delimiter')}
              name={`${prefix}.delimiter`}
              defaultValue={action.delimiter || ''}
              control={control}
              error={get(errors, `${prefix}.delimiter`)}
            />
          </Grid>
        )}
        {operands?.decimals && operands?.decimals !== null && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            <FormSelect
              {...utils.form.getFieldProps(fields, 'decimals')}
              name={`${prefix}.decimals`}
              label={utils.string.t('rules.decimals')}
              rules={{ required: true }}
              control={control}
              error={get(errors, `${prefix}.decimals`)}
            />
          </Grid>
        )}
        {operands?.roundingMode && operands?.roundingMode?.length !== 0 && (
          <Grid item xs={2} className={classes.operandLabelWrapper}>
            <FormSelect
              {...utils.form.getFieldProps(fields, 'roundingMode')}
              label={utils.string.t('rules.roundingMode')}
              name={`${prefix}.roundingMode`}
              rules={{ required: true }}
              control={control}
              error={get(errors, `${prefix}.roundingMode`)}
            />
          </Grid>
        )}
      </Grid>
      {!isLast && (
        <Grid item className={classes.deleteAction}>
          <Button icon={DeleteIcon} color="primary" size="xsmall" onClick={handleRemoveCondition} data-testid="btn-delete-action" />
        </Grid>
      )}
    </Grid>
  );
};
