import { useQuery, UseQueryOptions } from 'react-query';
import flatten from 'lodash/flatten';
import lodashMap from 'lodash/map';
import uniq from 'lodash/uniq';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { DROOLS_FACILITY_BDX_TYPES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBdxTypes = (riskCodes: string[], sanctionsOnly = false, options: Partial<UseQueryOptions<TBDXType[]>> = {}) => {
  const params = sanctionsOnly ? { sanctionsOnly } : {};

  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetBdxTypes' });

  return useQuery<TBDXType[]>(
    [DROOLS_FACILITY_BDX_TYPES, riskCodes, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get<IGetRiskCodesResponse>(`/riskCodes`, { params });
      if (!riskCodes) {
        const list = flatten(lodashMap(result?.data?.riskCodes, 'bdxTypes'));
        return uniq(list) || [];
      }

      const bdxTypes = result?.data?.riskCodes
        .filter(({ riskCode }) => riskCodes.includes(riskCode))
        .flatMap((riskCode) => riskCode.bdxTypes);

      return [...new Set(bdxTypes)];
    },
    {
      onError: handleError,
      keepPreviousData: true,
      ...options,
    }
  );
};
