import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { useState } from 'react';
import { firstBy } from 'thenby';

// MUI
import { Box, Table, TableBody, TableRow, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { ReactComponent as Error } from 'assets/svg/error.svg';
// app
import { Accordion, Empty, MapBox, MapBoxTooltip, Overflow, Pagination, TableCell, TableHead, Translate } from 'components';
import { renderValue } from 'modules/QuoteBind/RiskData';

import config from 'config';
import { usePagination, useSort, useWebGLAvailable } from 'hooks';
import * as utils from 'utils';
import { mapLayers } from 'utils/map/mapLayers';

import { LocationTooltip } from './LocationTooltip';

const { pagination: paginationConfig } = config?.ui;
const useStyles = makeStyles(() => ({
  root: {
    color: 'red',
    '& .MuiTableCell-sizeSmall': {
      '&:first-child': {
        paddingLeft: 30,
      },

      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
}));

export const RiskMap = ({ title, locations, locationDefinitions = [] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isWebGLAvailable = useWebGLAvailable();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [viewport, setViewport] = useState({});
  const [activeMarkers, setActiveMarkers] = useState([]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setSize(rowsPerPage);
  };

  const paginationObj = {
    page: 0,
    rowsTotal: locations?.length,
    rowsPerPage: 10,
  };

  const pagination = usePagination(locations, paginationObj, handleChangePage, handleChangeRowsPerPage);

  const defaultSort = {
    by: 'id',
    direction: 'asc',
    type: 'numeric',
  };

  const columnItems =
    locationDefinitions?.arrayItemDef
      ?.filter((locationDefinition) => locationDefinition?.displayOnTable && locationDefinition?.type !== 'HIDDEN')
      ?.map((locationDefinition) => ({
        id: locationDefinition?.name,
        label: locationDefinition?.label,
        type: locationDefinition?.type,
        name: locationDefinition?.name,
        sort: {
          by: locationDefinition?.name,
          direction: 'asc',
          type: 'lexical',
        },
      })) || [];

  const columns = [
    { id: 'id', label: <Translate label="risks.building" />, sort: { by: 'id', type: 'numeric', direction: 'asc' } },
    ...columnItems,
    { id: 'buildingInfo', label: '' },
  ];

  const { cols, sort } = useSort(columns, defaultSort);
  const sortedLocations = locations.sort(firstBy(utils.sort.array(sort.type, sort.by, sort.direction)));

  const start = page * size;
  const end = start + size;

  const handleOpenTooltip = (open) => {
    setIsTooltipOpen(open);
  };

  const handleMarkerClick = ({ id }) => {
    const locationIndex = sortedLocations.findIndex((location) => location.id === id);

    setPage(Math.floor(locationIndex / size));
    setActiveMarkers([id]);
  };

  const handleRowClick = (location) => {
    setViewport({
      ...viewport,
      center: [location.longitude, location.latitude],
      zoom: config.mapbox.marker.maxZoom,
    });

    setActiveMarkers([location.id]);
  };

  const customTooltipComponent = ({ tooltip }) => {
    const title = `${tooltip.streetAddress}, ${tooltip.zip}, ${tooltip.city}, ${tooltip.county}, ${tooltip.state}`;

    return tooltip.buildingTitle ? <MapBoxTooltip title={tooltip.buildingTitle} list={[{ icon: <LocationOnIcon />, title }]} /> : null;
  };

  return (
    <Box
      style={{
        marginTop: '20px',
      }}
    >
      <Accordion
        type="solid"
        bgColor={theme.palette.grey[100]}
        icon={<ExpandMoreIcon />}
        iconPosition="right"
        titleComponent={
          <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600, flex: 1 }}>
            {startCase(toLower(title))}
          </Typography>
        }
        testid="risk-map"
      >
        {isWebGLAvailable ? (
          <Box
            style={{
              height: '400px',
              width: '100%',
            }}
          >
            <MapBox
              data-testid="mapbox"
              id="markers"
              height={400}
              activeMarkers={activeMarkers}
              onClickMarker={handleMarkerClick}
              fitBounds
              locations={sortedLocations}
              allowFullscreen
              allowSatelliteView
              allowScrollZoom
              allowMarkerClickZoom
              layers={mapLayers}
              tooltipComponent={customTooltipComponent}
              {...viewport}
            />
          </Box>
        ) : (
          <Box
            style={{
              marginBottom: '20px',
            }}
          >
            <Empty height={200} icon={<Error />} text={utils.string.t('app.webGlNotSupported')} />
          </Box>
        )}

        <Box>
          <Overflow>
            <Table size="small" classes={{ root: classes.root }}>
              <TableHead
                columns={cols}
                sorting={{ by: sort.by, direction: sort.direction }}
                nestedClasses={{ tableHead: classes.tableHead }}
              />
              <TableBody>
                {sortedLocations.slice(start, end).map((location, index) => (
                  <TableRow
                    key={location.id}
                    data-testid="location-row"
                    hover
                    selected={activeMarkers.includes(location.id)}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(location)}
                  >
                    {columns.map((column) => {
                      const value = location[column.id];

                      return (
                        <TableCell key={column.id} align="left" className={classes.tableCell}>
                          {column.id === 'buildingInfo' ? (
                            <LocationTooltip
                              location={location}
                              locationDefinitions={locationDefinitions}
                              excludeColumns={columns}
                              handleOpen={handleOpenTooltip}
                              disabled={isTooltipOpen}
                            />
                          ) : column.id === 'id' ? (
                            location.buildingTitle ?? `${index + 1}.`
                          ) : (
                            <Typography variant="body2">{renderValue(column, value)}</Typography>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              page={pagination.obj.page}
              count={locations?.length}
              rowsPerPage={pagination.obj.rowsPerPage}
              rowsPerPageOptions={paginationConfig?.rowsPerPageOptions}
              onChangePage={pagination.handlers.handleChangePage}
              onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
            />
          </Overflow>
        </Box>
      </Accordion>
    </Box>
  );
};
