import React from 'react';
import PropTypes from 'prop-types';

// app
import styles from './AddProductsInsured.styles';
import { Form } from 'components';

// mui
import { makeStyles } from '@material-ui/core';

AddProductsInsuredView.propTypes = {
  fields: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export function AddProductsInsuredView({ fields, actions, defaultValues, validationSchema }) {
  const classes = makeStyles(styles, { name: 'AddProductsInsured' })();

  return (
    <div className={classes.root}>
      <Form
        id="add-products-insured"
        type="dialog"
        fields={fields}
        actions={actions}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      />
    </div>
  );
}
