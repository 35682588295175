import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { adminAxios } from 'lib/admin/admin.api';
import { OPERATIONAL_MARKETS } from 'lib/admin/queryKeys';
import * as utils from 'utils';
import config from 'config';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';

export const useDeleteOperationalMarket = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const handleSettled = () => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useDeleteOperationalMarket',
      message: 'Data missing for DELETE request',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    err?.response?.data?.message
      ? dispatch(enqueueNotification(err?.response?.data?.message, 'error'))
      : dispatch(
          enqueueNotification('notification.admin.deleteOperationalMarketFail', 'error', {
            data: { market: err?.response?.data?.name },
          })
        );
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_MARKETS);
    history.push(config.routes.admin.operationalMarkets);

    dispatch(
      enqueueNotification('notification.admin.deleteOperationalMarketSuccess', 'success', {
        data: { market: data?.name },
      })
    );
  };

  return useMutation(
    async (id) => {
      return await adminAxios.delete(`marketParent/${id}`);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: handleSettled,
    }
  );
};
