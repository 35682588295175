import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { CircularProgress, Grid, Typography } from '@material-ui/core';

import { Button, FormCheckbox, FormSelect, FormText } from 'components';

import { BDX_TYPE_CLAIM } from 'consts';
import { useConfigContext } from 'config/Config.context';
import * as utils from 'utils';

import { ClaimBlockConfig, ThirdPartyConfig } from './components';
import { IFacilityConfigViewProps } from './FacilityConfig.types';

import { useFacilityConfigStyles } from './FacilityConfig.style';

export const FacilityConfigView: FC<IFacilityConfigViewProps> = ({
  fields,
  isLoadingFacilityConfig,
  methods,
  isSubmitting,
  umr,
  tab,
  onSubmit,
  handleClose,
}) => {
  const classes = useFacilityConfigStyles();
  const featuresConfig = useConfigContext();

  if (isLoadingFacilityConfig) {
    return (
      <div className={classes.loader} data-testid="loader-config">
        <CircularProgress />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.headerOverRows}>
          <Grid item>
            <Typography variant="body1">Header over</Typography>
          </Grid>
          <Grid item className={classes.headerOverWrapper}>
            <FormSelect
              nestedClasses={{ root: `${classes.field} ${classes.headerOverSelect}` }}
              control={methods.control}
              {...utils.form.getFieldProps(fields, 'noOfHeaderRows')}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item className={classes.headerOverLabel}>
            <Typography variant="body1">rows</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {!isEmpty(utils.form.getFieldProps(fields, 'clientDueDateOffset')) && (
            <Grid item>
              <FormText control={methods.control} {...utils.form.getFieldProps(fields, 'clientDueDateOffset')} disabled={isSubmitting} />
            </Grid>
          )}
          {!isEmpty(utils.form.getFieldProps(fields, 'dueDateOffset')) && (
            <Grid item>
              <FormText control={methods.control} {...utils.form.getFieldProps(fields, 'dueDateOffset')} disabled={isSubmitting} />
            </Grid>
          )}
        </Grid>
        <FormCheckbox
          nestedClasses={{ root: classes.field }}
          control={methods.control}
          {...utils.form.getFieldProps(fields, 'bdxCumulative')}
          disabled={isSubmitting}
        />
        {tab === BDX_TYPE_CLAIM && <ThirdPartyConfig fields={fields} methods={methods} isSubmitting={isSubmitting} />}
        {featuresConfig?.features?.ENABLE_NEW_CLAIMS_BLOCK && tab === BDX_TYPE_CLAIM && <ClaimBlockConfig umr={umr} />}
      </div>
      <Grid container className={classes.actions}>
        <Grid item className={classes.button}>
          <Button text={utils.string.t('app.cancel')} variant="text" onClick={handleClose} disabled={isSubmitting} />
        </Grid>
        <Grid item className={classes.button}>
          <Button text={utils.string.t('app.save')} color="primary" onClick={onSubmit} disabled={isSubmitting} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
