import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import { Link, Typography } from '@material-ui/core';

import { IBreadcrumbViewProps } from './Breadcrumb.types';

import { useBreadcrumbStyles } from './Breadcrumb.styles';

export const BreadcrumbView: FC<IBreadcrumbViewProps> = ({ links, path, testid }) => {
  const classes = useBreadcrumbStyles();

  return (
    <ul className={classes.list} data-testid={`breadcrumb${testid ? `-${testid}` : ''}`}>
      {links.map((item, index) => (
        <li className={classes.item} key={item.name}>
          {item.active && item.link === path ? (
            <Typography
              variant="body2"
              className={classnames(classes.text, { [classes.active]: item.active, [classes.largeFont || '']: item.largeFont })}
              data-testid={`${item.label}-Tab`}
            >
              {item.label}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              to={item.link}
              variant="body2"
              className={classnames(classes.text, classes.link, { [classes.active]: item.active })}
            >
              {item.label}
            </Link>
          )}
          {index < links.length - 1 && <span className={classes.separator} />}
        </li>
      ))}
    </ul>
  );
};
