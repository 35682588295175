import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';

// risks
export const useDeclineEndorsementQuote = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useDeclineEndorsementQuote',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));
    dispatch(hideModal());

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([QB_RISK_ENDORSEMENTS, res?.riskId], (oldData) => {
      return oldData?.map((endorsement) => {
        if (endorsement.id === res?.id) {
          return res;
        }
        return endorsement;
      });
    });

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.declineEndorsementQuote.success', 'success'));
  };

  return useMutation(
    async (params) => {
      const { endorsementId } = params;

      if (!endorsementId) {
        throw new Error('useDeclineEndorsementQuote: endorsementId is required');
      }

      const result = await quoteBindAxios.put(`/endorsements/${endorsementId}/decline`);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
