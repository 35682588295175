import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import get from 'lodash/get';

import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { QB_CLIENTS, QB_CLIENT } from 'lib/quoteBind/queryKeys';

export const usePostClient = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePostClient',
      message: 'There is an error when adding new client',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);

    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = ({ result, isEdit }) => {
    queryClient.invalidateQueries(QB_CLIENTS);
    queryClient.setQueryData([QB_CLIENT, result.data.id], result.data);

    const success = isEdit ? 'postEditSuccess' : 'postSuccess';
    const successNotification = `notification.client.${success}`;

    dispatch(enqueueNotification(successNotification, 'success'));
  };

  return useMutation(
    async (params) => {
      const { body, isCreateClientModal, isEdit = false } = params;

      if (!body || isEmpty(body)) {
        throw new Error('Data missing .');
      }

      const data = {
        ...(body?.id && { id: body.id }),
        name: body.name || '',
        clientOfficeId: get(body, 'clientOfficeId.value') ? get(body, 'clientOfficeId.value', '') : get(body, 'clientOfficeId', ''),
        productCode: get(body, 'productCode.value') ? get(body, 'productCode.value', '') : get(body, 'productCode', ''),
        address: {
          street: body.street || '',
          city: body.city || '',
          zipCode: body.zipCode || '',
          county: body.county || '',
          state: body.state || '',
          country: get(body, 'country.value', ''),
          distanceToCoast: '',
        },
        commission: {
          brokerageFee: body?.brokerageFee,
          clientCommissionRate: body?.clientCommissionRate,
          brokerCommissionRate: body?.brokerCommissionRate,
          reinsuranceRate: body?.reinsuranceRate,
        },
      };

      const result = await quoteBindAxios.post(`clients`, data);
      dispatch(hideModal(isCreateClientModal ? (isEdit ? 'EDIT_PRODUCTS_CLIENT' : 'ADD_PRODUCTS_CLIENT') : undefined));

      return { result, isEdit };
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
