/* eslint-disable react/prop-types */
import get from 'lodash/get';

import { Box, Typography } from '@material-ui/core';

// app
import { Tooltip } from 'components';

import * as utils from 'utils';

const getValue = (options, value) => {
  const option = options.find((option) => option.value === value || option.value === value?.toString());

  return option.label ?? value;
};

export const CoverageValues = ({ coverageValues, coverageDefinitionFields }) => (
  <Box
    style={{
      padding: 16,
    }}
  >
    {coverageDefinitionFields?.map((field) => {
      const value = field?.type === 'select' ? getValue(field.options, coverageValues[field.name]) : coverageValues[field.name];

      const condition = utils.risk.getCondition(field, coverageDefinitionFields);
      const refValueCondition = condition && get(coverageValues, `${condition.name}`);
      const isConditionValid = condition && utils.risk.isConditionValid(condition, refValueCondition);
      const isHidden = utils.risk.isHiddenField(field);
      const displayValue = utils.risk.renderRiskValue(field, value);

      return !isHidden && (condition === undefined || (condition && isConditionValid)) ? (
        <Box key={field.name}>
          <Tooltip title={field.label}>
            <Typography variant="body2">{displayValue}</Typography>
          </Tooltip>
        </Box>
      ) : null;
    })}
  </Box>
);
