import React, { useMemo } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

// MUI
import { Box, Card, CardHeader, CardContent, Typography, CircularProgress } from '@material-ui/core';

import { BDXCardItem } from './BDXCardItem';

export const BDXCard = ({ bdxInfo, uploadBDX, binderType, classes }) => {
  const allItems = useMemo(() => bdxInfo.items?.length, [bdxInfo.items]);

  const processed = useMemo(
    () => bdxInfo.items?.filter((i) => i.submissions?.length > 0 && i.submissions?.every((s) => s.status === 'PUBLISHED')).length,
    [bdxInfo.items]
  );

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        classes={{ root: classes.cardHeader }}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ paddingRight: '0.5rem' }}>
            <Box>
              <Typography data-testid="month" variant="h4" classes={{ root: classes.cardTitle }}>
                {get(bdxInfo, 'monthName', '-')}
              </Typography>
              <Typography variant="h5" classes={{ root: classes.subTitle }}>
                {get(bdxInfo, 'year', '-')}
              </Typography>
            </Box>
            <Box position="relative" display="inline-flex">
              <CircularProgress variant="determinate" value={(processed / allItems) * 100} />
              <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant={processed !== 0 ? 'caption' : 'h5'} color="textSecondary">
                  {`${processed}/${allItems}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      />

      <CardContent classes={{ root: classes.cardContent }}>
        {orderBy(bdxInfo.items, ['bdxType'], ['asc'])?.map((item, index) => (
          <BDXCardItem
            key={index}
            index={index}
            item={item}
            bdxInfo={bdxInfo}
            uploadBDX={uploadBDX}
            binderType={binderType}
            classes={classes}
          />
        ))}
      </CardContent>
    </Card>
  );
};
