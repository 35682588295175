import { forwardRef } from 'react';

// @mui
import { Box, BoxProps } from '@material-ui/core';

export const SvgColor = ({ src, sx, ...other }) => (
  <Box
    component="span"
    className="svg-color"
    sx={{
      width: 24,
      height: 24,
      display: 'inline-block',
      bgcolor: 'currentColor',
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
    {...other}
  />
);
