import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { UploadBDXContext } from 'forms/UploadBDX/UploadBDX.context';

import { UPLOAD_BDX_STEPS } from 'consts';
import { BDX_REQUIRED_BDX, BDX_UN_SUBMITTED_DATA, useSubmitBDXCoverHolder } from 'lib/binderManagement';
// app
import { enqueueNotification, fullScreenModal, hideModal } from 'stores';
import * as utils from 'utils';

import { SummaryCoverHolderView } from './SummaryCoverHolder.view';

export default function SummaryCoverHolder({ fullScreen, handlers, callbackOnSuccess, isTest, operationalClientId }) {
  const dispatch = useDispatch();
  const { mutateAsync: submitBDXCoverHolder } = useSubmitBDXCoverHolder();
  const queryClient = useQueryClient();

  const [fullView, setFullView] = useState(false);

  const {
    facility,
    sheetName,
    setRequestId,
    setRowId,
    setExpectedFields,
    setSpreadsheetFields,
    setSheetName,
    setStoredMappingValues,
    validatedCoverHolderData,
    bdxType,
    defaultBdxType,
  } = useContext(UploadBDXContext);

  const { groupId } = validatedCoverHolderData;

  const toggleFullScreen = () => {
    if (fullView === fullScreen) {
      dispatch(fullScreenModal());
    }

    setFullView((fv) => !fv);
  };

  const handleSubmit = async () => {
    try {
      handlers.setLoading(true);
      const { data } = await submitBDXCoverHolder({ groupId });

      if (data.status === 'MAPPING_REQUIRED') {
        setRequestId(data.requestId);
        setRowId(data.rowId);
        setExpectedFields(data.expectedMapperFields);
        setSpreadsheetFields(data.spreadsheetFields);
        setSheetName(data.sheetName);
        setStoredMappingValues({});
        handlers.getHandleSetStep(UPLOAD_BDX_STEPS.FIELD_MAPPING)();
        dispatch(enqueueNotification('notification.bdx.mappingRequired'));
      }

      if (data.status === 'SUCCESS') {
        setRequestId(data.requestId);
        setSheetName(data.sheetName);

        dispatch(enqueueNotification('notification.bdx.sheetSubmit', 'success'));
      }

      if (data.status === 'COMPLETED') {
        queryClient.invalidateQueries([BDX_UN_SUBMITTED_DATA, operationalClientId, bdxType || defaultBdxType]);
        dispatch(hideModal('UPLOAD_BDX_COVERHOLDER'));
        if (utils.generic.isFunction(callbackOnSuccess)) {
          callbackOnSuccess();
        }
        dispatch(enqueueNotification('notification.bdx.submitSuccess', 'success'));
      }

      queryClient.invalidateQueries([BDX_REQUIRED_BDX, facility.umr]);
    } finally {
      handlers.setLoading(false);
    }
  };

  return (
    <SummaryCoverHolderView
      toggleFullScreen={toggleFullScreen}
      fullScreen={fullScreen}
      fullView={fullView}
      handlers={handlers}
      handleSubmit={handleSubmit}
      sheetName={sheetName}
      isTest={isTest}
    />
  );
}
