const initialState = {
  selectedClient: null,
};

const partyReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PARTY_CLIENT':
      return {
        ...state,
        selectedClient: action.payload.client,
      };

    case 'PARTY_CLIENT_RESET':
      return {
        ...state,
        selectedClient: initialState.selectedClient,
      };

    default:
      return state;
  }
};

export default partyReducers;
