const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  text: {
    color: theme.palette.neutral.dark,
  },
  noResults: {
    marginTop: -theme.spacing(3),
  },
  noResultsLink: {
    cursor: 'pointer',
    fontSize: 16,
    width: 16,
    height: 16,
  },
});

export default styles;
