import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { ADD_LIMIT, EDIT_LIMIT, TBinderLimitAction } from 'consts';
import { useCreateLimit, useGetBdxTypes, useGetLimitColumns } from 'lib/binderManagement';
import * as utils from 'utils';

import { USD_CURRENCY } from './components/SumColumns/SumColumn.const';
import { IAddBinderLimitForm, IAddBinderLimitProps, TAddBinderLimitFields } from './AddBinderLimit.types';
import { AddBinderLimitView } from './AddBinderLimit.view';
import { AddBinderLimitSkeleton } from './AddBinderLimitSkeleton';

export const AddBinderLimit: FC<IAddBinderLimitProps> = ({ limit, facility }) => {
  const [selectedBdxType, setSelectedBdxType] = useState(limit?.bdxType || null);

  const getSelectOptions = (options: string[]) =>
    options?.map((option, index) => ({
      label: option,
      id: index + 1,
    }));

  const { data: bdxTypes, isLoading: bdxTypesIsLoading } = useGetBdxTypes(facility.riskCodes?.map(({ code }) => code) || []);
  const bdxOptions = getSelectOptions(bdxTypes);

  const { data: limitColumns, isLoading: limitColumnsIsLoading } = useGetLimitColumns(
    {
      riskCodes: facility?.riskCodes?.map(({ code }) => code).join(','),
      bdxType: selectedBdxType,
    },
    {
      enabled: !!selectedBdxType,
    }
  );

  const { mutateAsync: addLimit } = useCreateLimit({ facility });

  const methods = useForm<IAddBinderLimitForm>({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      conditions: [],
      sumColumns: [],
    },
  });

  const onSelectBdxType = (fieldName: string, value: number) => {
    const newBDXType = bdxOptions.find((bdx) => bdx.id === value).label;
    methods.resetField('conditions');
    methods.resetField('sumColumns');
    setSelectedBdxType(newBDXType);
  };

  const defaultBdxType = limit?.bdxType && bdxOptions ? bdxOptions.find((o) => o.label === limit.bdxType).id : null;
  const limitColumnsFiltered = limitColumns?.filter((column) => column.type === 'NUMBER');

  const fields: TAddBinderLimitFields = [
    {
      name: 'name',
      type: 'text',
      validation: Yup.string().required(utils.string.t('validation.required')),
      label: utils.string.t('binderLimits.addLimits.name'),
      defaultValue: limit?.name,
    },
    {
      name: 'bdxType',
      label: utils.string.t('form.bdx.bdxType'),
      type: 'select',
      optionKey: 'id',
      optionLabel: 'label',
      options: bdxOptions || [],
      getOptionSelected: (option, value) => option.id === value.id,
      validation: Yup.string().required(utils.string.t('validation.required')),
      defaultValue: defaultBdxType,
    },
    limitColumns && {
      name: 'conditionType',
      type: 'select',
      optionKey: 'id',
      optionLabel: 'label',
      options: [
        { id: 'ANY', label: 'any of' },
        { id: 'ALL', label: 'all of' },
      ],
      getOptionSelected: (option, value) => option.id === value.id,
      validation: Yup.string().required(utils.string.t('validation.required')),
      defaultValue: limit?.conditionType || 'ANY',
    },
    {
      name: 'values',
      type: 'multiText',
      label: utils.string.t('binderLimits.value'),
    },
    {
      name: 'value1',
      type: 'number',
      label: utils.string.t('binderLimits.value'),
    },
    {
      name: 'dateValue',
      label: utils.string.t('binderLimits.date'),
      type: 'datepicker',
      placeholder: 'DD/MM/YYYY',
      muiPickerProps: {
        format: 'DD-MM-yyyy',
      },
    },
    {
      name: 'delimiter',
      type: 'text',
      label: utils.string.t('binderLimits.delimiter'),
    },
  ];

  const { errors } = methods.formState;

  const submit = async () => {
    const formData = methods.getValues();
    const { conditions, sumColumns: formSumColumns } = formData;
    const newLimit: IBinderLimitDTO = {
      ...formData,
      id: limit?.id ? limit.id : null,
      bdxType: selectedBdxType,
      conditions: conditions.map((condition) => ({
        column: condition.field.name || null,
        condition: condition.operation || null,
        inputs: condition.inputs.map((input, index) => ({ index, value: input.value, delimiter: null })) || null,
      })),
      sumColumns: [...formSumColumns.map((column) => column.field.name)],
      limit: formSumColumns[0].limit ? Number(formSumColumns[0].limit) : null,
      limitAlertAtPercentage: formSumColumns[0].limitAlertAtPercentage ? Number(formSumColumns[0].limitAlertAtPercentage) : null,
      currency: USD_CURRENCY.value,
      conditionType: formData.conditionType || null,
    };

    const action: TBinderLimitAction = limit ? EDIT_LIMIT : ADD_LIMIT;
    const data = limit ? newLimit : [newLimit];
    await addLimit({ data, action });
  };

  return limitColumnsIsLoading || bdxTypesIsLoading || (limit && !defaultBdxType) ? (
    <AddBinderLimitSkeleton />
  ) : (
    <AddBinderLimitView
      fields={fields}
      methods={methods}
      errors={errors}
      limitColumns={limitColumns}
      limitColumnsFiltered={limitColumnsFiltered}
      limit={limit?.bdxType === selectedBdxType ? limit : null}
      onSelectBdxType={onSelectBdxType}
      submit={submit}
    />
  );
};
