import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { ADD_LIMIT, DELETE_LIMIT, EDIT_LIMIT, FAVOURITE_LIMIT, IMPORT_LIMITS, UNFAVOURITE_LIMIT } from 'consts';
import { enqueueNotification, hideModal } from 'stores';

import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_LIMITSETS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useCreateLimit = ({ facility }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.drools.mutations.createLimit',
    message: 'API fetch error (binderManagement.drools.post)',
    warning: 'notification.binderManagement.binderLimits.createLimit.fail',
  });

  const handleSuccess = ({ data, action }) => {
    queryClient.invalidateQueries([DROOLS_LIMITSETS, { facilityUMR: facility.umr }]);

    dispatch(hideModal());

    switch (action) {
      case ADD_LIMIT:
        dispatch(enqueueNotification('binderLimits.createLimitSuccess', 'success'));
        break;
      case EDIT_LIMIT:
        dispatch(enqueueNotification('binderLimits.editLimitSuccess', 'success'));
        break;
      case DELETE_LIMIT:
        dispatch(enqueueNotification('binderLimits.deleteLimitSuccess', 'success'));
        break;
      case FAVOURITE_LIMIT:
        dispatch(enqueueNotification('binderLimits.favouriteLimitSuccess', 'success'));
        break;
      case UNFAVOURITE_LIMIT:
        dispatch(enqueueNotification('binderLimits.favouriteLimitRemovedSuccess', 'success'));
        break;
      case IMPORT_LIMITS:
        dispatch(enqueueNotification('binderLimits.importLimitsSuccess', 'success'));
        break;
      default:
        break;
    }
  };

  return useMutation<ICreateLimit, AxiosError, ICreateLimit>(
    async ({ data, action }) => {
      let result;
      if (Array.isArray(data) && (action === ADD_LIMIT || action === IMPORT_LIMITS)) {
        result = await binderManagementDroolsAxios.post(`/limits?facilityUMR=${facility?.umr}`, data);
      } else if (!Array.isArray(data)) {
        switch (action) {
          case EDIT_LIMIT:
            result = await binderManagementDroolsAxios.put(`/limits/${data?.id}`, data);
            break;
          case DELETE_LIMIT:
            result = await binderManagementDroolsAxios.delete(`/limits/${data?.id}`);
            break;
          case FAVOURITE_LIMIT:
            result = await binderManagementDroolsAxios.patch(`/limits/${data?.id}`, { favourite: true });
            break;
          case UNFAVOURITE_LIMIT:
            result = await binderManagementDroolsAxios.patch(`/limits/${data?.id}`, { favourite: false });
            break;
          default:
            break;
        }
      }
      return { data: result.data, action };
    },
    {
      onSuccess: (result) => handleSuccess({ data: result?.data, action: result?.action }),
      onError: handleError,
    }
  );
};
