import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// edge
import { useAssignLinesToFacility } from 'lib/binderManagement';
import { Button, FormContainer, FormActions, FormGrid, FormFields, FormAutocompleteMui } from 'components';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

export const AssignToFacility = ({ selectedRowKeys, groupId, requestId, allFacilities, handleClose, handleSuccess }) => {
  const classes = useStyles();
  const { mutateAsync: assignToFacility, isLoading } = useAssignLinesToFacility();

  const allFacilitiesOptions = allFacilities?.map((facility) => ({
    label: facility,
    value: facility,
  }));

  const defaultValues = {
    facility: null,
  };

  const validationSchema = yup
    .object()
    .shape({
      facility: yup.object().nullable().required(utils.string.t('validation.required')),
    })
    .required();

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onCancel = () => {
    handleClose();
  };

  const onSubmit = async (data) => {
    const formData = {
      facilityReference: data.facility?.value,
      groupId,
      requestId,
      rowIds: selectedRowKeys,
    };

    await assignToFacility(formData);
    handleSuccess();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <FormContainer type="default" onSubmit={handleSubmit(onSubmit)} data-testid="form-assign-to-facility">
        {allFacilities?.length ? (
          <FormFields type="default">
            <FormGrid container>
              <FormGrid item xs={12}>
                <FormAutocompleteMui
                  control={control}
                  name="facility"
                  label={utils.string.t('form.assignToFacility.title')}
                  options={allFacilitiesOptions}
                  error={errors.facility}
                />
              </FormGrid>
            </FormGrid>
          </FormFields>
        ) : (
          <Typography variant="h2" style={{ fontWeight: 500, fontSize: 14 }}>
            {utils.string.t('form.assignToFacility.noFacilities')}
          </Typography>
        )}

        <FormActions>
          <Button text={utils.string.t('app.cancel')} variant="text" disabled={formState.isSubmitting || isLoading} onClick={onCancel} />
          <Button
            text={utils.string.t('app.assign')}
            type="submit"
            disabled={formState.isSubmitting || isLoading || allFacilities?.length === 0}
            color="primary"
          />
        </FormActions>
      </FormContainer>
    </div>
  );
};
