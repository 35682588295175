import { useState, useEffect } from 'react';

// app
import { QuoteIssuesView } from './QuoteIssues.view';
import { useUpdateIssue, useUpdateAllIssues } from 'lib/quoteBind';

export default function QuoteIssues({
  riskIssues,
  quoteIssues,
  insuredSanctionsCheckResult,
  reInsuredSanctionsCheckResult,
  canCurrentUserDismissIssues,
  handleRiskRefresh,
  quoteId,
}) {
  const [quoteIssuesState, setQuoteIssuesState] = useState(quoteIssues);
  const [isWaiting, setIsWaiting] = useState(false);
  const { mutateAsync: updateIssue } = useUpdateIssue();
  const { mutateAsync: updateAllIssues } = useUpdateAllIssues();

  useEffect(() => {
    const allIssuedPassed = quoteIssuesState?.filter((issue) => issue.issueStatus === 'PASSED').length;

    allIssuedPassed === quoteIssuesState?.length && quoteIssuesState?.length > 0 && handleRiskRefresh();
  }, [quoteIssuesState, handleRiskRefresh]);

  const handleUpdateIssue = async (id, status) => {
    if (quoteId) {
      setIsWaiting(true);
      const result = await updateIssue({ id, status, quoteId });
      const updatedIssues = quoteIssuesState.map((issue) => (issue.id === result?.id ? result : issue));
      setQuoteIssuesState(updatedIssues);
      setIsWaiting(false);
    }
  };

  const handleUpdateAllIssues = async (status) => {
    if (quoteId) {
      setIsWaiting(true);
      const result = await updateAllIssues({ issueIds: quoteIssuesState.map((issue) => issue.id), status, quoteId });
      const updatedIssues = quoteIssuesState.map((issue) =>
        result?.succeeded?.includes(issue.id)
          ? {
              ...issue,
              issueStatus: status,
            }
          : issue
      );
      setQuoteIssuesState(updatedIssues);
      setIsWaiting(false);
    }
  };

  return (
    <QuoteIssuesView
      riskIssues={riskIssues}
      quoteIssues={quoteIssuesState}
      insuredSanctionsCheckResult={insuredSanctionsCheckResult}
      reInsuredSanctionsCheckResult={reInsuredSanctionsCheckResult}
      handleUpdateIssue={handleUpdateIssue}
      handleUpdateAllIssues={handleUpdateAllIssues}
      canCurrentUserDismissIssues={canCurrentUserDismissIssues}
      isWaiting={isWaiting}
    />
  );
}
