import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { binderManagementDroolsAxios } from 'lib/binderManagement';
import { enqueueNotification } from 'stores';
import * as utils from 'utils';

export const useDownloadRules = (): IDownloadRulesResult => {
  const [isDownloadingRules, setIsDownloadingRules] = useState(false);
  const dispatch = useDispatch();

  const handleDownloadRules = async ({ rulesFile }: IDownloadRulesDTO) => {
    setIsDownloadingRules(true);
    try {
      const { data } = await binderManagementDroolsAxios.get(`/drools/file/${rulesFile}`, {
        responseType: 'blob',
      });

      utils.file.download(data, rulesFile);
    } catch (e) {
      dispatch(enqueueNotification('notification.binderManagement.documentDownloadFail', 'error'));
    }
    setIsDownloadingRules(false);
  };

  return { handleDownloadRules, isDownloadingRules };
};
