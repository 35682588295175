import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFileInputLineageAxios } from '../../binderManagement.api';
import { LINEAGE_YEAR_MONTH } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitLineageDocument = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useSubmitLineageDocument',
    warning: 'notification.lineage.submitFail',
  });

  const handleSuccess = ({ yearMonthSubmission }: ILineageDetailsDTO) => {
    const yearMonthStart = `${yearMonthSubmission.split('-')[0]}-01`;
    queryClient.invalidateQueries([LINEAGE_YEAR_MONTH, yearMonthStart]);

    dispatch(enqueueNotification('notification.lineage.submitSuccess', 'success'));
  };

  return useMutation<ILineageDetailsDTO, AxiosError, ILineageDetailsDTO>(
    async (config) => {
      await binderManagementFileInputLineageAxios.post('details', config);

      return config;
    },
    { onError: handleError, onSuccess: handleSuccess }
  );
};
