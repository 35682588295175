import { map } from 'lodash';
import { useState, useMemo, useRef, useContext } from 'react';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';

// MUI
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import { Box, Tooltip, Grid, IconButton, CircularProgress, Typography, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

// App
import { usePagination } from 'hooks';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';

import { Pagination, Empty, Button } from 'components';
import { useDismissBDXSanctionException, useDismissBDXValidationException, useGetBdxData } from 'lib/binderManagement';
import * as utils from 'utils';
import { useBDXExceptionsStyles, useSanctionsAlertStyles } from './useBDXExceptionsStyles';
import { useMountDelay } from '../useMountDelay';
import { BDXExtractionContext } from '../../BDXExtraction.context';
import { Error } from '@material-ui/icons';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { PROCESSED } from 'consts/binder-management';

const HEADER_HEIGHT = 55;
const MAX_ROWS = 12;

export const BDXExceptions = ({ bdxDocumentsInfo, tabId, tab, errors, warnings, sanctions }) => {
  const classes = useBDXExceptionsStyles();
  const { activeBDX } = useContext(BDXExtractionContext);
  const { mutateAsync: dismissValidation, isLoading: isLoadingValidation } = useDismissBDXValidationException();
  const { mutateAsync: dismissSanction, isLoading: isLoadingSanction } = useDismissBDXSanctionException();
  const isLoading = isLoadingValidation || isLoadingSanction;
  const ref = useRef(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);
  const mountDelay = useMountDelay();

  const { data, isFetching } = useGetBdxData({
    bdxDocumentsInfo,
    requestId: activeBDX.requestId,
    page: page,
    size: size,
    exceptionType: tabId === 'sanctions-content' ? 'SANCTIONS' : 'PARSING',
  });
  const isSanctions = useMemo(() => tabId === 'sanctions-content', [tabId]);

  const goToSanctionsUrl = (url) => {
    url && window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setSize(rowsPerPage);
  };
  const pagination = usePagination(data?.content, utils.generic.getPaginationObj(data), handleChangePage, handleChangeRowsPerPage);

  const renderEmpty = () => {
    if (isFetching) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }
    return <Empty width={340} title={utils.string.t('app.noWarnings')} icon={<SearchResultImage />} padding />;
  };

  const getExceptionWidth = ({ errors, warnings }) => {
    if (errors) {
      return 120;
    }
    if (warnings) {
      return 90;
    }
    return 50;
  };

  const getDismissButton = () => {
    if (mountDelay || isLoading || isFetching) {
      return false;
    }
    if (activeBDX?.status !== PROCESSED) {
      return false;
    }
    if (tabId === 'sanctions-content' && sanctions) {
      return true;
    }
    if (errors || warnings) {
      return true;
    }
    return false;
  };

  const columns = useMemo(() => {
    if (data?.content?.length > 0) {
      const content = map(data.content[0].cells, 'columnName');
      if (content?.length > 0) {
        return content.map((c, index) => ({
          key: `field_${index}`,
          title: c,
          dataKey: `field_${index}`,
          resizable: true,
          width: 150,
          flex: 1,
          align: 'left',
          sortable: true,
        }));
      }
    }
    return [];
  }, [data?.content]);

  const rows = useMemo(() => {
    if (!columns || columns?.length === 0) {
      return [];
    }

    if (data?.content?.length > 0) {
      const rows = data.content.map((row) => {
        if (row.cells?.length > 0) {
          let newRow = { id: row.rowId };

          columns.forEach((column, index) => {
            const record = row.cells?.find((col) => column.title === col.columnName);

            const exceptions =
              row.cells
                ?.filter((cell) => cell.exceptions?.length > 0)
                .map((cell) => {
                  return {
                    exceptions: cell.exceptions,
                    columnName: cell.columnName,
                  };
                }) || [];

            const sanction = {
              dismissedOn: row.dismissedOn,
              sanctionUrl: row.sanctionUrl,
              sanctionMessage: row.sanctionMessage,
              rowId: row.rowId,
            };

            newRow = { ...newRow, [`field_${index}`]: record?.columnValue || '', exceptions, sanction };
          });

          return newRow;
        }

        return [];
      });
      return rows;
    }
  }, [data?.content, columns]);

  const Cell = (params) => {
    const { column, cellData, rowData } = params;
    const cells = data?.content?.find((r) => r.rowId === rowData?.id)?.cells;
    const exceptions = cells?.find((c) => c.columnName === column?.title)?.exceptions;
    const exes = map(exceptions, 'message');
    const message = exes?.join('\r\n');

    if (exceptions?.length > 0 && tabId === 'validations-content') {
      if (exceptions.some((ex) => ex.level === 'ERROR')) {
        return (
          <Tooltip title={<div className={classes.tooltip}>{message}</div>} placement="top-start">
            <Typography className={classes.errorCell}>{cellData}</Typography>
          </Tooltip>
        );
      }

      return (
        <Tooltip title={<div className={classes.tooltip}>{message}</div>} placement="top-start">
          <Typography className={classes.warningCell}>{cellData}</Typography>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={cellData ? cellData : ''} placement="top-start">
        <Typography className={classes.wordWrap}>{cellData}</Typography>
      </Tooltip>
    );
  };

  const getHandleDismissValidation = (column) => async () => {
    await dismissValidation({ requestId: activeBDX.requestId, columnName: column.title });
  };
  const handleDismissAll = async () => {
    if (isSanctions) {
      await dismissSanction({ requestId: activeBDX.requestId });
    } else {
      await dismissValidation({ requestId: activeBDX.requestId });
    }
  };

  const TableHeaderCell = ({ className, column }) => {
    const exceptions = data?.content.filter(({ cells }) => {
      const cell = cells.find(({ columnName }) => columnName === column.title);

      return cell?.exceptions.filter(({ dismissedOn }) => !dismissedOn).length > 0;
    });

    if (exceptions?.length > 0) {
      return (
        <Tooltip title={column?.title ? column.title : ''}>
          <div className={`${className} ${classes.titleWithMenu}`}>
            {column.title}
            <div className={classes.titleMenu}>
              <DismissButton classes={classes} handleDismiss={getHandleDismissValidation(column)} />
            </div>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={column?.title ? column.title : ''}>
        <div className={className}>{column.title}</div>
      </Tooltip>
    );
  };

  const handleScrollToOffset = (offset) => {
    ref.current.scrollToLeft(offset);
  };

  return (
    <div className={classes.root} data-testid={`bdx-exceptions-${tabId}`}>
      <Box className={classes.header} display="flex" alignContent="center" justifyContent="space-between">
        <Box display="flex" alignContent="center">
          {tab.tabIcon}
          <Typography variant="h2" className={classes.title}>
            {tab.label}
          </Typography>
        </Box>

        {getDismissButton() && (
          <Button
            size="small"
            onClick={handleDismissAll}
            text="Dismiss All"
            variant="contained"
            color="secondary"
            classes={{ btn: classes.actionButton }}
            disabled={isLoading}
            icon={isLoading ? () => <CircularProgress size={20} className={classes.downloadIcon} /> : Error}
            iconPosition="right"
          />
        )}
      </Box>
      <div className={classes.baseTable}>
        {mountDelay || isLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <AutoResizer height={MAX_ROWS * (HEADER_HEIGHT + 1)}>
            {({ width, height }) => (
              <BaseTable
                fixed
                data={isFetching ? [] : rows}
                rowKey="id"
                ref={ref}
                emptyRenderer={renderEmpty}
                headerHeight={HEADER_HEIGHT}
                width={width}
                height={height}
                components={{ TableCell: Cell, TableHeaderCell }}
              >
                {isSanctions ? (
                  <Column
                    key="scrollNext"
                    width={getExceptionWidth({ warnings: sanctions })}
                    frozen={Column.FrozenDirection.LEFT}
                    cellRenderer={(params) => {
                      return params.rowData.sanction ? (
                        <SanctionsAlert
                          sanctionData={params.rowData.sanction}
                          goToSanctionsUrl={goToSanctionsUrl}
                          requestId={activeBDX.requestId}
                          status={activeBDX.status}
                        />
                      ) : null;
                    }}
                  />
                ) : (
                  <Column
                    key="scrollNext"
                    width={getExceptionWidth({ warnings, errors })}
                    frozen={Column.FrozenDirection.LEFT}
                    cellRenderer={(params) => (
                      <ValidationAlert
                        params={params}
                        columns={columns}
                        handleScrollToOffset={handleScrollToOffset}
                        requestId={activeBDX.requestId}
                        status={activeBDX.status}
                        rowId={params.rowData?.id}
                      />
                    )}
                  />
                )}

                {columns.map(({ dataKey, ...restProps }) => {
                  return <Column key={dataKey} dataKey={dataKey} {...restProps} />;
                })}
              </BaseTable>
            )}
          </AutoResizer>
        )}
      </div>
      <Grid container data-testid="pagination">
        <Grid item xs={12} sm={12}>
          <Pagination
            page={pagination.obj.page}
            count={pagination.obj.rowsTotal}
            rowsPerPage={pagination.obj.rowsPerPage}
            rowsPerPageOptions={[12, 24, 48, 96]}
            onChangePage={pagination.handlers.handleChangePage}
            onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ValidationAlert = ({ params, columns, requestId, status, rowId, handleScrollToOffset }) => {
  const classes = useSanctionsAlertStyles();
  const [exceptionsOffset, setExceptionsOffset] = useState(0);
  const [errorsOffset, setErrorsOffset] = useState(0);
  const { mutateAsync: dismissValidation } = useDismissBDXValidationException();

  const { exceptions } = params?.rowData;

  const allExceptions = exceptions
    .map((exception) => {
      return {
        columnName: exception.columnName,
        exceptions: exception.exceptions.filter((ex) => ex.level === 'WARN') || [],
      };
    })
    .filter((ex) => ex.exceptions.length > 0);

  const allErrors = exceptions
    .map((exception) => {
      return {
        columnName: exception.columnName,
        exceptions: exception.exceptions.filter((ex) => ex.level === 'ERROR') || [],
      };
    })
    .filter((ex) => ex.exceptions.length > 0);

  const hasExceptions = allExceptions?.filter((ex) => ex.exceptions.filter(({ dismissedOn }) => !dismissedOn).length > 0).length > 0;
  const hasErrors = allErrors?.filter((ex) => ex.exceptions.filter(({ dismissedOn }) => !dismissedOn).length > 0).length > 0;

  const scrollToException = () => {
    const { columnName } = allExceptions[exceptionsOffset];
    const toOffset = columns?.findIndex((col) => col.title === columnName) * 150;

    handleScrollToOffset(toOffset);
    setExceptionsOffset((prev) => (prev === allExceptions?.length - 1 ? 0 : prev + 1));
  };

  const scrollToError = () => {
    const { columnName } = allErrors[errorsOffset];
    const toOffset = columns?.findIndex((col) => col.title === columnName) * 150;

    handleScrollToOffset(toOffset);
    setErrorsOffset((prev) => (prev === allErrors?.length - 1 ? 0 : prev + 1));
  };

  const exceptionsMessage = allExceptions?.map((ex) => ex?.exceptions.map((e) => e.message).join('\r\n')).join('\r\n');
  const errorsMessage = allErrors?.map((ex) => ex?.exceptions.map((e) => e.message).join('\r\n')).join('\r\n');

  if (!hasExceptions && !hasErrors) {
    return <CheckCircle className={classes.check} />;
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        {hasExceptions ? (
          <Tooltip title={exceptionsMessage} placement="top-start">
            <IconButton className={classes.warningIconButton} onClick={scrollToException}>
              <ErrorIcon className={classes.warningIcon} />
            </IconButton>
          </Tooltip>
        ) : (
          <span style={{ width: 30 }} />
        )}
      </Grid>
      <Grid item xs={4}>
        {hasErrors ? (
          <Tooltip title={errorsMessage} placement="top-start">
            <IconButton className={classes.warningIconButton} onClick={scrollToError}>
              <ErrorIcon className={classes.errorIcon} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Grid>
      <Grid item xs={4}>
        {(hasErrors || hasExceptions) && status === PROCESSED ? (
          <DismissButton classes={classes} handleDismiss={() => dismissValidation({ rowId, requestId })} />
        ) : null}
      </Grid>
    </Grid>
  );
};

const SanctionsAlert = ({ goToSanctionsUrl, sanctionData, requestId, status }) => {
  const { sanctionUrl, sanctionMessage, rowId, dismissedOn } = sanctionData;
  const classes = useSanctionsAlertStyles({ sanctionUrl });
  const { mutateAsync: dismissSanction } = useDismissBDXSanctionException();

  if (dismissedOn) {
    return <CheckCircle className={classes.check} />;
  }

  return sanctionMessage ? (
    <Grid container>
      <Grid item xs={6}>
        <Tooltip title={sanctionMessage} placement="top-start">
          <IconButton className={classes.warningIconButton} onClick={() => sanctionUrl && goToSanctionsUrl(sanctionUrl)}>
            <WarningIcon className={classes.warningIcon} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        {status === PROCESSED && <DismissButton classes={classes} handleDismiss={() => dismissSanction({ rowId, requestId })} />}
      </Grid>
    </Grid>
  ) : null;
};

const DismissButton = ({ handleDismiss, classes }) => {
  const menuRef = useRef(null);
  const [menuActive, setMenuActive] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      handleClose();
      await handleDismiss();
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setMenuActive(true);
  const handleClose = () => setMenuActive(false);

  return (
    <>
      {isLoading ? (
        <div className={classes.menuLoaderWrapper}>
          <CircularProgress size={25} color="secondary" />
        </div>
      ) : (
        <IconButton className={classes.warningIconButton} onClick={handleOpen} ref={menuRef}>
          <MoreVert />
        </IconButton>
      )}
      <Menu anchorEl={menuRef.current} getContentAnchorEl={null} open={Boolean(menuActive && menuRef?.current)} onClose={handleClose}>
        <MenuItem onClick={handleClick}>{utils.string.t('app.dismiss')}</MenuItem>
      </Menu>
    </>
  );
};
