import { useQuery } from 'react-query';
import flatten from 'lodash/flatten';

// app
import { adminAxios } from '../../admin.api';
import { CLIENT_OFFICE_LIST } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

export const useGetParentOfficeListAll = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getParentOfficeListAll',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [CLIENT_OFFICE_LIST, 'all'],
    async () => {
      const allOffices = await adminAxios.get('client/operational/all/offices');

      if (!allOffices?.data?.length) return;

      const offices = allOffices?.data?.map((operational) => {
        return operational.offices.map((office) => ({
          id: office.id,
          name: `${operational.name} - ${office.name}`,
        }));
      });
      return flatten(offices);
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
