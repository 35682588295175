import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import _orderBy from 'lodash/orderBy';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
// MUI
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CheckCircle } from '@material-ui/icons';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloudUpload from '@material-ui/icons/CloudUpload';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PublishIcon from '@material-ui/icons/Publish';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
// Edge
import { Avatar, Button, Empty, Pagination, PopoverMenu, Tooltip } from 'components';

import { BDX_TYPE_CLAIM, BLOCKED, PROCESSED, PUBLISHED } from 'consts';
import {
  BDX_EXCEPTIONS_COUNT,
  useDeleteReport,
  useGetExceptionsCount,
  useGetIfSubmissionExistsForFacility,
  useGetUploadedBdx,
  useReSubmitBDX,
  useSubmitReport,
  useUnpublishReport,
} from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { BDXExtractionContext } from '../../../../BDXExtraction.context';

import { BDXUploadCardSkeleton } from './BDXUploadCardSkeleton';

import { StyledBDXUploadCardTableRow, useBDXUploadCardStyles } from './BDXUploadCard.styles';

const ROWS_PER_PAGE = 5;

export const BDXUploadCard = ({ bdxDocumentsInfo, uploadBDX, handleDownload }) => {
  const { activeBDX, setActiveBDX, initialBDX } = useContext(BDXExtractionContext);

  const classes = useBDXUploadCardStyles();
  const theme = useTheme();
  const [submissions, setSubmissions] = useState([]);
  const [page, setPage] = useState(0);

  const { facilityReference } = bdxDocumentsInfo;

  const { data: uploadedBdx, isLoading: isLoadingBdx } = useGetUploadedBdx({ bdxDocumentsInfo });
  const { mutate: submitReport, isLoading: isSubmitting } = useSubmitReport();
  const { mutate: unpublishReport, isLoading: isUnpublishing } = useUnpublishReport();
  const { mutate: deleteReport, isLoading: isDeleting } = useDeleteReport();
  const { data: isAllowedToAddBdx } = useGetIfSubmissionExistsForFacility(bdxDocumentsInfo);

  const isDisabled = isSubmitting || isDeleting || isUnpublishing;
  useEffect(() => {
    const bdxSubmissions = utils.generic.isValidArray(uploadedBdx, true)
      ? uploadedBdx[0]?.items?.[0]?.items?.find((item) => item?.bdxType === bdxDocumentsInfo?.bdxType)?.submissions
      : [];

    setSubmissions(bdxSubmissions);
    if (bdxSubmissions.slice(-1) && !initialBDX) {
      setActiveBDX({
        requestId: bdxSubmissions?.slice(-1)?.requestId,
        status: bdxSubmissions?.slice(-1)?.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bdxDocumentsInfo?.bdxType, uploadedBdx, initialBDX]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleSubmitReport = (requestId) => {
    submitReport({ requestId, facilityReference, callbackOnSuccess: handleSubmitSuccess });
  };

  const handleUnpublishReport = (requestId) => {
    unpublishReport({ requestId, facilityReference, callbackOnSuccess: handleSubmitSuccess });
  };

  useEffect(() => {
    if (submissions?.length > 0) {
      setActiveBDX({ requestId: submissions[submissions.length - 1].requestId, status: submissions[submissions.length - 1].status });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissions]);

  const handleSetActiveBDX = (requestId, status) => {
    if (requestId === activeBDX.requestId) {
      setActiveBDX({ requestId: null, status: null });
    } else {
      setActiveBDX({ requestId, status });
    }
  };

  const handleSubmitSuccess = (data) => {
    setSubmissions((prevSubmissions) => {
      const submissionIndex = prevSubmissions.findIndex((submission) => submission.requestId === data.requestId);
      const updatedSubmissions = [...prevSubmissions];
      updatedSubmissions[submissionIndex] = {
        ...updatedSubmissions[submissionIndex],
        status: data?.code,
      };
      return updatedSubmissions;
    });
  };

  const handleDeleteReport = (requestId) => {
    deleteReport({ requestId, facilityReference, bdxDocumentsInfo, callbackOnSuccess: handleDeleteReportSuccess });
  };

  const handleDeleteReportSuccess = (requestId) => {
    setSubmissions((prevSubmissions) => prevSubmissions.filter((submission) => submission.requestId !== requestId));
  };

  if (isLoadingBdx) {
    return <BDXUploadCardSkeleton bdxItem={bdxDocumentsInfo} />;
  }

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} elevation={2} className={classes.tableContainer} data-testid="bdx-upload-card">
        {submissions?.length === 0 && (
          <Empty title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} width={300} />
        )}
        {submissions?.length > 0 && (
          <Table className={classes.table} aria-label="BDX overview table">
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Tab Name</TableCell>
                {bdxDocumentsInfo?.bdxType === BDX_TYPE_CLAIM && <TableCell>TPA</TableCell>}
                <TableCell>Uploaded</TableCell>
                <TableCell>Validation</TableCell>
                <TableCell>Sanction</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions?.length > 0 ? (
                <>
                  {_orderBy(submissions, ['submittedOn'], ['desc'])
                    .slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE)
                    .map((submission) => (
                      <SubmissionRow
                        key={submission.requestId}
                        submission={submission}
                        bdxInfo={bdxDocumentsInfo}
                        uploadBDX={uploadBDX}
                        handleDeleteReport={handleDeleteReport}
                        handleDownload={handleDownload}
                        handleSubmitReport={handleSubmitReport}
                        handleSetActiveBDX={handleSetActiveBDX}
                        handleUnpublishReport={handleUnpublishReport}
                        isDisabled={isDisabled}
                        isAllowedToAddBdx={isAllowedToAddBdx}
                        activeBDX={activeBDX}
                      />
                    ))}
                </>
              ) : null}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {submissions?.length > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          <div>
            {isAllowedToAddBdx && (
              <Button
                size="small"
                onClick={() => uploadBDX(bdxDocumentsInfo?.yearMonthSubmission, bdxDocumentsInfo?.bdxType, null, null, null)}
                icon={CloudUpload}
                iconPosition="right"
                text="Add BDX"
                variant="contained"
                color="primary"
                data-testid="bdx-upload-button"
              />
            )}
          </div>
          <Pagination
            page={page}
            count={submissions?.length}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[]}
            onChangePage={handlePageChange}
          />
        </Box>
      )}
    </div>
  );
};

const SubmissionRow = ({
  submission,
  bdxInfo,
  uploadBDX,
  handleDeleteReport,
  handleDownload,
  handleSubmitReport,
  handleUnpublishReport,
  handleSetActiveBDX,
  isDisabled,
  isAllowedToAddBdx,
  activeBDX,
}) => {
  const theme = useTheme();
  const classes = useBDXUploadCardStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const initials = submission?.submittedBy
    ?.split(' ')
    .map((name) => name[0])
    .join('');
  const submittedOn = utils.date.formatDateWithParameter(submission.submittedOn, 'D MMMM YYYY');
  const submittedTime = utils.date.formatDateWithParameter(submission.submittedOn, 'HH:mm A');
  const status = isDeleting ? 'Deleting' : submission.status;
  const isPublished = submission.status === PUBLISHED;
  const isProcessed = submission.status === PROCESSED;
  const canDownload = isPublished || isProcessed;
  const isBlocked = submission.status === BLOCKED;
  const contactAdmin = submission?.contactAdmin || false;

  useEffect(() => {
    if (submission.status === PROCESSED) {
      queryClient.invalidateQueries([BDX_EXCEPTIONS_COUNT, submission.requestId]);
    }
  }, [queryClient, submission]);

  const { data: exceptionsCount, isFetching: isLoadingExceptions } = useGetExceptionsCount({
    requestId: submission.requestId,
  });
  const { mutate: reSubmitBDX } = useReSubmitBDX();

  let blockedMessage = isBlocked ? utils.string.t('notification.bdx.brokenRules', { ruleName: submission.ruleName }) : '';
  if (contactAdmin && isBlocked) {
    blockedMessage = utils.string.t('notification.bdx.contactAdmin');
  }

  const statusBackgroundColor = theme.palette.bm.status[`${status?.toLowerCase()}_bdx`]
    ? theme.palette.bm.status[`${status?.toLowerCase()}_bdx`]
    : theme.palette.bm.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

  const handleDelete = () => {
    setIsDeleting(true);
    handleDeleteReport(submission.requestId);
  };

  const popoverMenu = [
    {
      id: 'resubmit',
      label: 'Resubmit',
      callback: () => reSubmitBDX({ requestId: submission.requestId }),
    },
    {
      id: 'reupload',
      label: 'Reupload',
      callback: () => uploadBDX(bdxInfo.yearMonthSubmission, bdxInfo.bdxType, null, submission.requestId, null),
    },
    {
      id: 'delete',
      label: 'Delete',
      callback: handleDelete,
    },
  ];

  const popoverMenuPublished = [
    {
      id: 'unpublish',
      label: 'Unpublish',
      callback: () =>
        dispatch(
          showModal({
            component: 'CONFIRM',
            props: {
              title: 'Are you sure you want to Unpublish',
              fullWidth: true,
              maxWidth: 'sm',
              componentProps: {
                cancelLabel: utils.string.t('app.cancel'),
                confirmLabel: utils.string.t('app.continue'),
                submitHandler: () => {
                  handleUnpublishReport(submission.requestId);
                },
              },
            },
          })
        ),
    },
  ];

  const getHandleClick = (callback) => (e) => {
    e.stopPropagation();
    callback();
  };

  const handlePublish = () => {
    if (!exceptionsCount) {
      return;
    }

    if (
      exceptionsCount?.validationWarnings !== '0' ||
      exceptionsCount?.validationErrors !== '0' ||
      exceptionsCount?.sanctionExceptions !== '0'
    ) {
      const validation = parseFloat(exceptionsCount.validationWarnings) + parseFloat(exceptionsCount.validationErrors);
      const validationMessage = validation ? `Validation - ${validation}` : null;

      const sanction = parseFloat(exceptionsCount?.sanctionExceptions);
      const sanctionMessage = sanction ? `Sanction - ${sanction}` : null;

      dispatch(
        showModal({
          component: 'CONFIRM',
          props: {
            title: 'You have outstanding Sanctions and Validations to check',
            subtitle: [validationMessage, sanctionMessage].filter(Boolean).join(', '),
            fullWidth: true,
            maxWidth: 'sm',
            componentProps: {
              cancelLabel: utils.string.t('app.cancel'),
              confirmLabel: utils.string.t('app.publish'),
              submitHandler: () => {
                handleSubmitReport(submission.requestId);
              },
            },
          },
        })
      );
    } else {
      handleSubmitReport(submission.requestId);
    }
  };

  const tpa = submission.thirdParty?.name || 'No TPA';

  return (
    <StyledBDXUploadCardTableRow
      className={classNames({
        active: submission.requestId === activeBDX.requestId,
        processed: canDownload,
      })}
      key={submission.requestId}
      data-testid="bdx-file-row"
      onClick={() =>
        canDownload && submission.requestId !== activeBDX.requestId && handleSetActiveBDX(submission.requestId, submission.status)
      }
    >
      <TableCell
        style={{
          maxWidth: '200px',
        }}
      >
        {submission.fileName}
      </TableCell>
      <TableCell>{submission.tabName}</TableCell>
      {bdxInfo.bdxType === BDX_TYPE_CLAIM && <TableCell>{tpa}</TableCell>}
      <TableCell>
        <Box display="flex" alignItems="center">
          <Tooltip title={submission.submittedBy}>
            <Avatar text={initials} size={32} />
          </Tooltip>
          <Typography variant="body2" style={{ marginLeft: '8px', marginBottom: 0 }}>
            {submittedOn}
            <br />
            {submittedTime}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {canDownload && <BDXValidationExceptionCount exceptionsCount={exceptionsCount} isLoading={isLoadingExceptions} theme={theme} />}
      </TableCell>
      <TableCell>
        {canDownload && <BDXSanctionExceptionCount exceptionsCount={exceptionsCount} isLoading={isLoadingExceptions} theme={theme} />}
      </TableCell>
      <TableCell align="justify">
        <Chip
          size="small"
          label={utils.string.capitalise(status?.replace(/_/g, ' '))}
          status={status}
          data-testid="bdxInfo-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
            fontSize: '11px',
            zIndex: 1,
            width: 'fit-content',
          }}
        />
        {isBlocked ? (
          <Tooltip title={blockedMessage}>
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="end">
          {canDownload ? (
            <IconButton
              size="small"
              onClick={getHandleClick(() => handleDownload(submission.requestId))}
              disabled={isDisabled}
              aria-label="download"
            >
              <CloudDownload classes={{ root: classes.icon }} />
            </IconButton>
          ) : null}

          {isProcessed ? (
            <IconButton
              size="small"
              onClick={getHandleClick(handlePublish)}
              disabled={isDisabled || isLoadingExceptions}
              aria-label="publish"
            >
              <PublishIcon classes={{ root: classes.icon }} />
            </IconButton>
          ) : null}
          {isAllowedToAddBdx && (
            <PopoverMenu
              id="menu-select"
              disabled={isDisabled}
              size="medium"
              items={isPublished ? popoverMenuPublished : popoverMenu}
              iconPosition="right"
              nestedClasses={{ label: { fontWeight: 600 } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />
          )}
        </Box>
      </TableCell>
    </StyledBDXUploadCardTableRow>
  );
};

const BDXValidationExceptionCount = ({ exceptionsCount, theme, isLoading }) => {
  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  const isValidationErrors = exceptionsCount?.validationWarnings !== '0' || exceptionsCount?.validationErrors !== '0';

  return isValidationErrors ? (
    <>
      {exceptionsCount?.validationWarnings !== '0' ? (
        <Chip
          size="small"
          label={exceptionsCount.validationWarnings}
          data-testid="validation-warnings"
          style={{
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.common.white,
            fontSize: '11px',
            zIndex: 1,
            width: 'fit-content',
            marginRight: 4,
            cursor: 'pointer',
          }}
        />
      ) : null}
      {exceptionsCount?.validationErrors !== '0' ? (
        <Chip
          size="small"
          label={exceptionsCount.validationErrors}
          style={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            fontSize: '11px',
            zIndex: 1,
            width: 'fit-content',
            cursor: 'pointer',
          }}
        />
      ) : null}
    </>
  ) : (
    <CheckCircle style={{ color: theme.palette.success.main }} />
  );
};

const BDXSanctionExceptionCount = ({ exceptionsCount, theme, isLoading }) => {
  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  return exceptionsCount?.sanctionExceptions !== '0' ? (
    <Chip
      size="small"
      label={exceptionsCount?.sanctionExceptions}
      style={{
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
        fontSize: '11px',
        zIndex: 1,
        width: 'fit-content',
        cursor: 'pointer',
      }}
    />
  ) : (
    <CheckCircle style={{ color: theme.palette.success.main }} />
  );
};
