import { FC, useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

import { Button, FormSelect } from 'components';

import * as utils from 'utils';

import { IConditionsProps } from '../../AddBinderLimit.types';

import { Condition } from './Condition';

import { conditionsStyles } from './Conditions.styles';

export const Conditions: FC<IConditionsProps> = ({ methods, fields, limitColumns, limit }) => {
  const classes = conditionsStyles();
  const { control, formState } = methods;
  const {
    fields: conditions = [],
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'conditions',
  });

  const removeCondition = (index: number) => {
    remove(index);
  };

  const addCondition = useCallback(() => {
    append({
      field: null,
      condition: '',
    });
  }, [append]);

  useEffect(() => {
    if (limit) {
      remove();
      const result = limit.conditions.map((condition) => ({
        ...condition,
        field: limitColumns?.find(({ name }) => condition.column === name),
      }));
      append(result);
    } else {
      addCondition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, append, limitColumns, addCondition]);

  return (
    <div className={classes.conditions}>
      <Grid container spacing={1} className={classes.conditionStatement}>
        <Grid item>
          <Typography variant="subtitle1">{utils.string.t('rules.if')}</Typography>
        </Grid>
        <Grid item>
          <FormSelect
            {...utils.form.getFieldProps(fields, 'conditionType')}
            rules={{ required: true }}
            control={control}
            error={formState.errors.conditionType}
            testid="conditionType"
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{utils.string.t('rules.conditionStatement')}</Typography>
        </Grid>
        <div className={classes.button}>
          <Button
            text={utils.string.t('rules.addCondition')}
            onClick={addCondition}
            icon={PostAdd}
            variant="contained"
            color="secondary"
            size="small"
          />
        </div>
      </Grid>
      {conditions?.map((condition, conditionIndex) => (
        <Condition
          key={condition.id}
          isLast={conditions?.length === 1}
          condition={condition}
          prefix={`conditions.${conditionIndex}`}
          fields={fields}
          methods={methods}
          handleRemoveCondition={() => removeCondition(conditionIndex)}
          limitColumns={limitColumns}
        />
      ))}
    </div>
  );
};
