const styles = (theme) => ({
  card: {
    backgroundColor: theme.palette.grey[100],
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    boxShadow: theme.shadows[2],
    borderRadius: '5px',
    position: 'relative',
    padding: 0,
    minWidth: 'fit-content',
  },
  cardTitle: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitleHeading: {
    fontWeight: 600,
    color: 'white',
    marginBottom: 0,
  },
  cardContent: {
    padding: '0px!important',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'white',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  label: {
    color: 'black!important',
  },
  cardActions: {
    marginTop: '0px!important',
    paddingTop: '16px!important',
    paddingBottom: '16px!important',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    alignItems: 'start',
    minHeight: '64px',
  },
  policyBox: {},
  cardPolicyTitle: {
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
  },
  cardFacility: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 0,
    textAlign: 'left',
  },
  buttonProgress: {
    position: 'absolute',
    top: 5,
    left: 5,
    color: 'white',
  },
  buttonDownloading: {
    paddingLeft: '22px!important',
  },
  buttonCancel: {
    color: 'white',
    backgroundColor: theme.palette.status.cancelled,
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: theme.palette.status.cancelled,
      color: 'white',
    },
  },
  buttonExtend: {
    marginLeft: theme.spacing(1),
  },
  quotedIndex: {
    backgroundColor: theme.palette.status.bound,
    color: theme.palette.primary.contrastText,
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 700,
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  endorsementIndex: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.status.quoted,
    borderColor: theme.palette.primary.dark,
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 700,
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginRight: '10px',
  },
});

export default styles;
