import { List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { Tooltip } from 'components';
import { groupBy } from 'lodash';
import styles from './FacilityUserRoleInfo.style';

export const FacilityUserRoleInfo = ({ users, children }) => {
  const classes = makeStyles(styles, { name: 'FaciliFacilityUserRoleInfotyDetails' })();

  const FacilityUserTooltip = () => {
    const groups = groupBy(users, 'group');

    return (
      <List className={classes.wrapper} data-testid="facility-users-tooltip">
        {Object.entries(groups).map(([group, users]) => (
          <ListItem key={group} className={classes.listItem}>
            <div>
              <Typography variant="body2" className={classes.title}>
                {group}
              </Typography>
              <List className={classes.wrapper}>
                {users.map(({ id, fullName }) => (
                  <ListItem key={id} className={classes.user}>
                    {fullName}
                  </ListItem>
                ))}
              </List>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  if (!users.length) {
    return <>{children}</>;
  }

  return (
    <Tooltip title={<FacilityUserTooltip />} placement="bottom" style={{ display: 'block' }}>
      {children}
    </Tooltip>
  );
};
