import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PowerBIEmbed } from 'powerbi-client-react';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';

import { getReportingDetails, selectReportDetails } from 'stores';
import * as utils from 'utils';

import { useReportStyles } from './Report.styles';

const model = {
  BackgroundType: {
    Default: 0,
    Transparent: 1,
  },
  TokenType: {
    Add: 0,
    Embed: 1,
  },
  DisplayOption: {
    ActualSize: 2,
    FitToPage: 0,
    FitToWidth: 1,
  },
};

interface IReportProps {
  report: {
    id?: string;
  };
}

export const Report: FC<IReportProps> = ({ report }) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();

  const reportDetails = useSelector(selectReportDetails);

  useEffect(() => {
    dispatch(getReportingDetails(report.id));
  }, [report.id, dispatch]);

  const embedConfiguration = {
    type: 'report', // Supported types: report, dashboard, tile, visual and qna
    id: reportDetails?.reportId,
    embedUrl: reportDetails?.embedUrl,
    accessToken: reportDetails?.embedToken, // Keep as empty string, null or undefined
    tokenType: model.TokenType.Embed,
    pageView: model.DisplayOption.FitToPage,
    settings: {
      background: model.BackgroundType.Default,
      filterPaneEnabled: false,
      panes: {
        filters: {
          expanded: true,
          visible: true,
        },
      },
    },
  };

  if (reportDetails?.reportError) {
    return <Empty title={utils.string.t('app.noReport')} icon={<Loading />} width={400} />;
  }
  if (!reportDetails?.embedToken || !reportDetails?.embedUrl) {
    return null;
  }
  return <PowerBIEmbed embedConfig={embedConfiguration} cssClassName={classes.report} />;
};
