import { useQuery } from 'react-query';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_COVERHOLDER_FILTERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetCoverholderFilters = () => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetCoverholderFilters',
  });

  return useQuery<ICoverholderFilters>(
    [FACILITY_COVERHOLDER_FILTERS],
    async () => {
      const result = await binderManagementFacilitiesAxios.get('coverHolders/filter');
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
