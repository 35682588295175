import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import { Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, FormAutocompleteMui, FormText } from 'components';
import { RuleLine } from 'modules/Rules';

import * as utils from 'utils';

import { ISumColumnProps } from '../../AddBinderLimit.types';

import { USD_CURRENCY } from './SumColumn.const';

import { sumcolumnsStyles } from './SumColumns.styles';

export const SumColumn: FC<ISumColumnProps> = ({
  methods,
  sumColumn,
  limitColumns,
  limitValue,
  limitAlertAtPercentage,
  handleRemoveCondition,
  isFirst,
  isLast,
  prefix,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = methods;
  const [selectedField, setSelectedField] = useState<string>(null);

  useEffect(() => {
    if (sumColumn.name) {
      setSelectedField(sumColumn.name);
    }
  }, [sumColumn]);

  const classes = sumcolumnsStyles();

  const onSelectColumnHeading = (selected) => {
    setSelectedField(selected.name);
    setValue(`${prefix}.operation`, null);
  };

  const label = (
    <div className={classes.sumColumnLabel}>
      <Typography className={classNames(classes.sumColumnLabelText, { [classes.sumColumnLabelTextActive]: true })}>
        {utils.string.t('binderLimits.sumColumns.columnHeading')}
      </Typography>
    </div>
  );

  return (
    <Grid container spacing={1} className={classes.sumColumn}>
      <Grid item container spacing={1}>
        <Grid item className={classes.sumColumnLineContainer}>
          <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={55} />
        </Grid>
        <Grid item xs={2}>
          <FormAutocompleteMui
            name={`${prefix}.field`}
            optionKey="name"
            optionLabel="displayName"
            label={label}
            defaultValue={limitColumns.find((limitColumn) => limitColumn.name === sumColumn.name) || []}
            options={limitColumns || []}
            onSelect={onSelectColumnHeading}
            rules={{ required: 'Required' }}
            control={control}
            error={get(errors, `${prefix}.field`)}
          />
        </Grid>

        {isFirst && (
          <>
            <Grid item xs={3} className={classes.operandLabelWrapper}>
              <FormText
                name={`${prefix}.limit`}
                control={control}
                label={utils.string.t('binderLimits.limit')}
                defaultValue={limitValue || ''}
                type="number"
                rules={{ required: 'Required' }}
                disabled={!selectedField}
                error={get(errors, `${prefix}.limit`)}
              />
            </Grid>
            <Grid item xs={2} className={classes.operandLabelWrapper}>
              <FormText
                name={`${prefix}.limitAlertAtPercentage`}
                control={control}
                label={utils.string.t('binderLimits.sumColumns.limitAlertAtPercentage')}
                defaultValue={limitAlertAtPercentage || ''}
                type="number"
                rules={{ required: 'Required' }}
                disabled={!selectedField}
                error={get(errors, `${prefix}.limitAlertAtPercentage`)}
              />
            </Grid>
            <Grid item xs={2} className={classes.operandLabelWrapper}>
              <FormAutocompleteMui
                name={`${prefix}.currency`}
                optionKey="value"
                optionLabel="value"
                label={utils.string.t('binderLimits.sumColumns.currency')}
                defaultValue={USD_CURRENCY || {}}
                options={[USD_CURRENCY] || []}
                control={control}
                disabled
              />
            </Grid>{' '}
          </>
        )}
      </Grid>
      {!isLast && (
        <Grid item className={classes.deleteSumColumn}>
          <Button icon={DeleteIcon} color="primary" size="xsmall" onClick={handleRemoveCondition} data-testid="btn-delete-action" />
        </Grid>
      )}
    </Grid>
  );
};
