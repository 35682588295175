import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification, hideModal } from 'stores';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_FACILITIES_USERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useUpdateFacilityUsers = ({ umr, role = 'producers' }: { umr: string; role: 'producers' | 'underwriter' }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useUpdateFacilityUsers',
    warning: 'notification.binderManagement.userUpdateFail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries(FACILITY_BINDER_FACILITIES_USERS);

    dispatch(enqueueNotification('notification.binderManagement.userUpdate', 'success'));

    dispatch(hideModal());
  };

  return useMutation<IUpdateFacilityUsersResponse, AxiosError, IUpdateFacilityUsersDTO>(
    async (userIds) => await binderManagementFacilitiesAxios.post(`facilities/${umr}/user/${role}`, { userIds }),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
