import * as Yup from 'yup';

// app
import { AddEditParentClientView } from './AddEditParentClient.view';
import * as utils from 'utils';
import { useCreateOperationalClient, useEditOperationalClient } from 'lib/admin';

export default function AddEditParentClient({ handleClose, client = {} }) {
  const { mutate: createOperationalClient } = useCreateOperationalClient();
  const { mutate: editOperationalClient } = useEditOperationalClient();

  const handleSubmit = (parentClient) => {
    if (client.id) {
      editOperationalClient({ newParentClient: parentClient, id: client.id });
    } else {
      createOperationalClient(parentClient);
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const fields = [
    {
      name: 'name',
      label: utils.string.t('admin.form.client.label'),
      type: 'text',
      value: client?.name ? client.name : '',
      innerComponentProps: {
        maxMenuHeight: 120,
        allowEmpty: true,
        'data-testid': 'parent',
      },
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      type: 'file',
      name: 'logo',
      label: utils.string.t('app.file'),
      placeholder: 'Select File ...',
      value: client?.logo ? client.logo : '',
      validation: client.id ? Yup.mixed().nullable() : Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
    },
  ];

  const logo = utils.client.parent.getLogoFilePath(client);

  const actions = [
    {
      name: 'submit',
      label: client.id ? utils.string.t('app.save') : utils.string.t('app.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return <AddEditParentClientView actions={actions} fields={fields} logo={logo} />;
}
