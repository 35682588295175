import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Empty } from 'components';
import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';

import { useSectionWebSocket } from 'hooks';
import { useGetBdxTypes } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { binderLimits } from './BinderLimits.utils';
import { BindersLimitsView } from './BinderLimits.view';
import { BinderLimitsSkeleton } from './BinderLimitsSkeleton';
import { ColumnHeader } from './components/BinderLimit/BinderLimits.types';

const NoLimits: FC = () => <Empty width={400} title={utils.string.t('binderLimits.noLimitsFound')} icon={<SearchResultImage />} padding />;

export const BinderLimits: FC = () => {
  const { facility, isLoading: isLoadingFacility } = useContext(FacilityContext);
  const { data: bdxTypes, isFetching: isLoadingBDXTypes } = useGetBdxTypes(facility?.riskCodes?.map(({ code }) => code) || []);
  const dispatch = useDispatch();
  useSectionWebSocket(facility, binderLimits);

  const columnHeaders: ColumnHeader[] = [
    ColumnHeader.Name,
    ColumnHeader.Limit,
    ColumnHeader.AlertPercentage,
    ColumnHeader.Currency,
    ColumnHeader.ValueToDate,
    ColumnHeader.Empty,
  ];

  const handleAddLimit = () => {
    dispatch(
      showModal({
        component: 'ADD_BINDER_LIMIT',
        props: {
          title: 'binderLimits.addLimits.modalTitle',
          fullWidth: true,
          maxWidth: 'xl',
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility,
          },
        },
      })
    );
  };

  const handleImportLimits = () => {
    dispatch(
      showModal({
        component: 'IMPORT_LIMITS',
        props: {
          title: 'binderLimits.importLimits',
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility,
          },
        },
      })
    );
  };

  const isLoading = isLoadingFacility || isLoadingBDXTypes;

  if (isLoading) return <BinderLimitsSkeleton />;

  if (!bdxTypes || bdxTypes.length === 0) return <NoLimits />;

  return <BindersLimitsView handleAddLimit={handleAddLimit} handleImportLimits={handleImportLimits} columnHeaders={columnHeaders} />;
};
