// useGetQuotes
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getCoverages(id, token) {
  if (!id) {
    throw new Error('useGetCoverages: id is required');
  }

  const apiUrl = `risks/${id}/coverages`;

  const response = await api(token).get(apiUrl);
  return response.data;
}

export function useGetCoverages(queryKey, id, options) {
  return useApi([queryKey, id], async (id, token) => await getCoverages(id, token), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
