const styles = (theme) => ({
  list: {
    overflowX: 'hidden',
    paddingTop: 0,
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['padding']),
    '&:last-child': {
      borderBottom: 0,
    },
  },
  deptIcon: {
    minWidth: 'auto',
  },
  listDeptBox: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['padding']),
    height: 60,
    display: 'flex',
    alignItems: 'center',
  },
  listDept: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  listActive: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  active: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    minWidth: 'auto',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: 'inherit',
    transition: theme.transitions.create(['color']),
  },
  iconActive: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.neutral.dark,
  },
  departmentTitle: {
    fontWeight: '700!important',
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  info: {
    marginLeft: -4,
  },
  avatar: {
    marginRight: (props) => (props.isExpanded ? 12 : 4),
    transition: theme.transitions.create(['margin']),
  },
  divider: (props) => ({
    height: 0,
    marginTop: props.isExpanded ? theme.spacing(2) : 0,
    marginBottom: props.isExpanded ? theme.spacing(2) : 0,
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${props.isExpanded ? theme.palette.neutral.lighter : 'transparent'}`,
    transition: `
      height ${theme.transitions.duration.leavingScreen}ms ${props.isExpanded ? 'step-start' : 'step-end'},
      margin ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut},
      border ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}
    `,

    '& + $divider': {
      display: 'none',
    },
  }),
  subheader: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: -4,
  },
});

export default styles;
