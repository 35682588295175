import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useCreateUser, useEmailSearch } from 'lib/admin';
import * as utils from 'utils';

// app
import { AddUserView } from './AddUser.view';

const AddUser = ({ handleClose, user = {} }) => {
  const { isLoading: isCreatingUser, mutate: createUser } = useCreateUser();

  const handleSubmit = (updatedUser) => {
    createUser(updatedUser);
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const userFields = [
    {
      name: 'firstName',
      label: utils.string.t('admin.form.firstName.label'),
      value: user.firstName || '',
      type: 'text',
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      name: 'lastName',
      label: utils.string.t('admin.form.lastName.label'),
      value: user.lastName || '',
      type: 'text',
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      name: 'role',
      label: utils.string.t('admin.form.role.label'),
      value: user.role || '',
      type: 'select',
      options: utils.user.getRolesString(),
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      name: 'emailId',
      label: utils.string.t('admin.form.email.label'),
      value: user.emailId || '',
      type: 'text',
      validation: Yup.string().email(utils.string.t('validation.email')).required(utils.string.t('validation.required')),
    },
    {
      name: 'contactPhone',
      label: utils.string.t('admin.form.phone.label'),
      value: user.contactPhone || '',
      type: 'text',
    },
  ];

  const actions = [
    {
      name: 'submit',
      label: user.id ? utils.string.t('app.save') : utils.string.t('app.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return <AddUserView actions={actions} fields={userFields} isCreatingUser={isCreatingUser} useEmailSearch={useEmailSearch} />;
};

AddUser.propTypes = {
  handleClose: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailId: PropTypes.string,
    contactPhone: PropTypes.string,
    role: PropTypes.string,
  }),
};
export default AddUser;
