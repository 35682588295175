import get from 'lodash/get';

import * as constants from 'consts';
// app
import * as utils from 'utils';

// private
const userRoleObj = () => ({
  [constants.ROLE_BROKER]: utils.string.t('app.broker_user'),
  [constants.ROLE_PRODUCER]: utils.string.t('app.producer_user'),
  [constants.ROLE_UNDERWRITER]: utils.string.t('app.underwriter_user'),
  [constants.ROLE_CLAIMS]: utils.string.t('app.claimsAdvocate'),
});

const utilsUser = {
  initials: (userObj) => {
    if (!userObj) return '';

    const { firstName, lastName, fullName } = userObj;
    let first = '';
    let last = '';

    // as a fallback, we take the 1st letter of the email
    first = userObj.emailId ? userObj.emailId.slice(0, 1) : '';

    // then, we try to get 1 or both initials from the full name
    if (fullName) {
      const parts = fullName.split(' ');

      first = parts[0] ? parts[0].slice(0, 1) : first;
      last = parts[1] ? parts[1].slice(0, 1) : last;

      // if we have fullname AND first/last names (only overwrite missing values)
      if (firstName) {
        first = firstName.slice(0, 1);
      }

      if (lastName) {
        last = lastName.slice(0, 1);
      }
    }
    // if we have first/last names, we take those values (and overwrite email fallback)
    else if (firstName || lastName) {
      first = firstName ? firstName.slice(0, 1) : '';
      last = lastName ? lastName.slice(0, 1) : '';
    }

    return (first + last).toUpperCase();
  },

  firstname: (userObj) => {
    if (!userObj) return '';

    const { firstName, fullName } = userObj;

    // if there is a firstName, return that
    if (firstName) {
      return firstName;
    }

    // if there's no firstName, we try to get the 1st part of the fullName
    if (fullName) {
      const parts = fullName.split(' ');

      return parts[0] || '';
    }

    return '';
  },

  fullname: (userObj) => {
    if (!userObj) return '';

    const { firstName, lastName, fullName } = userObj;
    let first = '';
    let last = '';

    // as a fallback, we take parts from the fullname
    if (fullName) {
      const parts = fullName.split(' ');

      first = parts[0] ? parts[0] : first;
      last = parts[1] ? parts[1] : last;
    }

    // then we check if we have first and last name values
    if (firstName) first = firstName;
    if (lastName) last = lastName;

    // if there's no firstName AND no lastName, we take the whole fullName if present
    if (!firstName && !lastName) {
      return fullName || '';
    }

    return [first, last].filter(Boolean).join(' ');
  },

  isBroker: (userObj) => userObj && Boolean(userObj.role) && userObj.role === constants.ROLE_BROKER,

  isProducer: (userObj) => userObj && Boolean(userObj.role && userObj.role === constants.ROLE_PRODUCER),

  isUnderwriter: (userObj) => userObj && Boolean(userObj.role && userObj.role === constants.ROLE_UNDERWRITER),

  isAdmin: (userObj) => userObj && userObj.isAdmin === true,

  isReportAdmin: (userObj) => userObj && userObj.isReportAdmin === true,

  isTeamLeader: (userObj) => userObj && userObj.teamLeader === true,

  isClaimsTechnician: (userObj) => userObj && Boolean(userObj.role && userObj.role === constants.ROLE_CLAIMS),

  canUserBeAddedToQB: (userObj) => !userObj.programmesUserId && !!userObj.role?.trim() && userObj.role !== constants.ROLE_CLAIMS,

  canUserBeAddedToBM: (userObj) => !userObj.addedBinderManagement && !!userObj.role?.trim() && userObj.role !== constants.ROLE_CLAIMS,

  hasAccessToQB: (userObj) =>
    utilsUser.isBroker(userObj) || (userObj?.programmesUserId && (utilsUser.isUnderwriter(userObj) || utilsUser.isProducer(userObj))),

  getRolesString: () => {
    const roles = userRoleObj();
    return Object.keys(roles).map((key) => ({
      value: key,
      label: roles[key],
    }));
  },

  getRoleString: (role) => userRoleObj()[role] || role,

  department: {
    getAll: (userObj) => {
      if (!userObj || !userObj.departments || !userObj.departments.length > 0) return [];

      return get(userObj, 'departments', []).map((department) => department.id);
    },

    getDefault: (userObj) => {
      const defaultDepartment = userObj && parseInt(get(userObj, 'departments[0].id'), 10);

      const savedDepartment = parseInt(localStorage.getItem('edge-department'), 10);
      const deptId = savedDepartment || defaultDepartment;

      return deptId;
    },

    getCurrent: (userObj) => {
      if (!userObj || !get(userObj, 'departmentSelected')) return null;

      return get(userObj, 'departmentSelected');
    },
  },
};

export default utilsUser;
