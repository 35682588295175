export const styles = (theme) => ({
  root: {
    width: '100%',
    fontSize: 14,
    position: 'relative',
  },
  button: {
    fontSize: 16,
    width: '100%',
    textAlign: 'left',
    fontWeight: 600,
    '& p': {
      width: '100%',
      fontSize: 16,
      fontWeight: 600,
    },
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  tag: {
    marginTop: 3,
    height: 24,
    padding: '.25em 0px',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '16px',
    borderRadius: 2,
  },
  clear: {
    fontSize: '0.8rem',
    color: '#9e9e9e',
    fontWeight: 600,
    cursor: 'pointer',
  },
  popper: {
    boxShadow: theme.shadows[1],
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    backgroundColor: '#f6f8fa',
  },
  option: {
    overflow: 'hidden',
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 12,
    borderRadius: '4px',
    margin: '5px 8px',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&[data-focus="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.neutral.lighter,
    },
  },
  optionButton: {
    padding: 8,
  },
  selectedOption: {
    display: 'flex',
    cursor: 'pointer',
    minHeight: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    borderRadius: '4px',
    margin: '5px 0px',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    overflow: 'hidden',
  },

  selectedValuesTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.neutral.black,
    '&:first-child': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },

  text: {
    flexGrow: 1,
    fontSize: 13,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
    cursor: 'pointer',
  },
  remove: {
    color: theme.palette.error.main,
    backgroundColor: 'white',
    borderRadius: '50%',
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
});
