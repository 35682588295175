import { useState } from 'react';

import { List, Divider } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';

import { Accordion } from 'components';
import { Document } from './Document';
import { useDocumentsStyle } from '../Documents.style';

export const Folder = ({ folder, umr, dispatch }) => {
  const classes = useDocumentsStyle();
  const [isOpen, setOpen] = useState(false);

  const folderFiles = folder.facilityDocumentList.map(({ documentName }) => documentName);

  return (
    <Accordion
      icon={true}
      primaryIcon={<FolderIcon className={classes.folderIcon} />}
      expanded={isOpen}
      title={{ text: folder.folderName, type: 'h2' }}
      onChange={(e, isExpanded) => setOpen(isExpanded)}
      testid={folder.folderName}
    >
      <List className={classes.documentList}>
        {folderFiles.map((documentName) => (
          <Document
            key={documentName}
            documentName={documentName}
            classes={classes}
            umr={umr}
            folder={folder.folderName}
            folderFiles={folderFiles}
            dispatch={dispatch}
          />
        ))}
        <Divider />
      </List>
    </Accordion>
  );
};
