import { useQuery } from 'react-query';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { LINEAGE_YEAR_MONTH_DETAILS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLineageDetailsProps {
  yearMonthSubmission: string;
}

export const useGetLineageDetails = ({ yearMonthSubmission }: IUseGetLineageDetailsProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineageDetails',
  });

  return useQuery<ILineageEntitiesByMonth>(
    [LINEAGE_YEAR_MONTH_DETAILS, yearMonthSubmission],
    async () => {
      const result = await binderManagementLineageAxios.get('lineage', {
        params: {
          yearMonthStart: yearMonthSubmission,
          size: 1,
        },
      });
      return result?.data?.[0] ?? null;
    },
    {
      onError: handleError,
    }
  );
};
