import { useQuery } from 'react-query';

// app
import { adminAxios } from '../../admin.api';
import { OPERATIONAL_CLIENTS_ALL } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

export const useGetOperationalClientsAll = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getParentOfficeListAll',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [OPERATIONAL_CLIENTS_ALL, 'all'],
    async () => {
      const result = await adminAxios.get('client/operational/all/offices');

      return result.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
