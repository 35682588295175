import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { createReport, reportingEdit } from 'stores';
import * as utils from 'utils';

// app
import { AddEditReportView } from './AddEditReport.view';

const AddEditReport = ({ report = {}, handleClose }) => {
  const dispatch = useDispatch();

  const handleEdit = (values) => {
    dispatch(reportingEdit(values));
  };

  const handleAdd = (values, reportgroupId) => dispatch(createReport({ ...values, reportgroupId }));

  const fields = [
    {
      name: 'id',
      type: 'text',
      value: report?.id,
    },
    {
      name: 'report',
      type: 'text',
      value: report?.title || '',
      validation: Yup.string().required(utils.string.t('reporting.form.reportName.required')),
      label: utils.string.t('reporting.form.reportName.label'),
    },
    {
      name: 'powerbiReportId',
      type: 'text',
      value: report?.powerbiReportId || '',
      label: utils.string.t('reporting.form.powerbiReportId'),
    },
    {
      name: 'rowLevelSecurity',
      type: 'switch',
      value: Boolean(report?.dataSetId) || Boolean(report?.showAllData) || false,
      title: utils.string.t('reporting.form.rowLevelSecurity'),
      muiComponentProps: {
        color: 'primary',
        'data-testid': 'rowLevelSecurity',
      },
    },
    {
      name: 'dataSetId',
      type: 'text',
      value: report?.dataSetId || '',
      label: utils.string.t('reporting.form.dataSetId.label'),
    },
    {
      name: 'workspaceId',
      type: 'text',
      value: report?.workspaceId || '',
      label: utils.string.t('reporting.form.workspaceId.label'),
    },
    {
      name: 'description',
      type: 'textarea',
      label: utils.string.t('reporting.form.description'),
      value: report?.description || '',
      muiComponentProps: {
        multiline: true,
        minRows: 3,
        maxRows: 6,
        'data-testid': 'description',
      },
    },
    {
      name: 'showAllData',
      type: 'checkbox',
      label: utils.string.t('reporting.form.showAllData'),
      value: report?.showAllData || false,
    },
  ];

  const actions = [
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleClose,
    },
    {
      name: 'submit',
      label: utils.string.t('app.submit'),
      handler: (values) => (report?.id ? handleEdit(values) : handleAdd(values, report.groupId)),
    },
  ];
  return <AddEditReportView fields={fields} actions={actions} />;
};

AddEditReport.propTypes = {
  report: PropTypes.shape.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddEditReport;
