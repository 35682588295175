import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';
import * as utils from 'utils';

import { binderManagementReportingAxios } from '../../binderManagement.api';
import { BDX_REQUIRED_BDX } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface ISubmitReportResponse {
  facilityReference: string;
  callbackOnSuccess: (data: IReportingResponse) => void;
  data: IReportingResponse;
}
interface ISubmitReportProps extends ISubmitReportResponse {
  requestId: string;
}

export const useSubmitReport = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.reporting.mutation.useSubmitReport',
    warning: 'notification.bdxSubmit.fail',
  });

  const handleSuccess = ({ facilityReference, callbackOnSuccess, data }) => {
    dispatch(enqueueNotification('notification.bdxSubmit.success', 'success'));

    if (utils.generic.isFunction(callbackOnSuccess)) {
      callbackOnSuccess(data);
    }

    queryClient.invalidateQueries([BDX_REQUIRED_BDX, facilityReference]);
  };

  return useMutation<ISubmitReportResponse, AxiosError, ISubmitReportProps>(
    async ({ requestId, facilityReference, callbackOnSuccess }) => {
      const result = await binderManagementReportingAxios.post(`publish`, { requestId });

      return { facilityReference, callbackOnSuccess, data: result.data };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
