const styles = (theme) => ({
  compact: {
    maxWidth: 180,

    '& input': {
      padding: 4,
    },
  },
});

export default styles;
