import { makeStyles } from '@material-ui/core';

export const useRulesListStyle = makeStyles(
  (theme) => ({
    list: {
      width: '100%',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    tpaTitleWrapper: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tpaTitle: {
      margin: 0,
    },
    tpaList: {
      width: '100%',
      padding: theme.spacing(0, 2),
    },
    groupTitle: {
      display: 'block',
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      marginBottom: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
    },
    controls: {
      width: 'auto',
      justifyContent: 'flex-end',
    },
    titleSkeleton: {
      transform: 'none',
    },
  }),
  { name: 'RulesList' }
);
