import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { SummaryTable } from 'modules/Binders/BDXExtraction/tabs/Processing';

import { useSimplePagination } from 'hooks';
import { useAssignLinesToFacility, useUnmappedBdxLines } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { AssignDismissLines } from './AssignDismissLines';

interface IUnSubmittedBdxLinesProps {
  groupId: string;
  requestId: string;
  umrs: string[];
}

export const UnSubmittedBdxLines: FC<IUnSubmittedBdxLinesProps> = ({ groupId, requestId, umrs }) => {
  const dispatch = useDispatch();
  const { page, size, handleChangePage, handleChangeRowsPerPage } = useSimplePagination();
  const { mutateAsync: dismissLines } = useAssignLinesToFacility();

  const { data, isLoading, isFetching, isError } = useUnmappedBdxLines({
    groupId,
    requestId,
    page,
    size,
    options: {
      enabled: !!groupId || !!requestId,
    },
  });

  const getHandleAssign = (selectedRowKeys, handleSuccess) => (event) => {
    event.preventDefault();
    const selectedKeys = selectedRowKeys.filter((key) => key !== 'bulkSelectButton');

    dispatch(
      showModal({
        component: 'ASSIGN_TO_FACILITY',
        props: {
          title: utils.string.t('bdx.assignLines', { length: selectedKeys.length }),
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            selectedRowKeys: selectedKeys,
            groupId,
            requestId,
            allFacilities: umrs,
            handleSuccess,
          },
        },
      })
    );
  };

  const getHandleDismiss = (selectedRowKeys, handleSuccess) => (event) => {
    event.preventDefault();
    const selectedKeys = selectedRowKeys.filter((key) => key !== 'bulkSelectButton');

    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: utils.string.t('bdx.dismissLines', { length: selectedKeys.length }),
          fullWidth: true,
          maxWidth: 'sm',
          componentProps: {
            cancelLabel: utils.string.t('app.cancel'),
            confirmLabel: utils.string.t('app.continue'),
            submitHandler: () => {
              dismissLines({
                groupId,
                requestId,
                rowIds: selectedKeys,
              });
              handleSuccess();
            },
          },
        },
      })
    );
  };

  return (
    <SummaryTable
      data={data || []}
      isLoading={isLoading || isFetching}
      isError={isError}
      size={size}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      AssignComponent={AssignDismissLines}
      getHandleAssign={getHandleAssign}
      getHandleDismiss={getHandleDismiss}
      assignCellWidth={100}
      cellName={requestId ? 'cells' : 'unprocessedColumns'}
      columnName={requestId ? 'columnName' : 'bdxColumnName'}
      columnValue={requestId ? 'columnValue' : 'value'}
    />
  );
};
