import { FC, Fragment } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { IConditionPreviewProps, IConditionsPreviewProps } from 'forms/AddBinderLimit/AddBinderLimit.types';
import { splitString } from 'modules/BinderLimits/BinderLimits.utils';
import { RuleLine } from 'modules/Rules';

import * as utils from 'utils';

import { ConditionType } from '../BinderLimit/BinderLimits.types';

import { binderLimitPreviewStyles } from './BinderLimitPreview.styles';

const ConditionPreview: FC<IConditionPreviewProps> = ({ condition }) => {
  const classes = binderLimitPreviewStyles();
  const { column = '', condition: conditionValue = '', inputs = [] } = condition || {};

  const getInputs = () =>
    inputs.map(({ value }, index) => (
      <Fragment key={value}>
        <span className={classes.operationText}>{value}</span>
        {index !== inputs.length - 1 && <span className={classes.operationTextBold}> and </span>}
      </Fragment>
    ));

  return (
    <Grid container className={classes.operation}>
      <Grid item className={classes.ruleLineContainer}>
        <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.operationDescription}>
          {column && <span className={classes.operationText}>{splitString(column)} is </span>}

          <span className={classes.operationTextBold}>{conditionValue} </span>

          {getInputs()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ConditionsPreview: FC<IConditionsPreviewProps> = ({ conditionType, conditions }) => {
  const classes = binderLimitPreviewStyles();

  return (
    <Grid container className={classes.previewContainer}>
      <Grid item className={classes.previewHeader}>
        <Typography variant="subtitle1">{utils.string.t('rules.if')}</Typography>
        <Typography variant="subtitle1" className={classes.conditionType}>
          {ConditionType[conditionType]}
        </Typography>
        <Typography variant="subtitle1">{utils.string.t('rules.conditionStatement')}</Typography>
      </Grid>
      {conditions?.map((condition) => (
        <ConditionPreview key={`${condition.column}-${condition.condition}`} condition={condition} />
      ))}
    </Grid>
  );
};
