/* eslint-disable react/function-component-definition */
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useCreateRule, useGetRuleColumns, useGetRuleRowActions, useGetRulesets } from 'lib/binderManagement';
// mui
import { selectUser } from 'stores';
import * as utils from 'utils';

import { AddRuleView } from './AddRule.view';
import { AddRuleSkeleton } from './AddRuleSkeleton';
import { getActions, getConditions } from './utils';

export default function AddRule({ rule, facility, bdxType, thirdPartyId, tpaRuleset }) {
  const user = useSelector(selectUser);
  const { data: ruleColumns, isLoading: isColumnsLoading } = useGetRuleColumns({
    riskCodes: facility?.riskCodes?.map(({ code }) => code),
    bdxType,
  });
  const { data: ruleRowActions, isLoading: rowActionsLoading } = useGetRuleRowActions({ bdxType, facilityReference: facility.umr });

  const isLoading = isColumnsLoading || rowActionsLoading;

  const { data: ruleSets } = useGetRulesets({
    bdxType,
    facility: facility?.umr,
  });

  const roundingModeList = ruleColumns
    ?.map((item) => item.actions.find((action) => action.action.toLowerCase().includes('divide')))
    .find((divideAction) => !!divideAction);

  const roundingModeOptions = (roundingModeList?.roundingMode || []).map((id) => ({
    id,
    label: id.replace(/_/g, ' ').toLowerCase(),
  }));

  const ruleset = useMemo(() => {
    // Case when TPA ruleset is configured
    if (tpaRuleset) {
      return tpaRuleset;
    }
    // Case when TPA ruleset is not configured but requested
    if (thirdPartyId) {
      return null;
    }
    // Case when TPA ruleset is not requested. Use default ruleset
    return ruleSets?.find(({ thirdPartyId }) => !thirdPartyId);
  }, [ruleSets, tpaRuleset, thirdPartyId]);

  const { mutateAsync: addRule } = useCreateRule({ rule, facility, bdxType, thirdPartyId });

  const methods = useForm({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const fields = [
    {
      name: 'name',
      type: 'text',
      validation: Yup.string().required(utils.string.t('validation.required')),
      label: utils.string.t('rules.addRule.name'),
      defaultValue: rule?.name,
    },
    {
      name: 'salience',
      type: 'number',
      validation: Yup.string().required(utils.string.t('validation.required')),
      label: utils.string.t('rules.addRule.priority'),
      defaultValue: rule?.salience || 1,
    },
    {
      name: 'conditionType',
      type: 'select',
      optionKey: 'id',
      optionLabel: 'label',
      options: [
        { id: 'ANY', label: 'any of' },
        { id: 'ALL', label: 'all of' },
      ],
      getOptionSelected: (option, value) => option.id === value.id,
      validation: Yup.string().required(utils.string.t('validation.required')),
      defaultValue: rule?.conditionType || 'ANY',
    },
    {
      name: 'values',
      type: 'multiText',
      label: utils.string.t('rules.values'),
    },
    {
      name: 'value1',
      type: 'text',
      label: utils.string.t('rules.value'),
    },
    {
      name: 'value2',
      type: 'text',
      label: utils.string.t('rules.value2'),
    },
    {
      name: 'dateValue',
      label: utils.string.t('rules.date'),
      type: 'datepicker',
      placeholder: 'DD/MM/YYYY',
      muiPickerProps: {
        format: 'DD-MM-yyyy',
      },
    },
    {
      name: 'delimiter',
      type: 'text',
      label: utils.string.t('rules.delimiter'),
    },
    {
      name: 'decimals',
      type: 'select',
      optionKey: 'id',
      optionLabel: 'label',
      options: [
        { id: 0, label: '0' },
        { id: 1, label: '1' },
        { id: 2, label: '2' },
        { id: 3, label: '3' },
        { id: 4, label: '4' },
        { id: 5, label: '5' },
        { id: 6, label: '6' },
        { id: 7, label: '7' },
        { id: 8, label: '8' },
        { id: 9, label: '9' },
        { id: 10, label: '10' },
      ],
      getOptionSelected: (option, value) => option.id === value.id,
      validation: Yup.string().required(utils.string.t('validation.required')),
      defaultValue: 0,
    },
    {
      name: 'roundingMode',
      type: 'select',
      optionKey: 'id',
      optionLabel: 'label',
      options: roundingModeOptions,
      getOptionSelected: (option, value) => option.id === value.id,
      validation: Yup.string().required(utils.string.t('validation.required')),
      defaultValue: roundingModeOptions[0]?.id || '',
    },
  ];

  const { errors } = methods.formState;

  const submit = async () => {
    const newRule = {
      ...methods.getValues(),
      userId: user.id,
      id: uuidv4(),
      lastUpdated: new Date().toISOString(),
      ...(rule?.id && { id: rule.id }),
      ...(rule?.ruleId && { ruleId: rule.ruleId }),
    };

    newRule.actions = getActions(newRule);

    newRule.conditions = getConditions(newRule, methods);
    if (!newRule.conditions) {
      return;
    }
    newRule.ruleId = rule?.ruleId || null;

    const restRules = ruleset?.rules.filter(({ id }) => rule?.id !== id) || [];
    const rules = [...restRules, newRule];
    const action = rule ? 'EDIT_RULE' : 'ADD_RULE';

    await addRule({ rules, action });
  };

  return isLoading ? (
    <AddRuleSkeleton />
  ) : (
    <AddRuleView
      fields={fields}
      methods={methods}
      errors={errors}
      ruleColumns={ruleColumns}
      ruleRowActions={ruleRowActions}
      rule={rule}
      submit={submit}
    />
  );
}
