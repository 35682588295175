const styles = (theme) => ({
  header: {
    marginBottom: '0px!important',
  },
  content: {
    margin: 0,
  },
  selection: {
    display: 'flex',
    flex: 1,
  },
});

export default styles;
