import { PUBLISHED, PROCESSING, PUBLISHING, BLOCKED, PROCESSED, UPLOADED, QUEUED, PENDING_SANCTIONS } from 'consts';

export const getBdxStatus = (submissions) => {
  if (submissions.every(({ status }) => status === PUBLISHED)) {
    return PUBLISHED;
  }
  if (submissions.some(({ status }) => status === BLOCKED)) {
    return BLOCKED;
  }
  if (submissions.some(({ status }) => status === PROCESSING || status === QUEUED || status === PENDING_SANCTIONS)) {
    return PROCESSING;
  }
  if (submissions.some(({ status }) => status === PUBLISHING)) {
    return PUBLISHING;
  }
  if (submissions.some(({ status }) => status === PROCESSED)) {
    return PROCESSED;
  }
  if (submissions.some(({ status }) => status === UPLOADED)) {
    return UPLOADED;
  }

  return '';
};
