import * as utils from 'utils';

interface IGetTooltipTextProps {
  hasUnpublishedBDX?: boolean;
  hasUnallocatedLines?: boolean;
  isThirdPartyAccountUnset?: boolean;
}

export const getTooltipText = ({ hasUnpublishedBDX, hasUnallocatedLines, isThirdPartyAccountUnset }: IGetTooltipTextProps): string => {
  if (hasUnpublishedBDX) return utils.string.t('notification.binderManagement.aggregate.tooltip.unpublishedBdx');
  if (hasUnallocatedLines) return utils.string.t('notification.binderManagement.aggregate.tooltip.unallocatedLines');
  if (isThirdPartyAccountUnset) return utils.string.t('notification.binderManagement.aggregate.tooltip.unset');
  return '';
};
