import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { adminAxios } from 'lib/admin';
import { enqueueNotification, hideModal } from 'stores';
import { OPERATIONAL_MARKET_DETAIL } from 'lib/admin/queryKeys';

export const useEditOperationalMarkets = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useEditOperationalMarkets',
      message: 'Data missing for POST request',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.operationalMarketsPutFail', 'error'));
    dispatch(hideModal());
  };

  const handleSuccess = (res) => {
    const { data } = res;

    queryClient.invalidateQueries(OPERATIONAL_MARKET_DETAIL, data.id);

    dispatch(
      enqueueNotification('notification.admin.operationalMarketsPutSuccess', 'success', {
        data: { marketName: data?.name },
      })
    );
    dispatch(hideModal());
  };

  return useMutation(
    async ({ data }) => {
      const { id, ...rest } = data;

      return await adminAxios.put(`marketParent/${id}`, rest);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
