import React, { useState } from 'react';

import { Popover, Box, Typography, IconButton, TextField, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useHeaderSelectClasses } from './HeaderSelect.styles';
import { Button, Translate } from 'components';
import * as utils from 'utils';

export const HeaderSelect = ({ defaultRow, reUpload, inputEl, handleClose }) => {
  const classes = useHeaderSelectClasses();
  const [rowNumber, setRowNumber] = useState(defaultRow);

  const handleSubmit = () => {
    reUpload(rowNumber);
    handleClose();
  };

  const handleChange = (e) => {
    const newVal = e.target.value;
    setRowNumber(newVal);
  };

  return (
    <Popover
      style={{
        padding: '1rem',
        paddingTop: 0,
        paddingBottom: 0,
      }}
      open={true}
      anchorEl={inputEl.current}
      disableScrollLock={true}
      disableRestoreFocus
      onClose={() => {
        handleClose();
      }}
    >
      <Box className={classes.commentsBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.HeaderRowTitle}>
          <Typography>{utils.string.t('form.bdx.selectRow')}</Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container direction="column">
          <Grid item>
            <TextField
              label="Row Number"
              style={{
                maxWidth: 200,
                paddingBottom: '1rem',
              }}
              variant="outlined"
              value={rowNumber}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              text={<Translate label="app.submit" />}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              size="small" // eslint-disable-next-line
              disabled={rowNumber === '' || rowNumber == defaultRow}
            />
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};
