import { useDispatch } from 'react-redux';
import { firstBy } from 'thenby';

import { Grid, Typography } from '@material-ui/core';
import { Add, ImportExport, PlaylistPlay } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Accordion, Button } from 'components';

import { UPLOAD_RULES } from 'consts';
import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

import { useDownloadRules } from '../../hooks/useDownloadRules';
import { Rule } from '../../Rule';
import { useRulesListStyle } from '../RulesList.style';

import { NoResultsRulesList } from './NoResultsRulesList';

export const TPARulesAccordion = ({ tpa, facility, bdxType, isLoading, handlers, ruleset }) => {
  const classes = useRulesListStyle();
  const dispatch = useDispatch();

  const { handleDownloadRules } = useDownloadRules();

  const rules =
    ruleset?.rules
      ?.map((rule) => ({
        ...rule,
        fileName: ruleset.fileName,
        isFavourite: !!rule?.ruleId,
        thirdPartyId: ruleset.thirdPartyId,
      }))
      .sort(firstBy(utils.sort.array('numeric', 'salience', 'asc')).thenBy(utils.sort.array('string', 'name', 'asc'))) || [];

  const handleAddRule = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_RULE',
        props: {
          title: 'rules.addRule.title',
          fullWidth: true,
          maxWidth: 'xl',
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility,
            bdxType,
            thirdPartyId: tpa.id,
            tpaRuleset: ruleset,
          },
        },
      })
    );
  };

  const handleRunRules = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'UPLOAD_BDX',
        props: {
          title: [utils.string.t('app.run'), facility.coverHolder, facility.umr].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility: { ...facility, bdxType },
            isTest: true,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX')),
            thirdPartyId: tpa.id,
          },
        },
      })
    );
  };

  const handleImportRules = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'IMPORT_RULES',
        props: {
          title: `Import ${bdxType} Rules - ${tpa.name}`,
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            bdxType,
            facility,
            ruleset,
            thirdPartyId: tpa.id,
          },
        },
      })
    );
  };

  const handleUploadRules = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: UPLOAD_RULES,
        props: {
          title: `Upload ${bdxType} Rules`,
          fullWidth: true,
          maxWidth: 'md',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            bdxType,
            facility: facility?.umr,
            thirdPartyId: tpa?.id,
          },
        },
      })
    );
  };

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    handleDownloadRules({ rulesFile: ruleset.fileName });
  };

  const Title = () => (
    <Grid className={classes.tpaTitleWrapper} container spacing={1}>
      <Grid item>
        <Typography variant="h3" className={classes.tpaTitle}>
          {tpa.name}
        </Typography>
      </Grid>
      <Grid item className={classes.controls} container spacing={1}>
        <Grid item>
          <Button
            size="small"
            text={utils.string.t('app.run')}
            icon={PlaylistPlay}
            variant="contained"
            color="secondary"
            onClick={handleRunRules}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            text={utils.string.t('app.importRules')}
            icon={ImportExport}
            variant="contained"
            color="primary"
            onClick={handleImportRules}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            text={utils.string.t('rules.addRule.title')}
            icon={Add}
            variant="contained"
            color="primary"
            onClick={handleAddRule}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            text={utils.string.t('rules.uploadRules')}
            icon={CloudUploadIcon}
            variant="contained"
            color="primary"
            onClick={handleUploadRules}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            text={utils.string.t('rules.downloadRules')}
            icon={CloudDownloadIcon}
            variant="contained"
            color="primary"
            onClick={handleDownloadClick}
            disabled={rules?.length === 0}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Accordion titleComponent={<Title />} kay={tpa.id} type="solid">
      <div className={classes.tpaList}>
        {rules?.length !== 0 ? (
          rules?.map((rule) => (
            <Rule
              key={`${rule.id}-${rule.name}`}
              ruleset={ruleset}
              rule={rule}
              bdxType={bdxType}
              facility={facility}
              handleFavourites={handlers.handleFavourites}
              isLoading={isLoading}
              handleDeleteRule={handlers.handleDeleteRule}
            />
          ))
        ) : (
          <NoResultsRulesList />
        )}
      </div>
    </Accordion>
  );
};
