import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { ADMIN_BINDER_MANAGEMENT_USER } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useUpdateBMUser = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.admin.mutations.useUpdateBMUser',
    message: 'API error when updating BM user',
    warning: 'notification.admin.userBMFacilitiesFail',
  });

  const handleSuccess = (res) => {
    queryClient.invalidateQueries([ADMIN_BINDER_MANAGEMENT_USER, res.data?.userId]);
    dispatch(enqueueNotification('notification.admin.userBMFacilitiesSuccess', 'success'));
  };

  return useMutation(async (data) => binderManagementFacilitiesAxios.post('user', data), {
    onSuccess: (res) => handleSuccess(res),
    onError: (err) => handleError(err),
  });
};
