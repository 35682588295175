import { useQuery } from 'react-query';
import orderBy from 'lodash/orderBy';

import config from 'config';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// app
import { adminAxios, authAxios } from './admin.api';
import { MY_USER_DETAILS, USER_DETAILS } from './queryKeys';

export const useGetUserList = (params = {}) => {
  const { departmentId, fullName, page, size, sort, filters } = params;

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getUserList',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const endpointParams = {
    page: page || 1,
    size: size || config.ui.gridPagination.default,
    orderBy: sort?.by || 'fullName',
    direction: sort?.direction || 'asc',
    ...(fullName && { nameContainsFilter: fullName }),
    ...filters,
  };

  const apiUrl = departmentId ? `/v2/user/${departmentId}/user` : '/v2/user';

  return useQuery(
    ['userList', endpointParams],
    async () => {
      const result = await adminAxios.get(apiUrl, {
        params: endpointParams,
      });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetUserDetails = (userId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getUserDetails',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [USER_DETAILS, userId],
    async () => {
      const result = await adminAxios.get(`/user/${userId}`);
      return result?.data?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetMyUserDetails = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getUserDetails',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [MY_USER_DETAILS],
    async () => {
      const result = await adminAxios.get(`/user`);
      return result?.data?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetCarriersProgrammes = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getCarriersProgrammes',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    ['carriersProgrammes'],
    async () => {
      const result = await authAxios.get('/v1/carriers/edge');
      const data = orderBy(result?.data, ['name'], ['asc']);
      return data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetProductsProgrammes = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getProductsProgrammes',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    ['productsProgrammes'],
    async () => {
      const result = await authAxios.get('/v1/products/edge');
      const data = orderBy(result?.data, ['name'], ['asc']);
      return data?.length > 1 && data[1];
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetUserProgrammes = (programmesUserId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getUserProgrammes',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    ['userProgrammes', programmesUserId],
    async () => {
      if (!programmesUserId) return;
      const result = await authAxios.get(`/v1/users/edge/${programmesUserId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useEmailSearch = (email) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.emailSearch',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    ['emailId', email],
    async () => {
      if (!email) return;
      const result = await adminAxios.get(`/user/emailId/${email}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
      enabled: false,
    }
  );
};
