import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { UPLOAD_BDX_STEPS } from 'consts';
import {
  useGetBdxTypes,
  useGetFacilityConfig,
  useGetIfSubmissionExistsForFacility,
  useGetRiskCodes,
  useUploadBDXCoverHolderDetails,
  useUploadBDXDetails,
  useUploadBDXFile,
  useValidatePassword,
} from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { UploadBDXContext } from '../UploadBDX.context';

import { BdxCoverHolderFields, BdxFields, getFormValues, getSelectOptions } from './UploadFile.utils';
import { UploadFileView } from './UploadFile.view';

const UploadFile = ({ fullScreen, handlers, defaultFile, error }) => {
  const {
    requestId,
    fileRequestId,
    setRequestId,
    setFileReady,
    sanctionsCheckOnly,
    facility,
    bdxType,
    isTest,
    yearMonthSubmission,
    isFileReady,
    setPassword,
    password,
    setRowId,
    setExpectedFields,
    setSpreadsheetFields,
    setStoredFileUploadValues,
    storedFileUploadValues,
    setSheetName,
    sheets,
    setSheets,
    thirdPartyId,
    setThirdPartyId,
    operationalClientId,
    isCoverHolderUpload,
    setvalidatedCoverHolderData,
    defaultBdxType,
  } = useContext(UploadBDXContext);

  const fieldParams = {
    facility,
    bdxType,
    storedFileUploadValues,
    isTest,
    yearMonthSubmission,
    thirdPartyId,
    defaultBdxType,
    isCoverHolderUpload,
    ...(isCoverHolderUpload && operationalClientId),
  };

  const fields = isCoverHolderUpload ? BdxCoverHolderFields(fieldParams) : BdxFields(fieldParams);

  const [file, setFile] = useState(defaultFile || null);
  const [selectedBDXType, setSelectedBDXType] = useState(bdxType || facility?.bdxType);
  const [submissionsInput, setSubmissionsInput] = useState({
    facilityReference: facility?.umr,
    yearMonthSubmission,
    bdxType,
  });
  const dragDropRef = useRef(null);
  const dispatch = useDispatch();

  const { data: isAllowedToSubmit, isFetched: isFetchedAllowedToSubmit } = useGetIfSubmissionExistsForFacility(submissionsInput, {
    enabled: !sanctionsCheckOnly,
  });

  const { mutateAsync: uploadBDXFile, isLoading: isUploadingBDXFile } = useUploadBDXFile();
  const { mutateAsync: uploadBDXDetails } = useUploadBDXDetails();
  const { mutateAsync: UploadBDXCoverHolderDetails } = useUploadBDXCoverHolderDetails();
  const uploadBdxDetails = isCoverHolderUpload ? UploadBDXCoverHolderDetails : uploadBDXDetails;
  // in future the same endpoint will be used and this ternary will be unnecessary
  // TODO - change single uploadBDX to use V2 endpoint - same as uploadBDXCoverHolder
  const methods = useForm({ mode: 'onChange' });

  const bdxTypeValue = selectedBDXType?.label || bdxType || facility?.bdxType;

  const defaultRiskCodes = facility?.riskCodes?.map(({ code }) => code).join(',');
  const { data: bdxTypes } = useGetBdxTypes(defaultRiskCodes, sanctionsCheckOnly);
  const { data: riskCodes } = useGetRiskCodes(bdxTypeValue, sanctionsCheckOnly, { enabled: !!bdxTypeValue || sanctionsCheckOnly });
  const { mutateAsync: validatePassword } = useValidatePassword();
  const { data: config } = useGetFacilityConfig({ umr: facility?.umr, bdxType: bdxTypeValue }, { enabled: !!bdxTypeValue });
  const fieldsWatched = useWatch({ control: methods?.control, name: ['facility', 'bdxPeriod', 'bdxType'] });

  useEffect(() => {
    const { facility, bdxPeriod, bdxType } = methods?.getValues();
    const yearMonth = !!bdxPeriod && `${bdxPeriod?.split('-')[0]}-${bdxPeriod?.split('-')[1]}`;
    setSubmissionsInput({
      facilityReference: facility,
      yearMonthSubmission: yearMonth,
      bdxType: bdxType?.label,
    });
  }, [fieldsWatched, methods]);

  useEffect(() => {
    if (sanctionsCheckOnly && riskCodes) {
      methods.setValue('riskCodes', { label: riskCodes.join(',') });
    }
  }, [riskCodes, sanctionsCheckOnly, methods]);

  const handleFileUploadSuccess = useCallback(
    ({ data }) => {
      methods.setValue('requestId', data.requestId);
      setSheets(data.sheets);
      setRequestId(data.requestId);
      setFileReady(true);
    },
    [methods, setSheets, setRequestId, setFileReady]
  );

  useEffect(() => {
    fields.forEach(({ name, defaultValue }) => {
      if (defaultValue) {
        methods.setValue(name, defaultValue);
      }
    });

    if (Object.keys(storedFileUploadValues).length > 0) {
      Object.entries(storedFileUploadValues).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
      setFile(storedFileUploadValues.file);
    }
  }, [fields, methods, storedFileUploadValues]);

  useEffect(() => {
    methods.setValue('bdxCumulative', config?.bdxCumulative || false);
  }, [config, methods]);

  const resetOnFileChange = useCallback(() => {
    setPassword(null);
    setFile(null);
    setSheets([]);
    methods.setValue('sheets', []);
    methods.setValue('requestId', null);
    setRequestId(null);
  }, [setPassword, setSheets, methods, setRequestId]);

  const resetForm = useCallback(() => {
    resetOnFileChange();
    dragDropRef?.current?.removeFile();
  }, [resetOnFileChange]);

  const handleUploadBDXFile = useCallback(
    async (file) => {
      if (file) {
        setSheets([]);
        methods.setValue('sheets', []);
        setFileReady(false);
        const { data } = await uploadBDXFile({ file });
        if (data.securityStatus === 'PASSWORD_REQUIRED') {
          if (password) {
            const dataWithPassword = await validatePassword({ password, requestId: data.requestId });
            handleFileUploadSuccess(dataWithPassword);
          } else {
            dispatch(
              showModal({
                component: 'VALIDATE_FILE_PASSWORD',
                props: {
                  title: 'form.bdx.validateFilePassword',
                  fullWidth: true,
                  maxWidth: 'xs',
                  disableBackdropClick: true,
                  componentProps: {
                    requestId: data.requestId,
                    cancelHandler: () => {
                      resetForm();
                    },
                    onSuccess: ({ data, password }) => {
                      handleFileUploadSuccess(data);
                      setPassword(password);
                    },
                  },
                },
              })
            );
          }
        }
        if (data.securityStatus === 'PASSWORD_NOT_REQUIRED') {
          handleFileUploadSuccess({ data });
        }
      }
    },
    [setFileReady, uploadBDXFile, password, validatePassword, handleFileUploadSuccess, dispatch, resetForm, setPassword]
  );

  useEffect(() => {
    if (file === defaultFile) {
      handleUploadBDXFile(defaultFile);
    }
  }, [defaultFile, file, handleUploadBDXFile]);

  useEffect(() => {
    if (!file && Object.keys(storedFileUploadValues).length === 0) {
      resetForm();
    }
  }, [file, resetForm, storedFileUploadValues]);

  const bdxTypesOptions = (isCoverHolderUpload ? getSelectOptions(['Claim', 'Premium', 'RLD']) : getSelectOptions(bdxTypes)) || [];

  const bdxTypeDefaultValue =
    fields.find((field) => field.name === 'bdxType')?.defaultValue || (bdxTypesOptions?.length === 1 ? bdxTypesOptions[0] : null);

  const handleNext = async () => {
    const isFormValid = await methods.trigger();
    if (isFormValid && requestId && file) {
      try {
        handlers.setLoading(true);
        const isDisabledToSubmit = isTest ? false : !isAllowedToSubmit;
        if (!isCoverHolderUpload && isFetchedAllowedToSubmit && isDisabledToSubmit) {
          handlers.setError(utils.string.t('notification.binderManagement.aggregate.submissionCheck.message'));
          handlers.setLoading(false);
          resetForm();
          return;
        }
        const formValues = getFormValues(
          methods.getValues(),
          isCoverHolderUpload,
          isTest,
          operationalClientId,
          facility,
          requestId,
          password,
          defaultRiskCodes,
          riskCodes,
          fileRequestId
        );
        const { data } = await uploadBdxDetails({
          ...formValues,
        });
        isCoverHolderUpload && setvalidatedCoverHolderData(data);
        setRowId(data.rowId);
        setExpectedFields(data.expectedMapperFields);
        setSpreadsheetFields(data.spreadsheetFields);
        setStoredFileUploadValues({ ...methods.getValues(), file });
        setSheetName(data.sheetName);
        setThirdPartyId(formValues.thirdParty);
        if (data.status === 'MAPPING_REQUIRED') {
          handlers.getHandleCompleteStep(UPLOAD_BDX_STEPS.UPLOAD_FILE)();
          handlers.getHandleSetStep(UPLOAD_BDX_STEPS.FIELD_MAPPING)();
        }
        if (data.status === 'SUCCESS') {
          handlers.skiptoLastStep();
          handlers.getHandleCompleteStep(UPLOAD_BDX_STEPS.UPLOAD_FILE)();
          handlers.getHandleCompleteStep(UPLOAD_BDX_STEPS.FIELD_MAPPING)();
        }
      } finally {
        handlers.setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <UploadFileView
        file={file}
        setFile={setFile}
        methods={methods}
        fullScreen={fullScreen}
        isUploadingBDXFile={isUploadingBDXFile}
        handleUploadBDXFile={handleUploadBDXFile}
        dragDropRef={dragDropRef}
        isFileReady={isFileReady}
        bdxTypesOptions={bdxTypesOptions}
        bdxTypeDefaultValue={bdxTypeDefaultValue}
        sheets={sheets}
        fields={fields}
        isTest={isTest}
        error={error}
        isCoverHolderUpload={isCoverHolderUpload}
        sanctionsCheckOnly={sanctionsCheckOnly}
        thirdParties={config?.thirdParties || []}
        config={config}
        setSelectedBDXType={setSelectedBDXType}
        resetOnFileChange={resetOnFileChange}
        handleNext={handleNext}
      />
    </FormProvider>
  );
};

export default UploadFile;
