import React from 'react';
import PropTypes from 'prop-types';

// app
import { FormLabelView } from './FormLabel.view';

FormLabel.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  parseDangerousHtml: PropTypes.bool,
};
FormLabel.defaultProps = {
  variant: 'body2',
  parseDangerousHtml: true,
};

export default function FormLabel({ label, variant, parseDangerousHtml }) {
  return <FormLabelView label={label} variant={variant} parseDangerousHtml={parseDangerousHtml} />;
}
