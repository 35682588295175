import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_MARKET_FACILITY_FILTERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetMarketFacilityFilters = ({ marketId }) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetMarketFacilityFilters',
  });

  return useQuery<IGetMarketFacilityFiltersResponse, AxiosError, IGetMarketFacilityFiltersDTO>(
    [FACILITY_MARKET_FACILITY_FILTERS, marketId],
    async () => {
      if (!marketId) return null;
      const result = await binderManagementFacilitiesAxios.get(`facilities/filter/markets/${marketId}`);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
