import { useMutation } from 'react-query';
import { authorizedLocation } from 'lib/quoteBind/quoteBind.api';
import { useAuth0 } from '@auth0/auth0-react';

async function getRiskAddress(searchTerm, componentRestrictions, token) {
  const data = {
    address: searchTerm,
    ...(componentRestrictions && { componentRestrictions }),
  };

  const response = await authorizedLocation(token).post(`search`, data);
  return response.data?.data || {};
}

export function useGetAddress() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async ({ searchTerm, componentRestrictions }) => {
      if (!searchTerm) return;
      const token = await getAccessTokenSilently();

      const result = await getRiskAddress(searchTerm, componentRestrictions, token);

      return result;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );
}
