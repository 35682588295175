import { useState } from 'react';

// MUI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import { withStyles } from '@material-ui/core/styles';

// app
import { Button, Empty, Tooltip } from 'components';
import { useSummaryTableStyles } from 'forms/UploadBDX/Summary/SummaryTable/SummaryTable.styles';
import { CircularProgress } from 'components/CircularProgress';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as BlockedRulesImage } from 'assets/svg/mapping-not-found.svg';
import * as utils from 'utils';

const TableHeadStyled = withStyles((theme) => ({
  root: {
    backgroundColor: '#107C41 !important',
  },
}))(TableHead);

export const GetUnprocessedRowsTable = ({ data = {}, fullView, fullScreen, toggleFullScreen, progress }) => {
  const classes = useSummaryTableStyles({
    fullView,
    fullScreen,
    isUnprocessedRows: true,
  });

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (data?.headers.length === 0 || data?.rows.length === 0) {
    return (
      <Empty width={400} title={utils.string.t('notification.reporting.reportDetails.notFound')} icon={<BlockedRulesImage />} padding />
    );
  }

  return (
    <div>
      <Grid container className={classes.summaryHeader}>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: 'auto',
          }}
        >
          <Grid item>
            <Typography variant="h2" noWrap>
              {utils.string.t('form.bdx.bdxSample')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item style={{ margin: '3px' }}>
          <Box display="flex" alignItems="center">
            <TablePagination
              component="div"
              page={page}
              count={data.rows?.length}
              rowsPerPage={100}
              rowsPerPageOptions={[]}
              onPageChange={handleChangePage}
              centered
            />
            <CircularProgress value={progress} />
            <Button
              icon={fullView ? FullScreenExitIcon : FullScreenIcon}
              variant="text"
              onClick={() => {
                toggleFullScreen();
              }}
              data-testid="bdxsample-fullscreen-button"
            />
          </Box>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {data.rows?.length > 0 ? (
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHeadStyled>
                  <TableRow>
                    {data.headers?.length > 0 &&
                      data.headers.map((header) => (
                        <TableCell key={header} align="center">
                          <Tooltip title={<HeaderTooltip header={header} />} placement="top" rich>
                            {header}
                          </Tooltip>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHeadStyled>
                <TableBody>
                  {data.rows?.length > 0 &&
                    data.headers?.length > 0 &&
                    data.rows.map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {row.map((rowValue) => {
                          return (
                            <TableCell key={`${rowValue}-${index}`} align="center" className={classes.autoCompleteLabel}>
                              {rowValue?.length > 15 ? (
                                <Tooltip title={rowValue} placement="top" rich>
                                  {rowValue}
                                </Tooltip>
                              ) : (
                                rowValue
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Empty width={360} title={utils.string.t('form.bdx.summaryInProgress')} icon={<Loading />} padding />
        )}
      </div>
    </div>
  );
};

const HeaderTooltip = ({ header }) => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        <span style={{ fontWeight: 600 }}>{header}</span>
      </Typography>
    </Box>
  );
};
