import { FC, useCallback } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { useDispatch } from 'react-redux';

import { CircularProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { BaseTableCell, BaseTableHeaderCell, Button, Chip } from 'components';

import { useGetClaimBlocks } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { useFacilityConfigStyles } from '../FacilityConfig.style';

import 'react-base-table/styles.css';

const HEADER_HEIGHT = 50;
const MAX_VISIBLE_ROWS = 5;

interface IClaimBlockConfigProps {
  umr: string;
}

const BLOCK_STATUS_MAP: Record<IClaimBlock['status'], string> = {
  PENDING: 'default',
  IMPORTED: 'live',
  EDGE_CREATED: 'uploaded_bdx',
};

export const ClaimBlockConfig: FC<IClaimBlockConfigProps> = ({ umr }) => {
  const { data: claimBlocks, isLoading } = useGetClaimBlocks({ umr });
  const dispatch = useDispatch();
  const theme = useTheme<Theme>();

  const classes = useFacilityConfigStyles();

  const tableHeight = Math.min(((claimBlocks?.length ?? 0) + 1) * HEADER_HEIGHT, MAX_VISIBLE_ROWS * HEADER_HEIGHT);

  const handleCreateBlock = () => {
    dispatch(
      showModal({
        component: 'ADD_CLAIM_BLOCK',
        props: {
          title: 'form.facilityConfig.claims.addClaimBlock',
          fullWidth: true,
          maxWidth: 'xs',
          disableBackdropClick: true,
          componentProps: {
            umr,
          },
        },
      })
    );
  };

  const StatusRenderer = useCallback(
    ({ cellData }) => {
      const statusBackgroundColor = theme.palette.bm.status[BLOCK_STATUS_MAP[cellData]] ?? theme.palette.bm.status.default;
      const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

      return (
        <Chip
          label={utils.string.t(`form.facilityConfig.claims.${cellData}`)}
          type={cellData}
          data-testid="bdxInfo-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
            fontSize: '11px',
            zIndex: 1,
            width: 'fit-content',
          }}
        />
      );
    },
    [theme]
  );

  return (
    <div className={classes.group}>
      <Typography variant="h3" className={classes.groupTitle}>
        {utils.string.t('form.facilityConfig.claims.claimBlocks')}
      </Typography>

      {isLoading ? (
        <div style={{ height: tableHeight }} className={classes.groupLoader}>
          <CircularProgress data-testid="loading" />
        </div>
      ) : (
        <>
          <AutoResizer height={tableHeight}>
            {({ height, width }) => (
              <BaseTable
                data={claimBlocks}
                height={height}
                width={width}
                headerHeight={HEADER_HEIGHT}
                rowKey="ucr"
                components={{ TableCell: BaseTableCell, TableHeaderCell: BaseTableHeaderCell }}
              >
                <Column
                  key="name"
                  dataKey="name"
                  flexGrow={1}
                  title={utils.string.t('form.facilityConfig.claims.name')}
                  width={width - 600}
                />
                <Column key="ucr" dataKey="ucr" width={180} title={utils.string.t('form.facilityConfig.claims.ucr')} />
                <Column
                  key="uwGroupName"
                  dataKey="uwGroupName"
                  width={180}
                  title={utils.string.t('form.facilityConfig.claims.uwGroupName')}
                />
                <Column
                  key="interestType"
                  dataKey="interestType"
                  width={140}
                  title={utils.string.t('form.facilityConfig.claims.interestType')}
                />
                <Column
                  key="status"
                  dataKey="status"
                  width={100}
                  title={utils.string.t('form.facilityConfig.claims.status')}
                  cellRenderer={StatusRenderer}
                />
              </BaseTable>
            )}
          </AutoResizer>
          <div className={classes.claimBlockActions}>
            <Button text={utils.string.t('form.facilityConfig.claims.addClaimBlock')} color="primary" onClick={handleCreateBlock} />
          </div>
        </>
      )}
    </div>
  );
};
