import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { PAGE_SIZE } from 'consts/quote-bind';

async function getFacilities(params, token, id = null) {
  const apiURL = id ? `facilities/${id}` : 'facilities';

  const response = await api(token).get(apiURL, {
    params: {
      orderBy: params.orderBy || 'name',
      direction: params.direction || 'DESC',
      page: params.page || 1,
      size: params.size || PAGE_SIZE,
      ...params,
    },
  });
  return response.data;
}

export function useGetRiskFacilities(queryKey, id, params, options) {
  return useApi([queryKey, { ...params }, id], async (params, token) => await getFacilities(params, token, id), {
    cacheTime: 1000 * 60 * 15,
    ...options,
  });
}
