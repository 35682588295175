import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { PAGE_SIZE } from 'consts/quote-bind';

async function getCarriers(params, token) {
  const response = await api(token).get('carriers', {
    params: {
      orderBy: params.orderBy || 'name',
      direction: params.direction || 'ASC',
      page: params.page || 1,
      size: params.size || PAGE_SIZE,
      ...params,
    },
  });
  return response.data;
}

export function useGetCarriers(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (params, token) => await getCarriers(params, token), {
    cacheTime: 1000 * 60 * 60 * 24,
    ...options,
  });
}
