import { useMutation } from 'react-query';
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { useAuth0 } from '@auth0/auth0-react';

async function getInsured(id, reInsured, token) {
  if (!id) {
    throw new Error('useGetInsuredDetailMutation: id is required');
  }
  const apiURL = reInsured ? `reinsured/${id}` : `insured/${id}`;

  const response = await api(token).get(apiURL);
  return response.data;
}

export function useGetInsuredDetail(queryKey, id, options, reInsured = false) {
  return useApi([queryKey, id], async (id, token) => await getInsured(id, reInsured, token), {
    cacheTime: 1000 * 60 * 15,
    ...options,
  });
}

export function useGetInsuredDetailMutation() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async ({ id, reInsured = false }) => {
      const token = await getAccessTokenSilently();

      const result = await getInsured(id, reInsured, token);

      return result;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );
}
