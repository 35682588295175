import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import * as utils from 'utils';
import { BreadcrumbView } from './Breadcrumb.view';
import { IBreadcrumbProps } from './Breadcrumb.types';

export const Breadcrumb: FC<IBreadcrumbProps> = ({ links, testid }) => {
  const location = useLocation();

  // Abort
  if (!utils.generic.isValidArray(links, true)) return null;

  return <BreadcrumbView links={links} path={location.pathname} testid={testid} />;
};
