import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

// app
import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

interface IUseValidatePasswordProps {
  requestId: string;
  password: string;
}

export const useValidatePassword = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useValidatePassword',
    warning: 'notification.bdx.validateFilePasswordFail',
  });

  return useMutation<AxiosResponse<IFileUploadSecurity>, AxiosError, IUseValidatePasswordProps>(
    async ({ requestId, password }) => {
      const form = new FormData();

      form.append('requestId', requestId);
      form.append('password', password);

      const data = await binderManagementFileInputAxios.post<IFileUploadSecurity>('password', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (data.data.securityStatus === 'PASSWORD_REQUIRED') {
        throw new Error('Wrong password');
      }

      return data;
    },
    {
      onError: handleError,
    }
  );
};
