import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import has from 'lodash/has';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification } from 'stores';
import { FACILITY_RATES } from 'lib/quoteBind/queryKeys';

export const usePostFacilityRates = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePostFacilityRates',
      message: 'Data missing for facility rates.',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([FACILITY_RATES, res.facilityId], res);

    dispatch(enqueueNotification('notification.postFacilityRates.success', 'success'));
  };

  return useMutation(
    async (data) => {
      const { facilityId, ratesId, formData = {} } = data;

      const hasRatesData =
        has(formData, 'countries') &&
        has(formData, 'brokerageFee') &&
        has(formData, 'clientCommissionRate') &&
        has(formData, 'brokerCommissionRate') &&
        has(formData, 'reinsuranceRate');

      if (!facilityId || !hasRatesData) {
        throw new Error('Data missing for facility rates');
      }

      const { brokerageFee, clientCommissionRate, brokerCommissionRate, reinsuranceRate, countries = [] } = formData;

      const body = {
        ...(ratesId && { id: ratesId }),
        facilityId,
        brokerageFee,
        clientCommissionRate,
        brokerCommissionRate,
        reinsuranceRate,
        countryRates: countries.map((country) => {
          return {
            country: country?.countryCode.value,
            value: country?.rate,
            refer: country?.refer,
          };
        }, {}),
      };

      const result = await quoteBindAxios.post(`rates?facilityId=${facilityId}`, body);
      return result.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
