import { CircularProgress, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useRulesStyles } from './Rules.styles';

export const RulesSkeleton = () => {
  const classes = useRulesStyles();
  return (
    <div className={classes.root} data-testid="RulesSkeleton">
      <Grid container spacing={2}>
        <Grid item className={classes.bdxTypes}>
          <Skeleton width={265} height={36} className={classes.loadingButton} />
        </Grid>
        <Grid item>
          <Skeleton width={118} height={36} className={classes.loadingButton} />
        </Grid>
        <Grid item>
          <Skeleton width={142} height={36} className={classes.loadingButton} />
        </Grid>
        <Grid item>
          <Skeleton width={110} height={36} className={classes.loadingButton} />
        </Grid>
        <Grid item>
          <Skeleton width={110} height={36} className={classes.loadingButton} />
        </Grid>
        <Grid item>
          <Skeleton width={110} height={36} className={classes.loadingButton} />
        </Grid>
      </Grid>
      <div className={classes.rootLoader}>
        <CircularProgress />
      </div>
    </div>
  );
};
