import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_FACILITIES_USERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetFacilityUsersProps {
  umr: string;
  enabled?: boolean;
}

export const useGetFacilityUsers = ({ umr, enabled }: IUseGetFacilityUsersProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetFacilityUsers',
  });

  return useQuery<IGetFacilityUserResponse, AxiosError, IGetFacilityDTO>(
    [FACILITY_BINDER_FACILITIES_USERS, umr],
    async () => {
      if (!umr) return null;
      const result = await binderManagementFacilitiesAxios.get(`facilities/${umr}/users`);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      enabled,
    }
  );
};
