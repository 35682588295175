import { useContext } from 'react';
import get from 'lodash/get';

// mui
import { Box, ButtonBase, Chip, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Breadcrumb, BreadcrumbSkeleton, EdgeImage, Layout, Restricted, Tooltip, Translate } from 'components';
import { BinderLimits } from 'modules/BinderLimits/BinderLimits';
import { BDX, Documents, Reports } from 'modules/Binders';
import { useFacilitiesStyles } from 'modules/Binders/Facilities/Facilities.styles';
import { Rules } from 'modules/Rules';

import {
  BINDER_LIMITS,
  BORDEREAUX,
  DOCUMENTS,
  FACILITY_USERS_PRODUCERS,
  FACILITY_USERS_UNDERWRITERS,
  REPORTS,
  ROLE_BROKER,
  RULES,
} from 'consts';
import config from 'config';
import * as utils from 'utils';

import { FacilityConfigWaring } from './FacilityConfig/FacilityConfigWaring';
import { FacilityBinder } from './FacilityBinder';
import { FacilityContext } from './FacilityDetails';
import { FacilityDetailsSkeleton } from './FacilityDetailsSkeleton';
import { FacilityUserRoleInfo } from './FacilityUserRoleInfo';

// app
import { useFacilityDetailsStyles } from './FacilityDetails.styles';

export default function FacilityDetailsView({
  tabs,
  selectedTab,
  breadcrumbs,
  handlers,
  facilityUserData,
  facilityConfig,
  binderType,
  bdxTypes,
  hasLimitsAlert,
}) {
  const { facility, isLoading } = useContext(FacilityContext);
  const classes = useFacilityDetailsStyles();
  const facilityClasses = useFacilitiesStyles();
  const theme = useTheme();

  const statusBackgroundColor = theme.palette.bm.status[facility?.status?.toLowerCase()]
    ? theme.palette.bm.status[facility?.status.toLowerCase()]
    : theme.palette.bm.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);
  const disabledFacilityConfig = facility?.riskCodes?.length === 0 || bdxTypes?.length === 0;

  const RiskCodePreview = ({ code, description }) => (
    <Grid item className={facilityClasses.riskCode}>
      <Tooltip title={description} placement="bottom">
        {code}
      </Tooltip>
    </Grid>
  );

  const InfoLine = ({ label, content, value }) => (
    <Box className={facilityClasses.cardInfoLine}>
      <Typography className={facilityClasses.infoLeft}>{label}</Typography>
      {content || <Typography className={facilityClasses.infoRight}>{value}</Typography>}
    </Box>
  );

  return (
    <Box className={classes.pageContainer}>
      <Layout isCentered testid="facilityDetails">
        <Layout main padding={false}>
          <div className={classes.root}>
            <div className={classes.header}>
              {isLoading ? <BreadcrumbSkeleton items={[120, 80, 80]} /> : <Breadcrumb links={breadcrumbs} />}
            </div>
            {isLoading || !facility ? (
              <FacilityDetailsSkeleton />
            ) : (
              <div className={classes.info}>
                <Grid container spacing={4} className={classes.facilityDetailsWrapper}>
                  <Grid item className={classes.cardHeaderContent}>
                    <Box className={classes.cardHeaderTitle}>
                      {facility.brandLogoUrl && <EdgeImage url={facility.brandLogoUrl} className={classes.cardHeaderLogo} />}
                      <div className={classes.cardHeaderTitleWrapper}>
                        <Typography data-testid="umr" variant="h4" className={facilityClasses.cardHeaderTitle}>
                          {get(facility, 'umr', '-')}
                        </Typography>
                        <Typography variant="h5" className={facilityClasses.cardHeaderSubtitle}>
                          {get(facility, 'team', '-')}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item className={classes.facilityCardWrapper}>
                    <div className={classes.facilityCard}>
                      <div className={classes.facilityCardContent}>
                        <InfoLine label={utils.string.t('app.gxb')} value={facility.gxbInstanceShortName || facility.gxbInstance} />
                        {facility.riskCodes?.length > 0 && (
                          <InfoLine
                            label={utils.string.t('app.riskCodes')}
                            content={
                              <Grid container className={facilityClasses.infoRight}>
                                {facility.riskCodes?.map(({ code, description }) => (
                                  <RiskCodePreview key={code} code={code} description={description} />
                                ))}
                              </Grid>
                            }
                          />
                        )}
                        <InfoLine
                          label={utils.string.t('app.inceptionDate')}
                          value={
                            <Translate
                              label="format.date"
                              options={{ value: { date: facility.inceptionDate, format: config.ui.format.date.text, default: '-' } }}
                            />
                          }
                        />
                        <InfoLine
                          label={utils.string.t('app.expiryDate')}
                          value={
                            <Translate
                              label="format.date"
                              options={{ value: { date: facility.expiryDate, format: config.ui.format.date.text, default: '-' } }}
                            />
                          }
                        />
                        <InfoLine
                          label={utils.string.t('app.status')}
                          content={
                            <Grid container className={facilityClasses.infoRight}>
                              <Chip
                                size="small"
                                label={utils.string.capitalise(facility.status)}
                                status={facility.status}
                                data-testid="facility-status"
                                className={classes.chip}
                                style={{
                                  backgroundColor: statusBackgroundColor,
                                  color: statusColor,
                                }}
                              />
                            </Grid>
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item container spacing={2} className={classes.facilityBinders}>
                    <Grid item container spacing={2}>
                      {facility.operationalClientName && (
                        <Grid item>
                          {utils.string.t('binders.coverholders.title')}
                          <div className={classes.facilityBindersWrapper}>
                            <FacilityBinder
                              title={facility.operationalClientName}
                              url={facility.operationalClientLogoUrl}
                              pathname={`${config.routes.binders.coverholder}/${facility.operationalClientId}`}
                            />
                          </div>
                        </Grid>
                      )}
                      {facility.office && (
                        <Grid item>
                          {utils.string.t('app.office')}
                          <div className={classes.facilityBindersWrapper}>
                            <FacilityBinder title={facility.office} />
                          </div>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item container spacing={2}>
                      {facility.markets.length > 0 && (
                        <Grid item className={classes.facilityBindersContainer}>
                          {utils.string.t('app.market_plural')}
                          <div className={classes.facilityBindersWrapper}>
                            {facility.markets.map(({ operationalMarketId, operationalMarketName, operationalMarketLogoUrl }) => (
                              <FacilityBinder
                                key={operationalMarketId}
                                title={operationalMarketName}
                                url={operationalMarketLogoUrl}
                                pathname={`${config.routes.binders.market}/${operationalMarketId}`}
                              />
                            ))}
                          </div>
                        </Grid>
                      )}
                      {facilityConfig?.thirdParties?.length > 0 && (
                        <Grid item className={classes.facilityBindersContainer}>
                          {utils.string.t('admin.thirdParties')}
                          <div className={classes.facilityBindersWrapper}>
                            {facilityConfig.thirdParties.map(({ id, name, logoUrl }) => (
                              <FacilityBinder key={id} title={name} url={logoUrl} />
                            ))}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {facilityUserData && (
                    <Restricted include={[ROLE_BROKER]}>
                      <Grid item container spacing={2} className={classes.facilityBindersOptions}>
                        <Grid item className={classes.facilityBindersContainer}>
                          <div className={classes.facilityBindersUsers}>
                            {utils.string.t('app.users')}
                            <div className={classes.facilityBindersWrapper}>
                              <FacilityUserRoleInfo users={handlers.getAssignedFacilityUsers(facilityUserData.producerUsers)}>
                                <FacilityBinder
                                  title={`${utils.string.t('binders.facilities.producers')} ${facilityUserData.noProducerUsers}`}
                                  onClick={handlers.getHandleEditUsers(FACILITY_USERS_PRODUCERS)}
                                />
                              </FacilityUserRoleInfo>
                              <FacilityUserRoleInfo users={handlers.getAssignedFacilityUsers(facilityUserData.underwriterUsers)}>
                                <FacilityBinder
                                  title={`${utils.string.t('binders.facilities.underwriters')} ${facilityUserData.noUnderwriterUsers}`}
                                  onClick={handlers.getHandleEditUsers(FACILITY_USERS_UNDERWRITERS)}
                                />
                              </FacilityUserRoleInfo>
                            </div>
                          </div>
                        </Grid>
                        <Grid item className={classes.facilityBindersContainer}>
                          <div className={`${classes.facilityBindersWrapper} `}>
                            {facility?.umr && bdxTypes?.length ? <FacilityConfigWaring umr={facility.umr} bdxTypes={bdxTypes} /> : null}
                            <Tooltip title={disabledFacilityConfig ? utils.string.t('app.noBDX') : ''} placement="top">
                              <ButtonBase
                                onClick={handlers.handleOpenFacilityConfig}
                                className={`${classes.facilityConfig} ${classes.facilityBindersInfo} ${
                                  disabledFacilityConfig ? classes.disabled : ''
                                }`}
                                disabled={disabledFacilityConfig}
                              >
                                <Settings disabled={disabledFacilityConfig} />
                                <Typography variant="h5" className={classes.facilityConfigLabel}>
                                  {utils.string.t('binders.config.title')}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                    </Restricted>
                  )}
                </Grid>
              </div>
            )}
          </div>
          <Tabs value={selectedTab} onChange={handlers.handleSelectTab}>
            {tabs.map(({ value, label, disabled }) => (
              <Tab
                key={value}
                value={value}
                label={
                  value === 'BINDER LIMITS' && hasLimitsAlert ? (
                    <div className={classes.limitAlertIconWrapper}>
                      {label}
                      <InfoOutlinedIcon fontSize="small" className={classes.limitAlertIcon} />
                    </div>
                  ) : (
                    label
                  )
                }
                disabled={disabled}
              />
            ))}
          </Tabs>
          {selectedTab === REPORTS && <Reports />}
          {selectedTab === DOCUMENTS && <Documents />}
          {selectedTab === BORDEREAUX && <BDX binderType={binderType} />}
          {selectedTab === RULES && <Rules />}
          {selectedTab === BINDER_LIMITS && <BinderLimits />}
        </Layout>
      </Layout>
    </Box>
  );
}
