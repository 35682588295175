import { useState } from 'react';

import config from 'config';

export const EdgeImage = ({
  url = null,
  container = null,
  imageName = null,
  alt = null,
  width = null,
  height = null,
  placeholder = null,
  ...props
}) => {
  const [error, setError] = useState(false);

  const iconUrl = `${config.assets}/${container}/${imageName}`;

  const onError = () => {
    setError(true);
  };

  return error ? placeholder || null : <img src={url || iconUrl} alt={alt} width={width} height={height} {...props} onError={onError} />;
};
