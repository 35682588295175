import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Edge
import { UPLOAD_BDX_STEPS } from 'consts';
import { UploadBDXContent, UploadBDXActions } from '../../components';
import { hideModal } from 'stores';
import { SummaryTableCoverHolder } from './SummaryTablesCoverHolder';
import { useSummaryStyles } from 'forms/UploadBDX/Summary/Summary.styles';
import * as utils from 'utils';

SummaryCoverHolderView.propTypes = {
  isTablet: PropTypes.bool.isRequired,
};

export function SummaryCoverHolderView({ fullScreen, toggleFullScreen, fullView, handlers, handleSubmit, sheetName, isTest }) {
  const dispatch = useDispatch();
  const classes = useSummaryStyles();

  const labelNext = isTest ? 'app.close' : 'app.submit';
  const handleNext = isTest ? () => dispatch(hideModal('UPLOAD_BDX')) : handleSubmit;

  return (
    <UploadBDXContent
      actions={
        <UploadBDXActions
          labelNext={utils.string.t(labelNext)}
          handleBack={handlers.getHandleSetStep(UPLOAD_BDX_STEPS.FIELD_MAPPING)}
          handleNext={handleNext}
        />
      }
    >
      <div className={classes.content}>
        <h2 className={classes.sheetName}>
          {utils.string.t('form.bdx.sheet')}: {sheetName}
        </h2>
        <SummaryTableCoverHolder toggleFullScreen={toggleFullScreen} fullView={fullView} fullScreen={fullScreen} />
      </div>
    </UploadBDXContent>
  );
}
