import { makeStyles } from '@material-ui/core/styles';

export const useBDXOverviewStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    flex: 1,
    margin: theme.spacing(3),
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: '600',
    fontSize: theme.typography.pxToRem(20),
    color: `${theme.palette.neutral.darker}!important`,
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  infoCard: {
    boxShadow: theme.shadows[2],
    borderRadius: 10,
    margin: theme.spacing(1),
    marginRight: 0,
    cursor: 'pointer',
    width: '300px',
    height: 90,
  },
  infoCardIcon: {
    marginRight: theme.spacing(1),
  },
  infoCardTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: `${theme.palette.neutral.darker}!important`,
    fontWeight: 500,
  },
}));
