import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_LINEAGE_AGGREGATES_EXISTS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetIfSubmissionExistsForLineage = ({ yearMonthSubmission }, options = {}) => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.queries.useGetIfSubmissionExistsForLineage',
  });

  return useQuery<boolean>(
    [BDX_LINEAGE_AGGREGATES_EXISTS, yearMonthSubmission],
    async () => {
      try {
        if (!yearMonthSubmission) throw new Error('Missing required params');

        const path = `aggregate/lineage/year-month/${yearMonthSubmission}/exists`;
        const result = await binderManagementBDXAxios.get(path);
        const res = result?.status === 204;

        return res;
      } catch (ex) {
        return false;
      }
    },
    {
      onError: handleError,
      keepPreviousData: false,
      ...options,
    }
  );
};
