import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// app
import * as utils from 'utils';
import { showModal } from 'stores';
import { PreventNavigationView } from './PreventNavigation.view';

PreventNavigation.propTypes = {
  dirty: PropTypes.bool,
};

export function PreventNavigation({ dirty }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [location, setLocation] = useState();
  const [allowNavigation, setAllowNavigation] = useState(false);

  useEffect(
    () => {
      if (location && allowNavigation) {
        history.push(location);
      }
    },
    [location, allowNavigation] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const launchConfirmModal = () => {
    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: 'navigation.page.title',
          subtitle: 'navigation.page.subtitle',
          fullWidth: true,
          maxWidth: 'sm',
          componentProps: {
            cancelLabel: utils.string.t('navigation.page.cancel'),
            confirmLabel: utils.string.t('navigation.page.confirm'),
            submitHandler: () => {
              setAllowNavigation(true);
            },
          },
        },
      })
    );
  };

  const handleInternalNavigation = (location) => {
    if (!allowNavigation) {
      setLocation(location.pathname);
      launchConfirmModal();
      return false;
    }
    return true;
  };

  return <PreventNavigationView dirty={dirty} handleInternalNavigation={handleInternalNavigation} />;
}

export default PreventNavigation;
