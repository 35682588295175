import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { BDX_AGGREGATE_TYPE_PREMIUM } from 'consts';
import { enqueueNotification, hideModal } from 'stores';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES, BDX_PREMIUM_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useAssignToUnderwriterGroup = ({ facilityReference, yearMonthSubmission, type }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useAssignToUnderwriterGroup',
    warning: 'notification.binderManagement.assignUwGroup.fail',
  });

  const handleSuccess = (config) => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === BDX_PREMIUM_DATA && (query.queryKey[1]?.uwGroup === config.uwGroup || query.queryKey[1]?.uwGroup === null),
    });
    queryClient.invalidateQueries([BDX_AGGREGATES, BDX_AGGREGATE_TYPE_PREMIUM, facilityReference, yearMonthSubmission]);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.binderManagement.assignUwGroup.success', 'success'));
  };

  return useMutation(
    async (config) => {
      await binderManagementBDXAxios.put(`uwGroups`, config);

      return config;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
