import { makeStyles } from '@material-ui/core';
import { he } from 'date-fns/locale';

export const useFieldMappingStyles = makeStyles(
  (theme) => ({
    root: {
      overflowY: 'auto',
      alignItems: 'center',
      overflowX: 'hidden',
      padding: theme.spacing(2),
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    field: {
      fontWeight: 'bold',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: '1px',
      borderColor: '#2CC6AB !important',
    },
    fuzzyNotchedOutline: {
      borderWidth: '1px',
      borderColor: '#edac00 !important',
    },
    autoCompleteLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
      paddingBottom: '0.5rem',
    },
    sheetName: {
      margin: theme.spacing(0, 0, 2),
    },
    helpIcon: {
      margin: theme.spacing(0, 1),
      height: 12,
      width: 12,
      color: theme.palette.warning.main,
      position: 'relative',

      '& > svg': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        margin: '-10px 0 0 -10px',
        height: 20,
        width: 20,
      },
    },
    sanctionsMandatoryField: {
      overflow: 'visible',
      display: 'flex',
      alignItems: 'center',
    },
    sanctionsTooltip: {
      overflow: 'hidden',
    },
    clearButton: {
      position: 'absolute',
      right: 0,
      top: 7,
      '&.MuiButtonBase-root': {
        height: 24,
        fontSize: 12,
      },
    },
  }),
  { name: 'FieldMapping' }
);
