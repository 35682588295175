import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ISubmitLineageMappingDTO } from 'forms/AddEditLineageMapping/AddEditLineageMapping.types';

import { enqueueNotification } from 'stores';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { LINEAGE_MAPPING } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitLineageMapping = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useSubmitLineageMapping',
    warning: 'notification.lineage.submitMappingFail',
  });

  const handleSuccess = ({ handleEnableEdit, index }) => {
    handleEnableEdit(index, true);
    queryClient.invalidateQueries(LINEAGE_MAPPING);
    dispatch(enqueueNotification('notification.lineage.submitMappingSuccess', 'success'));
  };

  return useMutation<void, Error, ISubmitLineageMappingDTO>(
    async ({ oldReference, newReference, handleEnableEdit, index }) => {
      await binderManagementLineageAxios.put(`/lineage/config/${oldReference}/${newReference}`);
      handleSuccess({ handleEnableEdit, index });
    },
    { onError: handleError }
  );
};
