import { useEffect, useState } from 'react';

export const useWebGLAvailable = () => {
  const [isWebGlSupported, setIsWebGlSupported] = useState(false);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    setIsWebGlSupported(!!gl);
  }, []);

  return isWebGlSupported;
};

export default useWebGLAvailable;
