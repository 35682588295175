import React, { useCallback, useState } from 'react';

// app
import { FACILITY_USERS_PRODUCERS, FACILITY_USERS_UNDERWRITERS } from 'consts';
import { EditFacilityUsersView } from './EditFacilityUsers.view';
import * as utils from 'utils';
import { useUpdateFacilityUsers } from 'lib/binderManagement';

const EditFacilityUsers = ({ facilityUserData, facilityUMR, initialTab = FACILITY_USERS_PRODUCERS }) => {
  const tabs = [
    { id: 0, value: FACILITY_USERS_PRODUCERS, label: utils.string.t('binders.tabs.facilityUserProducers') },
    { id: 1, value: FACILITY_USERS_UNDERWRITERS, label: utils.string.t('binders.tabs.facilityUserUnderwriters') },
  ];

  const [selectedTab, setSelectedTab] = useState(initialTab);
  const { mutate: updateFacilityProducers } = useUpdateFacilityUsers({ umr: facilityUMR, role: 'producer' });
  const { mutate: updateFacilityUnderwriters } = useUpdateFacilityUsers({ umr: facilityUMR, role: 'underwriter' });

  const handleSelectTab = useCallback((_, tabName) => {
    setSelectedTab(tabName);
  }, []);

  const producers = facilityUserData.producerUsers.flatMap((userGroup) =>
    userGroup.users.map((user) => ({
      ...user,
      isAssigned: user.facilityUMRs?.includes(facilityUMR),
      group: userGroup.client,
    }))
  );

  const underwriters = facilityUserData.underwriterUsers.flatMap((userGroup) =>
    userGroup.users.map((user) => {
      return {
        ...user,
        isAssigned: user.facilityUMRs?.includes(facilityUMR),
        group: userGroup.market,
      };
    })
  );

  return (
    <EditFacilityUsersView
      tabs={tabs}
      selectedTab={selectedTab}
      handlers={{ handleSelectTab, updateFacilityProducers, updateFacilityUnderwriters }}
      underwriters={underwriters}
      producers={producers}
    />
  );
};

export default EditFacilityUsers;
