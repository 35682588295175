import { memo } from 'react';

// MUI
import { Box, Card, CardHeader, CardContent, Divider, Typography, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// app
import { useBDXStyles } from './BDX.styles';
import classNames from 'classnames';

const NUMBER_OF_CARDS = 12;

const Col = () => (
  <Grid container direction="column" justifyContent="space-between">
    <Skeleton height={94} />
    <Skeleton />
  </Grid>
);

export const CardLoading = () => {
  const classes = useBDXStyles();

  return (
    <Card data-testid="bdx-loading-card" classes={{ root: classes.card }}>
      <CardHeader
        classes={{ root: classes.cardHeader }}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ paddingRight: '0.5rem' }}>
            <Box>
              <Typography variant="h4" className={classes.cardTitle}>
                <Skeleton width={100} />
              </Typography>
              <Typography variant="h5" className={classNames(classes.cardTitle, classes.cardTitleLoading)}>
                <Skeleton width="40%" />
              </Typography>
            </Box>
            <Box position="relative" display="inline-flex">
              <Skeleton variant="circle" width={40} height={40} />
            </Box>
          </Box>
        }
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <Grid
          container
          display="flex"
          spacing={1}
          direction="row"
          justifyContent="space-evenly"
          style={{ flexWrap: 'nowrap', padding: 16 }}
        >
          <Col />
          <Divider orientation="vertical" variant="middle" flexItem style={{ width: '2px' }} />
          <Col />
          <Divider orientation="vertical" variant="middle" flexItem style={{ width: '2px' }} />
          <Col />
        </Grid>
      </CardContent>
    </Card>
  );
};

const BDXSkeletonView = () => {
  const classes = useBDXStyles();

  return (
    <div className={classes.yearWrapper} data-testid="BDXSkeleton">
      <div className={classes.yearHeader}>
        <div className={classes.yearHeaderSkeleton}>
          <Skeleton height={25} width={100} />
        </div>
      </div>
      <Grid container spacing={2} className={classes.bdxList} style={{ margin: 0 }}>
        {Array.from(Array(NUMBER_OF_CARDS).keys()).map((i) => (
          <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
            <CardLoading classes={classes} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const BDXSkeleton = memo(BDXSkeletonView);
