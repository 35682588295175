import { FC } from 'react';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export type TGetHandleAssignToBlock = (selectedRowKeys: string[], handleSuccess: VoidFunction) => (event: Partial<Event>) => void;

interface IAssignDismissLinesProps {
  selectedRowKeys: string[];
  getHandleAssign: TGetHandleAssignToBlock;
  handleSuccess: VoidFunction;
  getHandleDismiss: TGetHandleAssignToBlock;
}

export type TAssignDismissLinesComponent = FC<IAssignDismissLinesProps>;

export const AssignDismissLines = ({ selectedRowKeys, getHandleAssign, getHandleDismiss, handleSuccess }: IAssignDismissLinesProps) => {
  const isDisabled = selectedRowKeys?.length === 0;

  const handleAssign = getHandleAssign(selectedRowKeys, handleSuccess);
  const handleDismiss = getHandleDismiss(selectedRowKeys, handleSuccess);

  return (
    <Box display="flex" flex={1} flexDirection="row">
      <Tooltip title="Assign to facility" placement="top">
        <IconButton
          aria-label="Assign to facility"
          color="primary"
          onClick={handleAssign}
          disabled={isDisabled}
          style={{
            marginLeft: '-3px',
          }}
        >
          <AddCircleOutlineIcon color={isDisabled ? 'disabled' : 'primary'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Dismiss Lines" placement="top">
        <IconButton
          aria-label="Dismiss Lines"
          color="primary"
          onClick={handleDismiss}
          disabled={isDisabled}
          style={{
            marginLeft: '-3px',
          }}
        >
          <RemoveCircleOutlineIcon color={isDisabled ? 'disabled' : 'error'} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
