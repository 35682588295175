import { useMutation } from 'react-query';
import moment from 'moment';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { file } from 'utils';

export const useDownloadRiskBordereaux = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useDownloadRiskBordereaux',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = ({ data, fileName }) => {
    file.fileSaver(data, fileName);
  };

  return useMutation(
    async ({ product, from, to, facility, clients, type, onlyBound }) => {
      if (!product || !from || !to) {
        throw new Error('Invalid params');
      }

      const dateFrom = moment(from).format('YYYY-MM');
      const dateTo = moment(to).format('YYYY-MM');

      const facilityParam = facility ? `_${facility}` : '';
      const clientsIds = clients?.length ? clients.map((client) => client.id) : null;

      const defaultFileName =
        type === 'DATA_DUMP'
          ? `data_${product}_${dateFrom}_${dateTo}${facilityParam}.xlsx`
          : `bordereaux_${product}_${dateFrom}_${dateTo}${facilityParam}.xlsx`;

      const endpointParams = {
        ...(dateFrom && { from: dateFrom }),
        ...(dateTo && { to: dateTo }),
        ...(facility && { facilityId: facility }),
        ...(type && { type }),
        ...(onlyBound && { onlyBound }),
        ...(clients && { clients: clientsIds.join() }),
      };

      const { headers, data } = await quoteBindAxios.get(`products/${product}/bordereaux`, {
        params: endpointParams,
        responseType: 'blob',
        timeout: 30000,
      });

      const fileName = utils.file.getFilenameFromHeadersBlob(headers, data, defaultFileName);
      return { data, fileName };
    },
    {
      onError: (err) => handleError(err),
      onSuccess: (res) => handleSuccess(res),
    }
  );
};
