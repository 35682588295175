import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { TAB } from 'consts';

export default function useLocationTabs(tabs, defaultIndex = 0) {
  const location = useLocation();
  const history = useHistory();

  const getInitialTab = () => {
    if (tabs.length === 0) {
      return null;
    }

    const initialTabId = new URLSearchParams(location.search).get(TAB);

    const initialTab = tabs.find(({ id }) => id === initialTabId);

    return initialTab?.value || tabs[defaultIndex].value;
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab(location));

  const handleSelectTab = (_, tabName) => {
    const tab = tabs.find(({ value }) => value === tabName);

    if (!tab) {
      return;
    }

    const search = new URLSearchParams(location.search);
    search.set(TAB, tab.id);

    history.replace({ search: search.toString(), state: location.state });

    setSelectedTab(tabName);
  };

  return { selectedTab, handleSelectTab };
}
