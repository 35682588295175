import { useMutation } from 'react-query';
import { authorizedDTC } from 'lib/quoteBind/quoteBind.api';
import { useAuth0 } from '@auth0/auth0-react';

async function getDistanceToCoast(location, token) {
  if (!location?.lng || !location?.lat) {
    throw new Error('useGetDistanceToCoast: lng and lat are required');
  }
  const { lng, lat } = location;

  const params = {
    lng,
    lat,
  };

  const response = await authorizedDTC(token).get(`distance-to-coast`, { params });
  return response.data;
}

export function useGetDistanceToCoastRequest() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async (location) => {
      if (!location) return;
      const token = await getAccessTokenSilently();

      const result = await getDistanceToCoast(location, token);

      return result;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );
}
