import axios from 'axios';

import { app } from 'utils';
import { accessToken } from 'utils/auth0/auth0';

export const axiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.taskManagement}/api`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const authorized = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axiosInstance;
};

export default authorized;
