import { Query, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { BDX_AGGREGATE_TYPE_CLAIM } from 'consts';
import { enqueueNotification, hideModal } from 'stores';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES, BDX_CLAIM_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IClaimDataKey {
  claimGroup: string;
}

export const useAssignToClaimBlock = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useAssignToClaimBlock',
    warning: 'notification.binderManagement.assignClaimBlock.error',
  });

  const handleSuccess = (config: IClaimBlockAssignmentDTO) => {
    queryClient.invalidateQueries({
      predicate: (query: Query<unknown, unknown, unknown, [string, unknown, string, string]>) => {
        if (
          query.queryKey[0] === BDX_CLAIM_DATA &&
          ((query.queryKey[1] as IClaimDataKey).claimGroup === config.claimBlock ||
            (query.queryKey[1] as IClaimDataKey)?.claimGroup === null)
        ) {
          return true;
        }

        if (query.queryKey[0] === BDX_AGGREGATES && query.queryKey[1] === BDX_AGGREGATE_TYPE_CLAIM) {
          return true;
        }

        return false;
      },
    });

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.binderManagement.assignClaimBlock.success', 'success'));
  };

  return useMutation<IClaimBlockAssignmentDTO, AxiosError, IClaimBlockAssignmentDTO>(
    async (config) => {
      await binderManagementBDXAxios.put(`claimBlock`, config);

      return config;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
