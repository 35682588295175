import { FC } from 'react';

import { Button, CircularProgress, Divider } from '@material-ui/core';

import { BDXDragDrop, FormSelect } from 'components';

import * as utils from 'utils';

import { IUploadLineageReportViewProps } from './UploadLineageReport.types';

import { useUploadLineageReportStyles } from './UploadLineageReport.styles';

export const UploadLineageReportView: FC<IUploadLineageReportViewProps> = ({
  dragDropRef,
  methods,
  file,
  setFile,
  handleUploadFile,
  resetOnFileChange,
  isLoading,
  isReady,
  sheets,
  fields,
  handleSubmit,
}) => {
  const classes = useUploadLineageReportStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <BDXDragDrop
              ref={dragDropRef}
              label="Lineage File"
              control={methods.control}
              defaultFile={file}
              onChange={(_, f) => {
                setFile(f);
                if (f) {
                  handleUploadFile(f);
                } else {
                  resetOnFileChange();
                }
              }}
              showUploadPreview
              width="30rem"
              height="15rem"
              name="file"
            />
            {isReady && (
              <FormSelect
                control={methods.control}
                {...utils.form.getFieldProps(fields, 'sheetName')}
                rules={{ required: 'Required' }}
                error={methods.formState.errors.sheetName}
                options={sheets.map((sheet) => ({ label: sheet }))}
              />
            )}
          </>
        )}
      </div>
      <Divider />
      <div className={classes.actions}>
        <Button variant="contained" disabled={!isReady || isLoading} color="primary" onClick={handleSubmit}>
          {utils.string.t('app.submit')}
        </Button>
      </div>
    </div>
  );
};
