import { Grid } from '@material-ui/core';
import { Add, ImportExport, PlaylistPlay } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Button } from 'components';
import { BDXTypesButtons } from 'modules/Binders';

import * as utils from 'utils';

import { RulesList } from './components';

import { useRulesStyles } from './Rules.styles';

export const RulesView = ({
  handleRunRules,
  handleAddRule,
  handleImportRules,
  handleDownloadRules,
  handleUploadRules,
  ruleSets,
  rules,
  nonTPARuleSet,
  isLoadingRuleSets,
  isDownloadingRules,
  activeTab,
  tabs,
  handleChangeTab,
}) => {
  const classes = useRulesStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item className={classes.bdxTypes}>
          <BDXTypesButtons active={activeTab?.id} tabs={tabs} handleChangeTab={handleChangeTab} />
        </Grid>
        <Grid item>
          <Button text={utils.string.t('app.run')} icon={PlaylistPlay} variant="contained" color="secondary" onClick={handleRunRules} />
        </Grid>
        <Grid item>
          <Button
            text={utils.string.t('app.importRules')}
            icon={ImportExport}
            variant="contained"
            color="primary"
            onClick={handleImportRules}
          />
        </Grid>
        <Grid item>
          <Button text={utils.string.t('rules.addRule.title')} icon={Add} variant="contained" color="primary" onClick={handleAddRule} />
        </Grid>
        <Grid item>
          <Button
            text={utils.string.t('rules.uploadRules')}
            icon={CloudUploadIcon}
            variant="contained"
            onClick={handleUploadRules}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Button
            text={utils.string.t('rules.downloadRules')}
            icon={CloudDownloadIcon}
            variant="contained"
            color="primary"
            onClick={() => handleDownloadRules({ rulesFile: nonTPARuleSet?.fileName })}
            disabled={isDownloadingRules || rules?.length === 0}
          />
        </Grid>
      </Grid>
      <RulesList
        bdxType={activeTab?.id}
        nonTPARuleSet={nonTPARuleSet}
        isLoadingRuleSets={isLoadingRuleSets}
        ruleSets={ruleSets}
        rules={rules}
      />
    </div>
  );
};
