import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesDocumentsAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_DOCUMENT_FOLDERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetDocumentsFolders = ({ umr }) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetDocumentsFolders',
  });

  return useQuery<IFacilityDocumentResponse, AxiosError, IGetDocumentsFoldersDTO>(
    [FACILITY_BINDER_DOCUMENT_FOLDERS, umr],
    async () => {
      if (!umr) return null;
      const result = await binderManagementFacilitiesDocumentsAxios.get(umr);
      return { ...result?.data, baseUrl: result.config.baseURL };
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
