import { useState } from 'react';

interface IUseSimplePaginationProps {
  initSize: number;
}

export const useSimplePagination = ({ initSize }: IUseSimplePaginationProps = { initSize: 5 }) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(initSize);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(0);
    setSize(rowsPerPage);
  };

  return { page, size, handleChangePage, handleChangeRowsPerPage };
};
