import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getRiskCountries(params, token) {
  const response = await api(token).get('facilities/countries', {
    params: {
      ...params,
    },
  });
  return response.data;
}

export function useGetRiskCountries(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (params, token) => await getRiskCountries(params, token), {
    cacheTime: 1000 * 60 * 60 * 24,
    ...options,
  });
}
