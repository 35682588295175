import WarningIcon from '@material-ui/icons/Warning';

import { Button } from 'components';

import { UPLOAD_RULES_STEPS } from 'consts';

export const ConfirmRulesUpload = ({ setActiveStep, classes }: IConfirmRulesUploadProps) => (
  <div className={classes.content}>
    <h3 className={classes.warning} data-testid="warning-icon">
      <WarningIcon className={classes.warningIcon} />
      Uploading new rules will overwrite existing rules
    </h3>

    <div className={classes.actions}>
      <Button
        style={{ marginTop: 8 }}
        color="primary"
        size="small"
        text="Continue"
        data-testid="continue-button"
        onClick={() => setActiveStep(UPLOAD_RULES_STEPS.UPLOAD_RULES)}
      />
    </div>
  </div>
);
