import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { binderManagementBDXAxios } from 'lib/binderManagement';
import { enqueueNotification } from 'stores';

import * as utils from 'utils';

export const useHandleDownloadPremium = ({ aggregate }) => {
  const [isDownload, setDownload] = useState(false);
  const dispatch = useDispatch();

  const handleDownload = async () => {
    setDownload(true);
    try {
      const { headers, data } = await binderManagementBDXAxios.get(`aggregate/premium/${aggregate?.requestId}/download`, {
        responseType: 'blob',
      });
      const fileName = utils.file.getFilenameFromHeadersBlob(headers, data);

      utils.file.download(data, `${fileName}`);
    } catch (e) {
      dispatch(enqueueNotification('notification.binderManagement.documentDownloadFail', 'error'));
    }
    setDownload(false);
  };

  return {
    handleDownload,
    isDownload,
  };
};
