import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_LIMIT_FAVOURITES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

const getLimitFavourites = async (riskCodes) => {
  const result = await binderManagementDroolsAxios.get(`/limits/favourites?riskCodes=${riskCodes}`);

  return result?.data;
};

export const useGetLimitFavourites = (riskCodes = [], options = {}) => {
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetLimitFavourites' });

  return useQuery([DROOLS_LIMIT_FAVOURITES, riskCodes], async () => getLimitFavourites(riskCodes), {
    onError: (err) => handleError(err),
    cacheTime: 60 * 60 * 1000,
    staleTime: 10 * 1000,
    ...options,
  });
};
