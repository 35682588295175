import { makeStyles } from '@material-ui/core';

export const useConditionsStyles = makeStyles(
  (theme) => ({
    conditions: {
      margin: theme.spacing(2, 0),
    },
    conditionStatement: {
      alignItems: 'center',
    },
    button: {
      marginLeft: 'auto',
    },

    condition: {
      marginTop: theme.spacing(1),
      flexWrap: 'nowrap',
      alignItems: 'center',
    },

    ruleLineContainer: {
      paddingRight: `0px!important`,
      paddingLeft: `${theme.spacing(3)}px!important`,

      '& + *': {
        paddingLeft: `0px!important`,
      },
    },

    deleteCondition: {
      marginTop: theme.spacing(1),
    },

    checkboxCondition: {
      marginBottom: theme.spacing(2),
    },
    noConditions: {
      padding: theme.spacing(2, 1, 0),
    },
  }),
  { name: 'Conditions' }
);
