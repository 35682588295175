import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// Edge
import { Empty } from 'components';
import { ReactComponent as Error } from 'assets/svg/error.svg';
import * as utils from 'utils';

function fallbackRender({ error }) {
  const showError = !utils.app.isEdgeProduction();

  return <Empty height={400} icon={<Error />} title="Something went wrong" text={showError ? error.message : ''} />;
}

const ErrorBoundary = ({ children }) => {
  return <ReactErrorBoundary fallbackRender={fallbackRender}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
