import { RulesListSkeleton } from '../RulesListSkeleton';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { useRulesListStyle } from '../RulesList.style';

export const RulesClaimsListSkeleton = () => {
  const classes = useRulesListStyle();

  return (
    <div>
      <Typography variant="h2" className={classes.groupTitle}>
        <Skeleton width={140} height={28} className={classes.titleSkeleton} />
      </Typography>
      <RulesListSkeleton />
    </div>
  );
};
