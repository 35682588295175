import React, { useState } from 'react';

// Edge
import { UploadBDXContextProvider } from '../UploadBDX.context';
import UploadBDXCoverHolderView from './UploadBDXCoverHolder.view';
import { UPLOAD_BDX_STEPS } from 'consts';
import * as utils from 'utils';

export default function UploadBDXCoverHolder({
  isCoverHolderUpload,
  operationalClientId,
  fullScreen,
  isTest,
  sanctionsCheckOnly = false,
  callbackOnSuccess = null,
  defaultBdxType,
}) {
  const [activeStep, setActiveStep] = useState(UPLOAD_BDX_STEPS.UPLOAD_FILE);
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState({
    [UPLOAD_BDX_STEPS.UPLOAD_FILE]: false,
    [UPLOAD_BDX_STEPS.FIELD_MAPPING]: false,
    [UPLOAD_BDX_STEPS.SUMMARY]: false,
  });
  const getHandleSetStep = (step) => () => setActiveStep(step);
  const skiptoLastStep = () => setActiveStep(UPLOAD_BDX_STEPS.SUMMARY);
  const getHandleCompleteStep = (step) => () => setCompleted((c) => ({ ...c, [step]: true }));
  const [validatedCoverHolderData, setvalidatedCoverHolderData] = useState({});

  const steps = [
    {
      id: UPLOAD_BDX_STEPS.UPLOAD_FILE,
      label: utils.string.t('form.bdx.uploadBdx'),
    },
    {
      id: UPLOAD_BDX_STEPS.FIELD_MAPPING,
      label: utils.string.t('form.bdx.fieldMapping'),
    },
    {
      id: UPLOAD_BDX_STEPS.SUMMARY,
      label: utils.string.t('form.bdx.summary'),
    },
  ];
  const progress = (activeStep / (steps.length - 1)) * 100;

  return (
    <UploadBDXContextProvider
      operationalClientId={operationalClientId}
      isCoverHolderUpload={isCoverHolderUpload}
      sanctionsCheckOnly={sanctionsCheckOnly}
      validatedCoverHolderData={validatedCoverHolderData}
      setvalidatedCoverHolderData={setvalidatedCoverHolderData}
      defaultBdxType={defaultBdxType}
    >
      <UploadBDXCoverHolderView
        steps={steps}
        activeStep={activeStep}
        completed={completed}
        isLoading={isLoading}
        progress={progress}
        fullScreen={fullScreen}
        operationalClientId={operationalClientId}
        handlers={{ getHandleSetStep, skiptoLastStep, setLoading, getHandleCompleteStep }}
        callbackOnSuccess={callbackOnSuccess}
        isTest={isTest}
      />
    </UploadBDXContextProvider>
  );
}
