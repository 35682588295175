import { useMutation } from 'react-query';

import { binderManagementFileInputAxiosV2 } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useValidateBDXCoverHolder = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useValidateBDX',
    warning: 'notification.bdx.validateFail',
  });

  return useMutation(
    async (payload) => {
      const data = await binderManagementFileInputAxiosV2.post('validate', payload);
      return data;
    },
    {
      onError: handleError,
    }
  );
};
