import { FC, useEffect, useState } from 'react';
import get from 'lodash/get';

import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, FormAutocompleteMui, FormSelect, FormText } from 'components';
import { RuleLine } from 'modules/Rules';

import * as utils from 'utils';

import { IConditionProps } from '../../AddBinderLimit.types';

import { conditionsStyles } from './Conditions.styles';

export const Condition: FC<IConditionProps> = ({ fields, methods, condition, isLast, prefix, handleRemoveCondition, limitColumns }) => {
  const classes = conditionsStyles();
  const [selectedField, setSelectedField] = useState<ILimitsColumn>(null);
  const [operands, setOperands] = useState<typeof condition['inputs']>([]);

  useEffect(() => {
    if (condition.condition && condition.field) {
      const field = limitColumns.find((l) => l.name === condition.column);
      setSelectedField(field);

      if (condition.inputs) {
        setOperands(condition.inputs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  const {
    control,
    formState: { errors },
    setValue,
  } = methods;

  const onSelectColumnHeading = (selected) => {
    setSelectedField(selected);
    setValue(prefix, null);
    setOperands([]);
  };

  const onSelectComparator = (_, option) => {
    setOperands(
      selectedField.availableConditions
        .find(({ name }) => name === option)
        .inputs.map((input) => ({
          delimiter: null,
          value: null,
          index: input.index,
        }))
    );
  };

  const defaultConditionValue = limitColumns?.find(({ name }) => condition.column === name);

  return (
    <Grid container spacing={1} className={classes.condition}>
      <Grid item container spacing={1}>
        <Grid item className={classes.conditionLineContainer}>
          <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} />
        </Grid>
        <Grid item xs={2} data-testid="condition.columnHeading.wrapper">
          <FormAutocompleteMui
            name={`${prefix}.field`}
            label={utils.string.t('binderLimits.sumColumns.columnHeading')}
            optionKey="name"
            optionLabel="displayName"
            options={limitColumns || []}
            defaultValue={limitColumns.find((limitColumn) => limitColumn.name === condition.column) || []}
            control={control}
            rules={{ required: 'Required' }}
            error={get(errors, `${prefix}.field`)}
            onSelect={onSelectColumnHeading}
            testid="condition.columnHeading"
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name={`${prefix}.operation`}
            control={control}
            label={utils.string.t('binderLimits.condition')}
            defaultValue={defaultConditionValue?.availableConditions.find((d) => d.name === condition.condition).name || []}
            options={selectedField?.availableConditions || []}
            handleUpdate={onSelectComparator}
            optionKey="name"
            optionLabel="name"
            rules={{ required: 'Required' }}
            error={get(errors, `${prefix}.operation`)}
            disabled={!selectedField}
          />
        </Grid>
        {operands?.map(({ index, value }) => (
          <Grid item xs={2} key={index}>
            <FormText
              {...utils.form.getFieldProps(fields, 'values')}
              name={`${prefix}.inputs.${index}.value`}
              defaultValue={value || []}
              type={selectedField?.type}
              control={control}
              rules={{ required: 'Required' }}
              error={get(errors, `${prefix}.inputs.${index}.value`)}
            />
          </Grid>
        ))}
      </Grid>
      {!isLast && (
        <Grid item className={classes.deleteCondition}>
          <Button icon={DeleteIcon} color="primary" size="xsmall" onClick={handleRemoveCondition} data-testid="btn-delete-condition" />
        </Grid>
      )}
    </Grid>
  );
};
