import React from 'react';

import * as utils from 'utils';
import { Empty } from 'components';
import { ReactComponent as Uploading } from 'assets/svg/uploadingBDX.svg';

export const UploadBDXLoader = ({ title, fullScreen }) => (
  <Empty
    width={400}
    fullScreen={fullScreen}
    title={title}
    text={utils.string.t('notification.bdx.waitingMsg')}
    image={<Uploading />}
    padding
  />
);
