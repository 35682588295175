import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { firstBy } from 'thenby';

import { ReactComponent as FlashWarningSVG } from 'assets/svg/flash-warning.svg';
import { Empty } from 'components';
import { getTabFromBDXType } from 'modules/Binders';
import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';

import { UPLOAD_RULES } from 'consts';
import { useGetBdxTypes, useGetRulesets } from 'lib/binderManagement';
import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

import { useDownloadRules } from './components/hooks/useDownloadRules';
import { RulesView } from './Rules.view';
import { RulesSkeleton } from './RulesSkeleton';

export const Rules = () => {
  const { facility, isLoading: isLoadingFacility } = useContext(FacilityContext);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(null);

  const { handleDownloadRules, isDownloadingRules } = useDownloadRules();
  const { data: bdxTypes, isFetching: isLoadingBDXTypes } = useGetBdxTypes(facility?.riskCodes?.map(({ code }) => code) || []);
  const { data: ruleSets, isFetching: isLoadingRuleSets } = useGetRulesets(
    {
      bdxType: activeTab?.id,
      facility: facility?.umr,
    },
    {
      enabled: Boolean(activeTab?.id) && Boolean(facility?.umr),
    }
  );
  const nonTPARuleSet = ruleSets?.find(({ thirdPartyId }) => !thirdPartyId);
  const rules =
    nonTPARuleSet?.rules
      ?.map((rule) => ({
        ...rule,
        fileName: nonTPARuleSet.fileName,
        isFavourite: !!rule?.ruleId,
        thirdPartyId: nonTPARuleSet.thirdPartyId,
      }))
      .sort(firstBy(utils.sort.array('numeric', 'salience', 'asc')).thenBy(utils.sort.array('string', 'name', 'asc'))) || [];

  const tabs = useMemo(() => bdxTypes?.map(getTabFromBDXType), [bdxTypes]);

  useEffect(() => {
    if (bdxTypes?.length) {
      setActiveTab(getTabFromBDXType(bdxTypes[0]));
    }
  }, [bdxTypes]);

  const handleChangeTab = (_, newTabId) => {
    if (newTabId) {
      setActiveTab(tabs.find(({ id }) => newTabId === id));
    }
  };

  const handleRunRules = () => {
    dispatch(
      showModal({
        component: 'UPLOAD_BDX',
        props: {
          title: [utils.string.t('app.run'), facility.coverHolder, facility.umr].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility: { ...facility, bdxType: activeTab?.label },
            isTest: true,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX')),
          },
        },
      })
    );
  };

  const handleAddRule = () => {
    dispatch(
      showModal({
        component: 'ADD_RULE',
        props: {
          title: 'rules.addRule.title',
          fullWidth: true,
          maxWidth: 'xl',
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility,
            bdxType: activeTab?.id,
          },
        },
      })
    );
  };

  const handleImportRules = () => {
    dispatch(
      showModal({
        component: 'IMPORT_RULES',
        props: {
          title: `Import ${activeTab?.label} Rules`,
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            bdxType: activeTab?.id,
            facility,
          },
        },
      })
    );
  };

  const handleUploadRules = () => {
    dispatch(
      showModal({
        component: UPLOAD_RULES,
        props: {
          title: `Upload ${activeTab?.label} Rules`,
          fullWidth: true,
          maxWidth: 'md',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            bdxType: activeTab?.id,
            facility: facility?.umr,
            thirdPartyId: nonTPARuleSet?.thirdPartyId,
          },
        },
      })
    );
  };

  const isLoading = isLoadingFacility || isLoadingBDXTypes;
  const NoBDX = () => <Empty width={400} title={utils.string.t('app.noBDX')} icon={<FlashWarningSVG />} padding />;

  if (isLoading) return <RulesSkeleton />;

  if (!bdxTypes || bdxTypes.length === 0) return <NoBDX />;

  return (
    <RulesView
      activeTab={activeTab}
      tabs={tabs}
      handleAddRule={handleAddRule}
      handleChangeTab={handleChangeTab}
      handleRunRules={handleRunRules}
      handleImportRules={handleImportRules}
      handleDownloadRules={handleDownloadRules}
      handleUploadRules={handleUploadRules}
      ruleSets={ruleSets}
      rules={rules}
      nonTPARuleSet={nonTPARuleSet}
      isDownloadingRules={isDownloadingRules}
      isLoadingRuleSets={isLoadingRuleSets}
    />
  );
};
