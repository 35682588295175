import { useQuery } from 'react-query';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { LINEAGE_YEAR_MONTH } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLineageLinesProps {
  requestId: string;
  facilityReference?: string;
  options?: {
    size: number;
    page: number;
  };
  enabled?: boolean;
}

export const useGetLineageLines = ({
  requestId,
  facilityReference,
  options = { size: 12, page: 0 },
  enabled = true,
}: IUseGetLineageLinesProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineageLines',
  });

  return useQuery<IPage<ILineageLine>>(
    [LINEAGE_YEAR_MONTH, requestId, facilityReference, options],
    async () => {
      const result = await binderManagementLineageAxios.get('lineage/lines', {
        params: {
          requestId,
          facilityReference,
          size: options.size,
          page: options.page,
        },
      });
      return result?.data;
    },
    {
      onError: handleError,
      enabled,
    }
  );
};
