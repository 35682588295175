import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// mui
import { makeStyles } from '@material-ui/core';

// app
import { Button, FormActions, FormContainer, FormDate, FormFields, FormSelect } from 'components';

import { useCancelPolicy, useExtendPolicy } from 'lib/quoteBind';
import * as utils from 'utils';

ExtendCancelPolicy.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  effectiveTo: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
  },
}));

export default function ExtendCancelPolicy({ handleClose, policyId, minDate, maxDate, isExtend, cancellationReasons, hint = null }) {
  const classes = useStyles();
  const { mutate: cancelPolicy, isLoading: cancelPolicyLoading } = useCancelPolicy();
  const { mutate: extendPolicy, isLoading: extendPolicyLoading } = useExtendPolicy();

  const isLoading = cancelPolicyLoading || extendPolicyLoading;
  const hasCancellationReasons = cancellationReasons?.length > 0 || false;

  const fields = useMemo(
    () => [
      {
        name: 'expiryDate',
        type: 'datepicker',
        value: '',
        validation: Yup.date()
          .nullable(utils.string.t(`risks.effectiveToInvalid`))
          .typeError(utils.string.t(`risks.effectiveToInvalid`))
          .required(utils.string.t(`risks.effectiveToInvalid`))
          .min(minDate, utils.string.t(isExtend ? `risks.effectiveToInvalidExtend` : `risks.effectiveToInvalidMin`))
          .max(maxDate, utils.string.t(isExtend ? `risks.effectiveToInvalidExtend` : `risks.effectiveToInvalidMax`)),
        label: 'Policy expiry date:',
        hint,
        muiComponentProps: {
          autoOk: true,
          minDate,
          maxDate,
          placeholder: utils.date.today('DD-MM-yyyy'),
          format: 'DD-MM-yyyy',
          disableToolbar: true,
          fullWidth: true,
          autoComplete: 'off',
        },
      },
      ...(hasCancellationReasons
        ? [
            {
              name: 'cancellationReason',
              type: 'select',
              value: '',
              validation: Yup.string().required(utils.string.t(`risks.cancellationReasonInvalid`)),
              label: utils.string.t(`risks.cancellationReason`),
              options: cancellationReasons?.map((reason) => ({ value: reason, label: reason })) || [],
            },
          ]
        : []),
    ],
    [minDate, isExtend, maxDate, hint, hasCancellationReasons, cancellationReasons]
  );

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;

  const submitHandler = (data) => {
    const policyData = { policyId, expiryDate: data.expiryDate };
    isExtend
      ? extendPolicy(policyData)
      : cancelPolicy({ ...policyData, cancellationReason: hasCancellationReasons ? data.cancellationReason : null });
  };

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(submitHandler)} data-testid="form-ExtendCancelPolicy">
        <FormFields type="dialog">
          <FormDate {...utils.form.getFieldProps(fields, 'expiryDate')} control={control} error={errors.expiryDate} />
          {hasCancellationReasons ? (
            <FormSelect {...utils.form.getFieldProps(fields, 'cancellationReason')} control={control} error={errors.cancellationReason} />
          ) : null}
        </FormFields>

        <FormActions type="dialog">
          <Button text={utils.string.t('app.close')} variant="text" disabled={formState.isSubmitting || isLoading} onClick={handleClose} />
          <Button
            text={utils.string.t('app.confirm')}
            type="submit"
            disabled={formState.isSubmitting || isLoading}
            color="primary"
            danger={!isExtend}
          />
        </FormActions>
      </FormContainer>
    </div>
  );
}
