import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { ADMIN_BINDER_MANAGEMENT_ALL_USER_FACILITIES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetAllUserFacilities = (userId) => {
  const handleError = useHandleError({
    file: 'admin.queries.useGetAllUserFacilities',
  });

  return useQuery(
    [ADMIN_BINDER_MANAGEMENT_ALL_USER_FACILITIES, userId],
    async () => {
      if (!userId) throw new Error('UserId is required');

      const result = await binderManagementFacilitiesAxios.get(`facilities/user/${userId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
