import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Box, Button, CircularProgress, Grid, Icon, IconButton, TextField } from '@material-ui/core';
import { Add, ArrowRightAlt, Delete, EditOutlined, Save } from '@material-ui/icons';

import { useDeleteLineageMapping } from 'lib/binderManagement/lineage/mutations/useDeleteLineageMapping';
import { useSubmitLineageMapping } from 'lib/binderManagement/lineage/mutations/useSubmitLineageMapping';
import { useGetLineageMapping } from 'lib/binderManagement/lineage/queries/useGetLineageMapping';
import * as utils from 'utils';

import { IAddLineageMappingForm } from './AddEditLineageMapping.types';

import { useAddLineageMappingStyles } from './AddLineageMapping.styles';

export const AddEditLineageMapping: FC = () => {
  const classes = useAddLineageMappingStyles();
  const { data: lineageMappingConfig = [], isLoading: isLoadingLineageMappingConfig } = useGetLineageMapping();
  const { mutateAsync: deleteLineageMapping } = useDeleteLineageMapping();
  const { mutateAsync: submitLineageMapping, isLoading: isSubmitting } = useSubmitLineageMapping();
  const [disabledStates, setDisabledStates] = useState([]);
  const errorMessage = utils.string.t('form.addEditLineageMapping.fieldError');
  const methods = useForm<IAddLineageMappingForm>({
    mode: 'onChange',
  });

  const defaultValues = {
    mappings: lineageMappingConfig?.map((row) => ({
      ...row,
      isOriginalValue: true,
    })),
  };
  const {
    register,
    control,
    formState: { errors },
    getValues,
    getFieldState,
    reset,
  } = useForm<IAddLineageMappingForm>({
    mode: 'onBlur',
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    name: 'mappings',
    control,
  });

  const handleEnableEdit = (index, value) => {
    setDisabledStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  };

  const onSubmit = async (index) => {
    const newMappings = getValues();
    const oldReferenceValue = getFieldState(`mappings.${index}.oldReference`);
    const newReferenceValue = getFieldState(`mappings.${index}.newReference`);

    if (!oldReferenceValue.error && !newReferenceValue.error) {
      const { oldReference, newReference } = newMappings.mappings[index];
      await submitLineageMapping({ oldReference, newReference, handleEnableEdit, index });
    }
  };

  const handleDelete = async ({ index, oldReference, isOriginalValue }) =>
    isOriginalValue ? deleteLineageMapping({ oldReference }) : remove(index);

  useEffect(() => {
    if (lineageMappingConfig) {
      setDisabledStates(lineageMappingConfig.map(() => true));
      reset({
        ...defaultValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineageMappingConfig, reset]);

  return (
    <FormProvider {...methods}>
      <div className={classes.root}>
        {isLoadingLineageMappingConfig ? (
          <Box className={classes.loader}>
            <CircularProgress ata-testid="loader" />
          </Box>
        ) : (
          <>
            <Grid item xs={12} style={{ display: 'flex', margin: '2rem 0' }} justifyContent="flex-end">
              <Box>
                <Button
                  onClick={() =>
                    append({
                      oldReference: '',
                      newReference: '',
                    })
                  }
                  variant="outlined"
                  color="secondary"
                  size="small"
                  endIcon={<Add />}
                >
                  Add
                </Button>
              </Box>
            </Grid>

            <Box>
              {fields?.map(({ id, oldReference, isOriginalValue }, index) => (
                <Grid container spacing={5} key={id} justifyContent="center" alignItems="center">
                  <Grid item>
                    <TextField
                      variant="outlined"
                      id={`oldReference-${index}`}
                      name={`oldReference_${index}`}
                      type="text"
                      label={`${utils.string.t('form.addEditLineageMapping.leftLabel')} ${index + 1}`}
                      disabled={isOriginalValue || isSubmitting}
                      error={!!errors?.mappings?.[index]?.oldReference}
                      helperText={errors?.mappings?.[index]?.oldReference && errorMessage}
                      {...register(`mappings.${index}.oldReference`, { required: true })}
                    />
                  </Grid>
                  <Icon className={classes.icon}>
                    <ArrowRightAlt />
                  </Icon>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      id={`newReference-${index}`}
                      name={`newReference_${index}`}
                      type="text"
                      label={`${utils.string.t('form.addEditLineageMapping.rightLabel')} ${index + 1}`}
                      disabled={isSubmitting || (isOriginalValue && disabledStates[index])}
                      error={!!errors?.mappings?.[index]?.newReference}
                      helperText={errors?.mappings?.[index]?.newReference && errorMessage}
                      {...register(`mappings.${index}.newReference`, { required: true })}
                    />
                  </Grid>
                  {isOriginalValue && disabledStates[index] ? (
                    <IconButton className={classes.iconButton} onClick={() => handleEnableEdit(index, false)}>
                      <EditOutlined />
                    </IconButton>
                  ) : (
                    <IconButton className={classes.iconButton} onClick={() => onSubmit(index)}>
                      <Save />
                    </IconButton>
                  )}
                  <IconButton className={classes.iconButton} onClick={() => handleDelete({ index, oldReference, isOriginalValue })}>
                    <Delete />
                  </IconButton>
                </Grid>
              ))}
            </Box>
          </>
        )}
      </div>
    </FormProvider>
  );
};
