// useGetQuotes
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getPreBindDefinitions(product, type, facilityId, token) {
  if (!product || !type || !facilityId) {
    throw new Error('useGetPreBindDefinitions: Missing required parameters.');
  }

  const apiUrl = `products/${product}?type=${type}&facilityId=${facilityId}`;

  const response = await api(token).get(apiUrl);
  return response.data;
}

export function useGetPreBindDefinitions(queryKey, product, type, facilityId, options) {
  return useApi([queryKey, product, type, facilityId], async (_, token) => await getPreBindDefinitions(product, type, facilityId, token), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
