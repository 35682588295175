import { useQuery } from 'react-query';

// app
import { placementsAxios } from 'lib/placements';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { DEPARTMENT_ACCOUNTS } from 'lib/placements/queryKeys';

// get department accounts

const fetch = async (deptId, params) => {
  const response = await placementsAxios.get(`/placement/department/${deptId}/calendar`, {
    params: params,
  });

  return utils.placement.parsePlacements(response.data?.data);
};

export const useGetDepartmentAccounts = ({ departmentId, params }) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'placements.queries.useGetDepartmentAccounts',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [DEPARTMENT_ACCOUNTS, departmentId, params],
    async () => {
      const result = await fetch(departmentId, params);

      return result;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
