import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_RULESETS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

const getRules = async (params) => {
  const result = await binderManagementDroolsAxios.get('/drools/rules', {
    params,
  });

  const data = result?.data;

  return data;
};

export const useGetRulesets = (params = {}, options = {}) => {
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetRulesets' });

  return useQuery([DROOLS_RULESETS, params], async () => getRules(params), {
    onError: (err) => handleError(err),
    cacheTime: 60 * 60 * 1000,
    staleTime: 10 * 1000,
    ...options,
  });
};
