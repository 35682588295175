import { makeStyles } from '@material-ui/core';

export const useBinderLimitsStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  searchBox: {
    padding: theme.spacing(1, 2),
  },
  list: {
    flex: '1 1 100%',
    display: 'flex',
    height: 400,
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  avatarRoot: {
    marginRight: theme.spacing(1),
  },
  tooltip: {
    minWidth: 375,
  },
}));

export const useBinderLimitPreviewStyles = makeStyles(() => ({
  root: {
    minWidth: 375,
  },
}));
