import { FC } from 'react';
import { FormGrid } from 'components';
import { FacilityCard } from './FacilityCard';
import { FacilityCardListSkeleton } from './FacilityCardListSkeleton';

export const FacilityCardList: FC<IFacilityCardListProps> = ({ isListLoading, facilities, entity, type }) => (
  <>
    {isListLoading ? (
      <FacilityCardListSkeleton />
    ) : (
      facilities?.content?.length > 0 &&
      facilities?.content?.map((facility) => (
        <FormGrid item xs={12} sm={6} md={4} lg={3} key={facility?.umr} data-testid={`facilities-card-${facility?.umr}`}>
          <FacilityCard facility={facility} entity={entity} type={type} />
        </FormGrid>
      ))
    )}
  </>
);
