const styles = (theme) => ({
  root: ({ type, size, align }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
    marginTop: 2,
    marginBottom: 2,
    color: theme.palette.neutral.main,
    fontSize: theme.typography.pxToRem(size === 'small' ? 12 : size === 'medium' ? 13 : 14),
    ...(type === 'info' && { color: theme.palette.info.main }),
    ...(type === 'success' && { color: theme.palette.success.main }),
    ...(type === 'alert' && { color: theme.palette.alert.main }),
    ...(type === 'error' && { color: theme.palette.error.main }),
  }),
  icon: ({ size }) => ({
    fontSize: theme.typography.pxToRem(size === 'small' ? 16 : size === 'medium' ? 18 : 20),
    marginRight: 4,
  }),
});

export default styles;
