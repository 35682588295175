import { useQuery } from 'react-query';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { LINEAGE_YEAR_MONTH } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLineageProps {
  yearMonthStart: string;
  options?: {
    size: number;
  };
}

export const useGetLineage = ({ yearMonthStart, options = { size: 12 } }: IUseGetLineageProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineage',
  });

  return useQuery<ILineageEntitiesByMonth[]>(
    [LINEAGE_YEAR_MONTH, yearMonthStart],
    async () => {
      const result = await binderManagementLineageAxios.get('lineage', {
        params: {
          yearMonthStart,
          size: options.size,
        },
      });
      return result?.data;
    },
    {
      onError: handleError,
    }
  );
};
