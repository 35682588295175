import { FC, useCallback, useContext } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Button, Status, Tooltip } from 'components';

import { SUBMISSION_STATUS, SUBMISSION_STATUS_FAILED, SUBMISSION_STATUS_NEW } from 'consts';
import * as utils from 'utils';

import { ProcessingContext } from '../../Processing.context';
import { Deductions } from '../Deductions';

import { ICurrencyAggregateProps } from './CurrencyAggregate.types';

import { useCurrencyAggregateStyle } from './CurrencyAggregate.style';

export const CurrencyAggregate: FC<ICurrencyAggregateProps> = ({ aggregate, hooks }) => {
  const classes = useCurrencyAggregateStyle();
  const status = SUBMISSION_STATUS.find(({ statusCode }) => statusCode === aggregate.submissionStatus);
  const { bdxDocumentsInfo, tooltipText, isAllSubmitting, isSubmitDisabled } = useContext(ProcessingContext);

  const { handleDownload, isDownload } = hooks.useHandleDownload({ aggregate });
  const { handleSubmit, isSubmitting } = hooks.useHandleSubmit({ aggregate, bdxDocumentsInfo });
  const { handleSubmitManually, isSubmittingManually } = hooks.useHandleSubmitManually({ aggregate, bdxDocumentsInfo });

  const AggregateRecord = useCallback(
    ({ label, value }) => (
      <Typography variant="h5" className={classes.aggregateRecord}>
        {label}
        <span className={classes.aggregateRecordValue}>{value}</span>
      </Typography>
    ),
    [classes.aggregateRecord, classes.aggregateRecordValue]
  );

  return (
    <div
      data-testid="extra-information"
      className={classes.aggregateWrapper}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className={classes.aggregateContent}>
        <div className={classes.aggregateStatus}>
          <Status
            status={status.status}
            text={status.text}
            size="sm"
            statusOverrides={{
              processing: 'light',
            }}
          />
        </div>
        <AggregateRecord label={utils.string.t('bdx.processing.currency')} value={aggregate.settlementCurrency} />
        {(aggregate as IBDXPremiumAggregate).grossAmount >= 0 && (
          <AggregateRecord
            label={utils.string.t('bdx.processing.grossAmount')}
            value={utils.string.t('format.currency', {
              value: { number: (aggregate as IBDXPremiumAggregate).grossAmount, currency: aggregate.settlementCurrency },
            })}
          />
        )}
        {(aggregate as IBDXClaimAggregate).latestFee >= 0 && (
          <AggregateRecord
            label={utils.string.t('bdx.processing.latestFee')}
            value={utils.string.t('format.currency', {
              value: { number: (aggregate as IBDXClaimAggregate).latestFee, currency: aggregate.settlementCurrency },
            })}
          />
        )}
        {(aggregate as IBDXClaimAggregate).latestReserve >= 0 && (
          <AggregateRecord
            label={utils.string.t('bdx.processing.latestReserve')}
            value={utils.string.t('format.currency', {
              value: { number: (aggregate as IBDXClaimAggregate).latestReserve, currency: aggregate.settlementCurrency },
            })}
          />
        )}
        <AggregateRecord label={utils.string.t('bdx.processing.transactionType')} value={aggregate.transactionType} />
        {(aggregate as IBDXPremiumAggregate).deductions?.length > 0 && (
          <div className={classes.aggregateDeductions}>
            <Tooltip
              title={
                <Deductions deductions={(aggregate as IBDXPremiumAggregate).deductions} settlementCurrency={aggregate.settlementCurrency} />
              }
              rich
              testid="-deductions"
              interactive
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        {status.statusCode === SUBMISSION_STATUS_FAILED && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            classes={{ btn: classes.processManually }}
            text="Submitted Manually"
            tooltip={{ title: tooltipText }}
            onClick={handleSubmitManually}
            disabled={isAllSubmitting || isSubmittingManually || isSubmitDisabled}
          />
        )}
        {status.statusCode === SUBMISSION_STATUS_NEW ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            classes={{ btn: classes.submit }}
            text={`Submit ${aggregate?.settlementCurrency}`}
            onClick={handleSubmit}
            tooltip={{ title: tooltipText }}
            disabled={isAllSubmitting || isSubmitting || isSubmitDisabled}
          />
        ) : (
          <Tooltip title="Download" placement="top">
            <IconButton onClick={handleDownload} size="small" disabled={isDownload} data-testid="download-bdx">
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
