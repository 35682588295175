import { useContext, FC } from 'react';
import { firstBy } from 'thenby';

// app
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Empty } from 'components';
import { useCreateLimit, useGetLimitSets } from 'lib/binderManagement';
import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';
import * as utils from 'utils';
import { BinderLimit } from '../BinderLimit';
import { BinderLimitsListSkeleton } from './BinderLimitsListSkeleton';

const NoLimits: FC = () => <Empty width={400} title={utils.string.t('binderLimits.noLimitsFound')} icon={<SearchResultImage />} padding />;

export const BinderLimitsList: FC = () => {
  const { facility, isLoading: isLoadingFacility } = useContext(FacilityContext);
  const { mutateAsync: updateLimit, isLoading: isLoadingLimit } = useCreateLimit({ facility });

  const { data: limitSets, isLoading: isLoadingLimitSets } = useGetLimitSets(
    {
      facilityUMR: facility?.umr,
    },
    {
      enabled: facility?.umr ? true : false,
    }
  );

  const isLoading = isLoadingFacility || isLoadingLimitSets;

  if (isLoading) return <BinderLimitsListSkeleton />;

  const handleFavourites = (data: IBinderLimit) => {
    const action = data?.favourite ? 'UNFAVOURITE_LIMIT' : 'FAVOURITE_LIMIT';

    updateLimit({
      data,
      action,
    });
  };

  const handleDeleteLimit = (data: IBinderLimit) => {
    updateLimit({ data, action: 'DELETE_LIMIT' });
  };

  const limits = limitSets
    ? limitSets?.sort(firstBy(utils.sort.array('numeric', 'salience', 'asc')).thenBy(utils.sort.array('string', 'name', 'asc')))
    : [];

  return (
    <div>
      {limits?.length !== 0 ? (
        limits?.map((limit) => (
          <BinderLimit
            key={`${limit.id}-${limit.name}`}
            limit={limit}
            facility={facility}
            isLoading={isLoadingLimit}
            handleFavourites={handleFavourites}
            handleDeleteLimit={handleDeleteLimit}
          />
        ))
      ) : (
        <NoLimits />
      )}
    </div>
  );
};
