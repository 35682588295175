import PropTypes from 'prop-types';

// app
import { useAddFacility } from 'lib/quoteBind';
import { AddProductsFacilityView } from './AddProductsFacility.view';
import { Empty } from 'components';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { useGetQBUsers, QB_USERS } from 'lib/quoteBind';
import * as utils from 'utils';

AddProductsFacility.propTypes = {
  fields: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

AddProductsFacility.defaultProps = {
  handleClose: () => {},
};

export default function AddProductsFacility({ fields, facility, groupId, carrierId, handleClose }) {
  const { mutate: addFacility } = useAddFacility();

  const { data: notifiedUsers, isLoading } = useGetQBUsers(QB_USERS);

  const options = {
    notifiedUsers,
  };

  const { id, liveFrom, liveTo, ...facilityDefaultData } = facility || {};

  const hydrateOptions = (fields) => {
    if (!fields) return [];

    return fields.map((field) => {
      return {
        ...field,
        muiComponentProps: {
          ...field.muiComponentProps,
        },
        ...(field.optionsDynamicKey && {
          options: utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          }),
        }),
      };
    });
  };

  const getFacilityDefaultData = (fields, facility) => {
    if (!fields) return {};

    return fields.reduce((acc, field) => {
      let value = facility[field.name] || '';
      if (field.id === 'permissionToBindGroups' || field.id === 'permissionToDismissIssuesGroups') {
        value = value?.map((item) => {
          return field.options.find((option) => option.value === item);
        });
      }

      return {
        ...acc,
        [field.name]: value,
      };
    }, {});
  };

  const handleSubmit = (values) => {
    const { permissionToBindGroups, permissionToDismissIssuesGroups, notifiedUsers, liveFrom, liveTo, ...rest } = values;

    const data = {
      ...rest,
      groupId,
      carrierId,
      liveFrom: utils.date.startOf(liveFrom, 'day'),
      liveTo: utils.date.endOf(liveTo, 'day'),
      permissionToBindGroups: permissionToBindGroups?.map((prog) => prog.value),
      permissionToDismissIssuesGroups: permissionToDismissIssuesGroups?.map((item) => item.value),
      notifiedUsers: notifiedUsers.map((user) => {
        return {
          name: user.name,
          email: user.email,
        };
      }),
      ...(facility && { existingFacilityId: facility.id }),
    };

    addFacility(data);
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('products.admin.facilities.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  const hydratedFields = hydrateOptions(fields);

  const defaultValues = facility
    ? getFacilityDefaultData(hydratedFields, facilityDefaultData)
    : utils.form.getInitialValues(hydratedFields);

  // abort
  if (!utils.generic.isValidArray(fields, true)) return null;

  if (isLoading) {
    return <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />;
  }

  return (
    <AddProductsFacilityView
      fields={[hydratedFields]}
      actions={actions}
      loading={isLoading}
      defaultValues={defaultValues}
      validationSchema={utils.form.getValidationSchema(hydratedFields)}
    />
  );
}
