const initialState = {
  binders: {
    searchStr: '',
    facilities: {},
    coverholders: {},
    markets: {},
    reports: {},
    documents: {},
    bordereaux: {},
  },
  admin: {
    users: {},
  },
  'quote-bind': {
    'risk-list': {},
  },
  'claims-tasks': {
    'claims-tasks-list': {},
  },
};

const filterReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        [action.payload.category]: {
          ...state[action.payload.category],
          [action.payload.page]: action.payload.options,
        },
      };
    case 'RESET_FILTER':
      return {
        ...state,
        [action.payload.category]: { ...state[action.payload.category], [action.payload.page]: [] },
      };
    case 'RESET_ALL_FILTERS':
      return {
        ...initialState,
      };

    case 'SET_SEARCH_FILTER':
      return {
        ...state,
        [action.payload.category]: {
          ...state[action.payload.category],
          searchStr: action.payload.searchStr,
        },
      };

    default:
      return state;
  }
};

export default filterReducers;
