import { useQuery } from 'react-query';

// app
import { axiosInstance } from '../api';
import { CLAIMS_USER_LIST } from '../queryKeys';
import { getHandleError } from '../utils';

export const useGetClaimsUserList = () => {
  const handleError = getHandleError('claimsTaskManagement.queries.useGetClaimsUserList');

  return useQuery(
    [CLAIMS_USER_LIST],
    async () => {
      const url = `tm/v1/users`;

      const result = await axiosInstance.get(url);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
