import { FC, useMemo, useRef, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import lodashMap from 'lodash/map';

import { Box, Checkbox, CircularProgress, Grid } from '@material-ui/core';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { BaseTableCell, BaseTableHeaderCell, Empty, Pagination } from 'components';

import { usePagination } from 'hooks';
import * as utils from 'utils';

import { useMountDelay } from '../../../useMountDelay';

import { ISummaryTableColumnProps, ISummaryTableProps } from './SummaryTable.types';

import { useSummaryTableStyles } from './SummaryTable.styles';
import 'react-base-table/styles.css';

const HEADER_HEIGHT = 55;
const SELECT_ALL = 'Select All';
const UNSELECT_ALL = 'Unselect All';
const BULK_SELECT_BUTTON = 'bulkSelectButton';

const CheckboxCell = (params) => {
  const { rowData, rowIndex, container, column, columns } = params;
  const { onChange, selectedRowKeys, setBulkSelectCondition } = column;
  const selectedKeysLength = columns[0]?.selectedRowKeys.length;
  const totalKeysLength = container.props.data.length;

  const handleChange = (e) => {
    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  const condition = selectedKeysLength < totalKeysLength ? SELECT_ALL : UNSELECT_ALL;
  const handleSelectOrDeselectAll = () => {
    setBulkSelectCondition(condition);
    container.props.data.forEach((rowData) => {
      rowData.id && onChange({ rowData }, condition);
    });
  };

  const onChangeHandler = rowData.id === BULK_SELECT_BUTTON ? handleSelectOrDeselectAll : handleChange;
  const checked = rowData.id === BULK_SELECT_BUTTON ? selectedKeysLength === totalKeysLength : selectedRowKeys?.includes(rowData.id);
  return <Checkbox color="primary" checked={checked} onChange={onChangeHandler} data-testid={`checkbox-${rowData.id}`} />;
};

export const SummaryTable: FC<ISummaryTableProps> = ({
  data,
  isLoading,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  size,
  getHandleAssign,
  getHandleDismiss,
  AssignComponent,
  assignCellWidth = 60,
  cellName = 'cells',
  columnName = 'columnName',
  columnValue = 'columnValue',
}) => {
  const classes = useSummaryTableStyles();
  const ref = useRef(null);
  const mountDelay = useMountDelay();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [bulkSelectCondition, setBulkSelectCondition] = useState(SELECT_ALL);

  const canAssign = !!AssignComponent;

  const renderEmpty: FC = () => {
    if (data?.content?.length === 0 || isError) {
      return <Empty width={340} title={utils.string.t('app.noResults')} icon={<SearchResultImage />} padding />;
    }
    return null;
  };

  const pagination = usePagination(data?.content, utils.generic.getPaginationObj(data), handleChangePage, handleChangeRowsPerPage);

  const columns: ISummaryTableColumnProps[] = useMemo(() => {
    if (data?.content?.length > 0) {
      const content = lodashMap(data?.content[0]?.[cellName], columnName);
      if (content?.length > 0) {
        return content.map((c, index) => ({
          title: c,
          dataKey: `field_${index}`,
          resizable: true,
          width: 150,
          flex: 1,
          align: 'left',
        }));
      }
    }
    return [];
  }, [cellName, columnName, data?.content]);

  const rows: Record<string, string>[] = useMemo(() => {
    if (!columns || columns?.length === 0) {
      return [];
    }

    if (data?.content?.length > 0) {
      const result = data.content
        .map((row) => {
          if (row[cellName]?.length > 0) {
            let newRow = { id: row.rowId };
            columns.forEach((column, index) => {
              const record = row[cellName]?.find((col) => column.title === col[columnName]);
              newRow = { ...newRow, [`field_${index}`]: record?.[columnValue] || '' };
            });
            return newRow;
          }
          return null;
        })
        .filter(Boolean);

      result.unshift({ id: BULK_SELECT_BUTTON });
      return result;
    }
    return [];
  }, [columns, data?.content, cellName, columnValue, columnName]);

  const handleSelectChange = ({ rowData }, condition = null) => {
    const key = rowData.id;
    setSelectedRowKeys((prev) => {
      if (condition === SELECT_ALL) {
        if (!prev.includes(key)) {
          return [...prev, key];
        }
        return [...prev];
      }
      if (condition === UNSELECT_ALL) {
        return [];
      }
      if (prev.includes(key)) {
        return prev.filter((r) => r !== key);
      }
      return [...prev, key];
    });
  };

  const selectedRowCurrent = ({ rowData }): string => {
    const rowClass = '';
    const key = rowData.id;
    return [rowClass, selectedRowKeys.includes(key) && classes.rowSelected].filter(Boolean).join(' ');
  };

  const handleSuccess = () => {
    setSelectedRowKeys([]);
  };

  const headerRenderer = () => (
    <AssignComponent
      selectedRowKeys={selectedRowKeys}
      handleSuccess={handleSuccess}
      getHandleAssign={getHandleAssign}
      getHandleDismiss={getHandleDismiss}
    />
  );

  return (
    <>
      <Box className={classes.baseTable}>
        {mountDelay || isLoading ? (
          <Box className={classes.loader} data-testid="loader">
            <CircularProgress />
          </Box>
        ) : (
          <AutoResizer height={(size + 2) * (HEADER_HEIGHT + 1)}>
            {({ width, height }) => (
              <BaseTable
                fixed
                data={rows}
                rowKey="id"
                ref={ref}
                emptyRenderer={renderEmpty}
                headerHeight={HEADER_HEIGHT}
                rowClassName={selectedRowCurrent}
                width={width}
                height={height}
                components={{ TableCell: BaseTableCell, TableHeaderCell: BaseTableHeaderCell }}
              >
                {canAssign ? (
                  <Column
                    key="__selection__"
                    width={assignCellWidth}
                    align="center"
                    frozen={Column.FrozenDirection.LEFT}
                    onChange={handleSelectChange}
                    headerRenderer={headerRenderer}
                    selectedRowKeys={selectedRowKeys}
                    cellRenderer={CheckboxCell}
                    bulkSelectCondition={bulkSelectCondition}
                    setBulkSelectCondition={setBulkSelectCondition}
                  />
                ) : null}
                {columns.map(({ dataKey, ...restProps }) => (
                  <Column key={dataKey} dataKey={dataKey} {...restProps} />
                ))}
              </BaseTable>
            )}
          </AutoResizer>
        )}
      </Box>
      {rows?.length > 0 ? (
        <Grid container data-testid="pagination">
          <Grid item xs={12} sm={12}>
            <Pagination
              page={pagination.obj.page}
              count={pagination.obj.rowsTotal}
              rowsPerPage={pagination.obj.rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onChangePage={pagination.handlers.handleChangePage}
              onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
