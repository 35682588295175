const styles = (theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '200px',
    '& img': {
      maxWidth: '100%',
    },
  },
  instructionsLabel: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: theme.palette.neutral.black,
  },
  instructions: {
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.neutral.black,
    '& li': {
      marginBottom: theme.spacing(1),
    },
    '& li::marker': {
      color: theme.palette.neutral.black,
      fontSize: '12px',
      fontWeight: 600,
    },
  },
});
export default styles;
