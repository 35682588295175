import { FC } from 'react';
import { BinderLimitSkeleton } from '../BinderLimit/BinderLimitSkeleton';

export const BinderLimitsListSkeleton: FC = () => (
  <div>
    <BinderLimitSkeleton />
    <BinderLimitSkeleton />
    <BinderLimitSkeleton />
  </div>
);
