import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// edge
import { useAssignToFacility } from 'lib/quoteBind';
import { Button, FormContainer, FormActions, FormGrid, FormFields, FormAutocompleteMui } from 'components';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

export const AssignToFacilityGroup = ({ facility, facilityGroups, handleClose }) => {
  const classes = useStyles();
  const { mutate: addToFacilityGroup } = useAssignToFacility();

  const { carrierId, productCode } = facility;
  const availableFacilityGroups = facilityGroups.filter(
    (facilityGroup) => facilityGroup.carrierId === carrierId && facilityGroup.productCode === productCode
  );
  const showFacilityGroup = availableFacilityGroups.length > 0;

  const defaultValues = {
    groupId: null,
  };

  const validationSchema = yup
    .object()
    .shape({
      groupId: yup.object().nullable().required(utils.string.t('validation.required')),
    })
    .required();

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onCancel = () => {
    handleClose();
  };

  const onSubmit = async (data) => {
    const facilityData = {
      facilityId: facility?.id,
      groupId: data.groupId?.value,
    };

    addToFacilityGroup(facilityData);
  };

  return (
    <div className={classes.root}>
      <FormContainer type="default" onSubmit={handleSubmit(onSubmit)} data-testid="form-assign-to-facility-group">
        {showFacilityGroup ? (
          <FormFields type="default">
            <FormGrid container>
              <FormGrid item xs={12}>
                <FormAutocompleteMui
                  control={control}
                  name="groupId"
                  label={utils.string.t('products.admin.facilities.facilityGroup')}
                  options={availableFacilityGroups}
                  error={errors.groupId}
                />
              </FormGrid>
            </FormGrid>
          </FormFields>
        ) : (
          <Typography variant="h2" style={{ fontWeight: 500, fontSize: 14 }}>
            {utils.string.t('products.admin.facilities.noFacilityGroups')}
          </Typography>
        )}

        <FormActions>
          <Button text={utils.string.t('app.cancel')} variant="text" disabled={formState.isSubmitting} onClick={onCancel} />
          <Button
            text={utils.string.t('app.assign')}
            type="submit"
            disabled={formState.isSubmitting || !showFacilityGroup}
            color="primary"
          />
        </FormActions>
      </FormContainer>
    </div>
  );
};
