import get from 'lodash/get';
import { createSelector } from 'reselect';
import { selectRefDataDepartments } from 'stores';
import * as utils from 'utils';

export const selectUser = (state) => state.user;
export const selectUserAuthenticated = (state) => Boolean(get(state, 'user.auth.accessToken'));
export const selectUserAuthInProgress = (state) => Boolean(get(state, 'user.auth.inProgress'));

export const selectIsBroker = createSelector(selectUser, (user) => utils.user.isBroker(user));

export const selectIsProducer = createSelector(selectUser, (user) => utils.user.isProducer(user));

export const selectIsUnderwriter = createSelector(selectUser, (user) => utils.user.isUnderwriter(user));

export const selectIsAdmin = createSelector(selectUser, (user) => utils.user.isAdmin(user));

export const selectIsTeamLeader = createSelector(selectUser, (user) => utils.user.isTeamLeader(user));

export const selectIsReportAdmin = createSelector(selectUser, (user) => utils.user.isReportAdmin(user));

export const selectUserDepartmentId = (state) => get(state, 'user.departmentSelected');

export const selectUserDepartmentIds = (state) => get(state, 'user.departmentIds') || [];

export const selectUserDepartment = createSelector(selectUserDepartmentId, selectRefDataDepartments, (departmentId, departments) => {
  if (!departmentId || !utils.generic.isValidArray(departments)) return;
  return departments.find((dept) => departmentId === dept.id);
});

export const selectUserOffices = (state) => get(state, 'user.offices') || [];
