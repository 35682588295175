import Box from '@material-ui/core/Box';
import NotificationsIcon from '@material-ui/icons/Notifications';

type NewIconProps = {
  isNew: boolean;
};
export const NewIcon = ({ isNew }: NewIconProps) => {
  return isNew ? (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: 'rgba(79, 178, 206, 0.16)',
        color: '#00829d',
        fontSize: '0.7rem',
        fontWeight: 'bold',
      }}
    >
      <NotificationsIcon
        color="secondary"
        fontSize="small"
        style={{
          width: 16,
          height: 16,
        }}
      />
      <span>NEW</span>
    </Box>
  ) : null;
};
