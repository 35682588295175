import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { DROOLS_RULESETS, multipartBinderManagementDroolsAxios } from 'lib/binderManagement';
import { useHandleError } from 'lib/binderManagement/binderManagement.utils';
import { enqueueNotification } from 'stores';

export const useUploadRules = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'rules.hooks.useUploadRules',
    warning: 'notification.binderManagement.rulesUploadFail',
  });

  const handleSuccess = ({ facility }) => {
    queryClient.invalidateQueries(DROOLS_RULESETS, facility);
    dispatch(enqueueNotification('notification.binderManagement.rulesUploadSuccess', 'success'));
  };

  return useMutation<{ facility: string }, AxiosError, IUploadRulesDTO>(
    async ({ file, bdxType, facility, thirdPartyId }) => {
      const form = new FormData();
      form.append('multipartFile', file);

      const baseurl = '/drools/file';
      const queryParams = [`bdxType=${bdxType}`, `facility=${facility}`];

      if (thirdPartyId) {
        queryParams.push(`thirdPartyId=${thirdPartyId}`);
      }

      const url = `${baseurl}?${queryParams.join('&')}`;

      await multipartBinderManagementDroolsAxios.post(url, form);
      return { facility };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
