import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, FormFileDrop } from 'components';

import { DRL_FILE_EXTENSION, UPLOAD_RULES } from 'consts';
import { hideModal } from 'stores';

import { useUploadRules } from '../hooks/useUploadRules';

export const RulesUploader = ({ bdxType, facility, thirdPartyId, classes }: IRulesUploaderProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [fileIsReady, setFileIsReady] = useState(false);
  const { mutateAsync: uploadRules, isLoading: isLoadingUploadRules } = useUploadRules();
  const { control, setValue, getValues } = useForm({ defaultValues: { file: '' } });

  const onRemoveCallback = () => {
    setError('');
    setFileIsReady(false);
    setValue('file', null);
  };

  const handleChange = (file) => {
    const { name } = file?.[0] || {};
    const nameLength = name.length;

    if (name.substring(nameLength, nameLength - 4) === DRL_FILE_EXTENSION) {
      setError('');
      setFileIsReady(true);
      setValue('file', file);
    } else {
      setError(`Please upload a ${DRL_FILE_EXTENSION} file`);
      setFileIsReady(false);
    }
  };

  const onSuccess = () => {
    dispatch(hideModal(UPLOAD_RULES));
  };

  const handleSubmit = async () => {
    const [file] = getValues().file;
    await uploadRules({ file, bdxType, facility, thirdPartyId });
    onSuccess();
  };

  return (
    <div className={classes.content}>
      <FormFileDrop
        label="Rules File"
        control={control}
        onChange={(f) => handleChange(f)}
        onRemoveCallback={onRemoveCallback}
        error={{ message: error }}
        name="file"
        value={null}
        onlyOneFile
        data-testid="form-file-drop"
      />
      <div className={classes.actions}>
        <Button
          style={{ marginTop: 8 }}
          color="primary"
          size="small"
          text="Submit"
          onClick={handleSubmit}
          disabled={!fileIsReady || isLoadingUploadRules}
          data-testId="submit-button"
        />
      </div>
    </div>
  );
};
