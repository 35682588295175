import { makeStyles } from '@material-ui/core';

export const useCurrencyAggregateStyle = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      alignContent: 'center',
      justifyContent: 'space-between',
    },
    title: {
      margin: 0,
      fontWeight: 600,
    },
    groupTitle: {
      marginBottom: 0,
      marginRight: 10,
      fontWeight: 600,
    },
    aggregateWrapper: {
      display: 'flex',
      flex: 1,
      alignContent: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      width: '100%',
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 10,
    },
    aggregateContent: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    aggregateStatus: {
      marginRight: theme.spacing(2),
      width: 130,
      display: 'flex',
      alignContent: 'center',
    },
    aggregateRecord: {
      margin: 0,
      marginLeft: 10,
      padding: 0,
      fontWeight: 500,
    },
    aggregateRecordValue: {
      fontWeight: 700,
      marginLeft: 4,
    },
    aggregateDeductions: {
      marginLeft: theme.spacing(2),
    },
    submit: {
      margin: 0,
    },
    processManually: {
      marginLeft: 10,
    },
  }),
  { name: 'CurrencyAggregate' }
);
