import { FC, useContext } from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion } from 'components';
import { TGetHandleAssignToBlock } from 'modules/Binders';

import { useGetBdxPremiumData } from 'lib/binderManagement';

import { useHandleDownloadPremium, useHandleSubmitPremium, useHandleSubmitPremiumManually } from '../hooks';
import { ProcessingContext } from '../Processing.context';
import { useBDXProcessingAccordion, useBDXProcessingPagination } from '../utils';

import { TAssignComponent } from './AssignToBlock';
import { GroupTitle } from './GroupTitle';
import { SummaryTable } from './SummaryTable';

interface IUnderWriterGroupProps {
  uwGroup: IFacilitySection;
  getHandleAssign: TGetHandleAssignToBlock;
  AssignComponent?: TAssignComponent;
  aggregates?: IBDXPremiumAggregate[];
}

export const UnderWriterGroup: FC<IUnderWriterGroupProps> = ({ uwGroup, aggregates, getHandleAssign, AssignComponent }) => {
  const { page, size, handleChangePage, handleChangeRowsPerPage } = useBDXProcessingPagination();
  const { bdxDocumentsInfo } = useContext(ProcessingContext);

  const {
    data: premiumData,
    isLoading,
    isFetching,
    isError,
  } = useGetBdxPremiumData({
    bdxDocumentsInfo: {
      facilityReference: bdxDocumentsInfo.facilityReference,
      yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
      uwGroup: uwGroup.sectionKey,
    },
    page,
    size,
  });

  const { expanded, handleChange } = useBDXProcessingAccordion(premiumData?.numberOfElements);

  if (uwGroup.sectionKey === null && premiumData?.numberOfElements === 0) return null;

  return (
    <Accordion
      titleComponent={
        <GroupTitle
          title={`${uwGroup.groupRef ? `${uwGroup.groupRef} - ` : ''}${uwGroup.sectionName}`}
          aggregates={aggregates}
          hooks={{
            useHandleDownload: useHandleDownloadPremium,
            useHandleSubmit: useHandleSubmitPremium,
            useHandleSubmitManually: useHandleSubmitPremiumManually,
          }}
        />
      }
      icon={isLoading || isFetching ? <CircularProgress size={24} /> : <ExpandMoreIcon />}
      disabled={isLoading || premiumData?.numberOfElements === 0}
      expanded={expanded}
      onChange={handleChange}
      type="solid"
      margin={1}
      testid="underwriter-group"
    >
      <Box style={{ padding: 0, width: '100%' }}>
        <SummaryTable
          data={premiumData}
          isLoading={isLoading || isFetching}
          isError={isError}
          size={size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          AssignComponent={AssignComponent}
          getHandleAssign={getHandleAssign}
        />
      </Box>
    </Accordion>
  );
};
