import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import { QB_FACILITIES } from 'lib/quoteBind/queryKeys';
import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

export const usePutFacilityDetails = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePutFacilityDetails',
      message: 'Data missing for facility limits.',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([QB_FACILITIES, {}, res.id], [res]);

    dispatch(enqueueNotification('notification.putFacilityDetails.success', 'success'));
  };

  return useMutation(
    async (data) => {
      const { facilityId, formData = {} } = data;

      if (!facilityId) {
        throw new Error('Invalid data');
      }

      const postExpiryOptions = {
        enablePostExpiry: formData?.enablePostExpiry,
        quoteFrom: utils.date.formatDateWithParameter(formData?.quoteFrom, 'YYYY-MM-DDTHH:mm:ss'),
        createUntil: utils.date.formatDateWithParameter(formData?.createUntil, 'YYYY-MM-DDTHH:mm:ss'),
      };

      const body = {
        preBind: formData?.preBind,
        permissionToBindGroups: formData?.permissionToBindGroups?.map((prog) => prog.value),
        notifiedUsers: formData?.notifiedUsers,
        permissionToDismissIssuesGroups: formData?.permissionToDismissIssuesGroups.map((item) => item.value),
        enablePreBindConfirmation: formData?.enablePreBindConfirmation ?? false,
        preBindConfirmationText: formData?.preBindConfirmationText ?? '',
        watermarkQuotes: formData?.watermarkQuotes,
        watermarkText: formData?.watermarkText,
        watermarkBoundText: formData?.watermarkBoundText,
        quoteValidDays: formData?.quoteValidDays,
        ...(formData?.enablePostExpiry ? postExpiryOptions : {}),
      };

      const result = await quoteBindAxios.put(`facilities/${facilityId}/settings`, body);
      return result.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
