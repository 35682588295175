import { Confirm } from 'components';
import {
  AddBinderLimit,
  AddClaimBlock,
  AddDepartmentMarket,
  AddEditClaimsTask,
  AddEditMarkets,
  AddEditOperationalClientOffice,
  AddEditOperationalThirdParty,
  AddEditOperationalThirdPartyOffice,
  AddEditParentClient,
  AddEditQuoteBind,
  AddEditReport,
  AddEditReportGroup,
  AddEditUmr,
  AddInsured,
  AddLayer,
  AddMarket,
  AddPlacementLayer,
  AddPlacementLayerMarket,
  AddPlacementMarket,
  AddProductsCarrier,
  AddProductsClient,
  AddProductsFacility,
  AddProductsInsured,
  AddRule,
  AddUser,
  AssignToClaimBlock,
  AssignToFacility,
  AssignToFacilityGroup,
  AssignToUnderwriterGroup,
  BulkUpdateLayer,
  BulkUpdatePolicy,
  ConfirmDecline,
  ConfirmDelete,
  ConfirmUnBound,
  CreateInWhitespace,
  DocumentUpload,
  DownloadBordereaux,
  DownloadFiles,
  EditDepartmentMarket,
  EditEndorsementQuote,
  EditEndorsementQuoteProRata,
  EditFacilityUsers,
  EditPlacement,
  EditPlacementLayer,
  EditPlacementMarketsLayers,
  EditPolicy,
  EditProductsClient,
  EditProductsInsured,
  EditQuote,
  EditRiskQuote,
  EditTripBrokers,
  ExtendCancelPolicy,
  FacilityConfig,
  ImportBinderLimits,
  ImportRules,
  ModellingTask,
  NewEnquiry,
  PlacementPDF,
  PreBindQuote,
  Report,
  SignDown,
  UploadBDX,
  UploadBDXCoverHolder,
  UploadFacilityDocument,
  UploadLineageReport,
  ValidateFilePassword,
  AddEditLineageMapping,
} from 'forms';
import { BDXExtraction, PasteFromExcel, PortfolioMap, UploadRules } from 'modules';

export const MODALS = {
  ADD_DEPARTMENT_MARKET: AddDepartmentMarket,
  ADD_EDIT_LINEAGE_MAPPING: AddEditLineageMapping,
  ADD_EDIT_OPERATIONAL_CLIENT_OFFICE: AddEditOperationalClientOffice,
  ADD_EDIT_PARENT_CLIENT: AddEditParentClient,
  ADD_EDIT_OPERATIONAL_MARKET: AddEditMarkets,
  ADD_EDIT_OPERATIONAL_THIRD_PARTY: AddEditOperationalThirdParty,
  ADD_EDIT_OPERATIONAL_THIRD_PARTY_OFFICE: AddEditOperationalThirdPartyOffice,
  ADD_EDIT_UMR: AddEditUmr,
  ADD_USER: AddUser,
  ADD_EDIT_QUOTE_BIND: AddEditQuoteBind,
  ADD_INSURED: AddInsured,
  ADD_LAYER: AddLayer,
  ADD_MARKET: AddMarket,
  ADD_PLACEMENT_LAYER: AddPlacementLayer,
  ADD_PLACEMENT_LAYER_MARKET: AddPlacementLayerMarket,
  ADD_PLACEMENT_MARKET: AddPlacementMarket,
  ADD_PRODUCTS_CLIENT: AddProductsClient,
  ADD_PRODUCTS_CARRIER: AddProductsCarrier,
  ADD_PRODUCTS_FACILITY: AddProductsFacility,
  ASSIGN_TO_FACILITY_GROUP: AssignToFacilityGroup,
  ASSIGN_TO_CLAIM_BLOCK: AssignToClaimBlock,
  ASSIGN_TO_FACILITY: AssignToFacility,
  ADD_PRODUCTS_INSURED: AddProductsInsured,
  EDIT_RISK_QUOTE: EditRiskQuote,
  EDIT_ENDORSEMENT_QUOTE: EditEndorsementQuote,
  EDIT_ENDORSEMENT_QUOTE_PRO_RATA: EditEndorsementQuoteProRata,
  ASSIGN_TO_UNDERWRITER_GROUP: AssignToUnderwriterGroup,
  BDX_EXTRACTION: BDXExtraction,
  BULK_UPDATE_LAYER: BulkUpdateLayer,
  BULK_UPDATE_POLICY: BulkUpdatePolicy,
  CONFIRM: Confirm,
  CONFIRM_DELETE: ConfirmDelete,
  CONFIRM_DECLINE_RISK: ConfirmDecline,
  EXTEND_CANCEL_POLICY: ExtendCancelPolicy,
  CONFIRM_UNBOUND: ConfirmUnBound,
  CREATE_IN_WHITESPACE: CreateInWhitespace,
  DOCUMENT_UPLOAD: DocumentUpload,
  DOWNLOAD_BORDEREAUX: DownloadBordereaux,
  UPLOAD_BDX: UploadBDX,
  DOWNLOAD_FILES: DownloadFiles,
  EDIT_DEPARTMENT_MARKET: EditDepartmentMarket,
  EDIT_PLACEMENT_LAYER: EditPlacementLayer,
  EDIT_PLACEMENT_MARKETS_LAYERS: EditPlacementMarketsLayers,
  EDIT_POLICY: EditPolicy,
  EDIT_PLACEMENT: EditPlacement,
  EDIT_PRODUCTS_CLIENT: EditProductsClient,
  EDIT_PRODUCTS_INSURED: EditProductsInsured,
  EDIT_QUOTE: EditQuote,
  EDIT_TRIP_BROKERS: EditTripBrokers,
  MODELLING_TASK: ModellingTask,
  NEW_ENQUIRY: NewEnquiry,
  PASTE_FROM_EXCEL: PasteFromExcel,
  PLACEMENT_PDF: PlacementPDF,
  PORTFOLIO_MAP: PortfolioMap,
  SIGN_DOWN: SignDown,
  ADD_EDIT_REPORT: AddEditReport,
  ADD_EDIT_REPORT_GROUP: AddEditReportGroup,
  PRE_BIND_QUOTE: PreBindQuote,
  EDIT_FACILITY_USERS: EditFacilityUsers,
  UPLOAD_FACILITY_DOCUMENT: UploadFacilityDocument,
  ADD_RULE: AddRule,
  IMPORT_RULES: ImportRules,
  IMPORT_LIMITS: ImportBinderLimits,
  VALIDATE_FILE_PASSWORD: ValidateFilePassword,
  FACILITY_CONFIG: FacilityConfig,
  ADD_BINDER_LIMIT: AddBinderLimit,
  UPLOAD_BDX_COVERHOLDER: UploadBDXCoverHolder,
  ADD_CLAIM_BLOCK: AddClaimBlock,
  ADD_EDIT_CLAIMS_TASK: AddEditClaimsTask,
  UPLOAD_LINEAGE_REPORT: UploadLineageReport,
  REPORT: Report,
  UPLOAD_RULES: UploadRules,
} as const;
