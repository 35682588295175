import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import { Button } from 'components';

import { useGetRuleRowActions } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { ActionsPreview } from './ActionsPreview';
import { ConditionsPreview } from './ConditionsPreview';
import { RulePreviewSkeleton } from './RulePreviewSkeleton';

import styles from './RulePreview.styles';

export const RulePreview = ({ rule, bdxType, facility, isLoading, handleDeleteRule, ruleset }) => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();
  const dispatch = useDispatch();
  // TODO check in import rules preview if bdxType and facility are null
  const { data: ruleRowActions, isLoading: rowActionsLoading } = useGetRuleRowActions({ bdxType, facilityReference: facility.umr });

  const parsedRule =
    {
      ...rule,
      actions: rule?.actions?.map((action) => {
        const actionRow = ruleRowActions?.find((rowAction) => rowAction.action === action.operation);

        return {
          ...action,
          displayValue1: actionRow?.keyValueObjects?.find((keyValue) => keyValue.key === action.value1)?.value,
          displayValue2: actionRow?.keyValueObjects?.find((keyValue) => keyValue.key === action.value2)?.value,
        };
      }),
    } || null;

  const handleEditRule = () => {
    dispatch(
      showModal({
        component: 'ADD_RULE',
        props: {
          title: 'rules.editRule',
          fullWidth: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          maxWidth: 'xl',
          componentProps: {
            facility,
            bdxType,
            rule,
            tpaRuleset: ruleset,
            thirdPartyId: ruleset?.thirdPartyId,
          },
        },
      })
    );
  };

  return isLoading || rowActionsLoading ? (
    <RulePreviewSkeleton />
  ) : (
    <div className={classes.rule}>
      <ConditionsPreview conditionType={rule?.conditionType} conditions={rule?.conditions} />
      <ActionsPreview actions={parsedRule?.actions || rule?.actions} />

      <div className={classes.ruleControlWrapper}>
        <Button
          text={utils.string.t('rules.deleteRule')}
          icon={Delete}
          variant="contained"
          color="secondary"
          size="small"
          nestedClasses={{ btn: classes.ruleControl }}
          onClick={() => handleDeleteRule(rule, ruleset)}
        />
        <Button
          text={utils.string.t('rules.editRule')}
          icon={Edit}
          variant="contained"
          color="primary"
          size="small"
          nestedClasses={{ btn: classes.ruleControl }}
          onClick={handleEditRule}
        />
      </div>
    </div>
  );
};
