import { makeStyles } from '@material-ui/core/styles';

import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  currentEnv: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    color: 'white',
    width: 30,
    zIndex: -1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    padding: 1,
    top: 12,
    right: -25,
  },
}));

const env = ['DEV', 'QA', 'UAT'];

export const Env = () => {
  const classes = useStyles();

  if (utils.app.isEdgeProduction()) return null;

  if (utils.app.isEdgeUAT()) return <span className={classes.currentEnv}>UAT</span>;
  if (utils.app.isEdgeQA()) return <span className={classes.currentEnv}>QA</span>;

  return <span className={classes.currentEnv}>DEV</span>;
};
