import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// app
import { Skeleton } from 'components';
import styles from './DepartmentAccountsCalendarList.styles';
import { DepartmentAccountsCalendarListView } from './DepartmentAccountsCalendarList.view';
import { selectPlacementId, selectRefDataStatusesPlacement } from 'stores';
import { useGetDepartmentAccounts } from 'lib/placements';

import * as utils from 'utils';

// mui
import { makeStyles } from '@material-ui/core';

DepartmentAccountsCalendarList.propTypes = {
  rows: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
};

export default function DepartmentAccountsCalendarList({ cols, handlers, deptId, monthDetails }) {
  const [firstItem, setFirstItem] = useState(0);
  const placementSelectedId = useSelector(selectPlacementId);
  const refDataStatusesPlacement = useSelector(selectRefDataStatusesPlacement);
  const {
    data: items,
    isLoading,
    isError,
  } = useGetDepartmentAccounts({
    departmentId: deptId,
    params: {
      month: monthDetails.month,
      year: monthDetails.year,
    },
  });

  const today = utils.date.today();

  const classes = makeStyles(styles, { name: 'DepartmentAccountsCalendarTable' })({ wide: false });

  useEffect(() => {
    if (today === monthDetails.date && items?.length) {
      const firstItemToday = items.findIndex((item) => item.inceptionDate >= today);

      firstItemToday > 0 && setFirstItem(firstItemToday);
    }
  }, [today, monthDetails, items]);

  if (isLoading) {
    return (
      <div className={classes.monthBox} data-testid="department-accounts-list-loading">
        <Skeleton height={40} animation="wave" displayNumber={5} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.monthBox} data-testid="department-accounts-list-error">
        <Skeleton height={40} animation="wave" displayNumber={5} />
      </div>
    );
  }

  return (
    <DepartmentAccountsCalendarListView
      isLoading={isLoading}
      items={items}
      firstItem={firstItem}
      cols={cols}
      placementId={placementSelectedId}
      placementStatuses={refDataStatusesPlacement}
      handleClickRow={handlers.handleClickRow}
      handleDoubleClickRow={handlers.handleDoubleClickRow}
    />
  );
}
