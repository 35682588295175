import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_DETAILS, QB_RISK_QUOTES } from 'lib/quoteBind/queryKeys';

export const useQuoteResponse = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useQuoteResponse',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));
    dispatch(hideModal());

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, { id: res.riskId });
    queryClient.invalidateQueries(QB_RISK_QUOTES, res.riskId);

    dispatch(hideModal());
  };

  return useMutation(
    async ({ quoteId, riskId, effectiveFrom, effectiveTo, ...formData }) => {
      if (!riskId || !quoteId || !effectiveFrom || !effectiveTo) {
        throw new Error('Invalid id in useQuoteResponse');
      }
      const payload = {
        effectiveFrom: utils.date.startOf(effectiveFrom),
        effectiveTo: utils.date.startOf(effectiveTo),
        ...formData,
      };
      const result = await quoteBindAxios.post(`/quotes/${quoteId}/response`, payload);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
