import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification, hideModal } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

import { axiosInstance } from '../api';
// app
import { CLAIMS_TASKS } from '../queryKeys';

// risks
export const useDeleteTask = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'claimsTaskManagement.mutations.useDeleteTask',
    };

    dispatch(enqueueNotification('claimsTaskManagement.notification.useDeleteTask.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries([CLAIMS_TASKS]);

    dispatch(hideModal());
    dispatch(enqueueNotification('claimsTaskManagement.notification.useDeleteTask.success', 'success'));
  };

  return useMutation(
    async ({ id }) => {
      if (!id) {
        throw new Error('Invalid id');
      }

      const result = await axiosInstance.delete(`tm/v1/tasks/${id}`);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
