import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_CLAIM_BLOCK } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useCreateClaimBlock = ({ umr }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccess = (data: IClaimBlock) => {
    queryClient.setQueryData([FACILITY_CLAIM_BLOCK, umr], (oldData: IClaimBlock[]) => [...(oldData ?? []), data]);

    dispatch(enqueueNotification('notification.binderManagement.createClaimBlockSuccess', 'success'));
  };

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useCreateClaimBlock',
    warning: 'notification.binderManagement.createClaimBlockFail',
  });

  return useMutation<IClaimBlock, AxiosError, IClaimBlockDTO>(
    async (config) => {
      const { data } = await binderManagementFacilitiesAxios.post('claimblocks', config);

      return data;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
