import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification, hideModal } from 'stores';

import { binderManagementFacilitiesDocumentsAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_DOCUMENT_FOLDERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useUploadFacilityDocument = ({ umr }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useUploadFacilityDocument',
    warning: 'notification.binderManagement.documentUploadFail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries([FACILITY_BINDER_DOCUMENT_FOLDERS, umr]);

    dispatch(enqueueNotification('notification.binderManagement.documentUpload', 'success'));

    dispatch(hideModal());
  };

  return useMutation<IFacilityDocumentResponse, AxiosError, IUploadFacilityDocumentDTO>(
    async ({ file, name }) => {
      const form = new FormData();
      form.append('facilityReference', umr);
      form.append('folderName', name);
      form.append('file', file);

      return binderManagementFacilitiesDocumentsAxios.post('', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
