import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

// mui
import { Box, IconButton, Typography, useTheme, Chip } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// app
import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';
import { Button } from 'components';
import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import config from 'config';
import { getBdxStatus } from '../utils';

const VALID_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

const CARD_MOTION = {
  rest: {
    scale: 1,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    scale: 1.05,
    opacity: 1,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

export const BDXCardItem = ({ item, uploadBDX, bdxInfo, binderType, classes }) => {
  const { facility, binderId } = useContext(FacilityContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const confirmCancel = true;

  const [isFlipped, setIsFlipped] = useState(false);

  const { submittedOn } = item?.submissions?.[item?.submissions?.length - 1] || {};
  const hasUpload = item?.submissions?.length > 0;
  const status = item?.submissions?.length > 0 ? getBdxStatus(item.submissions) : '';

  const statusBackgroundColor = theme.palette.bm.status[`${status?.toLowerCase()}_bdx`]
    ? theme.palette.bm.status[`${status?.toLowerCase()}_bdx`]
    : theme.palette.primary.main;

  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

  const handleDropFile = (files) => {
    const file = files[0];

    const fileExt = file?.name.includes('.') ? file.name.split('.').pop > 1 : '';

    if (VALID_TYPES.includes(file?.type) || fileExt === 'csv') {
      uploadBDX(bdxInfo.yearMonthSubmission, item.bdxType, files[0], null, handleUploadSuccess, confirmCancel);
    } else {
      dispatch(enqueueNotification('notification.bdx.fileError', 'warning'));
    }
  };

  const goToPage = () => {
    const url = `${config.routes.binders.root}/${binderType}/${binderId}/${config.routes.binders.facilityDetails}/${facility.umr}/${item.bdxType}/${bdxInfo.yearMonthSubmission}`;
    history.push(url);
  };

  const handleUploadSuccess = async () => {
    goToPage();
  };

  const { getRootProps, isDragActive } = useDropzone({ onDrop: handleDropFile });

  return (
    <motion.div
      variants={CARD_MOTION}
      whileHover="hover"
      animate="rest"
      initial="rest"
      onMouseEnter={status !== 'PENDING' ? () => setIsFlipped(true) : () => {}}
      onMouseLeave={status !== 'PENDING' ? () => setIsFlipped(false) : () => {}}
      onClick={
        hasUpload ? goToPage : () => uploadBDX(bdxInfo.yearMonthSubmission, item.bdxType, null, null, handleUploadSuccess, confirmCancel)
      }
      className={classes.cardItem}
      style={{
        borderTop: `0.2rem solid ${statusBackgroundColor}`,
      }}
      data-testid={`BDXCardItem-${item.bdxType}`}
    >
      <div>
        <Typography variant="h3" classes={{ root: classes.cardItemType }}>
          {item.bdxType}
        </Typography>
      </div>

      {submittedOn ? (
        <div className={classes.submitted}>
          <IconButton className={classes.fileIcon}>
            <InsertDriveFileIcon />
          </IconButton>
          <Typography variant="h5" classes={{ root: classes.cardDate }}>
            {utils.date.fromISOtoDate(submittedOn, 'DD MMM, YYYY')}
          </Typography>
        </div>
      ) : (
        <div className={classes.fileDragWrapper}>
          <div className={classNames(classes.dragArea, { [classes.dragOver]: isDragActive })} {...getRootProps()} data-form-type="file">
            <CloudUploadIcon />
          </div>
        </div>
      )}
      {isFlipped ? (
        <Box>
          <Button
            size="xsmall"
            data-testid="bdxInfo-status"
            icon={hasUpload ? ArrowForwardIcon : null}
            iconPosition="right"
            text={hasUpload ? 'Review' : 'Upload'}
            style={{
              backgroundColor: statusBackgroundColor,
              color: statusColor,
              borderRadius: '10px',
              fontSize: '10px',
            }}
          />
        </Box>
      ) : (
        <Box>
          {hasUpload ? (
            <Chip
              size="small"
              label={utils.string.capitalise(status)}
              status={status}
              data-testid="bdxInfo-status"
              style={{
                backgroundColor: statusBackgroundColor || 'white',
                color: statusColor,
                fontSize: '11px',
                zIndex: 1,
                width: 'fit-content',
              }}
            />
          ) : (
            <Button size="xsmall" data-testid="bdxInfo-status" variant="outlined" color="primary" text={`Upload`} />
          )}{' '}
        </Box>
      )}
    </motion.div>
  );
};
