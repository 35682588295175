import React, { useEffect, useMemo, useState } from 'react';

import { useGetReports } from 'lib/binderManagement';
import { ReportsView } from './Reports.view';
import { ReportsSkeleton } from './ReportsSkeleton';

import { Empty } from 'components';
import * as utils from 'utils';
import { ReactComponent as FlashWarningSVG } from 'assets/svg/flash-warning.svg';
import { useParams } from 'react-router';

const model = {
  BackgroundType: {
    Default: 0,
    Transparent: 1,
  },
  TokenType: {
    Add: 0,
    Embed: 1,
  },
  DisplayOption: {
    ActualSize: 2,
    FitToPage: 0,
    FitToWidth: 1,
  },
};

export const Reports = () => {
  const { data: report, isLoading } = useGetReports({ reportType: 'SUMMARY' });
  const { facilityUMR } = useParams();

  const [config, setConfig] = useState();

  const embedConfiguration = useMemo(() => {
    const obj = {
      tokenType: model.TokenType.Embed,
      pageView: model.DisplayOption.FitToPage,
      settings: {
        background: model.BackgroundType.Default,
        filterPaneEnabled: false,
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
      },
      filters: [
        {
          $schema: 'http://powerbi.com/product/schema#advanced',
          target: {
            table: 'Policies',
            column: 'UniqueMarketRef',
          },
          operator: 'In',
          values: [facilityUMR],
        },
      ],
    };
    return obj;
  }, [facilityUMR]);

  useEffect(() => {
    setConfig({
      ...embedConfiguration,
      type: 'report',
      id: report?.reportId,
      embedUrl: report?.embedUrl,
      accessToken: report?.embedToken,
    });
  }, [report, embedConfiguration]);

  const NoResult = () => <Empty width={400} title={utils.string.t('app.noReports')} icon={<FlashWarningSVG />} padding />;

  if (isLoading) return <ReportsSkeleton />;

  if (!report) return <NoResult width={400} title={utils.string.t('app.noReports')} icon={<FlashWarningSVG />} padding />;

  return <ReportsView config={config} />;
};
