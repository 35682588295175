import { useMutation } from 'react-query';

import { binderManagementFileInputAxiosV2 } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useUploadBDXCoverHolderDetails = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useUploadBDXDetails',
    warning: 'notification.bdx.uploadFail',
  });

  return useMutation(
    async ({
      sheetDetails,
      password,
      bdxType,
      thirdPartyId,
      operationalClientId,
      requestId,
      // replacesRequest, ignore for now
    }) => {
      const payload = {
        requestId,
        sheetDetails,
        password,
        bdxType,
        thirdPartyId,
        operationalClientId,
      };

      const data = await binderManagementFileInputAxiosV2.post('details', payload, {
        headers: {
          'Content-type': 'application/json',
        },
      });

      return data;
    },
    {
      onError: handleError,
    }
  );
};
