import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { CurrencyAggregate, ICurrencyAggregateHooks, TProcessingAggregate } from '../CurrencyAggregate';

import { useGroupTitleStyle } from './GroupTitle.style';

interface IGroupTitleProps {
  title: string;
  aggregates: TProcessingAggregate[];
  hooks: ICurrencyAggregateHooks;
}

export const GroupTitle: FC<IGroupTitleProps> = ({ title, aggregates = [], hooks }) => {
  const classes = useGroupTitleStyle();

  if (aggregates.filter(Boolean)?.length === 0) {
    return (
      <Typography variant="h3" className={classes.groupTitle}>
        {title}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      </div>
      {aggregates.map((item) => (
        <CurrencyAggregate key={item.settlementCurrency} aggregate={item} hooks={hooks} />
      ))}
    </div>
  );
};
