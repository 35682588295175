import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, List, ListItem, TextField, ListItemText, ListItemSecondaryAction, useTheme, Divider } from '@material-ui/core';
import { Check, NotInterested } from '@material-ui/icons';

import * as utils from 'utils';

export type TUser = {
  id: number;
  programmesUserId: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  fullName: string;
  contactPhone: string;
  userName: string | null;
  emailId: string;
  role: string;
  isSystemUser: boolean;
  isAdmin: boolean;
  isReportAdmin: boolean;
  addedBinderManagement: boolean | null;
  addedReporting: boolean | null;
  active: boolean;
};

type TUserSearchProps = {
  options: TUser[];
  handleChange: (newValue: TUser | null) => void;
  disabled?: boolean;
};

export const UserSearch = ({ options, handleChange, disabled }: TUserSearchProps) => {
  const theme = useTheme();

  return (
    <Autocomplete
      id="user-search"
      data-testid="user-search"
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => `${option.fullName} (${option.emailId})`}
      onChange={(_, newValue) => {
        if (typeof newValue === 'object') {
          handleChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          return option.fullName.toLowerCase().includes(params.inputValue.toLowerCase());
        });

        return filtered;
      }}
      style={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      renderOption={(option, { inputValue }) => {
        const { fullName, emailId } = option;

        const matches = match(fullName, inputValue);
        const parts = parse(fullName, matches);

        const title = (
          <Box>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}{' '}
            ({emailId})
          </Box>
        );

        return (
          <ListItem
            key={option.id}
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(1),
              marginBottom: theme.spacing(1),
              width: '100%',
            }}
          >
            <ListItemText primary={title} />
          </ListItem>
        );
      }}
    />
  );
};

type UserInfoProps = {
  user: TUser;
};

const StyledListItem = ({ children }: { children: React.ReactNode }) => (
  <ListItem
    dense
    style={{
      marginLeft: 0,
      paddingLeft: 0,
      width: '100%',
    }}
  >
    {children}
  </ListItem>
);

export const UserInfo = ({ user }: UserInfoProps) => {
  const theme = useTheme();
  const { fullName, emailId, role, isReportAdmin, addedBinderManagement, addedReporting, active, programmesUserId } = user;

  return (
    <List
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
      }}
    >
      <StyledListItem>
        <ListItemText
          primary={fullName}
          primaryTypographyProps={{
            style: {
              fontWeight: 600,
              fontSize: 16,
            },
          }}
        />
      </StyledListItem>
      <StyledListItem>
        <ListItemText
          primary={utils.user.getRoleString(role)}
          primaryTypographyProps={{
            style: {
              fontWeight: 600,
            },
          }}
        />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary={emailId} />
      </StyledListItem>
      <Divider
        style={{
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      />
      <StyledListItem>
        <ListItemText primary="Active" />
        <PermissionStatus status={active} />
      </StyledListItem>

      <StyledListItem>
        <ListItemText primary="Report Admin" />
        <PermissionStatus status={isReportAdmin} />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary="Reporting" />
        <PermissionStatus status={addedReporting} />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary="Programme Management" />
        <PermissionStatus status={addedBinderManagement} />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary="Quote & Bind" />
        <PermissionStatus status={!!Boolean(programmesUserId)} />
      </StyledListItem>
    </List>
  );
};

const PermissionStatus = ({ status }: { status: boolean }) => {
  const theme = useTheme();

  return (
    <ListItemSecondaryAction>
      {status ? (
        <Check
          style={{
            color: theme.palette.success.main,
            fontSize: 16,
          }}
        />
      ) : (
        <NotInterested style={{ color: theme.palette.error.main, fontSize: 16 }} />
      )}
    </ListItemSecondaryAction>
  );
};
