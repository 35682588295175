import { makeStyles } from '@material-ui/core';

export const useValidateFilePasswordClasses = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 164,
    },
    icon: {
      height: 20,
    },
    iconButton: {
      padding: theme.spacing(0.5),
      marginRight: 0,
    },
  }),
  { name: 'ValidateFilePassword' }
);
