import moment from 'moment';

const utilsDate = {
  toISOString: (date) => {
    return moment(date).toISOString();
  },
  formatDate: (format = 'YYYY-MM-DD') => {
    return moment().format(format);
  },
  formatDateWithParameter: (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
  },
  formatISO: (date) => {
    // input:   dd/mm/yyyy hh:mm:ss  (date format from API endpoints)
    // output:  yyyy-mm-dd hh:mm:ss  (moment expects ISO 8601 date format)
    // this method transforms the dates so that JS/moment can handle them

    // quit if date is not defined
    if (!date || date.length <= 0) return date;

    const partDate = date.toString().split(/[\sT]+/)[0];
    const partTime = date.toString().split(/[\sT]+/)[1];

    // quit if date part doesn't have day, month and year
    if (partDate.split('/').length < 3) return date;

    const partDay = partDate.split('/')[0];
    const partMonth = partDate.split('/')[1];
    const partYear = partDate.split('/')[2];

    return partYear + '-' + partMonth + '-' + partDay + (partTime ? ' ' + partTime : '');
  },
  formatToTime(date) {
    return moment(date).format('HH:mm');
  },
  formatHourMinute(hourMinute) {
    const [hour, minute] = hourMinute.split(':');

    return moment().hour(hour).minute(minute);
  },
  fromISOtoDate: (isoDate, format = 'DD-MM-YYYY') => {
    const dateString = moment(isoDate).format(format);
    return dateString;
  },
  fromNow: (date, translations) => {
    const fromNow = moment(date).fromNow();

    // TODO added on 13/11/2019: add default translations

    return moment(date).calendar(null, {
      sameDay: () => `[${fromNow}]`,
      lastWeek: () => `[${fromNow}]`,
      lastDay: `[${translations.yesterday || 'Yesterday'}]`,
      nextDay: `[${translations.tomorrow || 'Tomorrow'}]`,
      nextWeek: () => `[${fromNow}]`,
      sameElse: () => `[${fromNow}]`,
    });
  },

  fromNowString: (date) => {
    return moment(date).fromNow();
  },

  timestamp: (date) => {
    // abort
    if (!date || date.length <= 0) return 0;

    const newDate = date && new Date(date);
    const timestamp = newDate && newDate.getTime();

    return timestamp || 0;
  },

  today: (format = 'YYYY-MM-DD') => {
    return moment().format(format);
  },
  tomorrow: () => {
    return moment().add(1, 'days');
  },
  addDays: (date, days) => {
    return moment(date).add(days, 'days');
  },
  addMonths: (date, months) => {
    return moment(date).add(months, 'months');
  },
  addYears: (date, years) => {
    return moment(date).add(years, 'years');
  },
  monthDetails: (date, format = 'YYYY-MM-DD') => {
    return {
      monthName: moment(date).format('MMMM'),
      month: moment(date).format('M'),
      year: moment(date).format('YYYY'),
      date: moment(date).format(format),
    };
  },
  previousMonth: (date) => {
    const startLastMonth = moment(date).subtract(1, 'months').startOf('month');

    return utilsDate.monthDetails(startLastMonth);
  },
  nextMonth: (date) => {
    const startNextMonth = moment(date).add(1, 'months').startOf('month');

    return utilsDate.monthDetails(startNextMonth);
  },

  diffDays: (firstDate, secondDate) => {
    return moment(firstDate).diff(moment(secondDate), 'days');
  },

  datePercent: (startDate, endDate, currentDate) => {
    const mStart = moment(startDate);
    const mEnd = moment(endDate);
    const mData = moment(currentDate);

    const percentile = (100.0 * mData.diff(mStart)) / mEnd.diff(mStart);

    return percentile;
  },
  isBefore: (date) => {
    return moment(date).isBefore(moment());
  },
  isBetween: (date, startDate, endDate) => {
    return moment(date).isBetween(startDate, endDate);
  },
  isValid: (date) => {
    return moment(date).isValid();
  },
  startOf: (date, format = 'day') => {
    return moment(date).utc(true).startOf(format).toISOString();
  },
  endOf: (date, format = 'day') => {
    return moment(date).utc(true).endOf(format).toISOString();
  },
  monthsBetween: (startDate, endDate, precise = false) => {
    const start = moment(startDate);
    const end = moment(endDate);

    return end.diff(start, 'months', precise);
  },
  isFuture: (date) => {
    return moment(date).isAfter(moment());
  },
  isSameOrBefore: (date, otherDate) => {
    return moment(date).isSameOrBefore(otherDate);
  },
  isSameOrAfter: (date, otherDate) => {
    return moment(date).isSameOrAfter(otherDate);
  },
  isValidTime: (time) => {
    return moment(time, 'HH:mm').isValid();
  },
};

export default utilsDate;
