import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getRiskList(params, token) {
  const response = await api(token).get('risks', {
    params: {
      page: params.page || 1,
      size: params.size || 12,
      orderBy: params.orderBy || 'id',
      direction: params.direction || 'desc',
      ...params,
    },
  });
  return response.data;
}

export function useGetRiskList(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (params, token) => await getRiskList(params, token), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
