import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_UNDERWRITER_GROUPS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetUnderwriterGroups = ({ facilityReference }, options) => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetUnderwriterGroups' });

  return useQuery(
    [BDX_UNDERWRITER_GROUPS, facilityReference],
    async () => {
      if (!facilityReference) throw new Error('Missing required params');

      const result = await binderManagementBDXAxios.get(`uwGroups/${facilityReference}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
