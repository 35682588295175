import { FC, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Star, StarBorder, Create } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Accordion, Button, FormText } from 'components';
import { binderLimitStyles } from './BinderLimit.styles';
import { BinderLimitPreview } from '../BinderLimitPreview';
import * as utils from 'utils';
import { QuickEdit } from './QuickEdit';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm } from 'react-hook-form';
import { useCreateLimit } from 'lib/binderManagement';
import { EDIT_LIMIT, TBinderLimitAction } from 'consts';
import { IBinderLimitProps } from './BinderLimit.types';

export const BinderLimit: FC<IBinderLimitProps> = ({ limit: limitData, facility, isLoading, handleFavourites, handleDeleteLimit }) => {
  const classes = binderLimitStyles();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutateAsync: addLimit } = useCreateLimit({ facility });

  const methods = useForm({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const handleClickFavourites = (event) => {
    event.stopPropagation();
    handleFavourites(limitData);
  };

  const handleClickQuickIcons = (event) => {
    event.stopPropagation();
    setIsEditing(!isEditing);
  };

  const stopDefault = (event) => {
    event.stopPropagation();
  };

  const onSubmitQuickEdit = async (event) => {
    event.stopPropagation();
    const formData = methods.getValues();
    const { name, limit, limitAlertAtPercentage } = formData;

    const updatedLimit: IBinderLimitDTO = {
      conditionType: '',
      conditions: [],
      sumColumns: [],
      ...limitData,
      name,
      limit: Number(limit),
      limitAlertAtPercentage: Number(limitAlertAtPercentage),
    };

    const action: TBinderLimitAction = EDIT_LIMIT;

    setIsEditing(!isEditing);
    await addLimit({ data: updatedLimit, action });
  };

  const {
    name,
    limit,
    limitAlertAtPercentage,
    currency,
    valueToDate: initialValueToDate,
    processing,
    alertPercentageReached,
    limitReached,
  } = limitData;

  const valueToDate = processing ? 'Awaiting Processing' : initialValueToDate;
  const infoIconColor = alertPercentageReached && !limitReached ? 'iconIsWarning' : limitReached ? 'iconIsAlert' : null;
  const hasAlert = alertPercentageReached || limitReached ? 'iconIsVisible' : 'iconIsHidden';

  const quickLimitData = { name, limit, limitAlertAtPercentage, currency, valueToDate };
  const quickEditData = { name, limit, limitAlertAtPercentage };

  const Title: FC = () => (
    <Table className={classes.tableOverride}>
      <TableBody>
        <TableRow>
          {isEditing ? (
            <>
              {Object.keys(quickEditData).map((data) => (
                <TableCell className={classes.tableCellOverride} align="center" onClick={stopDefault} key={data}>
                  <QuickEdit fieldName={data} value={limitData[data]} methods={methods} />
                </TableCell>
              ))}
              <TableCell
                classes={{
                  root: classes.tableCellOverride,
                }}
              >
                <FormText value={currency} disabled={true} />
              </TableCell>
              <TableCell className={classes.tableCellOverride}>
                <FormText value={valueToDate} disabled={true} />
              </TableCell>
            </>
          ) : (
            Object.keys(quickLimitData).map((item, index) => {
              const tableCellAlert =
                (item === 'limit' && limitReached) || (item === 'limitAlertAtPercentage' && alertPercentageReached && !limitReached);
              return (
                <TableCell
                  className={classes.tableCellOverride}
                  style={{
                    width: index === 0 ? '13%' : '',
                    ...(tableCellAlert && {
                      position: 'relative',
                      left: '-1rem',
                    }),
                  }}
                  align="center"
                  key={item}
                >
                  {tableCellAlert && (
                    <IconButton className={classes[hasAlert]} disabled={true}>
                      <InfoOutlinedIcon className={classes[infoIconColor]} />
                    </IconButton>
                  )}
                  {typeof quickLimitData[item] == 'number'
                    ? utils.string.t('format.number', {
                        value: { number: quickLimitData[item], default: '-' },
                      })
                    : quickLimitData[item]}
                </TableCell>
              );
            })
          )}
          <TableCell className={classes.tableCellOverride} align="center">
            {isEditing ? (
              <>
                <Button icon={ClearIcon} size="xsmall" variant="contained" onClick={handleClickQuickIcons} style={{ marginRight: 8 }} />
                <Button icon={SaveIcon} size="xsmall" variant="contained" onClick={onSubmitQuickEdit} color="primary" />
              </>
            ) : (
              <>
                <IconButton onClick={handleClickQuickIcons} size="small" data-testid="edit" disabled={isLoading}>
                  <Create
                    classes={{
                      root: classes.iconOverride,
                    }}
                  />
                </IconButton>
                <IconButton onClick={handleClickFavourites} size="small" data-testid="favorite" disabled={isLoading}>
                  {limitData?.favourite ? (
                    <Star className={classes.iconOverride} data-testid="star" />
                  ) : (
                    <StarBorder className={classes.iconOverride} data-testid="starBorder" />
                  )}
                </IconButton>
              </>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <div className={classes.limit}>
      <Accordion titleComponent={<Title />} rotateIcons={false}>
        <BinderLimitPreview limit={limitData} facility={facility} isLoading={isLoading} handleDeleteLimit={handleDeleteLimit} />
      </Accordion>
    </div>
  );
};
