// usePostEndorsement
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { RISKS, QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';

// risks
export const usePostEndorsement = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.usePostEndorsement',
    };

    dispatch(enqueueNotification('notification.addEndorsement.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(RISKS, res.riskId);
    queryClient.invalidateQueries(QB_RISK_ENDORSEMENTS, res.riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.addEndorsement.success', 'success'));
  };

  return useMutation(
    async (params) => {
      const { values, definitions, riskId, riskData, effectiveDate } = params;

      if (!values || isEmpty(values) || !utils.generic.isValidArray(definitions)) {
        throw new Error('Invalid params');
      }

      const riskDataParsed = {
        ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions),
      };

      const { comments, inceptionDate, ...risk } = riskDataParsed;

      const data = {
        risk: {
          ...riskData,
          ...risk,
        },
        riskId,
        comments,
        effectiveFrom: utils.date.startOf(effectiveDate, 'day'),
      };

      const result = await quoteBindAxios.post(`/risks/${riskId}/endorsements`, data);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
