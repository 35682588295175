import { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

import { Button, FormSelect } from 'components';
import { Condition, DuplicateRowCondition } from './components';
import * as utils from 'utils';
import { useConditionsStyles } from './Conditions.styles';
import { isConditionRegular, getCustomCondition } from './utils';

export const Conditions = ({ methods, fields, ruleColumns, rule }) => {
  const classes = useConditionsStyles();
  const { control, formState } = methods;
  const {
    fields: conditions,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'conditions',
  });

  const removeCondition = (index) => {
    remove(index);
  };

  const addCondition = useCallback(() => {
    append({
      field: '',
      operation: '',
    });
  }, [append]);

  useEffect(() => {
    if (rule) {
      const regularRules = rule.conditions
        .filter(isConditionRegular)
        .map((condition) => ({ ...condition, field: ruleColumns?.find(({ name }) => condition.field === name) }));

      if (regularRules.length > 0) {
        append(regularRules);
      }

      rule.conditions
        .filter((condition) => !isConditionRegular(condition))
        .forEach((condition) => methods.setValue(...getCustomCondition(condition)));
    }
  }, [rule, append, ruleColumns, methods]);

  return (
    <div className={classes.conditions}>
      <DuplicateRowCondition control={control} name="duplicate" error={methods.formState.errors.duplicate} />
      <Grid container spacing={1} className={classes.conditionStatement}>
        <Grid item>
          <Typography variant="subtitle1">{utils.string.t('rules.if')}</Typography>
        </Grid>
        <Grid item>
          <FormSelect
            {...utils.form.getFieldProps(fields, 'conditionType')}
            rules={{ required: true }}
            control={control}
            error={formState.errors.conditionType}
            testid="conditionType"
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{utils.string.t('rules.conditionStatement')}</Typography>
        </Grid>
        <div className={classes.button}>
          <Button
            text={utils.string.t('rules.addCondition')}
            onClick={addCondition}
            icon={PostAdd}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </Grid>
      {conditions?.map((condition, conditionIndex) => (
        <Condition
          key={condition.id}
          condition={condition}
          prefix={`conditions.${conditionIndex}`}
          fields={fields}
          methods={methods}
          handleRemoveCondition={() => removeCondition(conditionIndex)}
          ruleColumns={ruleColumns}
        />
      ))}
      {conditions?.length === 0 && (
        <Typography variant="h2" className={classes.noConditions}>
          {utils.string.t('rules.noConditions')}
        </Typography>
      )}
    </div>
  );
};
