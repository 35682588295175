import { useState } from 'react';

export const useBDXProcessingPagination = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setSize(rowsPerPage);
  };

  return { page, size, handleChangePage, handleChangeRowsPerPage };
};
