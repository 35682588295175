import { makeStyles } from '@material-ui/core';

export const addBinderLimitStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    overflowY: 'auto',
  },

  nameWrapper: {
    flex: 1,
    maxWidth: '50%',
    marginRight: 'auto',
  },
  priorityWrapper: {
    maxWidth: 100,
  },

  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  skeletonOperation: {
    margin: theme.spacing(2, 0),
  },

  skeletonStatement: {
    alignItems: 'center',
  },

  button: {
    marginLeft: 'auto',
  },

  operation: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));
