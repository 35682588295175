import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// mui
import { makeStyles } from '@material-ui/core';

// app
import { Button, FormContainer, FormFields, FormActions, FormText } from 'components';
import * as utils from 'utils';

ConfirmUnBound.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  effectiveTo: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
  },
}));

export default function ConfirmUnBound({ submitHandler, handleClose, policyNumber, hint = '' }) {
  const classes = useStyles();
  const regexDelete = new RegExp(`^${policyNumber}$`);

  const fields = [
    {
      name: 'deleteConfirm',
      type: 'text',
      value: '',
      validation: Yup.string()
        .matches(regexDelete, `Please type ${policyNumber} to confirm`)
        .required(`Please type ${policyNumber} to confirm`),
      label: (
        <span>
          Please type <b>{policyNumber}</b> to confirm
        </span>
      ),
      hint: hint,
      muiComponentProps: {
        autoComplete: 'off',
        autoFocus: true,
      },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" data-testid="form-confirmDelete">
        <FormFields type="dialog">
          <FormText {...utils.form.getFieldProps(fields, 'deleteConfirm')} control={control} error={errors.deleteConfirm} />
        </FormFields>

        <FormActions type="dialog">
          <Button text={utils.string.t('app.close')} variant="text" disabled={formState.isSubmitting} onClick={handleClose} />

          <Button
            text={utils.string.t('app.confirm')}
            onClick={handleSubmit(submitHandler)}
            disabled={formState.isSubmitting}
            color="primary"
            danger
          />
        </FormActions>
      </FormContainer>
    </div>
  );
}
