import { makeStyles } from '@material-ui/core';

export const useHeaderSelectClasses = makeStyles(
  (theme) => ({
    commentsBox: {
      maxWidth: 600,
      padding: theme.spacing(2.5),
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingBottom: 0,
    },
    HeaderRowTitle: {
      paddingBottom: theme.spacing(2),
    },
    comment: {
      display: 'inline-flex',
      alignItems: 'center',
      width: 16,
      height: 16,
      marginTop: 4,
      color: theme.palette.neutral.main,
    },
    formContainer: {
      alignItems: 'center',
    },
  }),
  { name: 'HeaderSelect' }
);
