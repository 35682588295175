import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_EXCEPTIONS_COUNT } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetExceptionsCount = ({ bdxDocumentsInfo, requestId }, options) => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetExceptionsCount' });

  return useQuery(
    [BDX_EXCEPTIONS_COUNT, requestId, bdxDocumentsInfo],
    async () => {
      if (requestId) {
        const url = `exceptions/${requestId}`;
        const result = await binderManagementBDXAxios.get(url);
        return result?.data;
      }

      const { facilityReference, yearMonthSubmission, bdxType } = bdxDocumentsInfo || {};

      if (!facilityReference || !yearMonthSubmission || !bdxType) throw new Error('Missing required params', bdxDocumentsInfo);

      const url = `exceptions/${facilityReference}/${yearMonthSubmission}/${bdxType}`;

      const result = await binderManagementBDXAxios.get(url);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
      ...options,
    }
  );
};
