import { useAuth0 } from '@auth0/auth0-react';

import { App, Loader } from 'components';
import Login from 'pages/Login/Login';

import { sec } from 'utils/auth0/auth0';
import * as utils from 'utils';

const EdgeApp = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);
  sec.setLogout(() => logout({ logoutParams: { returnTo: window.location.origin } }));

  if (isLoading) {
    return <Loader absolute label={utils.string.t('app.authenticating')} />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return <App />;
};

export default EdgeApp;
