import { makeStyles } from '@material-ui/core';

export const useFacilitiesStyles = makeStyles(
  (theme) => ({
    // card view
    cardWrapper: {
      width: '100%',
    },
    card: {
      overflow: 'hidden',
      color: 'white',
      borderRadius: 10,
      boxShadow: theme.shadows[2],
      position: 'relative',
      height: '100%',
      padding: 0,
      minWidth: 'fit-content',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
    },
    cardLink: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
      backgroundColor: '#fafafa',
      minHeight: '7vh',
    },
    cardHeaderContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardHeaderTitle: {
      margin: 0,
      paddingRight: '0.5rem',
      width: '100%',
      textAlign: 'left',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '1.5em',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    cardHeaderSubtitle: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    cardHeaderLogo: {
      width: 'auto',
      maxWidth: '5rem',
    },
    cardContent: {
      paddingLeft: '16px!important',
      paddingTop: '16px!important',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      paddingBottom: '16px!important',
    },
    cardInfoLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.75),
    },
    cardActions: {
      padding: '16px!important',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    cardActionsContent: {
      height: 24,
      display: 'flex',
      position: 'relative',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    cardActionsButton: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'start',
    },
    infoLeft: {
      color: 'black!important',
      fontWeight: 600,
      width: '40%',
      fontSize: 13,
    },
    infoRight: {
      color: 'black!important',
      fontWeight: 500,
      width: '50%',
      marginLeft: 10,
      marginTop: 0,
      fontSize: 13,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    chip: {
      fontSize: '11px',
      marginLeft: 10,
      zIndex: 1,
    },
    riskCode: {
      display: 'block',
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
    },

    // table view
    row: {
      textDecoration: 'none',
    },
    loadingCell: {
      padding: theme.spacing(0, 2),
    },

    tableChip: {
      fontSize: '11px',
      paddingTop: '2px',
      paddingBottom: '2px',
      width: '100%',
      height: 'auto',
    },
  }),
  { name: 'Facilities' }
);
