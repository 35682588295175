import { FC, useContext } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion } from 'components';
import { TGetHandleAssignToBlock } from 'modules/Binders';

import { BDX_TYPE_CLAIM } from 'consts';
import { useGetBdxClaimData } from 'lib/binderManagement';

import { useHandleDownloadClaims, useHandleSubmitClaims, useHandleSubmitClaimsManually } from '../hooks';
import { ProcessingContext } from '../Processing.context';
import { useBDXProcessingAccordion, useBDXProcessingPagination } from '../utils';

import { TAssignComponent } from './AssignToBlock';
import { GroupTitle } from './GroupTitle';
import { SummaryTable } from './SummaryTable';

interface IClaimBlockProps {
  claimBlock: IClaimBlock;
  getHandleAssign: TGetHandleAssignToBlock;
  AssignComponent?: TAssignComponent;
  aggregates?: IBDXClaimAggregate[];
}

export const ClaimBlock: FC<IClaimBlockProps> = ({ claimBlock, getHandleAssign, AssignComponent, aggregates }) => {
  const { page, size, handleChangePage, handleChangeRowsPerPage } = useBDXProcessingPagination();
  const { bdxDocumentsInfo } = useContext(ProcessingContext);

  const {
    data: claimData,
    isLoading,
    isFetching,
    isError,
  } = useGetBdxClaimData({
    bdxDocumentsInfo: {
      facilityReference: bdxDocumentsInfo.facilityReference,
      yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
      bdxType: BDX_TYPE_CLAIM,
    },
    claimGroup: claimBlock?.ucr,
    page,
    size,
  });

  const { expanded, handleChange } = useBDXProcessingAccordion(claimData?.numberOfElements);

  if (claimBlock.ucr === null && claimData?.numberOfElements === 0) return null;

  return (
    <Accordion
      titleComponent={
        <GroupTitle
          aggregates={aggregates}
          title={claimBlock.name}
          hooks={{
            useHandleDownload: useHandleDownloadClaims,
            useHandleSubmit: useHandleSubmitClaims,
            useHandleSubmitManually: useHandleSubmitClaimsManually,
          }}
        />
      }
      icon={isLoading || isFetching ? <CircularProgress size={24} /> : <ExpandMoreIcon />}
      disabled={isLoading || claimData?.numberOfElements === 0}
      expanded={expanded}
      onChange={handleChange}
      type="solid"
      margin={1}
      testid="claim-block"
    >
      <Box style={{ padding: 0, width: '100%' }}>
        <SummaryTable
          data={claimData}
          isLoading={isLoading || isFetching}
          isError={isError}
          size={size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          AssignComponent={AssignComponent}
          getHandleAssign={getHandleAssign}
        />
      </Box>
    </Accordion>
  );
};
