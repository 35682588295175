import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

/**
 * Creates error handler to be used in axios fallback
 *
 * @param {string} file Unique identifier of area
 * @param {string} message Additional info about error
 * @returns Error handler callback
 */
export const getHandleError =
  (file, message = '') =>
  (err) => {
    const errorParams = { file, message };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };
