let getAccessTokenSilently = null;
let logout = null;

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func) => (getAccessTokenSilently = func),
  logoutAuth0: () => logout,
  setLogout: (func) => (logout = func),
};

export const accessToken = async () => {
  const token = await sec.getAccessTokenSilently()();
  return token;
};

export const logoutAuth0 = () => {
  return sec.logoutAuth0()();
};
