import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// app
import styles from './FormRadio.styles';
import { ErrorMessage } from 'components';

// mui
import { FormControlLabel, FormLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';

FormRadioView.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object, PropTypes.bool]),
    })
  ),
  title: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  muiComponentProps: PropTypes.object,
  muiFormGroupProps: PropTypes.object,
};

FormRadioView.defaultProps = {
  color: 'primary',
  muiComponentProps: {},
};

export function FormRadioView({
  control,
  name,
  options,
  title,
  hint,
  error,
  disabled,
  color,
  muiComponentProps,
  muiFormGroupProps,
  defaultValue,
}) {
  const classes = makeStyles(styles, { name: 'FormRadio' })({
    hasError: Boolean(error),
  });

  const RadioOptions = () => {
    return options.map((option, index) => (
      <FormControlLabel
        key={`${option.value}_${index}`}
        value={option.value}
        label={option.label}
        disabled={option.disabled}
        className={classes.radioLabel}
        control={<Radio disabled={disabled} color={color} classes={{ root: classes.radio }} {...muiComponentProps} />}
      />
    ));
  };

  return (
    <div className={classes.radioGroup}>
      {title && (
        <FormLabel component="legend" className={classes.groupTitle}>
          {title}
        </FormLabel>
      )}

      {options &&
        (control ? (
          <Controller
            render={({ field }) => (
              <RadioGroup {...field} component={RadioGroup} {...muiFormGroupProps}>
                <RadioOptions />
              </RadioGroup>
            )}
            control={control}
            name={name}
            id={name}
            defaultValue={defaultValue}
          />
        ) : (
          <RadioGroup name={name} component={RadioGroup} {...muiFormGroupProps}>
            <RadioOptions />
          </RadioGroup>
        ))}
      <ErrorMessage error={error} hint={hint} />
    </div>
  );
}
