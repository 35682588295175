// buildingAxios

import { useApi } from 'hooks';
import { buildingAxios } from 'lib/quoteBind/quoteBind.api';
import { QB_BUILDING_DETAIL } from 'lib/quoteBind/queryKeys';

async function getBuildingDetail(id) {
  const response = await buildingAxios.get(`/building/${id}`);

  return response.data || {};
}

export function useGetBuildingDetail(id, options) {
  return useApi([QB_BUILDING_DETAIL, id], async (id) => await getBuildingDetail(id), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
