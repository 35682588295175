import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Typography } from '@material-ui/core';
import { useDebounceEffect } from 'hooks';
import * as utils from 'utils';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '300px',
    width: '100%',
  },
  crop: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 200,
    minWidth: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  previewCanvas: {
    border: `1px solid ${theme.palette.neutral.darkest}`,
    objectFit: 'contain',
    width: 160,
    height: 90,
  },
}));

const usePasteImageStyles = makeStyles((theme) => ({
  pasteRoot: {
    width: '300px',
    height: '300px',
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pasteAbsolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const EditImage = ({ handleUpdateFile, handlePaste, image = [] }) => {
  const classes = useStyles();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const aspect = 16 / 9;
  let { name, type } = image[0] ? image[0] : { name: '', type: '' };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    if (image && image?.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();

      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      reader.readAsDataURL(image[0]);
    }
  }, [image]);

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);

        const canvas = previewCanvasRef.current;

        if (type === 'image/svg+xml' || type === 'image/jpeg' || type === 'image/jpg') {
          type = 'image/png';
          name = name.replace('.svg', '.png') || name.replace('.jpg', '.png') || name.replace('.jpeg', '.png');
        }

        canvas.toBlob((blob) => {
          if (!blob) {
            return;
          }
          blob.name = name;
          const imageCrop = new File([blob], name, { type });
          handleUpdateFile(imageCrop);
        }, type);
      }
    },
    100,
    [completedCrop, scale]
  );

  if (!Boolean(imgSrc)) {
    return <ImagePaste handlePaste={handlePaste} />;
  }

  return (
    <div className={classes.root}>
      <ImagePaste handlePaste={handlePaste} height={80} />
      <Typography id="scale-slider" gutterBottom>
        {utils.string.t('admin.scale')}
      </Typography>
      <Slider
        defaultValue={scale}
        aria-labelledby="scale-slider"
        step={0.025}
        min={0.5}
        max={1.5}
        onChange={(e, value) => setScale(value)}
      />
      <div className={classes.crop}>
        {Boolean(imgSrc) ? (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minWidth={160}
            minHeight={90}
            keepSelection
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              border: '4px solid #8b8b8b',
            }}
          >
            <img ref={imgRef} alt="Crop logo" src={imgSrc} style={{ transform: `scale(${scale})` }} onLoad={onImageLoad} />
          </ReactCrop>
        ) : (
          <div style={{ width: '100%', height: '100%' }}>
            <Typography variant="h6" align="center" style={{ color: 'grey' }} contenteditable="false">
              {utils.string.t('app.pasteImage')}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.preview}>
        <Typography gutterBottom>Preview</Typography>
        {Boolean(completedCrop) && <canvas ref={previewCanvasRef} className={classes.previewCanvas} />}
      </div>
    </div>
  );
};

const ImagePaste = ({ handlePaste, height }) => {
  const classes = usePasteImageStyles();

  return (
    <div className={classes.pasteRoot} style={{ height: height ? height : '' }}>
      <div
        className={classes.pasteAbsolute}
        contenteditable="true"
        onKeyDownCapture={(e) => {
          if (e.key === 'v' && (e.ctrlKey || e.metaKey)) {
            return;
          }
          e.preventDefault();
        }}
        onPasteCapture={(e) => {
          const items = e.clipboardData.items;
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              handlePaste(e);
            }
          }
          e.preventDefault();
        }}
        onPaste={(e) => handlePaste(e)}
      ></div>
      <Typography variant="body1">{utils.string.t('app.pasteImage')}</Typography>
    </div>
  );
};
