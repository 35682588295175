import React from 'react';

import { FormCheckbox } from 'components';

import { useConditionsStyles } from '../Conditions.styles';

import * as utils from 'utils';

export const DuplicateRowCondition = ({ control, name, error }) => {
  const classes = useConditionsStyles();

  return (
    <div className={classes.checkboxCondition}>
      <FormCheckbox control={control} name={name} label={utils.string.t('rules.rowsDuplicated')} error={error} />
    </div>
  );
};
