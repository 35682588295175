export const USER_LIST_SORT_BY_OPTIONS = [
  { sortBy: 'fullName', sortDirection: 'asc', label: 'Name - Asc' },
  { sortBy: 'fullName', sortDirection: 'desc', label: 'Name - Desc' },
  { sortBy: 'role', sortDirection: 'asc', label: 'Role - Asc' },
  { sortBy: 'role', sortDirection: 'desc', label: 'Role - Desc' },
];

export const QUOTE_BIND = 'QUOTE_BIND';
export const BINDER_MANAGEMENT = 'BINDER_MANAGEMENT';
export const EDGE_ADMIN = 'EDGE_ADMIN';
export const REPORTS_ADMIN = 'REPORTS_ADMIN';

export const USER_LIST_FILTER_OPTIONS = [
  {
    title: 'Role',
    key: 'roleFilter',
    type: 'autocomplete',
    options: [
      {
        name: 'Broker - Internal ASL User',
        value: 'BROKER',
      },
      {
        name: 'Producer - External to ASL',
        value: 'PRODUCER',
      },
      {
        name: 'Underwriter - External to ASL',
        value: 'UNDERWRITER',
      },
      {
        name: 'Claims Advocate',
        value: 'CLAIMS',
      },
    ],
  },
  {
    title: 'Access',
    key: 'appAccessFilter',
    type: 'autocomplete',
    options: [
      {
        name: 'Quote & Bind',
        value: QUOTE_BIND,
      },
      {
        name: 'Binder Management',
        value: BINDER_MANAGEMENT,
      },
      {
        name: 'Admin',
        value: EDGE_ADMIN,
      },
      {
        name: 'Reports Admin',
        value: REPORTS_ADMIN,
      },
    ],
  },
  {
    title: 'Brand',
    key: 'brandFilter',
    type: 'autocomplete',
  },
  {
    title: 'GXB Instance',
    key: 'sourceSystemFilter',
    type: 'autocomplete',
    groupBy: 'groupBy',
  },
  {
    title: 'Department',
    key: 'departmentFilter',
    type: 'autocomplete',
    groupBy: 'groupBy',
  },
  {
    title: 'Operational Client',
    key: 'operationalClientFilter',
    type: 'autocomplete',
    groupBy: 'groupBy',
  },

  {
    title: 'Client Office',
    key: 'clientOfficeFilter',
    type: 'autocomplete',
    groupBy: 'groupBy',
  },
  {
    title: 'Operational Market',
    key: 'operationalMarketFilter',
    type: 'autocomplete',
  },
  {
    title: 'Quote Bind - Product',
    key: 'quoteAndBindProductFilter',
    type: 'autocomplete',
  },
  {
    title: 'Name',
    key: 'nameContainsFilter',
    type: 'text',
  },
  {
    title: 'Dashboard',
    key: 'dashboardTypesFilter',
    type: 'autocomplete',
  },
];
export const TOGGLES = ['teamLeader', 'internal', 'isAdmin', 'isReportAdmin', 'reportReadAll'];
export const TOGGLE_OPTIONS = {
  CLAIMS: ['teamLeader', 'internal'],
  BROKER: ['isAdmin', 'isReportAdmin', 'reportReadAll'],
  PRODUCER: ['teamLeader'],
};
