import { useQuery } from 'react-query';

// app
import { adminAxios } from '../../admin.api';
import { THIRD_PARTY_OFFICE_LIST } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// useGetOperationalTPOffices
export const useGetOperationalTPOffices = (parentId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.useGetOperationalTPOffices',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [THIRD_PARTY_OFFICE_LIST, parentId],
    async () => {
      const result = await adminAxios.get(`v1/operational-third-party/${parentId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
