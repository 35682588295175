// app
import * as utils from 'utils';

const utilsReferenceData = {
  departments: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((dept) => id === dept.id);
    },
    getByIdAndSourceSystemId: (arr, id, sourceSystemId) => {
      if (!id || !sourceSystemId || !utils.generic.isValidArray(arr)) return;
      return arr.find((dept) => id === dept.id && sourceSystemId === dept.sourceSystemId);
    },
    getByGxbIdAndSourceSystemId: (arr, gxbDepartmentId, sourceSystemId) => {
      if (!gxbDepartmentId || !sourceSystemId || !utils.generic.isValidArray(arr)) return;

      return arr.find((dept) => gxbDepartmentId === dept.gxbDepartmentId && sourceSystemId === dept.sourceSystemId);
    },
    getNameById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return '';

      const deptObj = utilsReferenceData.departments.getById(arr, id);

      return (deptObj && deptObj.name) || '';
    },
    getBusinessTypes: (departmentArray, id) => {
      if (!id || !utils.generic.isValidArray(departmentArray)) return [];

      const dept = utilsReferenceData.departments.getById(departmentArray, id);
      return dept?.businessTypes || [];
    },
    getUsers: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return [];

      const dept = utilsReferenceData.departments.getById(arr, id);
      return dept && dept.users ? dept.users : [];
    },
  },
  businessTypes: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((type) => id === type.id);
    },
    getNameById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return '';

      const obj = utilsReferenceData.businessTypes.getById(arr, id);
      return (obj && obj.id && obj.description) || '';
    },
  },
  markets: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((market) => id === market.id);
    },
  },
  underwriters: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((uw) => id === uw.underwriter.id);
    },
  },
  clients: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((client) => id === client.id);
    },
  },
  insureds: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((insured) => id === insured.id);
    },
  },
  newRenewalBusinesses: {
    getLabelById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return '';
      const obj = arr.find((business) => id === business.id);
      return (obj && obj.code && obj.description) || '';
    },
  },
  status: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      return arr.find((status) => id === status.id);
    },
    getByCode: (arr, code) => {
      if (!code || !utils.generic.isValidArray(arr)) return;
      return arr.find((status) => status.code === code);
    },
    getIdByCode: (arr, code) => {
      if (!code || !utils.generic.isValidArray(arr)) return;
      const obj = utilsReferenceData.status.getByCode(arr, code);
      return obj && obj.id;
    },
    getLabelById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return;
      const obj = arr.find((status) => id === status.id);
      return obj && obj.code ? utils.string.replaceLowerCase(obj.code) : '';
    },
    getKey: (status) => {
      return status ? utils.string.replaceLowerCase(status.code) : '';
    },
  },
  countries: {
    getOptionsIso2: (countries) => {
      if (!utils.generic.isValidArray(countries)) return [];

      return countries.map((country) => ({
        value: country.codeAlpha2,
        label: country.name,
      }));
    },
  },
  currencies: {
    getById: (arr, id) => {
      if (!id || !utils.generic.isValidArray(arr)) return {};
      return arr.find((currency) => id === currency.id) || {};
    },
    getByCode: (arr, code) => {
      if (!code || !utils.generic.isValidArray(arr)) return {};
      return arr.find((currency) => code === currency.code) || {};
    },
  },
};

export default utilsReferenceData;
