import { makeStyles } from '@material-ui/core';

export const useBDXExceptionsStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    flex: 1,
    margin: theme.spacing(3),
  },
  header: {
    fontWeight: '600',
    fontSize: theme.typography.pxToRem(20),
    color: `${theme.palette.neutral.darker}!important`,
    marginBottom: theme.spacing(2),
  },
  title: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    lineHeight: '1.2em',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    width: '100%',
  },
  wordWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  baseTable: {
    display: 'flex',
    flex: '1 1 auto',
    margin: 'auto',
    height: 'auto',
    width: '100%',
    '& .BaseTable__row-cell': {
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  actionButton: {
    bottom: '100%',
    '& svg:first-child': {
      marginLeft: '0!important',
    },
    '& svg:last-child': {
      marginRight: '0!important',
    },
  },

  errorCell: {
    border: `1px solid ${theme.palette.error.main}`,
    minWidth: '100px',
    minHeight: '30px',
    borderRadius: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px',
  },
  warningCell: {
    border: `1px solid ${theme.palette.alert.main}`,
    minWidth: '100px',
    minHeight: '30px',
    borderRadius: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
  },
  tooltipMessage: {
    whiteSpace: 'pre-line',
  },
  titleWithMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleMenu: {
    marginLeft: theme.spacing(1),
  },
  menuLoaderWrapper: {
    height: 34,
    width: 34,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(-0.75),
  },
}));

export const useSanctionsAlertStyles = makeStyles((theme) => ({
  warningIcon: {
    fill: `${theme.palette.alert.main}!important`,
  },
  errorIcon: {
    fill: `${theme.palette.error.main}!important`,
  },
  warningIconButton: (props) => ({
    padding: 5,
    borderRadius: '50%',
    marginLeft: theme.spacing(-0.75),
    cursor: props?.sanctionUrl ? 'pointer' : 'default',
  }),
  menuLoaderWrapper: {
    height: 34,
    width: 34,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(-0.75),
  },
  check: {
    color: theme.palette.success.main,
  },
}));
