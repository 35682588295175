import { useQuery } from 'react-query';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_LINEAGE_PROCESSING } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLineageAggregateProps {
  yearMonthSubmission: string;
}

export const useGetLineageAggregate = ({ yearMonthSubmission }: IUseGetLineageAggregateProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineageAggregate',
  });

  return useQuery<ILineageSubmissionAggregate[]>(
    [BDX_LINEAGE_PROCESSING, yearMonthSubmission],
    async () => {
      const result = await binderManagementBDXAxios.get(`aggregate/lineage/year-month/${yearMonthSubmission}`, {
        params: {
          yearMonthSubmission,
        },
      });
      return result?.data;
    },
    {
      onError: handleError,
    }
  );
};
