import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { FACILITY_GROUPS, UNGROUPED_FACILITIES } from 'lib/quoteBind/queryKeys';

export const useAddFacility = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.useAddFacility',
      message: 'There is an error when adding new facility',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);

    if (err.response.data.message) {
      dispatch(enqueueNotification(err.response.data.message, 'error'));
    } else {
      dispatch(enqueueNotification('notification.quoteBind.admin.addFacility.fail', 'error'));
    }
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(FACILITY_GROUPS);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.quoteBind.admin.addFacility.success', 'success'));
  };

  return useMutation(
    async (data) => {
      const { existingFacilityId, ...rest } = data;
      const facilitiesUrl = existingFacilityId ? `facilities?existingFacilityId=${existingFacilityId}` : 'facilities';

      return await quoteBindAxios.post(facilitiesUrl, rest);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useAssignToFacility = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.assignToFacility',
      message: 'Data missing for assign to facility group.',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.quoteBind.admin.assignToFacility.fail', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(FACILITY_GROUPS);
    queryClient.invalidateQueries(UNGROUPED_FACILITIES);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.quoteBind.admin.assignToFacility.success', 'success'));
  };

  return useMutation(
    async (data) => {
      const { facilityId, groupId } = data;

      return await quoteBindAxios.put(`facilities/groups/${groupId}?facilityId=${facilityId}`, {});
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
