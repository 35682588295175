import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification, hideModal } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

import { axiosInstance } from '../api';
// app
import { CLAIMS_STATISTICS, CLAIMS_TASKS } from '../queryKeys';

// risks
export const usePostTask = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'claimsTaskManagement.mutations.usePostTask',
    };

    dispatch(enqueueNotification('claimsTaskManagement.notification.usePostTask.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries([CLAIMS_TASKS]);
    queryClient.invalidateQueries([CLAIMS_STATISTICS]);

    dispatch(hideModal());
    dispatch(enqueueNotification('claimsTaskManagement.notification.usePostTask.success', 'success'));
  };

  return useMutation(
    async ({ data }) => {
      if (!data) {
        throw new Error('Invalid data');
      }
      const formattedData = {
        ...data,
        emailRecipient: data?.emailRecipient || null,
        primaryHandler: data?.primaryHandler || null,
        division: data?.division?.value || '',
        taskDetail: data?.taskDetail?.value || '',
        emailReceivedDate: data?.emailReceivedDate ? utils.date.formatDateWithParameter(data?.emailReceivedDate) : null,
        completedDate: data?.completedDate ? utils.date.formatDateWithParameter(data?.completedDate) : null,
        sentToUnderwriterDate: data?.sentToUnderwriterDate ? utils.date.formatDateWithParameter(data?.sentToUnderwriterDate) : null,
      };

      const result = await axiosInstance.put(`tm/v1/tasks`, formattedData);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
