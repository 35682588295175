import React from 'react';

// mui
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Checkbox,
  List,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// app
import styles from './FacilityUserCustomList.style';

export const FacilityUserCustomListView = ({ items, title, search, checked, handlers, groups }) => {
  const classes = makeStyles(styles, { name: 'FacilityUserCustomList' })();

  const Item = ({ users, group }) => (
    <ListItem className={classes.groupItem}>
      <div className={classes.group}>
        <Typography variant="body2" className={classes.groupTitle}>
          {group}
        </Typography>
        <List>
          {users.map((user) => {
            const isChecked = checked.indexOf(user) !== -1;

            return (
              <ListItem
                button
                onClick={handlers.handleToggle(user)}
                key={user.id}
                className={classes.listItem}
                data-testid={`user-${user.id}`}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                    className={classes.checkbox}
                    data-testid={`user-checkbox-${user.id}`}
                  />
                </ListItemIcon>
                <ListItemText>{user.fullName}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </div>
    </ListItem>
  );

  return (
    <Card className={classes.wrapper} data-testid={`facility-user-list-${title}`}>
      <CardHeader className={classes.section} title={title} />
      <Box className={classes.section}>
        <TextField
          fullWidth
          value={search}
          onChange={handlers.handleSearchChange}
          variant="outlined"
          placeholder="Search"
          InputProps={{
            endAdornment:
              search !== '' ? (
                <InputAdornment position="end">
                  <IconButton onClick={handlers.handleSearchReset} data-testid="clear-search">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Box>
      <Divider />
      <Box>
        <List style={{ height: 300, overflowY: 'auto', width: '100%', paddingInlineStart: 0 }}>
          {items.map((users, index) => (
            <Item users={users} key={groups[index]} group={groups[index]} />
          ))}
        </List>
      </Box>
    </Card>
  );
};
