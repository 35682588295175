// buildingAxios

import { useApi } from 'hooks';
import { buildingAxios } from 'lib/quoteBind/quoteBind.api';
import { QB_BUILDINGS } from 'lib/quoteBind/queryKeys';

async function getBuildings(facilityGroupId) {
  const response = await buildingAxios.get(`/building`, {
    params: {
      facilityGroupId,
    },
  });

  return response.data || [];
}

export function useGetBuildings(facilityGroupId, options) {
  return useApi([QB_BUILDINGS, facilityGroupId], async (facilityGroupId) => await getBuildings(facilityGroupId), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
