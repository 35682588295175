import { useQuery } from 'react-query';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_MARKET_FILTERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetMarketFilters = () => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetMarketFilters',
  });

  return useQuery<IGetMarketFiltersResponse>(
    [FACILITY_MARKET_FILTERS],
    async () => {
      const result = await binderManagementFacilitiesAxios.get(`markets/filter`);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
