import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { RISK_DOCUMENTS } from 'lib/quoteBind/queryKeys';

export const useDeleteRiskDocument = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.deleteRiskDocument',
      message: 'Data missing for DELETE request',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.document.deleteFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(RISK_DOCUMENTS, data.risk_id);

    dispatch(enqueueNotification('notification.document.deleteSuccess', 'success'));
  };

  return useMutation(
    async ({ riskId, documentId }) => {
      dispatch(hideModal());
      if (!documentId || !riskId) {
        throw new Error('useDeleteRiskDocument: riskId and documentId are required');
      }

      return await quoteBindAxios.delete(`/risks/${riskId}/documents/${documentId}`);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
