import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// app
import { Empty, TableCell, TableHead, PopoverMenu, Button, Translate, Overflow, Pagination, Skeleton } from 'components';
import { RiskCard, RiskCardLoadingSkeleton } from 'modules';
import * as utils from 'utils';
import config from 'config';
import { RISK_DISPLAY_CARD } from 'consts';

// mui
import { Grid, Table, TableRow, TableBody, makeStyles, createStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as SearchResultImage } from '../../../assets/svg/search-result.svg';

const NoResult = () => <Empty width={400} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />;

QuoteBindDraftRiskView.propTypes = {
  listDraftRisk: PropTypes.object.isRequired,
  draftRiskListLoading: PropTypes.bool.isRequired,
  popoverActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    })
  ),
  riskProducts: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsTotal: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    handleEditDraftRisk: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeRowsPerPage: PropTypes.func.isRequired,
  }),
};

const useStyles = makeStyles(() =>
  createStyles({
    dataCellLast: {
      borderTop: '1px solid #eeeeee',
    },
  })
);

export function QuoteBindDraftRiskView({
  displayStyle,
  listDraftRisk,
  draftRiskListLoading,
  popoverActions,
  riskProducts,
  pagination,
  handlers,
}) {
  const classes = useStyles();
  const cols = [
    { id: 'createdAt', label: utils.string.t('risks.draftCreatedAt') },
    { id: 'product', label: utils.string.t('app.product') },
    { id: 'client', label: utils.string.t('app.client') },
    { id: 'insured', label: utils.string.t('app.insured') },
    { id: 'inceptionDate', label: utils.string.t('app.inceptionDate'), nowrap: true },
    { id: 'expiryDate', label: utils.string.t('app.expiryDate'), nowrap: true },
    { id: 'userName', label: utils.string.t('admin.user') },
    { id: 'status', label: '' },
  ];
  const noResult = listDraftRisk.length === 0 && !draftRiskListLoading;

  return (
    <>
      {displayStyle === RISK_DISPLAY_CARD ? (
        <Grid container spacing={2} style={{ marginTop: 20 }} data-testid="risk-list-card">
          {draftRiskListLoading ? (
            <RiskCardLoadingSkeleton numberOfCards={pagination.rowsPerPage} isDraft />
          ) : (
            <>
              {noResult ? (
                <NoResult />
              ) : (
                <>
                  {listDraftRisk?.map((draft) => {
                    const createdAt = utils.string.t('format.date', {
                      value: { date: get(draft, 'lastUpdatedDate'), format: 'lll' },
                    });
                    const createdBy = get(draft, 'userName');

                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={draft.id}>
                        <RiskCard
                          risk={draft.risk}
                          loading={draftRiskListLoading}
                          riskProducts={riskProducts}
                          isDraft
                          createdAt={createdAt}
                          createdBy={createdBy}
                          handlers={{
                            handleEditDraftRisk: () => handlers.handleEditDraftRisk(draft),
                            handleDeleteDraftRisk: () => handlers.handleDeleteDraftRisk(draft),
                          }}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Grid>
      ) : (
        <Overflow>
          <Table data-testid="risk-list-table">
            <TableHead columns={cols} />
            {draftRiskListLoading ? (
              <TableBody data-testid="risk-skeleton">
                <TableRow>
                  <TableCell colSpan={cols.length}>
                    <Skeleton height={40} animation="wave" displayNumber={pagination.rowsPerPage} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {noResult ? (
                  <TableBody data-testid="risk-list">
                    <TableRow>
                      <TableCell colSpan={cols.length}>
                        <NoResult />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="risk-list">
                    {listDraftRisk &&
                      listDraftRisk.map((draft) => {
                        const createdAt = utils.string.t('format.date', {
                          value: { date: get(draft, 'lastUpdatedDate'), format: 'lll' },
                        });
                        return (
                          <TableRow key={draft.id} data-testid={`draft-row-${draft.id}`}>
                            <TableCell data-testid={`draft-cell-createdAt-${draft.id}`}>{createdAt}</TableCell>
                            <TableCell data-testid={`draft-cell-product-${draft.id}`}>
                              {utils.risk.getRiskName(draft.productCode, riskProducts)}
                            </TableCell>
                            <TableCell data-testid={`draft-cell-clients-${draft.id}`}>{get(draft, 'clientName')}</TableCell>
                            <TableCell data-testid={`draft-cell-insureds-${draft.id}`}>{get(draft, 'insuredName')}</TableCell>
                            <TableCell nowrap data-testid={`draft-cell-inceptionDate-${draft.id}`}>
                              <Translate
                                label="format.date"
                                options={{ value: { date: draft.risk.inceptionDate, format: config.ui.format.date.text, default: '-' } }}
                              />
                            </TableCell>
                            <TableCell nowrap data-testid={`draft-cell-expiryDate-${draft.id}`}>
                              <Translate
                                label="format.date"
                                options={{ value: { date: draft.risk.expiryDate, format: config.ui.format.date.text, default: '-' } }}
                              />
                            </TableCell>
                            <TableCell data-testid={`draft-cell-userName-${draft.id}`}>{get(draft, 'userName')}</TableCell>
                            <TableCell data-testid={`draft-cell-status-${draft.id}`}>
                              <Button size="xsmall" color="primary" icon={EditIcon} onClick={() => handlers.handleEditDraftRisk(draft)} />
                            </TableCell>
                            <TableCell menu nestedClasses={{ root: classes.dataCellLast }}>
                              <PopoverMenu id="draft-risk" data={draft} items={popoverActions} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </Overflow>
      )}
      {!noResult ? (
        <Grid container data-testid="pagination">
          <Grid item xs={12} sm={12}>
            <Pagination
              page={pagination.page}
              count={pagination.rowsTotal}
              rowsPerPage={pagination.rowsPerPage}
              rowsPerPageOptions={config.ui.gridPagination.options}
              onChangePage={handlers.changePage}
              onChangeRowsPerPage={handlers.changeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
