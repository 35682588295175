import { useQuery } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { RISK_DOCUMENTS } from 'lib/quoteBind/queryKeys';

// risks
export const useGetRiskDocuments = (riskId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useGetRiskDocuments',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [RISK_DOCUMENTS, riskId],
    async () => {
      if (!riskId) {
        throw new Error('useGetRiskDocuments:No riskId provided');
      }

      const result = await quoteBindAxios.get(`/risks/${riskId}/documents`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
