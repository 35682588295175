/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, Controller } from 'react-hook-form';

// mui
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import { ReactComponent as ExcelLogo } from 'assets/svg/excel_icon.svg';
import { Button, ErrorMessage } from 'components';

// app
import * as utils from 'utils';

import { useBDXDragDropStyles } from './BDXDragDrop.styles';

export interface IBDXDragDropRefProps {
  removeFile: (e?: Event) => void;
}

interface IBDXDragDropProps {
  name: string;
  label: string;
  hint?: string;
  control: Control<any>;
  onChange: (_, file?: File) => void;
  dragLabel?: string;
  defaultFile?: File;
  width: number | string;
  height: number | string;
  version?: 'small' | 'default';
  showUploadPreview?: boolean;
  fileNameLength?: number;
}

const BDXDragDrop = forwardRef<IBDXDragDropRefProps, IBDXDragDropProps>(
  (
    {
      name,
      label,
      hint,
      control,
      onChange,
      dragLabel,
      defaultFile,
      width,
      height,
      version,
      showUploadPreview = false,
      fileNameLength = 48,
    },
    ref
  ) => {
    const classes = useBDXDragDropStyles({ version });
    const [uploadedFile, setUploadedFile] = useState({});
    const [error, setError] = useState({});
    const validExtensions = ['.xlsx', '.xls', '.csv'];

    const onRemove = useCallback(
      (e?: Event) => {
        e?.stopPropagation();
        setUploadedFile({});
        onChange(null, null);
      },
      [onChange]
    );

    const onDrop = useCallback(
      (files) => {
        if (files && files.length > 0) {
          const fileExt = files[0]?.name?.match(/\.[0-9a-z]+$/)?.[0];
          if (validExtensions.includes(fileExt)) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const contents = e.target.result;
              onChange(contents, files[0]);
            };
            reader.readAsArrayBuffer(files[0]);
            setUploadedFile(files[0]);
            setError({});
          } else {
            onRemove();
            setError({ message: utils.string.t('notification.bdx.fileError') });
          }
        }
      }, // eslint-disable-next-line
      [uploadedFile]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: validExtensions });

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    useImperativeHandle(ref, () => ({
      removeFile: onRemove,
    }));

    const UploadedFile = useCallback(
      ({ file }) => (
        <div className={classes.uploadedFile} data-testid="uploadedFile">
          <div className={classes.uploadedFileListLabel}>
            <CheckCircleIcon className={classes.successIcon} />
            <p>{utils.file.truncate(file.name, fileNameLength)}</p>

            <Button icon={DeleteIcon} size="xsmall" variant="text" style={{ color: '#E93D4C' }} onClick={(e) => onRemove(e)} />
          </div>
        </div>
      ),
      [classes, fileNameLength, onRemove]
    );

    const dragInstruction = (
      <>
        <CloudUploadIcon className={classes.uploadIcon} />
        <p className={classes.dragFile}>{dragLabel || utils.string.t('form.dragDrop.dragBDXHere')}</p>
        <p className={classes.browseFile}>
          <ExcelLogo className={classes.excelIcon} />
          {utils.string.t('form.dragDrop.chooseFile')}
        </p>
      </>
    );
    const dragInstructionSmall = <CloudUploadIcon />;

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item>
            {label && (
              <label className={classes.formLabel} data-testid="bdxdragdrop-label" htmlFor={name}>
                {label}
              </label>
            )}
            {control ? (
              <Controller
                control={control}
                name={name}
                render={() => (
                  <div
                    className={isDragActive ? `${classes.dragOver} ${classes.dragArea} ` : `${classes.dragArea}`}
                    style={{ width, height }}
                    {...getRootProps()}
                    data-form-type="file"
                  >
                    <input {...getInputProps()} data-testid="bdxDropZone" />
                    {version === 'small' ? dragInstructionSmall : dragInstruction}
                  </div>
                )}
              />
            ) : (
              <div
                className={isDragActive ? `${classes.dragOver} ${classes.dragArea} ` : `${classes.dragArea}`}
                style={{ width, height }}
                {...getRootProps()}
                data-form-type="file"
              >
                <input {...getInputProps()} data-testid="bdxDropZone" />
                {version === 'small' ? dragInstructionSmall : dragInstruction}
              </div>
            )}
          </Grid>

          {showUploadPreview && (
            <Grid item>
              {(defaultFile?.size > 0 && <UploadedFile file={defaultFile} />) ||
                (showUploadPreview && uploadedFile && !isEmpty(uploadedFile) && <UploadedFile file={uploadedFile} />)}
            </Grid>
          )}
        </Grid>
        <ErrorMessage error={error} hint={hint} />
      </div>
    );
  }
);

BDXDragDrop.displayName = 'BDXDragDrop';

export default BDXDragDrop;
