import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@material-ui/core';

const transition = { duration: 0.5, ease: 'easeInOut' };

export const RuleLine = ({ startingPoint, length, height = 40 }) => {
  const theme = useTheme();

  return (
    <div className="ruleLine">
      <svg width={length} height={height}>
        {/* Framer motion path is not fully compatible with react dom https://www.framer.com/docs/component/###exceptions */}
        {process.env.NODE_ENV !== 'test' && (
          <motion.path
            d={`M ${startingPoint.x} ${startingPoint.y} V ${height} H ${length}`}
            fill="transparent"
            strokeWidth="4"
            stroke={theme.palette.primary.main}
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={transition}
          />
        )}
      </svg>
    </div>
  );
};
