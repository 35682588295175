const styles = (theme) => ({
  logoLink: {
    lineHeight: 0,
  },
  root: {
    width: 'auto',
  },
});

export default styles;
