import { TableRow, makeStyles, withStyles } from '@material-ui/core';

export const useBDXUploadCardStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: '12px 16px',
    },
    '& .MuiTableCell-root:first-child': {
      padding: '12px 16px 12px 24px',
    },
    '& .MuiTableCell-root:last-child': {
      padding: '12px 24px 12px 16px',
    },
  },
  icon: {
    width: '22px',
    height: '22px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    width: '22px',
    height: '22px',
    color: '#F44336',
  },
  downloadAllButton: {
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tableContainer: {
    width: '100%',
    height: 'fit-content',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
}));

export const StyledBDXUploadCardTableRow = withStyles((theme) => ({
  root: {
    '&.active': {
      backgroundColor: `${theme.palette.grey[300]}!important`,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },

    '&.processed': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
}))(TableRow);
