import { FC } from 'react';

import { Grid, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Add, ImportExport } from '@material-ui/icons';

import { Button } from 'components';

import * as utils from 'utils';

import { binderLimitsStyles } from './BinderLimits.styles';
import { ColumnHeader } from './components/BinderLimit/BinderLimits.types';
import { BinderLimitsList } from './components/BinderLimitsList';
import { IBindersLimitsViewProps } from './components/BinderLimit/BinderLimit.types';

export const BindersLimitsView: FC<IBindersLimitsViewProps> = ({ handleAddLimit, handleImportLimits, columnHeaders }) => {
  const classes = binderLimitsStyles();
  const createHeaders = (headers) =>
    headers.map((header) => (
      <TableCell key={header} className={header === ColumnHeader.Name ? classes.tableCell_Name : classes.tableCell} align="center">
        {header}
      </TableCell>
    ));

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item className={classes.buttons} />
        <Grid item>
          <Button
            text={utils.string.t('binderLimits.addLimits.title')}
            icon={Add}
            variant="contained"
            color="primary"
            onClick={handleAddLimit}
          />
        </Grid>
        <Grid item>
          <Button
            text={utils.string.t('binderLimits.importLimits')}
            icon={ImportExport}
            variant="contained"
            color="primary"
            onClick={handleImportLimits}
          />
        </Grid>
      </Grid>
      <Table className={classes.table} data-testid="binder-limits-table">
        <TableHead>
          <TableRow>{createHeaders(columnHeaders)}</TableRow>
        </TableHead>
      </Table>
      <BinderLimitsList />
    </div>
  );
};
