import { makeStyles } from '@material-ui/core/styles';

export const useQuoteBindAggregateStyles = makeStyles((theme) => ({
  contentWrapper: ({ media }) => ({
    border: `1px solid ${theme.palette.neutral.light}`,
    marginLeft: media?.mobile ? '20px' : '40px',
    marginRight: media?.mobile ? '20px' : '40px',
    marginBottom: media?.mobile ? '20px' : '20px',
  }),

  titleDiv: ({ media }) => ({
    paddingTop: media?.mobile ? '10px' : '50px',
    paddingBottom: media?.mobile ? '0px' : '10px',
    paddingLeft: media?.mobile ? '20px' : '40px',
  }),
}));

export const useCustomTooltipStyles = makeStyles(() => ({
  toolTip: {
    display: 'block',
    border: '1px solid #E9DCC9',
    borderRadius: '10px',
    background: '#FFFFFF',
    padding: '8px',
  },
}));
