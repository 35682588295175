import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_LINEAGE_AGGREGATES_EXISTS, BDX_LINEAGE_PROCESSING } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitAllLineage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useSubmitAllLineage',
    warning: 'notification.lineage.submitFail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries(BDX_LINEAGE_PROCESSING);
    queryClient.invalidateQueries(BDX_LINEAGE_AGGREGATES_EXISTS);
    dispatch(enqueueNotification('notification.lineage.submitSuccess', 'success'));
  };

  return useMutation<ILineageAllSubmission, AxiosError, ILineageAllSubmission>(
    async ({ yearMonthSubmission }) => {
      const response: AxiosResponse<ILineageAllSubmission> = await binderManagementBDXAxios.post(
        `aggregate/lineage/year-month/${yearMonthSubmission}/submit`
      );

      return response.data;
    },
    { onError: handleError, onSuccess: handleSuccess }
  );
};
