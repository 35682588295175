import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import { adminAxios } from '../../admin.api';
import { CLIENT_OFFICE_LIST, OPERATIONAL_CLIENTS } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';

export const useDeleteClientOffice = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSettled = () => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.deleteClientOffice',
      message: 'Data missing for DELETE request',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    err?.response?.data?.message
      ? dispatch(enqueueNotification(err?.response?.data?.message, 'error'))
      : dispatch(enqueueNotification('notification.admin.deleteClientOfficeFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_CLIENTS);
    queryClient.invalidateQueries(CLIENT_OFFICE_LIST);

    dispatch(
      enqueueNotification('notification.admin.deleteClientOfficeSuccess', 'success', {
        data: { officeName: data?.name },
      })
    );
  };

  return useMutation(
    async (office) => {
      if (isEmpty(office)) {
        throw new Error('Invalid office');
      }

      const { id } = office;
      return await adminAxios.delete(`client/office/${id}`);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: handleSettled,
    }
  );
};
