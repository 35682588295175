import { useState, useMemo, useRef, useContext } from 'react';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import lodashMap from 'lodash/map';

import 'react-base-table/styles.css';

import { Box, Grid, CircularProgress, Typography } from '@material-ui/core';

import { usePagination } from 'hooks';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Pagination, Empty, Button, BaseTableCell, BaseTableHeaderCell } from 'components';
import { useGetBdxData } from 'lib/binderManagement';
import * as utils from 'utils';

import { useBDXSummaryStyles } from './useBDXSummaryStyles';
import { CloudDownload } from '@material-ui/icons';
import { useMountDelay } from '../useMountDelay';
import { BDXExtractionContext } from '../../BDXExtraction.context';

const HEADER_HEIGHT = 55;
const MAX_ROWS = 12;

export const BDXSummary = ({ bdxDocumentsInfo, handleDownloadAll, isDownload, tab }) => {
  const classes = useBDXSummaryStyles();
  const ref = useRef(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);
  const { activeBDX } = useContext(BDXExtractionContext);

  const { data, isLoading, isError } = useGetBdxData({ requestId: activeBDX.requestId, bdxDocumentsInfo, page: page, size: size });
  const mountDelay = useMountDelay();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setSize(rowsPerPage);
  };

  const renderEmpty = () => {
    if (isLoading)
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    if (data?.content?.length === 0 || isError) {
      return <Empty width={340} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />;
    }
  };

  const pagination = usePagination(data?.content, utils.generic.getPaginationObj(data), handleChangePage, handleChangeRowsPerPage);

  const columns = useMemo(() => {
    if (data?.content?.length > 0) {
      const content = lodashMap(data?.content[0].cells, 'columnName');
      if (content?.length > 0) {
        return content.map((c, index) => ({
          title: c,
          dataKey: `field_${index}`,
          resizable: true,
          width: 150,
          flex: 1,
          align: 'left',
        }));
      }
    }
    return [];
  }, [data?.content]);

  const rows = useMemo(() => {
    if (!columns || columns?.length === 0) {
      return [];
    }

    if (data?.content?.length > 0) {
      const rows = data.content.map((row) => {
        if (row.cells?.length > 0) {
          let newRow = { id: row.rowId };
          columns.forEach((column, index) => {
            const record = row.cells?.find((col) => column.title === col.columnName);
            newRow = { ...newRow, [`field_${index}`]: record?.columnValue || '' };
          });
          return newRow;
        }
        return [];
      });

      return rows;
    }
  }, [data?.content, columns]);

  return (
    <Box className={classes.root} data-testid="bdx-summary">
      <Box className={classes.header} display="flex" alignContent="center" justifyContent="space-between">
        <Box display="flex" alignContent="center">
          {tab.tabIcon}
          <Typography variant="h2" className={classes.title}>
            {tab.label}
          </Typography>
        </Box>
        {data?.content?.length > 0 ? (
          <Button
            size="small"
            onClick={() => handleDownloadAll({ ...bdxDocumentsInfo })}
            icon={!isDownload ? CloudDownload : () => <CircularProgress size={20} className={classes.downloadIcon} />}
            iconPosition="right"
            text="Download"
            variant="contained"
            color="secondary"
            disabled={isDownload}
            classes={{ btn: classes.actionButton }}
          />
        ) : null}
      </Box>
      <>
        <Box className={classes.baseTable}>
          {mountDelay ? (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          ) : (
            <AutoResizer height={MAX_ROWS * (HEADER_HEIGHT + 1)}>
              {({ width, height }) => (
                <BaseTable
                  fixed
                  data={rows}
                  rowKey="id"
                  ref={ref}
                  emptyRenderer={renderEmpty}
                  headerHeight={HEADER_HEIGHT}
                  width={width}
                  height={height}
                  components={{ TableCell: BaseTableCell, TableHeaderCell: BaseTableHeaderCell }}
                >
                  {columns.map(({ dataKey, ...restProps }) => (
                    <Column key={dataKey} dataKey={dataKey} {...restProps} />
                  ))}
                </BaseTable>
              )}
            </AutoResizer>
          )}
        </Box>
        <Grid container data-testid="pagination">
          <Grid item xs={12} sm={12}>
            <Pagination
              page={pagination.obj.page}
              count={pagination.obj.rowsTotal}
              rowsPerPage={pagination.obj.rowsPerPage}
              rowsPerPageOptions={[12, 24, 48, 96]}
              onChangePage={pagination.handlers.handleChangePage}
              onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </>
    </Box>
  );
};
