export const getActions = (rule) => {
  const actions = rule.actions.map((action) => ({
    ...action,
    column1: action.column1?.name || null,
    column2: action.column2?.name || null,
    field: action.field?.name || null,
  }));

  return actions;
};
