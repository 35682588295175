// MUI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { FormGrid } from 'components';
// Edge
import { useQuoteBindStyles } from 'modules/QuoteBind';

import { EndorsementQuote } from './EndorsementQuote';

export const EndorsementQuotes = ({
  title,
  policy,
  endorsements,
  isEndorsementEnabled,
  hasBackdateEndorsement,
  policyCurrency,
  latestEffectiveEndorsementDate,
  hasEndorsementAdditionalPremium,
  handleReQuoteEndorsement,
}) => {
  const classes = useQuoteBindStyles();

  return (
    <Box className={classes.root} data-testid="EndorsementQuotes">
      <Box className={classes.header} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        <FormGrid container>
          {endorsements.map((endorsement) => (
            <FormGrid item xs={12} sm={6} md={4} lg={4} key={endorsement.id}>
              <EndorsementQuote
                quote={endorsement}
                policyCurrency={policyCurrency}
                policy={policy}
                isEndorsementEnabled={isEndorsementEnabled}
                hasBackdateEndorsement={hasBackdateEndorsement}
                latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
                hasEndorsementAdditionalPremium={hasEndorsementAdditionalPremium}
                handleReQuoteEndorsement={handleReQuoteEndorsement}
              />
            </FormGrid>
          ))}
        </FormGrid>
      </Box>
    </Box>
  );
};
