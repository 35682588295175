import React from 'react';

// mui
import AddIcon from '@material-ui/icons/Add';

// Edge
import { Button } from 'components';

import * as utils from 'utils';

import { EffectiveDateSelection } from './EffectiveDateSelection';

export const NewEndorsement = ({ handleAddEndorsement, hasBackdateEndorsement, latestEffectiveEndorsementDate, policy }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <EffectiveDateSelection
        open={open}
        anchorEl={anchorEl}
        hasBackdateEndorsement={hasBackdateEndorsement}
        latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
        policy={policy}
        handleAddEndorsement={handleAddEndorsement}
        handleClose={handleClose}
      />
      <Button
        icon={AddIcon}
        size="xsmall"
        variant="outlined"
        color="primary"
        text={utils.string.t('app.endorsement')}
        onClick={handleAddClick}
      />
    </div>
  );
};
