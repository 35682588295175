export const BRANDS = 'BRANDS';
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const SEARCH_MARKET = 'SEARCH_MARKET';
export const SEARCH_THIRD_PARTY = 'SEARCH_THIRD_PARTY';

export const SEARCH_OPTIONS = [
  {
    key: SEARCH_CLIENT,
    URL: '/client/search',
  },
  {
    key: SEARCH_MARKET,
    URL: '/market/search',
  },
  {
    key: SEARCH_THIRD_PARTY,
    URL: 'v1/third-party',
  },
];
