const styles = (theme) => ({
  root: ({ isMobile }) => ({
    height: isMobile ? 'auto' : 36,
    marginBottom: theme.spacing(1),
  }),

  formGrid: {
    padding: '0!important',
  },

  formFields: ({ isMobile }) => ({
    flex: '1 1 auto',
    marginBottom: isMobile ? 10 : 0,
  }),

  filledInput: ({ isMobile }) => ({
    height: 36,
    padding: '12px 0',
    boxSizing: 'border-box',
    borderRadius: isMobile ? 4 : 0,
  }),

  filledRoot: {
    paddingLeft: 8,
    borderRight: '1px white solid',
    borderRadius: '4px 0 0 4px',
  },

  autocompleteRoot: ({ isMobile }) => ({
    height: 36,
    borderRight: '1px white solid',
    borderRadius: isMobile ? 4 : 0,
  }),

  resetButton: {
    height: 36,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent ',
    },
  },

  filterButton: ({ isMobile }) => ({
    height: 36,
    width: 90,
    marginLeft: isMobile ? '8px !important' : '-1px !important',
    marginTop: isMobile ? '-8px !important' : 'inherit',
    marginRight: '0 !important',
    borderRadius: '0 4px 4px 0',
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[100],
    border: '1px solid #BEBEBE',
    borderLeft: 0,
    '&:hover': {
      backgroundColor: '#E4E4E4',
      boxShadow: `0 1px 1px rgba(0,0,0,.15),
      inset 0 0 2px rgba(0,0,0,.05);`,
      transition: `all .07s ease-in-out`,
    },
  }),

  adornmentEnd: {
    marginRight: -theme.spacing(1),
  },
  actions: {
    margin: '0 !important',
  },

  formContainer: {
    alignItems: 'stretch',
    flexDirection: 'row',
    margin: 0,
  },

  firstInput: {
    borderRadius: '4px 0 0 4px',
  },
});

export default styles;
