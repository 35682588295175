import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Button, Empty, FormActions } from 'components';
import { TransferList } from 'components/TransferList/TransferList';

import { useCreateLimit, useGetLimitFavourites, useGetLimitSets } from 'lib/binderManagement';
import { hideModal } from 'stores';
import * as utils from 'utils';

import { BinderLimitsCustomList } from './BinderLimitsCustomList';
import { IImportBinderLimitsProps } from './ImportBinderLimits.types';

const formatBinderLimits = (binderLimits: IBinderLimit[] = []) =>
  binderLimits?.map((binderLimit) => ({
    ...binderLimit,
    groupBy: `${binderLimit.coverHolder} - ${binderLimit.facilityUMR}`,
  })) || [];

export const ImportBinderLimits: FC<IImportBinderLimitsProps> = ({ facility }) => {
  const dispatch = useDispatch();

  const { mutateAsync: importBinderLimits } = useCreateLimit({
    facility,
  });

  const {
    data: binderLimitSets,
    isFetching: isLoadingBinderLimitSets,
    isError: isErrorGetBinderLimits,
  } = useGetLimitSets({
    facilityUMR: facility?.umr,
  });

  const { data: favouriteLimits = [], isLoading, isError } = useGetLimitFavourites(facility?.riskCodes.map(({ code }) => code));

  const [selectedBinderLimits, setSelectedBinderLimits] = useState<IBinderLimit[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateAssignedData = useCallback((assignedData: IBinderLimit[]) => {
    setSelectedBinderLimits(assignedData);
  }, []);

  if (isLoading || isLoadingBinderLimitSets) return <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />;
  if (isError || isErrorGetBinderLimits) return <Empty title={utils.string.t('app.error')} icon={<Loading />} width={300} />;
  const handleClose = () => {
    dispatch(hideModal());
  };

  const { operationalClientName, umr } = facility;
  const currentBinderLimits =
    binderLimitSets?.map((limit) => ({
      ...limit,
      groupBy: `${operationalClientName} - ${umr}`,
      disabled: true,
    })) || [];

  const formattedBinderLimits = formatBinderLimits(favouriteLimits);

  const rightData = formattedBinderLimits
    .filter((binderLimit) => !currentBinderLimits?.find((currentBinderLimit) => currentBinderLimit.id === binderLimit.id))
    .map((binderLimit) => {
      const binderLimitCompare = {
        conditionType: binderLimit.conditionType,
        conditions: binderLimit.conditions,
        sumColumns: binderLimit.sumColumns,
      };

      const isDisabled = currentBinderLimits?.some((currentBinderLimit) => {
        const currentBinderLimitCompare = {
          conditionType: currentBinderLimit.conditionType,
          conditions: currentBinderLimit.conditions,
          sumColumns: currentBinderLimit.sumColumns,
        };
        return isEqual(binderLimitCompare, currentBinderLimitCompare);
      });

      return {
        ...binderLimit,
        disabled: isDisabled,
      };
    });

  const handleSaveBinderLimits = async () => {
    setIsSubmitting(true);
    const data = selectedBinderLimits
      .filter((binderLimit) => !currentBinderLimits.find((currentBinderLimit) => currentBinderLimit.id === binderLimit.id))
      .map((binderLimit) => ({ ...binderLimit, favourite: true }));

    try {
      await importBinderLimits({ data, action: 'IMPORT_LIMITS' });

      setIsSubmitting(false);
      handleClose();
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <TransferList
        spacing={0}
        leftData={currentBinderLimits}
        rightData={rightData}
        leftTitle={utils.string.t('binderLimits.assignedLimits')}
        rightTitle={utils.string.t('binderLimits.availableRules')}
        handleUpdateAssignedData={handleUpdateAssignedData}
        listComponent={BinderLimitsCustomList}
      />

      <FormActions type="dialog">
        <Button text={utils.string.t('app.cancel')} disabled={isSubmitting} onClick={handleClose} type="text" />
        <Button text={utils.string.t('app.save')} disabled={isSubmitting} type="submit" color="primary" onClick={handleSaveBinderLimits} />
      </FormActions>
    </div>
  );
};
