import { useMutation } from 'react-query';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useUploadBDXFile = () => {
  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useUploadBDXFile',
    warning: 'notification.bdx.uploadFail',
  });

  return useMutation(
    async ({ file, requestId }) => {
      const form = new FormData();
      form.append('file', file);
      if (requestId) {
        form.append('requestId', requestId);
      }

      const data = await binderManagementFileInputAxios.post('upload', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    },
    {
      onError: handleError,
    }
  );
};
