import { useQuery } from 'react-query';
import querystring from 'querystring';

// app
import { axiosInstance } from '../api';
import { CLAIMS_STATISTICS } from '../queryKeys';
import { getHandleError } from '../utils';

export const useGetClaimsStatistics = ({ params }) => {
  const handleError = getHandleError('claimsTaskManagement.queries.useGetClaimsStatistics');

  return useQuery(
    [CLAIMS_STATISTICS, params],
    async () => {
      if (params?.statistics?.length > 0) {
        const url = `tm/v1/tasks/statistics`;
        const parameters = querystring.stringify(params);
        const result = await axiosInstance.get(`${url}?${parameters}`);
        return result;
      }
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
