import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { PAGE_SIZE } from 'consts/quote-bind';

async function getClients(params, token) {
  const response = await api(token).get('clients', {
    params: {
      orderBy: params.orderBy || 'name',
      direction: params.direction || 'ASC',
      page: params.page || 1,
      size: params.size || PAGE_SIZE,
      ...params,
    },
  });
  return response.data;
}

export function useGetClients(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (params, token) => await getClients(params, token), {
    ...options,
    cacheTime: 1000 * 60 * 15,
  });
}
