import { FC } from 'react';
import classnames from 'classnames';

import { Avatar as MUIAvatar } from '@material-ui/core';

import * as utils from 'utils';

import { IAvatarProps } from './Avatar.types';

import { useAvatarStyles } from './Avatar.styles';

export const Avatar: FC<IAvatarProps> = ({ size, text, icon, src, bg, border, avatarClasses, onAvatarClick, ...rest }) => {
  const IconComponent = icon;

  const classes = useAvatarStyles({
    hasAction: utils.generic.isFunction(onAvatarClick),
    text,
    size,
    icon,
    src,
    bg,
    border,
    ...rest,
  });

  if (!text && !icon && !src) return null;

  return (
    <MUIAvatar
      onClick={utils.generic.isFunction(onAvatarClick) ? onAvatarClick : undefined}
      src={src}
      {...rest}
      className={classnames(classes.root, avatarClasses)}
      data-testid="avatar"
    >
      {icon ? (
        <IconComponent
          style={{
            fontSize: 'inherit',
            width: '60%',
            height: '60%',
          }}
        />
      ) : (
        text
      )}
    </MUIAvatar>
  );
};
