import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_CLAIM_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetBDXClaimDataProps {
  bdxDocumentsInfo: IBDXDocumentInfo;
  claimGroup: string | null;
  page?: number;
  size?: number;
}

export const useGetBdxClaimData = ({ bdxDocumentsInfo, claimGroup, page = 0, size = 12 }: IUseGetBDXClaimDataProps) => {
  const params = { claimGroup, page, size };
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxClaimData' });

  return useQuery<IPage<IBDXRow>>(
    [BDX_CLAIM_DATA, { ...bdxDocumentsInfo, claimGroup }, params],
    async () => {
      const { facilityReference, yearMonthSubmission } = bdxDocumentsInfo;
      if (!facilityReference || !yearMonthSubmission) throw new Error('Missing required params');

      const url = `claim/${facilityReference}/${yearMonthSubmission}`;

      const result = await binderManagementBDXAxios.get(url, { params });
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
