const styles = (theme) => ({
  panel: (props) => ({
    marginBottom: theme.spacing(2),
    '&:before': {
      backgroundColor: 'rgba(0,0,0,0.06)',
    },

    ...(props.blank && {
      boxShadow: 'none',

      '&:before': {
        display: 'none',
      },
    }),

    '& .Mui-expanded': {
      margin: 0,

      ...(props.rotateIcons && {
        '& svg': {
          transform: 'scaleY(-1)',
        },
      }),
    },
  }),
  summary: ({ blank }) => ({
    padding: `0 ${theme.spacing(1.5)}px`,
    minHeight: '32px !important',
    ...(blank && { padding: 0 }),
  }),
  summaryContent: ({ density }) => ({
    margin: `${theme.spacing(density === 'compact' ? 0.5 : density === 'comfortable' ? 2 : 1)}px 0 !important`,
    maxWidth: '100%',
  }),
  header: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    maxWidth: '100%',
    padding: '0 !important',
  },
  type: {
    margin: 0,
  },
  rightIcon: {
    display: 'flex',
    marginLeft: 'auto',
  },
  arrow: {
    marginRight: theme.spacing(1.5),
    verticalAlign: 'middle',
    transition: theme.transitions.create('transform'),
  },
  details: (props) => ({
    padding: '6px 20px 18px',

    ...(props.blank && {
      padding: `0 0 ${theme.spacing(2.5)}px 4px`,
    }),
  }),
});

export default styles;
