import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_QUOTES, QB_RISK_DETAILS } from 'lib/quoteBind/queryKeys';

// risks
export const useUnBindPolicy = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useUnBindPolicy',
    };

    dispatch(enqueueNotification('notification.unBindPolicy.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, { id: res.riskId });
    queryClient.invalidateQueries(QB_RISK_QUOTES, res.riskId);

    dispatch(enqueueNotification('notification.unBindPolicy.success', 'success'));
  };

  return useMutation(
    async (policy) => {
      const { id } = policy;
      dispatch(hideModal());

      const result = await quoteBindAxios.put(`/quotes/${id}/unbind`);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
