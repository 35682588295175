const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1, 0),
  },
  section: {
    padding: theme.spacing(1, 2),
  },
  groupTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1,
  },
  group: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  groupItem: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(0.5),
  },
  listItem: {
    padding: 0,
  },
  checkbox: {
    padding: theme.spacing(0.25),
  },
});

export default styles;
