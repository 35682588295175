import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getFacilityLimits(facilityId, token) {
  const response = await api(token).get(`limits?facilityId=${facilityId}`);

  return response.data;
}

export function useGetFacilityLimits(queryKey, facilityId, options) {
  return useApi([queryKey, facilityId], async (facilityId, token) => await getFacilityLimits(facilityId, token), {
    ...options,
    cacheTime: 1000 * 60 * 15,
  });
}
