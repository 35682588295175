import { makeStyles } from '@material-ui/core';

export const useFacilityConfigStyles = makeStyles(
  (theme) => ({
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'auto',
    },
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },
    group: {
      margin: theme.spacing(1, 0),
    },
    groupTitle: {
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    field: {
      marginTop: `${theme.spacing(0.5)}px!important`,
      paddingBottom: `${theme.spacing(1)}px!important`,
    },
    actions: {
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      margin: theme.spacing(0),
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    loader: {
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerOverRows: {
      alignItems: 'center',
    },
    headerOverLabel: {
      marginLeft: theme.spacing(1),
    },
    headerOverWrapper: {
      width: 64,
    },
    headerOverSelect: {
      minWidth: '64px!important',
    },
    groupLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    claimBlockActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 0),
    },
  }),
  { name: 'ValidateFilePassword' }
);
