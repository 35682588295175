import { combineReducers } from 'redux';
import commentReducer from './comment/comment.reducers';
import configReducer from './config/config.reducers';
import departmentReducer from './department/department.reducers';
import documentReducer from './document/document.reducers';
import filterReducers from './filters/filters.reducers';
import locationReducer from './location/location.reducers';
import marketReducer from './market/market.reducers';
import marketParentReducer from './marketParent/marketParent.reducers';
import modellingReducer from './modelling/modelling.reducers';
import openingMemoReducer from './openingMemo/openingMemo.reducers';
import parentReducer from './parent/parent.reducers';
import partyReducer from './party/party.reducers';
import policyReducer from './policy/policy.reducers';
import portfolioMapReducer from './portfolioMap/portfolioMap.reducers';
import placementReducer from './placement/placement.reducers';
import referenceDataReducer from './referenceData/referenceData.reducers';
import searchReducer from './search/search.reducers';
import sortReducers from './sort/sort.reducers';
import riskReducer from './risk/risk.reducers';
import tripReducer from './trip/trip.reducers';
import uiReducer from './ui/ui.reducers';
import userReducer from './user/user.reducers';
import whitespaceReducer from './whitespace/whitespace.reducers';
import reportingReducers from './reporting/reporting.reducers';

export * from './analytics/analytics.actions';
export * from './articles/articles.selectors';
export * from './bundled/bundled.actions.patchPolicyPostWhitespace';
export * from './comment/comments.actions.delete';
export * from './comment/comment.actions.get';
export * from './comment/comment.actions.post';
export * from './comment/comment.selectors';
export * from './config/config.actions';
export * from './department/department.actions.deleteMarket';
export * from './department/department.actions.getMarket';
export * from './department/department.actions.getMarkets';
export * from './department/department.actions.postMarket';
export * from './department/department.actions.putMarket';
export * from './department/department.selectors';
export * from './document/document.actions';
export * from './document/document.actions.download';
export * from './document/document.actions.delete';
export * from './document/document.actions.get';
export * from './document/document.actions.upload';
export * from './filters/filters.actions';
export * from './filters/filters.selectors';
export * from './location/location.actions';
export * from './location/location.actions.uploadWizard';
export * from './market/market.actions';
export * from './market/market.selectors';
export * from './marketParent/marketParent.actions.addEditMarkets';
export * from './marketParent/marketParent.actions.getListAll';
export * from './marketParent/marketParent.actions.getList';
export * from './marketParent/marketParent.actions.getPlacements';
export * from './modelling/modelling.actions';
export * from './modelling/modelling.actions.get';
export * from './modelling/modelling.actions.getModellingList';
export * from './modelling/modelling.actions.post';
export * from './modelling/modelling.actions.put';
export * from './modelling/modelling.selectors';
export * from './openingMemo/openingMemo.actions';
export * from './openingMemo/openingMemo.actions.get';
export * from './openingMemo/openingMemo.actions.search';
export * from './openingMemo/openingMemo.actions.getPlacementList';
export * from './openingMemo/openingMemo.actions.patch';
export * from './openingMemo/openingMemo.actions.post';
export * from './openingMemo/openingMemo.actions.postPDF';
export * from './openingMemo/openingMemo.actions.put';
export * from './openingMemo/openingMemo.selectors';
export * from './openingMemo/openingMemo.transform';
export * from './parent/parent.actions';
export * from './parent/parent.actions.get';
export * from './parent/parent.actions.getPlacements';

export * from './party/party.actions.clients';
export * from './party/party.selectors';

export * from './placement/placement.actions';
export * from './placement/placement.actions.addPlacementLayer';
export * from './placement/placement.actions.addPlacementLayerMarket';
export * from './placement/placement.actions.addPlacementMarket';
export * from './placement/placement.actions.updateChangeMarket';
export * from './placement/placement.actions.addPolicy';
export * from './placement/placement.actions.addPolicyMarket';
export * from './placement/placement.actions.bulkUpdate';
export * from './placement/placement.actions.bulkUpdateLayer';
export * from './placement/placement.actions.bulkUpdatePolicy';
export * from './placement/placement.actions.deletePlacementMarket';
export * from './placement/placement.actions.deletePlacementLayer';
export * from './placement/placement.actions.deletePlacementLayerMarket';
export * from './placement/placement.actions.deletePolicy';
export * from './placement/placement.actions.editConfig';
export * from './placement/placement.actions.editPlacement';
export * from './placement/placement.actions.editPlacementLayer';
export * from './placement/placement.actions.editPlacementMarketsLayers';
export * from './placement/placement.actions.editPolicy';
export * from './placement/placement.actions.editQuote';
export * from './placement/placement.actions.getDetails';
export * from './placement/placement.actions.getList';
export * from './placement/placement.actions.getMarkets';
export * from './placement/placement.actions.locations';
export * from './placement/placement.actions.newEnquiry';
export * from './placement/placement.actions.ntu';
export * from './placement/placement.actions.patchPolicy';
export * from './placement/placement.actions.removePlacement';
export * from './placement/placement.actions.signDown';
export * from './placement/placement.actions.bulkdeletePlacementMarket';
export * from './placement/placement.selectors';
export * from './placement/placement.actions.duplicateLine';
export * from './policy/policy.actions';
export * from './policy/policy.actions.get';
export * from './policy/policy.actions.getPlacement';
export * from './portfolioMap/portfolioMap.actions';
export * from './portfolioMap/portfolioMap.actions.get';
export * from './referenceData/referenceData.actions';
export * from './referenceData/referenceData.actions.get';
export * from './referenceData/referenceData.actions.getByType';
export * from './referenceData/referenceData.selectors';
// QB
export * from './risk/risk.actions';

export * from './risk/risk.selectors';

// QB
export * from './search/search.actions.get';
export * from './search/search.actions.reset';
export * from './search/search.selectors';
export * from './sort/sort.actions';
export * from './sort/sort.selectors';
export * from './trip/trip.actions';
export * from './trip/trip.actions.deleteTrip';
export * from './trip/trip.actions.getAddresses';
export * from './trip/trip.actions.getById';
export * from './trip/trip.actions.getLeads';
export * from './trip/trip.actions.getList';
export * from './trip/trip.actions.getMyList';
export * from './trip/trip.actions.postTrip';
export * from './trip/trip.actions.putTrip';
export * from './ui/ui.actions';
export * from './user/user.actions.auth';
export * from './user/user.actions.department';
export * from './user/user.actions.get';
export * from './user/user.selectors';
export * from './whitespace/whitespace.actions.getFilesList';
export * from './whitespace/whitespace.actions.getTemplates';
export * from './whitespace/whitespace.actions.downloadPdf';
export * from './whitespace/whitespace.actions.downloadZip';
export * from './whitespace/whitespace.actions.post';
export * from './reporting/reporting.actions.reportGroup.get';
export * from './reporting/reporting.actions.reportGroup.post';
export * from './reporting/reporting.actions.reportGroup.patch';
export * from './reporting/reporting.actions.reportGroup.delete';
export * from './reporting/reporting.actions.report.get';
export * from './reporting/reporting.actions.report.post';
export * from './reporting/reporting.actions.report.patch';
export * from './reporting/reporting.actions.report.delete';
export * from './reporting/reporting.selectors';
export * from './reporting/reporting.actions.reportDetails';
export * from './reporting/reporting.actions.addReportUser';
export * from './reporting/reporting.actions.deleteReportUser';
export * from './reporting/reporting.actions.documents.get.js';
export * from './reporting/reporting.actions.documents.upload';
export * from './reporting/reporting.actions.uploadFolders';
export * from './reporting/reporting.actions.reportGroup.doc.delete';

export default combineReducers({
  comment: commentReducer,
  config: configReducer,
  department: departmentReducer,
  document: documentReducer,
  filters: filterReducers,
  location: locationReducer,
  market: marketReducer,
  marketParent: marketParentReducer,
  modelling: modellingReducer,
  openingMemo: openingMemoReducer,
  parent: parentReducer,
  party: partyReducer,
  placement: placementReducer,
  policy: policyReducer,
  portfolioMap: portfolioMapReducer,
  referenceData: referenceDataReducer,
  search: searchReducer,
  sort: sortReducers,
  risk: riskReducer,
  trip: tripReducer,
  ui: uiReducer,
  user: userReducer,
  whitespace: whitespaceReducer,
  reporting: reportingReducers,
});
