import { useSubmitPremiumAggregate } from 'lib/binderManagement';

export const useHandleSubmitPremium = ({ aggregate, bdxDocumentsInfo }) => {
  const { mutate: submitAggregate, isLoading: isSubmitting } = useSubmitPremiumAggregate({
    facilityReference: bdxDocumentsInfo.facilityReference,
  });

  const handleSubmit = () => {
    submitAggregate({
      underwriterGroupId: aggregate.underwriterGroupId,
      yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
      settlementCurrency: aggregate.settlementCurrency,
    });
  };

  return { handleSubmit, isSubmitting };
};
