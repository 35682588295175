import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './AddRule.styles';
import { RuleLine } from 'modules/Rules';

export const AddRuleSkeleton = () => {
  const classes = makeStyles(styles, { name: 'AddRule' })();

  return (
    <div className={classes.root} data-testid="AddRuleSkeleton">
      <Grid container spacing={2}>
        <Grid item className={classes.nameWrapper}>
          <Skeleton height={63} />
        </Grid>
        <Grid item className={classes.priorityWrapper}>
          <Skeleton height={63} width={80} />
        </Grid>
      </Grid>
      <div className={classes.skeletonOperation}>
        <Grid container spacing={1} className={classes.skeletonStatement}>
          <Grid item>
            <Skeleton width={400} height={44} />
          </Grid>
          <div className={classes.button}>
            <Skeleton width={140} height={44} />
          </div>
        </Grid>
        <Grid item container spacing={1} className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={63} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={63} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={63} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.skeletonOperation}>
        <Grid container spacing={1} className={classes.skeletonStatement}>
          <Grid item>
            <Skeleton width={400} height={30} />
          </Grid>
          <div className={classes.button}>
            <Skeleton width={140} height={30} />
          </div>
        </Grid>
        <Grid item container spacing={1} className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={68} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={68} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={68} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.submit}>
        <Skeleton height={36} width={77} />
      </div>
    </div>
  );
};
