import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// app
import { ProductsAdminClientsView } from './ProductsAdminClients.view';
import { showModal } from 'stores';
import { useGetClients, useGetRiskCountries, useGetProducts, QB_CLIENTS, RISK_COUNTRIES, QB_PRODUCTS } from 'lib/quoteBind';
import { useGetParentOfficeListAll } from 'lib/admin';
import { productAdminSchema } from 'schemas';
import { usePagination } from 'hooks';
import * as utils from 'utils';
import config from 'config';

export default function ProductsAdminClients() {
  const dispatch = useDispatch();
  const [schema, setSchema] = useState({});
  const [clientsParams, setClientsParams] = useState({ size: config.ui.pagination.default });

  const { data: clientsData, isLoading: clientsLoading } = useGetClients(QB_CLIENTS, clientsParams);
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { data: programmesProductsData } = useGetProducts(QB_PRODUCTS);
  const { isLoading: officeListLoading, data: officeList } = useGetParentOfficeListAll();

  const clients = useMemo(() => clientsData?.content || [], [clientsData]);
  const clientsPagination = {
    page: clientsData?.pagination?.page ? clientsData?.pagination?.page - 1 : 1,
    rowsTotal: clientsData?.pagination?.totalElements || 0,
    rowsPerPage: clientsData?.pagination?.size || 0,
  };

  const products = useMemo(() => programmesProductsData?.data || [], [programmesProductsData]);

  const hydrateLabels = (fields) => fields.map((field) => ({ ...field, label: utils.string.t(field.label) }));
  // get clients offices, display from array based on id

  useEffect(
    () => {
      if (!clients) return;

      const { fields, ...rest } = productAdminSchema.getSchema('clients');

      setSchema({
        ...rest,
        fields: hydrateLabels(fields),
        items: clients,
        pagination: clientsPagination,
      });
    },
    [clients] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangePage = (newPage) => {
    setClientsParams((params) => ({ ...params, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setClientsParams((params) => ({ ...params, size: rowsPerPage }));
  };

  const handleAddClient = (fields) => (event) => {
    dispatch(
      showModal({
        component: 'ADD_PRODUCTS_CLIENT',
        props: {
          title: 'products.admin.clients.add',
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            productsAdmin: true,
            fields,
          },
        },
      })
    );
  };

  const handleEditClient = (data) => {
    dispatch(
      showModal({
        component: 'EDIT_PRODUCTS_CLIENT',
        props: {
          title: 'products.admin.clients.edit',
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            productsAdmin: true,
            id: data?.id,
          },
        },
      })
    );
  };

  const popoverActions = [
    {
      id: 'products-admin-edit-insured',
      label: 'products.admin.clients.edit',
      callback: (data) => handleEditClient(data),
    },
  ];

  const pagination = usePagination(schema.items, schema.pagination, handleChangePage, handleChangeRowsPerPage);

  // abort
  if (!schema || !schema.items) return null;

  return (
    <ProductsAdminClientsView
      schema={schema}
      countries={countries}
      officeList={officeList}
      products={products}
      loading={clientsLoading || officeListLoading}
      pagination={pagination.obj}
      popoverActions={popoverActions}
      handlers={{
        addClient: handleAddClient,
        changePage: pagination.handlers.handleChangePage,
        changeRowsPerPage: pagination.handlers.handleChangeRowsPerPage,
      }}
    />
  );
}
