export const DROOLS_RULE_COLUMNS = 'DROOLS_RULE_COLUMNS' as const;
export const DROOLS_LIMIT_COLUMNS = 'DROOLS_LIMIT_COLUMNS' as const;
export const DROOLS_RULES_ROW_ACTIONS = 'DROOLS_RULES_ROW_ACTIONS' as const;
export const DROOLS_FACILITY_BDX_TYPES = 'DROOLS_FACILITY_BDX_TYPES' as const;
export const DROOLS_RISK_CODES = 'DROOLS_RISK_CODES' as const;
export const DROOLS_RULESETS = 'DROOLS_RULESETS' as const;
export const DROOLS_LIMITSETS = 'DROOLS_LIMITSETS' as const;
export const DROOLS_LIMIT_FAVOURITES = 'DROOLS_LIMIT_FAVOURITES' as const;
export const DROOLS_TEMPLATES = 'DROOLS_TEMPLATES' as const;
export const DROOLS_DOWNLOAD_RULES = 'DROOLS_DOWNLOAD_RULES' as const;

export const FACILITY_BINDER_FACILITIES = 'FACILITY_BINDER_FACILITIES' as const;
export const FACILITY_BINDER_SINGLE_FACILITY = 'FACILITY_BINDER_SINGLE_FACILITY' as const;
export const FACILITY_BINDER_MARKETS = 'FACILITY_BINDER_MARKETS' as const;
export const FACILITY_BINDER_COVERHOLDERS = 'FACILITY_BINDER_COVERHOLDERS' as const;
export const FACILITY_COVERHOLDER_FILTERS = 'FACILITY_COVERHOLDER_FILTERS' as const;
export const FACILITY_COVERHOLDER_FACILITY_FILTERS = 'FACILITY_COVERHOLDER_FACILITY_FILTERS' as const;
export const FACILITY_MARKET_FILTERS = 'FACILITY_MARKET_FILTERS' as const;
export const FACILITY_MARKET_FACILITY_FILTERS = 'FACILITY_MARKET_FACILITY_FILTERS' as const;
export const FACILITY_BINDER_FACILITIES_USERS = 'FACILITY_BINDER_FACILITIES_USERS' as const;
export const FACILITY_BINDER_DOCUMENT_FOLDERS = 'FACILITY_BINDER_DOCUMENT_FOLDERS' as const;
export const FACILITY_BINDER_DOCUMENT = 'FACILITY_BINDER_DOCUMENT' as const;
export const FACILITY_BINDER_CONFIG = 'FACILITY_BINDER_CONFIG' as const;
export const FACILITY_THIRD_PARTIES = 'FACILITY_THIRD_PARTIES' as const;
export const FACILITY_CLAIM_BLOCK = 'FACILITY_CLAIM_BLOCK' as const;
export const FACILITY_THIRD_PARTY_OFFICES = 'FACILITY_THIRD_PARTY_OFFICES' as const;

export const ADMIN_BINDER_MANAGEMENT_USER = 'ADMIN_BINDER_MANAGEMENT_USER' as const;
export const ADMIN_BINDER_MANAGEMENT_ALL_USER_FACILITIES = 'ADMIN_BINDER_MANAGEMENT_ALL_USER_FACILITIES' as const;

export const BDX_REQUIRED_BDX = 'BDX_REQUIRED_BDX' as const;
export const BDX_DATA = 'BDX_DATA' as const;
export const BDX_PREMIUM_DATA = 'BDX_PREMIUM_DATA' as const;
export const BDX_CLAIM_DATA = 'BDX_CLAIM_DATA' as const;
export const BDX_UPLOADED_DATA = 'BDX_UPLOADED_DATA' as const;
export const BDX_SANCTION_EXCEPTIONS = 'sanctionExceptions' as const;
export const BDX_VALIDATION_EXCEPTIONS = 'validationExceptions' as const;
export const BDX_EXCEPTIONS_COUNT = 'BDX_EXCEPTIONS_COUNT' as const;
export const BDX_UNDERWRITER_GROUPS = 'BDX_UNDERWRITER_GROUPS' as const;
export const BDX_UNMAPPED_FILES = 'BDX_UNMAPPED_FILES' as const;
export const BDX_UNMAPPED_LINES = 'BDX_UNMAPPED_LINES' as const;
export const BDX_UN_SUBMITTED_LINES = 'BDX_UN_SUBMITTED_LINES' as const;

export const BDX_UN_SUBMITTED_DATA = 'BDX_UN_SUBMITTED_DATA' as const;
export const BDX_FACILITY_AGGREGATES = 'BDX_FACILITY_AGGREGATES' as const;

export const BM_SEARCH = 'BM_SEARCH' as const;

export const BDX_AGGREGATES = 'BDX_AGGREGATES' as const;

export const LINEAGE_YEAR_MONTH = 'LINEAGE_YEAR_MONTH' as const;
export const LINEAGE_YEAR_MONTH_DETAILS = 'LINEAGE_YEAR_MONTH_DETAILS' as const;
export const BDX_LINEAGE_DETAILS = 'BDX_LINEAGE_DETAILS' as const;
export const BDX_LINEAGE_SUMMARY = 'BDX_LINEAGE_SUMMARY' as const;
export const BDX_LINEAGE_PROCESSING = 'BDX_LINEAGE_PROCESSING' as const;
export const BDX_LINEAGE_AGGREGATES_EXISTS = 'BDX_LINEAGE_AGGREGATES_EXISTS' as const;
export const LINEAGE_MAPPING = 'LINEAGE_MAPPING' as const;
export const BDX_IMPORT_MAPPING = 'BDX_IMPORT_MAPPING' as const;
