import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_UPLOADED_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetUploadedBdx = ({ bdxDocumentsInfo, page = 0, size = 1000 }) => {
  const params = { page, size };
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxData' });

  return useQuery(
    [BDX_UPLOADED_DATA, bdxDocumentsInfo, params],
    async () => {
      const { facilityReference, yearMonthSubmission, bdxType } = bdxDocumentsInfo;
      if (!facilityReference || !yearMonthSubmission || !bdxType) throw new Error('Missing required params');

      const url = `umr/${facilityReference}/${yearMonthSubmission}/${bdxType}`;

      const result = await binderManagementBDXAxios.get(url, { params });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
