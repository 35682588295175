import { FC, useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

import { Button } from 'components';

import * as utils from 'utils';

import { ISumColumnsProps } from '../../AddBinderLimit.types';

import { SumColumn } from './SumColumn';

import { sumcolumnsStyles } from './SumColumns.styles';

export const SumColumns: FC<ISumColumnsProps> = ({ methods, limit, limitColumns, limitColumnsFiltered }) => {
  const classes = sumcolumnsStyles();
  const { limit: limitValue, limitAlertAtPercentage } = limit || {};
  const { control } = methods;
  const {
    fields: sumColumns,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'sumColumns',
  });

  const removeSumColumn = (index) => {
    remove(index);
  };
  const addLimit = useCallback(() => {
    append({
      field: null,
      operation: '',
      limit: null,
      limitAlertAtPercentage: null,
    });
  }, [append]);

  useEffect(() => {
    if (limit) {
      remove();
      append(limit.sumColumns.map((column) => ({ name: column, column, limit: limit.limit, limitAlertAtPercentage })));
    } else {
      addLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, append, limitColumns, addLimit]);

  return (
    <div className={classes.sumColumns}>
      <Grid container spacing={1} className={classes.sumColumnsStatement}>
        <Grid item>
          <Typography variant="h2">{utils.string.t('binderLimits.sumColumns.title')}</Typography>
        </Grid>
        <div className={classes.button}>
          <Button
            text={utils.string.t('binderLimits.sumColumns.addColumn')}
            onClick={addLimit}
            icon={PostAdd}
            variant="contained"
            color="secondary"
            size="small"
          />
        </div>
      </Grid>
      {sumColumns?.map((sumColumn, sumColumnIndex) => (
        <SumColumn
          key={sumColumn.id}
          isFirst={sumColumnIndex === 0}
          isLast={sumColumns?.length === 1}
          sumColumn={sumColumn}
          limitColumns={limitColumnsFiltered}
          limitValue={limitValue}
          limitAlertAtPercentage={limitAlertAtPercentage}
          methods={methods}
          prefix={`sumColumns.${sumColumnIndex}`}
          handleRemoveCondition={() => removeSumColumn(sumColumnIndex)}
        />
      ))}
    </div>
  );
};
