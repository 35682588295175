import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const usePutEndorsements = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.usePutEndorsements',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    // queryClient.invalidateQueries(QB_RISK_ENDORSEMENTS, res.riskId);
    queryClient.setQueryData([QB_RISK_ENDORSEMENTS, res?.riskId], (oldData) => {
      return oldData?.map((endorsement) => {
        if (endorsement.id === res?.id) {
          return res;
        }
        return endorsement;
      });
    });

    dispatch(enqueueNotification('notification.putEndorsements.success', 'success'));
  };

  return useMutation(
    async ({ endorsementId }) => {
      if (!endorsementId) {
        throw new Error('Invalid data');
      }
      const path = `endorsements/${endorsementId}/accept`;

      const result = await quoteBindAxios.put(path);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
