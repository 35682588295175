import { FC, useState, useEffect } from 'react';
import { usePagination } from 'hooks';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';

import { FormGrid, Pagination } from 'components';
import { RISK_DISPLAY_CARD, BINDERS, FACILITIES } from 'consts';
import { resetFilter } from 'stores';
import { useGetFacilities } from 'lib/binderManagement';
import * as utils from 'utils';
import config from 'config';

import { FacilityCardList } from './FacilityCardList';
import { FacilityTableList } from './FacilityTableList';

export const Facilities: FC<IFacilitiesProps> = ({ displayStyle, sort, filters, entity, type }) => {
  const dispatch = useDispatch();
  const input = type === 'market' ? { marketId: entity?.id } : { coverHolderId: entity?.id };
  const [params, setParams] = useState({ sortBy: sort?.by, sortAscending: sort?.direction === 'asc', page: null, size: 48 });

  const { data: facilities, isLoading: isListLoading } = useGetFacilities({ ...input, ...params, enabled: !!entity });

  const handleChangePage = (newPage) => {
    setParams({ ...params, page: newPage });
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setParams({ ...params, size: rowsPerPage });
  };

  useEffect(() => {
    return () => {
      dispatch(resetFilter({ page: FACILITIES.toLowerCase(), category: BINDERS.toLowerCase() }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParams((prev) => ({ ...prev, sortBy: sort?.by, sortAscending: sort?.direction === 'asc' }));
  }, [sort]);

  useEffect(() => {
    if (filters && Object.keys(filters)?.length > 0) {
      const newParams = Object.keys(filters)?.map((key) => {
        switch (key) {
          case 'statuses':
            return { status: map(filters[key], 'name').join(',') };
          case 'umrs':
            return { umr: map(filters[key], 'name').join(',') };
          case 'inceptionDate':
            return { inceptionDateStart: filters[key]?.startDate, inceptionDateEnd: filters[key]?.endDate };
          case 'expiryDate':
            return { expiryDateStart: filters[key]?.startDate, expiryDateEnd: filters[key]?.endDate };
          default:
            return { [key]: map(filters[key], 'value').join(',') };
        }
      });
      const obj = Object.assign({}, ...newParams);
      setParams((prev) => ({ ...prev, page: 0, ...obj }));
    } else {
      setParams((prev) => ({ sortBy: prev.sortBy, sortAscending: prev.sortAscending, page: prev.page, size: prev.size }));
    }
  }, [filters, sort]);

  const pagination = usePagination(
    facilities?.content,
    utils.generic.getPaginationObj(facilities),
    handleChangePage,
    handleChangeRowsPerPage
  );

  return (
    <>
      {displayStyle === RISK_DISPLAY_CARD ? (
        <FacilityCardList
          entity={{ ...(entity ?? {}), type }}
          facilities={facilities}
          isListLoading={isListLoading || !entity}
          type={type}
        />
      ) : (
        <FacilityTableList
          entity={{ ...(entity ?? {}), type }}
          facilities={facilities}
          isListLoading={isListLoading || !entity}
          type={type}
          sort={sort}
        />
      )}
      <FormGrid container data-testid="pagination">
        <FormGrid item xs={12} sm={12}>
          <Pagination
            page={pagination.obj.page}
            count={pagination.obj.rowsTotal}
            rowsPerPage={pagination.obj.rowsPerPage}
            rowsPerPageOptions={config.ui.gridPagination.options}
            onChangePage={pagination.handlers.handleChangePage}
            onChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
          />
        </FormGrid>
      </FormGrid>
    </>
  );
};
