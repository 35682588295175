import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';

// app
import { useWebSockets } from 'hooks';
import { Accordion } from 'components';
import { GetProcessedRowsTable } from './GetProcessedRowsTable';
import { useDeleteWebSocketConnection } from 'lib/binderManagement';

export const ProcessedRowsAccordion = ({
  id,
  facilityReference,
  fullView,
  fullScreen,
  toggleFullScreen,
  ProcessedRowsAccordionDetails,
  handleAccordion,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasProcessedRowsData, setHasProcessedRowsData] = useState(false);
  const [processedRowsData, setProcessedRowsData] = useState({
    headers: [],
    rows: [],
    exceptions: [],
    totalRows: 0,
    ruleName: '',
    message: '',
    contactAdmin: false,
    sessionId: '',
  });

  const client = useRef();
  const subscription = useRef();
  const { connectWebSocket } = useWebSockets();
  const { mutate: deleteWebSocketConnection } = useDeleteWebSocketConnection();

  const endpoint = useSelector((state) => get(state, 'config.vars.endpoint'));
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = getAccessTokenSilently();
  const progress =
    processedRowsData.rows?.length > 0 && processedRowsData.totalRows > 0
      ? (processedRowsData.rows?.length / processedRowsData.totalRows).toFixed(2) * 100
      : 0;

  useEffect(() => {
    if (expanded && !hasProcessedRowsData) {
      const { stompClient, sessionId } = connectWebSocket(`${endpoint.bm.fileProcessing}/ws/v1/bm?requestId=${id}`);
      client.current = stompClient;
      client.current.connect({ Authorization: `Bearer ${accessToken}` }, function (frame) {
        console.log('Connected: ' + frame);
        subscription.current = client.current.subscribe(`/topic/${sessionId}`, function (message) {
          let res = JSON.parse(message.body);

          if (progress === 100) {
            client.current.disconnect();
            client.current.unsubscribe(`/topic/${sessionId}`);
          }

          const newRows = res?.processedRows || [];
          setProcessedRowsData((prevData) => ({
            headers: res?.headers,
            rows: [...prevData.rows, ...newRows],
            totalRows: res?.totalRows,
            exceptions: res?.exceptions,
            ruleName: res?.ruleName,
            message: res?.message,
            contactAdmin: res?.contactAdmin,
            sessionId,
          }));

          setHasProcessedRowsData(true);
        });
      });
      return () => {
        subscription.current?.unsubscribe(`/topic/${sessionId}`);
        client.current?.disconnect();
        deleteWebSocketConnection({ sessionId });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, hasProcessedRowsData]);

  return (
    <Accordion
      icon={true}
      expanded={expanded}
      title={{ text: facilityReference, type: 'h2' }}
      style={{ margin: '10px' }}
      onChange={() => handleAccordion(expanded, setExpanded)}
      data-testid={'accordion-panel'}
    >
      <ProcessedRowsAccordionDetails>
        <GetProcessedRowsTable
          key={id}
          fullView={fullView}
          fullScreen={fullScreen}
          toggleFullScreen={toggleFullScreen}
          data={processedRowsData}
          progress={progress}
          datatest-id={'processed-rows-table'}
        />
      </ProcessedRowsAccordionDetails>
    </Accordion>
  );
};
