export const styles = (theme) => ({
  popper: {
    boxShadow: theme.shadows[2],
    borderRadius: 3,
    zIndex: 9999,
    fontSize: 13,
    background: theme.palette.primary.main,
    overflow: 'hidden',
  },
  paper: {
    backgroundColor: theme.palette.primary.contrastText,
    position: 'relative',
  },
  calendarTitle: {
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    fontWeight: 600,
  },
  buttonBox: {
    padding: 10,
    backgroundColor: '#fff',
    borderTop: `1px solid #eff2f7`,
  },
  defaultBtn: {
    background: theme.palette.neutral.light,
    // hover
    '&:hover': {
      background: theme.palette.neutral.light,
    },
  },
  clearButton: {
    fontSize: '0.8rem',
    color: '#9e9e9e',
    fontWeight: 600,
    cursor: 'pointer',
  },
  activeBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  button: {
    fontSize: 16,
    width: '100%',
    textAlign: 'left',
    fontWeight: 600,
    '& p': {
      width: '100%',
      fontSize: 16,
      fontWeight: 600,
    },
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  selectedValue: {
    fontSize: 14,
  },
});
