import React, { useState } from 'react';

import UploadBDXView from './UploadBDX.view';
import { UploadBDXContextProvider } from './UploadBDX.context';
import { UPLOAD_BDX_STEPS } from 'consts';
import * as utils from 'utils';

export default function UploadBDX({
  facility,
  yearMonthSubmission,
  bdxType,
  file,
  isTest,
  fullScreen,
  thirdPartyId,
  sanctionsCheckOnly = false,
  callbackOnSuccess = null,
  fileRequestId = null,
}) {
  const [activeStep, setActiveStep] = useState(UPLOAD_BDX_STEPS.UPLOAD_FILE);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState({
    [UPLOAD_BDX_STEPS.UPLOAD_FILE]: false,
    [UPLOAD_BDX_STEPS.FIELD_MAPPING]: false,
    [UPLOAD_BDX_STEPS.SUMMARY]: false,
  });

  const getHandleSetStep = (step) => () => setActiveStep(step);
  const skiptoLastStep = () => setActiveStep(UPLOAD_BDX_STEPS.SUMMARY);
  const getHandleCompleteStep = (step) => () => setCompleted((c) => ({ ...c, [step]: true }));

  const steps = [
    {
      id: UPLOAD_BDX_STEPS.UPLOAD_FILE,
      label: utils.string.t('form.bdx.uploadBdx'),
    },
    {
      id: UPLOAD_BDX_STEPS.FIELD_MAPPING,
      label: utils.string.t('form.bdx.fieldMapping'),
    },
    {
      id: UPLOAD_BDX_STEPS.SUMMARY,
      label: utils.string.t('form.bdx.summary'),
    },
  ];

  const progress = (activeStep / (steps.length - 1)) * 100;

  return (
    <UploadBDXContextProvider
      bdxType={bdxType}
      facility={facility}
      isTest={isTest}
      sanctionsCheckOnly={sanctionsCheckOnly}
      yearMonthSubmission={yearMonthSubmission}
      fileRequestId={fileRequestId}
      thirdPartyId={thirdPartyId}
    >
      <UploadBDXView
        file={file}
        steps={steps}
        activeStep={activeStep}
        completed={completed}
        isLoading={isLoading}
        error={error}
        progress={progress}
        fullScreen={fullScreen}
        handlers={{ getHandleSetStep, skiptoLastStep, setLoading, getHandleCompleteStep, setError }}
        callbackOnSuccess={callbackOnSuccess}
        isTest={isTest}
      />
    </UploadBDXContextProvider>
  );
}
