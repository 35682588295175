const styles = (theme) => ({
  quote: {
    '& .MuiTableCell-root': {
      paddingTop: 4,
      paddingBottom: 4,
      color: theme.palette.neutral.dark,
      borderBottomStyle: 'dotted',

      '&:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    '& .MuiTableRow-root:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 0,
      },
    },
  },
  card: {
    backgroundColor: theme.palette.grey[100],
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    borderRadius: '5px',
    position: 'relative',
    height: '100%',
    padding: 0,
    minWidth: 'fit-content',
  },
  cardHeader: {
    borderBottom: `4px solid ${theme.palette.status.blocked}`,
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 0,
  },
  cardTitleHeading: {
    fontWeight: 600,
    color: 'white',
    marginBottom: 0,
  },
  cardContent: {
    paddingTop: '16px!important',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'white',
  },
  cardActions: {
    marginTop: '0px!important',
    paddingTop: '16px!important',
    paddingBottom: '16px!important',
    justifyContent: 'flex-end',
  },
  sectionMessage: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.neutral.darkest,
    fontSize: theme.typography.pxToRem(13),
  },
  icon: {
    color: theme.palette.status.blocked,
  },
});

export default styles;
