import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_TEMPLATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetRuleTemplates = (bdxType) => {
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetRuleTemplates' });

  return useQuery(
    [DROOLS_TEMPLATES, bdxType],
    async () => {
      if (!bdxType) {
        throw new Error('bdxType is required');
      }

      const result = await binderManagementDroolsAxios.get(`/drools/templates/${bdxType}`);

      return result?.data;
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
