import orderBy from 'lodash/orderBy';

// app
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { PAGE_SIZE } from 'consts/quote-bind';

import { QB_PRICER_MODULES, QB_USERS } from 'lib/quoteBind/queryKeys';

// QB

async function getPricerModule(params, token, apiURL) {
  const response = await api(token).get(apiURL, {
    params: {
      orderBy: params.orderBy || 'name',
      direction: params.direction || 'ASC',
      page: params.page || 1,
      size: params.size || PAGE_SIZE,
      ...params,
    },
  });
  return response.data;
}

export function useGetPricerModules(productCode, params, options) {
  const apiURL = productCode ? `/facilities/modules?productCode=${productCode}` : `/facilities/modules`;

  return useApi(
    [QB_PRICER_MODULES, { ...productCode }, { ...params }],
    async (params, token) => await getPricerModule(params, token, apiURL),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      ...options,
    }
  );
}

async function getQBUsers(token) {
  const response = await api(token).get('users');
  return orderBy(response.data, 'name', 'asc');
}

export function useGetQBUsers(options) {
  return useApi([QB_USERS], async (_, token) => await getQBUsers(token), {
    cacheTime: 1000 * 60 * 60 * 24,
    ...options,
  });
}
