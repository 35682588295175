import { useSubmitClaimsAggregate } from 'lib/binderManagement';

export const useHandleSubmitClaims = ({ aggregate, bdxDocumentsInfo }) => {
  const { mutateAsync: submitAggregate, isLoading: isSubmitting } = useSubmitClaimsAggregate();

  const handleSubmit = () => {
    submitAggregate({
      underwriterGroupId: aggregate.underwriterGroupId,
      claimBlockId: aggregate.ucr,
      yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
      settlementCurrency: aggregate.settlementCurrency,
      facilityReference: bdxDocumentsInfo.facilityReference,
    });
  };

  return { handleSubmit, isSubmitting };
};
