import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

export function BrandPopover({ brands, selectedBrand, handleBrandChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (brands?.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBrandClick = (brand) => {
    handleBrandChange(brand);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div data-testid="brand-popover">
      <Button
        aria-describedby={id}
        onClick={handleClick}
        style={{
          width: 'auto',
          maxWidth: '100%',
          height: '60px',
        }}
      >
        <img
          style={{ display: 'inline-block', height: 'auto', maxWidth: '100%' }}
          src={selectedBrand?.brandLogoUrl}
          alt={selectedBrand.brandName}
          aria-describedby={id}
          onClick={handleClick}
        />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
          {brands.map((brand) => (
            <Button key={brand.brandId} onClick={() => handleBrandClick(brand.brandId)}>
              <img
                src={brand.brandLogoUrl}
                alt={brand.brandName}
                style={{ display: 'inline-block', height: '60px', width: 'auto', maxWidth: '100%', padding: '0 10px' }}
              />
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
}
