import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectRiskListSortOptions, selectRiskListFilters } from 'stores';
import { QuoteBindList } from 'modules';
import { useGetRiskList, QB_RISK_LIST, QB_RISK_MY_RISK_LIST } from 'lib/quoteBind';
import * as utils from 'utils';

export const QuoteBindRiskList = ({ cols, riskProducts, displayStyle, myRisk = false }) => {
  const [params, setParams] = useState({
    page: 1,
    size: 12,
    direction: 'desc',
    orderBy: 'id',
    userIds: myRisk ? 'MY_RISKS' : undefined,
  });

  const riskSort = useSelector(selectRiskListSortOptions);
  const selectedRiskFilters = useSelector(selectRiskListFilters);

  const formatSelectedFilters = (filters) => {
    const clientsFilter = filters?.clients?.length ? filters.clients.map((client) => client.value) : null;
    const productsFilter = filters?.products?.length ? filters.products.map((product) => product.value) : null;
    const inceptionStartDate = filters?.inceptionDate?.startDate ? utils.date.startOf(filters.inceptionDate.startDate, 'day') : null;
    const inceptionEndDate = filters?.inceptionDate?.endDate ? utils.date.endOf(filters.inceptionDate.endDate, 'day') : null;
    const expiryStartDate = filters?.expiryDate?.startDate ? utils.date.startOf(filters.expiryDate.startDate, 'day') : null;
    const expiryEndDate = filters?.expiryDate?.endDate ? utils.date.endOf(filters.expiryDate.endDate, 'day') : null;
    const searchQuery = filters?.searchQuery || null;
    const riskStatus = filters?.riskStatus?.length ? filters.riskStatus.map((status) => status.value) : null;

    return {
      ...(utils.generic.isValidArray(clientsFilter, true) && { clients: clientsFilter.join() }),
      ...(utils.generic.isValidArray(productsFilter, true) && { products: productsFilter.join() }),
      ...(utils.generic.isValidArray(riskStatus, true) && { riskStatus: riskStatus.join() }),
      ...(inceptionStartDate && { inceptionStartDate }),
      ...(inceptionEndDate && { inceptionEndDate }),
      ...(expiryStartDate && { expiryStartDate }),
      ...(expiryEndDate && { expiryEndDate }),
      ...(searchQuery && { query: searchQuery }),
    };
  };

  useEffect(() => {
    setParams((prev) => ({
      ...formatSelectedFilters(selectedRiskFilters),
      orderBy: riskSort?.by,
      direction: riskSort?.direction,
      userIds: myRisk ? 'MY_RISKS' : undefined,
      page: selectedRiskFilters?.page ? selectedRiskFilters.page : prev?.page || 1,
      size: prev?.size || 12,
    }));
  }, [riskSort, myRisk, selectedRiskFilters]);

  const { data: riskListData, isLoading } = useGetRiskList(myRisk ? QB_RISK_MY_RISK_LIST : QB_RISK_LIST, params);

  const riskList = riskListData?.content || [];
  const riskListPagination = riskListData?.pagination || {};

  const paginationObj = {
    page: params.page - 1,
    rowsTotal: riskListPagination.totalElements || 0,
    rowsPerPage: params.size,
  };

  const searchQuery = useSelector((state) => state.risk.searchQuery);

  const handleChangePage = (_, newPage) => {
    setParams((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({ ...prev, page: 1, size: event.target.value }));
  };

  return (
    <QuoteBindList
      displayStyle={displayStyle}
      cols={cols}
      riskListLoading={isLoading}
      risks={riskList}
      riskProducts={riskProducts}
      itemsTotal={riskListPagination?.totalElements || 0}
      searchQuery={searchQuery}
      pagination={paginationObj}
      handlers={{
        changePage: handleChangePage,
        changeRowsPerPage: handleChangeRowsPerPage,
      }}
    />
  );
};
