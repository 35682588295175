import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import { PAGE_SIZE } from 'consts/quote-bind';

async function getInsureds(params, token, apiURL) {
  const response = await api(token).get(apiURL, {
    params: {
      orderBy: params.orderBy || 'name',
      direction: params.direction || 'ASC',
      page: params.page || 1,
      size: params.size || PAGE_SIZE,
      ...params,
    },
  });
  return response.data;
}

export function useGetInsureds(queryKey, params, options, apiURL = 'insured') {
  return useApi([queryKey, { ...params }], async (params, token) => await getInsureds(params, token, apiURL), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
