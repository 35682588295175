// app
import { string, generic } from 'utils';
import { tabTableSchema, articleSchema } from './schemas.yup';
import difference from 'lodash/difference';

export const validateSchema = ({ schema }) => {
  return tabTableSchema.isValidSync(schema);
};

const createColumns = (columnHeaders, i18nPath) => {
  return columnHeaders.map((column) => ({
    ...column,
    label: string.t(`${i18nPath}.columnNames.${column.id}`),
  }));
};

const getCompletedCount = (rows, columnToCount) => {
  return rows
    .filter((row) => {
      const cell = row.cells.find((cells) => cells.columnName === columnToCount);
      return cell && cell.value;
    })
    .filter((i) => !!i).length;
};

const createContent = (tabNames, data, columnToCount, i18nPath, content) => {
  let rows = [];
  const tabs = tabNames.map((tabKey) => {
    const sectionRows = content[tabKey].map((row) => createRow(data, row, `${i18nPath}.${tabKey}.rows`, tabKey));
    const rowCount = getCompletedCount(rows, columnToCount);
    rows = [...rows, ...sectionRows];
    return {
      label: `${i18nPath}.${tabKey}.label`,
      value: tabKey,
      ...(columnToCount && { total: rows.length, complete: rowCount }),
    };
  });
  return { rows, tabs };
};

const createRow = (data, { rowKey, rowStyles = {}, cells = [] }, labelPath, tabKey) => {
  return {
    rowKey,
    rowStyles,
    tabKey: tabKey,
    cells: cells.map((cell) => {
      const { component = {}, cellProps = {} } = cell;
      const item = data.lineItems && data.lineItems.find((item) => item.itemKey === rowKey);
      return {
        ...(cell.label ? { label: `${labelPath}.${rowKey}.label` } : {}),
        columnName: cell.name,
        name: `lineItems.${rowKey}.${cell.name}`,
        ...(component && { ...component }),
        ...(cellProps && { cellProps }),
        value: item ? item[cell.name] : component.defaultValue,
      };
    }),
  };
};

const formatArticle = (article) => {
  const { id, heading, organisation_logo_url, excerpt, featured_image_url, external_url, date, topics } = article;
  return { id, heading, organisation_logo_url, excerpt, featured_image_url, external_url, date, topics };
};

const utilsSchemas = {
  parseArticles: (articles) => {
    return articles.filter((article) => articleSchema.isValidSync(article)).map((article) => formatArticle(article));
  },

  parseOpeningMemo: (schema, data) => {
    if (!validateSchema({ schema })) return;
    const { tabNames, defaultTab, columnHeaders, columnToCount, i18nPath, content } = schema;
    const hydratedColumns = createColumns(columnHeaders, i18nPath);
    const { tabs, rows } = createContent(tabNames, data, columnToCount, i18nPath, content);
    return { tabs, defaultTab, columnHeaders: hydratedColumns, rows };
  },

  removeIcons: (fields) => {
    if (generic.isInvalidOrEmptyArray(fields)) return [];

    return fields.map((field) => {
      const fieldCopy = { ...field };
      delete fieldCopy.icon;

      return fieldCopy;
    });
  },
  checkDependencies: (dependencies, options) => difference(dependencies, Object.keys(options)).length === 0,
};

export default utilsSchemas;
