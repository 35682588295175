import React from 'react';
import PropTypes from 'prop-types';

// app
import styles from './AddProductsCarrier.styles';
import { Form } from 'components';

// mui
import { makeStyles } from '@material-ui/core';

AddProductsCarrierView.propTypes = {
  fields: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export function AddProductsCarrierView({ fields, actions, defaultValues, validationSchema }) {
  const classes = makeStyles(styles, { name: 'AddProductsCarrier' })();

  return (
    <div className={classes.root}>
      <div>
        <Form
          id="add-products-carrier"
          type="dialog"
          fields={fields}
          actions={actions}
          defaultValues={defaultValues}
          validationSchema={validationSchema}
        />
      </div>
    </div>
  );
}
