import { makeStyles } from '@material-ui/core/styles';

export const useQuoteCardStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
    minHeight: 262,
  },
  card: {
    backgroundColor: theme.palette.grey[100],
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    borderRadius: '5px',
    position: 'relative',
    height: '100%',
    padding: 0,
    minWidth: 'fit-content',
  },
  cardArray: {
    padding: 10,
  },
  cardTitle: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitleHeading: {
    fontWeight: 600,
    color: 'white',
    marginBottom: 0,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px!important',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'white',
    minHeight: '100px',
  },
  cardContentCommission: {
    paddingTop: '16px!important',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'white',
    minHeight: '120px',
  },
  cardContentOptionals: {
    height: '8rem',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'white',
  },
  checkBox: {
    paddingBottom: 12,
  },
  label: {
    color: 'black!important',
  },
  cardActions: {
    marginTop: '0px!important',
    paddingTop: '16px!important',
    paddingBottom: '16px!important',
  },
  editIcon: {
    color: 'white',
    fontSize: theme.typography.pxToRem(18),
  },

  policyBox: {},
  cardPolicyTitle: {
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
  },
  cardFacility: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 0,
  },
  quoteValue: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 600,
  },
  issues: {
    fontSize: '1.2rem',
    color: theme.palette.error.main,
  },
  btnMarginLeft: {
    marginLeft: 10,
  },
  buttonProgress: {
    position: 'absolute',
    top: 5,
    left: 5,
    color: 'white',
  },
  buttonDownloading: {
    paddingLeft: '22px!important',
  },
  infoIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  infoGrid: {
    minWidth: 150,
  },
  ntu: {
    backgroundColor: theme.palette.status.ntu,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.status.ntu,
    },
  },
  title: {
    color: 'black!important',
  },
  value: {
    color: 'black!important',
    fontWeight: 600,
    textAlign: 'left',
  },
  boldTitle: {
    color: `${theme.palette.neutral.darker}!important`,
    fontWeight: 600,
  },
  icon: {
    padding: '0px',
  },
}));
