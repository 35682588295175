import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Empty } from 'components';

import { BDX_AGGREGATE_TYPE_CLAIM, BDX_TYPE_CLAIM, PUBLISHED } from 'consts';
import {
  useGetBdxAggregate,
  useGetBdxClaimData,
  useGetClaimBlocks,
  useGetFacilityConfig,
  useGetUploadedBdx,
  useSubmitAllAggregates,
} from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { ClaimBlock, getAssignToBlockComponent, ProcessingControls } from './components';
import { ProcessingContextProvider } from './Processing.context';
import { IProcessingProps } from './Processing.types';
import { getClaimAggregates } from './utils';

import { useBDXProcessingStyles } from './Processing.styles';

export const ClaimProcessing: FC<IProcessingProps> = ({ bdxDocumentsInfo, tab }) => {
  const classes = useBDXProcessingStyles();
  const dispatch = useDispatch();
  const [hasUnallocatedClaimBlock, setHasUnallocatedClaimBlock] = useState(false);
  const [hasUnpublishedBDX, setHasUnpublishedBDX] = useState(false);

  const {
    data: claimBlocks,
    isLoading: isLoadingUWGroups,
    isError: isErrorClaimBlocks,
    refetch: refetchClaimBlocks,
  } = useGetClaimBlocks({
    umr: bdxDocumentsInfo.facilityReference,
  });

  const {
    data: claimAggregates,
    isLoading: isLoadingClaimAggregate,
    isFetching: isFetchingClaimAggregates,
    isError: isErrorClaimAggregate,
    refetch: refetchAggregates,
  } = useGetBdxAggregate<typeof BDX_AGGREGATE_TYPE_CLAIM>({ ...bdxDocumentsInfo, type: BDX_AGGREGATE_TYPE_CLAIM });

  const { mutate: submitAllClaimAggregates, isLoading: isSubmitting } = useSubmitAllAggregates({
    facilityReference: bdxDocumentsInfo.facilityReference,
    yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
    type: BDX_AGGREGATE_TYPE_CLAIM,
  });

  const { data: unallocatedClaimData, isFetched: isUnallocatedClaimblockFetched } = useGetBdxClaimData({
    bdxDocumentsInfo,
    claimGroup: null,
  });

  const { data: config, isLoading: isLoadingConfig } = useGetFacilityConfig({
    umr: bdxDocumentsInfo.facilityReference,
    bdxType: BDX_TYPE_CLAIM,
    options: {},
  });

  const { data: uploadedBdx } = useGetUploadedBdx({ bdxDocumentsInfo });

  useEffect(() => {
    const bdxSubmissions = utils.generic.isValidArray(uploadedBdx, true)
      ? uploadedBdx[0]?.items?.[0]?.items?.find((item) => item?.bdxType === BDX_TYPE_CLAIM)?.submissions
      : [];
    setHasUnpublishedBDX(bdxSubmissions?.every((submission) => submission.status !== PUBLISHED));
  }, [uploadedBdx]);

  useEffect(() => {
    setHasUnallocatedClaimBlock(isUnallocatedClaimblockFetched && unallocatedClaimData?.numberOfElements > 0);
  }, [unallocatedClaimData, isUnallocatedClaimblockFetched]);

  if (isLoadingUWGroups || isLoadingClaimAggregate || isLoadingConfig) {
    return <Empty width={340} title={utils.string.t('app.loading')} icon={<Loading />} padding />;
  }

  if (isErrorClaimBlocks || isErrorClaimAggregate) {
    return <Empty width={340} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />;
  }

  const handleRefetch = () => {
    refetchAggregates();
    refetchClaimBlocks();
  };

  const getHandleAssign = (selectedRowKeys: string[], handleSuccess: VoidFunction) => (event: Event) => {
    event.preventDefault();
    const selectedKeys = selectedRowKeys.filter((key) => key !== 'bulkSelectButton');

    dispatch(
      showModal({
        component: 'ASSIGN_TO_CLAIM_BLOCK',
        props: {
          title: utils.string.t('form.assignToClaimBlock.title'),
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            selectedRowKeys: selectedKeys,
            claimBlocks,
            handleSuccess,
          },
        },
      })
    );
  };

  const showSubmitAllButton = claimAggregates?.some((item) => item.submissionStatus === 'NEW');

  return (
    <ProcessingContextProvider
      hasUnallocatedClaimBlock={hasUnallocatedClaimBlock}
      hasUnpublishedBDX={hasUnpublishedBDX}
      isAllSubmitting={isSubmitting}
      isThirdPartyAccountUnset={config?.thirdPartyAccountConfig === 'UNSET' ?? true}
      bdxDocumentsInfo={bdxDocumentsInfo}
    >
      <Box className={classes.root} data-testid="bdx-summary">
        <ProcessingControls
          tab={tab}
          refetch={handleRefetch}
          isRefetching={isFetchingClaimAggregates}
          handleSubmitAll={submitAllClaimAggregates}
          showSubmitAllButton={showSubmitAllButton}
        />

        {claimBlocks?.map((claimBlock) => (
          <ClaimBlock
            key={claimBlock.ucr}
            claimBlock={claimBlock}
            getHandleAssign={getHandleAssign}
            aggregates={getClaimAggregates(claimAggregates, claimBlock.ucr)}
          />
        ))}

        <ClaimBlock
          claimBlock={{
            ucr: null,
            name: 'Unspecified / unallocated claim block',
            blockId: null,
            interestType: null,
            sourceSystemId: null,
            status: null,
            uwGroupId: null,
            uwGroupName: null,
          }}
          getHandleAssign={getHandleAssign}
          AssignComponent={getAssignToBlockComponent('form.assignToClaimBlock.title')}
        />
      </Box>
    </ProcessingContextProvider>
  );
};
