import { makeStyles } from '@material-ui/core';

export const useImportMappingClasses = makeStyles(
  (theme) => ({
    commentsBox: {
      maxWidth: 600,
      padding: theme.spacing(2.5),
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingBottom: 0,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    formContainer: {
      alignItems: 'center',
    },
  }),
  { name: 'HeaderSelect' }
);
