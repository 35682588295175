import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getFacilitiesLimits(id, token) {
  const apiURL = `facilities/${id}/limit-values`;

  const response = await api(token).get(apiURL);

  return response.data;
}

export function useGetFacilitiesLimits(queryKey, id, options) {
  return useApi([queryKey, id], async (id, token) => await getFacilitiesLimits(id, token), {
    ...options,
  });
}
