import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';

import { FormActions, Translate } from 'components';
import FormSkeleton from './FormSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
    display: 'flex',
  },
  linearProgress: { height: 2 },
  formContent: (props) => ({
    height: props.fullScreen ? '65vh' : '50vh',
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: theme.spacing(3, 4, 4),
    '@media (min-height: 800px)': {
      height: props.fullScreen ? '70vh' : '60vh',
    },
  }),
}));

export const MultiStepLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box p={4}>
        <Skeleton height={58} variant="rect" />
      </Box>
      <LinearProgress variant="determinate" value={0} className={classes.linearProgress} />
      <Box className={classes.formContent}>
        <FormSkeleton />
      </Box>
      <FormActions type="dialog">
        <Button variant="contained" disabled color="primary">
          <Translate label="app.loading" />
        </Button>
      </FormActions>
    </Box>
  );
};
