import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { RISK_LOCATIONS_ACCURACY, XLSX_TEMPLATE, BARRIER_ISLAND_LIMIT } from 'consts';
import { useGetAddress, useGetAddressBatch, useGetDistanceToCoastRequest } from 'lib/quoteBind';
import { enqueueNotification, hideModal, showModal } from 'stores';
import * as utils from 'utils';

// app
import { AddRiskRowMultipleView } from './AddRiskRowMultiple.view';

const AddRiskRowMultiple = ({ field, formProps, overflow, formatData, productType }) => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [showIncompleteOnly, setShowIncompleteOnly] = useState(false);
  const { mutateAsync: getDistanceToCoast } = useGetDistanceToCoastRequest();
  const { mutateAsync: getRiskAddress } = useGetAddress();
  const { mutateAsync: getRiskAddressBatch } = useGetAddressBatch();
  const xlsxTemplate = XLSX_TEMPLATE[productType];
  const dtcEnabled = field?.dtcEnabled ?? true;

  const {
    formState: { errors },
    trigger,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: field.name,
  });

  const fieldsValues = useWatch({
    control: formProps.control,
    name: field.name,
  });

  // abort
  if (!field || !field.name || !field.arrayItemDef) return null;
  if (!formProps || !formProps.control) return null;

  const validFields = [
    'text',
    'number',
    'datepicker',
    'select',
    'autocomplete',
    'autocompletemui',
    'radio',
    'checkbox',
    'toggle',
    'toggle_group',
    'hidden',
  ];

  const cols = [
    ...compact(
      field.arrayItemDef.map((def) => {
        if (!validFields.includes(def.type) || def.type === 'hidden') return null;

        return { id: def.name, label: def.label };
      })
    ),
    { id: 'delete' },
  ];

  const headers = [
    ...compact(
      field.arrayItemDef.map((def) => {
        if (def?.excelHidden || def.type === 'hidden') return null;

        return { key: def.name, label: def.label, value: camelCase(def.label) };
      })
    ),
  ];

  const launchPasteFromExcelModal = (data) => {
    dispatch(
      showModal({
        component: 'PASTE_FROM_EXCEL',
        props: {
          title: 'app.pasteFromExcel',
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            ...data,
            headers,
          },
        },
      })
    );
  };

  const handleDownloadExcelTemplate = () => {
    const link = window.document.createElement('a');

    link.href = xlsxTemplate;
    window.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const closePasteFromExcelModal = () => {
    dispatch(hideModal('PASTE_FROM_EXCEL'));
  };

  const getEmptyObject = () =>
    field?.arrayItemDef.reduce((acc, def) => {
      const defaultValue = def?.defaultValue;
      return defaultValue ? { ...acc, [def.name]: defaultValue } : acc;
    }, {}) || {};

  const getAddressDetails = async (address, item) => {
    const response = await getRiskAddress({ searchTerm: address, componentRestrictions: field?.componentRestrictions ?? null });
    if (!RISK_LOCATIONS_ACCURACY.includes(response?.accuracy)) return { error: 'NO_ACCURATE_RESULT', address };

    const location = { lng: response.lng, lat: response.lat };
    const distanceToCoastResult = dtcEnabled ? await getDistanceToCoast(location) : null;
    const streetAddress = response?.streetNumber ? `${response.streetNumber} ${response?.streetAddress}` : `${response?.streetAddress}`;

    const result = {
      city: response?.city,
      zip: response?.zip,
      county: response?.county,
      state: response?.state,
      streetAddress,
      formattedAddress: response?.outputAddress,
      ...(dtcEnabled && {
        distanceToCoast: distanceToCoastResult?.distanceInMiles,
        distanceToCoastInitialValue: distanceToCoastResult?.distanceInMiles,
        onBarrierIsland: distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT ? null : false,
        inOuterBanksNc:
          distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT && response?.state === 'North Carolina' ? null : false,
      }),

      latitude: response?.lat,
      longitude: response?.lng,
    };

    return result;
  };

  const getAddressDetailsBatch = async (addresses) => {
    const response = await getRiskAddressBatch({ addresses, componentRestrictions: field?.componentRestrictions ?? null });

    return response.map((item) => {
      const streetAddress = item?.streetNumber ? `${item.streetNumber} ${item?.streetAddress}` : item?.streetAddress;

      return {
        city: item?.city ?? '',
        zip: item?.zip ?? '',
        county: item?.county ?? '',
        state: item?.state ?? '',
        streetAddress: streetAddress || item?.inputAddress,
        formattedAddress: item?.outputAddress,
        inputAddress: item?.inputAddress,
        latitude: item?.lat,
        longitude: item?.lng,
      };
    });
  };

  async function asyncForEach(array, callback) {
    setIsAdding(true);
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    setIsAdding(false);
  }

  const appendHandler = async (obj, isFromExcel = false) => {
    const formattedObj = obj;
    if (formatData === 'PROPERTY' && isFromExcel) {
      if (dtcEnabled) {
        asyncForEach(obj, async (item) => {
          const zipCode = item?.zip ? ` ${item?.zip}` : '';
          const address = `${item?.streetAddress}${zipCode}`;
          const result = await getAddressDetails(address, item);

          if (utils.generic.isValidObject(result)) {
            if (result?.error !== 'NO_ACCURATE_RESULT') {
              const itemObj = utils.generic.formatFields(item, field?.arrayItemDef);
              const buildingObj = { ...itemObj, ...result };

              const newValue = { ...getEmptyObject(), ...buildingObj };

              append(newValue);
              validateRiskRow();
            } else {
              dispatch(
                enqueueNotification(`${result?.address} ${utils.string.t('products.multiLocation.buildingError')}`, 'error', {
                  delay: 6000,
                })
              );
            }
          }
        });
      } else {
        const searchAddressList = obj.map((item) => {
          const zipCode = item?.zip ? ` ${item?.zip}` : '';
          return `${item?.streetAddress}${zipCode}`;
        });

        const results = await getAddressDetailsBatch(searchAddressList);

        if (utils.generic.isValidArray(results)) {
          const newValues = obj.map((objItem) => {
            const result = results.find((item) => {
              const zipCode = objItem?.zip ? ` ${objItem?.zip}` : '';
              const address = `${objItem?.streetAddress}${zipCode}`;

              return item?.inputAddress === address;
            });

            if (result && result?.error !== 'NO_ACCURATE_RESULT') {
              const itemObj = utils.generic.formatFields(objItem, field?.arrayItemDef);
              const buildingObj = { ...itemObj, ...result };
              return { ...getEmptyObject(), ...buildingObj };
            }
            dispatch(
              enqueueNotification(`${result?.address} ${utils.string.t('products.multiLocation.buildingError')}`, 'error', {
                delay: 6000,
              })
            );
            return null;
          });

          append(newValues.filter((item) => item));
          validateRiskRow();
        }
      }
    } else append(formattedObj || getEmptyObject());
  };

  const removeHandler = (idx) => {
    remove(idx);
  };

  const validateRiskRow = () => {
    trigger(field.name);
  };

  const copyHandler = (idx) => {
    const { id, buildingTitle, ...rest } = fieldsValues[idx];

    const newValue = { ...rest };
    append(newValue);

    validateRiskRow();
  };

  const handleAddNewObj = (obj) => {
    const newValue = { ...getEmptyObject(), ...obj };
    appendHandler(newValue);
    validateRiskRow();
  };

  const toggleIncompleteOnly = () => {
    setShowIncompleteOnly((prev) => !prev);
  };

  return (
    <AddRiskRowMultipleView
      isAdding={isAdding}
      cols={cols}
      field={field}
      validFields={validFields}
      formProps={formProps}
      overflow={overflow}
      formatData={formatData}
      errors={errors}
      fields={fields}
      showDownloadExcelTemplate={Boolean(xlsxTemplate)}
      showIncompleteOnly={showIncompleteOnly}
      handlers={{
        launchPasteFromExcelModal,
        closePasteFromExcelModal,
        copyHandler,
        handleAddNewObj,
        removeHandler,
        appendHandler,
        handleDownloadExcelTemplate,
        toggleIncompleteOnly,
      }}
    />
  );
};

AddRiskRowMultiple.propTypes = {
  field: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  overflow: PropTypes.bool,
  formatData: PropTypes.string,
};

AddRiskRowMultiple.defaultProps = {
  overflow: true,
};

export default AddRiskRowMultiple;
