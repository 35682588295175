import React from 'react';

// app
import { Translate } from 'components';
import { CURRENCY_USD } from 'consts';
import * as utils from 'utils';
import config from 'config';

export function DynamicValue({ values, field }) {
  return <div data-testid="dynamic-table-component-total">{getValue(field, values)}</div>;
}

const getValue = (field, values) => {
  if (!field || !field.dynamicValue || typeof values !== 'object') return 0;
  const { variant } = field.dynamicValue;

  if (variant === 'sum') {
    return (
      <>
        {utils.generic.getSumOfArray(values, config.ui.format.percent.decimal)}
        {field.variant === 'percent' && '%'}
      </>
    );
  }
  if (variant === 'retainedBrokerageAmount') {
    const grossPremium =values[0];
    const slipOrder = values[1];
    const totalRetainedBrokerage = values[2];
    const premiumCurrency = values[3] || CURRENCY_USD;

    const brokerageAmount = utils.openingMemo.getRetainedBrokerageValue(grossPremium, slipOrder, totalRetainedBrokerage);
    const convertedBrokerage = utils.openingMemo.getRetainedBrokerageConvertedValue(premiumCurrency, brokerageAmount);

    return (
      <div>
        <Translate label="format.currency" options={{ value: { number: brokerageAmount, currency: premiumCurrency } }} />
        {convertedBrokerage && (
          <>
            <br />
            <Translate label="format.currency" options={{ value: { number: convertedBrokerage.value, currency: 'GBP' } }} /> @
            <Translate label="format.number" options={{ value: { number: convertedBrokerage.rate, format: { trimMantissa: false } } }} />
          </>
        )}
      </div>
    );
  }
  return 0;
};

export default DynamicValue;
