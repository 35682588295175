import { makeStyles } from '@material-ui/core';

export const useDocumentsStyle = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2.5),
    },
    folderList: {
      width: '100%',
      marginBottom: 8,
    },
    documentList: {
      width: '100%',
    },
    documentTitle: {
      margin: 0,
      paddingRight: theme.spacing(4),
      width: '100%',
      textAlign: 'left',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '44px',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    documentEdit: {
      padding: theme.spacing(0, 1, 0, 0),
    },
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    folderIconWrapper: {
      minWidth: 40,
      display: 'flex',
    },
    folderIcon: {
      transform: 'none!important',
      color: theme.palette.grey[400],
      marginRight: theme.spacing(1),
    },
    folderIconButton: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    rootLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 320,
    },
  }),
  { name: 'Documents' }
);
