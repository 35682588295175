import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// app
import styles from './Accordion.styles';

// mui
import { makeStyles, Accordion, AccordionDetails, AccordionSummary, Typography, withStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

AccordionView.propTypes = {
  title: PropTypes.node,
  titleComponent: PropTypes.node,
  icon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  boxed: PropTypes.bool,
  expanded: PropTypes.bool,
  density: PropTypes.oneOf(['compact', 'default', 'comfortable']),
  testid: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

AccordionView.defaultProps = {
  icon: true,
  iconPosition: 'left',
  density: 'default',
  testid: '',
};

export function AccordionView({
  title,
  titleComponent,
  icon,
  iconPosition,
  boxed,
  expanded,
  secondaryIcon,
  primaryIcon,
  expandIcon,
  rightIcon,
  density,
  testid,
  onChange,
  children,
  type,
  bgColor = null,
  disabled = false,
  margin = 2,
  rotateIcons = true,
}) {
  const classes = makeStyles(styles, { name: 'Accordion' })({ blank: !boxed, density, rotateIcons });

  if (type === 'solid') {
    return (
      <SolidAccordion disabled={disabled} margin={margin} onChange={onChange}>
        <SolidAccordionSummary expandIcon={expandIcon} bgColor={bgColor} data-testid={`accordion${testid ? `-${testid}` : ''}`}>
          {icon && iconPosition === 'left' ? <KeyboardArrowDownIcon className={classes.arrow} /> : null}
          {titleComponent ? (
            titleComponent
          ) : (
            <Typography variant={title?.type ? title.type : 'h4'} noWrap className={classes.type}>
              {title?.text || title}
            </Typography>
          )}
          {icon && iconPosition === 'right' ? <KeyboardArrowDownIcon className={classes.arrow} /> : null}
        </SolidAccordionSummary>
        <SolidAccordionDetails>{children}</SolidAccordionDetails>
      </SolidAccordion>
    );
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      classes={{
        root: classnames(classes.panel),
      }}
      data-testid={`accordion${testid ? `-${testid}` : ''}`}
    >
      <AccordionSummary
        classes={{
          root: classnames(classes.summary),
          content: classnames(classes.summaryContent),
        }}
        expandIcon={expandIcon}
      >
        <div className={classes.header}>
          {icon && iconPosition === 'left' ? <KeyboardArrowDownIcon className={classes.arrow} /> : null}
          {primaryIcon}
          {titleComponent ? (
            titleComponent
          ) : (
            <Typography variant={title?.type ? title.type : 'h4'} noWrap className={classes.type}>
              {title?.text || title}
            </Typography>
          )}
          {secondaryIcon}
        </div>
        {icon && iconPosition === 'right' ? <KeyboardArrowDownIcon className={classes.arrow} /> : null}
        {rightIcon && <div className={classes.rightIcon}>{rightIcon}</div>}
      </AccordionSummary>

      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
}

const SolidAccordion = withStyles((theme) => ({
  root: (props) => {
    return {
      marginTop: theme.spacing(props?.margin || 2),
      marginBottom: theme.spacing(props?.margin || 2),
      borderRadius: '10px!important',
      boxShadow: theme.shadows[1],
      border: `1px solid ${theme.palette.grey[300]}`,
      overflow: 'hidden',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    };
  },
  expanded: {},
}))(Accordion);

const SolidAccordionSummary = withStyles((theme) => ({
  root: (props) => {
    return {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      margin: 0,
      transition: theme.transitions.create('margin'),
      zIndex: 100,
      backgroundColor: props?.bgColor ? props?.bgColor : theme.palette.grey[50],
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      '&$expanded': {
        minHeight: 56,
      },
    };
  },
  content: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0)}px!important`,
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(AccordionSummary);

const SolidAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}))(AccordionDetails);
