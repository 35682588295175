export const resetPolicy = () => {
  return {
    type: 'POLICY_RESET',
  };
};

export const resetPolicyPlacement = () => {
  return {
    type: 'POLICY_PLACEMENT_RESET',
  };
};
