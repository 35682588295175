import { makeStyles } from '@material-ui/styles';

export const useSummaryStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  sheetName: {
    margin: theme.spacing(0, 0, 1),
  },
}));
