import { useInfiniteQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_REQUIRED_BDX } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetRequiredBdx = ({ facilityReference }) => {
  const handleError = useHandleError({ file: 'binderManage.queries.useGetRequiredBdx' });

  return useInfiniteQuery(
    [BDX_REQUIRED_BDX, facilityReference],
    async ({ pageParam = 0 }) => {
      if (!facilityReference) return null;
      const result = await binderManagementBDXAxios.get(`umr/${facilityReference}`, { params: { offset: pageParam } });

      return { data: result?.data, nextPageId: pageParam + 1 };
    },
    {
      onError: (err) => handleError(err),
      getNextPageParam: (lastPage) => lastPage?.nextPageId ?? undefined,
    }
  );
};
