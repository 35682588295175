import { FC } from 'react';
import { ColumnShape } from 'react-base-table';

import { Box, Tooltip } from '@material-ui/core';

interface IBaseTableHeaderProps {
  className: string;
  column: ColumnShape;
}

export const BaseTableHeaderCell: FC<IBaseTableHeaderProps> = ({ className, column }) => (
  <Tooltip title={column.title}>
    <Box className={className}>{column.title}</Box>
  </Tooltip>
);
