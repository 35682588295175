import { addLoader, authLogout, removeLoader, setDepartmentSelected, setSourceSystemId } from 'stores';
import * as utils from 'utils';
import get from 'lodash/get';

export const getUser = () => (dispatch, getState) => {
  // prettier-ignore
  const { user: { auth }, config: { vars: { endpoint }}} = getState();

  const defaultError = {
    file: 'stores/user.actions.get',
  };

  dispatch(getUserRequest());
  dispatch(addLoader('getUser'));

  return new Promise((resolve, reject) => {
    utils.api
      .get({
        token: auth.accessToken,
        endpoint: endpoint.edge,
        path: 'api/user',
      })
      .then((response) => utils.api.handleResponse(response))
      .then((json) => utils.api.handleData(json))
      .then((data) => {
        dispatch(getUserSuccess(data));
        const { departments } = data;
        // checking that the logged in user departments and saved dept (localStorage) match
        // if the saved department in localStorage isn't one of the user, we replace it

        const depts = data ? departments.map((department) => department.id) || [] : [];

        const firstDept = parseInt(get(data, 'departments[0].id'));
        const sourceSystemId = parseInt(get(data, 'departments[0].sourceSystemId'));
        const savedDept = parseInt(localStorage.getItem('edge-department'));

        if (!depts.includes(savedDept)) {
          dispatch(setDepartmentSelected(firstDept));
          dispatch(setSourceSystemId(sourceSystemId));
        } else {
          const savedSourceSystemId = departments.find((dept) => dept.id === savedDept).sourceSystemId;
          dispatch(setSourceSystemId(savedSourceSystemId));
        }

        dispatch(removeLoader('getUser'));
        resolve(data);
      })
      .catch((err) => {
        utils.api.handleError(err, defaultError);
        utils.api.handleUnauthorized(err, dispatch, authLogout);
        dispatch(getUserFailure(err));
        dispatch(removeLoader('getUser'));
        reject({ ...err, file: 'stores/auth.actions', message: 'API fetch error (user.get)' });
      });
  });
};

export const getUserRequest = () => {
  return {
    type: 'USER_GET_REQUEST',
  };
};

export const getUserSuccess = (userData) => {
  return {
    type: 'USER_GET_SUCCESS',
    payload: userData,
  };
};

export const getUserFailure = (error) => {
  return {
    type: 'USER_GET_FAILURE',
    payload: error,
  };
};
