import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useReSubmitBDX = () => {
  const dispatch = useDispatch();

  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useReSubmitBDX',
    warning: 'notification.bdx.resubmitFail',
  });

  return useMutation(
    async ({ requestId }) => {
      if (!requestId) {
        throw new Error('No requestId');
      }

      const data = await binderManagementFileInputAxios.put(`resubmit/${requestId}`);

      return data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        dispatch(enqueueNotification('notification.bdx.resubmitSuccess', 'success'));
      },
    }
  );
};
