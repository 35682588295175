import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { quoteBindAxios } from 'lib/quoteBind';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';
// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const usePutEndorsementQuote = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.usePutEndorsementQuote',
    };

    const { response } = err;
    dispatch(enqueueNotification(response?.data?.message ?? 'notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([QB_RISK_ENDORSEMENTS, res?.riskId], (oldData) =>
      oldData?.map((endorsement) => {
        if (endorsement.id === res?.id) {
          return res;
        }
        return endorsement;
      })
    );

    dispatch(enqueueNotification('notification.putEndorsementQuote.success', 'success'));
  };

  return useMutation(
    async ({ premium, overriddenProRata, validUntil, accept, endorsementId, additionalPremiums }) => {
      if (!endorsementId) {
        throw new Error('Invalid params');
      }
      const path = `endorsements/${endorsementId}/premium`;
      const result = await quoteBindAxios.put(path, {
        premium,
        overriddenProRata,
        ...(validUntil && { validUntil }),
        ...(accept && { accept }),
        ...(additionalPremiums && { additionalPremiums }),
        ...(overriddenProRata && { overriddenProRata }),
      });
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
