import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import isEmpty from 'lodash/isEmpty';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { DRAFT_RISKS } from 'consts';

// risks
export const usePostDraftRisk = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.usePostDraftRisk',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (result) => {
    queryClient.invalidateQueries(DRAFT_RISKS);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.saveRisk.success', 'success'));

    return result;
  };

  return useMutation(
    async (params) => {
      const { values, type, definitions, draftId } = params;

      if (!values || isEmpty(values) || !type || !utils.generic.isValidArray(definitions)) {
        throw new Error('Invalid params');
      }

      const transFormedValues = { ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions) };
      const data = {
        id: draftId ? draftId : null,
        risk: {
          ...transFormedValues,
          riskType: type,
          address: {
            ...transFormedValues.address,
            country: null,
          },
          ownerAddress: {
            ...transFormedValues.ownerAddress,
            country: null,
          },
        },
      };

      const result = await quoteBindAxios.post(`/risks/drafts`, data);

      return result;
    },
    {
      onSuccess: (result) => handleSuccess(result),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
