export const OPERATIONAL_CLIENTS = 'OPERATIONAL_CLIENTS';
export const OPERATIONAL_CLIENTS_ALL = 'OPERATIONAL_CLIENTS_ALL';
export const OPERATIONAL_CLIENT_DETAIL = 'OPERATIONAL_CLIENT_DETAIL';
export const CLIENT_OFFICE_LIST = 'CLIENT_OFFICE_LIST';

export const OPERATIONAL_MARKETS = 'OPERATIONAL_MARKETS';
export const OPERATIONAL_MARKET_DETAIL = 'OPERATIONAL_MARKET_DETAIL';

export const USER_DETAILS = 'USER_DETAILS';
export const MY_USER_DETAILS = 'MY_USER_DETAILS';

// THIRD PARTIES
export const OPERATIONAL_THIRD_PARTY = 'OPERATIONAL_THIRD_PARTY';
export const THIRD_PARTY_OFFICE_LIST = 'THIRD_PARTY_OFFICE_LIST';

export const BULK_USER_UPLOADS = 'BULK_USER_UPLOADS';
