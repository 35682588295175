const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    overflowY: 'auto',
  },

  nameWrapper: {
    flex: 1,
    maxWidth: '50%',
    marginRight: 'auto',
  },
  priorityWrapper: {
    maxWidth: 100,
  },
  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  skeletonOperation: {
    margin: theme.spacing(2, 0),
  },
  skeletonStatement: {
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },

  operation: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  ruleLineContainer: {
    marginTop: theme.spacing(-3),
    paddingRight: `0px!important`,
    paddingLeft: `${theme.spacing(3)}px!important`,

    '& + *': {
      paddingLeft: `0px!important`,
    },
  },

  deleteCondition: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
