import * as Yup from 'yup';

// app
import { Translate } from 'components';

import { ROLE_ADMIN, ROLE_BROKER, ROLE_COVERHOLDER, ROLE_PRODUCER, ROLE_UNDERWRITER } from 'consts';
import config from 'config';
import * as utils from 'utils';

const permissionOptions = [
  { value: ROLE_BROKER, label: 'Broker', fixed: false },
  { value: ROLE_COVERHOLDER, label: 'Coverholder', fixed: false },
  { value: ROLE_PRODUCER, label: 'Producer', fixed: false },
  { value: ROLE_UNDERWRITER, label: 'Underwriter', fixed: true },
  { value: ROLE_ADMIN, label: 'Admin', fixed: false },
];
const fixedOptions = permissionOptions.filter((option) => option.fixed);

const addressUpdateFields = [
  {
    name: 'name',
    valueName: 'locationName',
  },
  {
    name: 'street',
    valueName: 'streetAddress',
  },
  {
    name: 'city',
    valueName: 'city',
  },
  {
    name: 'zipCode',
    valueName: 'zip',
  },
  {
    name: 'county',
    valueName: 'county',
  },
  {
    name: 'state',
    valueName: 'state',
  },
  {
    name: 'country',
    valueName: 'country',
  },
];

const updateAddressFields = (address, updateValue, countries) => {
  if (addressUpdateFields) {
    addressUpdateFields.forEach((field) => {
      if (field.valueName === 'country') {
        const addressCountry = countries.find(
          (country) =>
            country?.label.toLowerCase() === address?.country?.toLowerCase() ||
            country?.value.toLowerCase() === address?.country?.toLowerCase()
        );

        updateValue(field?.name, addressCountry);
      } else updateValue(field?.name, address[field?.valueName || field.name]);
    });
  }
};

const schema = {
  insureds: {
    key: 'insureds',
    dependenciesLoaded: (options) => utils.schemas.checkDependencies(['countries'], options),
    items: null,
    fields: [
      {
        id: 'locationAutocomplete',
        name: 'locationAutocomplete',
        type: 'locationAutocomplete',
        muiComponentProps: {
          title: 'products.locationSearch.insuredTitle',
          buttonLabel: 'products.locationSearch.buttonLabel',
          placeholder: 'products.locationSearch.placeholder',
        },
        gridSize: { xs: 12, md: 12 },
        callback: updateAddressFields,
        hideForProducers: false,
      },
      {
        id: 'name',
        name: 'name',
        type: 'text',
        cellProps: {
          nowrap: true,
        },
        value: '',
        label: 'products.admin.insureds.tableCols.name',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.insureds.tableCols.name', 'required')),
        gridSize: { xs: 12, sm: 5 },
      },
      {
        id: 'clientId',
        name: 'clientId',
        transform: 'option',
        type: 'autocompletemui',
        value: null,
        options: [],
        optionKey: 'value',
        optionLabel: 'label',
        cellProps: {
          nowrap: true,
        },
        defaultValue: '',
        optionsDynamicKey: 'clients',
        label: 'products.admin.facilities.tableCols.clientId',
        gridSize: { xs: 12, sm: 4 },
        validation: Yup.object()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.clientId', 'required')),
      },
      {
        id: 'partyType',
        name: 'partyType',
        label: 'products.admin.insureds.tableCols.partyType',
        type: 'autocompletemui',
        options: [
          { value: 'BUSINESS', label: 'products.admin.insureds.typeOptions.business', default: true },
          { value: 'INDIVIDUAL', label: 'products.admin.insureds.typeOptions.individual', default: false },
        ],
        optionsDefaultValue: true,
        validation: Yup.object()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.insureds.tableCols.partyType', 'required')),
        gridSize: { xs: 12, sm: 3 },
      },
      {
        id: 'genderType',
        name: 'genderType',
        label: 'products.admin.insureds.gender',
        value: [],
        type: 'autocompletemui',
        options: [
          { value: 'MALE', label: 'products.admin.insureds.genderOptions.male' },
          { value: 'FEMALE', label: 'products.admin.insureds.genderOptions.female' },
          { value: 'UNKNOWN', label: 'products.admin.insureds.genderOptions.unknown' },
          { value: 'NONE', label: 'products.admin.insureds.genderOptions.none' },
        ],
        conditional: {
          conditional: true,
          conditionalField: 'partyType',
          conditionValue: 'INDIVIDUAL',
        },
        gridSize: { xs: 12, md: 5 },
      },
      {
        id: 'dateOfBirth',
        name: 'dateOfBirth',
        transform: 'date',
        label: 'products.admin.insureds.dateOfBirth',
        conditional: {
          conditional: true,
          conditionalField: 'partyType',
          conditionValue: 'INDIVIDUAL',
        },
        type: 'datepicker',
        value: null,
        gridSize: { xs: 12, md: 3 },
      },
      {
        id: 'street',
        name: 'street',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.street',
        gridSize: { xs: 12, md: 5 },
      },
      {
        id: 'city',
        name: 'city',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.city',
        gridSize: { xs: 12, sm: 8, md: 4 },
      },
      {
        id: 'zipCode',
        name: 'zipCode',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.zipCode',
        gridSize: { xs: 12, sm: 4, md: 3 },
      },
      {
        id: 'county',
        name: 'county',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.county',
        gridSize: { xs: 12, sm: 4 },
      },
      {
        id: 'state',
        name: 'state',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.state',
        gridSize: { xs: 12, sm: 3 },
      },
      {
        id: 'country',
        name: 'country',
        type: 'autocompletemui',
        value: [],
        options: [],
        optionsDynamicKey: 'countries',
        label: 'products.admin.insureds.tableCols.country',
        validation: Yup.object()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.insureds.tableCols.country', 'required')),
        gridSize: { xs: 12, sm: 5 },
      },
      {
        id: 'productCode',
        name: 'productCode',
        transform: 'option',
        type: 'select',
        defaultValue: '',
        options: [],
        optionKey: 'value',
        optionLabel: 'label',
        optionsDynamicKey: 'products',
        label: 'products.admin.facilities.tableCols.productCode',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.productCode', 'required')
        ),
        gridSize: { xs: 12, sm: 4 },
        hideInAddRiskForm: true,
      },
      {
        id: 'distanceToCoast',
        name: 'distanceToCoast',
        type: 'hidden',
        value: '',
      },
    ],
  },
  clients: {
    key: 'clients',
    dependenciesLoaded: (options) => utils.schemas.checkDependencies(['countries', 'clientOffices', 'products'], options),
    items: null,
    fields: [
      {
        id: 'locationAutocomplete',
        name: 'locationAutocomplete',
        type: 'locationAutocomplete',
        muiComponentProps: {
          title: 'products.locationSearch.producerTitle',
          buttonLabel: 'products.locationSearch.buttonLabel',
          placeholder: 'products.locationSearch.placeholder',
        },
        gridSize: { xs: 12, md: 12 },
        callback: updateAddressFields,
        hideForProducers: true,
      },
      {
        id: 'name',
        name: 'name',
        type: 'text',
        cellProps: {
          nowrap: true,
        },
        value: '',
        label: 'products.admin.clients.tableCols.name',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.clients.tableCols.name', 'required')),
        gridSize: { xs: 12, sm: 8 },
      },
      {
        id: 'clientOfficeId',
        name: 'clientOfficeId',
        type: 'autocompletemui',
        value: null,
        options: [],
        optionKey: 'value',
        optionLabel: 'label',
        optionsDynamicKey: 'clientOffices',
        label: 'products.admin.clients.tableCols.clientOffice',
        gridSize: { xs: 12, sm: 4 },
        hideInAddRiskForm: true,
      },
      {
        id: 'street',
        name: 'street',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.street',
        gridSize: { xs: 12, md: 4 },
      },
      {
        id: 'city',
        name: 'city',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.city',
        gridSize: { xs: 12, sm: 4, md: 4 },
      },
      {
        id: 'zipCode',
        name: 'zipCode',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.zipCode',
        gridSize: { xs: 12, sm: 4, md: 4 },
      },
      {
        id: 'county',
        name: 'county',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.county',
        gridSize: { xs: 12, sm: 4 },
      },
      {
        id: 'state',
        name: 'state',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.state',
        gridSize: { xs: 12, sm: 4 },
      },
      {
        id: 'country',
        name: 'country',
        type: 'autocompletemui',
        value: null,
        options: [],
        optionKey: 'value',
        optionLabel: 'label',
        optionsDynamicKey: 'countries',
        label: 'products.admin.insureds.tableCols.country',
        validation: Yup.object()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.insureds.tableCols.country', 'required')),
        gridSize: { xs: 12, sm: 4 },
      },
      {
        id: 'productCode',
        name: 'productCode',
        transform: 'option',
        type: 'select',
        defaultValue: '',
        options: [],
        optionKey: 'value',
        optionLabel: 'label',
        optionsDynamicKey: 'products',
        label: 'products.admin.facilities.tableCols.productCode',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.productCode', 'required')
        ),
        gridSize: { xs: 12, sm: 4 },
        hideInAddRiskForm: true,
      },
      {
        id: 'commissionLabel',
        name: 'commissionLabel',
        type: 'legend',
        label: 'products.admin.clients.tableCols.commissionLabel',
        gridSize: { xs: 12 },
        displayColumn: false,
        hideInAddRiskForm: true,
      },
      {
        id: 'brokerageFee',
        name: 'brokerageFee',
        type: 'number',
        value: '',
        label: 'products.admin.facilities.brokerageFee',
        gridSize: { xs: 6, sm: 3 },
        displayColumn: false,
        hideInAddRiskForm: true,
        validation: Yup.number()
          .nullable()
          .min(0)
          .max(100)
          .transform((value) => (Number.isNaN(value) ? null : value)),
      },
      {
        name: 'clientCommissionRate',
        type: 'number',
        label: 'products.admin.facilities.clientCommissionRate',
        value: '',
        gridSize: { xs: 6, sm: 3 },
        displayColumn: false,
        hideInAddRiskForm: true,
        validation: Yup.number()
          .nullable()
          .min(0)
          .max(100)
          .transform((value) => (Number.isNaN(value) ? null : value)),
      },
      {
        name: 'brokerCommissionRate',
        type: 'number',
        gridSize: { xs: 6, sm: 3 },
        label: 'products.admin.facilities.brokerCommissionRate',
        value: '',
        displayColumn: false,
        hideInAddRiskForm: true,
        validation: Yup.number()
          .nullable()
          .min(0)
          .max(100)
          .transform((value) => (Number.isNaN(value) ? null : value)),
      },
      {
        name: 'reinsuranceRate',
        type: 'number',
        label: 'products.admin.facilities.reinsuranceRate',
        value: '',
        gridSize: { xs: 6, sm: 3 },
        displayColumn: false,
        hideInAddRiskForm: true,
        validation: Yup.number()
          .nullable()
          .min(0)
          .max(100)
          .transform((value) => (Number.isNaN(value) ? null : value)),
      },
    ],
  },
  // start
  carriers: {
    key: 'carriers',
    dependenciesLoaded: () => true,
    items: null,
    fields: [
      {
        id: 'name',
        name: 'name',
        type: 'text',
        cellProps: {
          nowrap: true,
        },
        value: '',
        label: 'products.admin.carriers.tableCols.name',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.carriers.tableCols.name', 'required')),
        gridSize: { xs: 12, sm: 6 },
      },
    ],
  },
  facilities: {
    key: 'facilities',
    dependenciesLoaded: (options) => utils.schemas.checkDependencies(['products', 'carriers'], options),
    items: null,
    fields: [
      {
        id: 'name',
        name: 'name',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.name',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.name', 'required')),
        gridSize: { xs: 12, md: 4 },
      },
      {
        id: 'brokerCode',
        name: 'brokerCode',
        cellProps: {
          nowrap: true,
        },
        type: 'text',
        label: 'products.admin.facilities.tableCols.brokerCode',
        defaultValue: '',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.brokerCode', 'required')
        ),
        gridSize: { xs: 5, sm: 4, md: 2 },
      },
      {
        id: 'quoteValidDays',
        name: 'quoteValidDays',
        cellProps: {
          nowrap: true,
        },
        type: 'number',
        label: 'products.admin.facilities.tableCols.quoteValidDays',
        defaultValue: '',
        validation: Yup.number()
          .nullable()
          .min(0)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.quoteValidDays', 'required')),
        gridSize: { xs: 5, sm: 4, md: 3 },
      },
      {
        id: 'earliestBindPeriod',
        name: 'earliestBindPeriod',
        cellProps: {
          nowrap: true,
        },
        type: 'number',
        label: 'products.admin.facilities.tableCols.earliestBindPeriod',
        defaultValue: '',
        validation: Yup.number()
          .nullable()
          .min(0)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.earliestBindPeriod', 'required')),
        gridSize: { xs: 5, sm: 4, md: 3 },
      },
      {
        id: 'capacity',
        name: 'capacity',
        transform: 'currency',
        defaultValue: '',
        type: 'number',
        variant: 'currency',
        label: 'products.admin.facilities.tableCols.capacity',
        validation: Yup.number()
          .currency(<Translate label="validation.number.format" options={config.ui.format.currency} />)
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.capacity', 'required'))
          .transform((value) => (Number.isNaN(value) ? null : value)),
        gridSize: { xs: 7, sm: 8, md: 4 },
      },

      {
        id: 'liveFrom',
        name: 'liveFrom',
        transform: 'date',
        cellProps: {
          nowrap: true,
        },
        type: 'datepicker',
        label: 'products.admin.facilities.tableCols.liveFrom',
        icon: 'TodayIcon',
        defaultValue: null,
        muiComponentProps: {
          fullWidth: true,
        },
        validation: Yup.date()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.liveFrom', 'required')),
        gridSize: { xs: 6, md: 4 },
      },
      {
        id: 'liveTo',
        name: 'liveTo',
        transform: 'date',
        defaultValue: null,
        cellProps: {
          nowrap: true,
        },
        type: 'datepicker',
        label: 'products.admin.facilities.tableCols.liveTo',
        muiComponentProps: {
          fullWidth: true,
        },
        validation: Yup.date()
          .nullable()
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.liveTo', 'required')),
        gridSize: { xs: 6, md: 4 },
      },
      {
        id: 'broker',
        name: 'broker',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.broker',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.broker', 'required')),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },
      {
        id: 'coverholderName',
        name: 'coverholderName',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.coverholderName',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.coverholderName', 'required')
        ),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },
      {
        id: 'coverholderPin',
        name: 'coverholderPin',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.coverholderPin',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.coverholderPin', 'required')
        ),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },
      {
        id: 'umr',
        name: 'umr',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.umr',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.umr', 'required')),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },
      {
        id: 'agreementNumber',
        name: 'agreementNumber',
        type: 'text',
        defaultValue: '',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.agreementNumber',
        validation: Yup.string().required(() =>
          utils.form.getValidationLabel('products.admin.facilities.tableCols.agreementNumber', 'required')
        ),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },
      {
        id: 'declarationNumberPattern',
        name: 'declarationNumberPattern',
        type: 'text',
        defaultValue: '####',
        cellProps: {
          nowrap: true,
        },
        label: 'products.admin.facilities.tableCols.declarationNumberPattern',
        hint: 'ABC####=ABC0001',
        validation: Yup.string()
          .matches(/\w+([#]{4,})/, { message: 'Minimum ####(4) are required' })
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.declarationNumberPattern', 'required')),
        gridSize: { xs: 12, md: 4 },
        displayColumn: false,
      },

      {
        id: 'permissionToBindGroups',
        name: 'permissionToBindGroups',
        label: 'products.admin.facilities.tableCols.permissionToBind',
        value: fixedOptions,
        type: 'autocompletemui',
        options: permissionOptions,
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          variant: 'outlined',
          filterSelectedOptions: true,
        },
      },
      {
        id: 'notifiedUsers',
        name: 'notifiedUsers',
        type: 'autocompletemui',
        value: [],
        options: [],
        optionsDynamicKey: 'notifiedUsers',
        optionKey: 'email',
        optionLabel: 'name',
        label: 'products.admin.facilities.tableCols.notifiedUsers',
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          filterSelectedOptions: true,
        },
      },
      {
        id: 'permissionToDismissIssuesGroups',
        name: 'permissionToDismissIssuesGroups',
        label: 'products.admin.facilities.tableCols.permissionToDismiss',
        value: fixedOptions,
        type: 'autocompletemui',
        options: permissionOptions,
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          variant: 'outlined',
          filterSelectedOptions: true,
        },
      },
      {
        id: 'preBind',
        name: 'preBind',
        type: 'checkbox',
        value: false,
        label: 'products.admin.facilities.tableCols.preBindInfo',
        gridSize: { xs: 6, sm: 6 },
      },
    ],
  },
  editFacilities: {
    key: 'editFacilities',
    items: null,
    fields: [
      {
        id: 'quoteValidDays',
        name: 'quoteValidDays',
        cellProps: {
          nowrap: true,
        },
        type: 'number',
        label: 'products.admin.facilities.tableCols.quoteValidDays',
        defaultValue: '',
        validation: Yup.number()
          .nullable()
          .min(0)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required(() => utils.form.getValidationLabel('products.admin.facilities.tableCols.quoteValidDays', 'required')),
        gridSize: { xs: 5, sm: 4, md: 3 },
      },
      {
        id: 'permissionToBindGroups',
        name: 'permissionToBindGroups',
        label: 'products.admin.facilities.tableCols.permissionToBind',
        value: fixedOptions,
        type: 'autocompletemui',
        options: permissionOptions,
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          variant: 'outlined',
          filterSelectedOptions: true,
        },
      },
      {
        id: 'notifiedUsers',
        name: 'notifiedUsers',
        type: 'autocompletemui',
        value: [],
        options: [],
        optionsDynamicKey: 'notifiedUsers',
        optionKey: 'email',
        optionLabel: 'name',
        label: 'products.admin.facilities.tableCols.notifiedUsers',
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          filterSelectedOptions: true,
        },
      },
      {
        id: 'permissionToDismissIssuesGroups',
        name: 'permissionToDismissIssuesGroups',
        label: 'products.admin.facilities.tableCols.permissionToDismiss',
        value: fixedOptions,
        type: 'autocompletemui',
        options: permissionOptions,
        gridSize: { xs: 12, sm: 6 },
        innerComponentProps: {
          isMulti: true,
          allowEmpty: true,
          maxMenuHeight: 120,
        },

        muiComponentProps: {
          multiple: true,
          variant: 'outlined',
          filterSelectedOptions: true,
        },
      },
      {
        id: 'preBind',
        name: 'preBind',
        type: 'checkbox',
        value: false,
        label: 'products.admin.facilities.tableCols.preBindInfo',
        gridSize: { xs: 6, sm: 6 },
      },
      {
        id: 'enablePostExpiry',
        name: 'enablePostExpiry',
        type: 'switch',
      },
    ],
  },
};

const schemasProductAdmin = {
  getSchema: (key) => (schema[key] ? schema[key] : {}),
};

export default schemasProductAdmin;
