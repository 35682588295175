import { useQuery } from 'react-query';

// app
import { axiosInstance } from '../api';
import { CLAIMS_TASKS } from '../queryKeys';
import { getHandleError } from '../utils';

export const useGetTasks = ({ params, options = {} }) => {
  const handleError = getHandleError('claimsTaskManagement.queries.useGetTasks');

  return useQuery(
    [CLAIMS_TASKS, params],
    async () => {
      const url = `tm/v1/tasks`;

      const result = await axiosInstance.get(url, { params });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
