import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
// MUI
import makeStyles from '@material-ui/core/styles/makeStyles';

// Edge
import { useMedia } from 'hooks';

const defaultCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const useStyles = makeStyles((theme) => ({
  root: {
    height: 36,
    marginBottom: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
  },
  character: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    padding: theme.spacing(0.5, 1),
    cursor: 'pointer',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  selected: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 600,
  },
}));

export const AlphabeticalFilter = ({ handleClick, selectedCharacter = '', characters = defaultCharacters }) => {
  const media = useMedia();
  const classes = useStyles({ isMobile: media.mobile });

  return (
    <Box className={classes.root} display="flex" data-testid="alphabetical-filter">
      {characters.map((character) => (
        <span
          key={character?.value || character}
          onClick={() => handleClick(character?.value || character?.value === '' ? character.value : character)}
          className={`${classes.character} ${
            selectedCharacter === character || selectedCharacter === character?.value ? `${classes.selected}` : ''
          }`}
        >
          {character?.label ? character.label : character}
        </span>
      ))}
    </Box>
  );
};

AlphabeticalFilter.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selectedCharacter: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  characters:
    PropTypes.arrayOf(PropTypes.string) || PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
};

AlphabeticalFilter.displayName = 'AlphabeticalFilter';
