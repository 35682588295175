import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { ReactComponent as FlashWarningSVG } from 'assets/svg/flash-warning.svg';
import { Empty, TabPanel } from 'components';

import { BDX_TYPE_CLAIM, BDX_TYPE_PREMIUM } from 'consts/binder-management';
import { useGetBdxTypes, useGetBMThirdParties, useGetFacilityConfig, useUpdateFacilityConfig } from 'lib/binderManagement';
import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

import { IFacilityConfigForm, IFacilityConfigProps } from './FacilityConfig.types';
import { FacilityConfigView } from './FacilityConfig.view';
import { getThirdPartyAccountConfig } from './utils';

import { useFacilityConfigStyles } from './FacilityConfig.style';

const FacilityConfig = ({ umr, riskCodes }: IFacilityConfigProps) => {
  const classes = useFacilityConfigStyles();

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);

  const { data: bdxTypes, isFetching: isLoadingBDXTypes } = useGetBdxTypes(riskCodes?.map(({ code }) => code) || []);
  const { data: tpa, isLoading: isLoadingTPA } = useGetBMThirdParties();
  const { data: facilityConfig, isLoading: isLoadingFacilityConfig } = useGetFacilityConfig({
    umr,
    bdxType: activeTab?.value,
    options: {
      enabled: !!activeTab?.value,
    },
  });
  const methods = useForm<IFacilityConfigForm>({
    mode: 'onChange',
    defaultValues: {
      noOfHeaderRows: facilityConfig?.noOfHeaderRows ?? 1,
      bdxCumulative: facilityConfig?.bdxCumulative ?? false,
      operationalThirdParties: facilityConfig?.thirdParties?.map(({ id }) => id) ?? [],
      clientDueDateOffset: facilityConfig?.clientDueDateOffset ?? null,
      dueDateOffset: facilityConfig?.dueDateOffset ?? null,
      claimsThirdPartyAccount: facilityConfig?.claimsThirdPartyAccount,
    },
  });

  const isLoading = isLoadingTPA || isLoadingBDXTypes;

  const tabs = useMemo(() => {
    const generalFields: FormFields<IFacilityConfigForm> = [
      {
        id: 'noOfHeaderRows',
        name: 'noOfHeaderRows',
        type: 'select',
        defaultValue: 1,
        options: new Array(10).fill(1).map((_, index) => ({ id: index + 1, value: index + 1 })),
        optionKey: 'id',
        optionLabel: 'value',
      },
      {
        id: 'bdxCumulative',
        name: 'bdxCumulative',
        type: 'checkbox',
        defaultValue: false,
        label: utils.string.t('form.facilityConfig.cumulativeBDX'),
      },
    ];

    const extraClaimFields: FormFields<IFacilityConfigForm> = [
      {
        id: 'operationalThirdParties',
        name: 'operationalThirdParties',
        label: utils.string.t('form.facilityConfig.operationalThirdParties'),
        type: 'select',
        options: tpa ?? [],
        defaultValue: [],
        optionKey: 'id',
        optionLabel: 'name',
        muiComponentProps: {
          multiple: true,
        },
      },
      {
        id: 'claimsThirdPartyAccount',
        name: 'claimsThirdPartyAccount',
        label: utils.string.t('form.facilityConfig.thirdPartyAccount'),
        type: 'select',
        optionKey: 'id',
        optionLabel: 'name',
      },
    ];

    const extraPremiumFields: FormFields<IFacilityConfigForm> = [
      {
        id: 'clientDueDateOffset',
        name: 'clientDueDateOffset',
        label: utils.string.t('form.facilityConfig.clientDueDateOffset'),
        type: 'number',
        defaultValue: null,
      },
      {
        id: 'dueDateOffset',
        name: 'dueDateOffset',
        label: utils.string.t('form.facilityConfig.dueDateOffset'),
        type: 'number',
        defaultValue: null,
      },
    ];

    const getExtraFields = (bdxType: TBDXType) => {
      switch (bdxType) {
        case BDX_TYPE_CLAIM:
          return extraClaimFields;
        case BDX_TYPE_PREMIUM:
          return extraPremiumFields;
        default:
          return [];
      }
    };

    return bdxTypes?.map((bdxType) => ({
      label: bdxType,
      value: bdxType,
      fields: [...generalFields, ...getExtraFields(bdxType)],
    }));
  }, [bdxTypes, tpa]);

  const { mutateAsync: updateConfig, isLoading: isSubmitting } = useUpdateFacilityConfig({ umr, bdxType: activeTab?.value });

  const { isDirty } = methods.formState;

  useEffect(() => {
    if (facilityConfig) {
      methods.reset({
        noOfHeaderRows: facilityConfig.noOfHeaderRows ?? 1,
        bdxCumulative: facilityConfig.bdxCumulative ?? false,
        ...(facilityConfig?.thirdParties?.length > 0 && {
          operationalThirdParties: facilityConfig?.thirdParties?.map(({ id }) => id) || [],
        }),
        clientDueDateOffset: facilityConfig?.clientDueDateOffset ?? null,
        dueDateOffset: facilityConfig?.dueDateOffset ?? null,
        claimsThirdPartyAccount: facilityConfig?.claimsThirdPartyAccount ?? null,
      });
    } else {
      methods.reset({
        noOfHeaderRows: 1,
        bdxCumulative: false,
        operationalThirdParties: [],
        clientDueDateOffset: null,
        dueDateOffset: null,
        claimsThirdPartyAccount: null,
      });
    }
  }, [methods, facilityConfig]);

  const onSubmit = async () => {
    if (await methods.trigger()) {
      const { noOfHeaderRows, bdxCumulative, operationalThirdParties, clientDueDateOffset, dueDateOffset, claimsThirdPartyAccount } =
        methods.getValues();

      await updateConfig({
        noOfHeaderRows,
        bdxCumulative,
        operationalThirdParties,
        clientDueDateOffset,
        dueDateOffset,
        claimsThirdPartyAccount,
        thirdPartyAccountConfig: getThirdPartyAccountConfig(claimsThirdPartyAccount),
      });
      methods.reset();
    }
  };

  useEffect(() => {
    if (tabs?.length) {
      setActiveTab(tabs[0]);
    }
  }, [tabs]);

  const handleDirtyFormChangeTab = (newTab) => {
    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: utils.string.t('form.facilityConfig.confirmModalTitle'),
          subtitle: utils.string.t('form.facilityConfig.confirmModalSubTitle'),
          fullWidth: false,
          maxWidth: 'xs',
          componentProps: {
            submitHandler: async () => {
              await onSubmit();
              setActiveTab(newTab);
            },
            cancelHandler: () => {
              setActiveTab(newTab);
              methods.reset();
            },
            cancelLabel: utils.string.t('app.continue'),
            confirmLabel: utils.string.t('app.save'),
          },
        },
      })
    );
  };

  const handleChangeTab = (_, newTabId) => {
    if (newTabId) {
      const newTab = tabs.find(({ value }) => value === newTabId);
      if (isDirty) {
        handleDirtyFormChangeTab(newTab);
      } else {
        setActiveTab(newTab);
        methods.reset();
      }
    }
  };

  const handleClose = () => {
    dispatch(hideModal('FACILITY_CONFIG'));
  };

  if (isLoading) {
    return (
      <div className={classes.loader} data-testid="loader">
        <CircularProgress />
      </div>
    );
  }

  if (bdxTypes?.length === 0 || activeTab === null) {
    return <Empty width={400} title={utils.string.t('app.noBDX')} icon={<FlashWarningSVG />} padding />;
  }

  return (
    <>
      <Tabs
        value={activeTab.value}
        onChange={handleChangeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="config tabs"
        data-testid="config-tabs"
      >
        {tabs?.map(({ value, label }) => (
          <Tab key={value} label={label} value={value} data-testid="config-tab" />
        ))}
      </Tabs>

      <div className={classes.content}>
        {tabs?.map((tab) => (
          <TabPanel key={tab.value} id={tab.value} value={activeTab.value}>
            <FacilityConfigView
              fields={tab.fields}
              tab={tab.value}
              isLoadingFacilityConfig={isLoadingFacilityConfig}
              isSubmitting={isSubmitting}
              methods={methods}
              onSubmit={onSubmit}
              handleClose={handleClose}
              umr={umr}
            />
          </TabPanel>
        ))}
      </div>
    </>
  );
};

export default FacilityConfig;
