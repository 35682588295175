export const ALL_CLAIMS_TASKS = 'ALL_CLAIMS_TASKS';
export const MY_CLAIMS_TASKS = 'MY_CLAIMS_TASKS';

export const CLAIMS_TASKS_STATUS_PENDING = 'PENDING';
export const CLAIMS_TASKS_STATUS_ACTIONED = 'ACTIONED';
export const CLAIMS_TASKS_STATUS_QUERIED = 'QUERIED';
export const CLAIMS_TASKS_STATUS_COMPLETED = 'COMPLETED';

export const CLAIMS_TASKS_STATUS = [
  { label: 'Pending', value: 'PENDING', name: 'Pending' },
  { label: 'Actioned', value: 'ACTIONED', name: 'Actioned' },
  { label: 'Queried', value: 'QUERIED', name: 'Queried' },
  { label: 'Completed', value: 'COMPLETED', name: 'Completed' },
];

export const CLAIMS_SLA_WARNINGS = [
  { label: 'SLA Breached', value: 'BREACHED' },
  { label: 'SLA Expires Today', value: 'EXPIRES_TOMORROW' },
  { label: 'SLA Expires Tomorrow', value: 'EXPIRES_TODAY' },
  { label: '', value: 'NONE' },
];
export const BREACHED = 'BREACHED';
export const EXPIRES_TOMORROW = 'EXPIRES_TOMORROW';
export const EXPIRES_TODAY = 'EXPIRES_TODAY';
export const NONE = 'NONE';
export const STATUS_COUNT = 'STATUS_COUNT';
export const DUE_TOMORROW = 'DUE_TOMORROW';
export const DUE_TODAY = 'DUE_TODAY';
export const PASSED_DUE = 'PASSED_DUE';
export const REMINDER_RECIPIENTS = [
  { label: 'Primary Handler', value: 'primaryHandler' },
  { label: 'Secondary Handler', value: 'secondaryHandler' },
];
export const TASK_WARNINGS = [
  {
    status: 'SLA Breached',
    edgeStatus: 'breached',
    count_reference: PASSED_DUE,
  },
  {
    status: 'SLA Expires today',
    edgeStatus: 'expires_today',
    count_reference: DUE_TODAY,
  },
  {
    status: 'SLA Expires tomorrow',
    edgeStatus: 'expires_tomorrow',
    count_reference: DUE_TOMORROW,
  },
];
export const CLAIMS_TASK_STATS = [
  {
    status: 'Pending',
    edgeStatus: 'na',

    count_reference: CLAIMS_TASKS_STATUS_PENDING,
  },
  {
    status: 'Actioned',
    edgeStatus: 'inprogress',

    count_reference: CLAIMS_TASKS_STATUS_ACTIONED,
  },
  {
    status: 'Queried',
    edgeStatus: 'light',

    count_reference: CLAIMS_TASKS_STATUS_QUERIED,
  },
  {
    status: 'Completed',
    edgeStatus: 'completed',

    count_reference: CLAIMS_TASKS_STATUS_COMPLETED,
  },
];

export const CLAIMS_TASKS_SORT_BY_OPTIONS = [
  { sortBy: 'emailReceivedDate', sortDirection: 'DESC', label: 'Email Received Date - Newest' },
  { sortBy: 'emailReceivedDate', sortDirection: 'ASC', label: 'Email Received Date - Oldest' },
  { sortBy: 'dateCompleted', sortDirection: 'DESC', label: 'Date Completed - Newest' },
  { sortBy: 'dateCompleted', sortDirection: 'ASC', label: 'Date Completed - Oldest ' },
  { sortBy: 'dueDate', sortDirection: 'DESC', label: 'Due Date - Newest' },
  { sortBy: 'dueDate', sortDirection: 'ASC', label: 'Due Date - Oldest ' },
];

export const CLAIMS_FILTER_MIN_DATE = '2018-01-01';
export const CLAIMS_TASKS_FILTER_BY_OPTIONS = [
  {
    title: 'Status',
    key: 'status',
    type: 'autocomplete',
    options: CLAIMS_TASKS_STATUS.sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    title: 'Division',
    key: 'division',
    type: 'autocomplete',
  },
  {
    title: 'Created By',
    key: 'createdBy',
    type: 'autocomplete',
  },
  {
    title: 'Email Received Date',
    key: 'emailReceivedDate',
    type: 'Date',
  },
  {
    title: 'Date Completed',
    key: 'dateCompleted',
    type: 'Date',
  },
  {
    title: 'Date Created',
    key: 'dateCreated',
    type: 'Date',
  },
  {
    title: 'SLA Due Date',
    key: 'slaRange',
    type: 'Date',
  },
];
