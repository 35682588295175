import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { ReactComponent as Error } from 'assets/svg/error.svg';

import config from 'config';
import { TServices, useGetAvaliableApps } from 'lib/app';
import { selectUser } from 'stores';

interface IUnderMaintenanceProviderProps {
  children: ReactNode;
}

const UnderMaintenanceMap: Partial<Record<string, TServices>> = {
  [config.routes.admin.root]: 'admin',
  [config.routes.client.root]: 'clients',
  [config.routes.client.item]: 'clients',
  [config.routes.claimsTaskManagement.root]: 'ctmv1',
  [config.routes.modelling.root]: 'modelling',
  [config.routes.placement.root]: 'placement',
  [config.routes.binders.root]: 'pmv1',
  [config.routes.quoteBind.root]: 'qbv1',
  [config.routes.reporting.root]: 'reportingv1',
  [config.routes.trip.root]: 'travel',
  [config.routes.opportunity.root]: 'travel',
  [config.routes.placement.root]: 'placement',
  [config.routes.department.root]: 'placement',
  // should be last
  '/': 'homev1',
};

export const UnderMaintenanceProvider = ({ children }: IUnderMaintenanceProviderProps) => {
  const user = useSelector(selectUser);
  const [activeApp, setActiveApp] = useState<TServices | null>(null);
  const { data: apps, refetch, isLoading, isError } = useGetAvaliableApps({ email: user?.emailId ?? '', app: activeApp });
  const location = useLocation();

  useEffect(() => {
    setActiveApp((Object.entries(UnderMaintenanceMap).find(([path]) => location.pathname.includes(path))?.[1] as TServices) ?? null);
  }, [location]);

  useEffect(() => {
    if (activeApp) {
      refetch();
    }
  }, [activeApp, refetch]);

  if (isError) {
    return children;
  }

  if (activeApp && !isLoading && !apps?.[activeApp]) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 24px',
        }}
      >
        <Error style={{ height: 400 }} />
        <Typography variant="h3" component="span" style={{ marginTop: 8, textAlign: 'center' }}>
          Service Under Maintenance
        </Typography>

        <Typography variant="body2" style={{ marginTop: 8, textAlign: 'center' }}>
          We sincerely apologize for the inconvenience.
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          This service is currently undergoing scheduled maintenance and upgrades, but will return shortly.
        </Typography>

        <Typography variant="h4" style={{ marginTop: 16, textAlign: 'center' }}>
          Thank you for your patience
        </Typography>
      </div>
    );
  }

  return children;
};
