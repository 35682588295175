import { FC } from 'react';

import { Skeleton } from '@material-ui/lab';

import { binderLimitStyles } from './BinderLimit.styles';

export const BinderLimitSkeleton: FC = () => {
  const classes = binderLimitStyles();

  return (
    <div className={classes.limit}>
      <div className={classes.limitSkeleton}>
        <Skeleton width={300} height={30} />
      </div>
    </div>
  );
};
