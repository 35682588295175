// useGetQuotes
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getCoverageDefinition(product, type, token) {
  if (!product || !type) {
    throw new Error('useGetCoverageDefinition: product and type are required');
  }

  const apiUrl = `products/${product}?type=${type}`;

  const response = await api(token).get(apiUrl);
  return response.data;
}

export function useGetCoverageDefinition(queryKey, product, type, options) {
  return useApi([queryKey, product, type], async (_, token) => await getCoverageDefinition(product, type, token), {
    cacheTime: 1000 * 60 * 10,
    ...options,
  });
}
