import { useDispatch } from 'react-redux';

import { useConfirmCancelModal } from 'components/Modal/useConfirmCancelModal';

import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

export const useUploadBDXCoverHolderModal = () => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(hideModal('UPLOAD_BDX_COVERHOLDER'));
  };

  const confirmCancelHandler = useConfirmCancelModal(handleConfirm);

  const openUploadBDXCoverHolderModal = ({ coverHolderName, id, defaultBdxType = null, uploadBdxCoverHolderRedirectCallback = null }) =>
    dispatch(
      showModal({
        component: 'UPLOAD_BDX_COVERHOLDER',
        props: {
          title: [utils.string.t('documents.upload.bdx.title'), coverHolderName].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            defaultBdxType,
            isCoverHolderUpload: true,
            confirmCancel: true,
            operationalClientId: id,
            confirmCancelHandler,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX_COVERHOLDER')),
            callbackOnSuccess: () => uploadBdxCoverHolderRedirectCallback(id),
          },
        },
      })
    );

  return openUploadBDXCoverHolderModal;
};
