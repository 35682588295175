import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { ConfirmView } from './Confirm.view';

Confirm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
};

export default function Confirm({ submitHandler, cancelHandler, handleClose, cancelLabel, confirmLabel }) {
  const handleConfirm = () => {
    if (utils.generic.isFunction(submitHandler)) {
      submitHandler();
      handleClose();
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(cancelHandler)) {
      cancelHandler();
    }

    handleClose();
  };

  return <ConfirmView handleConfirm={handleConfirm} handleClose={handleCancel} cancelLabel={cancelLabel} confirmLabel={confirmLabel} />;
}
