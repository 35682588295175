import { useQueries } from 'react-query';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_THIRD_PARTY_OFFICES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetBMThirdPartyAccountsProps {
  tpa: number[];
}

export const useGetBMThirdPartyAccounts = ({ tpa = [] }: IUseGetBMThirdPartyAccountsProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetBMThirdPartyAccounts',
  });

  return useQueries(
    tpa.map((thirdParty) => ({
      queryKey: [FACILITY_THIRD_PARTY_OFFICES, thirdParty],
      queryFn: async () => {
        const { data }: { data: IThirdPartyAccount[] } = await binderManagementFacilitiesAxios(`thirdParties/${thirdParty}/accounts`);
        return data;
      },
      onError: handleError,
    }))
  );
};
