import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

// app
import { resetFilter, setFilter } from 'stores';
import * as utils from 'utils';

import FilterWithDrawerView from './FilterWithDrawer.view';

FilterWithDrawer.propTypes = {
  size: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleFilterOpen: PropTypes.func,
  selectedFilters: PropTypes.object,
  options: PropTypes.array,
  resetFilters: PropTypes.func,
};

export default function FilterWithDrawer({
  size,
  category,
  page,
  title,
  isOpen,
  options,
  selectedFilters,
  toggleFilterOpen,
  getFilters,
  // resetFilters,
  disabled = false,
}) {
  const dispatch = useDispatch();

  const numberOfFilters = selectedFilters ? Object.keys(selectedFilters).filter((key) => !isEmpty(selectedFilters[key])).length : 0;

  const handleResetFilter = () => {
    dispatch(resetFilter({ page, category }));
    // resetFilters();
  };

  const handleValueChange = (value, key) => {
    if (value?.key === 'selection') {
      value.startDate = utils.date.toISOString(value.startDate).split('T')[0];
      value.endDate = utils.date.toISOString(value.endDate).split('T')[0];
    }

    const newFilters = { ...selectedFilters, [key]: value };
    if (!value || value?.length === 0) {
      delete newFilters[key];
    }
    dispatch(setFilter({ category, page, options: newFilters }));
  };

  return (
    <FilterWithDrawerView
      size={size}
      filterIsOpen={isOpen}
      page={page}
      title={title}
      numberOfFilters={numberOfFilters}
      filterOptions={options}
      selectedFilters={selectedFilters}
      getFilters={getFilters}
      toggleFilterOpen={toggleFilterOpen}
      handleResetFilter={handleResetFilter}
      handleValueChange={handleValueChange}
      disabled={disabled}
    />
  );
}
