import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// app
import { addReportGroupUser, selectReportAdminList } from 'stores';
import { ReportGroupSearchUsersView } from './ReportGroupSearchUsers.view';
import usersUtils from '../../utils/users/users';
import { useGetUserList } from 'lib/admin';

ReportGroupSearchUsers.propTypes = {
  searchVal: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};
export default function ReportGroupSearchUsers({ searchVal, groupId }) {
  const groupUserList = useSelector(selectReportAdminList);
  const { data: userList } = useGetUserList({ size: 10000 });
  const dispatch = useDispatch();

  const newUserList = userList?.content.map((user) => ({
    ...user,
    hasAccess: groupUserList && groupUserList.filter((e) => e.id === user.id).length > 0,
  }));

  const searchedUserList = newUserList?.length > 0 ? usersUtils.filterUserList(newUserList, searchVal) : null;

  const handleAddUser = (user) => {
    dispatch(addReportGroupUser(groupId, user));
  };

  return <ReportGroupSearchUsersView userList={searchedUserList ? searchedUserList : null} handleAddUser={handleAddUser} />;
}
