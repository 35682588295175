import { useDispatch } from 'react-redux';

import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

export const useConfirmCancelModal = (handleConfirm, type = 'page') => {
  const dispatch = useDispatch();
  const title = {
    page: 'navigation.page.title',
    deletion: 'navigation.deletion.title',
    selection: 'navigation.selection.title',
  }[type];

  const subtitle = {
    page: 'navigation.page.subtitle',
    deletion: 'navigation.deletion.subtitle',
    selection: 'navigation.selection.subtitle',
  }[type];

  const cancelLabel = {
    page: 'navigation.page.cancel',
    deletion: 'navigation.deletion.cancel',
    selection: 'navigation.selection.cancel',
  }[type];

  const confirmLabel = {
    page: 'navigation.page.confirm',
    deletion: 'navigation.deletion.confirm',
    selection: 'navigation.selection.confirm',
  }[type];

  const openConfirmationModal = (payload) =>
    dispatch(
      showModal({
        type: 'CONFIRM',
        component: 'CONFIRM',
        props: {
          title,
          subtitle,
          fullWidth: true,
          maxWidth: 'sm',
          disableEscapeKey: true,
          componentProps: {
            cancelLabel: utils.string.t(cancelLabel),
            confirmLabel: utils.string.t(confirmLabel),
            submitHandler: () => {
              handleConfirm(payload);
              dispatch(hideModal('CONFIRM'));
            },
          },
        },
      })
    );

  return openConfirmationModal;
};
