import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firstBy } from 'thenby';

// mui
import { makeStyles, Box, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Edge
import { setSourceSystemId, setDepartmentSelected } from 'stores';
import { PopoverMenu } from 'components';
import { BrandPopover } from './BrandPopover';
import { useMedia } from 'hooks';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  logoBox: {
    width: 120,
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  navBox: {
    margin: '0 20px',
    height: '100%',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
  },
}));

const getDefaultDepartment = (user, sourceSystemId) => {
  return (
    user?.departments?.find((department) => department.id === user.departmentSelected) ||
    user?.departments?.find((department) => department.sourceSystemId === sourceSystemId) ||
    user?.departments?.[0]
  );
};

export const DepartmentSelect = ({ brands, onDepartmentChange, sourceSystems, sourceSystemId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const media = useMedia();
  const user = useSelector((state) => state.user);

  const [deptSelected, setDeptSelected] = useState(() => getDefaultDepartment(user, sourceSystemId));

  const departmentList = user?.departments?.sort(firstBy(utils.sort.array('lexical', 'name'))) || [];

  const selectedSourceSystemId = deptSelected?.sourceSystemId;
  const selectedBrand = brands?.find((brand) =>
    brand.sourceSystems?.some(({ sourceSystemId }) => sourceSystemId === selectedSourceSystemId)
  );

  const deptItemsFiltered = departmentList
    .map((department) => {
      if (!department.id || !department.name) return null;

      return {
        id: department.id,
        label: department.name,
        sourceSystemId: department.sourceSystemId,
        callback: () => {
          handleDepartmentChange(department);
        },
      };
    })
    .filter((department) => department?.sourceSystemId === selectedSourceSystemId);

  const userSourceSystemIds = [...new Set(departmentList.map((department) => department.sourceSystemId))];
  const userSourceSystems = sourceSystems.filter((sourceSystem) => userSourceSystemIds.includes(sourceSystem.id));

  const userBrands =
    brands?.filter((brand) => brand.sourceSystems.some((sourceSystem) => userSourceSystemIds.includes(sourceSystem.sourceSystemId))) || [];

  const brandSourceSystem =
    selectedBrand?.sourceSystems
      ?.filter((sourceSystem) => userSourceSystemIds.includes(sourceSystem.sourceSystemId))
      .map((sourceSystem) => ({
        id: sourceSystem.sourceSystemId,
        label: sourceSystem.sourceSystemName,
        brandId: selectedBrand.brandId,
        callback: () => {
          handleSourceSystemChange(sourceSystem.sourceSystemId);
        },
      })) || [];

  const handleDepartmentChange = (department) => {
    dispatch(setDepartmentSelected(department.id));
    setDeptSelected(department);
    if (utils.generic.isFunction(onDepartmentChange)) onDepartmentChange(department.id, department.name);
  };

  const handleSourceSystemChange = (id) => {
    const firstDepartment = departmentList.find((dept) => dept.sourceSystemId === id);
    handleDepartmentChange(firstDepartment);
    dispatch(setSourceSystemId(id));
  };

  const handleBrandChange = (id) => {
    const firstSourceSystem = userSourceSystems.find((sourceSystem) => sourceSystem.brandId === id);
    const firstDepartment = departmentList.find((dept) => dept.sourceSystemId === firstSourceSystem.id);
    handleDepartmentChange(firstDepartment);
    dispatch(setSourceSystemId(firstDepartment.sourceSystemId));
  };

  const departmentSelect = (
    <PopoverMenu
      id="department-select"
      text={deptSelected.name || ''}
      size="medium"
      items={deptItemsFiltered}
      icon={deptItemsFiltered?.length > 1 ? ArrowDropDownIcon : null}
      iconPosition="right"
      nestedClasses={{ label: { fontWeight: 600 } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    />
  );

  const sourceSystemSelect = (
    <PopoverMenu
      id="source-system-select"
      text={brandSourceSystem.find((sourceSystem) => sourceSystem.id === selectedSourceSystemId)?.label || ''}
      size="medium"
      items={brandSourceSystem.sort((a, b) => a.label.localeCompare(b.label))}
      icon={brandSourceSystem?.length > 1 ? ArrowDropDownIcon : null}
      iconPosition="right"
      nestedClasses={{ label: { fontWeight: 600 } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    />
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" className={classes.root}>
      {userBrands && selectedBrand ? (
        <Box className={classes.logoBox}>
          <BrandPopover brands={userBrands} selectedBrand={selectedBrand} handleBrandChange={handleBrandChange} />
        </Box>
      ) : null}
      <Box display="flex" alignItems="center" justifyContent="flex-start" className={classes.root}>
        {media.desktopUp ? (
          <Box display="flex" alignItems="center" className={classes.navBox}>
            <NavigateNextIcon fontSize="small" />
          </Box>
        ) : null}
        <Typography variant="body1">{sourceSystemSelect}</Typography>
        {media.desktopUp ? (
          <Box display="flex" alignItems="center" className={classes.navBox}>
            <NavigateNextIcon fontSize="small" />
          </Box>
        ) : null}
        <Typography variant="body1">{departmentSelect}</Typography>
      </Box>
    </Box>
  );
};
