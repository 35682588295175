// app
import { PreBindQuoteView } from './PreBindQuote.view';
import { Empty } from 'components';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';

import * as utils from 'utils';
import { RISK_QUOTE_PREBIND } from 'consts';
import { useGetPreBindDefinitions, usePostPreBindInfo, QB_PRE_BIND_DEFINITIONS } from 'lib/quoteBind';

PreBindQuote.defaultProps = {
  handleClose: () => {},
};

export function PreBindQuote({ product: productName, risk, quote, handleClose }) {
  const riskData = risk?.risk;
  const riskId = risk?.id;
  const facilityId = quote?.facilityId;

  const { data: preBindDefinitionsData, isLoading: isPreBindInfoLoading } = useGetPreBindDefinitions(
    QB_PRE_BIND_DEFINITIONS,
    productName,
    RISK_QUOTE_PREBIND,
    facilityId,
    {}
  );
  const { mutateAsync: postPreBindInfo } = usePostPreBindInfo();

  const preBindInfo = preBindDefinitionsData?.data?.product || [];

  const fields = utils.risk.parseFields(preBindInfo, {});

  const handleSubmit = async (data) => {
    const requestPayload = { ...data, riskType: productName };
    await postPreBindInfo({ riskId, body: requestPayload });
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose())) {
      handleClose();
    }
  };
  const actions = [
    {
      name: 'submit',
      label: utils.string.t('app.submit'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  if (isPreBindInfoLoading) {
    return <Empty width={400} title={utils.string.t('products.loadInProgress')} icon={<Loading />} padding />;
  }

  return (
    <PreBindQuoteView
      actions={actions}
      fields={fields}
      defaultValues={riskData ? utils.form.getFormattedValues(riskData, fields) : utils.form.getInitialValues(fields)}
      isLoading={isPreBindInfoLoading}
    />
  );
}

export default PreBindQuote;
