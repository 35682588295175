import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// mui
import { makeStyles } from '@material-ui/core';

// app
import styles from './AddEditOperationalThirdPartyOffice.styles';
import { Form } from 'components';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { selectRefDataCountriesIso2 } from 'stores';
import { edgeAdminSchema } from 'schemas';
import * as utils from 'utils';
import { useCreateThirdPartyOffice, useEditThirdPartyOffice } from 'lib/admin';

export default function AddEditOperationalThirdPartyOffice({ handleClose, operationalId, office = {} }) {
  const classes = makeStyles(styles, { name: 'AddEditOperationalThirdPartyOffice' })();

  const countries = useSelector(selectRefDataCountriesIso2);
  const [options, setOptions] = useState({ countries });
  const { isLoading: isCreatingPartyOffice, mutate: createThirdPartyOffice } = useCreateThirdPartyOffice();
  const { mutate: editThirdPartyOffice } = useEditThirdPartyOffice();

  const { fields } = edgeAdminSchema.getSchema('operationalClientOffice');

  const handleSubmit = (data) => {
    const { name, country, locationAutocomplete, ...rest } = data;

    const thirdPartyOffice = {
      name,
      parentId: operationalId,
      address: {
        ...rest,
        country: country?.label || '',
        countryCode: country?.value || '',
      },
    };

    if (office.id) {
      editThirdPartyOffice({ office: { ...thirdPartyOffice }, id: office.id });
    } else {
      createThirdPartyOffice({
        ...thirdPartyOffice,
        thirdPartyIds: [],
      });
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const submitLabel = office.id ? 'app.save' : 'admin.addOperationalClientOffice';

  useEffect(() => {
    if (utils.generic.isValidArray(countries, true)) {
      setOptions((prevState) => ({ ...prevState, countries }));
    }
  }, [countries]);

  const hydrateOptions = (fields) => {
    if (!fields) return [];

    return fields.map((field) => {
      const fieldOptions = field?.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options
        ? field.options.map((option) => ({ ...option, label: utils.string.t(option.label) }))
        : [];

      return {
        ...field,
        label: utils.string.t(field.label),
        options: fieldOptions,
        ...(field?.optionsDefaultValue && {
          value: fieldOptions?.find((option) => option.default === true),
        }),
      };
    });
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t(submitLabel),
      handler: handleSubmit,
      disabled: isCreatingPartyOffice,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
      disabled: isCreatingPartyOffice,
    },
  ];

  const hasFields = utils.generic.isValidArray(fields, true);
  const hydratedFields = [hydrateOptions(fields)];

  // abort
  if (!hasFields || utils.generic.isInvalidOrEmptyArray(options?.countries)) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  const { city, country, countryCode, county, postCode, state, street } = office?.address || {};

  const editValues = {
    name: office?.name,
    country: {
      label: country,
      value: countryCode,
    },
    county,
    city,
    postCode,
    state,
    street,
  };

  return (
    <div className={classes.root}>
      <Form
        id="add-edit-operational-client-office"
        type="dialog"
        fields={hydratedFields}
        actions={actions}
        defaultValues={office?.id ? editValues : utils.form.getInitialValues(hydratedFields)}
        validationSchema={utils.form.getValidationSchema(hydratedFields)}
      />
    </div>
  );
}
