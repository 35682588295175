import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_RULE_COLUMNS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetRuleColumns = (params = {}) => {
  const { riskCodes, bdxType } = params;
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetRuleColumns' });

  return useQuery(
    [DROOLS_RULE_COLUMNS, riskCodes, bdxType],
    async () => {
      if (!bdxType || !riskCodes) {
        return [];
      }
      const result = await binderManagementDroolsAxios.get(`/drools/columns?riskCodes=${riskCodes.join(',')}&bdxType=${bdxType}`);
      return result?.data?.columns;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
