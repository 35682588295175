import { useQueries } from 'react-query';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_CONFIG } from '../../binderManagement.queryKeys';

const getFacilityConfig = async ({ umr, bdxType }) => {
  try {
    const { data } = await binderManagementFacilitiesAxios(`facility/${umr}/${bdxType}/config`);
    return data;
  } catch (error) {
    if (error.response.status === 404) {
      return {
        bdxType,
        notFound: true,
      };
    }

    throw Error(error);
  }
};

export const useGetAllFacilityConfig = ({ umr, bdxTypes, options }) => {
  const queries =
    bdxTypes?.map((bdxType) => ({
      queryKey: [FACILITY_BINDER_CONFIG, umr, bdxType],
      queryFn: () => getFacilityConfig({ umr, bdxType }),
      staleTime: Infinity,
    })) ?? [];

  return useQueries<IFacilityConfig[]>(queries);
};
