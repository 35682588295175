import { FC, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { IBDXDragDropRefProps } from 'components/BDXDragDrop/BDXDragDrop';

import { useSubmitLineageDocument, useUploadLineageDocument, useValidatePassword } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { IUploadLineageReportForm, IUploadLineageReportProps } from './UploadLineageReport.types';
import { UploadLineageReportView } from './UploadLineageReport.view';

export const UploadLineageReport: FC<IUploadLineageReportProps> = ({ yearMonthSubmission, onSuccess }) => {
  const dispatch = useDispatch();
  const dragDropRef = useRef<IBDXDragDropRefProps>(null);
  const [requestId, setRequestId] = useState<string>(null);
  const [file, setFile] = useState<File>(null);
  const [password, setPassword] = useState<string>(null);
  const [sheets, setSheets] = useState<string[]>([]);
  const [isFileReady, setFileReady] = useState(false);

  const { mutateAsync: uploadLineageDocument, isLoading: isUploadingFile } = useUploadLineageDocument();
  const { mutateAsync: validatePassword } = useValidatePassword();
  const { mutateAsync: submitLineage, isLoading: isSubmitting } = useSubmitLineageDocument();

  const methods = useForm<IUploadLineageReportForm>();

  const resetOnFileChange = useCallback(() => {
    setPassword(null);
    setFile(null);
    setSheets([]);
    methods.setValue('sheetName', '');
    setRequestId(null);
    setFileReady(false);
  }, [setPassword, setSheets, methods, setRequestId]);

  const resetForm = useCallback(() => {
    resetOnFileChange();
    dragDropRef?.current?.removeFile();
  }, [resetOnFileChange]);

  const handleFileUploadSuccess = useCallback(({ data }: Partial<AxiosResponse<IFileUploadSecurity>>) => {
    setSheets(data.sheets);
    setRequestId(data.requestId);
    setFileReady(true);
  }, []);

  const handleUploadLineage = useCallback(
    async (uploadedFile: File) => {
      if (uploadedFile) {
        setFileReady(false);
        const uploadData = await uploadLineageDocument({ file: uploadedFile });
        setRequestId(uploadData.requestId);

        if (uploadData.securityStatus === 'PASSWORD_REQUIRED') {
          if (password) {
            const dataWithPassword = await validatePassword({ password, requestId });
            handleFileUploadSuccess(dataWithPassword);
          } else {
            dispatch(
              showModal({
                component: 'VALIDATE_FILE_PASSWORD',
                props: {
                  title: 'form.bdx.validateFilePassword',
                  fullWidth: true,
                  maxWidth: 'xs',
                  disableBackdropClick: true,
                  componentProps: {
                    requestId: uploadData.requestId,
                    cancelHandler: () => {
                      resetForm();
                    },
                    onSuccess: ({ data, password: pass }: { data: AxiosResponse<IFileUploadSecurity>; password: string }) => {
                      handleFileUploadSuccess(data);
                      setPassword(pass);
                    },
                  },
                },
              })
            );
          }
        }
        if (uploadData.securityStatus === 'PASSWORD_NOT_REQUIRED') {
          handleFileUploadSuccess({ data: uploadData });
        }
      }
    },
    [dispatch, handleFileUploadSuccess, password, requestId, resetForm, uploadLineageDocument, validatePassword]
  );

  const fields: FormFields<IUploadLineageReportForm> = [
    {
      name: 'sheetName',
      id: 'sheetName',
      label: utils.string.t('form.bdx.sheet'),
      type: 'select',
      optionKey: 'label',
      optionLabel: 'label',
    },
  ];

  const handleSubmit = async () => {
    if (await methods.trigger()) {
      const { sheetName } = methods.getValues();
      await submitLineage({ requestId, sheetName, yearMonthSubmission, password, fileName: file.name });
      onSuccess();
    }
  };

  return (
    <UploadLineageReportView
      setFile={setFile}
      methods={methods}
      resetOnFileChange={resetOnFileChange}
      file={file}
      handleUploadFile={handleUploadLineage}
      dragDropRef={dragDropRef}
      isLoading={isUploadingFile || isSubmitting}
      isReady={isFileReady}
      fields={fields}
      sheets={sheets}
      handleSubmit={handleSubmit}
    />
  );
};
