import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { INSUREDS, REINSUREDS, QB_INSURED } from 'lib/quoteBind/queryKeys';

export const usePostInsured = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePostInsured',
      message: 'There is an error when adding new in/re insured',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);

    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = ({ isEdit, reInsured, isCreateInsuredModal, result }) => {
    queryClient.invalidateQueries(reInsured ? REINSUREDS : INSUREDS);
    queryClient.invalidateQueries([QB_INSURED, result?.data?.id]);

    const success = isEdit ? 'postEditSuccess' : 'postSuccess';
    const successNotification = reInsured ? `notification.reInsured.${success}` : `notification.insured.${success}`;

    dispatch(hideModal(isCreateInsuredModal ? (isEdit ? 'EDIT_PRODUCTS_INSURED' : 'ADD_INSURED') : undefined));
    dispatch(enqueueNotification(successNotification, 'success'));
  };

  return useMutation(
    async (params) => {
      const { body, isCreateInsuredModal, reInsured, isEdit = false } = params;

      if (!body || isEmpty(body)) {
        throw new Error('Data missing.');
      }

      const path = reInsured ? 'reinsured' : 'insured';

      const data = {
        ...(body?.id && { id: body.id }),
        name: body.name || '',
        clientId: get(body, 'clientId.value', ''),
        partyType: get(body, 'partyType.value', ''),
        genderType: get(body, 'genderType.value', null),
        dateOfBirth: body.dateOfBirth ? utils.date.toISOString(body.dateOfBirth) : null,
        address: {
          street: body.street || '',
          city: body.city || '',
          zipCode: body.zipCode || '',
          county: body.county || '',
          state: body.state || '',
          country: get(body, 'country.value', ''),
          distanceToCoast: '',
        },
        productCode: body.productCode || '',
      };

      const result = await quoteBindAxios.post(path, data);

      return { result, isEdit, reInsured, isCreateInsuredModal };
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
