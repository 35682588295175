import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesDocumentsAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_DOCUMENT_FOLDERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useDeleteFacilityDocument = ({ umr, folder }: IDeleteFacilityDocumentDTO) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useDeleteFacilityDocument',
    warning: 'notification.binderManagement.documentDeleteFail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries([FACILITY_BINDER_DOCUMENT_FOLDERS, umr]);

    dispatch(enqueueNotification('notification.binderManagement.documentDelete', 'success'));
  };

  return useMutation<void, AxiosError, IDeleteFacilityDocumentDTO>(
    async ({ fileName }) => await binderManagementFacilitiesDocumentsAxios.delete(`${umr}/${folder}/${fileName}`),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
