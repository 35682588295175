import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';
import * as utils from 'utils';

async function getRiskDetails(params, token) {
  if (!params?.id) {
    throw new Error('useGetRiskDetails: id is required');
  }

  const apiUrl = params.refresh ? `risks/${params.id}/refresh` : `risks/${params.id}`;

  const requestParams = {
    atDate: utils.date.formatDate('YYYY-MM-DDTHH:mm:ss'),
  };

  const response = await api(token).get(apiUrl, {
    params: requestParams,
  });

  return response.data;
}

export function useGetRiskDetails(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (params, token) => await getRiskDetails(params, token), {
    cacheTime: 1000 * 60,
    ...options,
  });
}
