import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_COVERHOLDER_FACILITY_FILTERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetReports = ({ reportType }: IGetReportsProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.reports.queries.useGetReports',
  });

  return useQuery<IGetReportsResponse, AxiosError>(
    [FACILITY_COVERHOLDER_FACILITY_FILTERS, reportType],
    async () => {
      const result = await binderManagementFacilitiesAxios.get(`reports/${reportType}`);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
