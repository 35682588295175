import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';
import { DRAFT_RISKS } from 'lib/quoteBind/queryKeys';

export const useDeleteDraftRisk = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.deleteRiskDocument',
      message: 'Data missing for DELETE request',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.deleteDraft.fail', 'error'));
  };

  const handleSuccess = ({ isSubmitted }) => {
    queryClient.invalidateQueries(DRAFT_RISKS);

    if (!isSubmitted) {
      dispatch(enqueueNotification('notification.deleteDraft.success', 'success'));
      dispatch(hideModal());
    }
  };

  return useMutation(
    async ({ draftId, isSubmitted }) => {
      dispatch(hideModal());
      if (!draftId) {
        throw new Error('useDeleteDraftRisk: draftId is required');
      }

      const result = await quoteBindAxios.delete(`/risks/drafts/${draftId}`);
      return {
        result,
        isSubmitted,
      };
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
