const styles = (theme) => ({
  container: {
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    width: '100%',
  },
  tab: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 40,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[900],
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      opacity: 0.2,
      position: 'absolute',
      background: 'none',
      transition: 'background 0.3s ease',
    },
    '& svg': {
      fill: 'currentColor',
      height: '20px',
      width: '20px',
      minWidth: '20px',
      transition: 'all 0.3s ease',
      position: 'relative',
      marginRight: theme.spacing(1),
    },
  },
  tabActive: {
    fontWeight: 700,
  },
  tabsComponent: {},
  tabLinks: {
    padding: '0',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 0, 3),
  },
  tabLinkActive: {
    fontWeight: 700,
  },
  tabLink: {
    position: 'relative',
    textDecoration: 'none',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    minWidth: 54,
    fontSize: theme.typography.pxToRem(14),
    cursor: 'pointer',
  },
  tabText: {
    fontSize: 14,
    fontWeight: 500,
    transition: 'all 0.3s ease',
    display: 'block',
  },
  activeText: {
    fontWeight: 700,
  },
});
export default styles;
