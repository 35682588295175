import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { BDX_AGGREGATE_TYPE_CLAIM, BDX_AGGREGATE_TYPE_PREMIUM } from 'consts';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetBDXAggregateProps {
  facilityReference: string;
  yearMonthSubmission: string;
  type: typeof BDX_AGGREGATE_TYPE_CLAIM | typeof BDX_AGGREGATE_TYPE_PREMIUM;
}

type TUseGetBDXAggregateReturn<T> = T extends typeof BDX_AGGREGATE_TYPE_CLAIM
  ? IBDXClaimAggregate[]
  : T extends typeof BDX_AGGREGATE_TYPE_PREMIUM
  ? IBDXPremiumAggregate[]
  : never;

export const useGetBdxAggregate = <T = typeof BDX_AGGREGATE_TYPE_CLAIM>({
  facilityReference,
  yearMonthSubmission,
  type,
}: IUseGetBDXAggregateProps) => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxClaimAggregate' });

  return useQuery<TUseGetBDXAggregateReturn<T>, AxiosError<IAPIError>>(
    [BDX_AGGREGATES, type, facilityReference, yearMonthSubmission],
    async () => {
      if (!facilityReference || !yearMonthSubmission) throw new Error('Missing required params');

      const url = `aggregate/${type}/facility/${facilityReference}/${yearMonthSubmission}`;

      const result = await binderManagementBDXAxios.get(url);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
