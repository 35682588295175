import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import get from 'lodash/get';

// app
import styles from './FilterBar.style';
import { Button, FormAutocomplete, FormContainer, FormFields, FormActions, FormGrid, FormText } from 'components';
import * as utils from 'utils';
import { useMedia } from 'hooks';

// mui
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

export function FilterBarView({ fields, actions, keepDirtyOnReinitialize = false }) {
  const media = useMedia();
  const classes = makeStyles(styles, { name: 'FilterBar' })({ isMobile: media.mobile });
  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);
  const spacing = media.mobile ? 2 : 0;

  const { control, reset, handleSubmit, formState, setValue } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  useEffect(() => {
    keepDirtyOnReinitialize && reset(utils.form.getInitialValues(fields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, keepDirtyOnReinitialize]);

  const resetAction = actions && actions.find((action) => action.name === 'reset');
  const filterAction = actions && actions.find((action) => action.name === 'filter');

  const onReset = () => {
    resetAction && utils.generic.isFunction(resetAction.handler) && resetAction.handler();
    reset();
  };

  const onFilter = (formData) => {
    filterAction && utils.generic.isFunction(filterAction.handler) && filterAction.handler(formData);
  };

  const fieldMap = {
    text: FormText,
    autocomplete: FormAutocomplete,
  };

  const filteredFields = fields.filter((field) => field.type !== fieldMap[field.type]);
  const ClearBtn = (
    <Button
      size="small"
      variant="text"
      icon={CloseIcon}
      onClick={onReset}
      nestedClasses={{ btn: classes.resetButton }}
      data-testid="search-button-clear"
    />
  );

  const renderField = (field, firstInput) => {
    const FieldComponent = fieldMap[field.type];

    switch (field.type) {
      case 'autocomplete':
        return (
          <FieldComponent
            control={control}
            {...field}
            innerComponentProps={{
              ...field.innerComponentProps,
              isClearable: false,
            }}
            muiComponentProps={{
              ...field.muiComponentProps,
              InputProps: {
                classes: {
                  root: classnames(classes.autocompleteRoot, { [classes.firstInput]: firstInput }),
                },
              },
            }}
            handleUpdate={(id, value) => {
              setValue(id, value, { shouldDirty: true });
            }}
          />
        );
      case 'text':
        return (
          <FieldComponent
            control={control}
            {...field}
            error={errors[field.name]}
            muiComponentProps={{
              InputProps: {
                ...get(field, 'muiComponentProps.InputProps', {}),
                classes: {
                  root: classnames(classes.filledRoot, { [classes.firstInput]: firstInput }),
                  input: classes.filledInput,
                },
                endAdornment: (
                  <InputAdornment position="end" classes={{ root: classes.adornmentEnd }}>
                    {resetAction && (formState.isDirty || field?.value) ? ClearBtn : <span />}
                  </InputAdornment>
                ),
              },
              ...field.muiComponentProps,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <FormContainer nestedClasses={{ root: classes.formContainer }} onSubmit={handleSubmit(onFilter)} data-testid="filter-bar">
        <FormFields nestedClasses={{ root: classes.formFields }}>
          <FormGrid container spacing={spacing}>
            {filteredFields.map((field, index) => (
              <FormGrid key={field.name} item {...field.gridSize} nestedClasses={{ root: classes.formGrid }}>
                {renderField(field, index === 0)}
              </FormGrid>
            ))}
          </FormGrid>
        </FormFields>
        <FormActions nestedClasses={{ actions: classes.actions }}>
          {filterAction ? (
            <IconButton
              disableRipple={true}
              type="submit"
              disabled={formState.isSubmitting}
              color="primary"
              className={classes.filterButton}
            >
              <SearchIcon />
            </IconButton>
          ) : null}
        </FormActions>
      </FormContainer>
    </div>
  );
}
