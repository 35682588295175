import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';
import * as Yup from 'yup';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';

import { CLAIMS_TASKS_STATUS_COMPLETED, CLAIMS_TASKS_STATUS_PENDING, REMINDER_RECIPIENTS } from 'consts';
import { useGetBrands } from 'lib/app';
import { useGetClaimsUserList, useGetTaskDetailList } from 'lib/claimsTaskManagement';
import { selectUser } from 'stores';
import * as utils from 'utils';

import { AddEditClaimsTaskForm } from './AddEditClaimsTaskForm';

// app
import { useAddEditClaimsTaskStyles } from './AddEditClaimsTask.styles';

const status = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Actioned', value: 'ACTIONED' },
  { label: 'Queried', value: 'QUERIED' },
  { label: 'Completed', value: 'COMPLETED' },
];

export const AddEditClaimsTask = ({ handleClose, data = null }) => {
  const classes = useAddEditClaimsTaskStyles();

  const user = useSelector(selectUser);
  const { data: brands, isLoading: brandsIsLoading } = useGetBrands('v2');
  const { data: userList, isLoading: userListIsLoading } = useGetClaimsUserList({ size: 10000 });
  const { data: taskDetailList, isLoading: taskDetailListIsLoading } = useGetTaskDetailList();

  const taskDetailOptions = useMemo(
    () =>
      taskDetailList?.map((taskDetail) => ({
        label: taskDetail.name,
        value: taskDetail.name,
      })) || [],
    [taskDetailList]
  );

  const isLoading = brandsIsLoading || userListIsLoading || taskDetailListIsLoading;

  const claimsUsers = useMemo(
    () =>
      userList?.map((claimsUser) => ({
        email: claimsUser.email,
        name: claimsUser.fullName,
      })) || [],
    [userList]
  );

  const usersOptions = useMemo(
    () =>
      claimsUsers.map((broker) => ({
        ...broker,
        label: utils.user.fullname(broker),
      })),
    [claimsUsers]
  );

  const departmentsOptionsFormatted = useMemo(
    () =>
      user?.departments
        ?.map((department) => {
          const { brandAndSourceSystemName, brandName, sourceSystemName } = utils.app.getBrandNameAndSourceSystemName(
            department.sourceSystemId,
            brands
          );

          return {
            ...department,
            name: department.name,
            groupBy: brandAndSourceSystemName,
            brandName,
            sourceSystemName,
            value: `${department.sourceSystemId}|${department.gxbDepartmentId}`,
          };
        })
        .sort(firstBy(utils.sort.array('lexical', 'groupBy', 'asc')).thenBy(utils.sort.array('lexical', 'name', 'asc'))),
    [brands, user]
  );

  const fields = useMemo(
    () => [
      {
        name: 'emailReceivedDate',
        type: 'datepicker',
        value: utils.date.today('yyyy-MM-DD'),
        validation: Yup.date()
          .nullable(utils.string.t(`claimsTaskManagement.form.errors.emailReceivedDateInvalid`))
          .transform((curr, orig) => (orig === '' ? null : curr))
          .required(utils.string.t(`risks.effectiveToInvalid`)),
        label: utils.string.t('claimsTaskManagement.form.emailReceivedDate'),
        muiComponentProps: {
          autoOk: true,
          placeholder: utils.date.today('DD-MM-yyyy'),
          format: 'DD-MM-yyyy',
          fullWidth: true,
          autoComplete: 'off',
          disableFuture: true,
        },
      },
      {
        name: 'emailRecipient',
        type: 'autocompletemui',
        value: { email: user?.emailId, name: user?.fullName },
        label: utils.string.t('claimsTaskManagement.form.emailRecipient'),
        options: usersOptions || [],
        validation: Yup.object().required(utils.string.t(`claimsTaskManagement.form.errors.emailRecipientInvalid`)),
        optionKey: 'email',
        optionLabel: 'name',
      },
      {
        name: 'primaryHandler',
        type: 'autocompletemui',
        value: { email: user?.emailId, name: user?.fullName },
        label: utils.string.t('claimsTaskManagement.form.primaryHandler'),
        options: usersOptions || [],
        optionKey: 'email',
        optionLabel: 'name',
        validation: Yup.object()
          .nullable('claimsTaskManagement.form.errors.primaryHandler')
          .required(utils.string.t(`claimsTaskManagement.form.errors.primaryHandlerRequired`)),
      },
      {
        name: 'secondaryHandler',
        type: 'autocompletemui',
        label: utils.string.t('claimsTaskManagement.form.secondaryHandler'),
        options: usersOptions || [],
        optionKey: 'email',
        optionLabel: 'name',
        validation: Yup.object()
          .nullable('claimsTaskManagement.form.errors.secondaryHandler')
          .when('reminderRecipients', (recipients, schema) =>
            recipients.some((rec) => rec.value === 'secondaryHandler')
              ? Yup.object().nullable().required(utils.string.t(`claimsTaskManagement.form.errors.secondaryHandlerRequired`))
              : schema
          ),
      },
      {
        name: 'division',
        type: 'autocompletemui',
        label: utils.string.t('claimsTaskManagement.form.division'),
        options: departmentsOptionsFormatted || [],
        updateValueFromOptions: true,
        optionKey: 'value',
        optionLabel: 'name',
        groupBy: 'groupBy',
        validation: Yup.object().nullable().required(utils.string.t(`claimsTaskManagement.form.errors.taskDivisionInvalid`)),
      },
      {
        name: 'taskDetail',
        type: 'autocompletemui',
        label: utils.string.t('claimsTaskManagement.form.taskDetail'),
        options: taskDetailOptions || [],
        updateValueFromOptions: true,
        validation: Yup.object().nullable().required(utils.string.t(`claimsTaskManagement.form.errors.taskDetailInvalid`)),
      },
      {
        name: 'status',
        type: 'select',
        label: utils.string.t('claimsTaskManagement.form.taskStatus'),
        options: status || [],
        defaultValue: CLAIMS_TASKS_STATUS_PENDING,
      },
      {
        name: 'completedDate',
        type: 'datepicker',
        value: '',
        validate: false,
        label: utils.string.t('claimsTaskManagement.form.completedDate'),
        validation: Yup.date()
          .nullable()
          .typeError(utils.string.t(`claimsTaskManagement.form.errors.completedDate`))
          .transform((curr, orig) => (orig === '' ? null : curr))
          .when('status', {
            is: CLAIMS_TASKS_STATUS_COMPLETED,
            then: Yup.date().nullable().required(utils.string.t(`claimsTaskManagement.form.errors.completedDateRequired`)),
          }),
        muiComponentProps: {
          autoOk: true,
          format: 'DD-MM-yyyy',
          fullWidth: true,
          autoComplete: 'off',
        },
      },
      {
        name: 'sentToUnderwriterDate',
        type: 'datepicker',
        value: '',
        validate: false,
        label: utils.string.t('claimsTaskManagement.form.sentToUWRDate'),
        validation: Yup.date()
          .nullable()
          .typeError(utils.string.t(`claimsTaskManagement.form.errors.sentToUWRDate`))
          .transform((curr, orig) => (orig === '' ? null : curr))
          .notRequired(),
        muiComponentProps: {
          autoOk: true,
          format: 'DD-MM-yyyy',
          fullWidth: true,
          autoComplete: 'off',
        },
      },
      {
        name: 'description',
        type: 'textarea',
        label: utils.string.t('claimsTaskManagement.form.description'),
        muiComponentProps: {
          multiline: true,
          minRows: 3,
          variant: 'outlined',
          fullWidth: true,
        },
      },
      {
        name: 'ucr',
        type: 'text',
        label: utils.string.t('claimsTaskManagement.form.UCR'),
        muiComponentProps: {
          variant: 'outlined',
          fullWidth: true,
        },
      },
      {
        name: 'umr',
        type: 'text',
        label: utils.string.t('claimsTaskManagement.form.UMR'),
        muiComponentProps: {
          variant: 'outlined',
          fullWidth: true,
        },
      },
      {
        name: 'insured',
        type: 'text',
        label: utils.string.t('claimsTaskManagement.form.insured'),
        muiComponentProps: {
          variant: 'outlined',
          fullWidth: true,
        },
      },
      {
        name: 'denial',
        type: 'switch',
        value: false,
        title: utils.string.t('claimsTaskManagement.form.denial'),
        muiComponentProps: {
          labelPlacement: 'start',
        },
      },
      {
        name: 'reminderDate',
        type: 'datepicker',
        value: '',
        label: utils.string.t('claimsTaskManagement.form.reminderDate'),
        validate: false,
        isReminderField: true,
        updateValueFromObject: true,
        validation: Yup.date()
          .typeError(utils.string.t('claimsTaskManagement.form.errors.reminderDateInvalid'))
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .min(utils.date.tomorrow().format('YYYY-MM-DD'), utils.string.t('claimsTaskManagement.form.errors.reminderDateMin'))
          .notRequired(),
        muiComponentProps: {
          autoOk: true,
          format: 'DD-MM-yyyy',
          fullWidth: true,
          autoComplete: 'off',
          minDate: utils.date.tomorrow(),
        },
      },
      {
        name: 'reminderRecipients',
        type: 'autocompletemui',
        value: [],
        isReminderField: true,
        updateValueFromObject: true,
        label: utils.string.t('claimsTaskManagement.form.reminderRecipient'),
        options: REMINDER_RECIPIENTS || [],
        optionKey: 'value',
        optionLabel: 'label',
        muiComponentProps: {
          multiple: true,
        },
      },
      {
        name: 'notes',
        type: 'textarea',
        label: utils.string.t('claimsTaskManagement.form.notes'),
        isReminderField: true,
        updateValueFromObject: true,
        muiComponentProps: {
          multiline: true,
          minRows: 3,
          variant: 'outlined',
          fullWidth: true,
        },
      },
    ],
    [user?.emailId, user?.fullName, usersOptions, departmentsOptionsFormatted, taskDetailOptions]
  );

  const getRecipientMapping = (values) => {
    if (!values.reminder?.recipients) return REMINDER_RECIPIENTS.slice(0, 1);
    const recipients = [];
    values.reminder?.recipients?.forEach((rec) => {
      if (rec.email === values.primaryHandler.email) recipients.push(REMINDER_RECIPIENTS[0]);
      if (rec.email === values.secondaryHandler?.email) recipients.push(REMINDER_RECIPIENTS[1]);
    });
    return recipients;
  };

  const updateValues = useCallback(
    (values, formFields) =>
      formFields.reduce((acc, field) => {
        if (field.updateValueFromOptions) {
          const option = field.options.find((fOption) => fOption.value === values[field.name]);
          acc[field.name] = option;
        } else if (field.isReminderField) {
          acc.reminderDate = values.reminder?.date;
          acc.notes = values.reminder?.notes;
          acc.reminderRecipients = getRecipientMapping(values);
        } else {
          acc[field.name] = values?.[field.name];
        }
        return acc;
      }, {}),
    []
  );

  const defaultValues = useMemo(
    () => (data ? updateValues(data, fields) : utils.form.getInitialValues(fields)),
    [data, fields, updateValues]
  );

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />
      </div>
    );
  }
  return <AddEditClaimsTaskForm fields={fields} defaultValues={defaultValues} handleClose={handleClose} taskId={data?.id} />;
};
