import groupBy from 'lodash/groupBy';
import { firstBy } from 'thenby';

import { Box, Typography } from '@material-ui/core';

// Edge
import { Empty } from 'components';
import { useGetUnSubmittedData, useGetCoverholderFacilityFilters } from 'lib/binderManagement';
import { UnSubmittedBdxData } from './UnSubmittedBdxData';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import * as utils from 'utils';

export const UnSubmittedBdxFiles = ({ bdxType, coverHolderId }) => {
  const { data, isLoading, isError } = useGetUnSubmittedData({
    bdxType,
    coverHolderId,
    options: {
      enabled: !!bdxType && !!coverHolderId,
    },
  });

  const { data: facilityFilters } = useGetCoverholderFacilityFilters({
    coverHolderId: coverHolderId,
  });
  if (isLoading) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  if (isError || !data?.length) {
    return <Empty width={340} title={utils.string.t('binders.facilities.noUploads')} icon={<SearchResultImage />} padding />;
  }

  const submittedBdxData = data?.filter((item) => item.submitted);
  const unSubmittedData = data?.filter((item) => !item.submitted);

  const orderUnmappedBdxFiles = groupBy(
    unSubmittedData?.sort(firstBy(utils.sort.array('lexical', 'submittedFor', 'desc'))),
    'submittedFor'
  );

  const orderSubmittedBdxFiles = groupBy(
    submittedBdxData?.sort(firstBy(utils.sort.array('lexical', 'submittedFor', 'desc'))),
    'submittedFor'
  );

  return (
    <>
      {Object.keys(orderUnmappedBdxFiles)?.length > 0 ? (
        <Box mt={2}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {utils.string.t('binders.coverholders.bulkUploads.unSubmittedBdxFiles')}
          </Typography>
          {Object.keys(orderUnmappedBdxFiles).map((key) => (
            <Box key={key}>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {key}
              </Typography>
              {orderUnmappedBdxFiles[key]?.map((unSubmittedItem) => (
                <Box key={unSubmittedItem.requestId} mb={3}>
                  <UnSubmittedBdxData
                    bdxFile={unSubmittedItem}
                    umrs={facilityFilters?.umrs || []}
                    facilities={unSubmittedItem?.facilities || []}
                    bdxType={bdxType}
                    coverHolderId={coverHolderId}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : null}
      {Object.keys(orderSubmittedBdxFiles)?.length > 0 ? (
        <Box mt={2}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {utils.string.t('binders.coverholders.bulkUploads.submittedBdxFiles')}
          </Typography>

          {Object.keys(orderSubmittedBdxFiles).map((key) => (
            <Box key={key}>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {key}
              </Typography>
              {orderSubmittedBdxFiles[key]?.map((unSubmittedItem) => (
                <Box key={unSubmittedItem.requestId} mb={3}>
                  <UnSubmittedBdxData
                    bdxFile={unSubmittedItem}
                    umrs={facilityFilters?.umrs || []}
                    facilities={unSubmittedItem?.facilities || []}
                    bdxType={bdxType}
                    coverHolderId={coverHolderId}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  );
};
