import { Link } from 'react-router-dom';
import classnames from 'classnames';
import get from 'lodash/get';

// app
import styles from './QuoteBindList.styles';
import { RiskCard, RiskCardLoadingSkeleton } from 'modules';
import { Empty, Overflow, Pagination, Status, Skeleton, TableCell, TableHead, Translate } from 'components';
import * as utils from 'utils';
import config from 'config';
import { RISK_DISPLAY_CARD } from 'consts';

// mui
import { useTheme } from '@material-ui/core/styles';
import { Grid, Table, TableRow, TableBody, makeStyles } from '@material-ui/core';
import { ReactComponent as SearchResultImage } from '../../assets/svg/search-result.svg';

export const NoResult = ({ width = 400 }) => (
  <Empty width={width} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />
);

const QuoteBindList = ({ displayStyle, cols, riskListLoading, risks, riskProducts, pagination, handlers }) => {
  const classes = makeStyles(styles, { name: 'Products' })();
  const theme = useTheme();
  const noResult = !risks?.length && !riskListLoading;

  return (
    <>
      {displayStyle === RISK_DISPLAY_CARD ? (
        <Grid container spacing={2} style={{ marginTop: 20 }} data-testid="risk-list-card">
          {riskListLoading ? (
            <RiskCardLoadingSkeleton numberOfCards={pagination.rowsPerPage} />
          ) : (
            <>
              {noResult ? (
                <NoResult />
              ) : (
                <>
                  {risks.map((risk) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={risk.id} data-testid={`risk-card-${risk.id}`}>
                      <Link to={`${config.routes.quoteBind.riskDetails}/${risk.id}`} className={classes.riskLink}>
                        <RiskCard risk={risk} loading={riskListLoading} riskProducts={riskProducts} />
                      </Link>
                    </Grid>
                  ))}
                </>
              )}
            </>
          )}
        </Grid>
      ) : (
        <>
          <Overflow>
            <Table data-testid="risk-list-table">
              <TableHead columns={cols} />
              {riskListLoading ? (
                <TableBody data-testid="risk-skeleton">
                  <TableRow>
                    <TableCell colSpan={cols.length}>
                      <Skeleton height={40} animation="wave" displayNumber={10} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {noResult ? (
                    <TableBody data-testid="risk-list">
                      <TableRow>
                        <TableCell colSpan={cols.length}>
                          <NoResult />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody data-testid="risk-list">
                      {risks.map((risk) => {
                        const isNew = risk.__new__;
                        const status = risk.riskStatus && risk.riskStatus.toLowerCase();

                        const classesRow = {
                          [classes.row]: true,
                          [classes.rowNew]: isNew,
                        };
                        const statusBackgroundColor = theme.palette.status[status?.toLowerCase()]
                          ? theme.palette.status[status.toLowerCase()]
                          : theme.palette.status.default;

                        return (
                          <TableRow
                            key={risk.id}
                            hover
                            className={classnames(classesRow)}
                            component={Link}
                            to={`${config.routes.quoteBind.riskDetails}/${risk.id}`}
                            data-testid={`risk-row-${risk.id}`}
                          >
                            <TableCell data-testid={`risk-cell-product-${risk.id}`}>
                              {utils.risk.getRiskName(risk.riskType, riskProducts)}
                            </TableCell>
                            <TableCell data-testid={`risk-cell-client-${risk.id}`}>{get(risk, 'client.name')}</TableCell>
                            <TableCell data-testid={`risk-cell-insured-${risk.id}`}>{get(risk, 'insured.name')}</TableCell>
                            <TableCell nowrap data-testid={`risk-cell-inceptionDate-${risk.id}`}>
                              <Translate
                                label="format.date"
                                options={{ value: { date: risk.inceptionDate, format: config.ui.format.date.text, default: '-' } }}
                              />
                            </TableCell>
                            <TableCell nowrap data-testid={`risk-cell-expiryDate-${risk.id}`}>
                              <Translate
                                label="format.date"
                                options={{ value: { date: risk.expiryDate, format: config.ui.format.date.text, default: '-' } }}
                              />
                            </TableCell>
                            <TableCell nowrap data-testid={`risk-cell-created-by-${risk.id}`}>
                              {risk.createdBy}
                            </TableCell>
                            <TableCell data-testid={`risk-cell-status-${risk.id}`}>
                              {status && (
                                <Status
                                  size="xs"
                                  text={<Translate label={`QBstatus.${status}`} />}
                                  status={status}
                                  style={{
                                    backgroundColor: statusBackgroundColor,
                                    color: utils.color.contrast(statusBackgroundColor, 0.6),
                                    fontSize: '11px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    width: '100%',
                                    height: 'auto',
                                  }}
                                  statusOverrides={{
                                    quoted: 'info',
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </Overflow>
        </>
      )}
      {!noResult ? (
        <Grid container data-testid="pagination">
          <Grid item xs={12} sm={12}>
            <Pagination
              page={pagination.page}
              count={pagination.rowsTotal}
              rowsPerPage={pagination.rowsPerPage}
              rowsPerPageOptions={config.ui.gridPagination.options}
              onChangePage={handlers.changePage}
              onChangeRowsPerPage={handlers.changeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default QuoteBindList;
