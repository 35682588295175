import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// app
import { UploadFacilityDocumentView } from './UploadFacilityDocument.view';
import { hideModal } from 'stores';
import * as utils from 'utils';
import { useGetDocumentsFolders, useUploadFacilityDocument } from 'lib/binderManagement';
import { CircularProgress, makeStyles } from '@material-ui/core';

import styles from './UploadFacilityDocument.style';

export default function UploadFacilityDocument({ umr }) {
  const dispatch = useDispatch();
  const { data: folders, isLoading } = useGetDocumentsFolders({ umr });
  const { mutate: uploadFacilityDocument, isLoading: isSubmitting } = useUploadFacilityDocument({ umr });
  const [clearOnBlur, setClearOnBlur] = useState(false);
  const classes = makeStyles(styles)();

  const fileField = {
    type: 'file',
    name: 'file',
    label: utils.string.t('app.file'),
    onlyOneFile: true,
    validation: Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
  };

  const folderNames = folders?.folderList.map(({ folderName }) => folderName) || [];

  const folderField = {
    type: 'autocompletemui',
    name: 'folder',
    label: utils.string.t('app.folder'),
    value: null,
    optionKey: 'id',
    optionLabel: 'label',
    optionsCreatable: true,
    clearOnBlur: clearOnBlur,
    validation: Yup.object().nullable().required(utils.string.t('form.folder.required')),
    hint: utils.string.t('app.folderHintText'),
    options: folderNames.map((folder) => ({ value: folder, label: folder, id: folder })),
    muiComponentProps: {
      filterOptions: (options, params) => {
        const filtered = createFilterOptions()(options, params);

        if (params.inputValue !== '') {
          const exists = options?.some(({ label }) => label.toLowerCase() === params.inputValue.toLowerCase().trim());
          if (!exists) {
            filtered.push({
              id: params.inputValue,
              value: params.inputValue,
              label: `${utils.string.t('app.add')} "${params.inputValue}"`,
            });
          }
        }

        return filtered;
      },
      onOpen: () => setClearOnBlur(false),
    },
  };

  const fields = [fileField, folderField];

  const actions = [
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: () => dispatch(hideModal()),
    },
    {
      name: 'submit',
      label: utils.string.t('app.submit'),
      handler: (data) => {
        uploadFacilityDocument({ name: data.folder.value, file: data.file[0] });
      },
    },
  ];

  return isLoading || isSubmitting ? (
    <div className={classes.loader}>
      <CircularProgress size={32} />
    </div>
  ) : (
    <UploadFacilityDocumentView fields={fields} actions={actions} />
  );
}
