import { ChangeEvent, FC, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { groupBy } from 'lodash';
import { firstBy } from 'thenby';

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Avatar, Tooltip } from 'components';
import VirtualList from 'components/TransferList/VirtualList';
import { ConditionsPreview } from 'modules/BinderLimits/components/BinderLimitPreview/ConditionsPreview';
import { SumColumnsPreview } from 'modules/BinderLimits/components/BinderLimitPreview/SumColumnsPreview';

import { useDebounce } from 'hooks';
import * as utils from 'utils';

import { IBinderLimitsCustomListProps, IItemProps } from './ImportBinderLimits.types';

import { useBinderLimitPreviewStyles, useBinderLimitsStyles } from './ImportBinderLimits.styles';

const BinderLimitPreview: FC<{ binderLimit: IImportLimitItem }> = ({ binderLimit }) => {
  const classes = useBinderLimitPreviewStyles();

  return (
    <div className={classes.root}>
      <ConditionsPreview conditionType={binderLimit?.conditionType} conditions={binderLimit?.conditions} />
      <SumColumnsPreview sumColumns={binderLimit?.sumColumns} />
    </div>
  );
};

const Item: FC<IItemProps> = (props) => {
  const { itemIndex, sectionIndex, isSectionHeader, style, sectionIds, groupedItems, checked, testid, handleToggle, classes } = props;
  const groupName = sectionIds[sectionIndex];
  const item = groupedItems[groupName][itemIndex];

  if (isSectionHeader) {
    return (
      <ListSubheader>
        <Typography variant="subtitle1" style={{ padding: '14px', fontSize: '16px', fontWeight: 600 }}>
          {groupName}
        </Typography>
      </ListSubheader>
    );
  }

  const labelId = `transfer-list-all-item-${item.id}-label`;
  const isChecked = checked.indexOf(item) !== -1;
  const initials =
    utils.user.initials({
      fullName: item.userName,
    }) || '-';

  const primary = (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <Typography variant="body2" style={{ fontWeight: 600, fontSize: '14px', lineHeight: 1 }}>
        {item.name}
      </Typography>
    </div>
  );

  const secondary = item.lastUpdated ? (
    <div>
      <Typography variant="body1">
        {utils.date.formatDateWithParameter(item.lastUpdated, 'MMM Do, YYYY')},{' '}
        {utils.date.formatDateWithParameter(item.lastUpdated, 'HH:mm')}
      </Typography>
    </div>
  ) : null;

  return (
    <ListItem
      key={item.id}
      role="listitem"
      button
      onClick={item?.disabled ? undefined : () => handleToggle(item)}
      style={style}
      data-testid={`list-item-${testid}`}
    >
      <ListItemIcon>
        <Checkbox
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          color="primary"
          disabled={item?.disabled}
        />
      </ListItemIcon>
      <ListItemAvatar
        style={{
          marginRight: '16px',
          minWidth: '24px',
        }}
      >
        <Tooltip title={item.userName} placement="top">
          <Avatar text={initials} size={32} />
        </Tooltip>
      </ListItemAvatar>
      <ListItemText id={labelId} primary={primary} secondary={secondary} />
      <Tooltip
        title={<BinderLimitPreview binderLimit={item} />}
        placement="top"
        rich
        nestedClasses={{
          tooltip: classes.tooltip,
        }}
      >
        <VisibilityIcon data-testid="binderLimit-preview" />
      </Tooltip>
    </ListItem>
  );
};

export const BinderLimitsCustomList: FC<IBinderLimitsCustomListProps> = (props) => {
  const { title, items, handleToggle, checked, testid } = props;

  const classes = useBinderLimitsStyles();
  const [listItems, setListItems] = useState(items.sort(firstBy(utils.sort.array('lexical', 'groupBy', 'asc')).thenBy('name')));
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchReset = () => {
    setSearch('');
  };

  useEffect(() => {
    setListItems((prevItems) => prevItems.sort(firstBy(utils.sort.array('lexical', 'groupBy', 'desc')).thenBy('name')));
  }, [items]);

  useEffect(() => {
    if (debouncedSearchTerm === '') {
      setListItems(items);
    } else {
      setListItems((prevItems) =>
        prevItems.filter(
          (item) =>
            item?.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.coverHolder?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.facilityReference?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.user?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        )
      );
    }
  }, [debouncedSearchTerm, items]);

  const groupedItems = groupBy(listItems, 'groupBy');
  const sectionIds = Object.keys(groupedItems);
  const sectionCounts = sectionIds.map((id) => groupedItems[id].length);

  return (
    <Card data-testid={`transfer-list-card-${testid}`}>
      <CardHeader className={classes.cardHeader} title={title} />
      <Box className={classes.searchBox}>
        <TextField
          fullWidth
          value={search}
          onChange={handleSearchChange}
          variant="outlined"
          placeholder="Search"
          InputProps={{
            endAdornment:
              search !== '' ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchReset} data-testid="reset-search">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
          }}
        />
      </Box>
      <Divider />
      <Box className={classes.list}>
        <AutoSizer>
          {({ width, height }) => (
            <VirtualList
              height={height}
              width={width}
              itemSize={64}
              sectionCounts={sectionCounts}
              component={List}
              handleToggle={handleToggle}
            >
              {({ itemIndex, sectionIndex, isSectionHeader, style }) => (
                <Item
                  itemIndex={itemIndex}
                  sectionIndex={sectionIndex}
                  isSectionHeader={isSectionHeader}
                  style={style}
                  sectionIds={sectionIds}
                  checked={checked}
                  classes={classes}
                  testid={testid}
                  groupedItems={groupedItems}
                  handleToggle={handleToggle}
                />
              )}
            </VirtualList>
          )}
        </AutoSizer>
      </Box>
    </Card>
  );
};
