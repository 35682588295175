import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './RulePreview.styles';
import { RuleLine } from '../RuleLine';

export const RulePreviewSkeleton = () => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();

  return (
    <div className={classes.rule} data-testid="RulePreviewSkeleton">
      <div className={classes.previewContainer}>
        <Skeleton height={25} />
        <Grid container className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
          </Grid>
          <Grid item className={classes.operationSkeleton}>
            <Skeleton height={20} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.previewContainer}>
        <Skeleton height={25} />
        <Grid container className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
          </Grid>
          <Grid item className={classes.operationSkeleton}>
            <Skeleton height={20} />
          </Grid>
        </Grid>
        <div className={classes.ruleControlWrapper}>
          <Skeleton height={30} width={100} className={classes.ruleControl} />
          <Skeleton height={30} width={100} className={classes.ruleControl} />
        </div>
      </div>
    </div>
  );
};
