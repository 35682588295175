import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import * as utils from 'utils';
import config from 'config';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { multipartAdminAxios } from 'lib/admin';
import { enqueueNotification, hideModal } from 'stores';
import { OPERATIONAL_MARKETS } from 'lib/admin/queryKeys';

export const useCreateOperationalMarket = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const handleSettled = (data) => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useCreateOperationalClient',
      message: 'API fetch error (admin.post)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.operationalMarketFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_MARKETS);

    history.push(`${config.routes.admin.operationalMarkets}/${data?.id}`);

    dispatch(
      enqueueNotification('notification.admin.operationalMarketSuccess', 'success', {
        data: { clientName: data?.name },
      })
    );
  };

  return useMutation(
    async (market) => {
      let form = new FormData();

      form.append('name', market.name);
      if (!isEmpty(market?.logo)) {
        market.logo.forEach((file) => form.append('logo', file));
      }

      if (!utils.generic.isValidObject(market)) {
        throw new Error('Invalid operational market request body');
      }

      return await multipartAdminAxios.post('marketParent', form);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: handleSettled,
    }
  );
};
