const styles = (theme) => ({
  actions: {
    margin: theme.spacing(2, 0),
  },
  actionsStatement: {
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },

  action: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  ruleLineContainer: {
    paddingRight: `0px!important`,
    paddingLeft: `${theme.spacing(3)}px!important`,

    '& + *': {
      paddingLeft: `0px!important`,
    },
  },
  actionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  actionLabelText: {
    transition: 'all 0.3s ease',
    opacity: '0.3',
  },
  actionLabelTextActive: {
    opacity: '1',
  },

  operandLabelWrapper: {
    '& label': {
      marginTop: 5,
      marginBottom: 12,
    },
  },

  ruleset: {
    alignItems: 'center',
  },
  rulesetTitleWrapper: {
    display: 'flex',
    flex: 1,
  },
  rulesetTitle: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    margin: 0,
  },

  deleteAction: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
