import { Grid, makeStyles, Typography } from '@material-ui/core';

import * as utils from 'utils';

import { RuleLine } from '../RuleLine';

import { parseDatetimeValue } from './RulePreview.utils';

import styles from './RulePreview.styles';

export const ActionsPreview = ({ actions }) => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();

  return (
    <Grid container className={classes.previewContainer}>
      <Grid item className={classes.previewHeader}>
        <Typography variant="subtitle1">{utils.string.t('rules.actionStatement')}</Typography>
      </Grid>
      {actions?.map((action, index) => (
        <ActionPreview key={index} action={action} />
      ))}
    </Grid>
  );
};

const ActionPreview = ({ action }) => {
  const classes = makeStyles(styles, { name: 'RulePreview' })();

  return (
    <Grid container className={classes.operation}>
      <Grid item className={classes.ruleLineContainer}>
        <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.operationDescription}>
          {action.field && <span className={classes.operationText}>{action.field}</span>}
          <span className={classes.operationTextBold}>{action.operation}</span>

          {action.value1 || action?.displayValue1 ? (
            <span className={classes.operationText}>
              {parseDatetimeValue(action?.displayValue1 ? action?.displayValue1 : action.value1)}
            </span>
          ) : null}

          {action.value2 || action?.displayValue2 ? (
            <span className={classes.operationText}>
              {parseDatetimeValue(action?.displayValue2 ? action?.displayValue2 : action.value2)}
            </span>
          ) : null}

          {action.column1 && <span className={classes.operationText}>{action.column1}</span>}
          {action.column2 && <span className={classes.operationText}>{`${utils.string.t('rules.and')} ${action.column2}`}</span>}
          {action.delimiter && <span className={classes.operationText}>{`${utils.string.t('rules.with')} "${action.delimiter}"`}</span>}
          {action.values && <span className={classes.operationText}>{action.values.join(', ')}</span>}

          {action.decimals ? (
            <>
              <span className={classes.operationText}>{`${utils.string.t('rules.with')} ${action.decimals}`}</span>
              <span className={classes.operationTextBold}>{`${utils.string.t('rules.decimals').toLowerCase()}`}</span>
            </>
          ) : null}

          {action.roundingMode ? (
            <>
              <span className={classes.operationText}>{`${utils.string.t('rules.and')}`}</span>
              <span className={classes.operationText}>{`${utils.string.t('rules.roundingMode').toLowerCase()}`}</span>
              <span className={classes.operationTextBold}>{`${action.roundingMode.replace(/_/g, ' ').toLowerCase()}`}</span>
            </>
          ) : null}
        </Typography>
      </Grid>
    </Grid>
  );
};
