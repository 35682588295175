import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_CONFIG } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseUpdateFacilityConfigProps {
  umr: string;
  bdxType: string;
}

export const useUpdateFacilityConfig = ({ umr, bdxType }: IUseUpdateFacilityConfigProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useUpdateFacilityConfig',
    warning: 'notification.binderManagement.configUpdateFail',
  });

  const handleSuccess = (data: IFacilityConfig) => {
    queryClient.setQueryData([FACILITY_BINDER_CONFIG, umr, bdxType], data);

    dispatch(enqueueNotification('notification.binderManagement.configUpdate', 'success'));
  };

  return useMutation<IFacilityConfig, AxiosError, IFacilityConfigDTO>(
    async (config) => {
      const { data } = await binderManagementFacilitiesAxios.put(`facility/${umr}/${bdxType}/config`, config);

      return data;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
