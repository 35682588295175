import React from 'react';

import { useUploadBDXContentClasses } from './UploadBDXContent.styles';

export const UploadBDXContent = ({ children, actions }) => {
  const classes = useUploadBDXContentClasses();

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
      {actions}
    </div>
  );
};
