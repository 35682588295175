import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_DETAILS, QB_RISK_QUOTES } from 'lib/quoteBind/queryKeys';

// risks
const acceptQuote = async (params) => {
  try {
    const { id } = params;

    if (!id) {
      throw new Error('useAcceptQuote: id is required');
    }

    const result = await quoteBindAxios.put(`/quotes/${id}/accept`);
    return result?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useAcceptQuote = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useAcceptQuote',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));
    dispatch(hideModal());

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, { id: res.riskId });
    queryClient.invalidateQueries(QB_RISK_QUOTES, res.riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.acceptRiskQuote.success', 'success'));
  };

  return useMutation({
    mutationFn: acceptQuote,
    onSuccess: (res) => handleSuccess(res),
    onError: (err) => handleError(err),
    keepPreviousData: true,
  });
};
