const styles = (theme) => ({
  card: {
    overflow: 'hidden',
    color: 'white',
    borderRadius: 5,
    boxShadow: theme.shadows[2],
    position: 'relative',
    height: '100%',
    padding: 0,
    minWidth: 'fit-content',
    cursor: 'pointer',
  },
  cardDraft: {
    overflow: 'hidden',
    color: 'white',
    borderRadius: 5,
    boxShadow: theme.shadows[2],
    position: 'relative',
    height: '100%',
    padding: 0,
    minWidth: 'fit-content',
  },
  cardArray: {
    padding: 10,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  cardTitle: {
    width: '100%',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '1.5em',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 14,
    margin: 0,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  cardContent: {
    paddingTop: '16px!important',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    minHeight: '100px',
    paddingBottom: '28px!important',
  },
  infoLeft: {
    color: 'black!important',
    width: 60,
    fontWeight: 400,
    fontSize: 13,
  },
  infoRight: {
    color: 'black!important',
    fontWeight: 400,
    marginLeft: 10,
    marginTop: 0,
    fontSize: 13,
  },
  productName: {
    textAlign: 'left',
    color: 'black!important',
    fontWeight: 600,
    fontSize: 13,
    marginBottom: 0,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  cardActions: {
    padding: '16px!important',
  },
  createdBy: {
    color: 'black!important',
    fontWeight: 400,
    fontSize: 13,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    color: 'black!important',
  },
  editIcon: {
    color: 'white',
    fontSize: theme.typography.pxToRem(18),
  },
  cellDataLabel: {
    width: '60%',
  },
  arrayCell: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  arrayTable: {
    '& td': {
      padding: 4,
      fontSize: theme.typography.pxToRem(11),
      borderBottomStyle: 'dotted',
      borderBottomColor: theme.palette.neutral.lighter,
      '&:first-child': {
        paddingLeft: 0,
        paddingRight: 12,
      },
      '&:last-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    '& tr:last-child td': {
      borderBottom: 0,
    },
  },
  policyBox: {},
  cardPolicyTitle: {
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
  },
  cardFacility: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 0,
  },
  quoteValue: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 600,
  },
  issues: {
    fontSize: '1.2rem',
    color: theme.palette.error.main,
  },
  btnMarginLeft: {
    marginLeft: 10,
  },
});

export default styles;
