import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { adminAxios } from '../../admin.api';
import { THIRD_PARTY_OFFICE_LIST, OPERATIONAL_THIRD_PARTY } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';

export const useCreateThirdPartyOffice = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const handleSettled = (data) => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useCreateThirdPartyOffice',
      message: 'API fetch error (admin.post)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.officePostFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_THIRD_PARTY);
    queryClient.invalidateQueries(THIRD_PARTY_OFFICE_LIST);
    dispatch(
      enqueueNotification('notification.admin.officePostSuccess', 'success', {
        data: { officeName: data?.name, clientName: data?.parent && data?.parent.name },
      })
    );
  };

  return useMutation(
    async (data) => {
      const { parentId } = data;
      return await adminAxios.post(`v1/operational-third-party/${parentId}/office`, data);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: (data) => handleSettled(data),
    }
  );
};
