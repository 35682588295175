import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { RISK_ENDORSEMENT_FIELDS } from 'consts/quote-bind';
import {
  INSUREDS,
  LARGE_SIZE,
  QB_CLIENTS,
  QB_RISK_DEFINITION,
  REINSUREDS,
  useGetClients,
  useGetInsureds,
  useGetReInsureds,
  useGetRiskDefinitions,
  usePatchEndorsement,
  usePatchRisk,
  usePostDraftRisk,
  usePostEndorsement,
  usePostRisk,
} from 'lib/quoteBind';
// app
import { selectRiskProduct } from 'stores';
import * as utils from 'utils';

import MultiStepForm from './MultiStepForm';
import { MultiStepLoading } from './MultiStepLoading';

const AddEditQuoteBind = ({
  fullScreen,
  product,
  riskData,
  draftId,
  riskId,
  effectiveDate = null,
  duplicate = false,
  addEndorsement = false,
  endorsementId,
}) => {
  const dispatch = useDispatch();
  const type = product?.value;

  const { data: riskDefinitionData, isLoading: riskDefinitionsLoading } = useGetRiskDefinitions(QB_RISK_DEFINITION, type);
  const { data: clientsContent, isLoading: clientsLoading } = useGetClients(QB_CLIENTS, { size: LARGE_SIZE, productCode: type });
  const { data: insuredsContent, isLoading: insuredsLoading } = useGetInsureds(INSUREDS, { size: LARGE_SIZE, productCode: type });
  const { data: reinsuredsContent, isLoading: reinsuredsLoading } = useGetReInsureds(REINSUREDS, { size: LARGE_SIZE, productCode: type });
  const { mutateAsync: postRisk } = usePostRisk();
  const { mutateAsync: patchRiskData } = usePatchRisk();
  const { mutateAsync: postRiskDraft } = usePostDraftRisk();
  const { mutateAsync: postEndorsement } = usePostEndorsement();
  const { mutateAsync: patchEndorsement } = usePatchEndorsement();

  const clients = clientsContent?.content || [];
  const insureds = insuredsContent?.content || [];
  const reinsureds = reinsuredsContent?.content || [];

  const [isLoading, setIsLoading] = useState(true);

  const isReQuote = Boolean(riskId) && !duplicate;
  const isEndorsement = Boolean(riskId) && addEndorsement;

  const riskDefinitionsFieldsByType = isEndorsement
    ? utils.risk.disableInceptionExpiry(riskDefinitionData?.data?.product)
    : riskDefinitionData?.data?.product || [];
  const definitionsFields = riskDefinitionsFieldsByType.filter((definition) => definition.type !== 'LABEL');
  const riskFieldOptionsByType = riskDefinitionData?.data?.fieldOptions || [];
  const countries = riskFieldOptionsByType?.countryOfOrigin ? riskFieldOptionsByType.countryOfOrigin : [];

  useEffect(() => {
    let isSubscribed = true;

    const isLoadingValue =
      [insuredsLoading, clientsLoading, reinsuredsLoading, riskDefinitionsLoading].filter((loading) => loading === false).length !== 4;

    isSubscribed && setIsLoading(isLoadingValue);

    return () => (isSubscribed = false);
  }, [insuredsLoading, clientsLoading, reinsuredsLoading, riskDefinitionsLoading]);

  useEffect(
    () => {
      dispatch(selectRiskProduct(type));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const riskDefinitionsFields = isEndorsement ? [...riskDefinitionsFieldsByType, ...RISK_ENDORSEMENT_FIELDS] : riskDefinitionsFieldsByType;

  const fields = utils.risk.parseFields(riskDefinitionsFields, {
    insureds,
    reinsureds,
    clients,
    countryOfOrigin: countries,
    ...riskFieldOptionsByType,
  });

  const handlePostRisk = (values) => {
    if (isEndorsement) {
      return endorsementId
        ? patchEndorsement({ definitions: fields, values, riskId, riskData, effectiveDate, endorsementId })
        : postEndorsement({ definitions: fields, values, riskId, riskData, effectiveDate });
    }

    return isReQuote
      ? patchRiskData({ values, type, definitions: fields, riskId, riskData })
      : postRisk({ values, type, definitions: fields, draftId });
  };

  const handlePostDraftRisk = (values) => {
    draftId ? postRiskDraft({ values, type, definitions: fields, draftId }) : postRiskDraft({ values, type, definitions: fields });
  };

  const defaultValues = riskData ? utils.form.getFormattedValues(riskData, fields) : utils.form.getInitialValues(fields);

  if (isLoading || isEmpty(defaultValues)) {
    return <MultiStepLoading />;
  }

  return (
    <MultiStepForm
      isReQuote={isReQuote}
      isEndorsement={isEndorsement}
      fullScreen={fullScreen}
      productType={type}
      fields={fields}
      defaultValues={defaultValues}
      riskDataValues={riskData}
      definitionsFields={definitionsFields}
      hasCountryOfOrigin={!!countries.length}
      handleSubmit={handlePostRisk}
      handleDraftSave={handlePostDraftRisk}
    />
  );
};

export default AddEditQuoteBind;
