/* eslint-disable no-use-before-define */
import * as React from 'react';
import PropTypes from 'prop-types';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';

import * as utils from 'utils';
import { styles } from './CustomAutocompleteMui.styles';

CustomAutocompleteMui.propTypes = {
  title: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string,
  optionsSearchText: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionKey: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
};

CustomAutocompleteMui.defaultProps = {
  muiComponentProps: {},
  options: [],
  optionKey: 'value',
  optionLabel: 'label',
  value: null,
  defaultValue: null,
};

export default function CustomAutocompleteMui({
  selectionKey,
  title,
  noOptionsText,
  optionsSearchText,
  options,
  groupBy,
  handleValueChange,
  optionKey = 'value',
  optionLabel = 'label',
  selectedOptions = [],
  triggerFilterOnSelect = true,
}) {
  const classes = makeStyles(styles, { name: 'CustomAutocompleteMui' })();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(() => selectedOptions);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const removeValue = (optionValue, optionKey) => {
    setValue((value) => {
      const newValue = [...value].filter((v) => v[optionKey] !== optionValue);
      handleValueChange(newValue, selectionKey);
      return newValue;
    });
  };

  const removeAllValues = () => {
    handleValueChange([], selectionKey);
    setValue([]);
  };

  const handleClose = (_, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    if (!triggerFilterOnSelect) {
      handleValueChange(value, selectionKey);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'select-label' : undefined;

  const SelectedOption = ({ value, label, optionKey, removeValue }) => {
    const [isActive, setIsActive] = React.useState(false);

    const handleMouseEnter = () => {
      setIsActive(true);
    };

    const handleMouseLeave = () => {
      setIsActive(false);
    };

    return (
      <div
        className={classes.selectedOption}
        onClick={() => removeValue(value, optionKey)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className={classes.text}>{label}</span>
        <Slide direction="left" in={isActive} mountOnEnter unmountOnExit>
          <RemoveCircleIcon className={classes.remove} />
        </Slide>
      </div>
    );
  };

  const selectedValues = _groupBy(value, (item) => item.groupBy);

  return (
    <React.Fragment>
      <div data-testid="custom-autocomplete-root" className={classes.root}>
        <ButtonBase
          data-testid="custom-autocomplete-button"
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          <Typography variant="body2">{title}</Typography>
          <SearchIcon />
        </ButtonBase>
        {Object.keys(selectedValues).map((key) => (
          <div key={key} className={classes.selectedValues}>
            {key !== 'undefined' ? (
              <Typography
                variant="body2"
                classes={{
                  body2: classes.selectedValuesTitle,
                }}
              >
                {key}
              </Typography>
            ) : null}
            {selectedValues[key].map((label, index) => (
              <SelectedOption
                key={label[optionKey]}
                removeValue={removeValue}
                index={index}
                value={label[optionKey]}
                optionKey={optionKey}
                label={label[optionLabel]}
              />
            ))}
          </div>
        ))}

        {value?.length ? (
          <div
            style={{
              width: '100%',
              textAlign: 'right',
            }}
          >
            <span onClick={removeAllValues} className={classes.clear}>
              {utils.string.t('products.filter.clear')}
            </span>
          </div>
        ) : null}
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" disablePortal={true} className={classes.popper}>
        <div className={classes.header}>
          <span>{optionsSearchText}</span>
          <IconButton data-testid="close-custom-autocomplete-popper" onClick={handleClose} size="small" disableRipple>
            <CloseIcon width={16} height={16} />
          </IconButton>
        </div>
        <Autocomplete
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            selected: classes.selected,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            if (triggerFilterOnSelect) {
              handleValueChange(newValue, selectionKey);
            }
          }}
          disableCloseOnSelect
          disablePortal
          groupBy={(option) => (groupBy ? option[groupBy] : null)}
          noOptionsText={noOptionsText || utils.string.t('products.filter.noResults')}
          ListboxComponent={List}
          getOptionSelected={(option, value) => option[optionKey] === value[optionKey] || option[optionKey] === value}
          renderOption={(option, { inputValue }) => {
            const title = option[optionLabel];
            const matches = match(title, inputValue);
            const parts = parse(title, matches);

            return (
              <React.Fragment>
                <div className={classes.text} data-testid={`option-${title}`}>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              </React.Fragment>
            );
          }}
          options={
            groupBy
              ? _orderBy([...options], [groupBy, optionLabel], ['asc', 'asc'])
              : [...options].sort((a, b) => {
                  // Display the selected labels first.
                  let ai = value.indexOf(a);
                  ai = ai === -1 ? value.length + options.indexOf(a) : ai;
                  let bi = value.indexOf(b);
                  bi = bi === -1 ? value.length + options.indexOf(b) : bi;
                  return ai - bi;
                })
          }
          getOptionLabel={(option) => option[optionLabel]}
          renderInput={(params) => (
            <InputBase
              data-testid="custom-autocomplete-input"
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </React.Fragment>
  );
}
