import { FC } from 'react';

import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useFacilitiesStyles } from './Facilities.styles';

export const FacilityTableListSkeleton: FC<IFacilityTableListSkeletonProps> = ({ colSpan, numberOfCards = 12 }) => {
  const classes = useFacilitiesStyles();
  return (
    <TableBody data-testid="facility-skeleton">
      {Array.from(Array(numberOfCards).keys()).map((i) => (
        <TableRow key={i} className={classes.row}>
          <TableCell className={classes.loadingCell} colSpan={colSpan}>
            <Skeleton height={42} animation="wave" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
