import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { IDeleteLineageMappingDTO } from 'forms/AddEditLineageMapping/AddEditLineageMapping.types';

import { LINEAGE_MAPPING } from 'lib/binderManagement/binderManagement.queryKeys';
import { enqueueNotification } from 'stores';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useDeleteLineageMapping = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useDeleteLineageMapping',
    warning: 'notification.lineage.deleteMappingFail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries([LINEAGE_MAPPING]);
    dispatch(enqueueNotification('notification.lineage.deleteMappingSuccess', 'success'));
  };

  return useMutation<void, AxiosError, IDeleteLineageMappingDTO>(
    async ({ oldReference }) => {
      await binderManagementLineageAxios.delete(`/lineage/config/${oldReference}`);
    },
    { onError: handleError, onSuccess: handleSuccess }
  );
};
