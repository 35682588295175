import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesDocumentsAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_DOCUMENT_FOLDERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useRenameFacilityDocument = ({ umr, folder }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binders.mutations.useRenameFacilityDocument',
    warning: 'notification.binderManagement.documentRenameFail',
  });

  const onError = (e) => {
    if (e.message === 'DUPLICATED') {
      dispatch(enqueueNotification('notification.binderManagement.documentRenameFailDuplicated', 'error'));
      return;
    }
    handleError(e);
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries([FACILITY_BINDER_DOCUMENT_FOLDERS, umr]);

    dispatch(enqueueNotification('notification.binderManagement.documentRename', 'success'));
  };

  return useMutation<void, AxiosError, IRenameFacilityDocumentDTO>(
    async ({ originalFileName, newFileName, folderFiles = [] }) => {
      if (folderFiles?.includes(newFileName)) {
        throw new Error('DUPLICATED');
      }

      return binderManagementFacilitiesDocumentsAxios.put(`${umr}/${folder}`, { originalFileName, newFileName });
    },
    {
      onSuccess: handleSuccess,
      onError,
    }
  );
};
