import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { quoteBindAxios } from 'lib/quoteBind';
import { QB_RISK_QUOTES } from 'lib/quoteBind/queryKeys';
// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const usePutQuoteOptions = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.usePutQuoteOptions',
    };

    dispatch(enqueueNotification('notification.putQuoteOptions.fail', 'error'));
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = async (res) => {
    dispatch(enqueueNotification('notification.putQuoteOptions.success', 'success'));
    await queryClient.setQueryData([QB_RISK_QUOTES, res?.riskId], (oldData) =>
      oldData?.map((quote) => {
        if (quote.id === res?.id) {
          return res;
        }
        return quote;
      })
    );
  };

  return useMutation(
    async ({ additionalOptions, quoteID }) => {
      if (isEmpty(additionalOptions) || !quoteID) {
        throw new Error('Invalid data');
      }

      const path = `quotes/${quoteID}/update-additional-options`;

      const result = await quoteBindAxios.put(path, additionalOptions);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
