export const setSortOptions = (payload) => {
  return {
    type: 'SET_SORT_OPTIONS',
    payload,
  };
};

export const setDisplayStyle = (payload) => {
  return {
    type: 'SET_DISPLAY_STYLE',
    payload,
  };
};

export const resetSort = (payload) => {
  return {
    type: 'RESET_SORT',
    payload,
  };
};
