import { makeStyles } from '@material-ui/core';

export const conditionsStyles = makeStyles((theme) => ({
  conditions: {
    margin: theme.spacing(2, 0),
  },
  conditionStatement: {
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },

  condition: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  conditionLineContainer: {
    paddingRight: `0px!important`,
    paddingLeft: `${theme.spacing(3)}px!important`,

    '& + *': {
      paddingLeft: `0px!important`,
    },
  },

  deleteCondition: {
    marginTop: theme.spacing(1),
  },
}));
