export const styles = (theme) => ({
  filterContainer: {
    maxHeight: 'calc(100vh - 160px)',
    height: 'calc(100vh - 160px)',
    marginBottom: 20,
    overflowY: 'auto',
  },
  filterTop: {
    backgroundColor: '#f5f5f5',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  filterTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  filterFooter: {
    padding: 20,
    borderTop: `1px solid ${theme.palette.neutral.light}`,
  },
  popper: {
    boxShadow: theme.shadows[2],
    borderRadius: 3,
    zIndex: 9999,
    fontSize: 13,
    background: theme.palette.primary.main,
    overflow: 'hidden',
  },
  calendarTitle: {
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    fontWeight: 600,
  },
  defaultBtn: {
    background: theme.palette.neutral.light,

    // hover
    '&:hover': {
      background: theme.palette.neutral.light,
    },
  },
  activeBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  clearBtn: {
    marginLeft: 4,
    '& .MuiBadge-badge': { fontSize: '0.7rem', height: 16, minWidth: 16 },
  },
  button: {
    fontSize: 16,
    width: '100%',
    textAlign: 'left',
    // color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      // color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 24,
      height: 24,
    },
  },
});
