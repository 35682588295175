import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'components';
import { BinderLimitPreviewSkeleton } from './BinderLimitPreviewSkeleton';
import { binderLimitPreviewStyles } from './BinderLimitPreview.styles';

import * as utils from 'utils';
import { Delete, Edit } from '@material-ui/icons';
import { showModal } from 'stores';
import { ConditionsPreview } from './ConditionsPreview';
import { SumColumnsPreview } from './SumColumnsPreview';
import { IBinderLimitPreviewProps } from './BinderLimitPreview.types';

export const BinderLimitPreview: FC<IBinderLimitPreviewProps> = ({ limit, facility, isLoading, handleDeleteLimit }) => {
  const classes = binderLimitPreviewStyles();
  const dispatch = useDispatch();
  const { conditionType, conditions, sumColumns } = limit || {};

  const handleEditLimit = () => {
    dispatch(
      showModal({
        component: 'ADD_BINDER_LIMIT',
        props: {
          title: 'binderLimits.editLimit',
          fullWidth: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          maxWidth: 'xl',
          componentProps: {
            facility,
            limit,
          },
        },
      })
    );
  };

  return isLoading ? (
    <BinderLimitPreviewSkeleton />
  ) : (
    <div className={classes.limit}>
      <ConditionsPreview conditionType={conditionType} conditions={conditions} />
      <SumColumnsPreview sumColumns={sumColumns} />

      <div className={classes.limitControlWrapper}>
        <Button
          text={utils.string.t('binderLimits.deleteLimit')}
          icon={Delete}
          variant="contained"
          color="secondary"
          size="small"
          nestedClasses={{ btn: classes.limitControl }}
          onClick={() => handleDeleteLimit(limit)}
        />
        <Button
          text={utils.string.t('binderLimits.editLimit')}
          icon={Edit}
          variant="contained"
          color="primary"
          size="small"
          nestedClasses={{ btn: classes.limitControl }}
          onClick={handleEditLimit}
        />
      </div>
    </div>
  );
};
