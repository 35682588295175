import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// mui
import { Box, CardContent, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import { Button, FormCheckbox } from 'components';

import { usePutQuoteOptions } from 'lib/quoteBind/mutations/usePutQuoteOptions';
import * as utils from 'utils';

// app
import { useQuoteCardStyles } from './QuoteCard/QuoteCard.styles';

export const AdditionalOptions = ({ additionalOptions, currency, isEditable, quoteID, handleUpdateAddOns }) => {
  const classes = useQuoteCardStyles();

  const { mutateAsync: putQuoteOptions } = usePutQuoteOptions();
  const fields = additionalOptions?.map((option) => ({
    gridSize: { xs: 12, sm: 8 },
    type: 'checkbox',
    name: option?.name,
    value: option?.status,
    label: option?.name,
  }));
  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, reset, handleSubmit, formState, watch } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
    mode: 'onChange',
  });

  const editAdditionalOptions = async (data) => {
    const payload = additionalOptions?.map((option) => ({ ...option, status: data[option?.name] }));
    const optionsResult = await putQuoteOptions({ additionalOptions: payload, quoteID });
    if (optionsResult) {
      reset(data);
      handleUpdateAddOns(optionsResult);
    }
  };

  return (
    <CardContent classes={{ root: classes.cardContentOptionals }}>
      <Box display="flex" direction="row" justifyContent="space-between" alignItems="flex-start" style={{ paddingBottom: '0.4rem' }}>
        <Typography data-testid="additionaloption-title" variant="body2" className={classes.boldTitle}>
          {utils.string.t('products.additionaloptions')}
        </Typography>
      </Box>
      {additionalOptions?.map((option) => (
        <Box key={option?.name} display="flex" justifyContent="space-between" alignItems="center" width="70%" style={{ margin: '0' }}>
          <FormCheckbox
            disabled={!isEditable}
            {...utils.form.getFieldProps(fields, option?.name)}
            control={control}
            watch={watch}
            label={
              <Typography variant="body2" className={classes.title}>
                {option?.name}
              </Typography>
            }
          />
          <Typography variant="body2" className={classnames(classes.value, classes.checkBox)}>
            {`${currency ? utils.string.t(currency) : ''} ${utils.string.t('format.number', {
              value: { number: option?.revisedValue ?? option?.value, default: '-' },
            })}`}
          </Typography>
        </Box>
      ))}
      {formState?.isDirty && (
        <Box display="flex" width="100%" flexDirection="row" justifyContent="flex-end">
          <Button
            data-testid="commission-done-button"
            size="xsmall"
            icon={DoneIcon}
            color="primary"
            onClick={handleSubmit(editAdditionalOptions)}
            nestedClasses={{ icon: classes.icon }}
          />
          <Button
            data-testid="cancel-commission-button"
            size="xsmall"
            icon={ClearIcon}
            onClick={() => reset(defaultValues)}
            color="default"
            nestedClasses={{ btn: classes.btnMarginLeft, icon: classes.icon }}
          />
        </Box>
      )}
    </CardContent>
  );
};

AdditionalOptions.propTypes = {
  additionalOptions: PropTypes.shape({
    value: PropTypes.number,
    status: PropTypes.bool,
    name: PropTypes.string,
    revisedValue: PropTypes.number,
  }),
  currency: PropTypes.string,
  isEditable: PropTypes.bool,
  quoteID: PropTypes.string,
  handleUpdateAddOns: PropTypes.func,
};
