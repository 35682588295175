import React from 'react';
import PropTypes from 'prop-types';

// app
import styles from './Confirm.styles';
import * as utils from 'utils';
import { Button, FormContainer, FormActions } from 'components';

// mui
import { makeStyles } from '@material-ui/core';

ConfirmView.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
};

export function ConfirmView({ handleConfirm, handleClose, cancelLabel, confirmLabel }) {
  const classes = makeStyles(styles, { name: 'Confirm' })();
  return (
    <div className={classes.root}>
      <FormContainer type="dialog">
        <FormActions type="dialog" divider={false}>
          <Button text={cancelLabel || utils.string.t('app.cancel')} variant="text" onClick={handleClose} />
          <Button text={confirmLabel || utils.string.t('app.confirm')} onClick={handleConfirm} color="primary" data-testid="app.confirm" />
        </FormActions>
      </FormContainer>
    </div>
  );
}
