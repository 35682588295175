import { NO_THIRD_PARTY_ACCOUNT } from '../components';

export const getThirdPartyAccountConfig = (claimsThirdPartyAccount: string): IFacilityConfig['thirdPartyAccountConfig'] => {
  if (!claimsThirdPartyAccount) {
    return 'UNSET';
  }
  if (claimsThirdPartyAccount === NO_THIRD_PARTY_ACCOUNT.id) {
    return 'ABSENT';
  }
  return 'PRESENT';
};
