import { makeStyles } from '@material-ui/core';

export const binderLimitStyles = makeStyles((theme) => ({
  limit: {
    width: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    '& > div': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: theme.spacing(2.5),
    },
  },
  limitTitle: {
    alignItems: 'center',
    cursor: 'pointer',
  },
  nameWrapper: {
    display: 'flex',
    flex: 1,
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    '& svg': {
      transform: 'none!important',
    },
  },
  limitSkeleton: {
    padding: theme.spacing(1, 0),
  },
  tableOverride: {
    tableLayout: 'fixed',
  },
  tableCellOverride: {
    border: 'none',
    padding: '0',
  },
  iconOverride: {
    fontSize: 'large',
  },
  iconIsWarning: {
    color: theme.palette.alert.main,
    fontSize: 'large',
  },
  iconIsAlert: {
    color: theme.palette.error.main,
    fontSize: 'large',
  },
  iconIsVisible: {
    visibility: 'inherit',
  },
  iconIsHidden: {
    visibility: 'hidden',
  },
}));
