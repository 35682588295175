import { useQuery } from 'react-query';
import lodashMap from 'lodash/map';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { DROOLS_RISK_CODES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetRiskCodes = (bdxType, sanctionsOnly = false, options = {}) => {
  const params = sanctionsOnly ? { sanctionsOnly } : {};

  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetRiskCodes' });

  return useQuery(
    [DROOLS_RISK_CODES, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get(`/riskCodes`, { params });
      if (bdxType) {
        const list = result?.data?.riskCodes?.filter((c) => c.bdxTypes?.includes(bdxType));
        return lodashMap(list, 'riskCode') || [];
      }
      return lodashMap(result?.data?.riskCodes, 'riskCode') || [];
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
      cacheTime: 60 * 60 * 1000,
      ...options,
    }
  );
};
