import { FC, useMemo } from 'react';

import { Typography } from '@material-ui/core';

import { FormSelect } from 'components';

import { useGetBMThirdPartyAccounts } from 'lib/binderManagement';
import * as utils from 'utils';

import { useFacilityConfigStyles } from '../FacilityConfig.style';
import { IFacilityConfigViewProps } from '../FacilityConfig.types';

interface IThirdPartyConfigProps {
  fields: IFacilityConfigViewProps['fields'];
  methods: IFacilityConfigViewProps['methods'];
  isSubmitting?: boolean;
}

interface IThirdPartySelectOption {
  id: string;
  name: string;
}

export const NO_THIRD_PARTY_ACCOUNT: IThirdPartySelectOption = {
  id: '-1',
  name: 'No TPA Account',
};

export const ThirdPartyConfig: FC<IThirdPartyConfigProps> = ({ fields, methods, isSubmitting }) => {
  const classes = useFacilityConfigStyles();
  const { operationalThirdParties: selectedOperationalThirdParties } = methods.watch();
  const results = useGetBMThirdPartyAccounts({
    tpa: selectedOperationalThirdParties,
  });
  const handleChangeTPA = () => {
    methods.setValue('claimsThirdPartyAccount', null);
  };

  const isLoadingThirdPartyAccounts = results.some(({ isFetching }) => isFetching);

  const thirdPartyAccounts: IThirdPartySelectOption[] = useMemo(() => {
    const tpa = results
      .reduce<IThirdPartyAccount[]>((acc, { data }) => [...acc, ...(data ?? [])], [])
      .map((account) => ({
        id: account.claimsThirdPartyAccount,
        name: `${account.claimsThirdPartyAccount} - ${account.companyName} - ${account.address.street}`,
      }));
    return [NO_THIRD_PARTY_ACCOUNT, ...tpa];
  }, [results]);

  const isAccountDisabled = isSubmitting || isLoadingThirdPartyAccounts;

  return (
    <div className={classes.group}>
      <Typography variant="h3" className={classes.groupTitle}>
        {utils.string.t('form.facilityConfig.tpa')}
      </Typography>

      <FormSelect
        control={methods.control}
        {...utils.form.getFieldProps(fields, 'operationalThirdParties')}
        disabled={isSubmitting}
        handleChange={handleChangeTPA}
      />

      <FormSelect
        control={methods.control}
        {...utils.form.getFieldProps(fields, 'claimsThirdPartyAccount')}
        disabled={isAccountDisabled}
        options={thirdPartyAccounts ?? []}
      />
    </div>
  );
};
