import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { BDX_UN_SUBMITTED_LINES, BDX_UNMAPPED_LINES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useUnmappedBdxLines = ({ groupId, requestId, page = 0, size = 12, options = {} }) => {
  const params = { page, size };
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useUnmappedBdxLines' });

  return useQuery(
    [requestId ? BDX_UN_SUBMITTED_LINES : BDX_UNMAPPED_LINES, groupId, requestId, params],
    async () => {
      if (!groupId && !requestId) throw new Error('Missing required params');

      const path = requestId ? `/bdx/data/${requestId}` : `/unprocessed/${groupId}`;

      const result = await binderManagementFacilitiesAxios.get(path, { params });
      return {
        ...result.data,
        content: result?.data?.content.map((line) => ({
          ...line,
          rowId: requestId ? line?.rowId : line?.id?.rowId,
        })),
      };
    },
    {
      onError: (err) => handleError(err),
      ...options,
    }
  );
};
