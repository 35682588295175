import { makeStyles } from '@material-ui/styles';

export const useBDXDragDropStyles = makeStyles((theme: Theme) => ({
  root: (props: { version: 'small' | 'default' }) => ({
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1.5),
    '&:first-child': {
      marginTop: '6px',
    },

    '&:last-child': {
      paddingBottom: props.version !== 'small' ? '2rem' : '',
    },
  }),
  formLabel: {
    color: theme.palette.primary.main,
    marginLeft: '2px',
    lineHeight: 1,
    marginBottom: '6px',
    fontSize: theme.typography.pxToRem(15),
    display: 'flex',
  },
  dragArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#4FB2CE',
    borderStyle: 'dashed',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    justifyContent: 'center',
    transition: theme.transitions.create(['border']),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  dragOver: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  uploadedFile: {
    marginTop: theme.spacing(3),
    listStyle: 'none',
    display: 'flex',
    backgroundColor: 'rgba(79, 178, 206, 0.13)',
    borderRadius: 12,
    fontSize: theme.typography.pxToRem(13),
    padding: '0.25rem',
    width: 'max-content',

    '& > li': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  browseFile: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.light,
    border: `1px solid rgba(79, 178, 206, 0.5)`,
    borderRadius: 10,
    backgroundColor: 'rgba(134, 228, 255, 0.08)',
    transition: theme.transitions.create(['color', 'border', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.neutral.lightest,
    },
  },
  dragFile: {
    margin: '8px 0 10px',
    textAlign: 'center',
    fontFamily: 'Open Sans',
  },
  uploadIcon: {
    fontSize: theme.typography.pxToRem(36),
    color: theme.palette.primary.main,
    opacity: 0.75,
    transform: 'scale(2.5)',
    marginBottom: theme.spacing(3),
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: theme.typography.pxToRem(20),
    marginRight: 5,
  },
  excelIcon: {
    marginRight: '0.25rem',
  },
  uploadedFileListLabel: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    maxHeight: '24px',
  },
}));
