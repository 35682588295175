import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';
import get from 'lodash/get';

// mui
import { makeStyles } from '@material-ui/core';

// app
import { OpportunityTooltipView } from './OpportunityTooltip.view';
import { Chip } from 'components';
import { selectRefDataAccountStatuses, selectFormattedAccountStatusList } from 'stores';
import * as utils from 'utils';

OpportunityTooltip.propTypes = {
  tooltip: PropTypes.shape({
    client: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    outputAddress: PropTypes.string,
    premiumSnapshots: PropTypes.array,
  }),
};

export default function OpportunityTooltip({ tooltip }) {
  const departments = useSelector((state) => get(state, 'referenceData.departments'));
  const accountStatuses = useSelector(selectRefDataAccountStatuses);
  const formattedAccountStatusList = useSelector(selectFormattedAccountStatusList);

  // abort
  if (!tooltip) return null;

  const statusId = get(tooltip, 'client.statusId');
  const status = utils.referenceData.status.getById(accountStatuses, statusId);

  const title = `${get(tooltip, 'client.name')}`;
  const kcsTier = utils.trip.getKcsTier(tooltip);

  const address = tooltip.outputAddress;
  const previousYear = utils.app.getCurrentYear() - 1;
  const premiums = get(tooltip, 'premiumSnapshots') || [];

  const premiumsFiltered = premiums
    .filter((premium) => premium?.premium > 0)
    .map((premium) => {
      const dept = utils.referenceData.departments.getById(departments, premium.departmentId);
      const departmentName = dept && dept.name;
      return { ...premium, departmentName };
    })
    .sort(firstBy(utils.sort.array('numeric', 'premium', 'desc')));

  return (
    <OpportunityTooltipView
      title={title}
      titleComponent={<TitleComponent title={title} kcsTier={kcsTier} status={status} list={formattedAccountStatusList} />}
      address={address}
      year={previousYear}
      premiums={premiumsFiltered}
    />
  );
}

const TitleComponent = ({ title, kcsTier, status, list }) => {
  const classes = useChipStyles();

  return (
    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'start' }} data-testid="mapbox-tooltip-title">
      <span>{title}</span>
      {kcsTier ? (
        <Chip
          label={kcsTier}
          type="info"
          nestedClasses={{
            root: classes.tierChip,
            label: classes.tierChipLabel,
          }}
        />
      ) : null}
    </div>
  );
};

const useChipStyles = makeStyles((theme) => ({
  tierChip: {
    height: 16,
    marginTop: 5,
  },
  tierChipLabel: {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
