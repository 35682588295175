// useGetQuotes
import { useApi } from 'hooks';
import { quoteBindAxios } from 'lib/quoteBind';
import { QB_CANCELLATION_REASONS } from 'lib/quoteBind/queryKeys';

async function getCancellationReasons(productCode) {
  if (!productCode) {
    throw new Error('useGetCancellationReasons: productCode is required');
  }

  const apiUrl = `products/${productCode}/cancellationReasons`;

  const response = await quoteBindAxios.get(apiUrl);
  return response.data;
}

export function useGetCancellationReasons(productCode, options) {
  return useApi([QB_CANCELLATION_REASONS, productCode], async (productCode) => await getCancellationReasons(productCode), {
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
}
