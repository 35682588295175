import { makeStyles } from '@material-ui/core';

export const useDeductionStyle = makeStyles({
  root: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    padding: 10,
    fontWeight: 600,
    backgroundColor: '#F5F5F5',
    textAlign: 'left',
  },
  table: {
    minWidth: 450,
  },
});
