import { BDX_AGGREGATE_TYPE_CLAIM } from 'consts';
import { useProcessAggregateManually } from 'lib/binderManagement';

export const useHandleSubmitClaimsManually = ({ aggregate, bdxDocumentsInfo }) => {
  const { mutateAsync: processManually, isLoading: isSubmittingManually } = useProcessAggregateManually({
    type: BDX_AGGREGATE_TYPE_CLAIM,
    facilityReference: bdxDocumentsInfo.facilityReference,
    yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
  });

  const handleSubmitManually = () => {
    processManually({
      requestId: aggregate?.requestId,
    });
  };

  return { handleSubmitManually, isSubmittingManually };
};
