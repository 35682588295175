import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FacilityUserCustomList } from './FacilityUserCustomList/FacilityUserCustomList';

// app
import { FormActions, Button, FormContainer } from 'components';
import { TransferList } from 'components/TransferList/TransferList';
import * as utils from 'utils';
import { hideModal } from 'stores';

export const FacilityUserTransferList = ({ data, handleUpdate, role }) => {
  const [users, setUsers] = useState([...data]);
  const { handleSubmit, formState } = useForm();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleUpdateAssignedData = useCallback((assignedData) => {
    const assigned = assignedData.map((user) => user.id);

    setUsers((users) =>
      [...users].map((user) => {
        user.isAssigned = assigned.indexOf(user.id) !== -1;

        return user;
      })
    );
  }, []);

  const leftData = users.filter(({ isAssigned }) => isAssigned);

  const rightData = users.filter(({ isAssigned }) => !isAssigned);

  const submit = {
    name: 'submit',
    label: utils.string.t('app.save'),
    handler: () => {
      handleUpdate(leftData.map(({ edgeId }) => edgeId));
    },
  };

  return (
    <div style={{ paddingBottom: 10 }}>
      <FormContainer type="dialog" onSubmit={handleSubmit(submit.handler)}>
        <TransferList
          spacing={0}
          leftData={leftData}
          rightData={rightData}
          leftTitle={utils.string.t('binders.users.assigned', { role })}
          rightTitle={utils.string.t('binders.users.available', { role })}
          handleUpdateAssignedData={handleUpdateAssignedData}
          listComponent={FacilityUserCustomList}
        />

        <FormActions type="dialog">
          <Button text={utils.string.t('app.cancel')} disabled={formState.isSubmitting} onClick={handleClose} type="text" />
          <Button text={submit.label} disabled={formState.isSubmitting} type="submit" color="primary" />
        </FormActions>
      </FormContainer>
    </div>
  );
};
