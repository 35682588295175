import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { BDX_AGGREGATE_TYPE_CLAIM } from 'consts';
// app
import { enqueueNotification, hideModal } from 'stores';

import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES, BDX_FACILITY_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitClaimsAggregate = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.bdx.mutation.useSubmitClaimsAggregate',
    warning: 'notification.binderManagement.aggregate.claims.fail',
  });

  const handleSuccess = ({ data, facilityReference, yearMonthSubmission, claimBlockId }) => {
    queryClient.setQueryData([BDX_AGGREGATES, BDX_AGGREGATE_TYPE_CLAIM, facilityReference, yearMonthSubmission], (cache) =>
      cache?.map((aggregate) => (aggregate.ucr === claimBlockId ? data : aggregate))
    );
    queryClient.invalidateQueries([BDX_FACILITY_AGGREGATES, facilityReference, yearMonthSubmission, 'Claim']);
    dispatch(hideModal());
    dispatch(enqueueNotification('notification.binderManagement.aggregate.claims.success', 'success'));
  };

  return useMutation(
    async ({ underwriterGroupId, claimBlockId, yearMonthSubmission, settlementCurrency, facilityReference }) => {
      const { data } = await binderManagementBDXAxios.post('aggregate/claims/submit', {
        underwriterGroupId,
        claimBlockId,
        yearMonthSubmission,
        settlementCurrency,
        facilityReference,
      });

      return { data, facilityReference, claimBlockId, yearMonthSubmission };
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: handleError,
    }
  );
};
