import { useEffect, useState } from 'react';

export const useBDXProcessingAccordion = (numberOfElements) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (numberOfElements === 0) {
      setExpanded(false);
    }
  }, [numberOfElements]);

  const handleChange = (_, newExpanded) => {
    setExpanded(newExpanded);
  };

  return { expanded, handleChange };
};
