import { useCallback, useEffect, useRef, useState } from 'react';

export const useBDXStickyHeaders = ({ classes }) => {
  const headersRef = useRef([]);
  const [containerTop, setContainerTop] = useState();

  const handleScroll = useCallback(
    (e) => {
      const progress = e.target.scrollTop;

      headersRef.current.forEach((header, index, headers) => {
        const headerText = header.firstChild;
        const isNextInactive = headers[index + 1] ? headers[index + 1].offsetTop > progress : true;

        const isSticky = header.offsetTop < progress && isNextInactive;
        if (isSticky) {
          const { left, width } = header.getBoundingClientRect();
          headerText.style.top = `${containerTop}px`;
          headerText.style.left = `${left}px`;
          headerText.style.width = `${width}px`;
          headerText.classList.add(classes.yearHeaderSticky);
        } else {
          headerText.style.top = 0;
          headerText.style.left = 0;
          headerText.style.width = '100%';
          headerText.classList.remove(classes.yearHeaderSticky);
        }
      });
    },
    [classes, containerTop]
  );

  useEffect(() => {
    const content = document.getElementById('content');

    setContainerTop(content.getBoundingClientRect().top);
    handleScroll({ target: content });

    content.addEventListener('scroll', handleScroll);

    return () => content.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return headersRef;
};
