import axios from 'axios';
import { accessToken } from 'utils/auth0/auth0';
import { app } from 'utils';

export const travelAppAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

travelAppAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.baseURL = appConfig.endpoint.edge + `/api`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
