import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';

import { FILTER_MAX_DATE, FILTER_MIN_DATE } from 'consts';

import { defaultStaticRanges } from './defaultRanges';

import 'react-date-range/dist/styles.css'; // main css file
import './default.css'; // theme css file

export const DateRange = ({ selected, handleOnChange }) => {
  const [state, setState] = useState({
    selection: {
      startDate: selected?.startDate ? Date.parse(selected?.startDate) : new Date(),
      endDate: selected?.endDate ? Date.parse(selected?.endDate) : new Date(),
      key: 'selection',
    },
  });

  const handleSelect = (ranges) => {
    const newRanges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: ranges?.selection?.startDate?.setHours ? ranges.selection.startDate?.setHours(0, 0, 0, 0) : ranges.selection.startDate,
        endDate: ranges?.selection?.endDate?.setHours ? ranges.selection.endDate?.setHours(23, 59, 59, 999) : ranges.selection.endDate,
      },
    };

    handleOnChange(newRanges);
    setState({ ...state, ...newRanges });
  };

  return (
    <DateRangePicker
      onChange={handleSelect}
      months={1}
      direction="vertical"
      scroll={{ enabled: true }}
      ranges={[state.selection]}
      inputRanges={[]}
      staticRanges={defaultStaticRanges}
      rangeColors={['#334762']}
      minDate={FILTER_MIN_DATE}
      maxDate={FILTER_MAX_DATE}
      editableDateInputs
      showSelectionPreview
    />
  );
};
