import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { BDX_UN_SUBMITTED_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetUnSubmittedData = ({ coverHolderId, bdxType, options = {} }) => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetUnSubmittedData' });

  return useQuery(
    [BDX_UN_SUBMITTED_DATA, coverHolderId, bdxType],
    async () => {
      if (!coverHolderId || !bdxType) throw new Error('Missing required params');
      const result = await binderManagementFacilitiesAxios.get(`/unsubmitted/${coverHolderId}/${bdxType}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      ...options,
    }
  );
};
