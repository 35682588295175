import axios from 'axios';
import { accessToken } from 'utils/auth0/auth0';
import { app } from 'utils';

export const quoteBindAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const axiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const dtcAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const locationAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const buildingAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const multipartQuoteBindAxios = axios.create({
  headers: {
    'Content-type': 'multipart/form-data',
  },
});

quoteBindAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.baseURL = appConfig.endpoint.auth + `/api/v1`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

multipartQuoteBindAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.baseURL = appConfig.endpoint.auth + `/api/v1`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();

    config.baseURL = appConfig.endpoint.auth + `/api/v1`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const authorized = (token) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return axiosInstance;
};

dtcAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();

    config.baseURL = appConfig.endpoint.dtc + `/api`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const authorizedDTC = (token) => {
  dtcAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return dtcAxios;
};

locationAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.baseURL = appConfig.endpoint.location + `/api`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const authorizedLocation = (token) => {
  locationAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return locationAxios;
};

buildingAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.baseURL = appConfig.endpoint.building + ``;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default authorized;
