import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// app
import { ProductsAdminInsuredsView } from './ProductsAdminInsureds.view';
import { showModal } from 'stores';
import {
  useGetClients,
  useGetRiskCountries,
  useGetProducts,
  QB_CLIENTS,
  RISK_COUNTRIES,
  QB_PRODUCTS,
  useGetInsureds,
  INSUREDS,
  REINSUREDS,
} from 'lib/quoteBind';
import { productAdminSchema } from 'schemas';
import { usePagination } from 'hooks';
import * as utils from 'utils';

export default function ProductsAdminInsureds({ reInsured = false }) {
  const dispatch = useDispatch();
  const [schema, setSchema] = useState({});
  const [insuredsParams, setInsuredsParams] = useState({ size: 10 });

  const { data: clientsData } = useGetClients(QB_CLIENTS, { size: 1000 });
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { data: programmesProductsData } = useGetProducts(QB_PRODUCTS);

  const clients = useMemo(() => clientsData?.content || [], [clientsData]);
  const products = useMemo(() => programmesProductsData?.data || [], [programmesProductsData]);

  const { data: insuredsData, isLoading: insuredsLoading } = useGetInsureds(
    reInsured ? REINSUREDS : INSUREDS,
    insuredsParams,
    {},
    reInsured ? 'reinsured' : 'insured'
  );

  const insureds = useMemo(() => insuredsData?.content || [], [insuredsData]);
  const insuredsPagination = {
    page: insuredsData?.pagination?.page ? insuredsData?.pagination?.page - 1 : 1,
    rowsTotal: insuredsData?.pagination?.totalElements || 0,
    rowsPerPage: insuredsData?.pagination?.size || 0,
  };

  const addLabel = reInsured ? 'products.admin.reInsureds.add' : 'products.admin.insureds.add';
  const editLabel = reInsured ? 'products.admin.reInsureds.edit' : 'products.admin.insureds.edit';

  const options = useMemo(
    () => ({
      clients,
    }),
    [clients]
  );

  const hydrateLabels = (fields) =>
    fields.map((field) => {
      return {
        ...field,
        label: utils.string.t(field.label),
        ...(field.options && { options: field.options.map((option) => ({ ...option, label: utils.string.t(option.label) })) }),
      };
    });

  useEffect(
    () => {
      if (!insureds) return;

      const { fields, ...rest } = productAdminSchema.getSchema('insureds');

      setSchema({
        ...rest,
        fields: hydrateLabels(fields),
        items: insureds,
        pagination: insuredsPagination,
      });
    },
    [insureds] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangePage = (newPage) => {
    setInsuredsParams({ ...insuredsParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setInsuredsParams({ ...insuredsParams, size: rowsPerPage });
  };

  const handleAddInsured = (fields) => (event) => {
    dispatch(
      showModal({
        component: 'ADD_PRODUCTS_INSURED',
        props: {
          title: addLabel,
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            fields,
            reInsured,
          },
        },
      })
    );
  };

  const handleEditInsured = (data) => {
    const { fields, item } = data;
    const { address } = item;
    const insuredItem = { ...item, ...address };
    dispatch(
      showModal({
        component: 'EDIT_PRODUCTS_INSURED',
        props: {
          title: editLabel,
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            fields,
            item: insuredItem,
            id: insuredItem?.id,
            reInsured,
          },
        },
      })
    );
  };

  const popoverActions = [
    {
      id: 'products-admin-edit-insured',
      label: editLabel,
      callback: (data) => handleEditInsured(data),
    },
  ];

  const pagination = usePagination(schema.items, schema.pagination, handleChangePage, handleChangeRowsPerPage);

  // abort
  if (!schema || !schema.items) return null;

  return (
    <ProductsAdminInsuredsView
      schema={schema}
      loading={insuredsLoading}
      countries={countries}
      options={options}
      pagination={pagination.obj}
      products={products}
      buttonLabel={addLabel}
      reInsured={reInsured}
      popoverActions={popoverActions}
      handlers={{
        addInsured: handleAddInsured,
        changePage: pagination.handlers.handleChangePage,
        changeRowsPerPage: pagination.handlers.handleChangeRowsPerPage,
      }}
    />
  );
}
