import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

// app
import { useDebounce } from 'hooks';
import { FormAutocompleteMuiView } from './FormAutocompleteMui.view';
import * as utils from 'utils';

FormAutocompleteMui.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionKey: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionsCreatable: PropTypes.bool,
  optionsFetch: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  muiComponentProps: PropTypes.object,
  targetField: PropTypes.string,
  callback: PropTypes.func,
  clearOnBlur: PropTypes.bool,
  onSelect: PropTypes.func,
  testid: PropTypes.string,
};

FormAutocompleteMui.defaultProps = {
  muiComponentProps: {},
  options: [],
  optionKey: 'value',
  optionLabel: 'label',
  value: null,
  defaultValue: null,
};

export default function FormAutocompleteMui({
  control,
  setValue = null,
  name,
  value,
  defaultValue,
  disableClearable = null,
  getOptionDisabled = null,
  groupBy = null,
  options,
  optionKey,
  optionLabel,
  optionsCreatable,
  optionsFetch,
  label,
  labelComp = null,
  placeholder,
  hint,
  error,
  disabled = null,
  muiComponentProps,
  targetField,
  callback,
  showCreate = null,
  clearOnBlur,
  onSelect,
  rules = null,
  testid,
}) {
  const fieldProps = {
    groupBy,
    optionKey,
    optionLabel,
    label,
    labelComp,
    disableClearable,
    getOptionDisabled,
    showCreate,
    placeholder,
    error,
    disabled,
    helperText: hint,
    muiComponentProps,
    targetField,
    callback,
    rules,
  };

  const [componentValue, setComponentValue] = useState(value || defaultValue);
  const [componentInputValue, setComponentInputValue] = useState('');
  const [componentOptions, setComponentOptions] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState('');
  const debouncedSearchTerm = useDebounce(componentInputValue, 500);

  const fetch = useMemo(
    () =>
      // debounce to prevent too many fetches on every keystrokes
      debounce((request, callback) => {
        // check if the component support async fetch (optionsFetch)
        if (utils.generic.isFunction(optionsFetch?.handler)) {
          setSearchedTerm(request.input);
          optionsFetch.handler(optionsFetch.type, request.input).then((results) => {
            // check if the component has a custom filter method
            // if yes, filter the results before returning them
            // if no, return the results to callback method
            if (utils.generic.isFunction(optionsFetch?.filter)) {
              callback(optionsFetch.filter(results));
            } else {
              callback(results);
            }
          });
        }
      }, 250),
    [optionsFetch]
  );

  useEffect(() => {
    let active = true;

    // abort if component doesn't support async fetch for options
    if (!optionsFetch) {
      return;
    }

    if (debouncedSearchTerm === '') {
      setComponentOptions(componentValue ? [componentValue] : []);
      return;
    }

    // after selecting an option - to prevent another fetch
    if (debouncedSearchTerm === componentValue?.[optionLabel] || debouncedSearchTerm === searchedTerm) {
      return;
    }

    fetch({ input: debouncedSearchTerm }, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...results];
        }

        setComponentOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [componentValue, debouncedSearchTerm, fetch, optionsFetch, optionLabel]);

  return (
    <FormAutocompleteMuiView
      control={control}
      clearOnBlur={clearOnBlur}
      setValue={setValue}
      name={name}
      value={componentValue}
      fieldProps={fieldProps}
      options={utils.generic.isValidArray(componentOptions, true) ? componentOptions : options}
      filterOptions={fieldProps.filterOptions}
      onChange={(_, newValue) => {
        if (typeof onSelect === 'function') {
          onSelect(newValue);
        }
        if (typeof newValue === 'string') {
          setComponentValue({
            [optionKey]: newValue,
            [optionLabel]: newValue,
          });
        } else if (optionsCreatable && newValue && newValue[optionKey]) {
          // Create a new value from the user input
          setComponentValue({
            [optionKey]: newValue[optionKey],
            [optionLabel]: newValue[optionKey],
          });
        } else {
          setComponentValue(newValue);
        }

        setComponentOptions(componentOptions);
      }}
      onInputChange={(_, newInputValue) => {
        if (componentInputValue !== newInputValue) {
          setComponentInputValue(newInputValue);
        }
      }}
      testid={testid}
    />
  );
}
