import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FacilityContext } from 'pages/FacilityDetails/FacilityDetails';

import { useCreateRule } from 'lib/binderManagement';

import { Rule } from '../Rule';

import { RulesClaimsList } from './components/RulesClaimsList';
import { NoResultsRulesList } from './components';
import { RulesListSkeleton } from './RulesListSkeleton';

export const RulesList = ({ bdxType, nonTPARuleSet, isLoadingRuleSets, ruleSets, rules }) => {
  const { facility, isLoading: isLoadingFacility } = useContext(FacilityContext);
  const { mutateAsync: updateRule, isLoadingRule } = useCreateRule({ facility, bdxType });

  const isLoading = isLoadingFacility || isLoadingRuleSets;

  const handleFavourites = (rule, ruleset) => {
    const rest = ruleset?.rules?.filter((r) => r.id !== rule.id) || [];

    delete rule.isFavourite;
    delete rule.fileName;
    const action = rule?.ruleId ? 'UNFAVOURITE_RULE' : 'FAVOURITE_RULE';

    updateRule({
      rules: [...rest, { ...rule, ruleId: rule?.ruleId ? null : uuidv4(), lastUpdated: new Date().toISOString() }],
      action,
      ruleset,
    });
  };

  const handleDeleteRule = (rule, ruleset) => {
    const rules =
      ruleset?.rules
        ?.filter((r) => r.id !== rule.id)
        .map((r) => {
          delete r.isFavourite;
          delete r.fileName;

          return r;
        }) || [];

    updateRule({ rules, action: 'DELETE_RULE', ruleset });
  };

  if (bdxType === 'Claim') {
    return (
      <RulesClaimsList
        ruleSets={ruleSets}
        bdxType={bdxType}
        facility={facility}
        handlers={{ handleFavourites, handleDeleteRule }}
        isLoadingRule={isLoadingRule}
        isLoading={isLoading}
      />
    );
  }

  if (isLoading) return <RulesListSkeleton />;

  return (
    <div>
      {rules?.length !== 0 ? (
        rules?.map((rule) => (
          <Rule
            key={`${rule.id}-${rule.name}`}
            rule={rule}
            bdxType={bdxType}
            facility={facility}
            handleFavourites={handleFavourites}
            ruleset={nonTPARuleSet}
            isLoading={isLoadingRule}
            handleDeleteRule={handleDeleteRule}
          />
        ))
      ) : (
        <NoResultsRulesList />
      )}
    </div>
  );
};
