import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import { Button, PreventNavigation, Translate } from 'components';

import { fullScreenModal, hideModal } from 'stores';
import * as utils from 'utils';

import { MODALS } from './Modal.const';
import { IModalGetHandleCloseProps, TUIModals } from './Modal.types';

import { useModalStyles } from './Modal.styles';

const Modal: FC = () => {
  const dispatch = useDispatch();
  const { uiModal, fullScreen }: { uiModal: TUIModals[]; fullScreen?: boolean } = useSelector((state: State) => ({
    uiModal: state.ui.modal,
    fullScreen: state.ui.fullScreen,
  }));
  const classes = useModalStyles();

  const getHandleClose =
    ({ type, callback, reason, disableBackdropClick, confirmCancel, confirmCancelHandler }: IModalGetHandleCloseProps) =>
    () => {
      if (disableBackdropClick && reason === 'backdropClick') return;

      if (confirmCancel) {
        confirmCancelHandler();
      } else {
        dispatch(hideModal(type));
        if (utils.generic.isFunction(callback)) {
          callback();
        }
      }
    };

  const handleFullScreen = () => {
    dispatch(fullScreenModal());
  };

  if (!uiModal?.[0]?.type) return null;

  return (
    <>
      {uiModal.map((modal) => {
        const ModalContent = MODALS[modal.type];
        const hasMaxWidth = modal.props?.maxWidth;
        const disableBackdropClick = modal.props?.disableBackdropClick ?? false;
        const enableFullScreen = modal.props?.enableFullScreen ?? false;
        const disableEscapeKey = modal.props?.disableEscapeKey ?? false;
        const cancelHandler = modal.props?.componentProps?.cancelHandler ?? null;
        const confirmCancel = modal.props?.componentProps?.confirmCancel ?? false;
        const confirmCancelHandler = modal.props?.componentProps?.confirmCancelHandler ?? null;

        return (
          <Dialog
            disableEscapeKeyDown={disableEscapeKey}
            data-testid="modal-dialog"
            key={modal.type}
            open={modal.visible}
            onClose={(event, reason) => {
              getHandleClose({ type: modal.type, callback: cancelHandler, reason, disableBackdropClick })();
            }}
            fullWidth={modal.props?.fullWidth}
            fullScreen={fullScreen || modal.props?.fullScreen}
            maxWidth={modal.props?.maxWidth}
            disableAutoFocus={modal.props?.disableAutoFocus}
            aria-labelledby="modal-title"
            classes={{
              ...(!hasMaxWidth && { paper: classes.paper }),
            }}
          >
            {enableFullScreen ? (
              <Button
                icon={FullscreenIcon}
                variant="text"
                onClick={handleFullScreen}
                nestedClasses={{ btn: classes.fullScreen }}
                data-testid="modal-fullscreen-button"
              />
            ) : null}

            <Button
              icon={CloseIcon}
              variant="text"
              onClick={
                cancelHandler
                  ? getHandleClose({ type: modal.type, callback: cancelHandler, confirmCancel, confirmCancelHandler })
                  : getHandleClose({ type: modal.type })
              }
              nestedClasses={{ btn: classes.close }}
              data-testid="modal-close-button"
            />

            {(modal.props?.title || modal.props?.titleChildren) && (
              <>
                <DialogTitle disableTypography id="modal-title" data-testid="modal-title">
                  {modal.props?.titleChildren ? (
                    modal.props?.titleChildren
                  ) : (
                    <>
                      <Translate label={modal.props?.title} variant="h2" className={classes.title} />
                      {modal.props?.subtitle && <Translate label={modal.props?.subtitle} variant="h6" className={classes.subtitle} />}
                    </>
                  )}
                </DialogTitle>
                <Divider />
              </>
            )}

            <DialogContent className={classes.content}>
              {modal.props?.hint && (
                <DialogContentText className={classes.hint} data-testid="modal-hint">
                  <Translate variant="body2" label={modal.props?.hint} />
                </DialogContentText>
              )}

              {modal.props?.children ? (
                modal.props?.children
              ) : (
                // @ts-ignore: poor type validation
                <ModalContent fullScreen={fullScreen} {...modal.props?.componentProps} handleClose={getHandleClose({ type: modal.type })} />
              )}
            </DialogContent>

            {(modal.actions ?? []).length > 0 && (
              <>
                <Divider />
                <DialogActions data-testid="modal-actions">
                  {modal.actions.map((action) => {
                    let btnAction = null;

                    if (action.type === 'ok' || action.type === 'cancel') {
                      btnAction = getHandleClose({ type: modal.type, callback: action.callback });
                    } else {
                      btnAction = action.callback;
                    }

                    return (
                      <Button
                        key={action.label}
                        {...(btnAction && { onClick: btnAction })}
                        {...(action.variant && { variant: action.variant })}
                        {...(action.color && { color: action.color })}
                        text={<Translate label={action.label} />}
                        data-testid={`modal-btn-${action.type}`}
                      />
                    );
                  })}
                </DialogActions>
              </>
            )}
            {confirmCancel && <PreventNavigation dirty />}
          </Dialog>
        );
      })}
    </>
  );
};

export default Modal;
