import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';
import * as utils from 'utils';

// risks
export const useExtendPolicy = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useExtendPolicy',
    };

    dispatch(enqueueNotification('notification.extendPolicy.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([QB_RISK_ENDORSEMENTS, res?.riskId], (oldData = []) => [...oldData, res]);

    dispatch(enqueueNotification('notification.extendPolicy.success', 'success'));
  };

  return useMutation(
    async ({ policyId, expiryDate }) => {
      dispatch(hideModal());

      if (!policyId || !expiryDate) {
        throw new Error('useExtendPolicy: id,expiryDate is required');
      }
      const formattedExpiryDate = utils.date.formatDateWithParameter(expiryDate, 'YYYY-MM-DDTHH:mm:ss');

      const { data } = await quoteBindAxios.put(`/quotes/${policyId}/extend?expiryDate=${formattedExpiryDate}`);

      return data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
