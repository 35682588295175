import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { BDX_AGGREGATE_TYPE_PREMIUM } from 'consts';
import { enqueueNotification, hideModal } from 'stores';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_AGGREGATES, BDX_FACILITY_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useSubmitPremiumAggregate = ({ facilityReference }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useSubmitPremiumAggregate',
    warning: 'notification.binderManagement.aggregate.premium.error',
  });

  const handleSuccess = ({ yearMonthSubmission, data }) => {
    queryClient.setQueryData([BDX_AGGREGATES, BDX_AGGREGATE_TYPE_PREMIUM, facilityReference, yearMonthSubmission], (oldData) =>
      oldData?.map((item) => {
        if (item.underwriterGroupId === data.underwriterGroupId && item.settlementCurrency === data.settlementCurrency) {
          return data;
        }
        return item;
      })
    );

    queryClient.invalidateQueries([BDX_FACILITY_AGGREGATES, facilityReference, yearMonthSubmission, 'Premium']);
    dispatch(hideModal());
    dispatch(enqueueNotification('notification.binderManagement.aggregate.premium.success', 'success'));
  };

  return useMutation(
    async ({ underwriterGroupId, yearMonthSubmission, settlementCurrency }) => {
      if (!underwriterGroupId || !yearMonthSubmission || !settlementCurrency) throw new Error('Missing required params');

      const { data } = await binderManagementBDXAxios.post(`aggregate/premium/submit`, {
        underwriterGroupId,
        yearMonthSubmission,
        settlementCurrency,
      });

      return {
        yearMonthSubmission,
        data,
      };
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: handleError,
    }
  );
};
