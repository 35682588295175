import { makeStyles } from '@material-ui/core/styles';

export const useQuoteBindStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `20px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  title: {
    marginBottom: 0,
    marginRight: 10,
    fontWeight: 600,
  },
  content: {
    padding: `10px ${theme.spacing(3)}px 20px`,
  },
}));
