import { useQuery } from 'react-query';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_LIMIT_COLUMNS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLimitColumnsParams {
  riskCodes: string;
  bdxType: string;
}

export const useGetLimitColumns = (params: IUseGetLimitColumnsParams = { riskCodes: null, bdxType: null }, options = {}) => {
  const { riskCodes, bdxType } = params;
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetLimitColumns' });

  return useQuery<ILimitsColumn[]>(
    [DROOLS_LIMIT_COLUMNS, riskCodes, bdxType],
    async () => {
      if (!bdxType || !riskCodes) {
        return [];
      }
      const result = await binderManagementDroolsAxios.get(`/limits/columns?riskCodes=${riskCodes}&bdxType=${bdxType}`);

      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      ...options,
    }
  );
};
