import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFileInputAxiosV2 } from '../../binderManagement.api';
import { BDX_UN_SUBMITTED_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IDeleteBulkProps {
  groupId: string;
}

export const useDeleteBulk = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useDeleteBulk',
    warning: 'notification.binderManagement.bdxDelete.fail',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries(BDX_UN_SUBMITTED_DATA);

    dispatch(enqueueNotification('notification.bdxDelete.success', 'success'));
  };

  return useMutation<IDeleteBulkProps, AxiosError, IDeleteBulkProps>(
    async (config) => {
      await binderManagementFileInputAxiosV2.delete(`bulk/${config.groupId}`);

      return config;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
