import { makeStyles } from '@material-ui/core';

export const sumcolumnsStyles = makeStyles((theme) => ({
  sumColumns: {
    margin: theme.spacing(2, 0),
  },
  sumColumnsStatement: {
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },

  sumColumn: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  sumColumnLineContainer: {
    paddingRight: `0px!important`,
    paddingLeft: `${theme.spacing(3)}px!important`,

    '& + *': {
      paddingLeft: `0px!important`,
    },
  },
  sumColumnLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  sumColumnLabelText: {
    transition: 'all 0.3s ease',
    opacity: '0.3',
  },
  sumColumnLabelTextActive: {
    opacity: '1',
  },

  operandLabelWrapper: {
    '& label': {
      marginTop: 5,
      marginBottom: 9,
    },
  },

  deleteSumColumn: {
    marginTop: theme.spacing(1),
  },
}));
