import { makeStyles } from '@material-ui/core';

export const useBDXStyles = makeStyles(
  (theme) => ({
    yearWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },

    yearHeader: {
      position: 'relative',
    },
    yearHeaderBox: {
      height: 43,
    },

    yearHeaderText: {
      fontSize: 18,
      zIndex: 3,
      fontWeight: 600,
      position: 'absolute',
      width: '100%',
      top: 0,
      margin: 0,
      background: '#fff',
      padding: theme.spacing(1, 2.5),
      borderBottom: `1px solid ${theme.palette.neutral.light}`,
      borderTop: `1px solid ${theme.palette.neutral.light}`,
      boxShadow: theme.shadows[1],
    },
    yearHeaderSkeleton: {
      padding: theme.spacing(1, 2.5),
      borderBottom: `1px solid ${theme.palette.neutral.light}`,
      borderTop: `1px solid ${theme.palette.neutral.light}`,
    },

    yearHeaderSticky: {
      zIndex: 2,
      position: 'fixed',
    },

    bdxList: {
      width: 'auto',
      padding: theme.spacing(0, 1.5),
    },

    buttonWrapper: {
      display: 'flex',
      marginTop: theme.spacing(2),
      justifyContent: 'center',
    },

    cardItem: {
      flex: 1,
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
      backgroundColor: '#fff',
      height: '100%',
      borderLeft: `0.25px solid ${theme.palette.grey[300]}`,
      borderRight: `0.25px solid ${theme.palette.grey[300]}`,

      '&:first-child': {
        borderLeft: 0,
      },
      '&:last-child': {
        borderRight: 0,
      },
    },

    cardItemType: {
      fontSize: 14,
      color: 'black!important',
      fontWeight: 600,
    },

    fileIcon: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'white',
      },
    },

    submitted: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    fileDragWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      padding: theme.spacing(1),
      position: 'relative',
      flex: 1,
    },

    dragArea: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1.5),
      borderWidth: 1,
      borderRadius: 10,
      borderColor: '#4FB2CE',
      borderStyle: 'dashed',
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'center',
      transition: theme.transitions.create(['border']),

      '&:hover': {
        cursor: 'pointer',
      },
    },
    dragOver: {
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
    },

    card: {
      overflow: 'hidden',
      color: 'white',
      borderRadius: 10,
      boxShadow: theme.shadows[2],
      position: 'relative',
      padding: 0,
      minWidth: 'fit-content',
      cursor: 'pointer',
    },
    cardHeader: {
      padding: '0.5rem',
      backgroundColor: '#fafafa',
    },
    cardTitle: {
      margin: 0,
      paddingRight: '0.5rem',
      width: '100%',
      textAlign: 'left',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '1.5em',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    cardTitleLoading: {
      lineHeight: 1,
      height: 20,
    },
    cardContent: {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      display: 'flex',
      flexDirection: 'row',
      padding: '0!important',
    },
    subTitle: {
      fontWeight: 400,
      fontSize: 14,
      margin: 0,
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },

    cardDate: {
      fontSize: theme.typography.pxToRem(12),
      textAlign: 'center',
      color: `${theme.palette.neutral.black}!important`,
      fontWeight: 500,
    },

    cellWrapper: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'BDX' }
);
