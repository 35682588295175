const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2.5),
  },
  reports: {
    overflow: 'hidden',
    height: '70vh',
    margin: theme.spacing(1, 0),
  },
  loader: {
    width: '100%',
    height: 428,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
