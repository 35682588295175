const uploadBdx = {
  getMappingsfromValues: (values, fields, isCoverHolderUpload = false, validateData = {}) => {
    const filteredValues = Object.keys(values).filter((key) => key.includes('field_'));
    let mappings = {};

    if (isCoverHolderUpload) {
      mappings = validateData.expectedMapperFields.map((v, index) => {
        const mapping = {
          ...v,
          matchField: values[`field_${index}`] ? values[`field_${index}`]['mapperField'] : null,
          matchFieldId: values[`field_${index}`] ? values[`field_${index}`]['fieldId'] : null,
        };
        return mapping;
      });
    } else {
      mappings = filteredValues.map((key) => {
        const mapping = {
          odsField: fields[key.replace('field_', '')]?.label,
          matchField: values[key] ? values[key]['mapperField'] : null,
          fieldLocationId: values[key] ? values[key]['fieldId'] : null,
        };
        return mapping;
      });
    }
    return mappings;
  },
};

export default uploadBdx;
