import React from 'react';

import { Button, Divider, Grid } from '@material-ui/core';

import * as utils from 'utils';
import { useUploadBDXActionsClasses } from './UploadBDXActions.styles';

export const UploadBDXActions = ({ children, isBackDisabled, handleBack, isNextDisabled, handleNext, labelNext }) => {
  const classes = useUploadBDXActionsClasses();

  return (
    <div>
      <Divider />
      <Grid container className={classes.actions}>
        <Grid item>{children}</Grid>
        <Grid item className={classes.actionsRight}>
          <Button disabled={isBackDisabled} onClick={handleBack} className={classes.button}>
            {utils.string.t('app.backToPrevious')}
          </Button>
          <Button variant="contained" disabled={isNextDisabled} color="primary" onClick={handleNext}>
            {labelNext}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
