import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { adminAxios } from '../../admin.api';
import { CLIENT_OFFICE_LIST, OPERATIONAL_CLIENTS } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification, hideModal } from 'stores';

export const useEditClientOffice = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.editClientOffice',
      message: 'Data missing for PATCH request',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.officePatchFail', 'error'));
    dispatch(hideModal());
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_CLIENTS);
    queryClient.invalidateQueries(CLIENT_OFFICE_LIST);

    dispatch(
      enqueueNotification('notification.admin.officePatchSuccess', 'success', {
        data: { officeName: data?.name, clientName: data?.parent && data?.parent.name },
      })
    );
    dispatch(hideModal());
  };

  return useMutation(
    async ({ office, id }) => {
      return await adminAxios.patch(`client/office/${id}`, office);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
