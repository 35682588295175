import * as utils from 'utils';

import { isConditionRegular } from '../components/Conditions/utils';

export const getConditions = (rule, methods) => {
  if (rule.conditions.length === 0 && !rule.duplicate) {
    methods.setError('duplicate', { type: 'custom', message: utils.string.t('validation.required') });
    return false;
  }

  const conditions = rule.conditions.map((condition) => {
    const conditionToReturn = {
      ...condition,
      columnValue: condition.columnValue?.name || null,
      field: condition.field?.name || null,
    };
    if ('caseInsensitive' in condition) {
      conditionToReturn.caseInsensitive = !condition.caseInsensitive;
    }
    return conditionToReturn;
  });

  if (rule.duplicate) {
    conditions.unshift({ operation: 'is duplicate' });
  } else {
    return conditions.filter(isConditionRegular);
  }

  return conditions;
};
