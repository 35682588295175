import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import isEmpty from 'lodash/isEmpty';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_DETAILS, QB_RISK_QUOTES } from 'lib/quoteBind/queryKeys';

// risks
export const usePatchRisk = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.usePatchRisk',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, { id: res.riskId });
    queryClient.invalidateQueries(QB_RISK_QUOTES, res.riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.reQuote.success', 'success'));
  };

  return useMutation(
    async (params) => {
      const { values, definitions, riskId, riskData } = params;

      if (!riskId || isEmpty(values) || !utils.generic.isValidArray(definitions)) {
        throw new Error('Invalid params');
      }

      const body = { ...riskData, ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions) };

      const data = {
        id: riskId,
        ...body,
        hiddenField: uuidv4(),
      };

      const result = await quoteBindAxios.patch(`/risks/${riskId}`, data);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
