import FileSaver from 'file-saver';
import isString from 'lodash/isString';

// app
import { FILE_EXTENSION } from 'consts';

const utilsFile = {
  fileSaver: (fileData, fileName) => FileSaver.saveAs(fileData, fileName),
  download: (blob, filename) => {
    if (!blob || !filename) return;

    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');

    link.href = url;
    link.download = filename;
    window.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  },

  getFilenameFromHeadersBlob: (headers, blob, defaultName) => {
    const fallbackExtension = utilsFile.getFileExtensionFromBlobType(blob);
    const fallbackFilename = defaultName && isString(defaultName) && fallbackExtension ? `${defaultName}.${fallbackExtension}` : undefined;

    if (headers) {
      const contentDisposition = headers['content-disposition'] || '';
      const headerAttributes = contentDisposition.split(';');
      const filenameHeader = headerAttributes.find((att) => att.trim().startsWith('filename='));
      const filename = filenameHeader ? filenameHeader.trim().replace('filename=', '') : '';
      return filename || fallbackFilename;
    }

    return fallbackFilename;
  },

  getFileExtensionFromBlobType: (blob) => {
    if (!blob || !blob.type) return;

    return FILE_EXTENSION[blob.type];
  },

  truncate: (n, len) => {
    const ext = n.lastIndexOf('.') > -1 ? n.substring(n.lastIndexOf('.') - 2, n.length).toLowerCase() : '';
    let filename = n.replace(`.${ext}`, '');
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '..' : '');
    return `${filename}.${ext}`;
  },
};

export default utilsFile;
