import { makeStyles } from '@material-ui/core';

export const useUploadBDXContentClasses = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
    },
    content: {
      flex: 1,
      overflowY: 'auto',
    },
  }),
  { name: 'UploadBDXContent' }
);
