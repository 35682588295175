const themeDefaults = {
  palette: {
    primary: {
      lightest: '#bfc7d3',
      lighter: '#8f9cb1',
      light: '#5f7290',
      main: '#334762',
      medium: 'rgba(0, 0, 0, 0.25)',
      dark: '#062038',
      darker: '#051a2d',
      darkest: '#041322',
      hint: 'rgba(48, 69, 98, 0.54)',
      contrastText: '#fff',
    },
    secondary: {
      lightest: '#f8fdff',
      lighter: '#e3f5fc',
      light: '#86e4ff',
      main: '#4fb2ce',
      dark: '#00829d',
      darker: '#377d90',
      darkest: '#285967',
      hint: 'rgba(68, 179, 208, 0.54)',
    },
    neutral: {
      lightest: 'rgba(0, 0, 0, 0.02)',
      lighter: 'rgba(0, 0, 0, 0.08)',
      light: 'rgba(0, 0, 0, 0.12)',
      medium: 'rgba(0, 0, 0, 0.25)',
      main: 'rgba(0, 0, 0, 0.5)',
      dark: 'rgba(0, 0, 0, 0.75)',
      darker: 'rgba(0, 0, 0, 0.87)',
      darkest: 'rgba(0, 0, 0, 0.95)',
      black: '#000',
    },
    error: {
      light: '#EF747F',
      main: '#E93D4C',
      dark: '#D11828',
      contrastText: '#fff',
    },
    success: {
      lightest: '#d4ffd4',
      light: '#56D9C2',
      main: '#2CC6AB',
      dark: '#219480',
      darkest: '#2CC6AB',
      contrastText: '#fff',
    },
    info: {
      light: '#3CC0F8',
      main: '#09A9ED',
      dark: '#077FB2',
      contrastText: '#fff',
    },
    alert: {
      light: '#FFC52B',
      main: '#EDAC00',
      dark: '#B08000',
      darkest: '#B39602',
      contrastText: '#fff',
    },
    // Quote & Bind status colors
    status: {
      bound: '#2CC6AB',
      bindRequested: '#EDAC00',
      referred: '#EDAC00',
      quoted: '#09A9ED',
      quoting: '#0462AA',
      blocked: '#e93d4c',
      ntu: '#818181',
      declined: '#A71E43',
      rejected: '#818181',
      cancelled: '#A71E43',
      expired: '#219480',
    },
    // Binder Management status colors
    bm: {
      status: {
        default: '#818181',
        live: '#2CC6AB',
        expired: '#818181',
        uploaded_bdx: '#09A9ED',
        processed_bdx: '#0462AA',
        pending_bdx: '#818181',
        expired_bdx: '#A71E43',
        published_bdx: '#2CC6AB',
        blocked_bdx: '#e93d4c',
      },
    },

    claimsTaskManagement: {
      status: {
        default: {
          main: '#818181',
          transparent: '#81818130',
        },
        pending: {
          main: '#818181',
          transparent: '#81818145',
        },
        actioned: {
          main: '#09A9ED',
          transparent: '#09A9ED45',
        },
        completed: {
          main: '#2CC6AB',
          transparent: '#2CC6AB45',
        },
        queried: {
          main: '#EDAC00',
          transparent: '#EDAC0045',
        },
        breached: {
          main: '#e93d4c',
          transparent: '#e93d4c45',
        },
        expires_today: {
          main: '#A71E43',
          transparent: '#A71E4345',
        },
        expires_tomorrow: {
          main: '#EDAC00',
          transparent: '#EDAC0045',
        },
      },
    },

    new: {
      light: '#904eff',
      main: '#5318e7',
      dark: '#0000b3',
      contrastText: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    tooltip: {
      bg: 'rgba(90, 90, 90, 0.96)',
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    placeholder: {
      color: '#b1b1b1',
    },
  },
  shadows: [
    'none',
    '0px 1px 2px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.05)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  typography: {
    fontSize: 16,
    pxToRem: (size) => `${size / 16}rem`,
    lineHeight: {
      '16/13': 1.2307692308,
      '17/13': 1.3076923077,
      '17/14': 1.2142857143,
      '18/13': 1.3846153846,
      '18/14': 1.2857142857,
      '19/13': 1.4615384615,
      '19/14': 1.3571428571,
      '20/11': 1.8181818182,
      '20/12': 1.6666666667,
      '20/13': 1.5384615385,
      '20/14': 1.4285714286,
      '26/15': 1.7333333333,
    },
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600, // Open Sans
    fontWeightBold: 700, // Open Sans
  },
  width: {
    xs: 420,
    sm: 520,
    md: 720,
    lg: 960,
    xl: 1280,
    xxl: 1920,
  },
  zIndex: {
    dropdown: 1600,
    loader: 1700,
  },
};

export default themeDefaults;
