import { makeStyles } from '@material-ui/core';

export const binderLimitsStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1',
  },
  table: {
    tableLayout: 'fixed',
    marginTop: '1rem',
  },
  tableCell: {
    border: 'none',
  },
  tableCell_Name: {
    border: 'none',
    paddingLeft: '5em !important',
  },

  buttonSkeleton: {
    transform: 'none',
  },
  rootLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 320,
  },
}));
