const styles = (theme) => ({
  searchResult: {
    marginTop: theme.spacing(3),
  },
  searchResultText: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.neutral.dark,
    marginBottom: 0,
  },
  row: {
    textDecoration: 'none',
  },
  rowSelected: {
    background: theme.palette.grey[100],
    cursor: 'default',
  },

  button: {
    marginLeft: theme.spacing(1.5),
  },
  rowNew: {
    ...theme.mixins.row.new,
  },
  rowNewSelected: {
    ...theme.mixins.row.newSelected,
  },
  dataCellLast: {
    borderTop: '1px solid #eeeeee',
  },
  riskLink: {
    textDecoration: 'none',
  },
});

export default styles;
