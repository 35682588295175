import { BDX_AGGREGATE_TYPE_PREMIUM } from 'consts';
import { useProcessAggregateManually } from 'lib/binderManagement';

export const useHandleSubmitPremiumManually = ({ aggregate, bdxDocumentsInfo }) => {
  const { mutate: processManually, isLoading: isSubmittingManually } = useProcessAggregateManually({
    type: BDX_AGGREGATE_TYPE_PREMIUM,
    facilityReference: bdxDocumentsInfo.facilityReference,
    yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
  });

  const handleSubmitManually = () => {
    processManually({
      requestId: aggregate?.requestId,
    });
  };

  return { handleSubmitManually, isSubmittingManually };
};
