export enum ConditionType {
  ALL = 'all of',
  ANY = 'any of',
}

export enum ColumnHeader {
  Name = 'NAME',
  Limit = 'LIMIT',
  AlertPercentage = 'ALERT (%)',
  Currency = 'CURRENCY',
  ValueToDate = 'VALUE TO DATE (VTD)',
  Empty = '',
}
