import { useQuery } from 'react-query';
import axios from 'axios';

import * as utils from 'utils';

const axiosInstance = axios.create({
  headers: { 'Content-type': 'application/json' },
});

export type TServices =
  | 'homev1'
  | 'homev2'
  | 'placement'
  | 'ctmv1'
  | 'ctmv2'
  | 'qbv1'
  | 'qbv2'
  | 'pmv1'
  | 'pmv2'
  | 'travel'
  | 'reportingv1'
  | 'reportingv2'
  | 'admin'
  | 'modelling'
  | 'clients';

interface IUseGetAvaliableAppsProps {
  email: string;
  app: TServices | null;
}

export const useGetAvaliableApps = ({ email, app }: IUseGetAvaliableAppsProps) =>
  useQuery({
    queryKey: ['getAvaliableApps', email, app],
    queryFn: async (): Promise<Record<TServices, boolean>> => {
      const appConfig = await utils.app.getConfig();
      if (!app) {
        return {} as Record<TServices, boolean>;
      }
      const response = await axiosInstance.post(appConfig.endpoint.avaliableApps, { email, app });
      return response.data;
    },
    // refresh every 2 minutes
    refetchInterval: 1000 * 60 * 2,
    refetchIntervalInBackground: true,
  });
