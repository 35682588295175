import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Mui
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  searchRoot: (props) => ({
    border: props.open ? `1px solid ${theme.palette.neutral.light}` : 'none',
    borderRadius: props.open ? 4 : 0,
    paddingRight: props.isEmpty && props.open ? '18px' : 0,
    marginRight: props.open ? 4 : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: props.open ? 'white' : 'transparent',
  }),

  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  searchButton: {
    borderLeft: `1px solid ${theme.palette.neutral.light}`,
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        padding: '5px 16px',
        border: 0,
      },

      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
        boxShadow: 'none',
      },
    },
  },
  close: {
    width: 16,
    height: 16,
    marginRight: 2,
  },
}));

export const SearchWithIcon = ({ size, placeholder, value, handleSearch }) => {
  const [open, setOpen] = useState();
  const [searchQuery, setSearchQuery] = useState(value);

  const classes = useStyles({ open: open, isEmpty: !searchQuery });

  const initial = {
    opacity: 0,
    width: '0%',
    transition: {
      duration: 0.2,
    },
  };

  const animate = {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 0.2,
    },
  };

  const exit = {
    opacity: 0,
    width: 0,
    transition: {
      duration: 0.2,
    },
  };

  useEffect(() => {
    setSearchQuery(value);
    if (value) {
      setOpen(true);
    }
  }, [value]);

  const handleSearchButtonClick = () => {
    setOpen(true);
    if (searchQuery) {
      handleSearch(searchQuery);
    }
  };

  const handleClickAway = () => {
    if (searchQuery === '') {
      setOpen(false);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    handleSearch('');
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setSearchQuery(e.target.value);
      handleSearch(e.target.value);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.searchRoot}>
        <IconButton
          data-testid="search-button"
          disableRipple
          size={size}
          onClick={handleSearchButtonClick}
          classes={{ root: searchQuery && open ? classes.searchButton : null }}
        >
          <SearchIcon />
        </IconButton>
        <AnimatePresence>
          {open && (
            <motion.div initial={initial} animate={animate} exit={exit} className={classes.search}>
              <TextField
                autoFocus={open}
                classes={{ root: classes.textField }}
                variant="outlined"
                placeholder={placeholder}
                value={searchQuery}
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
                data-testid="search-input"
              />

              <IconButton size={size} onClick={handleClearSearch} disableRipple>
                {searchQuery ? <CloseIcon classes={{ root: classes.close }} data-testid="clear-search" /> : null}
              </IconButton>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ClickAwayListener>
  );
};
