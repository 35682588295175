import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';

// BIND
export const useEndorsementQuoteResponse = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useEndorsementQuoteResponse',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));
    dispatch(hideModal());

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_ENDORSEMENTS, res.riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.bindEndorsementQuote.success', 'success'));
  };

  return useMutation(
    async ({ quoteId, ...formData }) => {
      const result = await quoteBindAxios.post(`/endorsements/${quoteId}/response`, formData);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
