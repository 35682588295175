const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  tableHeadCell: {
    backgroundColor: theme.palette.neutral.lighter,
    verticalAlign: 'middle',
    padding: theme.spacing(1),
  },
});

export default styles;
