import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonBase, Typography } from '@material-ui/core';

import { EdgeImage } from 'components';
import { useFacilityDetailsStyles } from './FacilityDetails.styles';
import { Skeleton } from '@material-ui/lab';

export const FacilityBinder = ({ title, url, pathname, onClick }) => {
  const classes = useFacilityDetailsStyles();

  const Content = url ? (
    <EdgeImage url={url} className={classes.facilityBindersImage} />
  ) : (
    <Typography variant="h5" className={classes.facilityBindersName}>
      {title}
    </Typography>
  );

  if (pathname) {
    return (
      <Link to={{ pathname }} className={classes.facilityBindersInfo}>
        {Content}
      </Link>
    );
  }
  if (onClick) {
    return (
      <ButtonBase onClick={onClick} className={classes.facilityBindersInfo}>
        {Content}
      </ButtonBase>
    );
  }
  return <div className={classes.facilityBindersInfoText}>{Content}</div>;
};

export const FacilityBinderSkeleton = ({ items = [70] }) => {
  const classes = useFacilityDetailsStyles();

  return (
    <div className={classes.facilityBindersWrapper}>
      {items.map((width, index) => (
        <div className={classes.facilityBindersInfoText} style={{ padding: '0 4px' }} key={index}>
          <Skeleton key={index} width={width} height={40} className={classes.facilityBindersName} />
        </div>
      ))}
    </div>
  );
};
