import { makeStyles } from '@material-ui/styles';

export const useReportStyles = makeStyles(() => ({
  report: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
}));
