import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { TransferList } from 'components/TransferList/TransferList';
import { RulesCustomList } from './RulesCustomList';
import { useGetRuleTemplates, useGetRulesets, useCreateRule } from 'lib/binderManagement';
import { Empty, FormActions, Button } from 'components';
import * as utils from 'utils';
import { hideModal } from 'stores';

const formatRules = (rules) => {
  return (
    rules.map((rule) => {
      return {
        ...rule,
        groupBy: `${rule.coverHolder} - ${rule.facilityReference}`,
      };
    }) || []
  );
};

const ImportRules = ({ bdxType, facility, ruleset, thirdPartyId }) => {
  const dispatch = useDispatch();
  const { data: ruleTemplates = [], isLoading, isError } = useGetRuleTemplates(bdxType);
  const {
    data: ruleSets,
    isFetching: isLoadingRulesets,
    isError: isErrorGetRules,
  } = useGetRulesets({
    bdxType,
    facility: facility?.umr,
  });

  const { mutateAsync: importRules, isSubmitting } = useCreateRule({ facility, bdxType, thirdPartyId });

  const [selectedRules, setSelectedRules] = useState([]);

  const handleUpdateAssignedData = useCallback((assignedData) => {
    setSelectedRules(assignedData);
  }, []);

  const currentRules = useMemo(() => {
    const { operationalClientName, umr } = facility;
    // Case when TPA ruleset is configured
    if (ruleset) {
      return ruleset.rules.map((rule) => ({ ...rule, groupBy: `${operationalClientName} - ${umr}`, disabled: true }));
    }
    // Case when TPA ruleset is not configured but requested
    if (thirdPartyId) {
      return [];
    }
    // Case when TPA ruleset is not requested. Use default ruleset
    return (
      ruleSets
        ?.find(({ thirdPartyId }) => !Boolean(thirdPartyId))
        ?.rules.map((rule) => {
          return {
            ...rule,
            groupBy: `${operationalClientName} - ${umr}`,
            disabled: true,
          };
        }) || []
    );
  }, [facility, ruleSets, ruleset, thirdPartyId]);

  if (isLoading || isLoadingRulesets) return <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />;
  if (isError || isErrorGetRules) return <Empty title={utils.string.t('app.error')} icon={<Loading />} width={300} />;

  const handleClose = () => {
    dispatch(hideModal());
  };

  const formattedRules = formatRules(ruleTemplates);

  const rightData = formattedRules
    .filter((rule) => {
      return !currentRules?.find((currentRule) => currentRule.id === rule.id || currentRule.ruleId === rule.ruleId);
    })
    .map((rule) => {
      const ruleCompare = {
        conditionType: rule.conditionType,
        conditions: rule.conditions,
        actions: rule.actions,
      };

      const isDisabled = currentRules?.some((currentRule) => {
        const currentRuleCompare = {
          conditionType: currentRule.conditionType,
          conditions: currentRule.conditions,
          actions: currentRule.actions,
        };

        return isEqual(ruleCompare, currentRuleCompare);
      });

      return {
        ...rule,
        disabled: isDisabled,
      };
    });

  const handleSaveRules = async () => {
    const rules = selectedRules.map((rule) => {
      const { groupBy, disabled, ...rest } = rule;

      return {
        ...rest,
      };
    });

    await importRules({ rules, action: 'IMPORT_RULES', ruleset });

    handleClose();
  };

  return (
    <div style={{ padding: 20 }}>
      <TransferList
        spacing={0}
        leftData={currentRules}
        rightData={rightData}
        leftTitle={utils.string.t('rules.assignedRules')}
        rightTitle={utils.string.t('rules.availableRules')}
        handleUpdateAssignedData={handleUpdateAssignedData}
        listComponent={RulesCustomList}
      />

      <FormActions type="dialog">
        <Button text={utils.string.t('app.cancel')} disabled={isSubmitting} onClick={handleClose} type="text" />
        <Button text={utils.string.t('app.save')} disabled={isSubmitting} type="submit" color="primary" onClick={handleSaveRules} />
      </FormActions>
    </div>
  );
};

export default ImportRules;
