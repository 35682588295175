export const resetClients = () => {
  return {
    type: 'CLIENTS_RESET',
  };
};

export const setPartyClient = (client) => {
  return {
    type: 'SET_PARTY_CLIENT',
    payload: {
      client: client,
    }
  };
};

export const resetPartyClient = () =>{
  return {
    type: 'PARTY_CLIENT_RESET',
  };
}