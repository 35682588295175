import { makeStyles } from '@material-ui/core';

export const useAddLineageMappingStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginBottom: '4rem',
  },
  submit: {
    marginTop: '2rem',
    float: 'right',
  },
  iconButton: {
    marginTop: '1rem',
    '&:hover': {
      background: 'transparent',
    },
  },
  icon: {
    margin: '1rem',
  },
  loader: {
    padding: '4rem',
    textAlign: 'center',
  },
}));
