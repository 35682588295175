import { makeStyles } from '@material-ui/styles';

export const useUploadLineageReportStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    marginTop: 0,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 345,
  },
}));
