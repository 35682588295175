import { useQuery } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { FACILITY_AGGREGATE_LIMITS, FACILITY_AGGREGATE_LIMITS_GROUP } from 'lib/quoteBind/queryKeys';

export const useGetAggregateLimits = (id, group = true, options) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.queries.useGetAggregateLimits',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [group ? FACILITY_AGGREGATE_LIMITS_GROUP : FACILITY_AGGREGATE_LIMITS, id],
    async () => {
      const aggregateLimitsUrl = group ? `facilities/groups/${id}/aggregate-limits` : `facilities/${id}/aggregate-limits`;
      const result = await quoteBindAxios.get(aggregateLimitsUrl);

      return result.data;
    },
    {
      onError: (err) => handleError(err),
      ...options,
    }
  );
};
