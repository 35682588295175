import React from 'react';
import { groupBy } from 'lodash';
import { AutoSizer } from 'react-virtualized';
import { firstBy } from 'thenby';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { TextField, InputAdornment, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Edge
import { Avatar, Tooltip } from 'components';
import VirtualList from 'components/TransferList/VirtualList';
import { ActionsPreview } from 'modules/Rules/components/RulePreview/ActionsPreview';
import { ConditionsPreview } from 'modules/Rules/components/RulePreview/ConditionsPreview';
import { useDebounce } from 'hooks';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  searchBox: {
    padding: theme.spacing(1, 2),
  },
  list: {
    flex: '1 1 100%',
    display: 'flex',
    height: 400,
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  avatarRoot: {
    marginRight: theme.spacing(1),
  },
  tooltip: {
    minWidth: 375,
  },
}));

const useRulePreviewStyles = makeStyles((theme) => ({
  root: {
    minWidth: 375,
  },
}));

const RulePreview = ({ rule }) => {
  const classes = useRulePreviewStyles();

  return (
    <div className={classes.root}>
      <ConditionsPreview conditionType={rule?.conditionType} conditions={rule?.conditions} />
      <ActionsPreview actions={rule?.actions} />
    </div>
  );
};

export const RulesCustomList = (props) => {
  const { title, items, handleToggle, checked, testid } = props;

  const classes = useStyles();
  const [listItems, setListItems] = React.useState(items.sort(firstBy(utils.sort.array('lexical', 'groupBy', 'asc')).thenBy('name')));
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchReset = () => {
    setSearch('');
  };

  React.useEffect(() => {
    setListItems((prevItems) => prevItems.sort(firstBy(utils.sort.array('lexical', 'groupBy', 'desc')).thenBy('name')));
  }, [items]);

  React.useEffect(() => {
    if (debouncedSearchTerm === '') {
      setListItems(items);
    } else {
      setListItems((prevItems) => {
        return prevItems.filter((item) => {
          return (
            item?.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.coverHolder?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.facilityReference?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item?.user?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
          );
        });
      });
    }
  }, [debouncedSearchTerm, items]);

  const groupedItems = groupBy(listItems, 'groupBy');
  const sectionIds = Object.keys(groupedItems);
  const sectionCounts = sectionIds.map((id) => groupedItems[id].length);

  function Item(props) {
    const { itemIndex, sectionIndex, isSectionHeader, style } = props;

    const groupName = sectionIds[sectionIndex];
    const item = groupedItems[groupName][itemIndex];

    if (isSectionHeader) {
      return (
        <ListSubheader>
          <Typography variant="subtitle1" style={{ padding: '14px', fontSize: '16px', fontWeight: 600 }}>
            {groupName}
          </Typography>
        </ListSubheader>
      );
    }

    const labelId = `transfer-list-all-item-${item.id}-label`;
    const isChecked = checked.indexOf(item) !== -1;
    const initials =
      utils.user.initials({
        fullName: item.userName,
      }) || '-';

    const primary = (
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography variant="body2" style={{ fontWeight: 600, fontSize: '14px', lineHeight: 1 }}>
          {item.name}
        </Typography>
      </div>
    );

    const secondary = item.lastUpdated ? (
      <div>
        <Typography variant="body">
          {utils.date.formatDateWithParameter(item.lastUpdated, 'MMM Do, YYYY')},{' '}
          {utils.date.formatDateWithParameter(item.lastUpdated, 'HH:mm')}
        </Typography>
      </div>
    ) : null;

    return (
      <ListItem
        key={item.id}
        role="listitem"
        button
        onClick={item?.disabled ? null : handleToggle(item)}
        style={style}
        data-testid={`list-item-${testid}`}
      >
        <ListItemIcon>
          <Checkbox
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
            color="primary"
            disabled={item?.disabled}
          />
        </ListItemIcon>
        <ListItemAvatar
          style={{
            marginRight: '16px',
            minWidth: '24px',
          }}
        >
          <Tooltip title={item.userName} placement="top">
            <Avatar text={initials} size={32} />
          </Tooltip>
        </ListItemAvatar>
        <ListItemText id={labelId} primary={primary} secondary={secondary} disabled={item?.disabled} />
        <Tooltip
          title={<RulePreview rule={item} />}
          placement="top"
          rich
          nestedClasses={{
            tooltip: classes.tooltip,
          }}
        >
          <VisibilityIcon data-testid="rule-preview" />
        </Tooltip>
      </ListItem>
    );
  }

  // eslint-disable-next-line no-func-assign
  Item = React.memo(Item);

  return (
    <Card data-testid={`transfer-list-card-${testid}`}>
      <CardHeader className={classes.cardHeader} title={title} />
      <Box className={classes.searchBox}>
        <TextField
          fullWidth
          value={search}
          onChange={(e) => handleSearchChange(e)}
          variant="outlined"
          placeholder="Search"
          InputProps={{
            endAdornment:
              search !== '' ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchReset} data-testid="reset-search">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Box>
      <Divider />
      <Box className={classes.list}>
        <AutoSizer>
          {({ width, height }) => (
            <VirtualList
              height={height}
              width={width}
              itemSize={64}
              sectionCounts={sectionCounts}
              component={List}
              handleToggle={handleToggle}
            >
              {Item}
            </VirtualList>
          )}
        </AutoSizer>
      </Box>
    </Card>
  );
};
//
