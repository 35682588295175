import { useMutation } from 'react-query';

// app
import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useDeleteWebSocketConnection = () => {
  const handleError = useHandleError({
    file: 'binderManagement.mutation.useDeleteWebSocketConnection',
  });

  return useMutation(
    async ({ sessionId }) => {
      const url = `session/${sessionId}`;
      binderManagementFileInputAxios.delete(url);
    },
    {
      onError: handleError,
    }
  );
};
