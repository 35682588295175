import { useState } from 'react';
// app
import { Button } from 'components';
import IssueCard from './IssueCard';
import styles from './QuoteIssues.styles';
import * as utils from 'utils';
import * as constants from 'consts';

// mui
import { makeStyles, Box, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export function QuoteIssuesView({
  riskIssues,
  quoteIssues,
  insuredSanctionsCheckResult,
  reInsuredSanctionsCheckResult,
  handleUpdateIssue,
  handleUpdateAllIssues,
  canCurrentUserDismissIssues,
  isWaiting,
}) {
  const classes = makeStyles(styles, { name: 'QuoteIssues' })();
  const [clickedDismissAll, setClickedDismissAll] = useState(false);
  const riskIssuesLength = riskIssues?.length || 0;
  const quoteIssuesLength = quoteIssues?.length || 0;
  const totalIssues = riskIssuesLength + quoteIssuesLength;

  const handleClickDismissAll = () => {
    setClickedDismissAll(true);
    handleUpdateAllIssues(constants.RISK_ISSUE_STATUS_PASSED);
  };

  return (
    <>
      <Typography variant="h3">{utils.string.t('risks.issues')}</Typography>
      {(riskIssues || quoteIssues) && totalIssues > 0 && (
        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.foundIssues}>
            <span className={classes.numIssues}>{totalIssues}</span> {utils.string.t('risks.issuesFound', { count: totalIssues })}
          </Typography>
          {canCurrentUserDismissIssues && quoteIssuesLength > 1 ? (
            <Button
              icon={HighlightOffIcon}
              size="xsmall"
              color="primary"
              text={utils.string.t('risks.dismissAllIssues')}
              onClick={handleClickDismissAll}
              disabled={clickedDismissAll || isWaiting}
            />
          ) : null}
        </Box>
      )}
      {riskIssues?.map((issue) => {
        return (
          <IssueCard
            key={issue.id}
            issue={issue}
            canCurrentUserDismissIssues={canCurrentUserDismissIssues}
            insuredSanctionsCheckResult={insuredSanctionsCheckResult}
            reInsuredSanctionsCheckResult={reInsuredSanctionsCheckResult}
            handleUpdateIssue={handleUpdateIssue}
          />
        );
      })}

      {quoteIssues?.map((issue) => {
        return (
          <IssueCard
            key={issue.id}
            issue={issue}
            canCurrentUserDismissIssues={canCurrentUserDismissIssues}
            handleUpdateIssue={handleUpdateIssue}
            isWaiting={isWaiting || clickedDismissAll}
          />
        );
      })}
    </>
  );
}
