import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import isString from 'lodash/isString';

// app
import styles from './FormToggle.styles';
import { ErrorMessage } from 'components';
import * as utils from 'utils';

// mui
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles, FormLabel } from '@material-ui/core';

FormToggleView.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.object,
  buttonGroupProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

FormToggleView.defaultProps = {
  buttonGroupProps: {},
};

export function FormToggleView({ control, name, value, defaultValue, options, label, hint, error, buttonGroupProps, type }) {
  const classes = makeStyles(styles, { name: 'FormToggle' })({
    hasError: Boolean(error),
    type,
  });

  if (control) {
    return (
      <div className={classes.root}>
        {label && (
          <FormLabel component="legend" className={classes.title}>
            {label}
          </FormLabel>
        )}
        <Controller
          control={control}
          id={name}
          name={name}
          value={value}
          defaultValue={defaultValue}
          render={({ field: { onChange, name, value } }) => {
            return (
              <ToggleButtonGroup
                id={name}
                name={name}
                value={isString(value) || utils.generic.isBoolean(value) ? value?.toString() : value}
                onChange={(e, value) => {
                  onChange(value);
                }}
                {...buttonGroupProps}
                aria-label={name}
                data-testid="toggle-button-group"
              >
                {options.map((option, index) => (
                  <ToggleButton key={index} value={option.value} className={classes.toggleButton}>
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            );
          }}
        />
        <ErrorMessage error={error} hint={hint} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {label && (
        <FormLabel component="legend" className={classes.title}>
          {label}
        </FormLabel>
      )}
      <ToggleButtonGroup {...buttonGroupProps} aria-label={name} data-testid="toggle-button-group">
        {options.map((option, index) => (
          <ToggleButton key={index} value={option.value} className={classes.toggleButton}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <ErrorMessage error={error} hint={hint} />
    </div>
  );
}
