import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { useHandleError } from '../../binderManagement.utils';

export const useUploadLineageDocument = () => {
  const handleError = useHandleError({
    file: 'binders.mutations.useUploadLineageDocument',
    warning: 'notification.binderManagement.documentUploadFail',
  });

  return useMutation<IFileUploadSecurity, AxiosError, { file: File }>(
    async ({ file }) => {
      const form = new FormData();
      form.append('file', file);

      const { data } = await binderManagementFileInputAxios.post('upload', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    },
    { onError: handleError }
  );
};
