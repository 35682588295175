import React, { useState, useEffect } from 'react';
import { groupBy } from 'lodash';

// app
import { FacilityUserCustomListView } from './FacilityUserCustomList.view';

export const FacilityUserCustomList = ({ title, items, checked, handleToggle }) => {
  const [listItems, setListItems] = useState(items);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search === '') {
      setListItems(items);
    } else {
      setListItems(
        items.filter(({ fullName }) => {
          return fullName.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [search, items]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchReset = () => {
    setSearch('');
  };

  const userGroups = groupBy(listItems, 'group');

  return (
    <FacilityUserCustomListView
      title={title}
      search={search}
      checked={checked}
      groups={Object.keys(userGroups)}
      items={Object.values(userGroups)}
      handlers={{
        handleToggle,
        handleSearchChange,
        handleSearchReset,
      }}
    />
  );
};
