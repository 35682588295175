import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// edge
import { useAssignToUnderwriterGroup } from 'lib/binderManagement';
import { Button, FormContainer, FormActions, FormGrid, FormFields, FormAutocompleteMui } from 'components';
import { BDX_TYPE_PREMIUM } from 'consts/binder-management';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

export const AssignToUnderwriterGroup = ({ selectedRowKeys, allUwGroups, bdxDocumentsInfo, handleClose, handleSuccess }) => {
  const classes = useStyles();
  const { mutateAsync: assignToUnderwriterGroup, isLoading } = useAssignToUnderwriterGroup({
    facilityReference: bdxDocumentsInfo.facilityReference,
    yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
    type: BDX_TYPE_PREMIUM,
  });

  const allUwGroupOptions = allUwGroups?.map((uwGroup) => ({
    label: uwGroup?.groupRef ? `${uwGroup.groupRef} - ${uwGroup.sectionName}` : uwGroup.sectionName,
    value: uwGroup.sectionKey,
  }));

  const defaultValues = {
    uwGroup: null,
  };

  const validationSchema = yup
    .object()
    .shape({
      uwGroup: yup.object().nullable().required(utils.string.t('validation.required')),
    })
    .required();

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onCancel = () => {
    handleClose();
  };

  const onSubmit = async (data) => {
    const uwGroupData = {
      uwGroup: data.uwGroup?.value,
      rowIds: selectedRowKeys,
    };

    await assignToUnderwriterGroup(uwGroupData);
    handleSuccess();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <FormContainer type="default" onSubmit={handleSubmit(onSubmit)} data-testid="form-assign-to-facility-group">
        {allUwGroups?.length ? (
          <FormFields type="default">
            <FormGrid container>
              <FormGrid item xs={12}>
                <FormAutocompleteMui
                  control={control}
                  name="uwGroup"
                  label={utils.string.t('form.assignToUwGroup.uwGroup')}
                  options={allUwGroupOptions}
                  error={errors.uwGroup}
                />
              </FormGrid>
            </FormGrid>
          </FormFields>
        ) : (
          <Typography variant="h2" style={{ fontWeight: 500, fontSize: 14 }}>
            {utils.string.t('form.assignToUwGroup.noUwGroups')}
          </Typography>
        )}

        <FormActions>
          <Button text={utils.string.t('app.cancel')} variant="text" disabled={formState.isSubmitting || isLoading} onClick={onCancel} />
          <Button
            text={utils.string.t('app.assign')}
            type="submit"
            disabled={formState.isSubmitting || isLoading || allUwGroups?.length === 0}
            color="primary"
          />
        </FormActions>
      </FormContainer>
    </div>
  );
};
