import { FC } from 'react';
import { Link } from 'react-router-dom';

import { TableCell, TableRow } from '@material-ui/core';

import { Status, Translate } from 'components';

import config from 'config';
import * as utils from 'utils';

import { useFacilitiesStyles } from './Facilities.styles';

export const FacilityTableListElement: FC<IFacilityTableListElementProps> = ({ facility, type, entity, statusBackgroundColor }) => {
  const classes = useFacilitiesStyles();

  return (
    <TableRow
      hover
      className={classes.row}
      component={Link}
      to={{
        pathname: `${config.routes.binders.root}/${type}/${entity.id}/${config.routes.binders.facilityDetails}/${facility.umr}`,
      }}
      data-testid={`facility-row-${facility.umr}`}
    >
      <TableCell data-testid={`facility-cell-umr-${facility.umr}`}>{facility.umr}</TableCell>
      <TableCell data-testid={`facility-cell-product-${facility.umr}`}>{facility.product}</TableCell>
      <TableCell data-testid={`facility-cell-gxbInstance-${facility.umr}`}>{facility.gxbInstance}</TableCell>
      <TableCell data-testid={`facility-cell-team-${facility.umr}`}>{facility.team}</TableCell>
      <TableCell data-testid={`facility-cell-inceptionDate-${facility.umr}`}>
        <Translate
          label="format.date"
          options={{ value: { date: facility.inceptionDate, format: config.ui.format.date.text, default: '-' } }}
        />
      </TableCell>
      <TableCell data-testid={`facility-cell-expiryDate-${facility.umr}`}>
        <Translate
          label="format.date"
          options={{ value: { date: facility.expiryDate, format: config.ui.format.date.text, default: '-' } }}
        />
      </TableCell>
      <TableCell data-testid={`facility-cell-status-${facility.umr}`}>
        {facility.status && (
          <Status
            size="xs"
            text={facility.status}
            status={facility.status}
            className={classes.tableChip}
            style={{
              backgroundColor: statusBackgroundColor,
              color: utils.color.contrast(statusBackgroundColor, 0.6),
            }}
            statusOverrides={{
              quoted: 'info',
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
