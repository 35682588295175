import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// mui
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';

// app
import { Empty, Form } from 'components';
import * as utils from 'utils';
import { useUploadRiskDocument } from 'lib/quoteBind';

RiskDocumentUpload.propTypes = {
  riskId: PropTypes.string,
  groups: PropTypes.array,
};

export function RiskDocumentUpload({ riskId, groups = [] }) {
  const [documentGroups, setDocumentGroups] = useState(groups);
  const [uuid, setUuid] = useState('');
  const { isLoading: isUploading, mutate: uploadRiskDocument } = useUploadRiskDocument();

  const clearOnBlur = () => {};

  const fileField = {
    type: 'file',
    name: 'files',
    placeholder: 'Select File ...',
    validation: Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
  };

  const autocompleteMuiField = {
    type: 'autocompletemui',
    name: 'group',
    label: utils.string.t('app.folder'),
    value: null,
    optionKey: 'id',
    optionLabel: 'label',
    optionsCreatable: true,
    clearOnBlur: clearOnBlur,
    validation: Yup.object().nullable(),
    hint: utils.string.t('app.folderHintText'),
  };

  const folderField = {
    ...autocompleteMuiField,
    options: groups?.map((f) => ({ value: f.id, label: f.label, id: f.id })) || [],

    muiComponentProps: {
      filterOptions: (options, params) => {
        const filtered = createFilterOptions()(options, params);

        if (params.inputValue !== '') {
          const exists = options?.some(({ label }) => label.toLowerCase() === params.inputValue.toLowerCase().trim());
          if (!exists) {
            filtered.push({
              id: params.inputValue,
              value: params.inputValue,
              label: `${utils.string.t('app.add')} "${params.inputValue}"`,
            });
          }
        }

        return filtered;
      },
    },
    onSelect: (newInputValue) => {
      setDocumentGroups([{ id: newInputValue, label: newInputValue }, ...documentGroups]);
    },
  };

  const fields = [fileField, folderField];
  const actions = [
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      disabled: isUploading,
      handler: () => {
        setUuid(new Date().getTime());
      },
    },
    {
      name: 'submit',
      label: utils.string.t('app.upload'),
      disabled: isUploading,
      handler: (data) => {
        uploadRiskDocument({ riskId, uploadData: data });
      },
    },
  ];

  if (isUploading) {
    return (
      <Box position="relative">
        <LinearProgress />
        <Empty title={utils.string.t('products.uploadInProgress')} icon={<Loading />} />
      </Box>
    );
  }

  return (
    <Form
      key={uuid}
      type="default"
      fields={fields}
      actions={actions}
      defaultValues={utils.form.getInitialValues(fields)}
      validationSchema={utils.form.getValidationSchema(fields)}
    />
  );
}
