import { useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';

//mui
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

// app
import { useWebSockets } from 'hooks';
import { Accordion, Empty } from 'components';
import { UploadBDXContext } from '../../../UploadBDX.context';
import { ReactComponent as BlockedRulesImage } from 'assets/svg/mapping-not-found.svg';
import { ProcessedRowsAccordion } from './ProcessedRowsAccordion';
import { useDeleteWebSocketConnection } from 'lib/binderManagement';
import { GetUnprocessedRowsTable } from './GetUnprocessedRowsTable';
import * as utils from 'utils';

const ProcessedRowsAccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
  },
}))(MuiAccordionDetails);

const handleAccordion = (expanded, setExpanded) => {
  setExpanded(!expanded);
};

export const SummaryTableCoverHolder = ({ toggleFullScreen, fullView, fullScreen }) => {
  const { validatedCoverHolderData } = useContext(UploadBDXContext);
  const [hasUnprocessedRowsData, setHasUnprocessedRowsData] = useState(false);
  const [unprocessedRowsData, setUnprocessedRowsData] = useState({
    headers: [],
    rows: [],
  });
  const [expanded, setExpanded] = useState(false);
  const { groupId } = validatedCoverHolderData || {};
  const client = useRef();
  const subscription = useRef();
  const { connectWebSocket } = useWebSockets();
  const { mutate: deleteWebSocketConnection } = useDeleteWebSocketConnection();

  const endpoint = useSelector((state) => get(state, 'config.vars.endpoint'));
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = getAccessTokenSilently();
  const progress =
    hasUnprocessedRowsData.rows?.length > 0 && hasUnprocessedRowsData.totalRows > 0
      ? (hasUnprocessedRowsData.rows?.length / hasUnprocessedRowsData.totalRows).toFixed(2) * 100
      : 0;

  useEffect(() => {
    if (expanded && !hasUnprocessedRowsData) {
      const { stompClient, sessionId } = connectWebSocket(`${endpoint.bm.fileProcessing}/ws/v1/bm?groupId=${groupId}`);
      client.current = stompClient;
      client.current.connect({ Authorization: `Bearer ${accessToken}` }, function (frame) {
        console.log('Connected: ' + frame);
        subscription.current = client.current.subscribe(`/topic/${sessionId}`, function (message) {
          let res = JSON.parse(message.body);

          if (progress === 100) {
            client.current.disconnect();
            client.current.unsubscribe(`/topic/${sessionId}`);
          }

          const headers = res[0]?.unprocessedColumns.map((col) => col.bdxColumnName);
          const newRows = res?.map((r) => r.unprocessedColumns.map((col) => col.value)) || [];

          setUnprocessedRowsData((prevData) => ({
            headers,
            rows: [...prevData.rows, ...newRows],
          }));
          setHasUnprocessedRowsData(true);
        });
      });
      return () => {
        subscription.current?.unsubscribe(`/topic/${sessionId}`);
        client.current?.disconnect();
        deleteWebSocketConnection({ sessionId });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, hasUnprocessedRowsData]);

  if (validatedCoverHolderData?.requestIds?.length === 0 && !groupId) {
    return <Empty width={400} title={utils.string.t('reporting.noAccess')} icon={<BlockedRulesImage />} padding data-testid={'empty'} />;
  }

  return (
    <>
      {validatedCoverHolderData.requestIds.map(({ requestId, facilityReference }) => (
        <ProcessedRowsAccordion
          id={requestId}
          groupId={validatedCoverHolderData.groupId}
          facilityReference={facilityReference}
          fullView={fullView}
          fullScreen={fullScreen}
          toggleFullScreen={toggleFullScreen}
          data-testid={'summary-accordion'}
          ProcessedRowsAccordionDetails={ProcessedRowsAccordionDetails}
          handleAccordion={handleAccordion}
        />
      ))}
      {groupId && (
        <Accordion
          icon={true}
          expanded={expanded}
          title={{ text: 'Unprocessed Lines', type: 'h2' }}
          style={{ margin: '10px' }}
          onChange={() => handleAccordion(expanded, setExpanded)}
          data-testid={'accordion-panel'}
        >
          <ProcessedRowsAccordionDetails>
            <GetUnprocessedRowsTable
              key={groupId}
              fullView={fullView}
              fullScreen={fullScreen}
              toggleFullScreen={toggleFullScreen}
              data={unprocessedRowsData}
              progress={progress}
              datatest-id={'unprocessed-rows-table'}
            />
          </ProcessedRowsAccordionDetails>
        </Accordion>
      )}
    </>
  );
};
