const styles = (theme) => ({
  formControlRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderRadius: '4px',
    '&> div.container': {
      gap: '6px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    '& > div.container > span': {
      backgroundColor: 'gray',
      padding: '1px 3px',
      borderRadius: '4px',
    },
  },
  iconButton: {
    padding: 0,
    paddingLeft: '0.5rem',
    color: theme.palette.neutral.main,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },
  fieldBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.neutral.medium}`,
    padding: theme.spacing(0.75, 1, 0.75, 1),
    flexGrow: 1,
    width: 'auto',
    '& :first-child': {
      flexGrow: 1,
    },
    '.MuiInputBase-root': {
      width: '100%',
    },

    '&:hover': {
      borderColor: theme.palette.neutral.dark,
    },
  },
  inputBase: {
    width: '-webkit-fill-available',
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      transition: theme.transitions.create('width'),
      borderBottom: `1px solid ${theme.palette.neutral.medium}`,
      width: '-webkit-fill-available',
      '&:focus': {
        width: 'auto',
        border: 0
      }
    },
  },
  chipList: {
    '& .MuiChip-root': {
      borderRadius: '5px !important',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      whiteSpace: 'nowrap',
      maxWidth: '15vw',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
});

export default styles;
