import { makeStyles } from '@material-ui/styles';

export const useSummaryTableStyles = makeStyles((theme) => ({
  root: (props) => ({
    ...theme.mixins.modal.dialog.root,
    display: 'grid',
    overflowY: 'hidden',
    margin: 'auto',
    alignItems: 'center',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',

    '& .MuiTableCell-stickyHeader': {
      backgroundColor: props.isUnprocessedRows ? '#3C383E' : '#334762',
      color: 'white',
      borderLeft: '1px solid black !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
      padding: '0.8rem',
    },
    '& .MuiTableCell-head': {
      verticalAlign: 'middle',
      textAlign: 'center !important',
      '&:last-child': {
        borderRight: '1px solid black !important',
      },
      '& span': {
        maxWidth: '100%',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        whiteSpace: 'nowrap !important',
      },
    },
    '& .MuiTableCell-root': {
      maxWidth: '10rem !important',
      padding: '0.8rem !important',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderBottomColor: '#eeeeee',
      '&:first-child': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderLeftColor: '#eeeeee',
      },
      '& span': {
        maxWidth: '100%',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        whiteSpace: 'nowrap !important',
      },
    },
  }),

  excelHeader: {
    minWidth: '150px !important',
    borderBottom: '1px #eeeeee black !important',
    backgroundColor: '#107C41 !important',
    color: 'white',
    '& span': {
      maxWidth: '150px !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
    },
  },
  matchedColumnName: {
    minWidth: '150px !important',
    borderBottom: '1px #eeeeee black !important',
    backgroundColor: '#107C41 !important',
    color: 'white',
    '& span': {
      maxWidth: '150px !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
    },
  },
  field: {
    fontWeight: 'bold',
  },
  container: (props) => ({
    maxHeight: props.fullView ? '100vh' : props.fullScreen ? '60vh' : '37vh',
    minHeight: '37vh',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  }),
  summaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  autoCompleteLabel: {
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap !important',
    paddingBottom: '1rem',
  },
}));

export const useAccordionStyles = makeStyles({
  accordion: {
    '& .Mui-expanded': {
      '& $svg': {
        transform: 'none !important',
      },
    },
    marginTop: '10px !important',
    marginBottom: '0px !important',
  },
});
