import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';
import { quoteBindAxios, QB_RISK_QUOTES } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const useRequestToBind = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useRequestToBind',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_QUOTES, res?.riskId);
    dispatch(enqueueNotification('notification.requestBindQuote.success', 'success'));
  };

  return useMutation(
    async ({ quoteId }) => {
      if (!quoteId) {
        throw new Error('Invalid data');
      }

      const path = `quotes/${quoteId}/request-to-bind`;

      const result = await quoteBindAxios.put(path);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
