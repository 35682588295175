import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_COVERHOLDER_FACILITY_FILTERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetCoverholderFacilityFilters = ({ coverHolderId }) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetCoverholderFacilityFilters',
  });

  return useQuery<ICoverholderFacilityFiltersResponse, AxiosError, ICoverholderFacilityFiltersDTO>(
    [FACILITY_COVERHOLDER_FACILITY_FILTERS, coverHolderId],
    async () => {
      if (!coverHolderId) return null;
      const result = await binderManagementFacilitiesAxios.get(`facilities/filter/coverHolders/${coverHolderId}`);
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
