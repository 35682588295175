import { createContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  BDX_TYPE_CLAIM,
  BINDER_LIMITS,
  BORDEREAUX,
  DOCUMENTS,
  REPORTS,
  RULES,
  TAB_BDX,
  TAB_BINDER_LIMITS,
  TAB_DOCUMENTS,
  TAB_REPORTS,
  TAB_RULES,
} from 'consts/binder-management';
import { useConfigContext } from 'config';
import { useLocationTabs } from 'hooks';
import { useGetBdxTypes, useGetFacility, useGetFacilityConfig, useGetFacilityUsers } from 'lib/binderManagement';
import { selectIsBroker, showModal } from 'stores';
import * as utils from 'utils';

import { getFacilityDetailsBreadcrumbs } from './FacilityDetails.utils';
import FacilityDetailsView from './FacilityDetails.view';

const INITIAL_FACILITY_CONTEXT = { facility: null, isLoading: false };

export const FacilityContext = createContext(INITIAL_FACILITY_CONTEXT);

const FacilityDetails = () => {
  const history = useHistory();
  const { facilityUMR, binderType, binderId } = useParams();
  const { data: facility, isLoading: isLoadingFacility } = useGetFacility({ umr: facilityUMR });
  const { data: facilityConfig, isLoading: isLoadingTPA } = useGetFacilityConfig({ umr: facilityUMR, bdxType: BDX_TYPE_CLAIM });
  const isBroker = useSelector(selectIsBroker);
  const config = useConfigContext();

  const noFacilityRiskCodes = facility?.riskCodes?.length === 0;

  const { data: facilityUserData, isLoading: isLoadingUserData } = useGetFacilityUsers({ umr: facilityUMR, enabled: isBroker });
  const { data: bdxTypes } = useGetBdxTypes(facility?.riskCodes?.map(({ code }) => code) || [], false, {
    enabled: !noFacilityRiskCodes,
  });

  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    const result = [
      {
        id: TAB_REPORTS,
        value: REPORTS,
        label: utils.string.t('binders.tabs.reports'),
      },
      {
        id: TAB_BDX,
        value: BORDEREAUX,
        label: utils.string.t('binders.tabs.bdx'),
        disabled: noFacilityRiskCodes,
      },
      {
        id: TAB_DOCUMENTS,
        value: DOCUMENTS,
        label: utils.string.t('binders.tabs.documents'),
      },
    ];

    if (config.features?.ENABLE_BINDER_LIMITS) {
      result.push({
        id: TAB_BINDER_LIMITS,
        value: BINDER_LIMITS,
        label: utils.string.t('binders.tabs.binderLimits'),
      });
    }

    if (isBroker) {
      result.push({
        id: TAB_RULES,
        value: RULES,
        label: utils.string.t('binders.tabs.rules'),
        disabled: noFacilityRiskCodes,
      });
    }

    return result;
  }, [isBroker, noFacilityRiskCodes, config]);

  const { selectedTab, handleSelectTab } = useLocationTabs(tabs);

  const getHandleEditUsers = (initialTab) => (e) => {
    e.stopPropagation();

    dispatch(
      showModal({
        component: 'EDIT_FACILITY_USERS',
        props: {
          title: 'binders.facilities.users',
          maxWidth: 'xl',
          disableAutoFocus: true,
          componentProps: {
            facilityUserData,
            facilityUMR,
            initialTab,
          },
        },
      })
    );
  };

  const getAssignedFacilityUsers = (users) =>
    users?.flatMap(({ users, market, client }) =>
      users.filter(({ facilityUMRs }) => facilityUMRs?.includes(facilityUMR)).map((user) => ({ ...user, group: market || client }))
    ) || [];

  const breadcrumbs = getFacilityDetailsBreadcrumbs({ facility, binderType, binderId, history });

  const handleOpenFacilityConfig = () => {
    dispatch(
      showModal({
        component: 'FACILITY_CONFIG',
        props: {
          title: 'binders.config.title',
          maxWidth: 'xl',
          fullWidth: true,
          disableAutoFocus: true,
          componentProps: {
            riskCodes: facility?.riskCodes,
            umr: facilityUMR,
          },
        },
      })
    );
  };

  return (
    <FacilityContext.Provider value={{ facility, binderId, isLoading: isLoadingFacility || isLoadingUserData || isLoadingTPA }}>
      <Helmet>
        <title>{[utils.string.t('binders.title'), facility?.coverHolder, facilityUMR].filter(Boolean).join(' - ')}</title>
      </Helmet>

      <FacilityDetailsView
        breadcrumbs={breadcrumbs}
        tabs={tabs}
        selectedTab={selectedTab}
        facilityUserData={facilityUserData}
        hasLimitsAlert={facility?.hasLimitsAlert}
        binderType={binderType}
        facilityConfig={facilityConfig}
        bdxTypes={bdxTypes}
        handlers={{
          handleSelectTab,
          getHandleEditUsers,
          getAssignedFacilityUsers,
          handleOpenFacilityConfig,
        }}
      />
    </FacilityContext.Provider>
  );
};

export default FacilityDetails;
