import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import { multipartQuoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification } from 'stores';
import { RISK_DOCUMENTS } from 'lib/quoteBind/queryKeys';

export const useUploadRiskDocument = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.uploadRiskDocument',
      message: 'API fetch error (useUploadRiskDocument)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.document.fail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(RISK_DOCUMENTS, data.risk_id);
    dispatch(enqueueNotification('notification.document.success', 'success'));
  };

  return useMutation(
    async ({ riskId, uploadData }) => {
      if (utils.generic.isInvalidOrEmptyArray(uploadData?.files)) {
        throw new Error('No files to upload');
      }

      let form = new FormData();

      if (!isEmpty(uploadData?.files)) {
        uploadData.files.forEach((file) => form.append('files', file));
      }

      const group = uploadData.group?.value || '';

      const params = group ? new URLSearchParams([['group', group]]) : null;

      return await multipartQuoteBindAxios.post(`/risks/${riskId}/documents`, form, { params });
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
