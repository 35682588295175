import { useEffect, useState } from 'react';

// app
import { AccordionView } from './Accordion.view';

export default function Accordion(props) {
  return <AccordionView {...props} />;
}

export const useAccordion = (numberOfElements) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (numberOfElements === 0) {
      setExpanded(false);
    }
  }, [numberOfElements]);

  const handleChange = (_, newExpanded) => {
    setExpanded(newExpanded);
  };

  return { expanded, handleChange };
};
