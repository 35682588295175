import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import isEmpty from 'lodash/isEmpty';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_DETAILS, QB_RISK_QUOTES } from 'lib/quoteBind';

// risks
export const usePostPreBindInfo = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.usePostPreBindInfo',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (riskId, result) => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, riskId);
    queryClient.invalidateQueries(QB_RISK_QUOTES, riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.preBindInfo.postSuccess', 'success'));

    return result;
  };

  return useMutation(
    async ({ riskId, body }) => {
      if (isEmpty(body) || !riskId) {
        throw new Error('Invalid params');
      }

      const result = await quoteBindAxios.post(`risks/${riskId}/pre-bind`, body);

      return { result, riskId };
    },
    {
      onSuccess: ({ riskId, result }) => handleSuccess(riskId, result),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
