import { makeStyles } from '@material-ui/core';

export const useRulesStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2.5),
  },
  bdxTypes: {
    display: 'flex',
    flex: '1',
  },
  loadingButton: {
    transform: 'none',
  },
  rootLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 320,
  },
}));
