const styles = (theme) => ({
  grid: {
    height: '100vh',
    backgroundColor: theme.palette.grey[50],
  },
  logo: {
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '320px',
    height: '100px',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  paper: {
    textAlign: 'center',
    width: `calc(100% - ${theme.spacing(8)}px)`,
    maxWidth: theme.width.xs,
    padding: theme.spacing(8),
    margin: '0 auto',
  },
  brandName: {
    fontWeight: 400,
    fontSize: '1.4rem',
    marginBottom: theme.spacing(2),
  },
});

export default styles;
