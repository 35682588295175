// app
import styles from './EditFacilityUsers.styles';

// mui
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { FACILITY_USERS_PRODUCERS, FACILITY_USERS_UNDERWRITERS } from 'consts';
import { FacilityUserTransferList } from './FacilityUserTransferList';
import * as utils from 'utils';

export const EditFacilityUsersView = ({ tabs, handlers, selectedTab, underwriters, producers }) => {
  const classes = makeStyles(styles, { name: 'EditFacilityUsers' })();

  return (
    <div className={classes.root}>
      <Tabs value={selectedTab} onChange={handlers.handleSelectTab}>
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} className={classes.tab} />
        ))}
      </Tabs>

      {selectedTab === FACILITY_USERS_PRODUCERS && (
        <FacilityUserTransferList
          data={producers}
          handleUpdate={handlers.updateFacilityProducers}
          role={utils.string.t('binders.users.producer')}
        />
      )}
      {selectedTab === FACILITY_USERS_UNDERWRITERS && (
        <FacilityUserTransferList
          data={underwriters}
          handleUpdate={handlers.updateFacilityUnderwriters}
          role={utils.string.t('binders.users.underwriter')}
        />
      )}
    </div>
  );
};
