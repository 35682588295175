import moment from 'moment';

const datetimeRegexp = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/;

export const parseDatetimeValue = (str) => {
  if (datetimeRegexp.test(str)) {
    return moment(str).format('DD-MM-yyyy hh:mm A');
  }
  return str;
};
