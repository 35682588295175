import { makeStyles } from '@material-ui/core';

export const useAddClaimBlockStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loader: {
    height: 416,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
