import { Card, CardContent, CardHeader } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useQuoteCardStyles } from './QuoteCard.styles';

export const QuoteCardSkeleton = ({ height = 'auto' }) => {
  const classes = useQuoteCardStyles();

  return (
    <Card data-testid="skeleton-quote-card" classes={{ root: classes.root }} style={{ height }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />

      <CardContent>
        <>
          <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={20} />
        </>
      </CardContent>
      <CardContent classes={{ root: classes.cardContentCommission }}>
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} />
      </CardContent>
    </Card>
  );
};
