import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import * as utils from 'utils';
import { RuleLine } from 'modules/Rules';
import { binderLimitPreviewStyles } from './BinderLimitPreview.styles';
import { splitString } from 'modules/BinderLimits/BinderLimits.utils';
import { ISumColumnPreviewProps, ISumColumnsPreviewProps } from 'forms/AddBinderLimit/AddBinderLimit.types';

export const SumColumnsPreview: FC<ISumColumnsPreviewProps> = ({ sumColumns }) => {
  const classes = binderLimitPreviewStyles();

  return (
    <Grid container className={classes.previewContainer}>
      <Grid item className={classes.previewHeader}>
        <Typography variant="subtitle1">{utils.string.t('binderLimits.sumColumns.statement')}</Typography>
      </Grid>
      {sumColumns?.map((action, index) => (
        <SumColumnPreview key={index} sumColumn={action} />
      ))}
    </Grid>
  );
};

const SumColumnPreview: FC<ISumColumnPreviewProps> = ({ sumColumn }) => {
  const classes = binderLimitPreviewStyles();
  return (
    <Grid container className={classes.operation}>
      <Grid item className={classes.ruleLineContainer}>
        <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.operationDescription}>
          <span className={classes.operationText}>{splitString(sumColumn)}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};
