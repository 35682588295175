import { Box, Typography } from '@material-ui/core';
import Web from '@material-ui/icons/Web';

import { BDXUploadCard } from './components/BDXUploadCard/BDXUploadCard';
import { useBDXOverviewStyles } from './useBDXOverviewStyles';
import { useContext } from 'react';
import { BDXExtractionContext } from '../../BDXExtraction.context';
import { BDXUploadCardSkeleton } from './components';

export const BDXOverview = ({ bdxItem, bdxInfo, bdxDocumentsInfo, uploadBDX, handleDownload }) => {
  const classes = useBDXOverviewStyles();
  const { isLoading } = useContext(BDXExtractionContext);

  return (
    <Box className={classes.root} data-testid="bdx-overview">
      <Box className={classes.titleBox}>
        <Web color="secondary" />
        <Typography variant="h2" classes={{ h2: classes.title }}>
          Overview
        </Typography>
      </Box>

      <Box display="flex" flex={1}>
        {isLoading ? (
          <BDXUploadCardSkeleton bdxItem={bdxItem} />
        ) : (
          <BDXUploadCard
            bdxItem={bdxItem}
            bdxInfo={bdxInfo}
            bdxDocumentsInfo={bdxDocumentsInfo}
            uploadBDX={uploadBDX}
            handleDownload={handleDownload}
          />
        )}
      </Box>
    </Box>
  );
};
