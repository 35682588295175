import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_MARKETS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetMarkets = ({ page = 0, size = 24, marketIds, brands, sortBy = 'marketName', sortAscending = true }, options) => {
  const params = {
    page,
    size,
    marketIds,
    brands,
    sortBy,
    sortAscending,
  };
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetMarkets',
  });

  return useQuery<IGetMarketsResponse, AxiosError, IGetMarketsDTO>(
    [FACILITY_BINDER_MARKETS, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get('markets', { params });
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      ...options,
    }
  );
};
