import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getHandleError } from 'lib/claimsTaskManagement/utils';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_SINGLE_FACILITY } from '../../binderManagement.queryKeys';

export const useGetFacility = ({ umr }) => {
  const handleError = getHandleError('binderManagement.facilities.queries.useGetFacility');
  return useQuery<IGetFacilityDTO, AxiosError, IFacility>(
    [FACILITY_BINDER_SINGLE_FACILITY, umr],
    async () => {
      const result = await binderManagementFacilitiesAxios.get(`facilities/${umr}`, {});
      return result?.data;
    },
    {
      onError: handleError,
    }
  );
};
