import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as MappingNotFound } from 'assets/svg/mapping-not-found.svg';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { Empty } from 'components';

import { BDX_AGGREGATE_TYPE_PREMIUM, BDX_TYPE_PREMIUM, PUBLISHED } from 'consts';
import {
  useGetBdxAggregate,
  useGetBdxPremiumData,
  useGetUnderwriterGroups,
  useGetUploadedBdx,
  useSubmitAllAggregates,
} from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { UnderWriterGroup } from './components/UnderWriterGroup';
import { getAssignToBlockComponent, ProcessingControls } from './components';
import { ProcessingContextProvider } from './Processing.context';
import { IProcessingProps } from './Processing.types';
import { getPremiumAggregateErrorMessage } from './utils';

import { useBDXProcessingStyles } from './Processing.styles';

export const PremiumProcessing: FC<IProcessingProps> = ({ bdxDocumentsInfo, tab }) => {
  const classes = useBDXProcessingStyles();
  const dispatch = useDispatch();
  const [hasUnallocatedPremiumData, setHasUnallocatedPremiumData] = useState(true);
  const [hasUnpublishedBDX, setHasUnpublishedBDX] = useState(true);

  const {
    data: uwGroups,
    isLoading,
    isError: isErrorUWGroups,
    refetch: refetchUWGroups,
  } = useGetUnderwriterGroups(
    {
      facilityReference: bdxDocumentsInfo.facilityReference,
    },
    { enabled: !!bdxDocumentsInfo.facilityReference }
  );

  const { data: uploadedBdx } = useGetUploadedBdx({ bdxDocumentsInfo });
  const {
    data: aggregates,
    isLoading: isLoadingAggregates,
    isFetching: isFetchingAggregates,
    isError: isErrorPremiumAggregate,
    error,
    refetch: refetchAggregates,
  } = useGetBdxAggregate<typeof BDX_AGGREGATE_TYPE_PREMIUM>({ ...bdxDocumentsInfo, type: BDX_AGGREGATE_TYPE_PREMIUM });
  const { data: unallocatedPremiumData, isFetched: isUnallocatedPremiumDataFetched } = useGetBdxPremiumData({
    bdxDocumentsInfo: {
      facilityReference: bdxDocumentsInfo.facilityReference,
      yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
      uwGroup: null,
    },
  });

  const { mutate: submitAllPremiumAggregates, isLoading: isSubmitting } = useSubmitAllAggregates({
    facilityReference: bdxDocumentsInfo.facilityReference,
    yearMonthSubmission: bdxDocumentsInfo.yearMonthSubmission,
    type: BDX_AGGREGATE_TYPE_PREMIUM,
  });

  useEffect(() => {
    const bdxSubmissions = utils.generic.isValidArray(uploadedBdx, true)
      ? uploadedBdx[0]?.items?.[0]?.items?.find((item) => item?.bdxType === BDX_TYPE_PREMIUM)?.submissions
      : [];
    setHasUnpublishedBDX(bdxSubmissions?.every((submission) => submission.status !== PUBLISHED));
  }, [uploadedBdx]);

  useEffect(() => {
    setHasUnallocatedPremiumData(isUnallocatedPremiumDataFetched && unallocatedPremiumData?.numberOfElements > 0);
  }, [unallocatedPremiumData, isUnallocatedPremiumDataFetched]);

  if (isLoading || isLoadingAggregates) {
    return <Empty width={340} title={utils.string.t('app.loading')} icon={<Loading />} padding />;
  }

  if (isErrorPremiumAggregate) {
    return <Empty width={340} title={getPremiumAggregateErrorMessage(error)} icon={<MappingNotFound />} padding />;
  }

  if (isErrorUWGroups) {
    return <Empty width={340} title={utils.string.t('products.filter.noResults')} icon={<SearchResultImage />} padding />;
  }

  const handleRefetch = () => {
    refetchAggregates();
    refetchUWGroups();
  };

  const showSubmitAllButton = aggregates?.some((item) => item.submissionStatus === 'NEW');
  const getHandleAssign = (selectedRowKeys: string[], handleSuccess: VoidFunction) => (event: Event) => {
    event.preventDefault();

    dispatch(
      showModal({
        component: 'ASSIGN_TO_UNDERWRITER_GROUP',
        props: {
          title: utils.string.t('form.assignToUwGroup.title'),
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            selectedRowKeys,
            allUwGroups: uwGroups,
            handleSuccess,
            bdxDocumentsInfo,
          },
        },
      })
    );
  };

  return (
    <ProcessingContextProvider
      hasUnallocatedClaimBlock={hasUnallocatedPremiumData}
      hasUnpublishedBDX={hasUnpublishedBDX}
      isAllSubmitting={isSubmitting}
      bdxDocumentsInfo={bdxDocumentsInfo}
    >
      <Box className={classes.root} data-testid="bdx-processing">
        <ProcessingControls
          tab={tab}
          refetch={handleRefetch}
          handleSubmitAll={submitAllPremiumAggregates}
          isRefetching={isFetchingAggregates}
          showSubmitAllButton={showSubmitAllButton}
        />

        {uwGroups?.map((uwGroup) => (
          <UnderWriterGroup
            key={uwGroup.sectionKey}
            uwGroup={uwGroup}
            getHandleAssign={getHandleAssign}
            aggregates={
              aggregates?.filter((item) => item.underwriterGroup?.trim()?.toUpperCase() === uwGroup.groupRef.trim()?.toUpperCase()) || []
            }
          />
        ))}
        <UnderWriterGroup
          uwGroup={{
            sectionKey: null,
            groupRef: '',
            sectionName: 'Unspecified / unallocated underwriter group',
            facilitySectionMarketClaimBlocksList: [],
            facilitySectionMarkets: [],
          }}
          AssignComponent={getAssignToBlockComponent('form.assignToUwGroup.title')}
          getHandleAssign={getHandleAssign}
        />
      </Box>
    </ProcessingContextProvider>
  );
};
