import { useQuery } from 'react-query';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_CLAIM_BLOCK } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetClaimBlocksProps {
  umr: string;
}

export const useGetClaimBlocks = ({ umr }: IUseGetClaimBlocksProps) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetClaimBlocks',
  });

  return useQuery<IClaimBlock[]>(
    [FACILITY_CLAIM_BLOCK, umr],
    async () => {
      const { data } = await binderManagementFacilitiesAxios.get(`claimblocks/${umr}`);
      return data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
