import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';

// app
import { DownloadBordereauxView } from './DownloadBordereaux.view';
import { selectIsProducer } from 'stores';
import {
  useGetClients,
  useGetProductsWithReports,
  useGetRiskFacilities,
  useDownloadRiskBordereaux,
  QB_CLIENTS,
  QB_PRODUCTS_WITH_REPORTS,
  QB_FACILITIES,
  LARGE_SIZE,
} from 'lib/quoteBind';
import * as utils from 'utils';

DownloadBordereaux.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default function DownloadBordereaux({ handleClose }) {
  const [productSelected, setProductSelected] = React.useState(null);
  const [, setDownloadTypeSelected] = React.useState();
  const userIsProducer = useSelector(selectIsProducer);

  const { data: clientsData } = useGetClients(
    QB_CLIENTS,
    { size: 1000, productCode: productSelected },
    { enabled: !!productSelected && !!userIsProducer }
  );
  const { data: productsWithReportsData, isLoading: riskProductsLoading } = useGetProductsWithReports(QB_PRODUCTS_WITH_REPORTS);
  const { data: riskFacilitiesData } = useGetRiskFacilities(
    QB_FACILITIES,
    '',
    { size: LARGE_SIZE },
    {
      enabled: !userIsProducer,
    }
  );

  const { mutate: downloadRiskBordereaux, isLoading: downloadIsLoading } = useDownloadRiskBordereaux();

  const riskFacilitiesItems = riskFacilitiesData?.content || [];
  const productsWithReports = productsWithReportsData?.data || [];
  const clients = React.useMemo(() => clientsData?.content || [], [clientsData]);

  const reportsOptions =
    get(
      productsWithReports.find((product) => product.value === productSelected),
      'reports'
    ) || [];

  const clientsOptions = clients.map((client) => ({
    id: client.id,
    name: client.name,
  }));

  const products = productsWithReports?.map((product) => ({
    value: product.value,
    label: product.label,
  }));

  const facilities = riskFacilitiesItems
    .filter((facility) => facility.productCode === productSelected)
    ?.map((facility) => ({
      ...facility,
      name: `${facility.name}${facility?.umr ? ` - ${facility.umr}` : ''}`,
    }));

  React.useEffect(() => {
    if (products?.length === 1) {
      setProductSelected(products[0].value);
    }
  }, [products]);

  const handleSubmit = (data) => {
    downloadRiskBordereaux({ ...data });
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const fields = [
    [
      {
        gridSize: { xs: 12 },
        id: 'product',
        name: 'product',
        transform: 'option',
        type: 'select',
        value: productSelected ? productSelected : products?.length === 1 && products[0]?.value ? products[0].value : '',
        options: products,
        label: utils.string.t('products.product'),
        muiComponentProps: {
          disabled: !utils.generic.isValidArray(products, true),
        },
        validation: Yup.string().required(utils.string.t('validation.required')),
        handleUpdate: (name, value) => setProductSelected(value),
      },
      {
        gridSize: { xs: 9 },
        id: 'type',
        name: 'type',
        type: 'select',
        value: reportsOptions?.length === 1 && reportsOptions[0]?.value ? reportsOptions[0].value : '',
        options: reportsOptions,
        label: utils.string.t('products.report'),
        handleUpdate: (name, value) => setDownloadTypeSelected(value),
        muiComponentProps: {
          disabled: !productSelected || !utils.generic.isValidArray(reportsOptions, true),
        },
        validation: Yup.string().required(utils.string.t('validation.required')),
      },
      {
        gridSize: { xs: 3 },
        id: 'onlyBound',
        name: 'onlyBound',
        title: utils.string.t('products.boundOnly'),
        type: 'checkbox',
        value: false,
        conditional: {
          conditional: true,
          conditionalField: 'type',
          conditionValue: ['DATA_DUMP', 'RISK_BDX'],
        },
      },
      userIsProducer
        ? {
            gridSize: { xs: 12 },
            id: 'clients',
            name: 'clients',
            transform: 'option',
            type: 'autocompletemui',
            value: [],
            options: clientsOptions,
            optionKey: 'id',
            optionLabel: 'name',
            label: clientsOptions?.length > 1 ? utils.string.t('app.producer_plural') : utils.string.t('app.producer'),
            disabled: !productSelected || !utils.generic.isValidArray(clientsOptions, true),
            muiComponentProps: {
              multiple: true,
            },
            validation: Yup.array()
              .of(Yup.mixed().required(utils.string.t('validation.required')))
              .min(1, utils.string.t('validation.required'))
              .required(utils.string.t('validation.required')),
          }
        : {
            gridSize: { xs: 12 },
            id: 'facility',
            name: 'facility',
            type: 'select',
            value: '',
            options: facilities,
            optionKey: 'id',
            optionLabel: 'name',
            label: utils.string.t('products.facility'),
            hint: utils.string.t('products.facilityHint'),
            muiComponentProps: {
              disabled: !productSelected || !utils.generic.isValidArray(facilities, true),
            },
            validation: Yup.string(),
          },
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'datepicker',
        icon: 'TodayIcon',
        name: 'from',
        label: utils.string.t('form.dateFrom.label'),
        value: null,
        muiComponentProps: {
          fullWidth: true,
        },
        muiPickerProps: {
          views: ['year', 'month'],
          format: 'MMMM YYYY',
        },
        validation: Yup.string()
          .nullable()
          .required(utils.string.t('form.dateFrom.required'))
          .test('from', utils.string.t('form.dateFrom.mustBeSameOrBeforeTo'), function (value) {
            return value && this.options.parent.to ? moment(value).isSameOrBefore(this.options.parent.to) : true;
          }),
      },
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'datepicker',
        name: 'to',
        label: utils.string.t('form.dateTo.label'),
        value: null,
        muiComponentProps: {
          fullWidth: true,
        },
        muiPickerProps: {
          views: ['year', 'month'],
          format: 'MMMM YYYY',
        },
        validation: Yup.string()
          .nullable()
          .required(utils.string.t('form.dateTo.required'))
          .test('from', utils.string.t('form.dateTo.mustBeSameOrAfterFrom'), function (value) {
            return value && this.options.parent.from ? moment(value).isSameOrAfter(this.options.parent.from) : true;
          }),
      },
    ],
  ];

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('app.download'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return <DownloadBordereauxView fields={fields} actions={actions} loading={riskProductsLoading} downloadIsLoading={downloadIsLoading} />;
}
