import { makeStyles } from '@material-ui/styles';

export const useUploadBDXActionsClasses = makeStyles(
  (theme) => ({
    actions: {
      display: 'flex',
      marginTop: 0,
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    actionsRight: {
      marginLeft: theme.spacing(1),

      '& > button': {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),

        '&:first-child': {
          margin: 0,
        },

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  }),
  { name: 'UploadBDXActions' }
);
