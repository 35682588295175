// mui
import { Grid, Box } from '@material-ui/core';

// app
import { useFacilityDetailsStyles } from './FacilityDetails.styles';
import { Restricted } from 'components';
import { ROLE_BROKER } from 'consts';
import * as utils from 'utils';
import { FacilityBinderSkeleton } from './FacilityBinder';
import { Skeleton } from '@material-ui/lab';
import { useFacilitiesStyles } from 'modules/Binders/Facilities/Facilities.styles';

export const FacilityDetailsSkeleton = () => {
  const classes = useFacilityDetailsStyles();
  const facilityClasses = useFacilitiesStyles();

  const InfoLine = () => (
    <Box className={facilityClasses.cardInfoLine}>
      <Skeleton className={facilityClasses.infoLeft} width="45%" />
      <Skeleton className={facilityClasses.infoRight} width="45%" />
    </Box>
  );

  return (
    <div className={classes.info}>
      <Grid container spacing={4} className={classes.facilityDetailsWrapper}>
        <Grid item className={classes.cardHeaderContent}>
          <Box className={classes.cardHeaderTitle}>
            <Skeleton className={classes.cardHeaderLogo} width={120} height={40} />
            <div>
              <Skeleton className={facilityClasses.cardHeaderTitle} width={150} />
              <Skeleton className={facilityClasses.cardHeaderSubtitle} width={150} />
            </div>
          </Box>
        </Grid>
        <Grid item className={classes.facilityCardWrapper}>
          <div className={`${classes.facilityBindersWrapper} ${classes.facilityCard}`}>
            <div className={classes.facilityCardContent}>
              <InfoLine />
              <InfoLine />
              <InfoLine />
              <InfoLine />
              <InfoLine />
            </div>
          </div>
        </Grid>
        <Grid item container spacing={2} className={classes.facilityBinders}>
          <Grid item container spacing={2}>
            <Grid item>
              {utils.string.t('binders.coverholders.title')}
              <div className={classes.facilityBindersWrapper}>
                <FacilityBinderSkeleton />
              </div>
            </Grid>
            <Grid item>
              {utils.string.t('app.office')}
              <div className={classes.facilityBindersWrapper}>
                <FacilityBinderSkeleton />
              </div>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item className={classes.facilityBindersContainer}>
              {utils.string.t('app.market_plural')}
              <div className={classes.facilityBindersWrapper}>
                <FacilityBinderSkeleton />
              </div>
            </Grid>
            <Grid item className={classes.facilityBindersContainer}>
              {utils.string.t('admin.thirdParties')}
              <div className={classes.facilityBindersWrapper}>
                <FacilityBinderSkeleton />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Restricted include={[ROLE_BROKER]}>
          <Grid item container spacing={2} className={classes.facilityBindersOptions}>
            <Grid item className={classes.facilityBindersContainer}>
              <div className={classes.facilityBindersUsers}>
                {utils.string.t('app.users')}
                <div className={classes.facilityBindersWrapper}>
                  <FacilityBinderSkeleton items={[80, 120]} />
                </div>
              </div>
            </Grid>
            <Grid item className={classes.facilityBindersContainer}>
              <div
                className={`${classes.facilityBindersWrapper} ${classes.facilityConfig} ${classes.facilityBindersInfo} ${classes.facilityConfigLoading}`}
              >
                <FacilityBinderSkeleton items={[160]} />
              </div>
            </Grid>
          </Grid>
        </Restricted>
      </Grid>
    </div>
  );
};
