import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';

import styles from './Rule.styles';
import { Accordion } from 'components';
import { RulePreview } from '../RulePreview';

export const Rule = ({ rule, bdxType, facility, handleFavourites, isLoading, handleDeleteRule, ruleset }) => {
  const classes = makeStyles(styles, { name: 'Rule' })();

  const handleClickFavourites = (e) => {
    e.stopPropagation();

    handleFavourites(rule, ruleset);
  };

  const Title = () => (
    <Grid container spacing={2} className={classes.ruleTitle}>
      <Grid item className={classes.nameWrapper}>
        <Typography variant="body1" className={classes.name}>
          {rule.name}
        </Typography>
      </Grid>
      <Grid item className={classes.icon}>
        <IconButton onClick={handleClickFavourites} size="small" data-testid="favorite" disabled={isLoading}>
          {rule?.ruleId ? <Star data-testid="star" /> : <StarBorder data-testid="starBorder" />}
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.rule}>
      <Accordion titleComponent={<Title />}>
        <RulePreview
          rule={rule}
          bdxType={bdxType}
          facility={facility}
          handleDeleteRule={handleDeleteRule}
          isLoading={isLoading}
          ruleset={ruleset}
        />
      </Accordion>
    </div>
  );
};
