import { makeStyles } from '@material-ui/core';

export const useFileUploadStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      padding: theme.spacing(2),
      flexDirection: 'column',
    },

    uploadFileFullScreen: {
      paddingTop: '5vh',
      paddingLeft: '2vh',
    },

    bdxFileLoader: {
      width: '100%',
      height: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headersOverNRows: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      top: '1rem',
    },
    headerOverLabel: {
      marginLeft: theme.spacing(1),
    },
    headerOverWrapper: {
      width: 64,
    },
    headerOverSelect: {
      minWidth: '64px!important',
    },
  }),
  { name: 'FileUpload' }
);
