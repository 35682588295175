import { FC, useContext } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Button } from 'components';

import { ProcessingContext } from '../Processing.context';
import { useBDXProcessingStyles } from '../Processing.styles';
import { IProcessingTabProps } from '../Processing.types';

interface IProcessingControlsProps {
  tab: IProcessingTabProps;
  refetch: VoidFunction;
  handleSubmitAll: VoidFunction;
  isRefetching?: boolean;
  showSubmitAllButton?: boolean;
}

export const ProcessingControls: FC<IProcessingControlsProps> = ({ tab, isRefetching, refetch, handleSubmitAll, showSubmitAllButton }) => {
  const classes = useBDXProcessingStyles();
  const { tooltipText, isAllSubmitting, isSubmitDisabled } = useContext(ProcessingContext);

  return (
    <Box className={classes.header}>
      <Box display="flex" alignContent="center">
        {tab.tabIcon}
        <Typography variant="h2" className={classes.title}>
          {tab.label}
        </Typography>
        <IconButton className={classes.refreshButton} disabled={isRefetching} onClick={refetch}>
          <RefreshIcon />
        </IconButton>
      </Box>

      {showSubmitAllButton && (
        <div>
          <Button
            variant="contained"
            color="primary"
            text="Submit all"
            onClick={handleSubmitAll}
            tooltip={{ title: tooltipText }}
            disabled={isAllSubmitting || isSubmitDisabled}
          />
        </div>
      )}
    </Box>
  );
};
