import { useQuery } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { FACILITY_GROUPS, FACILITY_GROUP_DETAIL, FACILITY_RATES, UNGROUPED_FACILITIES } from 'lib/quoteBind/queryKeys';

// risks
export const useGetFacilityGroups = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.queries.useGetFacilityGroups',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [FACILITY_GROUPS],
    async () => {
      const result = await quoteBindAxios.get(`/facilities/groups`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetFacilities = (id) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.queries.useGetFacilities',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [FACILITY_GROUP_DETAIL, id],
    async () => {
      const result = await quoteBindAxios.get(`/facility-group/${id}`);

      return result.data.sort(utils.sort.array('date', 'liveFrom', 'desc'));
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetUngroupedFacilities = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.queries.useGetFacilities',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [UNGROUPED_FACILITIES],
    async () => {
      const result = await quoteBindAxios.get(`/facilities/groups/unassigned`);

      return result.data.sort(utils.sort.array('date', 'liveFrom', 'desc'));
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};

export const useGetFacilityRates = (facilityId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.queries.useGetFacilityRates',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [FACILITY_RATES, facilityId],
    async () => {
      const result = await quoteBindAxios.get(`/rates?facilityId=${facilityId}`, {});

      return result.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
