import React from 'react';
import PropTypes from 'prop-types';

// app
import { Form } from 'components';
import * as utils from 'utils';

export const UploadFacilityDocumentView = ({ fields, actions }) => (
  <Form
    id="uploadFacilityDocument"
    type="dialog"
    fields={fields}
    actions={actions}
    defaultValues={utils.form.getInitialValues(fields)}
    validationSchema={utils.form.getValidationSchema(fields)}
  />
);

UploadFacilityDocumentView.propTypes = {
  fields: PropTypes.array,
  actions: PropTypes.array,
};
