import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { multipartAdminAxios } from 'lib/admin';
import { enqueueNotification, hideModal } from 'stores';
import { OPERATIONAL_MARKETS, OPERATIONAL_MARKET_DETAIL } from 'lib/admin/queryKeys';

export const useEditOperationalMarket = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSettled = (data) => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useEditOperationalMarket',
      message: 'API fetch error (admin.post)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.operationalMarketsPatchFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.invalidateQueries(OPERATIONAL_MARKETS);
    queryClient.invalidateQueries(OPERATIONAL_MARKET_DETAIL, data.id);

    dispatch(
      enqueueNotification('notification.admin.operationalMarketsPatchSuccess', 'success', {
        data: { marketName: data?.name },
      })
    );
  };

  return useMutation(
    async (requestData) => {
      const { newMarket, id } = requestData;

      if (!utils.generic.isValidObject(newMarket)) {
        throw new Error('Invalid operational market request body');
      }

      let form = new FormData();
      form.append('name', newMarket.name);

      if (!isEmpty(newMarket?.logo)) {
        newMarket.logo.forEach((file) => form.append('logo', file));
      }

      return await multipartAdminAxios.patch(`marketParent/${id}`, form);
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: handleSettled,
    }
  );
};
