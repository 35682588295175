import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { enqueueNotification } from 'stores';
import { QB_FACILITY_LIMITS } from 'lib/quoteBind/queryKeys';

export const usePostFacilityLimits = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.admin.mutations.usePostFacilityLimits',
      message: 'Data missing for facility limits.',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.generic.request', 'error'));
  };

  const handleSuccess = (res) => {
    queryClient.setQueryData([QB_FACILITY_LIMITS, res.facilityId], res);

    dispatch(enqueueNotification('notification.postFacilityLimits.success', 'success'));
  };

  return useMutation(
    async (data) => {
      const { facilityId, limitsId, formData = {} } = data;
      const { limits } = formData;

      if (!facilityId || !limits) {
        throw new Error('Invalid data');
      }

      let fieldLimits = [];

      limits?.forEach((obj) => {
        const index = fieldLimits.findIndex((item) => item.fieldName === obj['fieldName']);
        if (index > -1) {
          fieldLimits[index] = {
            ...fieldLimits[index],
            valueLimits: [
              {
                fieldValue: obj['limitFieldOptions']?.value,
                limit: obj['limit'],
                alertRate: obj['alert'],
                label: obj['limitFieldOptions'].label,
                quoteValidDays: obj['quoteValidDays'],
                countQuotedInLimits: obj['countQuotedInLimits'],
              },
              ...fieldLimits[index].valueLimits,
            ],
          };
        } else {
          fieldLimits.push({
            fieldName: obj['fieldName'],
            label: obj.label,
            qualifier: obj.qualifier,
            valueLimits: [
              {
                fieldValue: obj['limitFieldOptions']?.value,
                limit: obj['limit'],
                alertRate: obj['alert'],
                label: obj['limitFieldOptions'].label,
                quoteValidDays: obj['quoteValidDays'],
                countQuotedInLimits: obj['countQuotedInLimits'],
              },
            ],
          });
        }
      });

      const body = {
        ...(limitsId && { id: limitsId }),
        facilityId,
        fieldLimits,
      };

      const result = await quoteBindAxios.post(`limits?facilityId=${facilityId}`, body);
      return result.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};
