import React from 'react';
import PropTypes from 'prop-types';

// app
import { SearchView } from './Search.view';
import styles from './Search.styles';
import * as utils from 'utils';

// mui
import { makeStyles } from '@material-ui/core';

Search.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  submitButton: PropTypes.bool,
  handlers: PropTypes.shape({
    search: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    focus: PropTypes.func,
  }).isRequired,
  nestedClasses: PropTypes.shape({
    root: PropTypes.string,
  }),
};

Search.defaultProps = {
  submitButton: true,
  handlers: {},
};

export default function Search({ text, placeholder, submitButton, handlers, nestedClasses }) {
  const classes = makeStyles(styles, { name: 'Search' })();

  const handleClear = (reset) => (event) => {
    reset();

    if (utils.generic.isFunction(handlers.reset)) {
      handlers.reset();
    }
  };

  const handleSubmit = (values = {}) => {
    const { query } = values;

    if (query && text !== query) {
      return handlers.search(query);
    }
  };

  const fields = [
    {
      name: 'query',
      type: 'text',
      placeholder: placeholder || '',
      defaultValue: submitButton ? text || '' : '',
      onChange: submitButton
        ? undefined
        : (value) => {
            const searchTerm = value ? value.trim() : '';
            handlers.search(searchTerm);

            return value;
          },
      muiComponentProps: {
        autoComplete: 'off',
        ...(utils.generic.isFunction(handlers.focus) && {
          onFocus: () => {
            handlers.focus();
          },
        }),
        classes: {
          root: classes.input,
        },
        'data-testid': 'search-field',
      },
    },
  ];

  return (
    <SearchView
      query={text}
      fields={fields}
      submitButton={submitButton}
      handlers={{
        clear: handleClear,
        search: handleSubmit,
      }}
      nestedClasses={nestedClasses}
    />
  );
}
