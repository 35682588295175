// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Tooltip } from 'components';
import { useGetAllFacilityConfig, useGetBMThirdParties } from 'lib/binderManagement';

export const FacilityConfigWaring = ({ umr, bdxTypes }) => {
  const classes = useFacilityConfigWaringStyles();
  const allFacilityConfig = useGetAllFacilityConfig({
    umr,
    bdxTypes,
    options: {
      enabled: bdxTypes?.length && !!umr,
    },
  });
  useGetBMThirdParties();

  const isLoading = allFacilityConfig.some(({ isLoading }) => isLoading);
  const configurationNotSet = allFacilityConfig.filter(({ data }) => data?.notFound);

  if (isLoading) {
    return null;
  }

  if (configurationNotSet.length === 0) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mx={1}>
      <Tooltip
        title={<ConfigurationStatus allFacilityConfig={allFacilityConfig} bdxTypes={bdxTypes} rich />}
        nestedClasses={{
          tooltip: classes.tooltipRoot,
        }}
      >
        <WarningIcon className={classes.icon} data-testid="warning-icon" />
      </Tooltip>
    </Box>
  );
};

const useFacilityConfigWaringStyles = makeStyles((theme) => ({
  tooltipRoot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: 10,
    boxShadow: theme.shadows[2],
  },
  icon: {
    color: theme.palette.error.main,
  },
}));

const ConfigurationStatus = ({ allFacilityConfig, bdxTypes }) => {
  const classes = useConfigurationStatusStyles();

  return (
    <List className={classes.root}>
      {bdxTypes.map((bdxType, index) => {
        const label = allFacilityConfig[index].data?.notFound ? `${bdxType} configuration was not set` : `${bdxType} configuration was set`;

        return (
          <ListItem key={bdxType} dense>
            <ListItemIcon className={classes.icon}>
              {allFacilityConfig[index].data?.notFound ? (
                <WarningIcon className={classes.error} />
              ) : (
                <CheckCircleIcon className={classes.success} />
              )}
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        );
      })}
    </List>
  );
};

const useConfigurationStatusStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 9999,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 10,
    minWidth: 20,
  },
  error: {
    color: theme.palette.error.main,
    width: 20,
    height: 20,
    margin: 0,
  },
  success: {
    color: theme.palette.success.main,
    width: 20,
    height: 20,
  },
}));
