import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_CONFIG } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetFacilityConfig = ({ umr, bdxType, options }) => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetFacilityConfig',
  });

  return useQuery<IFacilityConfig>(
    [FACILITY_BINDER_CONFIG, umr, bdxType],
    async () => {
      if (!umr || !bdxType) {
        return null;
      }
      try {
        const { data } = await binderManagementFacilitiesAxios(`facility/${umr}/${bdxType}/config`);
        return data;
      } catch (error) {
        if (error.response.status === 404) {
          return {
            bdxType,
            notFound: true,
          };
        }

        throw Error(error);
      }
    },
    {
      onError: handleError,
      ...options,
    }
  );
};
