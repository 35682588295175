const styles = (theme) => ({
  rule: {
    width: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    '& > div': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: theme.spacing(2.5),
    },
  },
  ruleTitle: {
    alignItems: 'center',
    cursor: 'pointer',
  },
  nameWrapper: {
    display: 'flex',
    flex: 1,
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    '& svg': {
      transform: 'none!important',
    },
  },
  ruleSkeleton: {
    padding: theme.spacing(1, 0),
  },
});

export default styles;
