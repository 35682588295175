import { useEffect, useState } from 'react';

const DELAY = 500;

// Temporary Solution. TODO (added 3.11.2022): Refactor BDX Overview table to memo table renders
// Mount delay is needed to proper work of tabs navigation
// Even if data is loaded and cached rendering of 50+ columns is large task
export const useMountDelay = () => {
  const [mountDelay, setMountDelay] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setMountDelay(false), DELAY);

    return () => clearTimeout(timer);
  }, []);

  return mountDelay;
};
