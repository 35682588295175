import { AxiosError } from 'axios';

import * as utils from 'utils';

export const getPremiumAggregateErrorMessage = (error: AxiosError<IAPIError>) => {
  const errorMessages = error?.response.data.errors.map(({ message }) => message);

  if (errorMessages.includes('GROSS_AMOUNT_CANNOT_BE_NULL_EXCEPTION')) {
    return utils.string.t('notification.binderManagement.aggregate.premium.grossAmountNotMapped');
  }
  return utils.string.t('products.filter.noResults');
};
