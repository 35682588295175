import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// app
import styles from './Logo.styles';

// mui
import { makeStyles } from '@material-ui/core';

export function LogoView({ component, height, noLink, nestedClasses }) {
  const classes = makeStyles(styles, { name: 'Logo' })();
  const LogoComponent = component;

  if (noLink) return <LogoComponent className={classnames(nestedClasses, classes.root)} height={height} data-testid="edge-logo" />;

  return (
    <Link to="/" className={classes.logoLink}>
      <LogoComponent className={classnames(nestedClasses, classes.root)} height={height} data-testid="edge-logo" />
    </Link>
  );
}
