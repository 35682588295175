import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_COVERHOLDERS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetCoverholders = (
  { page = 0, size = 24, coverHolders, brands, sortBy = 'coverHolderName', sortAscending = true },
  options
) => {
  const params = {
    page,
    size,
    coverHolders,
    brands,
    sortBy,
    sortAscending,
  };
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetCoverholders',
  });

  return useQuery<IGetCoverHoldersResponse, AxiosError, IGetCoverHoldersDTO>(
    [FACILITY_BINDER_COVERHOLDERS, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get('coverholders', { params });
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      ...options,
    }
  );
};
