import { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';

import { binderLimits } from 'modules/BinderLimits/BinderLimits.utils';
import { bdxDetails } from 'modules/Binders/BDXExtraction/BDXExtraction';

import { useWebSockets } from 'hooks';
import { BDX_DATA, BDX_EXCEPTIONS_COUNT, BDX_REQUIRED_BDX, BDX_UPLOADED_DATA, DROOLS_LIMITSETS } from 'lib/binderManagement';

export default function useSectionWebSocket(facility, section) {
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = getAccessTokenSilently();
  const { connectWebSocket } = useWebSockets();
  const queryClient = useQueryClient();
  const endpoint = useSelector((state) => get(state, 'config.vars.endpoint'));
  const client = useRef();

  const returnMessage = useCallback(() => {
    if (facility?.umr) {
      const { stompClient, sessionId } = connectWebSocket(`${endpoint.bm.fileProcessing}/ws/v1/bm?facilityReference=${facility.umr}`);
      client.current = stompClient;
      stompClient.connect({ Authorization: `Bearer ${accessToken}` }, (frame) => {
        stompClient.subscribe(`/topic/${sessionId}`, (message) => {
          const returnMessage = JSON.parse(message.body);

          switch (section) {
            case binderLimits:
              queryClient.invalidateQueries([DROOLS_LIMITSETS, { facilityUMR: facility?.umr }]);
              break;
            case bdxDetails:
              queryClient.invalidateQueries([BDX_UPLOADED_DATA, returnMessage, { page: 0, size: 1000 }]);
              queryClient.invalidateQueries(BDX_DATA);
              queryClient.invalidateQueries([BDX_EXCEPTIONS_COUNT, returnMessage]);
              queryClient.invalidateQueries([BDX_REQUIRED_BDX, returnMessage.facilityReference]);
              break;
            default:
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.umr]);

  useEffect(() => {
    if (facility?.umr) {
      returnMessage();
    }

    return () => {
      client.current?.disconnect();
    };
  }, [returnMessage, facility?.umr]);
}
