import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { AddInsuredView } from './AddInsured.view';
import { selectPartyClient, selectSelectedProduct } from 'stores';
import { useGetClients, useGetRiskCountries, usePostInsured, QB_CLIENTS, RISK_COUNTRIES } from 'lib/quoteBind';
import { productAdminSchema } from 'schemas';
import * as utils from 'utils';

AddInsured.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func,
  reInsured: PropTypes.bool,
  isAddRiskForm: PropTypes.bool,
};

AddInsured.defaultProps = {
  handleClose: () => {},
  reInsured: false,
  isAddRiskForm: false,
};

export default function AddInsured({ submitHandler, handleClose, cancelHandler, reInsured, isAddRiskForm = false }) {
  const { data: clientsContent } = useGetClients(QB_CLIENTS, { size: 1000 });
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { mutateAsync: postInsured } = usePostInsured();

  const clients = useMemo(() => clientsContent?.content || [], [clientsContent]);
  const options = useMemo(() => ({ countries, clients }), [countries, clients]);

  const partyClientSelected = useSelector(selectPartyClient);
  const productCode = useSelector(selectSelectedProduct);

  const hydrateFields = (fields, isAddRiskForm) => {
    if (!fields) return [];

    return fields.map((field) => {
      const fieldOptions = field?.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options
        ? field.options.map((option) => ({ ...option, label: utils.string.t(option.label) }))
        : [];

      return {
        ...field,
        label: utils.string.t(field.label),
        ...(isAddRiskForm && { type: field?.hideInAddRiskForm ? 'hidden' : field.type }),
        ...(isAddRiskForm && field.name === 'productCode' && field?.hideInAddRiskForm && { value: productCode }),
        options: fieldOptions,
        ...(field?.optionsDefaultValue && {
          value: fieldOptions?.find((option) => option.default === true),
        }),
      };
    });
  };

  const handleSubmit = async (values) => {
    const response = await postInsured({
      body: values,
      isCreateInsuredModal: true,
      reInsured,
      isEdit: false,
    });

    const { data } = response?.result || {};

    if (data) {
      // success
      if (utils.generic.isFunction(handleClose)) {
        handleClose();
        return submitHandler(data);
      }
    }
    // fail
    handleCancel();
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(cancelHandler)) {
      cancelHandler();
    }
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const { fields } = productAdminSchema.getSchema('insureds');

  const hydratedFields = [hydrateFields(fields, isAddRiskForm)];

  utils.generic.isValidArray(hydratedFields, true) &&
    hydratedFields[0].map((field) => {
      if (field.name === 'clientId' && !isEmpty(partyClientSelected)) {
        field.value = partyClientSelected;
      }
      return field;
    });

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('app.submit'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  // abort
  if (utils.generic.isInvalidOrEmptyArray(options?.countries) || utils.generic.isInvalidOrEmptyArray(options?.clients)) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  return <AddInsuredView actions={actions} fields={hydratedFields} />;
}
