import classnames from 'classnames';

// MUI
import { LinearProgress, Step, StepLabel, Stepper } from '@material-ui/core';

import { UPLOAD_BDX_STEPS } from 'consts';

import { UploadBDXLoader } from '../components';
import { FieldMapping } from '../FieldMapping';
// EDGE
import { useUploadBDXStyles } from '../UploadBDX.styles';
import { UploadFile } from '../UploadFile';

import { SummaryCoverHolder } from './SummaryCoverHolder';

export default function UploadBDXCoverHolderView({
  file,
  steps,
  completed,
  progress,
  activeStep,
  isLoading,
  fullScreen,
  handlers,
  callbackOnSuccess,
  isTest,
  operationalClientId = null,
}) {
  const classes = useUploadBDXStyles();

  const getContent = () => {
    switch (activeStep) {
      case UPLOAD_BDX_STEPS.UPLOAD_FILE:
        return <UploadFile fullScreen={fullScreen} handlers={handlers} defaultFile={file} operationalClientId={operationalClientId} />;

      case UPLOAD_BDX_STEPS.FIELD_MAPPING:
        return <FieldMapping handlers={handlers} />;

      case UPLOAD_BDX_STEPS.SUMMARY:
        return (
          <SummaryCoverHolder
            fullScreen={fullScreen}
            handlers={handlers}
            callbackOnSuccess={callbackOnSuccess}
            isTest={isTest}
            operationalClientId={operationalClientId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classnames(classes.root, { [classes.rootModal]: !fullScreen })}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map(({ label, id }) => (
          <Step key={id} completed={completed[id]}>
            <StepLabel StepIconProps={{ classes: { root: classes.iconContainer } }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {isLoading ? (
        <LinearProgress className={classes.linearProgress} />
      ) : (
        <LinearProgress variant="determinate" value={progress} className={classes.linearProgress} />
      )}
      {isLoading ? <UploadBDXLoader /> : getContent()}
    </div>
  );
}
