import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// app
import { ConfirmDeleteView } from './ConfirmDelete.view';
import * as utils from 'utils';

ConfirmDelete.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default function ConfirmDelete({ submitHandler, handleClose, hint = null, isConfirm = false }) {
  const regexDelete = isConfirm ? new RegExp(`^${utils.string.t('app.cancel')}$`) : new RegExp(`^${utils.string.t('app.delete')}$`);
  const formConfirm = isConfirm ? 'form.cancelConfirm' : 'form.deleteConfirm';

  const fields = [
    {
      name: 'deleteConfirm',
      type: 'text',
      value: '',
      validation: Yup.string()
        .matches(regexDelete, utils.string.t(`${formConfirm}.required`))
        .required(utils.string.t(`${formConfirm}.required`)),
      label: utils.string.t(`${formConfirm}.label`),
      hint: hint ? hint : utils.string.t(`${formConfirm}.hint`),
      muiComponentProps: {
        autoComplete: 'off',
        autoFocus: true,
      },
    },
  ];

  const actions = [
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleClose,
    },
    {
      name: 'submit',
      label: utils.string.t('app.submit'),
      handler: () => {
        if (utils.generic.isFunction(submitHandler)) {
          return submitHandler();
        }
      },
    },
  ];

  return <ConfirmDeleteView fields={fields} actions={actions} />;
}
