import * as Yup from 'yup';

// app
import * as utils from 'utils';

const addressUpdateFields = [
  {
    name: 'name',
    valueName: 'city',
  },
  {
    name: 'street',
    valueName: 'formattedAddress',
  },
  {
    name: 'city',
    valueName: 'city',
  },
  {
    name: 'postCode',
    valueName: 'zip',
  },
  {
    name: 'county',
    valueName: 'county',
  },
  {
    name: 'state',
    valueName: 'state',
  },
  {
    name: 'country',
    valueName: 'country',
  },
];

const updateAddressFields = (address, updateValue, countries) => {
  if (addressUpdateFields) {
    addressUpdateFields.forEach((field) => {
      if (field.valueName === 'country') {
        const addressCountry = countries.find(
          (country) =>
            country?.label.toLowerCase() === address?.country?.toLowerCase() ||
            country?.value.toLowerCase() === address?.country?.toLowerCase()
        );

        updateValue(field?.name, addressCountry);
      } else updateValue(field?.name, address[field?.valueName || field.name]);
    });
  }
};

const schema = {
  operationalClientOffice: {
    key: 'operationalClientOffice',
    dependenciesLoaded: (options) => utils.schemas.checkDependencies(['countries'], options),
    items: null,
    fields: [
      {
        id: 'locationAutocomplete',
        name: 'locationAutocomplete',
        type: 'locationAutocomplete',
        muiComponentProps: {
          title: 'products.locationSearch.insuredTitle',
          buttonLabel: 'products.locationSearch.buttonLabel',
          placeholder: 'products.locationSearch.placeholder',
        },
        gridSize: { xs: 12, md: 12 },
        callback: updateAddressFields,
        hideForProducers: false,
      },
      {
        id: 'name',
        name: 'name',
        type: 'text',
        cellProps: {
          nowrap: true,
        },
        value: '',
        label: 'products.admin.insureds.tableCols.name',
        validation: Yup.string().required(() => utils.form.getValidationLabel('products.admin.insureds.tableCols.name', 'required')),
        gridSize: { xs: 12, sm: 6, md: 4 },
      },

      {
        id: 'street',
        name: 'street',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.street',
        gridSize: { xs: 12, sm: 6, md: 4 },
      },
      {
        id: 'city',
        name: 'city',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.city',
        gridSize: { xs: 12, sm: 6, md: 4 },
      },
      {
        id: 'postCode',
        name: 'postCode',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.zipCode',
        gridSize: { xs: 12, sm: 6, md: 4 },
      },
      {
        id: 'county',
        name: 'county',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.county',
        gridSize: { xs: 12, sm: 6, md: 4 },
      },
      {
        id: 'state',
        name: 'state',
        type: 'text',
        value: '',
        label: 'products.admin.insureds.tableCols.state',
        gridSize: { xs: 12, m: 6, md: 4 },
      },
      {
        id: 'country',
        name: 'country',
        type: 'autocompletemui',
        value: [],
        options: [],
        optionsDynamicKey: 'countries',
        label: 'products.admin.insureds.tableCols.country',
        gridSize: { xs: 12, m: 6, md: 4 },
      },
    ],
  },
};

const schemasEdgeAdmin = {
  getSchema: (key) => (schema[key] ? schema[key] : {}),
};

export default schemasEdgeAdmin;
