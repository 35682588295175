import * as utils from 'utils';

const initialState = {
  id: null,
  firstName: '',
  lastName: '',
  fullName: '',
  emailId: '',
  departmentIds: [],
  departments: [],
  departmentSelected: null,
  offices: [],
  programmesUserId: null,
  role: '',
  auth: {
    accessToken: null,
  },
  isAdmin: false,
  isReportAdmin: false,
  reportReadAll: false,
  error: '',
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_LOGIN':
      return {
        ...state,
        auth: {},
      };

    case 'AUTH_LOGOUT':
      return {
        ...state,
        auth: {},
      };

    case 'AUTH_IN_PROGRESS':
      return {
        ...state,
        auth: {
          inProgress: true,
        },
      };

    case 'AUTH_SUCCESS':
      return {
        ...state,
        auth: {
          ...action.payload,
          inProgress: false,
        },
      };

    case 'AUTH_FAILURE':
      return {
        ...state,
        auth: {},
      };

    case 'USER_GET_SUCCESS':
      return {
        ...state,
        ...action.payload,
        departmentIds: action.payload.departments?.map((department) => department.id) || [],
        departmentSelected: utils.user.department.getDefault(action.payload),
      };

    case 'USER_GET_FAILURE':
      return {
        ...initialState,
        error: action.payload.text || '',
      };

    case 'USER_SET_DEPARTMENT_SELECTED':
      return {
        ...state,
        departmentSelected: action.payload,
      };

    default:
      return state;
  }
};

export default userReducers;
