import { FC } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import * as utils from 'utils';

import { useDeductionStyle } from './Deductions.style';

interface IDeductionsProps {
  deductions: IBDXAggregateDeduction[] | ILineageDeduction[];
  settlementCurrency: string;
}

export const Deductions: FC<IDeductionsProps> = ({ deductions, settlementCurrency }) => {
  const classes = useDeductionStyle();

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {utils.string.t('bdx.processing.deductions')}
      </Typography>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>Deduction</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deductions.map((deduction, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {deduction.accountNumber}
                </TableCell>
                <TableCell>
                  {deduction?.percentage
                    ? utils.string.t('format.percent', {
                        value: { number: deduction.deductionPercentage },
                      })
                    : utils.string.t('format.currency', {
                        value: { number: deduction.deductionAmount, currency: settlementCurrency },
                      })}
                </TableCell>
                <TableCell>{deduction.deductionType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
