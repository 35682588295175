import { useQuery } from 'react-query';

import { binderManagementLineageAxios } from '../../binderManagement.api';
import { BDX_LINEAGE_SUMMARY } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IUseGetLineageSummaryProps {
  requestId: string;
  page?: number;
  size?: number;
}

export const useGetLineageSummary = ({ requestId, page = 0, size = 12 }: IUseGetLineageSummaryProps) => {
  const params = { page, size, requestId };
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetLineageSummary',
  });

  return useQuery<IPage<ILineageLine>>(
    [BDX_LINEAGE_SUMMARY, params],
    async () => {
      const result = await binderManagementLineageAxios.get('lineage/lines', { params });

      return result.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      enabled: !!requestId,
    }
  );
};
