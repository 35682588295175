const initialState = {
  binders: {
    facilities: { displayStyle: 'CARD', sortOptions: { by: 'status', sortType: 'lexical', direction: 'desc' } },
    coverholders: { displayStyle: 'CARD', sortOptions: { by: 'coverHolderName', sortType: 'lexical', direction: 'asc' } },
    markets: { displayStyle: 'CARD', sortOptions: { by: 'marketName', sortType: 'lexical', direction: 'asc' } },
    documents: { sortOptions: {} },
    bordereaux: { sortOptions: {} },
  },
  admin: {
    users: {
      sortOptions: {
        by: 'fullName',
        sortType: 'lexical',
        direction: 'asc',
      },
    },
  },
  'quote-bind': {
    'risk-list': {
      sortOptions: {
        by: 'id',
        direction: 'desc',
      },
    },
  },
  'claims-tasks': {
    'claims-tasks-list': {
      sortOptions: {
        by: 'emailReceivedDate',
        direction: 'DESC',
      },
    },
  },
};

const sortReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SORT_OPTIONS':
      return {
        ...state,
        [action.payload.category]: {
          ...state[action.payload.category],
          [action.payload.page]: {
            ...state[action.payload.category][action.payload.page],
            sortOptions: {
              by: action.payload.sortOptions.sortBy,
              direction: action.payload.sortOptions.sortDirection,
            },
          },
        },
      };
    case 'SET_DISPLAY_STYLE':
      return {
        ...state,
        [action.payload.category]: {
          ...state[action.payload.category],
          [action.payload.page]: { ...state[action.payload.category][action.payload.page], displayStyle: action.payload.displayStyle },
        },
      };
    case 'RESET_SORT':
      return {
        ...state,
        [action.payload.category]: { ...state[action.payload.category], [action.payload.page]: {} },
      };
    default:
      return state;
  }
};

export default sortReducers;
