import { useQuery } from 'react-query';

import { BDX_TYPE_PREMIUM } from 'consts/binder-management';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_FACILITY_AGGREGATES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetIfSubmissionExistsForFacility = ({ facilityReference, yearMonthSubmission, bdxType }, options) => {
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetAggregatesForFacility' });

  return useQuery(
    [BDX_FACILITY_AGGREGATES, facilityReference, yearMonthSubmission, bdxType],
    async () => {
      try {
        if (!facilityReference || !yearMonthSubmission || !bdxType) throw new Error('Missing required params');

        const type = bdxType === BDX_TYPE_PREMIUM ? 'premium' : 'claims';
        const path = `aggregate/${type}/facility/${facilityReference}/${yearMonthSubmission}/exists`;
        const result = await binderManagementBDXAxios.get(path);
        return result?.status === 204;
      } catch (ex) {
        return false;
      }
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      ...options,
    }
  );
};
