import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_PREMIUM_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBdxPremiumData = ({ bdxDocumentsInfo, page = 0, size = 12 }) => {
  const params = { uwGroup: bdxDocumentsInfo?.uwGroup, page, size };
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxPremiumData' });

  return useQuery(
    [BDX_PREMIUM_DATA, bdxDocumentsInfo, params],
    async () => {
      const { facilityReference, yearMonthSubmission } = bdxDocumentsInfo;
      if (!facilityReference || !yearMonthSubmission) throw new Error('Missing required params');

      const url = `premium/${facilityReference}/${yearMonthSubmission}`;

      const result = await binderManagementBDXAxios.get(url, { params });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
