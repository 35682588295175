import { createSelector } from 'reselect';
import get from 'lodash/get';

// app
import * as utils from 'utils';
import * as constants from 'consts';

export const selectRefDataLoaded = (state) => get(state, 'referenceData.loaded') || false;
export const selectBrands = (state) => get(state, 'referenceData.brands') || [];
export const selectSourceSystems = (state) => get(state, 'referenceData.sourceSystems') || [];
export const selectRefDataBusinessTypes = (state) => get(state, 'referenceData.businessTypes') || [];
export const selectRefDataCapacityTypes = (state) => get(state, 'referenceData.capacityTypes') || [];
export const selectRefDataCurrencies = (state) => get(state, 'referenceData.currencies') || [];
export const selectRefDataDepartments = (state) => get(state, 'referenceData.departments') || [];
export const selectRefDataUnderwriters = (state) => get(state, 'referenceData.underwriters') || [];
export const selectRefDataMarkets = (state) => get(state, 'referenceData.markets') || [];
export const selectRefDataClients = (state) => get(state, 'referenceData.clients') || [];
export const selectRefDataInsureds = (state) => get(state, 'referenceData.insureds') || [];
export const selectRefDataStatusesPlacement = (state) => get(state, 'referenceData.statuses.placement') || [];
export const selectRefDataStatusesPolicy = (state) => get(state, 'referenceData.statuses.policy') || [];
export const selectRefDataStatusesMarketQuote = (state) => get(state, 'referenceData.statuses.policyMarketQuote') || [];
export const selectRefDataNewRenewalBusinesses = (state) => get(state, 'referenceData.newRenewalBusinesses') || [];
export const selectRefDataAccountStatuses = (state) => get(state, 'referenceData.statuses.account') || [];
export const selectRefDataRationales = (state) => get(state, 'referenceData.rationales') || [];
export const selectRefDataDeclinatures = (state) => get(state, 'referenceData.declinatures') || [];

export const selectRefDataCountriesIso2 = (state) => {
  return utils.referenceData.countries.getOptionsIso2(get(state, 'referenceData.countries'));
};

// private
const _userDeptId = (state) => get(state, 'user.departmentSelected');

// private
const _userDepartment = createSelector(_userDeptId, selectRefDataDepartments, (departmentId, departments) => {
  if (!departmentId || !utils.generic.isValidArray(departments)) return;
  return departments.find((dept) => departmentId === dept.id);
});

export const selectRefDataDepartmentUsers = createSelector(_userDepartment, (department) => {
  return department && department.users ? department.users : [];
});

export const selectRefDataDepartmentBrokers = createSelector(selectRefDataDepartmentUsers, (users) => {
  return users.filter((user) => user.role === constants.ROLE_BROKER);
});

export const selectRefDataDepartmentById = (id) => {
  return createSelector(selectRefDataDepartments, (departments) => {
    return departments.find((dept) => {
      return dept.id === id;
    });
  });
};

export const selectRefDataBusinessTypeById = (deptId, businessTypeId) => {
  return createSelector(selectRefDataDepartments, (departments) => {
    const department = departments.find((dept) => {
      return dept.id === deptId;
    });

    if (department && department.businessTypes) {
      return department.businessTypes.find((bt) => bt.id === businessTypeId);
    }

    return null;
  });
};

export const selectRefDataStatusIdByCode = (type, code) => {
  let statusArray;

  switch (type) {
    case 'market':
      statusArray = selectRefDataStatusesMarketQuote;
      break;
    case 'policy':
      statusArray = selectRefDataStatusesPolicy;
      break;
    case 'placement':
      statusArray = selectRefDataStatusesPlacement;
      break;
    default:
      statusArray = () => [];
      break;
  }

  return createSelector(statusArray, (statuses) => {
    const status = statuses.find((status) => {
      return status.code === code;
    });

    return status && status.id;
  });
};

export const selectRefDataStatusKeyByCode = (type, code) => {
  let statusArray;

  switch (type) {
    case 'market':
      statusArray = selectRefDataStatusesMarketQuote;
      break;
    case 'policy':
      statusArray = selectRefDataStatusesPolicy;
      break;
    case 'placement':
      statusArray = selectRefDataStatusesPlacement;
      break;
    default:
      statusArray = () => [];
      break;
  }

  return createSelector(statusArray, (statuses) => {
    const status = statuses.find((status) => {
      return status.code === code;
    });

    return status && status.code ? utils.string.replaceLowerCase(status.code) : undefined;
  });
};

export const selectRefDataStatusKeyById = (type, id) => {
  let statusArray;

  switch (type) {
    case 'market':
      statusArray = selectRefDataStatusesMarketQuote;
      break;
    case 'policy':
      statusArray = selectRefDataStatusesPolicy;
      break;
    case 'placement':
      statusArray = selectRefDataStatusesPlacement;
      break;
    default:
      statusArray = () => [];
      break;
  }

  return createSelector(statusArray, (statuses) => {
    const status = statuses.find((status) => {
      return status.id === id;
    });

    return status && status.code ? utils.string.replaceLowerCase(status.code) : undefined;
  });
};

export const selectRefDataCurrencyById = (id) => {
  return createSelector(selectRefDataCurrencies, (currencies) => {
    return currencies.find((currency) => {
      return currency.id === id;
    });
  });
};

export const selectFormattedAccountStatusList = createSelector(selectRefDataAccountStatuses, (statusList) => {
  const mappings = {
    live: 'success',
    livee: 'success',
    livef: 'success',
    liveg: 'success',
    livel: 'success',
    runoff: 'alert',
    livencpnoclientmoneypermissions: 'alert',
    longtermrunoff: 'alert',
    restrictedc: 'alert',
    restrictedd: 'alert',
    restrictedh: 'alert',
    restrictedj: 'alert',
    restrictedk: 'alert',
    restrictedi: 'alert',
    closed: 'error',
    closedp: 'error',
    closedt: 'error',
    donotuse: 'error',
    liquidated: 'error',
    provisional: 'alert',
    sanctionsdonotuse: 'error',
  };
  const statusOrder = ['success', 'alert', 'error'];
  const statusWithType = statusList.map((status) => ({
    id: status.id,
    type: mappings[utils.string.replaceLowerCase(status.code, 'withDash')],
    code: utils.string.replaceLowerCase(status.code, 'withDash'),
    label: status.code,
  }));
  return statusWithType.sort((a, b) => statusOrder.indexOf(a.type) - statusOrder.indexOf(b.type));
});
