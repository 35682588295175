import { useQuery } from 'react-query';

import { RISK_QUOTE_STATUS_QUOTING } from 'consts';
// app
import { quoteBindAxios } from 'lib/quoteBind';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const useGetEndorsements = (riskId, options) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useGetEndorsementRiskData',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [QB_RISK_ENDORSEMENTS, riskId],
    async () => {
      const result = await quoteBindAxios.get(`endorsements/risk/${riskId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      refetchInterval: (data) => {
        const isQuoting = data?.some((quote) => quote.status === RISK_QUOTE_STATUS_QUOTING);
        return isQuoting ? 2000 : false;
      },
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      ...options,
    }
  );
};
