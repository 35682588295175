const styles = (theme) => ({
  defaultMenuItem: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: '-5px',

    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    margin: '0!important',
  },
  productName: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export default styles;
