import * as Yup from 'yup';

// app
import { AddEditOperationalThirdPartyView } from './AddEditOperationalThirdParty.view';
import * as utils from 'utils';
import { useCreateOperationalThirdParty, useEditOperationalThirdParty } from 'lib/admin';

export default function AddEditOperationalThirdParty({ handleClose, operational = {} }) {
  const { mutate: createOperationalThirdParty } = useCreateOperationalThirdParty();
  const { mutate: editOperationalThirdParty } = useEditOperationalThirdParty();

  const handleSubmit = (data) => {
    if (operational.id) {
      editOperationalThirdParty({ newData: data, id: operational.id });
    } else {
      createOperationalThirdParty(data);
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const fields = [
    {
      name: 'name',
      label: utils.string.t('admin.form.thirdParty.label'),
      type: 'text',
      value: operational?.name ? operational.name : '',
      innerComponentProps: {
        maxMenuHeight: 120,
        allowEmpty: true,
        'data-testid': 'parent',
      },
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      type: 'file',
      name: 'logo',
      label: utils.string.t('app.file'),
      placeholder: 'Select File ...',
      value: operational?.logo ? operational.logo : '',
      validation: operational.id ? Yup.mixed().nullable() : Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
    },
  ];

  const logo = utils.client.parent.getLogoFilePath(operational);

  const actions = [
    {
      name: 'submit',
      label: operational.id ? utils.string.t('app.save') : utils.string.t('app.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return <AddEditOperationalThirdPartyView actions={actions} fields={fields} logo={logo} />;
}
