import { useEffect, useState } from 'react';
import get from 'lodash/get';

import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, FormAutocompleteMui, FormCheckbox, FormDate, FormMultiTextField, FormSelect, FormText } from 'components';
import { RuleLine } from 'modules/Rules';

import * as utils from 'utils';

import { useConditionsStyles } from '../Conditions.styles';

const getSelectOptions = (options) =>
  options?.map((option, index) => ({
    label: option,
    id: index + 1,
  }));

export const Condition = ({ fields, methods, condition, prefix, handleRemoveCondition, ruleColumns }) => {
  const classes = useConditionsStyles();
  const [selectedField, setSelectedField] = useState(null);
  const [operands, setOperands] = useState({});

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
  } = methods;

  useEffect(() => {
    if (condition.field) {
      setSelectedField(condition.field);
      if (condition.operation) {
        const operands = condition.field.conditions.find((c) => c.condition === condition.operation);
        setOperands(operands);
      }
    }
  }, [condition]);

  useEffect(() => {
    const value = condition.field ? condition.caseInsensitive === false : operands.caseInsensitive === false;
    setValue(`${prefix}.caseInsensitive`, value);
  }, [operands]);

  useEffect(() => {
    if (!operands?.value1) {
      resetField(`${prefix}.value1`);
    }
    if (!operands?.value2) {
      resetField(`${prefix}.value2`);
    }
    if (!operands?.values) {
      resetField(`${prefix}.values`);
    }
    if (!operands?.column) {
      resetField(`${prefix}.column`);
    }
  }, [operands, prefix, resetField]);

  const onSelectColumnHeading = (selected) => {
    setSelectedField(selected);
    setValue(`${prefix}.operation`, null);
    setOperands({});
  };

  const onSelectComparator = (_, option) => {
    setOperands(selectedField.conditions.find(({ condition }) => condition === option));
  };
  const renderOperand = (field, name) => {
    if (field === 'DATE' || field.type === 'DATE') {
      return (
        <FormDate
          {...utils.form.getFieldProps(fields, 'dateValue')}
          testid={`field.${name}`}
          name={`${prefix}.${name}`}
          defaultValue={condition[name] || ''}
          rules={{ required: 'Required' }}
          control={control}
          error={get(errors, `${prefix}.${name}`)}
        />
      );
    }
    if (field === 'DATE_TIME' || field.type === 'DATE_TIME') {
      return (
        <FormDate
          {...utils.form.getFieldProps(fields, 'dateValue')}
          isDatetimePicker
          testid={`field.${name}`}
          name={`${prefix}.${name}`}
          defaultValue={condition[name] || ''}
          rules={{ required: 'Required' }}
          control={control}
          error={get(errors, `${prefix}.${name}`)}
        />
      );
    }
    if (field.type === 'LIST') {
      return (
        <FormSelect
          name={`${prefix}.value2`}
          testid={`field.${name}`}
          label={utils.string.t('rules.value2')}
          defaultValue={condition.value2 || ''}
          optionKey="label"
          optionLabel="label"
          rules={{ required: 'Required' }}
          options={getSelectOptions(field.list)}
          getOptionSelected={(option, value) => option.id === value.id}
          control={control}
          error={get(errors, `${prefix}.value2`)}
        />
      );
    }
    return (
      <FormText
        {...utils.form.getFieldProps(fields, name)}
        data-testid={`field.${name}`}
        name={`${prefix}.${name}`}
        defaultValue={condition[name] || ''}
        rules={{ required: 'Required' }}
        control={control}
        error={get(errors, `${prefix}.${name}`)}
      />
    );
  };

  return (
    <Grid container spacing={1} className={classes.condition}>
      <Grid item container spacing={1}>
        <Grid item className={classes.ruleLineContainer}>
          <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} />
        </Grid>
        <Grid item xs={2} data-testid="condition.columnHeading.wrapper">
          <FormAutocompleteMui
            name={`${prefix}.field`}
            label={utils.string.t('rules.columnHeading')}
            optionKey="name"
            optionLabel="name"
            options={ruleColumns || []}
            defaultValue={ruleColumns?.find(({ name }) => condition.field === name)}
            control={control}
            rules={{ required: 'Required' }}
            error={get(errors, `${prefix}.field`)}
            onSelect={onSelectColumnHeading}
            testid="condition.columnHeading"
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name={`${prefix}.operation`}
            control={control}
            label={utils.string.t('rules.condition')}
            defaultValue={condition.operation || ''}
            options={selectedField?.conditions || []}
            handleUpdate={onSelectComparator}
            optionKey="condition"
            optionLabel="condition"
            rules={{ required: 'Required' }}
            getOptionSelected={(option, value) => option.id === value.id}
            error={get(errors, `${prefix}.operation`)}
            disabled={!selectedField}
          />
        </Grid>
        {operands?.values && (
          <Grid item xs={2}>
            <FormMultiTextField
              {...utils.form.getFieldProps(fields, 'values')}
              name={`${prefix}.values`}
              defaultValue={condition.values || []}
              control={control}
              rules={{ required: 'Required' }}
              error={get(errors, `${prefix}.values`)}
              isPasteFromExcel
            />
          </Grid>
        )}
        {operands?.value1 && (
          <Grid item xs={2}>
            {renderOperand(operands.value1, 'value1', 'value')}
          </Grid>
        )}
        {operands?.value2 && <Grid item>{renderOperand(operands.value2, 'value2', 'value2')}</Grid>}
        {operands?.column && (
          <Grid item xs={2}>
            <FormAutocompleteMui
              name={`${prefix}.columnValue`}
              label={utils.string.t('rules.columnHeading')}
              optionKey="name"
              optionLabel="name"
              rules={{ required: 'Required' }}
              options={ruleColumns || []}
              control={control}
              error={get(errors, `${prefix}.columnValue`)}
              defaultValue={ruleColumns?.find(({ name }) => condition.column === name)}
            />
          </Grid>
        )}
        {operands?.caseInsensitive && (
          <Grid container xs={2} alignItems="flex-end">
            <FormCheckbox
              control={control}
              name={`${prefix}.caseInsensitive`}
              label={utils.string.t('rules.caseSensitive')}
              error={get(errors, `${prefix}.caseInsensitive`)}
            />
          </Grid>
        )}
      </Grid>

      <Grid item className={classes.deleteCondition}>
        <Button icon={DeleteIcon} color="primary" size="xsmall" onClick={handleRemoveCondition} data-testid="btn-delete-condition" />
      </Grid>
    </Grid>
  );
};
