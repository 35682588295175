import { useQuery } from 'react-query';

import * as utils from 'utils';

// app
import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_RULES_ROW_ACTIONS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetRuleRowActions = (params = {}) => {
  const { bdxType, facilityReference } = params;
  const handleError = useHandleError({ file: 'binderManagement.drools.queries.useGetRuleRowActions' });

  return useQuery(
    [DROOLS_RULES_ROW_ACTIONS, bdxType, facilityReference],
    async () => {
      if (!bdxType || !facilityReference) {
        return [];
      }
      const result = await binderManagementDroolsAxios.get(`/drools/row/actions`, {
        params: {
          bdxType,
          facilityReference,
        },
      });
      return result?.data?.map((action) => ({
        ...action,
        field: {
          type: utils.generic.isInvalidOrEmptyArray(action?.keyValueObjects) ? 'TEXT' : 'SELECT',
          action: action?.action,
          optionKey: 'value',
          optionLabel: 'label',
          options: action?.keyValueObjects?.map((keyValueObject) => ({
            value: keyValueObject.key,
            label: keyValueObject.value,
          })),
        },
      }));
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
