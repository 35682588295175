import { Chip, FormControl, makeStyles, InputLabel, InputBase, Box, IconButton } from '@material-ui/core';
import { Tooltip } from 'components';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import AssignmentIcon from '@material-ui/icons/Assignment';

import * as utils from 'utils';

import styles from './FormMultiTextField.styles';

FormMultiTextField.defaultProps = {
  muiComponentProps: {},
  compact: false,
  disabled: false,
};

export default function FormMultiTextField({
  control,
  name,
  value,
  defaultValue,
  muiComponentProps,
  disabled,
  label,
  hint,
  error,
  placeholder,
  compact,
  onChange,
  classes,
  children,
  isPasteFromExcel,
  rules,
}) {
  const componentClasses = makeStyles(styles, { name: 'FormMultiTextField' })();
  const [values, setValues] = useState(defaultValue);
  const [currValue, setCurrValue] = useState();

  const handleOnChange = onChange ? onChange : null;
  const fieldClasses = {
    ...muiComponentProps.classes,
    ...classes,
  };

  const props = {
    id: name,
    name,
    label,
    placeholder,
    disabled,
    rules,
    variant: 'outlined',
    ...muiComponentProps,
    InputLabelProps: {
      shrink: true,
      ...muiComponentProps.InputLabelProps,
    },
    margin: 'normal',
    fullWidth: typeof muiComponentProps.fullWidth !== 'undefined' ? muiComponentProps.fullWidth : true,
    classes: {
      ...fieldClasses,
      root: classnames(fieldClasses.root, componentClasses.root, { [componentClasses.compact]: compact }),
    },
  };

  const pasteFromExcel = async () => {
    const text = await navigator.clipboard.readText();
    const newValues = text.split('\n').slice(0, -1);
    setValues((oldstate) => [...oldstate, ...newValues]);
  };

  const handleKeyUp = (e) => {
    // eslint-disable-next-line eqeqeq
    if (e.keyCode == 13) {
      e.preventDefault();
      if (e.target.value !== '') {
        setValues((oldState) => [...oldState, e.target.value]);
        setCurrValue('');
        return [...values, e.target.value]
      }
    }
    return values;
  };

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    setValues(arr);
    return arr;
  };

  const helperTextProps = {
    error: Boolean(error),
    helperText: (error && error.message) || hint,
  };

  return (
    <FormControl classes={{ root: componentClasses.formControlRoot }}>
      {control ? (
        <Controller
          {...props}
          render={({field}) => (
            <Box component="form" sx={{ flexGrow: 1 }}>
              {label && (
                <InputLabel disabled={!!muiComponentProps.disabled} error={Boolean(error)} shrink variant="outlined" htmlFor={name}>
                  {label}
                </InputLabel>
              )}
              <div className={componentClasses.fieldBox}>
                <div className={componentClasses.chipList}>
                  {values.map((item, index) => (
                    <Chip
                      style={{ margin: '0.1rem', padding: '0.1rem' }}
                      size="small"
                      onDelete={() => {
                        let newVals = handleDelete(item, index);
                        field.onChange([...newVals]);
                      }}
                      label={item}
                    />
                  ))}
                  <InputBase
                    className={componentClasses.inputBase}
                    fullWidth
                    placeholder="Enter text here.."
                    onKeyDown={(e) => {
                      let newVals = handleKeyUp(e);
                      field.onChange([...newVals]);
                    }}
                    onChange={(e) => {
                      if (utils.generic.isFunction(handleOnChange)) e.target.value = handleOnChange(e.target.value, control);
                      handleChange(e);
                    }}
                    name={field.name}
                    value={currValue}
                    {...props}
                    {...helperTextProps}
                  />
                </div>
                {isPasteFromExcel && (
                  <div>
                    <Tooltip title="Paste from Excel">
                      <IconButton onClick={pasteFromExcel} className={componentClasses.iconButton}>
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Box>
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
          {...props}
          value={values}
        />
      ) : (
        <Box component="form" sx={{ flexGrow: 1 }}>
          {label && (
            <InputLabel disabled={!!muiComponentProps.disabled} error={Boolean(error)} shrink variant="outlined" htmlFor={name}>
              {label}
            </InputLabel>
          )}
          <div className={componentClasses.fieldBox}>
            <div className={componentClasses.chipList}>
              {values.map((item, index) => (
                <Chip
                  style={{ margin: '0.1rem', padding: '0.1rem' }}
                  size="small"
                  onDelete={() => handleDelete(item, index)}
                  label={item}
                />
              ))}
              <InputBase
                className={componentClasses.inputBase}
                fullWidth
                onKeyDown={handleKeyUp}
                onChange={(e) => {
                  if (utils.generic.isFunction(handleOnChange)) e.target.value = handleOnChange(e.target.value, control);
                  onChange(e);
                  handleChange(e);
                }}
                name={name}
                value={currValue}
                {...props}
                {...helperTextProps}
              />
            </div>
            {isPasteFromExcel && (
              <div>
                <Tooltip title="Paste from Excel">
                  <IconButton onClick={pasteFromExcel} className={componentClasses.iconButton}>
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </Box>
      )}
    </FormControl>
  );
}
