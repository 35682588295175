import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { ADMIN_BINDER_MANAGEMENT_USER } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBMUser = (userId) => {
  const handleError = useHandleError({
    file: 'admin.queries.useGetBMUser',
  });

  return useQuery(
    [ADMIN_BINDER_MANAGEMENT_USER, userId],
    async () => {
      if (!userId) throw new Error('Email Id is required');

      const result = await binderManagementFacilitiesAxios.get(`user/${userId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
