const styles = (theme) => ({
  tab: {
    borderTop: 0,
    borderRadius: 0,
  },
  tabs: {
    borderBottom: 0,
  },
});

export default styles;
