import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button, FormActions, FormAutocompleteMui, FormContainer, FormFields, FormGrid } from 'components';

import { useAssignToClaimBlock } from 'lib/binderManagement';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

interface IAssignToClaimBlockProps {
  selectedRowKeys: string[];
  claimBlocks?: IClaimBlock[];
  handleClose: VoidFunction;
  handleSuccess: VoidFunction;
}

export const AssignToClaimBlock: FC<IAssignToClaimBlockProps> = ({ selectedRowKeys, claimBlocks = [], handleClose, handleSuccess }) => {
  const classes = useStyles();
  const { mutateAsync: assignToClaimBlock, isLoading } = useAssignToClaimBlock();

  const allClaimBlocksOptions = claimBlocks?.map((claimBlock) => ({
    label: claimBlock.name,
    value: claimBlock.ucr,
  }));

  const defaultValues = {
    claimBlock: null,
  };

  const validationSchema = yup
    .object()
    .shape({
      claimBlock: yup.object().nullable().required(utils.string.t('validation.required')),
    })
    .required();

  const { control, handleSubmit, formState } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onCancel = () => {
    handleClose();
  };

  const onSubmit = async (data) => {
    const claimBlockData = {
      claimBlock: data.claimBlock?.value,
      rowIds: selectedRowKeys,
    };

    await assignToClaimBlock(claimBlockData);
    handleSuccess();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <FormContainer type="default" onSubmit={handleSubmit(onSubmit)} data-testid="form-assign-to-facility-group">
        <>
          {claimBlocks?.length ? (
            <FormFields type="default">
              <FormGrid container>
                <FormGrid item xs={12}>
                  <FormAutocompleteMui
                    control={control}
                    name="claimBlock"
                    label={utils.string.t('form.assignToClaimBlock.claimBlock')}
                    options={allClaimBlocksOptions}
                    error={errors.claimBlock}
                  />
                </FormGrid>
              </FormGrid>
            </FormFields>
          ) : (
            <Typography variant="h2" style={{ fontWeight: 500, fontSize: 14 }}>
              {utils.string.t('form.assignToClaimBlock.noClaimBlocks')}
            </Typography>
          )}

          <FormActions>
            <Button text={utils.string.t('app.cancel')} variant="text" disabled={formState.isSubmitting || isLoading} onClick={onCancel} />
            <Button
              text={utils.string.t('app.assign')}
              type="submit"
              disabled={formState.isSubmitting || isLoading || claimBlocks?.length === 0}
              color="primary"
            />
          </FormActions>
        </>
      </FormContainer>
    </div>
  );
};
