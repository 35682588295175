import { FC, useEffect, useMemo, useState } from 'react';

// MUI
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';

// Edge
import { Accordion, Avatar, Button, PopoverMenu, TabPanel, Tooltip } from 'components';
import { useAccordion } from 'components/Accordion/Accordion';

import { useDeleteBulk, useSubmitBdxLines } from 'lib/binderManagement';
import * as utils from 'utils';

import { UmrLinks } from './UmrLinks';
import { UnSubmittedBdxLines } from './UnSubmittedBdxLines';

interface IUnSubmittedBdxDataProps {
  umrs: string[];
  bdxType: TBDXType;
  facilities: IUnSubmittedRowsFacilities[];
  coverHolderId: string;
  bdxFile: IUnSubmittedRows;
}

export const UnSubmittedBdxData: FC<IUnSubmittedBdxDataProps> = ({ bdxFile, umrs, facilities, bdxType, coverHolderId }) => {
  const [selectedTab, setSelectedTab] = useState<string>(null);
  const { mutateAsync: submitBdxLines, isLoading: isSubmitting } = useSubmitBdxLines();
  const { mutateAsync: deleteBulk, isLoading: isDeleting } = useDeleteBulk();

  const isLoading = isSubmitting || isDeleting;

  const tabs = useMemo(
    () => [
      ...(bdxFile?.unprocessedRowCount
        ? [
            {
              label: 'Unmapped Lines',
              value: 'unmapped',
              requestId: null,
              groupId: bdxFile?.groupId,
            },
          ]
        : []),
      ...facilities.map((facility) => ({
        label: facility.facilityReference,
        value: facility.facilityReference,
        requestId: facility.requestId,
        groupId: bdxFile?.groupId,
      })),
    ],
    [bdxFile?.groupId, bdxFile?.unprocessedRowCount, facilities]
  );

  useEffect(() => {
    if (selectedTab === null && tabs?.length) {
      setSelectedTab(tabs[0].value);
    }
  }, [selectedTab, tabs]);

  const { expanded, handleChange } = useAccordion(facilities.length > 0);

  const handleChangeTab = (_, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    submitBdxLines({
      groupId: bdxFile?.groupId,
    });
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const popoverMenu = [
    {
      id: 'delete',
      label: 'Delete',
      callback: () => deleteBulk({ groupId: bdxFile?.groupId }),
    },
  ];

  return (
    <Accordion
      titleComponent={
        <Box display="flex" flex={1} flexDirection="row" alignItems="flex-start" justifyContent="space-between">
          <Box mr={2}>
            <Tooltip
              title={
                <Box>
                  <Typography variant="h5" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600, fontSize: 14 }}>
                    {`${bdxFile.uploadedBy} - ${utils.date.formatDateWithParameter(bdxFile.uploadedOn, 'DD MMM YYYY hh:mm A')}`}
                  </Typography>
                </Box>
              }
              placement="top"
              rich
            >
              <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-end">
                <Avatar
                  text={
                    utils.user.initials({
                      fullName: bdxFile.uploadedBy,
                    }) || '-'
                  }
                  size={32}
                />
              </Box>
            </Tooltip>
          </Box>

          <Box display="flex" flex={1} flexDirection="column" alignItems="flex-start" justifyContent="center">
            <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600 }}>
              {`${bdxFile.fileName} - ${bdxFile.sheetName}`}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start">
              <Typography style={{ marginBottom: 0, marginTop: 0, marginRight: 10, fontWeight: 500, fontSize: 14 }}>
                Unmapped Lines: <strong>{bdxFile?.unprocessedRowCount}</strong>
              </Typography>
            </Box>
          </Box>
          {bdxFile?.submitted ? null : (
            <Box display="flex" flex-flexDirection="row">
              <Tooltip title={bdxFile?.unprocessedRowCount > 0 ? 'Assign or dismiss all unmapped lines.' : 'Submit'} placement="top">
                <Button
                  color="primary"
                  size="small"
                  text="Submit"
                  icon={PublishIcon}
                  disabled={bdxFile?.unprocessedRowCount > 0 || isLoading}
                  onClick={(e) => handleSubmit(e)}
                  style={{
                    marginRight: 10,
                  }}
                />
              </Tooltip>
              <PopoverMenu
                id="menu-select"
                size="medium"
                items={popoverMenu}
                iconPosition="right"
                nestedClasses={{ label: { fontWeight: 600 } }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              />
            </Box>
          )}
        </Box>
      }
      icon={<ExpandMoreIcon />}
      expanded={expanded}
      onChange={handleChange}
      type="solid"
      margin={1}
      testid="file-group"
    >
      <Box style={{ padding: 0, width: '100%' }}>
        {bdxFile?.submitted ? (
          <UmrLinks facilities={facilities} coverHolderId={coverHolderId} bdxType={bdxType} bdxFile={bdxFile} />
        ) : (
          <>
            <Box position="relative" color="default" style={{ width: '100%' }}>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
              >
                {tabs.map((tab, index) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} {...a11yProps(index)} />
                ))}
              </Tabs>
            </Box>
            <Box>
              {tabs?.map((tab, index) => (
                <TabPanel key={tab.value} value={selectedTab} id={tab.value} index={index}>
                  <UnSubmittedBdxLines requestId={tab.requestId} groupId={tab.groupId} umrs={umrs} />
                </TabPanel>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Accordion>
  );
};
