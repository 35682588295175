// mui
import { Button, Chip, Divider } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { Accordion, FormGrid } from 'components';

import { FUZZY, NO_MATCH, PREVIOUS, PREVIOUS_BUT_NOT_CURRENT, REAL } from 'consts';
import { useMedia } from 'hooks';
import * as utils from 'utils';

import { HeaderSelect, ImportMapping, UploadBDXActions, UploadBDXContent } from '../components';

import { FieldItem } from './FieldItem';

// app
import { useFieldMappingStyles } from './FieldMapping.styles';

export const FieldMappingView = ({
  methods,
  fields,
  expanded,
  handleAccordion,
  handlers,
  isPopupOpen,
  inputEl,
  rowId,
  sheetName,
  isImportMappingOpen,
  isCoverHolderUpload,
  mappingRef,
}) => {
  const classes = useFieldMappingStyles();
  const isTablet = useMedia().tablet;

  const { fuzzyFields, reqFields, realFields, previousFields, matchedFields, missingImportedFields } = fields;

  return (
    <UploadBDXContent
      actions={
        <UploadBDXActions handleBack={handlers.handleBackClick} handleNext={handlers.handleNext} labelNext={utils.string.t('app.next')}>
          <>
            <Button
              ref={inputEl}
              onClick={() => {
                handlers.setIsPopupOpen(true);
              }}
              variant="contained"
              color="secondary"
            >
              {utils.string.t('form.bdx.selectRow')}
            </Button>
            {isPopupOpen && (
              <HeaderSelect
                isRowOpen={isPopupOpen}
                defaultRow={rowId + 1}
                handleClose={() => handlers.setIsPopupOpen(false)}
                reUpload={handlers.reUpload}
                inputEl={inputEl}
              />
            )}
            {!isCoverHolderUpload && (
              <Button
                ref={mappingRef}
                onClick={() => {
                  handlers.setImportMappingOpen(true);
                }}
                variant="contained"
                color="secondary"
                style={{ marginLeft: '1rem' }}
              >
                {utils.string.t('form.bdx.importMapping')}
              </Button>
            )}
            {isImportMappingOpen && (
              <ImportMapping
                isRowOpen={isImportMappingOpen}
                handleClose={() => handlers.setImportMappingOpen(false)}
                handleImport={handlers.handleImport}
                inputEl={mappingRef}
              />
            )}
          </>
        </UploadBDXActions>
      }
    >
      <div className={classes.root} data-testid="fieldmapping-grid">
        <h2 className={classes.sheetName}>
          {utils.string.t('form.bdx.sheet')}: {sheetName}
        </h2>
        {previousFields.length > 0 && (
          <Accordion
            icon
            secondaryIcon={
              <Chip
                size="small"
                style={{ backgroundColor: '#2CC6AB', color: '#FFFFFF', marginLeft: '5px' }}
                label={previousFields.length}
              />
            }
            expanded={expanded.includes(PREVIOUS)}
            title={{ text: 'Previously Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(PREVIOUS)}
            testid="previous"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {previousFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={previousFields[index]}
                  list={previousFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {previousFields.length > 0 && (realFields.length > 0 || reqFields.length > 0) && <Divider />} {/* ? */}
        {realFields.length > 0 && (
          <Accordion
            icon
            secondaryIcon={
              <Chip size="small" style={{ backgroundColor: '#2CC6AB', color: '#FFFFFF', marginLeft: '5px' }} label={realFields.length} />
            }
            expanded={expanded.includes(REAL)}
            title={{ text: 'Fully Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(REAL)}
            testid="real"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {realFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={realFields[index]}
                  list={realFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {realFields.length > 0 && (missingImportedFields.length > 0 || fuzzyFields.length > 0 || reqFields.length > 0) && <Divider />}
        {missingImportedFields.length > 0 && (
          <Accordion
            icon
            secondaryIcon={
              <Chip
                size="small"
                style={{ backgroundColor: '#edac00', color: '#FFFFFF', marginLeft: '5px' }}
                label={missingImportedFields.length}
              />
            }
            expanded={expanded.includes(PREVIOUS_BUT_NOT_CURRENT)}
            title={{ text: 'Lost Matches on Import', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(PREVIOUS_BUT_NOT_CURRENT)}
            testid="imported"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {missingImportedFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={missingImportedFields[index]}
                  list={missingImportedFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {missingImportedFields.length > 0 && (fuzzyFields.length > 0 || reqFields.length > 0) && <Divider />}
        {fuzzyFields.length > 0 && (
          <Accordion
            icon
            secondaryIcon={
              <Chip size="small" style={{ backgroundColor: '#edac00', color: '#FFFFFF', marginLeft: '5px' }} label={fuzzyFields.length} />
            }
            expanded={expanded.includes(FUZZY)}
            title={{ text: 'Partially Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(FUZZY)}
            testid="fuzzy"
          >
            <>
              <Button
                size="small"
                startIcon={<RotateLeftIcon />}
                onClick={() => handlers.clearFields(fuzzyFields)}
                className={classes.clearButton}
                variant="contained"
                data-testid="reset-button"
              >
                {utils.string.t('form.bdx.clearAllValues')}
              </Button>
              <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
                {fuzzyFields.map((field, index) => (
                  <FieldItem
                    key={field.name}
                    item={fuzzyFields[index]}
                    list={fuzzyFields}
                    matchedFields={matchedFields}
                    methods={methods}
                    classes={classes}
                  />
                ))}
              </FormGrid>
            </>
          </Accordion>
        )}
        {fuzzyFields.length > 0 && reqFields.length > 0 && <Divider />}
        {reqFields.length > 0 && (
          <Accordion
            icon
            secondaryIcon={<Chip size="small" style={{ marginLeft: '5px' }} label={reqFields.length} />}
            expanded={expanded.includes(NO_MATCH)}
            title={{ text: 'Unmatched', type: 'h2' }}
            onChange={handleAccordion(NO_MATCH)}
            testid="nomatch"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {reqFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={reqFields[index]}
                  list={reqFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
      </div>
    </UploadBDXContent>
  );
};
