export const QB_QUOTE = 'QB_QUOTE';

export const RISK_DOCUMENTS = 'RISK_DOCUMENTS';
export const RISK_DOCUMENTS_DOWNLOAD = 'RISK_DOCUMENTS_DOWNLOAD';

// QB Admin Queries
export const FACILITY_GROUPS = 'FACILITY_GROUPS';
export const FACILITY_GROUP_DETAIL = 'FACILITY_GROUP_DETAIL';
export const FACILITY_AGGREGATE_LIMITS = 'FACILITY_AGGREGATE_LIMITS';
export const FACILITY_AGGREGATE_LIMITS_GROUP = 'FACILITY_AGGREGATE_LIMITS_GROUP';
export const FACILITY_RATES = 'FACILITY_RATES';
export const FACILITY_RATE_LIMITS = 'FACILITY_RATE_LIMITS';
export const QB_FACILITY_LIMIT_VALUES = 'QB_FACILITY_LIMIT_VALUES';
export const QB_FACILITY_LIMITS = 'QB_FACILITY_LIMITS';
export const QB_FACILITY_LIMITS_DEF = 'QB_FACILITY_LIMITS_DEF';
export const UNGROUPED_FACILITIES = 'UNGROUPED_FACILITIES';
export const QB_CARRIERS = 'CARRIERS';
export const QB_PRODUCTS = 'QB_PRODUCTS';
export const QB_PRICER_MODULES = 'QB_PRICER_MODULES';
export const QB_USERS = 'QB_USERS';

// QB
export const RISKS = 'RISKS';
export const MY_RISKS = 'MY_RISKS';
export const DRAFT_RISKS = 'DRAFT_RISKS';

export const QB_RISK_ENDORSEMENT_DATA = 'QB_RISK_ENDORSEMENT_DATA';
export const QB_RISK_ENDORSEMENTS = 'QB_RISK_ENDORSEMENTS';

export const QB_CLIENT = 'QB_CLIENT';
export const QB_CLIENTS = 'QB_CLIENTS';
export const QB_PRODUCTS_WITH_REPORTS = 'QB_PRODUCTS_WITH_REPORTS';
export const QB_FACILITIES = 'QB_FACILITIES';

export const QB_RISK_LIST = 'QB_RISK_LIST';
export const QB_RISK_MY_RISK_LIST = 'QB_RISK_MY_RISK_LIST';
export const QB_RISK_DEFINITION = 'QB_RISK_DEFINITION';
export const QB_RISK_DETAILS = 'QB_RISK_DETAILS';
export const QB_RISK_QUOTES = 'QB_RISK_QUOTES';
export const QB_RISK_COVERAGES = 'QB_RISK_COVERAGES';
export const QB_RISK_COVERAGE_DEFINITION = 'QB_RISK_COVERAGE_DEFINITION';
export const QB_PRE_BIND_DEFINITIONS = 'QB_PRE_BIND_DEFINITIONS';
export const QB_CANCELLATION_REASONS = 'QB_CANCELLATION_REASONS';

export const INSUREDS = 'INSUREDS';
export const QB_INSURED = 'QB_INSURED';
export const REINSUREDS = 'REINSUREDS';
export const RISK_COUNTRIES = 'RISK_COUNTRIES';

// Aggregation buildings
export const QB_BUILDINGS = 'QB_BUILDINGS';
export const QB_BUILDING_DETAIL = 'QB_BUILDING_DETAIL';
