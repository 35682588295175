import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { binderLimitPreviewStyles } from './BinderLimitPreview.styles';
import { RuleLine } from 'modules/Rules';

export const BinderLimitPreviewSkeleton: FC = () => {
  const classes = binderLimitPreviewStyles();

  return (
    <div className={classes.binderLimit} data-testid="BinderLimitPreviewSkeleton">
      <div className={classes.previewContainer}>
        <Skeleton height={25} />
        <Grid container className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
          </Grid>
          <Grid item className={classes.operationSkeleton}>
            <Skeleton height={20} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.previewContainer}>
        <Skeleton height={25} />
        <Grid container className={classes.operation}>
          <Grid item className={classes.ruleLineContainer}>
            <RuleLine startingPoint={{ x: 0, y: 0 }} length={20} height={20} />
          </Grid>
          <Grid item className={classes.operationSkeleton}>
            <Skeleton height={20} />
          </Grid>
        </Grid>
        <div className={classes.binderLimitControlWrapper}>
          <Skeleton height={30} width={100} className={classes.binderLimitControl} />
          <Skeleton height={30} width={100} className={classes.binderLimitControl} />
        </div>
      </div>
    </div>
  );
};
