import chroma from 'chroma-js';

import { makeStyles } from '@material-ui/styles';

import * as utils from 'utils';

import { IUseAvatarStylesProps } from './Avatar.types';

const borderSize = 2;

export const useAvatarStyles = makeStyles(
  (theme: Theme) => {
    const getBgColor = ({ text, icon, src, bg }: IUseAvatarStylesProps) => {
      if (bg) {
        return bg;
      }
      if (text) {
        return utils.color.random(text);
      }
      if (icon) {
        return 'white';
      }
      if (src) {
        return 'transparent';
      }
      return theme.palette.grey[300];
    };

    const getBorder = (border: IUseAvatarStylesProps['border']) => {
      if (border === true) {
        return `1px solid ${theme.palette.neutral.main}`;
      }
      if (typeof border === 'string') {
        return border;
      }
      return null;
    };

    const getBorderRadius = (variant: IUseAvatarStylesProps['variant']) => {
      if (variant === 'rounded') {
        return 4;
      }
      if (variant === 'square') {
        return 0;
      }
      return '50%';
    };

    const hasBorder = (props) => {
      const bgColor = getBgColor(props);
      return chroma(bgColor === 'transparent' ? theme.palette.grey[300] : bgColor).darken(0.75);
    };

    return {
      root: (props: IUseAvatarStylesProps) => ({
        width: props.hasAction ? props.size - borderSize * 2 : props.size,
        height: props.hasAction ? props.size - borderSize * 2 : props.size,
        fontSize: `${(props.size / 24) * 0.6875}rem`,
        ...(!!props.icon && { color: theme.palette.text.primary }),
        textTransform: 'uppercase',
        backgroundColor: getBgColor(props),
        boxSizing: 'content-box',
        border: props.hasAction ? `${borderSize}px solid transparent` : 'none',
        cursor: props.hasAction ? 'pointer' : 'default',

        '&:hover': {
          borderColor: props.hasAction ? hasBorder(props) : 'transparent',
        },

        '&:before': {
          display: props.border ? 'block' : 'none',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'transparent',
          border: getBorder(props.border),
          borderRadius: getBorderRadius(props.variant),
          zIndex: 1,
          boxSizing: 'border-box',
        },
      }),
    };
  },
  { name: 'Avatar' }
);
