import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_BINDER_FACILITIES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetFacilities = ({
  page = 0,
  size = 48,
  coverHolderId = null,
  marketId = null,
  offices = null,
  status = null,
  gxbInstanceIds = null,
  teams = null,
  riskCodes = null,
  inceptionDateStart = null,
  inceptionDateEnd = null,
  expiryDateStart = null,
  expiryDateEnd = null,
  brandIds = null,
  umr = null,
  sortBy = null,
  sortAscending = null,
  enabled = true,
}) => {
  const params = {
    page,
    size,
    coverHolderId,
    marketId,
    offices,
    status,
    gxbInstanceIds,
    teams,
    riskCodes,
    inceptionDateStart,
    inceptionDateEnd,
    expiryDateStart,
    expiryDateEnd,
    brandIds,
    umr,
    sortBy,
    sortAscending,
  };

  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetFacilities',
  });

  return useQuery<IGetFacilitiesDTO, AxiosError, IGetFacilitiesResponse>(
    [FACILITY_BINDER_FACILITIES, params],
    async () => {
      const result = await binderManagementFacilitiesAxios.get('facilities', { params });
      return result?.data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
      enabled,
    }
  );
};
