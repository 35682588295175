import React, { FC } from 'react';

import { Skeleton } from '@material-ui/lab';

import { useBreadcrumbStyles } from './Breadcrumb.styles';
import { IBreadcrumbSkeletonProps } from './Breadcrumb.types';

export const BreadcrumbSkeleton: FC<IBreadcrumbSkeletonProps> = ({ items = [] }) => {
  const classes = useBreadcrumbStyles();

  return (
    <div className={classes.breadcrumbSkeleton}>
      {items.map((width, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <div className={classes.separator} />}
          <Skeleton width={width} height={20} />
        </React.Fragment>
      ))}
    </div>
  );
};
