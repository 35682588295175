import { useQuery } from 'react-query';

// app
import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { FACILITY_THIRD_PARTIES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBMThirdParties = () => {
  const handleError = useHandleError({
    file: 'binderManagement.facilities.queries.useGetBMThirdParties',
  });

  return useQuery<IOperationalThirdParty[]>(
    [FACILITY_THIRD_PARTIES],
    async () => {
      const { data } = await binderManagementFacilitiesAxios.get('thirdParties');
      return data;
    },
    {
      onError: handleError,
      keepPreviousData: true,
    }
  );
};
