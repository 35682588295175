import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import get from 'lodash/get';

import { Box, Card, CardActions, CardContent, CardHeader, Chip, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Button, EdgeImage, Tooltip, Translate } from 'components';
import { useConfirmCancelModal } from 'components/Modal/useConfirmCancelModal';

import config from 'config';
import { hideModal, showModal } from 'stores';
import * as utils from 'utils';

import { useFacilitiesStyles } from './Facilities.styles';

const InfoLine: FC<IInfoLineProps> = ({ label, content, value, classes = {} }) => (
  <Box className={classes.cardInfoLine}>
    <Typography classes={{ root: classes.infoLeft }}>{label}</Typography>
    {content || <Typography classes={{ root: classes.infoRight }}>{value}</Typography>}
  </Box>
);

const RiskCodePreview: FC<IRiskCodePreviewProps> = ({ code, description, classes = {} }) => (
  <Grid item className={classes.riskCode}>
    <Tooltip title={description} placement="bottom">
      {code}
    </Tooltip>
  </Grid>
);

const FacilityCardBase: FC<IFacilityCardBaseProps> = ({ facility, type }) => {
  const classes = useFacilitiesStyles();

  const [isTitleLong, setIsTitleLong] = useState(false);
  const [isSubTitleLong, setIsSubTitleLong] = useState(false);
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);

  const isEllipsisActive = (e) => e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;

  useEffect(() => {
    if (titleRef?.current) {
      setIsTitleLong(isEllipsisActive(titleRef.current));
    }
  }, [titleRef]);

  useEffect(() => {
    if (subTitleRef?.current) {
      setIsSubTitleLong(isEllipsisActive(subTitleRef.current));
    }
  }, [subTitleRef]);

  const subheader = (
    <Tooltip title={isSubTitleLong ? get(facility, 'team', '-') : ''} placement="top">
      <Typography ref={subTitleRef} variant="h5" classes={{ root: classes.cardHeaderSubtitle }}>
        {get(facility, 'team', '-')}
      </Typography>
    </Tooltip>
  );

  if (!facility) {
    return null;
  }

  return (
    <>
      <CardHeader
        classes={{ root: classes.cardHeader }}
        title={
          <Box className={classes.cardHeaderContent}>
            <div>
              <Tooltip title={isTitleLong ? get(facility, 'umr', '-') : ''} placement="top" rich>
                <Typography ref={titleRef} data-testid="umr" variant="h4" classes={{ root: classes.cardHeaderTitle }}>
                  {get(facility, 'umr', '-')}
                </Typography>
                {subheader}
              </Tooltip>
            </div>
            {type === 'market' && facility.operationalClientLogoUrl && (
              <EdgeImage url={facility.operationalClientLogoUrl} className={classes.cardHeaderLogo} />
            )}
            {type !== 'market' && facility.brandLogoUrl && <EdgeImage url={facility.brandLogoUrl} className={classes.cardHeaderLogo} />}
          </Box>
        }
      />

      <CardContent classes={{ root: classes.cardContent }}>
        {facility.office && <InfoLine label={utils.string.t('app.office')} value={facility.office} classes={classes} />}
        <InfoLine label={utils.string.t('app.gxb')} value={facility.gxbInstanceShortName || facility.gxbInstance} classes={classes} />
        {facility.riskCodes?.length > 0 && (
          <InfoLine
            label={utils.string.t('app.riskCodes')}
            content={
              <Grid container className={classes.infoRight}>
                {facility.riskCodes?.map(({ code, description }) => (
                  <RiskCodePreview key={code} code={code} description={description} />
                ))}
              </Grid>
            }
            classes={classes}
          />
        )}
        <Box className={classes.cardInfoLine}>
          <Typography classes={{ root: classes.infoLeft }}>{utils.string.t('app.inceptionDate')}</Typography>
          <Typography classes={{ root: classes.infoRight }}>
            <Translate
              label="format.date"
              options={{ value: { date: facility.inceptionDate, format: config.ui.format.date.text, default: '-' } }}
            />
          </Typography>
        </Box>
        <Box className={classes.cardInfoLine}>
          <Typography classes={{ root: classes.infoLeft }}>{utils.string.t('app.expiryDate')}</Typography>
          <Typography classes={{ root: classes.infoRight }}>
            <Translate
              label="format.date"
              options={{ value: { date: facility.expiryDate, format: config.ui.format.date.text, default: '-' } }}
            />
          </Typography>
        </Box>
      </CardContent>
    </>
  );
};

export const FacilityCard: FC<IFacilityCardProps> = ({ facility, entity, type }) => {
  const classes = useFacilitiesStyles();
  const dispatch = useDispatch();
  const theme = useTheme<Theme>();

  const cardMotion = {
    rest: {
      scale: 1,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      scale: 1.04,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  const statusBackgroundColor = theme.palette.bm.status[facility.status?.toLowerCase()]
    ? theme.palette.bm.status[facility.status.toLowerCase()]
    : theme.palette.bm.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

  const handleConfirm = () => {
    dispatch(hideModal('UPLOAD_BDX'));
  };

  const confirmCancelHandler = useConfirmCancelModal(handleConfirm);

  const uploadBDX = () => {
    dispatch(
      showModal({
        component: 'UPLOAD_BDX',
        props: {
          title: [utils.string.t('documents.upload.bdx.title'), facility.coverHolder, facility.umr].join(' - '),
          fullWidth: true,
          maxWidth: 'xl',
          disableAutoFocus: true,
          enableFullScreen: true,
          disableBackdropClick: true,
          componentProps: {
            facility,
            confirmCancel: true,
            confirmCancelHandler,
            cancelHandler: () => dispatch(hideModal('UPLOAD_BDX')),
          },
        },
      })
    );
  };

  return (
    <motion.div initial="rest" whileHover="hover" animate="rest" className={classes.cardWrapper} data-testid="facility-card">
      <motion.div variants={cardMotion}>
        <Card classes={{ root: classes.card }}>
          <Link
            to={{
              pathname: `${config.routes.binders.root}/${type}/${entity.id}/${config.routes.binders.facilityDetails}/${facility.umr}`,
              state: { facility, type, entity },
            }}
            className={classes.cardLink}
          >
            <FacilityCardBase facility={facility} type={type} />
          </Link>
          <CardActions classes={{ root: classes.cardActions }}>
            <Box className={classes.cardActionsContent}>
              <Box className={classes.cardActionsButton}>
                <Button
                  color="primary"
                  size="xsmall"
                  onClick={uploadBDX}
                  icon={CloudUploadIcon}
                  iconPosition="right"
                  text={utils.string.t('app.uploadBDX')}
                />
              </Box>
              {facility.status && (
                <Chip
                  component={null}
                  size="small"
                  label={utils.string.capitalise(facility.status)}
                  status={facility.status}
                  data-testid="facility-status"
                  className={classes.chip}
                  style={{
                    backgroundColor: statusBackgroundColor,
                    color: statusColor,
                  }}
                />
              )}
            </Box>
          </CardActions>
        </Card>
      </motion.div>
    </motion.div>
  );
};
