import axios from 'axios';

import { app } from 'utils';
import { accessToken } from 'utils/auth0/auth0';

export const binderManagementDroolsAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});
export const multipartBinderManagementDroolsAxios = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const binderManagementFacilitiesAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementFacilitiesDocumentsAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementBDXAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementReportingAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementFileInputAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementFileInputAxiosV2 = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementLineageAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

export const binderManagementFileInputLineageAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

binderManagementDroolsAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.drools}/api/v1/bm`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

multipartBinderManagementDroolsAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.drools}/api/v1/bm`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementFacilitiesAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.facilities}/api/v1/bm`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementFacilitiesDocumentsAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.facilities}/api/v1/documents`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementBDXAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.facilities}/api/v1/bm/bdx`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementReportingAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.fileInput}/api/v1/bm/reporting`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementFileInputAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.fileInput}/api/v1/bm`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementFileInputAxiosV2.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.fileInput}/api/v2/bm`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementLineageAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.facilities}/api/v1`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

binderManagementFileInputLineageAxios.interceptors.request.use(
  async (config) => {
    const appConfig = await app.getConfig();
    const token = await accessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = `${appConfig.endpoint.bm.fileInput}/api/v1/lineage`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
