import PropTypes from 'prop-types';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { EditProductsClientView } from './EditProductsClient.view';
import { useGetParentOfficeListAll, useGetProductsProgrammes } from 'lib/admin';
import { useGetRiskCountries, useGetClientDetail, usePostClient, QB_CLIENT, RISK_COUNTRIES } from 'lib/quoteBind';
import * as utils from 'utils';

import { productAdminSchema } from 'schemas';

EditProductsClient.propTypes = {
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  submitHandler: PropTypes.func,
};

EditProductsClient.defaultProps = {
  handleClose: () => {},
};

export default function EditProductsClient({
  id,
  submitHandler,
  handleClose,
  isProducer,
  isCreateClientModal = false,
  isAddRiskForm = false,
}) {
  const { data, isLoading } = useGetClientDetail(QB_CLIENT, id);
  const { data: programmesProducts } = useGetProductsProgrammes();
  const { data: officeList } = useGetParentOfficeListAll();
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { mutateAsync: postClient } = usePostClient();
  const options = { countries, clientOffices: officeList, products: programmesProducts };

  if (isLoading || !data) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  const { address, commission, ...rest } = data;
  const clientDetails = { ...rest, ...address, ...commission };

  const submitLabel = 'products.admin.clients.update';

  const { fields } = productAdminSchema.getSchema('clients');

  const hydrateLabels = (fields, isAddRiskForm) =>
    fields.map((field) => {
      return {
        ...field,
        ...(isAddRiskForm && field.name === 'name' && { gridSize: { xs: 12, sm: 12 } }),
        ...(isAddRiskForm && { type: field?.hideInAddRiskForm ? 'hidden' : field.type }),
        label: utils.string.t(field.label),
        ...(field.options && { options: field.options.map((option) => ({ ...option, label: utils.string.t(option.label) })) }),
      };
    });

  const schema = { fields: hydrateLabels(fields, isAddRiskForm) };

  const hydrateOptions = (fields) => {
    if (!fields || !clientDetails) return [];

    const getOptionValue = (field, itemValue) => {
      const optionsList = field.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options;
      const [result] = optionsList.filter((option) => option.value === itemValue);

      return result;
    };

    return fields
      ?.filter((field) => !(field?.hideForProducers === true && isProducer))
      .map((field) => {
        return {
          ...field,
          value: field.type === 'autocompletemui' ? getOptionValue(field, clientDetails[field.name]) : clientDetails[field.name] || '',
          ...(field.type !== 'autocompletemui' && {
            muiComponentProps: {
              ...field.muiComponentProps,
            },
          }),
          ...(isProducer && { disabled: true }),
          ...(field.optionsDynamicKey && {
            options: utils.form.getSelectOptions(field.optionsDynamicKey, {
              [field.optionsDynamicKey]: options[field.optionsDynamicKey],
            }),
          }),
        };
      });
  };

  const handleSubmit = async (values) => {
    const updateValues = { id: clientDetails?.id, ...values };

    const response = await postClient({ body: updateValues, isCreateClientModal, isEdit: true });
    const { data } = response?.result || {};

    if (data && utils.generic.isFunction(submitHandler)) {
      if (utils.generic.isFunction(handleClose)) {
        handleClose();
      }
      return submitHandler(data);
    }

    // fail
    handleCancel();
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const actions = isProducer
    ? [
        {
          name: 'submit',
          label: utils.string.t('app.close'),
          handler: handleCancel,
        },
      ]
    : [
        {
          name: 'submit',
          label: utils.string.t(submitLabel),
          handler: handleSubmit,
        },
        {
          name: 'cancel',
          label: utils.string.t('app.cancel'),
          handler: handleCancel,
        },
      ];

  const hasFields = utils.generic.isValidArray(schema.fields, true);

  // abort
  if (!hasFields || !clientDetails || utils.generic.isInvalidOrEmptyArray(options?.countries))
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;

  const hydratedFields = [hydrateOptions(schema.fields)];

  const defaultValues = utils.form.getInitialValues(hydratedFields);

  return (
    <EditProductsClientView
      fields={hydratedFields}
      actions={actions}
      defaultValues={defaultValues}
      validationSchema={utils.form.getValidationSchema(hydratedFields)}
    />
  );
}
