import { Link } from 'react-router-dom';

// MUI
import { Box } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

// Edge
import { Chip } from 'components';
import config from 'config';

export const UmrLinks = ({ facilities, bdxType, coverHolderId, bdxFile }) => {
  return (
    <Box p={2}>
      {facilities.map((facility) => {
        const facilityBdxUrl = `${config.routes.binders.coverholder}/${coverHolderId}/${config.routes.binders.facilityDetails}/${facility.facilityReference}/${bdxType}/${bdxFile.submittedFor}`;

        return (
          <Link key={facility.facilityReference} to={facilityBdxUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>
            <Chip
              label={facility.facilityReference}
              color="primary"
              icon={<LaunchIcon fontSize="small" />}
              style={{
                marginRight: 10,
                cursor: 'pointer',
                fontWeight: 600,
              }}
            />
          </Link>
        );
      })}
    </Box>
  );
};
