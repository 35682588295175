import { FC } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { TGetHandleAssignToBlock } from 'modules/Binders/UnSubmittedBdx';

import * as utils from 'utils';

interface IAssignComponentProps {
  selectedRowKeys: string[];
  getHandleAssign: TGetHandleAssignToBlock;
  handleSuccess: VoidFunction;
}

export type TAssignComponent = FC<IAssignComponentProps>;

export const getAssignToBlockComponent = (label: string) => {
  const AssignComponent = ({ selectedRowKeys, getHandleAssign, handleSuccess }: IAssignComponentProps) => {
    const isDisabled = selectedRowKeys?.length === 0;

    const handleAssign = getHandleAssign(selectedRowKeys, handleSuccess);

    return (
      <Tooltip title={utils.string.t(label)} placement="top">
        <IconButton
          aria-label={utils.string.t(label)}
          color="primary"
          onClick={handleAssign}
          disabled={isDisabled}
          style={{
            marginLeft: '-3px',
          }}
        >
          <AddCircleOutlineIcon color={isDisabled ? 'disabled' : 'primary'} />
        </IconButton>
      </Tooltip>
    );
  };

  return AssignComponent;
};
