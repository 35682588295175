import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification, hideModal } from 'stores';

import { binderManagementDroolsAxios } from '../../binderManagement.api';
import { DROOLS_RULESETS } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useCreateRule = ({ facility, bdxType, thirdPartyId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const fileName = `${[facility.umr, bdxType, thirdPartyId || 'null'].join('-')}.drl`;

  const handleError = useHandleError({
    file: 'binderManagement.drools.mutations.createRule',
    message: 'API fetch error (binderManagemen.drools.post)',
    warning: 'notification.binderManagement.drools.createRuleFailure',
  });

  const handleSuccess = ({ data, action }) => {
    const { bdxType, facility } = data;

    queryClient.setQueryData(
      [
        DROOLS_RULESETS,
        {
          bdxType,
          facility,
        },
      ],
      (prevData = []) => [...prevData?.filter((dataset) => dataset.fileName !== data.fileName), data]
    );

    dispatch(hideModal());

    switch (action) {
      case 'ADD_RULE':
        dispatch(enqueueNotification('notification.binderManagement.drools.createRuleSuccess', 'success'));
        break;
      case 'EDIT_RULE':
        dispatch(enqueueNotification('notification.binderManagement.drools.editRuleSuccess', 'success'));
        break;
      case 'DELETE_RULE':
        dispatch(enqueueNotification('notification.binderManagement.drools.deleteRuleSuccess', 'success'));
        break;
      case 'FAVOURITE_RULE':
        dispatch(enqueueNotification('notification.binderManagement.drools.favouriteRuleSuccess', 'success'));
        break;
      case 'UNFAVOURITE_RULE':
        dispatch(enqueueNotification('notification.binderManagement.drools.favouriteRuleRemovedSuccess', 'success'));
        break;
      case 'IMPORT_RULES':
        dispatch(enqueueNotification('notification.binderManagement.drools.importRulesSuccess', 'success'));
        break;
      default:
        break;
    }
  };

  return useMutation(
    async ({ rules, action, ruleset }) => {
      const validatedRules = rules.map((rule) => ({
        ...rule,
        actions: rule.actions.map((action) => {
          // Ignore 'Current' type of field
          if (action.field === 'Current') {
            return { ...action, field: null };
          }
          return action;
        }),
      }));

      try {
        const updatedRuleset = {
          bdxType,
          coverHolder: facility.coverHolderId,
          coverHolderId: facility.coverHolderId,
          facility: facility.umr,
          fileName: ruleset ? ruleset.fileName : fileName.replace(' ', '_'),
          thirdPartyId: ruleset ? ruleset.thirdPartyId : null,
          riskCodes: facility.riskCodes.map(({ code }) => code),
          rules: validatedRules,
        };

        // Case when TPA ruleset is not configured but requested
        if (thirdPartyId) {
          updatedRuleset.thirdPartyId = thirdPartyId;
        }

        const { data } = await binderManagementDroolsAxios.post('drools/rules', updatedRuleset);

        return { data, action };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (result) => handleSuccess(result),
      onError: handleError,
    }
  );
};
