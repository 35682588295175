import { logoutAuth0 } from 'utils/auth0/auth0';

export const authLogout = (auth0) => {
  logoutAuth0();

  return {
    type: 'AUTH_LOGOUT',
  };
};

export const authInProgress = () => {
  return {
    type: 'AUTH_IN_PROGRESS',
  };
};
