import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFacilitiesAxios } from '../../binderManagement.api';
import { BDX_UN_SUBMITTED_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface ISubmitBDXLinesProps {
  groupId: string;
}

export const useSubmitBdxLines = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useSubmitBdxLines',
    warning: 'notification.binderManagement.useSubmitBdxLines',
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries(BDX_UN_SUBMITTED_DATA);

    dispatch(enqueueNotification('notification.binderManagement.useSubmitBdxLines', 'success'));
  };

  return useMutation<ISubmitBDXLinesProps, AxiosError, ISubmitBDXLinesProps>(
    async ({ groupId }) => {
      if (!groupId) throw new Error('Missing required params');

      const { data } = await binderManagementFacilitiesAxios.put(`unsubmitted/${groupId}`, {
        groupId,
      });

      return {
        groupId,
        result: data,
      };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
