import { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

import { Button } from 'components';

import * as utils from 'utils';

import { Action } from './Action';

import styles from './Actions.styles';

export const Actions = ({ methods, fields, rule, ruleColumns, ruleRowActions }) => {
  const classes = makeStyles(styles, { name: 'Rule' })();

  const { control } = methods;
  const {
    fields: actions,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'actions',
  });

  const removeAction = (index) => {
    remove(index);
  };
  const addAction = useCallback(() => {
    append({
      field: '',
      operation: '',
    });
  }, [append]);

  useEffect(() => {
    if (rule) {
      append(
        rule.actions.map((action) => ({
          ...action,
          field: ruleColumns?.find(({ name }) => action.field === name),
          column1: ruleColumns?.find(({ name }) => action.column1 === name) || null,
          column2: ruleColumns?.find(({ name }) => action.column2 === name) || null,
        }))
      );
    } else {
      addAction();
    }
  }, [rule, append, ruleColumns, addAction, ruleRowActions]);

  return (
    <div className={classes.actions}>
      <Grid container spacing={1} className={classes.actionsStatement}>
        <Grid item>
          <Typography variant="subtitle1">{utils.string.t('rules.actionStatement')}</Typography>
        </Grid>
        <div className={classes.button}>
          <Button
            text={utils.string.t('rules.addAction')}
            onClick={addAction}
            icon={PostAdd}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </Grid>
      {actions?.map((action, actionIndex) => (
        <Action
          key={action.id}
          isLast={actions?.length === 1}
          action={action}
          ruleColumns={ruleColumns}
          ruleRowActions={ruleRowActions}
          fields={fields}
          methods={methods}
          prefix={`actions.${actionIndex}`}
          handleRemoveCondition={() => removeAction(actionIndex)}
        />
      ))}
    </div>
  );
};
