import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { BDX_DATA, BDX_EXCEPTIONS_COUNT, BDX_REQUIRED_BDX } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useDeleteReport = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.reporting.mutation.useDeleteReport',
    warning: 'notification.binderManagement.bdxDelete.fail',
  });

  const handleSuccess = ({ facilityReference, requestId, bdxDocumentsInfo, callbackOnSuccess }) => {
    queryClient.invalidateQueries([BDX_EXCEPTIONS_COUNT, bdxDocumentsInfo]);
    queryClient.invalidateQueries(BDX_DATA);
    queryClient.invalidateQueries([BDX_REQUIRED_BDX, facilityReference]);

    dispatch(enqueueNotification('notification.bdxDelete.success', 'success'));

    if (utils.generic.isFunction(callbackOnSuccess)) {
      callbackOnSuccess(requestId);
    }
  };

  return useMutation(
    async ({ requestId, facilityReference, bdxDocumentsInfo, callbackOnSuccess }) => {
      const url = `upload/${requestId}`;
      const data = await binderManagementFileInputAxios.delete(url);
      return { facilityReference, requestId, bdxDocumentsInfo, callbackOnSuccess, ...data };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
