import { FC } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { binderLimitsStyles } from './BinderLimits.styles';

export const BinderLimitsSkeleton: FC = () => {
  const classes = binderLimitsStyles();
  return (
    <div className={classes.root} data-testid="BinderLimitsSkeleton">
      <Grid container spacing={2} className={classes.buttons}>
        <Grid item>
          <Skeleton width={120} height={36} className={classes.buttonSkeleton} />
        </Grid>
        <Grid item>
          <Skeleton width={144} height={36} className={classes.buttonSkeleton} />
        </Grid>
      </Grid>
      <div className={classes.rootLoader}>
        <CircularProgress />
      </div>
    </div>
  );
};
