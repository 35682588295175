import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

// app
import styles from './HeaderNav.styles';
import { AdvancedSearch, Button, Logo, Restricted, Search, Avatar } from 'components';
import { selectUser } from 'stores';
import * as constants from 'consts';
import * as utils from 'utils';
import config from 'config';
import classnames from 'classnames';

// mui
import { makeStyles, Collapse, ClickAwayListener, Badge, Fab, Grid, Hidden, List, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Env } from './Env';

HeaderNavView.propTypes = {
  isDev: PropTypes.bool,
  isSidebarExpanded: PropTypes.bool,
  isFabVisible: PropTypes.bool,
  isMagnifierVisible: PropTypes.bool,
  isSearchMobileVisible: PropTypes.bool,
  isSearchResultsVisible: PropTypes.bool,
  handlers: PropTypes.shape({
    searchMobileToggle: PropTypes.func,
    searchSubmit: PropTypes.func,
    searchReset: PropTypes.func,
    toggleNav: PropTypes.func,
    clickNewEnquiry: PropTypes.func,
    handleClickUserMenu: PropTypes.func,
    handleCloseUserMenu: PropTypes.func,
  }),
  displayUserMenu: PropTypes.object,
};

export function HeaderNavView({
  resetKey,
  isDev,
  isSidebarExpanded,
  isFabVisible,
  isMagnifierVisible,
  isSearchMobileVisible,
  isSearchResultsVisible,
  handlers,
  displayUserMenu,
}) {
  const classes = makeStyles(styles, { name: 'HeaderNav' })({});
  const { logout } = useAuth0();
  const location = useLocation();
  const user = useSelector(selectUser);

  const isBroker = utils.user.isBroker(user);
  const fullname = utils.user.fullname(user);
  const initials = utils.user.initials(user);
  const isPlacementPage =
    location.pathname.includes(config.routes.department.root) || location.pathname.includes(config.routes.placement.root);

  return (
    <>
      <Grid container spacing={3} alignItems="center" wrap="nowrap" justifyContent="space-between">
        <Grid item container wrap="nowrap" spacing={3} sm={4} md={3} lg={2} alignItems="center">
          <Grid item className={classes.gridMenu}>
            <Button
              icon={MenuIcon}
              variant="text"
              light
              size="medium"
              onClick={handlers.toggleNav}
              nestedClasses={{ btn: classes.hamburger }}
              data-testid="hamburger-menu"
            />
          </Grid>

          <Grid item className={classes.gridLogo}>
            <Env />
            <Logo height={40} className={classes.logo} />
          </Grid>
        </Grid>
        {isDev && isBroker && isMagnifierVisible && (
          <Hidden smUp>
            <Grid item className={classes.gridMagnifier}>
              <Button
                icon={SearchIcon}
                variant="text"
                light
                size="medium"
                onClick={handlers.searchMobileToggle}
                nestedClasses={{
                  btn: classes.magnifierBtn,
                  icon: classes.magnifierIcon,
                }}
                data-testid="mobile-search-btn"
              />
            </Grid>
          </Hidden>
        )}

        {/* Tablet/Desktop */}
        <Hidden xsDown>
          {isDev && isBroker && (
            <Grid item className={classes.gridSearch}>
              <ClickAwayListener
                onClickAway={isSearchResultsVisible && utils.generic.isFunction(handlers.searchReset) ? handlers.searchReset : () => {}}
              >
                <div>
                  <Search
                    key={resetKey}
                    text=""
                    placeholder={utils.string.t('advancedSearch.placeholder')}
                    submitButton={false}
                    handlers={{
                      search: handlers.searchSubmit,
                      reset: handlers.searchReset,
                    }}
                    nestedClasses={{
                      root: classes.search,
                    }}
                  />

                  {isSearchResultsVisible && (
                    <AdvancedSearch
                      handlers={{
                        onClick: handlers.searchReset,
                      }}
                      nestedClasses={{ root: classes.searchResults }}
                    />
                  )}
                </div>
              </ClickAwayListener>
            </Grid>
          )}
        </Hidden>
        <Grid padding={0} data-testid="menu-user">
          <Avatar text={initials} size={32} avatarClasses={classes.avatar} onAvatarClick={handlers.handleClickUserMenu} />
          <Menu
            id="user-menu"
            getContentAnchorEl={null}
            anchorEl={displayUserMenu}
            keepMounted
            open={Boolean(displayUserMenu)}
            onClose={handlers.handleCloseUserMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            MenuListProps={{ disablePadding: true }}
            PaperProps={{
              style: {
                minWidth: 170,
                width: 'auto',
              },
            }}
            disableAutoFocusItem
          >
            <List className={classnames(classes.listUser)} data-testid="menu-item-name">
              <li className={classes.user}>
                <Box display="flex" paddingBottom={1}>
                  <Avatar text={initials} size={32} avatarClasses={classes.avatar} />
                  <Box fontWeight="fontWeightMedium" padding={1} fontSize="14px" whiteSpace="nowrap">
                    {fullname}
                  </Box>
                </Box>
              </li>
            </List>
            <div>
              <Box data-testid="menu-item-logout">
                <MenuItem className={classes.logoutMenu} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={utils.string.t('app.logout')} />
                </MenuItem>
              </Box>
            </div>
          </Menu>
        </Grid>
      </Grid>
      {/* Mobile */}
      {isFabVisible && isPlacementPage && !isSidebarExpanded && (
        <Restricted include={[constants.ROLE_BROKER, constants.ROLE_PRODUCER]}>
          <Hidden smUp>
            <Fab
              color="primary"
              size="medium"
              className={classes.floatingButton}
              onClick={handlers.clickNewEnquiry}
              data-testid="fab-new-enquiry"
            >
              <AddIcon />
            </Fab>
          </Hidden>
        </Restricted>
      )}

      {isDev && isBroker && (
        <Hidden smUp>
          <Collapse in={isSearchMobileVisible} className={classes.searchMobileContainer}>
            <ClickAwayListener onClickAway={utils.generic.isFunction(handlers.searchReset) ? handlers.searchReset : () => {}}>
              <div className={classes.searchMobile}>
                <Search
                  key={resetKey}
                  text=""
                  placeholder={utils.string.t('advancedSearch.placeholder')}
                  submitButton={false}
                  handlers={{
                    search: handlers.searchSubmit,
                    reset: handlers.searchReset,
                  }}
                  nestedClasses={{
                    root: classes.search,
                  }}
                />

                {isSearchResultsVisible && (
                  <AdvancedSearch
                    handlers={{
                      onClick: handlers.searchReset,
                    }}
                    nestedClasses={{ root: classes.searchResults }}
                  />
                )}
              </div>
            </ClickAwayListener>
          </Collapse>
        </Hidden>
      )}
    </>
  );
}
