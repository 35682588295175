import { createContext, FC, useMemo } from 'react';

import { IProcessingContext, IProcessingContextProviderProps } from './Processing.types';
import { getTooltipText } from './utils';

const INITIAL_PROCESSING_CONTEXT: IProcessingContext = {
  isSubmitDisabled: false,
  isAllSubmitting: false,
  tooltipText: '',
  bdxDocumentsInfo: {
    bdxType: null,
    facilityReference: null,
    yearMonthSubmission: null,
  },
};

export const ProcessingContext = createContext<IProcessingContext>(INITIAL_PROCESSING_CONTEXT);

export const ProcessingContextProvider: FC<IProcessingContextProviderProps> = ({
  children,
  isAllSubmitting,
  hasUnpublishedBDX,
  hasUnallocatedClaimBlock,
  isThirdPartyAccountUnset,
  bdxDocumentsInfo,
}) => {
  const context = useMemo<IProcessingContext>(() => {
    const tooltipText = getTooltipText({ hasUnpublishedBDX, hasUnallocatedLines: hasUnallocatedClaimBlock, isThirdPartyAccountUnset });

    return {
      isSubmitDisabled: hasUnpublishedBDX || hasUnallocatedClaimBlock || isThirdPartyAccountUnset,
      isAllSubmitting,
      tooltipText,
      bdxDocumentsInfo,
    };
  }, [hasUnpublishedBDX, hasUnallocatedClaimBlock, isAllSubmitting, bdxDocumentsInfo, isThirdPartyAccountUnset]);

  return <ProcessingContext.Provider value={context}>{children}</ProcessingContext.Provider>;
};
