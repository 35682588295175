import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

// app
import * as utils from 'utils';
import styles from './AddRule.styles';
import { Button, FormText } from 'components';
import { Actions, Conditions } from './components';

export const AddRuleView = ({ fields, methods, errors, ruleColumns, ruleRowActions, rule, submit }) => {
  const classes = makeStyles(styles, { name: 'AddRule' })();

  return (
    <div className={classes.root} data-testid="AddRuleView">
      <Grid container spacing={2}>
        <Grid item className={classes.nameWrapper}>
          <FormText
            {...utils.form.getFieldProps(fields, 'name')}
            control={methods.control}
            error={errors.name}
            rules={{ required: 'Required' }}
          />
        </Grid>
        <Grid item className={classes.priorityWrapper}>
          <FormText
            {...utils.form.getFieldProps(fields, 'salience')}
            control={methods.control}
            error={errors.salience}
            rules={{ required: 'Required' }}
          />
        </Grid>
      </Grid>
      <Conditions methods={methods} fields={fields} rule={rule} ruleColumns={ruleColumns} />
      <Actions methods={methods} fields={fields} rule={rule} ruleColumns={ruleColumns} ruleRowActions={ruleRowActions} />
      <div className={classes.submit}>
        <Button
          text={utils.string.t('app.save')}
          onClick={methods.handleSubmit(submit)}
          color="primary"
          disabled={methods.formState.isSubmitting}
        />
      </div>
    </div>
  );
};
