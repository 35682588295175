import { useState } from 'react';
import SortIcon from '@material-ui/icons/Sort';
// material
import { Menu, IconButton, MenuItem } from '@material-ui/core';

export default function Sort({ size, sort, options, onChange, disabled = false }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOption = (option) => {
    onChange(option);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'sort-menu' : undefined;

  return (
    <>
      <IconButton data-testid="sort-button" disableRipple size={size} color="inherit" onClick={handleOpen} disabled={disabled}>
        <SortIcon />
      </IconButton>
      <Menu
        data-testid="sort-menu"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transition
      >
        {options.map((option) => (
          <MenuItem
            key={`${option.sortBy}-${option.label}`}
            selected={option.sortBy === sort?.by && option.sortDirection === sort?.direction}
            onClick={() => handleOption(option)}
            sx={{ typography: 'body2' }}
          >
            <span style={{ width: 200 }}>{option.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
