export const setFilter = (payload) => {
  return {
    type: 'SET_FILTER',
    payload,
  };
};

export const resetFilter = (payload) => {
  return {
    type: 'RESET_FILTER',
    payload,
  };
};

export const resetAllFilter = () => {
  return {
    type: 'RESET_ALL_FILTERS',
  };
};

export const setSearchFilter = (payload) => {
  return {
    type: 'SET_SEARCH_FILTER',
    payload,
  };
};
