import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const useUpdateAllIssues = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useUpdateAllIssues',
    };

    dispatch(enqueueNotification('notification.updateAllIssues.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    dispatch(enqueueNotification('notification.updateAllIssues.success', 'success'));
  };

  return useMutation(
    async ({ issueIds, status }) => {
      if (!status || utils.generic.isInvalidOrEmptyArray(issueIds)) {
        throw new Error('Invalid data');
      }

      const data = {
        issueIds,
        status,
      };

      const result = await quoteBindAxios.put(`issues/updateStatuses`, data);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
