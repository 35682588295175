import { useState } from 'react';

// MUI
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

// app
import { SUMMARY_ACCORDIONS } from 'consts';
import { Accordion, Button, Empty, Tooltip } from 'components';
import { useSummaryTableStyles, useAccordionStyles } from 'forms/UploadBDX/Summary/SummaryTable/SummaryTable.styles';
import { CircularProgress } from 'components/CircularProgress';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as BlockedRulesImage } from 'assets/svg/mapping-not-found.svg';
import * as utils from 'utils';

const ROW_PER_PAGE = 100;

export const GetProcessedRowsTable = ({ data = {}, fullView, fullScreen, toggleFullScreen, progress }) => {
  const classes = useSummaryTableStyles({
    fullView,
    fullScreen,
  });

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (!data?.headers || !data?.rows) {
    return (
      <Empty width={400} title={utils.string.t('notification.reporting.reportDetails.notFound')} icon={<BlockedRulesImage />} padding />
    );
  }

  return (
    <div>
      <Grid container className={classes.summaryHeader}>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: 'auto',
          }}
        >
          <Grid item>
            <Typography variant="h2" noWrap>
              {utils.string.t('form.bdx.bdxSample')}
            </Typography>
          </Grid>

          <Grid item>
            <ColorIndicator text={utils.string.t('form.bdx.edgeColumns')} color="#334762" />
          </Grid>
          <Grid item>
            <ColorIndicator text={utils.string.t('form.bdx.excelColumns')} color="#107C41" />
          </Grid>
        </Grid>
        <Grid item style={{ margin: '3px' }}>
          <Box display="flex" alignItems="center">
            <TablePagination
              component="div"
              page={page}
              count={data.rows?.length}
              rowsPerPage={100}
              rowsPerPageOptions={[]}
              onPageChange={handleChangePage}
              centered
            />
            <CircularProgress value={progress} />
            <Button
              icon={fullView ? FullScreenExitIcon : FullScreenIcon}
              variant="text"
              onClick={() => {
                toggleFullScreen();
              }}
              data-testid="bdxsample-fullscreen-button"
            />
          </Box>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {data.rows?.length > 0 ? (
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {data.headers?.length > 0 &&
                      data.headers.map((header) => (
                        <TableCell key={header.matchedColumnName} align="center" className={classes.matchedColumnName}>
                          <Tooltip title={<HeaderTooltip {...header} />} placement="top" rich>
                            {header.matchedColumnName}
                          </Tooltip>
                        </TableCell>
                      ))}
                  </TableRow>
                  <TableRow>
                    {data.headers?.length > 0 &&
                      data.headers.map((header) => (
                        <TableCell key={header.odsColumnName} align="center">
                          <Tooltip title={<HeaderTooltip {...header} />} placement="top" rich>
                            {header.odsColumnName}
                          </Tooltip>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.rows?.length > 0 &&
                    data.headers?.length > 0 &&
                    data.rows?.slice(page * ROW_PER_PAGE, page * ROW_PER_PAGE + ROW_PER_PAGE)?.map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {data.headers.map((header) => {
                            const value = row?.columns?.find((r) => r.odsColumnName === header.odsColumnName)?.value || '';
                            return (
                              <TableCell key={`${header.odsColumnName}-${index}`} align="center" className={classes.autoCompleteLabel}>
                                {value?.length > 15 ? (
                                  <Tooltip title={value} placement="top" rich>
                                    {value}
                                  </Tooltip>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.exceptions?.length > 0 ? <ExceptionAccordion exceptions={data?.exceptions} /> : null}
          </>
        ) : (
          <Empty width={360} title={utils.string.t('form.bdx.summaryInProgress')} icon={<Loading />} padding />
        )}
      </div>
    </div>
  );
};

const HeaderTooltip = ({ matchedColumnName, odsColumnName }) => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        Excel: <span style={{ fontWeight: 600 }}>{matchedColumnName}</span>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Edge: <span style={{ fontWeight: 600 }}>{odsColumnName}</span>
      </Typography>
    </Box>
  );
};

const ColorIndicator = ({ text, color }) => (
  <Grid container direction="row" alignItems="center">
    <Grid item>
      <Box
        sx={{
          width: '0.75rem',
          height: '0.75rem',
          backgroundColor: color,
          borderRadius: '1rem',
          marginRight: '0.5rem',
        }}
      />
    </Grid>
    <Grid item>
      <Typography>{text}</Typography>
    </Grid>
  </Grid>
);

const ExceptionAccordion = ({ exceptions }) => {
  const classes = useAccordionStyles();
  const [expanded, setExpanded] = useState(false);

  const handleAccordion = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.accordion}>
      <Accordion
        icon={true}
        secondaryIcon={
          exceptions?.length > 0 && (
            <Chip size="small" style={{ backgroundColor: '#edac00', color: '#FFFFFF', marginLeft: '5px' }} label={exceptions?.length} />
          )
        }
        expanded={expanded === SUMMARY_ACCORDIONS.EXCEPTIONS}
        title={{ text: 'Items for review', type: 'h2' }}
        onChange={handleAccordion(SUMMARY_ACCORDIONS.EXCEPTIONS)}
      >
        <List>
          {exceptions?.map((err) => (
            <ListItem>
              <ListItemIcon>
                {err.level === 'Error' ? (
                  <ErrorRoundedIcon style={{ color: '#ef5350' }} />
                ) : (
                  <WarningRoundedIcon style={{ color: '#ff9800' }} />
                )}
              </ListItemIcon>
              <ListItemText primary={err.message} />
            </ListItem>
          ))}
        </List>
      </Accordion>
    </div>
  );
};
