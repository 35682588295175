import { createContext, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { URL_BDX } from 'consts';

const INITIAL_BDX_EXTRACTION_CCONTEXT = {
  setActiveBDX: (activeBDX) => ({ requestId: null, status: null }),
  activeBDX: { requestId: null, status: null },
  isLoading: false,
  initialBDX: null,
};

export const BDXExtractionContext = createContext(INITIAL_BDX_EXTRACTION_CCONTEXT);

export const BDXExtractionContextProvider = ({ children, isLoading }) => {
  const location = useLocation();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialBDX = useMemo(() => new URLSearchParams(location.search).get(URL_BDX) || null, []);
  const [activeBDX, setActiveBDX] = useState({ requestId: initialBDX, status: null });

  const handleSetActiveBDX = useCallback(
    (activeBDX) => {
      const search = new URLSearchParams(location.search);
      if (activeBDX.requestId) {
        setActiveBDX({
          ...activeBDX,
        });
        search.set(URL_BDX, activeBDX.requestId);
      }
      history.replace({ search: search.toString(), state: location.state });
    },
    [history, location.search, location.state]
  );

  return (
    <BDXExtractionContext.Provider value={{ activeBDX, setActiveBDX: handleSetActiveBDX, isLoading, initialBDX }}>
      {children}
    </BDXExtractionContext.Provider>
  );
};
