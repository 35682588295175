import { useQuery } from 'react-query';

// app
import { axiosInstance } from '../api';
import { CLAIMS_TASKS_DETAIL_LIST } from '../queryKeys';
import { getHandleError } from '../utils';

export const useGetTaskDetailList = () => {
  const handleError = getHandleError('claimsTaskManagement.queries.useGetTaskDetailList');

  return useQuery(
    [CLAIMS_TASKS_DETAIL_LIST],
    async () => {
      const url = `tm/v1/task-details`;

      const result = await axiosInstance.get(url);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
