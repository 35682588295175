import { FC } from 'react';

import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useFacilitiesStyles } from './Facilities.styles';

const InfoLine: FC<IInfoLineProps> = ({ classes }) => (
  <Box className={classes.cardInfoLine}>
    <Typography classes={{ root: classes.infoLeft }}>
      <Skeleton width="100%" />
    </Typography>
    <Typography classes={{ root: classes.infoRight }}>
      <Skeleton width="100%" />
    </Typography>
  </Box>
);

interface IFacilityCardListSkeletonProps {
  numberOfCards?: number;
}

export const CardLoading: FC = () => {
  const classes = useFacilitiesStyles();

  return (
    <Card data-testid="facility-loading-card" classes={{ root: classes.card }} style={{ width: '100%' }}>
      <CardHeader
        classes={{ root: classes.cardHeader }}
        title={
          <Box className={classes.cardHeaderContent}>
            <div style={{ width: '100%' }}>
              <Typography variant="h4" classes={{ root: classes.cardHeaderTitle }}>
                <Skeleton width="80%" />
              </Typography>
              <Typography variant="body2" color="secondary">
                <Skeleton width="80%" />
              </Typography>
            </div>
            <Box className={classes.cardHeaderLogo}>
              <Skeleton variant="rect" width={60} height={40} style={{ marginRight: 5, marginTop: 10 }} />
            </Box>
          </Box>
        }
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <InfoLine classes={classes} />
        <InfoLine classes={classes} />
        <InfoLine classes={classes} />
        <InfoLine classes={classes} />
      </CardContent>
      <div className={classes.cardActions}>
        <Box className={classes.cardActionsContent}>
          <Box className={classes.cardActionsButton}>
            <Skeleton width="40%" />
          </Box>
          <Skeleton width="40%" />
        </Box>
      </div>
    </Card>
  );
};

export const FacilityCardListSkeleton: FC<IFacilityCardListSkeletonProps> = ({ numberOfCards = 12 }) => (
  <>
    {Array.from(Array(numberOfCards).keys()).map((i) => (
      <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
        <CardLoading />
      </Grid>
    ))}
  </>
);
