import { get } from 'lodash';

export const selectBinderSearchStr = (state) => get(state, 'filters.binders.searchStr') || null;
export const selectFilters = (state) => get(state, 'filters') || null;
export const selectBinderFilters = (state) => get(state, 'filters.binders') || [];
export const selectBinderFacilityFilters = (state) => get(state, 'filters.binders.facilities') || {};
export const selectBinderCoverholderFilters = (state) => get(state, 'filters.binders.coverholders') || {};
export const selectBinderMarketFilters = (state) => get(state, 'filters.binders.markets') || {};

export const selectAdminUsersFilters = (state) => get(state, 'filters.admin.users') || {};
export const selectRiskListFilters = (state) => get(state, 'filters.quote-bind.risk-list') || {};

// Claims Tasks
export const selectClaimsTasksListFilters = (state) => get(state, 'filters.claims-tasks.claims-tasks-list') || {};
