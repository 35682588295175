import { firstBy } from 'thenby';

import { Typography } from '@material-ui/core';

import { useGetFacilityConfig } from 'lib/binderManagement';
import * as utils from 'utils';

import { Rule } from '../../Rule';
import { useRulesListStyle } from '../RulesList.style';

import { NoResultsRulesList } from './NoResultsRulesList';
import { RulesClaimsListSkeleton } from './RulesClaimsListSkeleton';
import { TPARulesAccordion } from './TPARulesAccordion';

export const RulesClaimsList = ({ ruleSets, handlers, isLoadingRule, bdxType, facility, isLoading }) => {
  const classes = useRulesListStyle();
  const { data: config, isLoading: isLoadingTPA } = useGetFacilityConfig({ umr: facility.umr, bdxType, enabled: !isLoading });

  if (isLoading) {
    return <RulesClaimsListSkeleton />;
  }

  const nonTPARuleSet = ruleSets?.find(({ thirdPartyId }) => !thirdPartyId);

  const nonTPARules =
    nonTPARuleSet?.rules
      ?.map((rule) => ({
        ...rule,
        fileName: nonTPARuleSet.fileName,
        isFavourite: !!rule?.ruleId,
        thirdPartyId: nonTPARuleSet.thirdPartyId,
      }))
      .sort(firstBy(utils.sort.array('numeric', 'salience', 'asc')).thenBy(utils.sort.array('string', 'name', 'asc'))) || [];

  return (
    <div>
      <Typography variant="h2" className={classes.groupTitle}>
        Non TPA rules
      </Typography>
      {nonTPARules?.length !== 0 ? (
        nonTPARules?.map((rule) => (
          <Rule
            key={`${rule.id}-${rule.name}`}
            ruleset={nonTPARuleSet}
            rule={rule}
            bdxType={bdxType}
            facility={facility}
            handleFavourites={handlers.handleFavourites}
            isLoading={isLoadingRule || isLoadingTPA}
            handleDeleteRule={handlers.handleDeleteRule}
          />
        ))
      ) : (
        <NoResultsRulesList />
      )}
      {config?.thirdParties?.length > 0 && (
        <>
          <Typography variant="h2" className={classes.groupTitle}>
            TPA rules
          </Typography>
          {config.thirdParties.map((tpa) => (
            <TPARulesAccordion
              tpa={tpa}
              key={tpa.id}
              handlers={handlers}
              bdxType={bdxType}
              facility={facility}
              isLoading={isLoadingRule || isLoadingTPA}
              ruleset={ruleSets.find(({ thirdPartyId }) => thirdPartyId === tpa.id)}
            />
          ))}
        </>
      )}
    </div>
  );
};
