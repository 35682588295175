import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

interface IUseHandleErrorProps {
  file: string;
  message?: string;
  warning?: string;
}

export const useHandleError = ({ file, message, warning }: IUseHandleErrorProps) => {
  const dispatch = useDispatch();

  const handleError = useCallback(
    (error: AxiosError<IAPIError>) => {
      const errorParams = { file, message };
      utils.api.handleError(error, errorParams);
      utils.api.handleUnauthorized(error, null, logoutAuth0);

      const errorCode = error?.response?.data?.errors?.length > 0 ? error.response.data.errors[0].code : 'unknown';

      const errorMessage = warning || 'notification.binderManagement.errors.fail';

      dispatch(
        enqueueNotification(errorMessage, 'error', {
          data: {
            error: utils.string.t(`notification.binderManagement.errors.${errorCode}`),
          },
          delay: 0,
        })
      );
    },
    [file, message, warning, dispatch]
  );

  return handleError;
};
