import { useMutation } from 'react-query';
import { authorizedLocation } from 'lib/quoteBind/quoteBind.api';
import { useAuth0 } from '@auth0/auth0-react';

async function getRiskAddressBatch(addresses, componentRestrictions, token) {
  if (!Array.isArray(addresses) || !addresses.length) return null;

  const data = addresses.map((address) => ({
    address: address,
    ...(componentRestrictions && { componentRestrictions }),
  }));

  const response = await authorizedLocation(token).post(`search/batch?removeDuplicates=false`, data);
  return response.data?.data || {};
}

export function useGetAddressBatch() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async ({ addresses, componentRestrictions }) => {
      if (!addresses) return;

      const token = await getAccessTokenSilently();

      const result = await getRiskAddressBatch(addresses, componentRestrictions, token);

      return result;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );
}
