import { useQuery } from 'react-query';

// app
import { binderManagementBDXAxios } from '../../binderManagement.api';
import { BDX_DATA } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

export const useGetBdxData = ({ bdxDocumentsInfo, page = 0, size = 12, rowId, exceptionType, requestId }) => {
  const params = { page, size, rowId, exceptionType };
  const handleError = useHandleError({ file: 'binderManagement.bdx.queries.useGetBdxData' });

  return useQuery(
    [BDX_DATA, bdxDocumentsInfo, requestId, params],
    async () => {
      if (requestId) {
        const url = `data/${requestId}`;
        const result = await binderManagementBDXAxios.get(url, { params });
        return result?.data;
      }

      const { facilityReference, yearMonthSubmission, bdxType } = bdxDocumentsInfo;
      if (!facilityReference || !yearMonthSubmission || !bdxType) throw new Error('Missing required params');

      const url = `data/${facilityReference}/${yearMonthSubmission}/${bdxType}`;

      const result = await binderManagementBDXAxios.get(url, { params });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};
