import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ListItemText, ListItem, ListItemIcon, Typography, Tooltip, ButtonBase, CircularProgress } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import CloudDownload from '@material-ui/icons/CloudDownload';

import { useDocumentsStyle } from '../Documents.style';
import { FormText } from 'components';
import { enqueueNotification, hideModal, showModal } from 'stores';
import * as utils from 'utils';
import { binderManagementFacilitiesDocumentsAxios, useRenameFacilityDocument, useDeleteFacilityDocument } from 'lib/binderManagement';

export const Document = ({ documentName, folder, umr, dispatch, folderFiles }) => {
  const classes = useDocumentsStyle();
  const [isTitleLong, setIsTitleLong] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const titleRef = useRef(null);

  const { mutateAsync: editDocument, isLoading: isEditing } = useRenameFacilityDocument({ umr, folder });
  const { mutateAsync: deleteDocument, isLoading: isDeletting } = useDeleteFacilityDocument({ umr, folder });
  const [isEdit, setEdit] = useState(false);

  const handleEdit = (e) => {
    setEdit(true);
  };
  const handleSave = async (originalFileName) => {
    const newFileName = originalFileName.includes('.')
      ? `${methods.getValues().newFileName}.${originalFileName.split('.').pop()}`
      : methods.getValues().newFileName;

    if (originalFileName === newFileName) {
      handleCancel();
      return;
    }
    try {
      await editDocument({ originalFileName, newFileName, folderFiles });
    } finally {
      setEdit(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      showModal({
        component: 'CONFIRM_DELETE',
        props: {
          title: 'placement.document.delete',
          subtitle: documentName,
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            submitHandler: async () => {
              dispatch(hideModal());
              await deleteDocument({ fileName: documentName });
            },
          },
        },
      })
    );
  };

  const handleCancel = () => {
    setEdit(false);
    methods.reset();
  };

  const methods = useForm({
    mode: 'onChange',
  });

  const isEllipsisActive = (e) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (titleRef?.current) {
      setIsTitleLong(isEllipsisActive(titleRef.current));
    }
  }, [titleRef]);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const { data } = await binderManagementFacilitiesDocumentsAxios.get(`${umr}/${folder}/${documentName}`, { responseType: 'blob' });

      utils.file.download(data, documentName);
    } catch (e) {
      dispatch(enqueueNotification('notification.binderManagement.documentDownloadFail', 'error'));
    }
    setLoading(false);
  };

  return (
    <ListItem className={classes.listItem}>
      {isEdit ? (
        <DocumentEditTitle
          methods={methods}
          originalFileName={documentName}
          classes={classes}
          isLoading={isEditing}
          handlers={{
            handleCancel,
            handleSave,
          }}
        />
      ) : (
        <>
          <ListItemText>
            <Tooltip title={isTitleLong ? documentName : ''}>
              <Typography ref={titleRef} variant="h4" className={classes.documentTitle}>
                {documentName}
              </Typography>
            </Tooltip>
          </ListItemText>

          <ListItemIcon className={classes.folderIconWrapper}>
            <ButtonBase onClick={handleEdit} className={classes.folderIconButton} data-testid={`document-edit-${documentName}`}>
              <Edit />
            </ButtonBase>
          </ListItemIcon>
          <ListItemIcon className={classes.folderIconWrapper}>
            <ButtonBase onClick={handleDownload} className={classes.folderIconButton} data-testid={`document-download-${documentName}`}>
              {isLoading ? <CircularProgress size={24} /> : <CloudDownload data-testid="document-download-icon" />}
            </ButtonBase>
          </ListItemIcon>

          <ListItemIcon className={classes.folderIconWrapper}>
            <ButtonBase onClick={handleDelete} className={classes.folderIconButton} data-testid={`document-delete-${documentName}`}>
              {isDeletting ? <CircularProgress size={24} /> : <Delete data-testid="document-delete-icon" />}
            </ButtonBase>
          </ListItemIcon>
        </>
      )}
    </ListItem>
  );
};

const DocumentEditTitle = ({ handlers, originalFileName, classes, methods, isLoading }) => {
  const handleSave = () => handlers.handleSave(originalFileName);

  const handleFocus = (e) => e.target.select();
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      handlers.handleCancel();
    }
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <>
      <FormText
        control={methods.control}
        name="newFileName"
        muiComponentProps={{
          onKeyDown: handleKeyDown,
          onFocus: handleFocus,
          onBlur: handlers.handleCancel,
          autoFocus: true,
          className: classes.documentEdit,
        }}
        defaultValue={originalFileName.replace(/\.[^/.]+$/, '')}
        disabled={isLoading}
      />
      <ListItemIcon className={classes.folderIconWrapper}>
        <ButtonBase onClick={handlers.handleCancel} className={classes.folderIconButton} data-testid="document-cancel">
          <Cancel />
        </ButtonBase>
      </ListItemIcon>
      <ListItemIcon className={classes.folderIconWrapper}>
        <ButtonBase onClick={handleSave} className={classes.folderIconButton} disabled={isLoading} data-testid="document-save">
          {isLoading ? <CircularProgress size={24} /> : <Check data-testid="document-save-icon" disabled={isLoading} />}
        </ButtonBase>
      </ListItemIcon>
    </>
  );
};
