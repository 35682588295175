const styles = (theme) => ({
  root: (props) => ({
    width: props.fullwidth ? '100%' : 'auto',
    ...(props.isDialog && theme.mixins.modal.dialog.root),
  }),
  cancel: {
    marginLeft: -8,
  },
  legend: {
    '&:not(:first-child) > legend': {
      marginTop: '0px !important',
    },
    '& > legend': {
      marginBottom: 0,
    },
  },
});

export default styles;
