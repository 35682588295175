import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// app
import styles from './Link.styles';

// mui
import { makeStyles, Link as MuiLink } from '@material-ui/core';

LinkView.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['primary', 'secondary', 'neutral']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  nestedClasses: PropTypes.shape({
    root: PropTypes.string,
    link: PropTypes.string,
  }),
};

export function LinkView({ text, icon, iconPosition, color, disabled, onClick, nestedClasses, ...rest }) {
  const classes = makeStyles(styles, { name: 'Link' })({ color, disabled, icon, iconPosition, isHref: !!rest.href });
  const IconComponent = icon;

  const classesLink = {
    [classes.root]: true,
    [nestedClasses.link]: Boolean(nestedClasses.link),
  };

  return (
    <MuiLink variant="body2" onClick={onClick} className={classnames(classesLink)} {...rest}>
      {iconPosition === 'right' && <span className={classes.text}>{text}</span>}
      {icon && <IconComponent fontSize="inherit" className={classnames(classes.icon, nestedClasses.icon)} data-testid="link-icon" />}
      {iconPosition === 'left' && <span className={classes.text}>{text}</span>}
    </MuiLink>
  );
}
