import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { enqueueNotification } from 'stores';

import { binderManagementFileInputAxios } from '../../binderManagement.api';
import { BDX_UN_SUBMITTED_DATA, BDX_UN_SUBMITTED_LINES, BDX_UNMAPPED_LINES } from '../../binderManagement.queryKeys';
import { useHandleError } from '../../binderManagement.utils';

interface IAssignLinesDTO {
  requestId?: string;
}

type TAssignLinesMutationProps = (IAssignProcessedRowDTO & IAssignLinesDTO) | (IAssignUnprocessedRowDTO & IAssignLinesDTO);

interface IAssignLinesMutationResponse {
  groupId: string;
  rowIds: string[] | number[];
  requestId: string;
  result: Record<string, never> | IAssignUnprocessedRowResponse;
}

export const useAssignLinesToFacility = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = useHandleError({
    file: 'binderManagement.facilities.mutations.useAssignLinesToFacility',
    warning: 'notification.binderManagement.assignLinesFail',
  });

  const handleSuccess = ({ groupId, requestId }: IAssignLinesMutationResponse) => {
    queryClient.invalidateQueries(BDX_UN_SUBMITTED_DATA);
    queryClient.invalidateQueries([BDX_UNMAPPED_LINES, groupId]);
    queryClient.invalidateQueries([BDX_UN_SUBMITTED_LINES, groupId, requestId]);

    dispatch(enqueueNotification('notification.binderManagement.assignLines', 'success'));
  };

  return useMutation<IAssignLinesMutationResponse, AxiosError, TAssignLinesMutationProps>(
    async ({ groupId, rowIds, requestId = null, facilityReference = null }) => {
      if (!groupId || !rowIds || rowIds?.length === 0) throw new Error('Missing required params');

      const path = requestId ? `processed` : `unprocessed`;

      const { data } = await binderManagementFileInputAxios.put(path, {
        groupId,
        rowIds,
        facilityReference,
      });

      return {
        rowIds,
        groupId,
        requestId,
        result: data,
      };
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};
