import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

// app
import { Skeleton } from 'components';
import styles from './DepartmentAccountsCalendarTable.styles';
import { DepartmentAccountsCalendarTableView } from './DepartmentAccountsCalendarTable.view';
import { selectCalendarViewEdit, selectPlacementId, selectRefDataStatusesPlacement } from 'stores';
import { DEPARTMENT_ACCOUNTS, useGetDepartmentAccounts } from 'lib/placements';

import * as utils from 'utils';

// mui
import { makeStyles } from '@material-ui/core';

const DepartmentAccountsCalendarTable = ({ cols, handlers, deptId, monthDetails }) => {
  const params = {
    month: monthDetails.month,
    year: monthDetails.year,
  };
  const [firstItem, setFirstItem] = useState(0);
  const placementSelectedId = useSelector(selectPlacementId);
  const refDataStatusesPlacement = useSelector(selectRefDataStatusesPlacement);
  const calendarViewEdit = useSelector(selectCalendarViewEdit);
  const queryClient = useQueryClient();
  const {
    data: items,
    isLoading,
    isError,
  } = useGetDepartmentAccounts({
    departmentId: deptId,
    params,
  });

  const today = utils.date.today();
  const classes = makeStyles(styles, { name: 'DepartmentAccountsCalendarTable' })({ wide: true });

  useEffect(() => {
    if (today === monthDetails.date && items?.length) {
      const firstItemToday = items.findIndex((item) => item.inceptionDate >= today);

      firstItemToday > 0 && setFirstItem(firstItemToday);
    }
  }, [today, monthDetails, items]);

  useEffect(() => {
    if (calendarViewEdit?.id) {
      queryClient.setQueryData([DEPARTMENT_ACCOUNTS, deptId, params], (oldListItems) => {
        return oldListItems
          ?.map((item) => (item.id === calendarViewEdit.id ? calendarViewEdit : item))
          .filter((item) => {
            return item.isHidden === false && deptId === item.departmentId;
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewEdit]);

  if (isLoading) {
    return (
      <div className={classes.monthBox} data-testid="department-accounts-table-loading">
        <Skeleton height={40} animation="wave" displayNumber={10} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.monthBox} data-testid="department-accounts-table-isError">
        <Skeleton height={40} animation="wave" displayNumber={10} />
      </div>
    );
  }

  return (
    <DepartmentAccountsCalendarTableView
      isLoading={isLoading}
      items={items}
      firstItem={firstItem}
      cols={cols}
      placementId={placementSelectedId}
      placementStatuses={refDataStatusesPlacement}
      handleClickRow={handlers.handleClickRow}
      handleNtuClick={handlers.handleNtuClick}
      handleEditPlacementClick={handlers.handleEditPlacementClick}
      handleRemovePlacementClick={handlers.handleRemovePlacementClick}
    />
  );
};

DepartmentAccountsCalendarTable.propTypes = {
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  monthDetails: PropTypes.shape({
    month: PropTypes.string.isRequired,
    monthName: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    handleClickRow: PropTypes.func,
    handleNtuClick: PropTypes.func,
    handleEditPlacementClick: PropTypes.func,
    handleRemovePlacementClick: PropTypes.func,
  }).isRequired,
};

export default DepartmentAccountsCalendarTable;
