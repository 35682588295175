import { useQuery } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_ENDORSEMENT_DATA } from 'lib/quoteBind/queryKeys';

// risks
export const useGetEndorsementRiskData = (riskId, effectiveFrom) => {
  const formattedEffectiveFrom = utils.date.formatDateWithParameter(effectiveFrom, 'YYYY-MM-DDTHH:mm:ss');
  const params = {
    atDate: formattedEffectiveFrom,
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useGetEndorsementRiskData',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [QB_RISK_ENDORSEMENT_DATA, riskId],
    async () => {
      // GET /api/v1/risks/{riskId}?atDate=<DATE>
      const result = await quoteBindAxios.get(`/risks/${riskId}`, {
        params,
      });
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      enabled: false,
    }
  );
};
