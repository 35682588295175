import { CircularProgress, Paper, Table, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';

import { useBDXUploadCardStyles } from './BDXUploadCard.styles';
import { BDX_TYPE_CLAIM } from 'consts';

export const BDXUploadCardSkeleton = ({ bdxItem }) => {
  const classes = useBDXUploadCardStyles();

  return (
    <div className={classes.root} data-testid="bdx-upload-card-loading">
      <TableContainer component={Paper} elevation={2} className={classes.tableContainer} data-testid="bdx-upload-card">
        <Table className={classes.table} aria-label="BDX overview table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Tab Name</TableCell>
              {bdxItem.bdxType === BDX_TYPE_CLAIM && <TableCell>TPA</TableCell>}
              <TableCell>Uploaded</TableCell>
              <TableCell>Validation</TableCell>
              <TableCell>Sanction</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      </TableContainer>
    </div>
  );
};
