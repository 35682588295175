import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { QB_RISK_COVERAGES } from 'lib/quoteBind/queryKeys';
// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

import { quoteBindAxios } from '../quoteBind.api';

// risks
export const usePostCoverages = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.usePostCoverages',
    };

    dispatch(enqueueNotification('notification.coverage.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_COVERAGES, res.riskId);

    dispatch(enqueueNotification('notification.coverage.postSuccess', 'success'));
  };

  return useMutation(
    async (coverage) => {
      const { riskId, riskType, data, definitions, coverageId, isEdit = false } = coverage;

      if (!data || isEmpty(data) || !riskId || !riskType) {
        throw new Error('Invalid data');
      }

      const body = {
        ...utils.risk.parsedValues(utils.risk.filterConditionalValues(data, definitions), definitions),
        riskType,
      };
      const path = isEdit && coverageId ? `risks/${riskId}/coverages/${coverageId}` : `risks/${riskId}/coverages`;

      const result = await quoteBindAxios.post(path, body);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
