import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// app
import { ProductsAdminCarriersView } from './ProductsAdminCarriers.view';
import { showModal } from 'stores';
import { useGetCarriers, QB_CARRIERS } from 'lib/quoteBind';
import { productAdminSchema } from 'schemas';
import { usePagination } from 'hooks';
import * as utils from 'utils';

export default function ProductsAdminCarriers() {
  const dispatch = useDispatch();
  const [carriersParams, setCarriersParams] = useState({ size: 10 });
  const { data: carriersData, isLoading: carriersLoading } = useGetCarriers(QB_CARRIERS, carriersParams);

  const carriers = useMemo(() => carriersData?.content || [], [carriersData]);
  const carriersPagination = {
    page: carriersData?.pagination?.page ? carriersData?.pagination?.page - 1 : 1,
    rowsTotal: carriersData?.pagination?.totalElements || 0,
    rowsPerPage: carriersData?.pagination?.size || 10,
  };
  const hydrateLabels = (fields) => fields.map((field) => ({ ...field, label: utils.string.t(field.label) }));

  const { fields, ...rest } = productAdminSchema.getSchema('carriers');

  const schema = {
    ...rest,
    fields: hydrateLabels(fields),
    items: carriers,
    pagination: carriersPagination,
  };

  const handleChangePage = (newPage) => {
    setCarriersParams((params) => ({ ...params, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setCarriersParams((params) => ({ ...params, size: rowsPerPage }));
  };

  const handleAddCarrier = (fields) => (event) => {
    dispatch(
      showModal({
        component: 'ADD_PRODUCTS_CARRIER',
        props: {
          title: 'products.admin.carriers.add',
          fullWidth: true,
          maxWidth: 'lg',
          componentProps: {
            fields,
          },
        },
      })
    );
  };

  const pagination = usePagination(schema.items, schema.pagination, handleChangePage, handleChangeRowsPerPage);

  // abort
  if (!schema || !schema.items) return null;

  return (
    <ProductsAdminCarriersView
      schema={schema}
      loading={carriersLoading}
      pagination={pagination.obj}
      handlers={{
        addCarrier: handleAddCarrier,
        changePage: pagination.handlers.handleChangePage,
        changeRowsPerPage: pagination.handlers.handleChangeRowsPerPage,
      }}
    />
  );
}
