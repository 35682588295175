import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_COVERAGES } from 'lib/quoteBind/queryKeys';

// risks
export const useDeleteCoverages = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useDeleteCoverages',
    };

    dispatch(enqueueNotification('notification.deleteCoverages.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_COVERAGES, res.riskId);

    dispatch(enqueueNotification('notification.deleteCoverages.success', 'success'));
  };

  return useMutation(
    async ({ coverageId, riskId }) => {
      try {
        if (!coverageId || !riskId) {
          throw new Error('useDeleteCoverages: coverageId and riskId are required');
        }

        const path = `risks/${riskId}/coverages/${coverageId}`;

        const result = await quoteBindAxios.delete(path);
        return result?.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
