import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import * as utils from 'utils';
import { Button } from 'components';
import { useBDXStyles } from './BDX.styles';
import { BDXSkeleton } from './BDXSkeleton';
import { BDXCard } from './components';
import { useBDXStickyHeaders } from './utils/useBDXStickyHeaders';
import AddIcon from '@material-ui/icons/Add';

export const BDXView = ({ bordereaux, uploadBDX, facility, binderType, handleLoadMore, isFetchingNextPage }) => {
  const classes = useBDXStyles();

  const headersRef = useBDXStickyHeaders({ classes });

  if (!bordereaux) {
    return null;
  }

  return (
    <div className={classes.root} data-testid="BDXView">
      {bordereaux.map((bdxYear, index) => (
        <React.Fragment key={bdxYear.year}>
          <div ref={(el) => (headersRef.current[index] = el)} className={classes.yearHeader}>
            <Typography variant="h2" className={classes.yearHeaderText}>
              {bdxYear.year}
            </Typography>
            <div className={classes.yearHeaderBox} />
          </div>
          <Grid container className={classes.bdxList} data-testid="BDXView-year">
            {bdxYear.items.map((bdx) => {
              const [year, month] = bdx.yearMonthSubmission?.split('-');
              const date = new Date(year, month - 1, '01');
              const monthName = date.toLocaleString('default', { month: 'long' });

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cellWrapper} key={`${monthName}-${year}`}>
                  <BDXCard
                    bdxInfo={{ ...bdx, monthName, year }}
                    uploadBDX={uploadBDX}
                    facility={facility}
                    binderType={binderType}
                    classes={classes}
                  />
                </Grid>
              );
            })}
          </Grid>
        </React.Fragment>
      ))}
      {isFetchingNextPage && <BDXSkeleton />}

      <div className={classes.buttonWrapper}>
        <Button
          text={utils.string.t('bdx.loadMore')}
          icon={AddIcon}
          color="primary"
          onClick={handleLoadMore}
          disabled={isFetchingNextPage}
        />
      </div>
    </div>
  );
};
