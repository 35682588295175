import { makeStyles } from '@material-ui/core/styles';

export const useSummaryTableStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    flex: 1,
    margin: theme.spacing(3),
  },
  header: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    color: `${theme.palette.neutral.darker}!important`,
    marginBottom: theme.spacing(2),
  },
  title: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    lineHeight: '1.2em',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    width: '100%',
  },
  wordWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  baseTable: {
    display: 'flex',
    flex: '1 1 auto',
    margin: 'auto',
    height: 'auto',
    width: '100%',
    '& .BaseTable__row-cell': {
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  actionButton: {
    marginRight: '0!important',
  },
  rowSelected: {
    backgroundColor: `${theme.palette.grey[300]}!important`,
  },
}));
