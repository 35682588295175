import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { AddProductsInsuredView } from './AddProductsInsured.view';
import { useGetClients, useGetRiskCountries, useGetProducts, usePostInsured, QB_CLIENTS, RISK_COUNTRIES, QB_PRODUCTS } from 'lib/quoteBind';
import * as utils from 'utils';

AddProductsInsured.propTypes = {
  fields: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  reInsured: PropTypes.bool.isRequired,
};

AddProductsInsured.defaultProps = {
  handleClose: () => {},
  reInsured: false,
};

export default function AddProductsInsured({ fields, handleClose, reInsured }) {
  const { data: clientsContent } = useGetClients(QB_CLIENTS, { size: 1000 });
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { data: programmesProductsData } = useGetProducts(QB_PRODUCTS);
  const { mutateAsync: postInsured } = usePostInsured();

  const clients = useMemo(() => clientsContent?.content || [], [clientsContent]);
  const products = useMemo(() => programmesProductsData?.data || [], [programmesProductsData]);
  const options = useMemo(() => ({ countries, clients, products }), [countries, clients, products]);

  const submitLabel = reInsured ? 'products.admin.reInsureds.create' : 'products.admin.insureds.create';

  const hydrateOptions = (fields) => {
    if (!fields) return [];

    return fields.map((field) => {
      const fieldOptions = field?.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options
        ? field.options.map((option) => ({ ...option, label: utils.string.t(option.label) }))
        : [];

      return {
        ...field,
        options: fieldOptions,
        ...(field?.optionsDefaultValue && {
          value: fieldOptions?.find((option) => option.default === true),
        }),
      };
    });
  };

  const handleSubmit = (values) => {
    return postInsured({
      body: values,
      isCreateInsuredModal: false,
      reInsured,
      isEdit: false,
    });
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t(submitLabel),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  const hasFields = utils.generic.isValidArray(fields, true);
  const hydratedFields = [hydrateOptions(fields)];

  // abort
  if (!hasFields || utils.generic.isInvalidOrEmptyArray(options?.countries) || utils.generic.isInvalidOrEmptyArray(options?.clients)) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  return (
    <AddProductsInsuredView
      fields={hydratedFields}
      actions={actions}
      defaultValues={utils.form.getInitialValues(hydratedFields)}
      validationSchema={utils.form.getValidationSchema(hydratedFields)}
    />
  );
}
