import { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { Button, ModalDialog } from 'components';

import { useGetUserList, useOverrideUser } from 'lib/admin';

import * as utils from 'utils';
import { TUser, UserInfo, UserSearch } from './UserSearch/UserSearch';

export const UserOverride = ({ targetUserId }: { targetUserId: number }) => {
  const [open, setOpen] = useState(false);
  const [sourceUser, setSourceUser] = useState<TUser | null>(null);

  const { data: userList } = useGetUserList({ size: 10000 });
  const { mutateAsync: overrideUser, isLoading: isOverrideUserLoading } = useOverrideUser();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    sourceUser && setSourceUser(null);
  };

  const handleChange = (newValue: TUser | null) => {
    setSourceUser(newValue);
  };

  const handleConfirm = async () => {
    if (sourceUser) {
      // @ts-ignore
      await overrideUser({
        userIdTo: targetUserId,
        userIdFrom: sourceUser.id,
      });
      handleClose();
    }
  };

  const actions = [
    {
      name: 'cancel',
      type: 'cancel',
      color: 'default',
      label: utils.string.t('app.cancel'),
      callback: handleClose,
      isDisabled: isOverrideUserLoading,
    },
    {
      name: 'submit',
      type: 'submit',
      color: 'primary',
      label: utils.string.t('app.confirm'),
      disabled: !sourceUser || isOverrideUserLoading,
      callback: handleConfirm,
    },
  ];

  return (
    <Box>
      <Button text={utils.string.t('admin.overrideUser.button')} variant="contained" color="primary" onClick={handleClickOpen} />

      <ModalDialog
        title={utils.string.t('admin.overrideUser.title')}
        fullWidth
        enableFullScreen={false}
        maxWidth="sm"
        cancelHandler={handleClose}
        hideModal={handleClose}
        visible={open}
        actions={actions}
      >
        <Box p={2}>
          <Box>
            <Box
              style={{
                marginBottom: 20,
              }}
            >
              {utils.string.t('admin.overrideUser.search')}
            </Box>
            <Box>
              <UserSearch options={userList?.content ?? []} handleChange={handleChange} disabled={isOverrideUserLoading} />
            </Box>
            {sourceUser && (
              <Box data-testid="selected-user">
                <Box style={{ marginTop: 20 }}>Selected User:</Box>
                <Box>
                  <UserInfo user={sourceUser} />
                </Box>
              </Box>
            )}
            {isOverrideUserLoading && (
              <Typography
                variant="body1"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {utils.string.t('admin.overrideUser.loading')}
              </Typography>
            )}
          </Box>
        </Box>
      </ModalDialog>
    </Box>
  );
};
