import { useQuery } from 'react-query';

// app
import { adminAxios } from '../../admin.api';
import { CLIENT_OFFICE_LIST } from '../../queryKeys';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// findOperationalClientOffices
export const useGetOperationalClientOffices = (parentId) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.useGetOperationalClientOffices',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [CLIENT_OFFICE_LIST, parentId],
    async () => {
      const result = await adminAxios.get(`/client/operational/${parentId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
