import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// mui
import { makeStyles } from '@material-ui/core';

import { Button, FormActions, FormCheckbox, FormContainer, FormFields, FormGrid, FormSwitch, FormText } from 'components';

import * as utils from 'utils';

// app
import styles from './AddEditReport.styles';

export const AddEditReportView = ({ fields, actions }) => {
  const classes = makeStyles(styles, { name: 'AddEditReport' })();

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, reset, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  const cancel = actions && actions.find((action) => action.name === 'cancel');
  const submit = actions && actions.find((action) => action.name === 'submit');

  const onCancel = () => {
    if (cancel && utils.generic.isFunction(cancel.handler)) {
      cancel.handler();
    }
    reset();
  };

  const onSubmit = (data) => submit && utils.generic.isFunction(submit.handler) && submit.handler({ id: defaultValues.id, ...data });
  const isSubmitDisabled = !formState.isDirty;

  const getDataSetId = (allFields, fieldName, rowLevelSecurityValue, validation) => {
    const field = utils.form.getFieldProps(allFields, fieldName);

    if (rowLevelSecurityValue === false) {
      setValue(fieldName, '');
    }

    return {
      ...field,
      validation: validation ? Yup.string().required(utils.string.t('reporting.form.dataSetId.required')) : null,
      muiComponentProps: {
        ...field.muiComponentProps,
        ...(rowLevelSecurityValue === false && { disabled: true }),
      },
    };
  };

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(onSubmit)} data-testid="form-edit-reportGroup">
        <FormFields type="dialog">
          <FormGrid container>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'report')} control={control} error={errors.report} />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'powerbiReportId')} control={control} error={errors.powerbiReportId} />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormGrid container>
                <FormGrid item xs={4}>
                  <FormSwitch {...utils.form.getFieldProps(fields, 'rowLevelSecurity')} control={control} error={errors.rowLevelSecurity} />
                </FormGrid>
                <FormGrid item xs={8}>
                  <FormText
                    {...getDataSetId(fields, 'dataSetId', watch('rowLevelSecurity'), true)}
                    control={control}
                    error={errors.dataSetId}
                  />
                </FormGrid>
                <FormGrid item xs={12}>
                  <FormCheckbox
                    {...getDataSetId(fields, 'showAllData', watch('rowLevelSecurity'))}
                    control={control}
                    error={errors.showAllData}
                  />
                </FormGrid>
              </FormGrid>
            </FormGrid>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'workspaceId')} control={control} error={errors.workspaceId} />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'description')} control={control} error={errors.description} />
            </FormGrid>
          </FormGrid>
        </FormFields>

        <FormActions type="dialog">
          {cancel && <Button text={cancel.label} variant="text" onClick={onCancel} disabled={formState.isSubmitting || isSubmitDisabled} />}
          {submit && <Button text={submit.label} type="submit" disabled={formState.isSubmitting || isSubmitDisabled} color="primary" />}
        </FormActions>
      </FormContainer>
    </div>
  );
};

AddEditReportView.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape),
  actions: PropTypes.arrayOf(PropTypes.shape),
};
