import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';

import { List, Box, CircularProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useDocumentsStyle } from './Documents.style';

import { Translate, Button, Empty } from 'components';
import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
import { showModal } from 'stores';
import * as utils from 'utils';
import { useGetDocumentsFolders } from 'lib/binderManagement';
import { Folder } from './components';

export const Documents = () => {
  const { facilityUMR } = useParams();
  const { data: folders, isFetching: isLoading } = useGetDocumentsFolders({ umr: facilityUMR });

  const classes = useDocumentsStyle();
  const dispatch = useDispatch();

  const buttonMotion = {
    rest: {
      scale: 1,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      scale: 1.04,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  const uploadDoc = () => {
    dispatch(
      showModal({
        component: 'UPLOAD_FACILITY_DOCUMENT',
        props: {
          title: 'documents.upload.modal.title',
          fullWidth: true,
          maxWidth: 'sm',
          disableAutoFocus: true,
          componentProps: {
            umr: facilityUMR,
          },
        },
      })
    );
  };

  const NoResult = () => <Empty width={400} title={utils.string.t('app.noDocuments')} icon={<SearchResultImage />} padding />;

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
        <motion.div initial="rest" whileHover={isLoading ? 'rest' : 'hover'} animate="rest" data-testid="uploadButton">
          <Button
            component={motion.div}
            variants={buttonMotion}
            icon={CloudUploadIcon}
            iconWide={true}
            color="primary"
            text={<Translate label={'app.upload'} />}
            onClick={uploadDoc}
            disabled={isLoading}
          />
        </motion.div>
      </Box>

      <List className={classes.folderList} data-testid="folders-list">
        {isLoading ? (
          <div className={classes.rootLoader} data-testid="documents-loading">
            <CircularProgress />
          </div>
        ) : !folders || folders?.folderList.length === 0 ? (
          <NoResult />
        ) : (
          folders?.folderList.map((folder) => (
            <Folder key={folder.folderName} folder={folder} classes={classes} umr={facilityUMR} dispatch={dispatch} />
          ))
        )}
      </List>
    </div>
  );
};
