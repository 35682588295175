import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { EditProductsInsuredView } from './EditProductsInsured.view';
import {
  useGetClients,
  useGetRiskCountries,
  useGetProducts,
  useGetInsuredDetail,
  usePostInsured,
  QB_CLIENTS,
  RISK_COUNTRIES,
  QB_PRODUCTS,
  QB_INSURED,
} from 'lib/quoteBind';
import * as utils from 'utils';

import { productAdminSchema } from 'schemas';

EditProductsInsured.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  reInsured: PropTypes.bool.isRequired,
  isCreateInsuredModal: PropTypes.bool,
  isAddRiskForm: PropTypes.bool,
};

EditProductsInsured.defaultProps = {
  handleClose: () => {},
  reInsured: false,
};

export default function EditProductsInsured({
  id,
  submitHandler,
  handleClose,
  reInsured,
  isCreateInsuredModal = false,
  isAddRiskForm = false,
}) {
  const { data: clientsContent } = useGetClients(QB_CLIENTS, { size: 1000 });
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { data: programmesProductsData } = useGetProducts(QB_PRODUCTS);
  const { data, isLoading } = useGetInsuredDetail(QB_INSURED, id, {}, reInsured);
  const { mutateAsync: postInsured } = usePostInsured();

  const clients = useMemo(() => clientsContent?.content || [], [clientsContent]);
  const products = useMemo(() => programmesProductsData?.data || [], [programmesProductsData]);
  const options = useMemo(() => ({ countries, clients, products }), [countries, clients, products]);

  if (isLoading || !data) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  const { address, ...rest } = data;
  const item = { ...rest, ...address };

  const submitLabel = reInsured ? 'products.admin.reInsureds.update' : 'products.admin.insureds.update';
  const { fields } = productAdminSchema.getSchema('insureds');

  const hydrateLabels = (fields, isAddRiskForm) =>
    fields.map((field) => {
      return {
        ...field,
        ...(isAddRiskForm && { type: field?.hideInAddRiskForm ? 'hidden' : field.type }),
        label: utils.string.t(field.label),
        ...(field.options && { options: field.options.map((option) => ({ ...option, label: utils.string.t(option.label) })) }),
      };
    });

  const schema = { fields: hydrateLabels(fields, isAddRiskForm) };

  const hydrateOptions = (fields) => {
    if (!fields || !item) return [];

    const getOptionValue = (field, itemValue) => {
      const optionsList = field.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options;
      const [result] = optionsList.filter((option) => option.value === itemValue);

      return result;
    };

    return fields?.map((field) => {
      return {
        ...field,
        value: field.type === 'autocompletemui' ? getOptionValue(field, item[field.name]) : item[field.name] || '',
        ...(field.type !== 'autocompletemui' && {
          muiComponentProps: {
            ...field.muiComponentProps,
          },
        }),
        ...(field.optionsDynamicKey && {
          options: utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          }),
        }),
      };
    });
  };

  const handleSubmit = async (values) => {
    const updateValues = { id: item?.id, ...values };
    const response = await postInsured({
      body: updateValues,
      isCreateInsuredModal,
      reInsured,
      isEdit: true,
    });

    const { data } = response?.result || {};

    if (data) {
      // success
      if (utils.generic.isFunction(handleClose)) {
        if (utils.generic.isFunction(handleClose)) {
          handleClose();
        }
        return submitHandler(data);
      }
    }
    // fail
    handleCancel();
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t(submitLabel),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  const hasFields = utils.generic.isValidArray(schema.fields);

  // abort
  if (
    !hasFields ||
    !utils.generic.isValidObject(item) ||
    utils.generic.isInvalidOrEmptyArray(options?.clients) ||
    utils.generic.isInvalidOrEmptyArray(options?.countries)
  ) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }
  const hydratedFields = [hydrateOptions(schema.fields)];

  const defaultValues = utils.form.getInitialValues(hydratedFields);

  return (
    <EditProductsInsuredView
      fields={hydratedFields}
      actions={actions}
      defaultValues={defaultValues}
      validationSchema={utils.form.getValidationSchema(hydratedFields)}
    />
  );
}
