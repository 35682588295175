import React from 'react';
import classnames from 'classnames';

// MUI
import { Stepper, Step, StepLabel, LinearProgress } from '@material-ui/core';

import { useUploadBDXStyles } from './UploadBDX.styles';
import { UPLOAD_BDX_STEPS } from 'consts';
import { UploadFile } from './UploadFile';
import { FieldMapping } from './FieldMapping';
import { Summary } from './Summary';
import { UploadBDXLoader } from './components';

export default function UploadBDXView({
  file,
  steps,
  completed,
  progress,
  activeStep,
  isLoading,
  error,
  fullScreen,
  handlers,
  callbackOnSuccess,
  isTest,
}) {
  const classes = useUploadBDXStyles();
  const getContent = () => {
    switch (activeStep) {
      case UPLOAD_BDX_STEPS.UPLOAD_FILE:
        return <UploadFile fullScreen={fullScreen} handlers={handlers} defaultFile={file} error={error} />;

      case UPLOAD_BDX_STEPS.FIELD_MAPPING:
        return <FieldMapping handlers={handlers} />;

      case UPLOAD_BDX_STEPS.SUMMARY:
        return <Summary fullScreen={fullScreen} handlers={handlers} callbackOnSuccess={callbackOnSuccess} isTest={isTest} />;

      default:
        return null;
    }
  };

  return (
    <div className={classnames(classes.root, { [classes.rootModal]: !fullScreen })}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map(({ label, id }) => (
          <Step key={id} completed={completed[id]}>
            <StepLabel StepIconProps={{ classes: { root: classes.iconContainer } }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {isLoading ? (
        <LinearProgress className={classes.linearProgress} />
      ) : (
        <LinearProgress variant="determinate" value={progress} className={classes.linearProgress} />
      )}
      {isLoading ? <UploadBDXLoader /> : getContent()}
    </div>
  );
}
