const styles = (theme) => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      overflowY: 'auto',
    },
  },
  grid: {
    backgroundColor: 'white',

    [theme.breakpoints.up('md')]: {
      height: '100%',
      overflowY: 'auto',
    },
    '&:not(:last-child)': {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
      },
    },
    '&:not(:first-child)': {
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',

    [theme.breakpoints.down('sm')]: {
      pointerEvents: (p) => (p.hasSidebar && !p.collapsed ? 'none' : 'auto'),
    },

    [theme.breakpoints.only('sm')]: {
      width: (p) => (p.hasSidebar ? `calc(100% - ${theme.mixins.panel.width.collapsed}px)` : 'auto'),
    },

    [theme.breakpoints.up('md')]: {
      flex: (p) => (p.hasSidebar ? `1 1 66.6666%` : '1 1 100%'),
    },

    [theme.breakpoints.up('lg')]: {
      flex: (p) => (p.hasSidebar ? `1 1 66.6666%` : p.isCentered ? '1 1 100%' : '0 1 80%'),
    },
  },
  sidebar: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: theme.mixins.header.height - 1,
      left: (p) =>
        p.collapsed ? `calc(100% - ${theme.mixins.panel.width.collapsed}px)` : `calc(100% - ${theme.mixins.panel.width.tablet}px)`,
      bottom: 0,
      width: theme.mixins.panel.width.tablet,
      zIndex: 4,
      overflow: 'visible',
      backgroundColor: 'white',
      boxShadow: (p) =>
        p.collapsed ? 'none' : `-1px 0px 2px 0px ${theme.palette.neutral.light}, -2px 0px 2px 0px ${theme.palette.neutral.lighter}`,
      transition: theme.transitions.create(['left', 'background-color'], { duration: theme.transitions.duration.enteringScreen }),

      // hiding everything except the sidebar handle
      '& > *:not(:first-child)': {
        opacity: (p) => (p.collapsed ? 0 : 1),
        pointerEvents: (p) => (p.collapsed ? 'none' : 'auto'),
      },
    },

    [theme.breakpoints.down('xs')]: {
      left: (p) => (p.collapsed ? '100%' : '20%'),
      width: '80%',
    },

    [theme.breakpoints.up('md')]: {
      flex: '1 1 33.3333%',
    },
  },
  sidebarScroll: {
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: (p) => (p.collapsed ? 0 : '100%'),
    opacity: (p) => (p.collapsed ? 0 : 1),
    zIndex: 4,
    backgroundColor: theme.palette.neutral.main,
    transition: (p) =>
      p.collapsed
        ? `
      height ${theme.transitions.duration.leavingScreen}ms step-end,
      opacity ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}
    `
        : `
      height ${theme.transitions.duration.leavingScreen}ms step-start,
      opacity ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}
    `,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  handle: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'flex-end',
      top: 0,
      left: 0,
      width: (p) => (p.collapsed ? theme.mixins.panel.width.collapsed : theme.spacing(4)),
      height: '100%',
      borderLeft: `1px solid ${theme.palette.neutral.light}`,
      backgroundColor: 'white',
      cursor: (p) => (p.collapsed ? 'w-resize' : 'e-resize'),
      zIndex: 1,
      transition: theme.transitions.create(['border']),

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 'calc(50% - 10px)',
        left: (p) => (p.collapsed ? -2 : -5),
        width: 10,
        height: 10,
        border: `0 solid ${theme.palette.neutral.lighter}`,
        borderTopWidth: '2px',
        borderLeftWidth: '2px',
        opacity: 0,
        transform: (p) => (p.collapsed ? 'rotate(-45deg)' : 'rotate(135deg)'),
        transition: theme.transitions.create(['opacity', 'left'], { duration: theme.transitions.duration.shorter }),
      },

      '&:hover': {
        borderLeftColor: theme.palette.neutral.medium,

        '&:after': {
          opacity: 1,
          left: (p) => (p.collapsed ? -10 : 0),
        },
      },
    },

    [theme.breakpoints.down('xs')]: {
      top: 'auto',
      right: '100%',
      bottom: 100,
      left: 'auto',
      width: (p) => (p.collapsed ? theme.spacing(6) : theme.spacing(4)),
      height: theme.spacing(8),
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      border: '1px solid',
      borderColor: (p) => (p.collapsed ? theme.palette.grey.A100 : theme.palette.grey.A200),
      borderRight: 0,
      boxShadow: '-1px 0px 1px 0 rgba(0,0,0,0.12)',
      transition: theme.transitions.create(['width']),

      '&:after': {
        display: 'none',
      },
    },

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  handleBtn: {
    margin: '10px auto',
    width: 42,
    height: 42,
    lineHeight: 1,

    [theme.breakpoints.only('sm')]: {
      display: (p) => (p.collapsed ? 'block' : 'none'),
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      minWidth: `${theme.spacing(4)}px !important`,
      margin: 0,
    },

    '& > span': {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        marginLeft: (p) => (p.collapsed ? 0 : theme.spacing(1)),
      },
    },
  },
  handleIcon: {
    transform: 'scaleX(1)',
    fontSize: '1.5rem',

    [theme.breakpoints.down('sm')]: {
      transform: (p) => (p.collapsed ? 'scaleX(-1)' : 'none'),
    },
  },
  panel: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 320,
      height: '100%',
      boxShadow: `-1px 0px 2px 0px ${theme.palette.neutral.light}, -2px 0px 2px 0px ${theme.palette.neutral.lighter}`,
    },
  },
  panelSidebar: {
    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
    },
  },
  content: {
    flex: '1 1 auto',
  },
  footer: {
    flex: '0 0 auto',
  },
});

export default styles;
