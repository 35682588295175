import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export default function useApi(queryKey, fetcher, options) {
  const auth = useAuth0();

  return useQuery({
    queryKey,
    queryFn: async () => {
      const accessToken = await auth.getAccessTokenSilently();

      return fetcher(queryKey[1], accessToken);
    },
    ...options,
  });
}
