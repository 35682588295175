import { useMemo } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import { FormAutocompleteMui, FormCheckbox, FormDate, FormSelect, FormText } from 'components';
// import { getSelectOptions } from './UploadFile';
import classnames from 'classnames';
import { BDX_TYPE_CLAIM } from 'consts';
import * as utils from 'utils';

export const getSelectOptions = (options) => {
  return options?.map((option, index) => ({
    label: option,
    id: index + 1,
  }));
};

export const BdxCoverHolderFields = ({
  facility,
  bdxType,
  storedFileUploadValues,
  isTest,
  yearMonthSubmission,
  thirdPartyId,
  operationalClientId,
  defaultBdxType,
}) =>
  useMemo(() => {
    const getDefaultBDXType = () => {
      if (bdxType) {
        return { label: bdxType };
      }
      if (defaultBdxType) {
        return { label: defaultBdxType };
      }
      if (facility?.bdxType) {
        return { label: facility?.bdxType };
      }
      return null;
    };

    return [
      {
        name: 'coverholder',
        label: utils.string.t('form.bdx.coverholder'),
        type: 'text',
        defaultValue: facility?.coverHolder || '',
      },
      {
        name: 'bdxType',
        type: 'autocomplete',
        optionKey: 'label',
        optionLabel: 'label',
        defaultValue: storedFileUploadValues?.bdxType || getDefaultBDXType(),
        label: utils.string.t('form.bdx.bdxType'),
      },
      {
        name: 'requestId',
        label: utils.string.t('form.bdx.requestId'),
        type: 'text',
      },
      {
        name: 'thirdPartyId',
        label: utils.string.t('form.bdx.thirdPartyId'),
        type: 'number',
      },
      {
        name: 'operationalClientId',
        label: utils.string.t('form.bdx.operationalClientId'),
        type: 'number',
        defaultValue: operationalClientId || '',
      },
      {
        name: 'headersOverNRows',
        type: 'number',
        defaultValue: 1,
        options: new Array(10).fill(1).map((_, index) => ({ id: index + 1, value: index + 1 })),
        optionKey: 'id',
        optionLabel: 'value',
      },
      {
        name: 'sheets',
        label: utils.string.t(isTest ? 'form.bdx.sheet' : 'form.bdx.sheets'),
        type: 'select',
        optionKey: 'label',
        optionLabel: 'label',
        defaultValue: storedFileUploadValues?.sheets || [],
        muiComponentProps: {
          multiple: !isTest,
        },
      },
      {
        name: 'yearMonthSubmission',
        label: utils.string.t('form.bdx.yearMonthSubmission'),
        type: 'datepicker',
        icon: 'TodayIcon',
        placeholder: 'MM YYYY',
        defaultValue: storedFileUploadValues?.yearMonthSubmission
          ? storedFileUploadValues?.yearMonthSubmission
          : yearMonthSubmission || null,
        muiComponentProps: {
          fullWidth: true,
        },
        muiPickerProps: {
          views: ['year', 'month'],
          format: 'MM YYYY',
        },
      },
      {
        name: 'thirdParty',
        label: utils.string.t('form.bdx.tpa'),
        type: 'select',
        optionKey: 'id',
        optionLabel: 'name',
        defaultValue: thirdPartyId || -1,
      },
      {
        id: 'bdxCumulative',
        name: 'bdxCumulative',
        type: 'checkbox',
        defaultValue: false,
        label: utils.string.t('form.facilityConfig.cumulativeBDX'),
      },
    ];
  }, [
    facility?.coverHolder,
    facility?.bdxType,
    storedFileUploadValues?.bdxType,
    storedFileUploadValues?.sheets,
    storedFileUploadValues?.yearMonthSubmission,
    isTest,
    yearMonthSubmission,
    bdxType,
    thirdPartyId,
    operationalClientId,
    defaultBdxType,
  ]);

export const BdxFields = ({ facility, bdxType, storedFileUploadValues, isTest, yearMonthSubmission, thirdPartyId }) =>
  useMemo(() => {
    const getDefaultBDXType = () => {
      if (bdxType) {
        return { label: bdxType };
      }
      if (facility?.bdxType) {
        return { label: facility?.bdxType };
      }
      return null;
    };

    return [
      {
        name: 'coverholder',
        label: utils.string.t('form.bdx.coverholder'),
        type: 'text',
        defaultValue: facility?.coverHolder || '',
      },
      {
        name: 'bdxType',
        type: 'autocomplete',
        optionKey: 'label',
        optionLabel: 'label',
        defaultValue: storedFileUploadValues?.bdxType || getDefaultBDXType(),
        label: utils.string.t('form.bdx.bdxType'),
      },
      {
        name: 'facility',
        label: utils.string.t('form.bdx.facility'),
        type: 'text',
        defaultValue: facility?.umr || '',
      },
      {
        name: 'sheets',
        label: utils.string.t(isTest ? 'form.bdx.sheet' : 'form.bdx.sheets'),
        type: 'select',
        optionKey: 'label',
        optionLabel: 'label',
        defaultValue: storedFileUploadValues?.sheets || [],
        muiComponentProps: {
          multiple: !isTest,
        },
      },
      {
        name: 'bdxPeriod',
        label: utils.string.t('form.bdx.period'),
        type: 'datepicker',
        icon: 'TodayIcon',
        placeholder: 'MM YYYY',
        defaultValue: storedFileUploadValues?.bdxPeriod ? storedFileUploadValues?.bdxPeriod : yearMonthSubmission || null,
        muiComponentProps: {
          fullWidth: true,
        },
        muiPickerProps: {
          views: ['year', 'month'],
          format: 'MM YYYY',
        },
      },
      {
        name: 'thirdParty',
        label: utils.string.t('form.bdx.tpa'),
        type: 'select',
        optionKey: 'id',
        optionLabel: 'name',
        defaultValue: thirdPartyId || -1,
      },
      {
        id: 'bdxCumulative',
        name: 'bdxCumulative',
        type: 'checkbox',
        defaultValue: false,
        label: utils.string.t('form.facilityConfig.cumulativeBDX'),
      },
    ];
  }, [
    facility?.coverHolder,
    facility?.umr,
    facility?.bdxType,
    storedFileUploadValues?.bdxType,
    storedFileUploadValues?.sheets,
    storedFileUploadValues?.bdxPeriod,
    isTest,
    yearMonthSubmission,
    bdxType,
    thirdPartyId,
  ]);

export const getBdxFields = (
  classes,
  sanctionsCheckOnly,
  fields,
  utils,
  methods,
  isTest,
  bdxTypesOptions,
  bdxTypeDefaultValue,
  setSelectedBDXType,
  sheets,
  bdxType,
  thirdParties,
  config,
  fullScreen
) => (
  <Grid container spacing={2} className={classnames({ [classes.uploadFileFullScreen]: fullScreen })}>
    {sanctionsCheckOnly && (
      <Grid item xs={4}>
        <FormText
          {...utils.form.getFieldProps(fields, 'coverholder')}
          control={methods.control}
          error={methods.formState.errors.coverholder}
          rules={{ required: 'Required' }}
          disabled={isTest}
        />
      </Grid>
    )}
    {sanctionsCheckOnly && (
      <Grid item xs={4}>
        <FormText
          {...utils.form.getFieldProps(fields, 'facility')}
          control={methods.control}
          error={methods.formState.errors.facility}
          rules={{ required: 'Required' }}
          disabled={isTest}
        />
      </Grid>
    )}
    <Grid item xs={4}>
      <FormAutocompleteMui
        {...utils.form.getFieldProps(fields, 'bdxType')}
        options={bdxTypesOptions}
        defaultValue={bdxTypeDefaultValue}
        control={methods.control}
        disableClearable
        error={methods.formState.errors.bdxType}
        rules={{ required: 'Required' }}
        onSelect={(selected) => {
          setSelectedBDXType(selected);
        }}
        disabled={isTest}
      />
    </Grid>
    {sheets?.length > 0 && (
      <Grid item xs={4}>
        <FormSelect
          {...utils.form.getFieldProps(fields, 'sheets')}
          rules={sheets?.length > 0 ? { required: 'Required' } : {}}
          options={getSelectOptions(sheets)}
          control={methods.control}
          error={methods.formState.errors.sheets}
        />
      </Grid>
    )}
    {!isTest && (
      <Grid item xs={4}>
        <FormDate
          {...utils.form.getFieldProps(fields, 'bdxPeriod')}
          rules={isTest ? {} : { required: 'Required' }}
          control={methods.control}
          error={methods.formState.errors.bdxPeriod}
        />
      </Grid>
    )}
    {bdxType?.label === BDX_TYPE_CLAIM && (
      <Grid item xs={4}>
        <FormSelect
          {...utils.form.getFieldProps(fields, 'thirdParty')}
          rules={{ required: 'Required' }}
          options={[...thirdParties, { id: -1, name: 'No TPA' }]}
          control={methods.control}
          error={methods.formState.errors.thirdParty}
          disabled={isTest}
        />
      </Grid>
    )}
    {!isTest && (
      <Grid item xs={4}>
        <Box
          style={{
            marginTop: '1.4rem',
          }}
        >
          <FormCheckbox
            {...utils.form.getFieldProps(fields, 'bdxCumulative')}
            control={methods.control}
            error={methods.formState.errors.bdxCumulative}
            defaultValue={config?.bdxCumulative || false}
          />
        </Box>
      </Grid>
    )}
  </Grid>
);

export const getBdxCoverHolderFields = (
  classes,
  sanctionsCheckOnly,
  fields,
  utils,
  methods,
  isTest,
  bdxTypesOptions,
  bdxTypeDefaultValue,
  setSelectedBDXType,
  sheets,
  bdxType,
  thirdParties,
  config,
  fullScreen
) => (
  <Grid container spacing={2} className={classnames({ [classes.uploadFileFullScreen]: fullScreen })}>
    {sanctionsCheckOnly && (
      <Grid item xs={4}>
        <FormText
          {...utils.form.getFieldProps(fields, 'coverholder')}
          control={methods.control}
          error={methods.formState.errors.coverholder}
          rules={{ required: 'Required' }}
          disabled={isTest}
        />
      </Grid>
    )}
    {sanctionsCheckOnly && (
      <Grid item xs={4}>
        <FormText
          {...utils.form.getFieldProps(fields, 'facility')}
          control={methods.control}
          error={methods.formState.errors.facility}
          rules={{ required: 'Required' }}
          disabled={isTest}
        />
      </Grid>
    )}
    <Grid item xs={4}>
      <FormAutocompleteMui
        {...utils.form.getFieldProps(fields, 'bdxType')}
        options={bdxTypesOptions}
        defaultValue={bdxTypeDefaultValue}
        control={methods.control}
        disableClearable
        error={methods.formState.errors.bdxType}
        rules={{ required: 'Required' }}
        onSelect={(selected) => {
          setSelectedBDXType(selected);
        }}
        disabled={isTest || bdxTypeDefaultValue}
      />
    </Grid>
    {sheets?.length > 0 && (
      <Grid item xs={4}>
        <FormSelect
          {...utils.form.getFieldProps(fields, 'sheets')}
          rules={sheets?.length > 0 ? { required: 'Required' } : {}}
          options={getSelectOptions(sheets)}
          control={methods.control}
          error={methods.formState.errors.sheets}
        />
      </Grid>
    )}
    <Grid item xs={4}>
      <FormDate
        {...utils.form.getFieldProps(fields, 'yearMonthSubmission')}
        rules={isTest ? {} : { required: 'Required' }}
        control={methods.control}
        error={methods.formState.errors.yearMonthSubmission}
      />
    </Grid>
    {bdxType?.label === BDX_TYPE_CLAIM && (
      <Grid item xs={4}>
        <FormSelect
          {...utils.form.getFieldProps(fields, 'thirdParty')}
          rules={{ required: 'Required' }}
          options={[...thirdParties, { id: -1, name: 'No TPA' }]}
          control={methods.control}
          error={methods.formState.errors.thirdParty}
          disabled={isTest}
        />
      </Grid>
    )}
    <Grid item xs={4}>
      <Grid container spacing={2} className={classes.headersOverNRows}>
        <Grid>
          <Typography variant="body1">Header over</Typography>
        </Grid>
        <Grid item className={classes.headerOverWrapper}>
          <FormSelect
            nestedClasses={{ root: `${classes.field} ${classes.headerOverSelect}` }}
            control={methods.control}
            {...utils.form.getFieldProps(fields, 'headersOverNRows')}
          />
        </Grid>
        <Grid item className={classes.headerOverLabel}>
          <Typography variant="body1">rows</Typography>
        </Grid>
      </Grid>
    </Grid>
    {!isTest && (
      <Grid item xs={4}>
        <Box
          style={{
            marginTop: '1.4rem',
          }}
        >
          <FormCheckbox
            {...utils.form.getFieldProps(fields, 'bdxCumulative')}
            control={methods.control}
            error={methods.formState.errors.bdxCumulative}
            defaultValue={config?.bdxCumulative || false}
          />
        </Box>
      </Grid>
    )}
  </Grid>
);

export const getFormValues = (
  values,
  isCoverHolderUpload,
  isTest,
  operationalClientId,
  facility,
  requestId,
  password,
  defaultRiskCodes,
  riskCodes,
  fileRequestId
) => {
  if (isCoverHolderUpload) {
    const {
      bdxCumulative,
      bdxType,
      headersOverNRows,
      requestId,
      sheets,
      thirdParty,
      yearMonthSubmission,
      password = null,
      replacesRequest,
    } = values;

    return {
      requestId,
      sheetDetails: [
        {
          sheetName: sheets[0],
          facilityReference: null,
          yearMonthSubmission: utils.date.formatDateWithParameter(yearMonthSubmission, 'YYYY-MM'),
          cumulative: bdxCumulative,
          headersOverNRows: Number(headersOverNRows),
          preview: Boolean(isTest),
          sheetOrder: sheets?.length > 0 ? sheets?.length : 1,
        },
      ],
      password,
      bdxType: bdxType.label,
      thirdPartyId: thirdParty !== -1 ? thirdParty : null,
      replacesRequest: replacesRequest || null,
      operationalClientId: operationalClientId,
    };
  } else {
    const { bdxPeriod, sheets, bdxType, coverholder, facility: facilityUMR, thirdParty, bdxCumulative } = values;
    return {
      isPreview: Boolean(isTest),
      facility,
      requestId,
      password,
      yearMonthSubmission: bdxPeriod?.split('-')[0] + '-' + bdxPeriod?.split('-')[1],
      sheets: isTest ? [sheets] : sheets,
      riskCodes: facility ? defaultRiskCodes : riskCodes,
      bdxType: bdxType.label,
      coverholder,
      facilityUMR,
      thirdPartyId: thirdParty !== -1 ? thirdParty : null,
      replacesRequest: fileRequestId ? fileRequestId : null,
      cumulative: bdxCumulative,
    };
  }
};
