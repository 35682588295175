import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
// app
import { Empty } from 'components';

import { BINDER_MANAGEMENT, EDGE_ADMIN, QUOTE_BIND, REPORTS_ADMIN, USER_LIST_FILTER_OPTIONS } from 'consts';
import { usePagination } from 'hooks';
import { useGetUserList } from 'lib/admin';
import { useGetBrands } from 'lib/app';
import { selectAdminUsersFilters, selectAdminUsersSortOptions, setFilter, showModal } from 'stores';
import * as utils from 'utils';

import { AdminUserView } from './AdminUser.view';

export const AdminUser = () => {
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const filters = useSelector(selectAdminUsersFilters);
  const sortOptions = useSelector(selectAdminUsersSortOptions);

  const { isLoading: isLoadingUserList, data: userList } = useGetUserList(params);
  const { data: brands } = useGetBrands('v2');

  const paginationData = utils.api.pagination(userList, true);
  const paginationObj = {
    page: paginationData.page - 1,
    rowsTotal: paginationData.itemsTotal,
    rowsPerPage: paginationData.pageSize,
  };

  useEffect(() => {
    const filterParams = Object.keys(filters)?.reduce((acc, key) => {
      if (filters[key] && key !== 'page') {
        if (key === 'appAccessFilter') {
          const isQuoteAndBindUserFilter = filters?.appAccessFilter?.find(({ value }) => value === QUOTE_BIND);
          const isBinderManagementUserFilter = filters?.appAccessFilter?.find(({ value }) => value === BINDER_MANAGEMENT);
          const isAdminFilter = filters?.appAccessFilter?.find(({ value }) => value === EDGE_ADMIN);
          const isReportAdminFilter = filters?.appAccessFilter?.find(({ value }) => value === REPORTS_ADMIN);

          if (isQuoteAndBindUserFilter) acc.isQuoteAndBindUserFilter = true;
          if (isBinderManagementUserFilter) acc.isBinderManagementUserFilter = true;
          if (isAdminFilter) acc.isAdminFilter = true;
          if (isReportAdminFilter) acc.isReportAdminFilter = true;
        } else if (USER_LIST_FILTER_OPTIONS.find((option) => option.key === key)?.type === 'autocomplete') {
          acc[key] = filters[key].map((item) => item.value).join(',');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});

    setParams((prev) => ({
      ...prev,
      page: 1,
      filters: filterParams,
    }));
  }, [filters]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      sort: sortOptions,
    }));
  }, [sortOptions]);

  const handleChangePage = (newPage) => {
    setParams({ ...params, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setParams({ ...params, size: rowsPerPage });
  };

  const handleCreateUser = (user = {}) => {
    dispatch(
      showModal({
        component: 'ADD_USER',
        props: {
          title: user.id ? 'admin.editUser' : 'admin.createUser',
          fullWidth: true,
          maxWidth: 'md',
          componentProps: {
            user,
          },
        },
      })
    );
  };

  const handleFilterClick = (char) => {
    dispatch(
      setFilter({
        category: 'admin',
        page: 'users',
        options: {
          ...filters,
          nameBeginsWithFilter: char,
        },
      })
    );
  };

  const pagination = usePagination(userList?.content, paginationObj, handleChangePage, handleChangeRowsPerPage);

  if (isLoadingUserList) {
    return <Empty title={utils.string.t('app.loading')} icon={<Loading />} width={300} />;
  }

  return (
    <AdminUserView
      userList={userList?.content}
      brands={brands}
      pagination={pagination.obj}
      sort={{
        by: paginationData.sortBy,
        type: paginationData.sortType,
        direction: paginationData.sortDirection,
      }}
      handleChangePage={pagination.handlers.handleChangePage}
      handleCreateUser={handleCreateUser}
      handleChangeRowsPerPage={pagination.handlers.handleChangeRowsPerPage}
      handleFilterClick={handleFilterClick}
      selectedCharacter={filters?.nameBeginsWithFilter}
    />
  );
};

export default AdminUser;
